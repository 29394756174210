import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import "../index.css";
import Grid from "@mui/material/Grid";
import Switch from '@mui/material/Switch';
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Add from "../Assets/Icons/Icons/add.png";
import Filter from "../Assets/Icons/Icons/filter.png";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Image from "../Assets/Icons/Icons/Background-image.png";
import GreenDots from "../Assets/Icons/Icons/GreenDots.png";
import FormGroup from '@mui/material/FormGroup';
import Button from "@mui/material/Button";
import { apiService, apiService2 } from "../Services/apiService";

import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png";
import { Link } from "react-router-dom";
import BlackUser from "../Assets/Icons/Icons/BlackUser.png";
import CircularProgress from "@mui/material/CircularProgress";
import DefaultImage from "../Assets/Icons/Icons/DefaultImage.png";
import moment from "moment";

import {
  SERVER_URL,
  LIST_CAMPS,
  FILTER_LIST_CAMPS,
} from "../Services/apiEndpints";

const filters = [
  
  {
    name: "Camp Confidence",
    value: "Camp Confidence",
    state: false
  },
  {
    name: "Girls Camp",
    value: "Girls Camp",
    state: false
  },
  {
    name: "Boys Camp",
    value: "Boys Camp",
    state: false
  },
  {
    name: "Young Ladies Getaway",
    value: "Young Ladies Getaway",
    state: false
  },
  {
    name: "Adventure Camp",
    value: "Adventure Camp",
    state: false
  },
  {
    name: "Support Camp",
    value: "Support Camp",
    state: false
  },
];

const sortBy = [
  // {
  //   name: "Date",
  //   value: "regDate",
  // },
  {
    name: "Fee",
    value: "fee",
  },
  {
    name: "Age Group",
    value: "ageGroup",
  },
  {
    name: "None",
    value: "default",
  },
  // {
  //   name: "Age group",
  //   value: "regDate",
  // },

];


export default function ArchivedCamps() {
  const [sortType, setSortType] = React.useState('default');
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [filterData, setFilterData] = React.useState([]);
  const [campNo, setCampNo] = React.useState([]);
  const [toggleSide, setToggleSide] = React.useState(true)
  const [dummyValue, setDummyValue] = React.useState('default')
  // const[dummy,setDummy] = React.useState(filters)
  const sortTypes = {

    fee: {
      class: 'sort-up',
      fn: (a, b) => (a.campFee > b.campFee) ? 1 : -1,
      fn1: (a, b) => (a.campFee > b.campFee) ? -1 : 1
    },

    regDate: {
      class: 'sort-down',
      fn: (a, b) => Date.parse(a.campRegStartDateTime) - Date.parse(b.campRegStartDateTime)
      // fn: (a, b) => Date.parse(a.moment(a.campRegStartDateTime).format("DD-MM-YYYY")) - Date.parse(b.moment(b.campRegStartDateTime).format("DD-MM-YYYY"))
     
      // filteredList.sort(a, b) =>{}
      // sort(function(a, b){
      //   var aa = a.campRegStartDateTime.split('/').reverse().join(),
      //       bb = b.campRegStartDateTime.split('/').reverse().join();
      //   return aa < bb ? -1 : (aa > bb ? 1 : 0);
      //   })


      
      // { 
      //     console.log(a,b,'hereeeeeee')
      //     const [month1, day1, year1] = a.campStartDateTime.split('/');
      //   const [month2, day2, year2] = b.campStartDateTime.split('/');
      //    const dateObject1 = new Date(`${year1}-${month1}-${day1}`); 
      //    const dateObject2 = new Date(`${year2}-${month2}-${day2}`); 
      //    return dateObject1 - dateObject2;
      //    }
    },
    ageGroup: {
      class: 'sort-up',
      fn: (a, b) => (a.minAge > b.minAge) ? 1 : -1,
      fn1: (a, b) => (a.minAge > b.minAge) ? -1 : 1
    },

    default: {
      class: 'sort',
      fn: (a, b) => a
    }

  }

  const sortTypeValue = (val) => {
    console.log('type', val)
    setDummyValue(val)
    // setSortType(val)
  }

  

  const toggle = () => {
    setToggleSide(!toggleSide);
    console.log('toggleSide', toggleSide)
  }

  const handleSearchChange = (event) => {
    console.log(event.target.value);
    setSearch(event.target.value);
  };

  const handleClickPopover = (event, id) => {
    setAnchorEl2(event.currentTarget);
    console.log(event.currentTarget);
    console.log(id);
    setCampNo(id);
  };

  const handleClosePopover = () => {
    setAnchorEl2(null);
  };

  const openPopover = Boolean(anchorEl2);
  const idPopover = openPopover ? "simple-popover" : undefined;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [datatry, setDataTry] = React.useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // console.log(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getData = async (filterInput) => {
    console.log(filterInput,filterInput.value)
    let apiInfo = {
      // url: `${process.env.REACT_APP_API_URL}camps/filterlistcamps`,
      url:`${SERVER_URL}${ FILTER_LIST_CAMPS}`,
      method: "POST",
      postData: {
        userId: localStorage.getItem("userId"),
        filterBy: "camp_type",
        filterValue: filterInput.value,
        headers: {
          "Content-Type": "application/json",
          // tokenheaderkey:
          //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3Rjb29yZGluYXRvckB5b3BtYWlsLmNvbSIsImlhdCI6MTY3NTkzNTE0NywiZXhwIjoxNjgzNzExMTQ3fQ.oD0tLHtnbx_vl2XRF7jZZhCM2B22-W_wdav09G2kYoA",
          tokenheaderkey: localStorage.getItem("token"),
        },
      },
    };

    let filterValues = await apiService(apiInfo);
    console.log("filterValue", filterInput.value);
    let filterList = [];
    if (filterValues?.data?.responseDesc === "Success") {
      filterList = filterValues.data.responseData[0];
    }
    // console.log("filterList", filterLihandleApplyst);
    // setDataTry(filterList);
    setFilterData(filterList);

    console.log("filterlist", filterList);
    console.log("DataTry", datatry);
    // if(filterValues.data)
    
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleApply = () => {

    setSortType(dummyValue)
    setDataTry(filterData)
    handleClose()

  };
  const showList = () => {
    (async () => {
      let gettran = {
        userId: localStorage.getItem("userId"),
        campStatus:"Archived"
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/listallcamps",
        url:`${SERVER_URL}${LIST_CAMPS}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          // tokenheaderkey:
          //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3Rjb29yZGluYXRvckB5b3BtYWlsLmNvbSIsImlhdCI6MTY3NjI4NjM0NywiZXhwIjoxNjg0MDYyMzQ3fQ.TSAbvmY8RwRINFkDN2btiMOSm0f8bIjkTv2-w7APjP4",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);
      // login.data.responseData[0]?():()
      const campDataList = login.data.responseData[0];
      campDataList.forEach((element) => {
        if (element.StaffList != null) {
          console.log(element.StaffList);
          element.StaffList = JSON.parse(element.StaffList);
        }
      });
      console.log(campDataList);
      setDataTry(campDataList);
      setSortType('default')
      console.log(datatry);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        // setLoading(false);
      });
      handleClose()
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      let gettran = {
        userId: localStorage.getItem("userId"),
        campStatus:"Archived"
      };

      let apiInfo = {
        
        // url: "https://cncdevapi.natsoft.io/api/camps/listallcamps",
        url:`${SERVER_URL}${LIST_CAMPS}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          // tokenheaderkey:
          //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3Rjb29yZGluYXRvckB5b3BtYWlsLmNvbSIsImlhdCI6MTY3NTkzNTE0NywiZXhwIjoxNjgzNzExMTQ3fQ.oD0tLHtnbx_vl2XRF7jZZhCM2B22-W_wdav09G2kYoA",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);

      const campDataList = login.data.responseData[0];
      setLoading(false);
      campDataList.forEach((element) => {
        if (element.StaffList != null) {
          console.log(element.StaffList);
          element.StaffList = JSON.parse(element.StaffList);
          console.log(element.StaffList);
        }
      });
      console.log(campDataList);

      setDataTry(campDataList);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => { });
  }, []);

  const filteredList = datatry.filter((item) => {
    return (
      item.campName.toLowerCase().includes(search.toLowerCase()) ||
      item.campType.toLowerCase().includes(search.toLowerCase()) ||
      item.campStatus.toLowerCase().includes(search.toLowerCase()) ||
      item.campLocation.toLowerCase().includes(search.toLowerCase())
    );
  });

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };
  return (
    <div>
      <Box>
        <Grid
          container
          display="flex"
        //   justifyContent="space-between"
          alignItems="center"
        // px="1.5rem"
        >
          {/* <Grid item  direction='flex'> */}
          <Grid item>
            <Grid container direction="flex">
              <Stack
                direction="row"
                spacing={1}
                xs={12}
                sm={12}
                md={6}
                // mr="4rem"
                alignSelf="center"
              >
                <Link to="/CampList">
                  <Box
                    mt="0.65rem"
                    alignSelf="center"
                    component="img"
                    sx={{
                      height: "0.75rem",
                      width: "0.65rem",
                      cursor: "pointer",
                    }}
                    height="20"
                    alt="Logo"
                    src={ArrowBack}
                  ></Box>
                </Link>
                <Box className="bold" alignSelf="center">
                  Archived Camps List
                </Box>
                
              </Stack>
            </Grid>
          </Grid>
          <Grid item width="60%" ml='2rem'>
            <Paper
              xs={12}
              sm={12}
              md={6}
              component="form"
              sx={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                value={search}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}

              />
              <Box
                component="img"
                src={Filter}
                sx={{
                  height: "1rem",
                  width: "1rem",
                  mx: "1rem",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              />
            </Paper>
          </Grid>
          {/* </Grid> */}

          
        </Grid>

        <Popover
          id={id}
          open={open}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 250, left: 715 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <Grid>
            <Divider />
            <Grid>
              <FormControl className="medium">
                {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  size="small"
                  sx={{
                    maxWidth: 500,
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                  }}
                  // className="medium"
                  my="2rem"
                  mx="1rem"
                >
                  {filters.map((filter, index) => (
                    <FormControlLabel
                      key={index}
                      // value={filter.value}
                      value={filter.value}
                      control={<Radio />}
                      label={filter.name}
                      className="medium"
                      sx={{ px: "1rem" }}
                      name = {filter.name}
                      // onHandle={getFilterData}
                      onChange={(event) => {
                        getData(event.target);
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Divider />
              <FormControl>
                {/* <Stack direction='row' spacing={1} alignItems='center'>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{ px: "1rem" }}
                >
                  Sort by
                </FormLabel>
                <Switch defaultChecked size="small" />
                </Stack>
                 */}
                <Grid container display='flex' justifyContent='flex-start'>
                  <FormGroup>
                    {/* <FormControlLabel control={<Switch defaultChecked />} label="Label" /> */}
                    <FormControlLabel
                      value="start"
                      control={<Switch color="primary" onChange={toggle} />}
                      label="Sort by :"
                      labelPlacement="start"

                    />
                  </FormGroup>
                </Grid>

                <RadioGroup
                  sx={{ maxWidth: 500, "& .MuiSvgIcon-root": { fontSize: 16 } }}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  size="large"

                // className="medium"
                >
                  {sortBy.map((filter, index) => (
                    <Box>
                      <FormControlLabel
                        key={index}
                        value={filter.value}
                        control={<Radio />}
                        label={filter.name}
                        className="medium"
                        sx={{ px: "1rem" }}
                        onChange={(event) => {
                          sortTypeValue(event.target.value);
                        }}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid display="flex" justifyContent="flex-end" my="1rem">
            <Button
              onClick={() => showList()}
              variant="contained"
              sx={{
                px: "3rem",
                bgcolor: "#707070",
                textTransform: "none",
                "&:hover": { background: "#707070" },
                mx: "0.5rem",
              }}
            >
              Reset
            </Button>

            <Button
              onClick={() => handleApply()}
              variant="contained"
              sx={{
                px: "3rem",
                bgcolor: "#379592",
                textTransform: "none",
                "&:hover": { background: "#379592" },
                mx: "0.5rem",
              }}
            >
              Apply
            </Button>
          </Grid>
        </Popover>
        {/* Card component */}
        {loading === true ? (
          <Box textAlign="center" alignSelf="center">
            <CircularProgress />
          </Box>
        ) : (
          <div>
            <Grid>
              {filteredList.sort(toggleSide ? (sortTypes[sortType].fn) : (sortTypes[sortType].fn1)).map((card, index) => (
                <Card
                  key={index}
                  sx={{
                    backgroundColor: "#F8F8F8",
                    color: "#707070",
                    my: "1rem",
                    border: 1,
                    borderColor: "#379592",
                    borderRadius: "0.75rem",
                  }}
                >

                  <Link to={`/ViewCamp/${card.campId}`} className="link2">
                    <CardContent>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Grid item md={6}>
                          <Stack direction="row" spacing={1}>
                            <Box alignSelf="center">{card.campName}</Box>
                            <Chip
                              label={
                                card.campType.charAt(0).toUpperCase() +
                                card.campType.slice(1).toLowerCase()
                              }
                              variant="outlined"
                              size="small"
                              sx={{ color: "#707070", backgroundColor: "white" }}
                            />
                          </Stack>
                          <Box
                            mt="1rem"
                            sx={{ fontSize: "12px", mb: "0.75rem" }}
                            className="medium"
                          >
                            <Box>Location : {card.campLocation}</Box>

                            <Stack
                              direction="row"
                              spacing={12}
                              sx={{ mt: "0.75rem" }}
                            >
                              <Box>Start Date :{card.campStartDateTime}</Box>
                              <Box>End Date :{card.campEndDateTime}</Box>
                            </Stack>

                            {/* <Box sx={{ mt: "0.75rem" }}>
                        Start Date :{card.campStartDateTime}
                      </Box> */}
                            <Box sx={{ my: "0.75rem" }}>
                              Age group : {card.ageGroup}
                            </Box>

                            <Box sx={{ my: "0.75rem" }}>
                              Description : {card.campDescription}
                            </Box>
                            <Box>Fee : {card.campFee}</Box>
                          </Box>
                        </Grid>

                        <Grid item>
                          <Grid>
                            <Grid display="flex" justifyContent='space-between' >
                              {/* <Link to='/ViewCamp' classNam='link'> */}
                              <Grid item>
                                <Chip
                                 
                                  label={
                                    card.campStatus.charAt(0).toUpperCase() +
                                    card.campStatus.slice(1).toLowerCase()
                                }
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    color: 'white',
                                    backgroundColor: '#4C9F70',
                                    px: "1rem",
                                    py: "0.85rem",
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <Stack direction="row" spacing={1} alignSelf='center'  >
                                  <Box
                                    // pb="0.5rem"
                                    component="img"
                                    sx={{
                                      height: "1rem",
                                      width: "1rem",
                                      alignSelf: "center",
                                    }}
                                    src={BlackUser}
                                  ></Box>
                                  <Box sx={{ fontSize: "15px", pt: "0.25rem" }}>
                                    {card.campSpots}
                                  </Box>
                                </Stack>
                              </Grid>



                              {/* <Box>
                             
                              <Box
                                component="img"
                                
                                pt="0.15rem"
                                sx={{
                                  height: "2rem",
                                  width: "2.5rem",
                                  mx: "2px",
                                  cursor: "pointer",
                                }}
                                src={GreenDots}
                                aria-describedby={idPopover}
                                onClick={(e) =>
                                  handleClickPopover(e, card.campId)
                                }

                               
                              ></Box>
                            </Box> */}
                            </Grid>
                          </Grid>
                          <Grid
                            my="1rem"
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 1, md: "1" }}
                          >
                            <Box
                              component="img"
                              src={card.Thumbnail ? card.Thumbnail : DefaultImage}
                              sx={{
                                height: "120px",
                                width: "200px",
                                borderRadius: "10px",
                              }}
                              alignSelf="center"
                            ></Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Link>

                </Card>
              ))}
            </Grid>
          </div>
        )}
      </Box>
      <Popover
        id={idPopover}
        open={openPopover}
        anchorEl={anchorEl2}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ border: "none" }}
      >
        <Box m="0.5rem">
          <Box sx={{ fontSize: "10px" }}>
            <Link to={`/ViewCamp/${campNo}`} className="link">
              view
            </Link>
          </Box>
          <Box sx={{ fontSize: "10px" }}>
            <Link to={`/EditCamp/${campNo}`} className="link">
              edit
            </Link>
          </Box>
          <Box sx={{ fontSize: "10px" }}>
            <Link to={`/RemoveCamp/${campNo}`} className="link">
              remove
            </Link>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}
