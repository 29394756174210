import React, { useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Link, useHistory, useParams } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField";
import { useReactToPrint } from "react-to-print";
import {
  Radio,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  FormControl,
  Card,
} from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { apiService, apiService2 } from "../../Services/apiService";
import html2canvas from "html2canvas";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import jsPDF from "jspdf";
import { SERVER_URL, US8_URL } from "../../Services/apiEndpints";

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#379592",
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#379592", // Set your desired checkbox color
    },
  },
});
const theme1 = createTheme({
  palette: {
    primary: {
      main: "#2196f3", // Set your desired color for grid item 1
    },
  },
});
export default function ViewCampSummary() {
  const [loading, setLoading] = React.useState(false);
  const [viewData, setViewData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [accordionLoop, setAccordionLoop] = useState([]);
  const [goalId, setGoalId] = React.useState();
  const [goalStatus, setGoalStatus] = React.useState();
  const [staffInfo, setStaffInfo] = useState([]);
  const [overAllDetails, setOverAllDetails] = useState();
  const history = useHistory();
  const componentRef = useRef();

  let { camp_id, applicationId } = useParams();

  const dummyData = {
    overall_strength: "",
    overall_improvement: "",
    overall_strategies: "",
  };
  const [therapistName, setTherapistName] = React.useState(null);
  const [formData, setFormData] = useState(dummyData);

  const generatePDF = () => {
    // Get the element you want to convert to PDF (e.g., a div with id "my-element")
    const element = document.getElementById("my-element");

    // Use html2canvas to convert the element to a canvas
    html2canvas(element).then((canvas) => {
      // Create a new instance of jsPDF
      const doc = new jsPDF();

      // Calculate the width and height of the PDF document
      const width = doc.internal.pageSize.getWidth();
      const height = doc.internal.pageSize.getHeight();

      // Add the canvas to the PDF document
      doc.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, width, height);

      // Save the PDF
      doc.save("sample.pdf");
    });
  };

  const field = async (formData) => {
    let postDetails = {
      userId: localStorage.getItem("userId"),
      _methodId: "11",
      campId: camp_id,

      goal_Id: goalId,
      goalStatus: goalStatus,
      applicationId: applicationId,
      overall_strength: formData.overall_strength,
      overall_improvement: formData.overall_improvement,
      overall_strategies: formData.overall_strategies,
    };
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getStaffNameByRole = (roleName) => {
    console.log("staffInfo", staffInfo, roleName);
    let staffData = (staffInfo || []).find(
      (item) => item.rolesname === roleName
    );

    return staffData?.staffName;
  };
  React.useEffect(async () => {
    setLoading(true);
    let inputData = {
      _methodId: "10",
      campId: camp_id,
      // campId: 5199,
      applicationId: applicationId,
      userId: localStorage.getItem("userId"),
    };
    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/us8",
      url: `${SERVER_URL}${US8_URL}`,
      method: "POST",
      postData: inputData,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    };
    let output = await apiService2(apiInfo);
    console.log(output);
    let viewOp = output.data.responseData[0][0];

    if (viewOp && viewOp.group_leader_name) {
      let group_leader_name = JSON.parse(viewOp.group_leader_name);
      viewOp.group_leader_name = group_leader_name[0];
      console.log(viewOp);
      setViewData(viewOp);
      setRows(viewOp.groupInfo);
    }
    console.log(output.data.responseData[1][0]);
    let staffInfo = JSON.parse(output.data.responseData[1][0]["staffInfo"]);
    console.log(staffInfo);

    staffInfo.forEach((element) => {
      console.log(element);
      element.staffList.forEach((element1) => {
        console.log(element1);
        if (element1.rolesId === "5") {
          setTherapistName(element1.staffName);
        }
      });
    });

    setLoading(false);
    // console.log(viewOp)
  }, []);

  React.useEffect(() => {
    (async () => {
      let getPreGoalData = {
        _methodId: "6",
        applicationId: applicationId,
        // applicationId: 300004,

        campId: camp_id,
        userId: localStorage.getItem("userId"),
      };
      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/us8",
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: getPreGoalData,
        headers: {
          "Content-Type": "application/json",

          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let goals = await apiService2(apiInfo);
      let get = goals.data.responseData[0];
      // setLoading(false);
      setFormData({
        ...formData,
        overall_strength: goals.data.responseData[1][0]["overall_strengths"],
        overall_improvement: goals.data.responseData[1][0]["overall_aoi"],
        overall_strategies: goals.data.responseData[1][0]["overall_strategies"],
      });
      console.log(get);
      setGoalId(get[0].goal_Id);
      setGoalStatus(get[0].goal_status);
      setAccordionLoop(get);
      setOverAllDetails(goals["data"]["responseData"][1][0]);
      console.log(overAllDetails);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);

  return (
    <>
      <Grid className="gilroy" sx={{ mt: "2rem" }}>
        <Grid
          xs={12}
          sm={6}
          md={9}
          display="flex"
          justifyContent="space-between"
        >
          <Grid item>
            <Stack direction="row" spacing={1}>
              <Link to={`/GoalCamp/${camp_id}`} className="link2">
                <Box
                  alignSelf="center"
                  component="img"
                  sx={{
                    height: "0.75rem",
                    width: "0.65rem",
                    cursor: "pointer",
                  }}
                  alt="Logo"
                  src={ArrowBack}
                ></Box>
              </Link>
              <Box alignSelf="center" className="bold">
                {" "}
                View Camp Summary Report
              </Box>
            </Stack>
          </Grid>

          <Grid item>
            <Stack direction="row">
              <Link
                to={`/EditCampSummary/${camp_id}/${applicationId}`}
                className="link2"
              >
                <Button
                  variant="contained"
                  // disabled
                  sx={{
                    mx: "0.5rem",
                    bgcolor: "#FF9529",
                    textTransform: "none",
                    "&:hover": { background: "#FF801E" },
                    mt: "2rem",
                  }}
                >
                  EditReport
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>

        <Grid ml={1} ref={componentRef}>
          <div id="my-element" ml={4}>
            <Grid container display="flex" my={3}>
              <Grid mt={2} className="medium" display="flex" md={9}>
                <Grid md={4}>Date of Summary:</Grid>
                <Grid md={4}>
                  <TextField
                    id="standard-basic"
                    rows={2}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={viewData.dateOfsummary}
                  />
                </Grid>
              </Grid>
              <Grid mt={2} className="medium" display="flex" md={9}>
                <Grid md={4}>Child's DOB:</Grid>
                <Grid md={4}>
                  <TextField
                    id="standard-basic"
                    rows={2}
                    size="small"
                    value={viewData.date_of_birth}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid mt={2} className="medium" display="flex" md={9}>
                <Grid md={4}>Camp Start Date:</Grid>
                <Grid md={4}>
                  <TextField
                    id="standard-basic"
                    rows={2}
                    size="small"
                    value={viewData.camp_startdate}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid mt={2} className="medium" display="flex" md={9}>
                <Grid md={4}>Camp End Date:</Grid>
                <Grid md={4}>
                  <TextField
                    id="standard-basic"
                    rows={2}
                    size="small"
                    value={viewData.camp_enddate}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid mt={2} className="medium" display="flex" md={9}>
                <Grid md={4}>Camp Group Leaders:</Grid>
                <Grid md={4}>
                  <TextField
                    id="standard-basic"
                    rows={2}
                    size="small"
                    value={viewData?.group_leader_name}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item dispaly="flex" my={3} className="medium">
              To
              <TextField
                id="standard-basic"
                rows={1}
                size="small"
                sx={{
                  width: 220,
                  mx: "0.5rem",
                }}
                value={viewData.parentName}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid className="medium">
              We hope that your child,
              <TextField
                id="standard-basic"
                rows={1}
                sx={{ mx: "0.5rem" }}
                size="small"
                value={viewData.child_name}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />{" "}
              , enjoyed their experience at ({viewData.camp_name}) this year.
              This report contains your child's camp goals and ratings, and a
              summary of their overall strengths, areas to develop and
              strategies from camp.
            </Grid>
            <Grid className="medium">
              <p> Goals for Camp </p>
              The below goals were set prior to the start of
              <TextField
                id="standard-basic"
                rows={1}
                size="small"
                sx={{
                  width: 220,
                  mx: "0.5rem",
                }}
                value={viewData.camp_name}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              ></TextField>
              with
              <TextField
                id="standard-basic"
                rows={2}
                size="small"
                sx={{
                  width: 220,
                  mx: "0.5rem",
                }}
                value={therapistName}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              .Goals for Camp The below goals were set prior to the start of
              with . Goals are rated using the Canadian Occupation Performance
              Measure rating scales. Goals are rated on the following:
              <ul>
                <li>
                  Importance: how important it is to be able to achieve this
                  goal. 1=not important; 10=very important .
                </li>
                <li>
                  Performance:How well is the person able to perform the goal
                  area? From person's perspective: How well are you able to
                  perform this goal area 1= not well; 10= very well .
                </li>
                <li>
                  Satisfaction: How satisfied you are with the person's
                  performance? /If the child is rating, how satisfied are they
                  with their own performance for that goal? 1= not satisfied;
                  10= very satisfied.
                </li>
              </ul>
            </Grid>
            {accordionLoop.map((item) => (
              <Grid>
                <br></br>
                <div>
                  <AccordionDetails>
                    <Grid container sx={{ color: "#FFFFFF" }}>
                      <Grid md={8}>
                        <p>{item.goalName}</p>
                      </Grid>
                      <Grid
                        md={4}
                        sx={{ fontSize: "10px", justifyContent: "end" }}
                      >
                        <p>{item.goalDesc}</p>
                      </Grid>
                    </Grid>

                    <Grid className="medium">
                      <div>
                        <Grid
                          container
                          display="flex"
                          sx={{ mt: "0.5rem", mb: "0.5rem" }}
                        >
                          <Grid item xs={12} sm={6} md={9}>
                            <Stack direction="row" spacing={1}></Stack>
                          </Grid>
                        </Grid>

                        <Card sx={{ backgroundColor: "#F8F8F8" }}>
                          <h3>
                            <Box
                              sx={{ ml: "2rem", mt: "2rem", color: "#379592" }}
                            >
                              Pre Camp Rating
                            </Box>
                          </h3>

                          <ThemeProvider theme={theme}>
                            {/* <Grid container display="flex" sx={{ mt: "1rem" }}> */}
                              <div>
                                <div className="goal-title">
                                  <Box sx={{ ml: "2rem" }}>Importance:</Box>
                                </div>

                                <Stack direction="row" spacing={1}>
                                  <Grid container display="flex">
                                    <Grid item md={2} sx={{ pr: "1rem" }}>
                                      <div className="">
                                        <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                          Caregiver:
                                        </Box>
                                      </div>
                                    </Grid>
                                    <Grid item md={10} sx={{ pl: "2rem" }}>
                                      <div className="parentrdiobtn">
                                        <FormControl>
                                          {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}

                                          <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="parent_importance"
                                            value={item.parent_importance}
                                          >
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                            <FormControlLabel
                                              value="1"
                                              control={<Radio />}
                                              label="1"
                                            />
                                            <FormControlLabel
                                              value="2"
                                              control={<Radio />}
                                              label="2"
                                            />
                                            <FormControlLabel
                                              value="3"
                                              control={<Radio />}
                                              label="3"
                                            />
                                            <FormControlLabel
                                              value="4"
                                              control={<Radio />}
                                              label="4"
                                            />
                                            <FormControlLabel
                                              value="5"
                                              control={<Radio />}
                                              label="5"
                                            />
                                            <FormControlLabel
                                              value="6"
                                              control={<Radio />}
                                              label="6"
                                            />
                                            <FormControlLabel
                                              value="7"
                                              control={<Radio />}
                                              label="7"
                                            />
                                            <FormControlLabel
                                              value="8"
                                              control={<Radio />}
                                              label="8"
                                            />
                                            <FormControlLabel
                                              value="9"
                                              control={<Radio />}
                                              label="9"
                                            />
                                            <FormControlLabel
                                              value="10"
                                              control={<Radio />}
                                              label="10"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Stack>

                                <Stack direction="row" spacing={1}>
                                  {/* <div  className="parentgoal"> */}
                                  <Grid container display="flex">
                                    <Grid item md={2} sx={{ pr: "1rem" }}>
                                      <div className="">
                                        <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                          Camp Team:
                                        </Box>
                                      </div>
                                    </Grid>
                                    <Grid item md={10} sx={{ pl: "2rem" }}>
                                      <ThemeProvider theme={theme1}>
                                        <div className="">
                                          <FormControl>
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}

                                            <RadioGroup
                                              row
                                              aria-labelledby="demo-row-radio-buttons-group-label"
                                              name="setImp"
                                              value={item.setImp}
                                            >
                                              {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                              <FormControlLabel
                                                value="1"
                                                control={<Radio />}
                                                label="1"
                                              />
                                              <FormControlLabel
                                                value="2"
                                                control={<Radio />}
                                                label="2"
                                              />
                                              <FormControlLabel
                                                value="3"
                                                control={<Radio />}
                                                label="3"
                                              />
                                              <FormControlLabel
                                                value="4"
                                                control={<Radio />}
                                                label="4"
                                              />
                                              <FormControlLabel
                                                value="5"
                                                control={<Radio />}
                                                label="5"
                                              />
                                              <FormControlLabel
                                                value="6"
                                                control={<Radio />}
                                                label="6"
                                              />
                                              <FormControlLabel
                                                value="7"
                                                control={<Radio />}
                                                label="7"
                                              />
                                              <FormControlLabel
                                                value="8"
                                                control={<Radio />}
                                                label="8"
                                              />
                                              <FormControlLabel
                                                value="9"
                                                control={<Radio />}
                                                label="9"
                                              />
                                              <FormControlLabel
                                                value="10"
                                                control={<Radio />}
                                                label="10"
                                              />
                                            </RadioGroup>
                                          </FormControl>
                                        </div>
                                      </ThemeProvider>
                                    </Grid>
                                  </Grid>

                                  {/* </div> */}
                                </Stack>
                              </div>

                              <div>
                                <div className="goal-title">
                                  <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                    Satisfaction:
                                  </Box>
                                </div>
                                <Stack direction="row" spacing={1}>
                                  <Grid container display="flex">
                                    <Grid item md={2} sx={{ pr: "1rem" }}>
                                      <div className="">
                                        <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                          Caregiver:
                                        </Box>
                                      </div>
                                    </Grid>
                                    <Grid item md={10} sx={{ pl: "2rem" }}>
                                      <div className="">
                                        <FormControl>
                                          {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                          <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="parent_satisfaction"
                                            value={item.parent_satisfaction}
                                          >
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                            <FormControlLabel
                                              value="1"
                                              control={<Radio />}
                                              label="1"
                                            />
                                            <FormControlLabel
                                              value="2"
                                              control={<Radio />}
                                              label="2"
                                            />
                                            <FormControlLabel
                                              value="3"
                                              control={<Radio />}
                                              label="3"
                                            />
                                            <FormControlLabel
                                              value="4"
                                              control={<Radio />}
                                              label="4"
                                            />
                                            <FormControlLabel
                                              value="5"
                                              control={<Radio />}
                                              label="5"
                                            />
                                            <FormControlLabel
                                              value="6"
                                              control={<Radio />}
                                              label="6"
                                            />
                                            <FormControlLabel
                                              value="7"
                                              control={<Radio />}
                                              label="7"
                                            />
                                            <FormControlLabel
                                              value="8"
                                              control={<Radio />}
                                              label="8"
                                            />
                                            <FormControlLabel
                                              value="9"
                                              control={<Radio />}
                                              label="9"
                                            />
                                            <FormControlLabel
                                              value="10"
                                              control={<Radio />}
                                              label="10"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Stack>

                                <Stack direction="row" spacing={1}>
                                  <Grid container display="flex">
                                    <Grid item md={2} sx={{ pr: "1rem" }}>
                                      <div className="">
                                        <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                        Camp Team:
                                        </Box>
                                      </div>
                                    </Grid>
                                    <Grid item md={10} sx={{ pl: "2rem" }}>
                                      <ThemeProvider theme={theme1}>
                                        <div className="">
                                          <FormControl>
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                            <RadioGroup
                                              row
                                              aria-labelledby="demo-row-radio-buttons-group-label"
                                              name="setStasify"
                                              value={item.setStasify}
                                            >
                                              {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                              <FormControlLabel
                                                value="1"
                                                control={<Radio />}
                                                label="1"
                                              />
                                              <FormControlLabel
                                                value="2"
                                                control={<Radio />}
                                                label="2"
                                              />
                                              <FormControlLabel
                                                value="3"
                                                control={<Radio />}
                                                label="3"
                                              />
                                              <FormControlLabel
                                                value="4"
                                                control={<Radio />}
                                                label="4"
                                              />
                                              <FormControlLabel
                                                value="5"
                                                control={<Radio />}
                                                label="5"
                                              />
                                              <FormControlLabel
                                                value="6"
                                                control={<Radio />}
                                                label="6"
                                              />
                                              <FormControlLabel
                                                value="7"
                                                control={<Radio />}
                                                label="7"
                                              />
                                              <FormControlLabel
                                                value="8"
                                                control={<Radio />}
                                                label="8"
                                              />
                                              <FormControlLabel
                                                value="9"
                                                control={<Radio />}
                                                label="9"
                                              />
                                              <FormControlLabel
                                                value="10"
                                                control={<Radio />}
                                                label="10"
                                              />
                                            </RadioGroup>
                                          </FormControl>
                                        </div>
                                      </ThemeProvider>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </div>

                              <div>
                                <div className="goal-title">
                                  <Box sx={{ ml: "2rem", mt: "1.5rem" }}>
                                    Performance:
                                  </Box>
                                </div>
                                <Stack direction="row" spacing={1}>
                                  <Grid container display="flex">
                                    <Grid item md={2} sx={{ pr: "1rem" }}>
                                      <div className="">
                                        <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                          Caregiver:
                                        </Box>
                                      </div>
                                    </Grid>
                                    <Grid item md={10} sx={{ pl: "2rem" }}>
                                      <div className="">
                                        <FormControl>
                                          {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                          <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="parent_performance"
                                            value={item.parent_performance}
                                          >
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                            <FormControlLabel
                                              value="1"
                                              control={<Radio />}
                                              label="1"
                                            />
                                            <FormControlLabel
                                              value="2"
                                              control={<Radio />}
                                              label="2"
                                            />
                                            <FormControlLabel
                                              value="3"
                                              control={<Radio />}
                                              label="3"
                                            />
                                            <FormControlLabel
                                              value="4"
                                              control={<Radio />}
                                              label="4"
                                            />
                                            <FormControlLabel
                                              value="5"
                                              control={<Radio />}
                                              label="5"
                                            />
                                            <FormControlLabel
                                              value="6"
                                              control={<Radio />}
                                              label="6"
                                            />
                                            <FormControlLabel
                                              value="7"
                                              control={<Radio />}
                                              label="7"
                                            />
                                            <FormControlLabel
                                              value="8"
                                              control={<Radio />}
                                              label="8"
                                            />
                                            <FormControlLabel
                                              value="9"
                                              control={<Radio />}
                                              label="9"
                                            />
                                            <FormControlLabel
                                              value="10"
                                              control={<Radio />}
                                              label="10"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Stack>

                                <Stack direction="row" spacing={1}>
                                  <Grid container display="flex">
                                    <Grid item md={2} sx={{ pr: "1rem" }}>
                                      <div className="">
                                        <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                        Camp Team:
                                        </Box>
                                      </div>
                                    </Grid>
                                    <Grid item md={10} sx={{ pl: "2rem" }}>
                                      <ThemeProvider theme={theme1}>
                                        <div className="">
                                          <FormControl>
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                            <RadioGroup
                                              row
                                              aria-labelledby="demo-row-radio-buttons-group-label"
                                              name="setPerformance"
                                              value={item.setPerformance}
                                            >
                                              {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                              <FormControlLabel
                                                value="1"
                                                control={<Radio />}
                                                label="1"
                                              />
                                              <FormControlLabel
                                                value="2"
                                                control={<Radio />}
                                                label="2"
                                              />
                                              <FormControlLabel
                                                value="3"
                                                control={<Radio />}
                                                label="3"
                                              />
                                              <FormControlLabel
                                                value="4"
                                                control={<Radio />}
                                                label="4"
                                              />
                                              <FormControlLabel
                                                value="5"
                                                control={<Radio />}
                                                label="5"
                                              />
                                              <FormControlLabel
                                                value="6"
                                                control={<Radio />}
                                                label="6"
                                              />
                                              <FormControlLabel
                                                value="7"
                                                control={<Radio />}
                                                label="7"
                                              />
                                              <FormControlLabel
                                                value="8"
                                                control={<Radio />}
                                                label="8"
                                              />
                                              <FormControlLabel
                                                value="9"
                                                control={<Radio />}
                                                label="9"
                                              />
                                              <FormControlLabel
                                                value="10"
                                                control={<Radio />}
                                                label="10"
                                              />
                                            </RadioGroup>
                                          </FormControl>
                                        </div>
                                      </ThemeProvider>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </div>
                            {/* </Grid> */}
                          </ThemeProvider>

                          <Box
                            sx={{
                              ml: "2rem",
                              mt: "1.5rem",
                              mb: "0.5rem",
                              width: "100%",
                            }}
                          >
                            <TextField
                              id="outlined-multiline-static"
                              label="Comment:"
                              // multiline
                              rows={3}
                              sx={{ width: "71%", backgroundColor: "#fff" }}
                              name="preComments"
                              value={item.preComments}
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>

                          <Grid>
                            <h3>
                              <Box
                                sx={{
                                  ml: "2rem",
                                  mt: "2rem",
                                  color: "#379592",
                                }}
                              >
                                Post Camp Rating
                              </Box>
                            </h3>
                          </Grid>

                          <ThemeProvider theme={theme}>
                            {/* <Grid container display="flex" sx={{ mt: "1rem" }}> */}
                              <div>
                                <div className="goal-title">
                                  <Box sx={{ ml: "2rem" }}>Importance:</Box>
                                </div>
                                <Stack direction="row" spacing={1}>
                                  <Grid container display="flex">
                                    <Grid item md={2} sx={{ pr: "1rem" }}>
                                      <div className="">
                                        <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                          Caregiver:
                                        </Box>
                                      </div>
                                    </Grid>
                                    <Grid item md={10} sx={{ pl: "2rem" }}>
                                      <div className="">
                                        <FormControl>
                                          {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                          <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="lastday_importance"
                                            value={item.lastday_importance}
                                          >
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                            <FormControlLabel
                                              value="1"
                                              control={<Radio />}
                                              label="1"
                                            />
                                            <FormControlLabel
                                              value="2"
                                              control={<Radio />}
                                              label="2"
                                            />
                                            <FormControlLabel
                                              value="3"
                                              control={<Radio />}
                                              label="3"
                                            />
                                            <FormControlLabel
                                              value="4"
                                              control={<Radio />}
                                              label="4"
                                            />
                                            <FormControlLabel
                                              value="5"
                                              control={<Radio />}
                                              label="5"
                                            />
                                            <FormControlLabel
                                              value="6"
                                              control={<Radio />}
                                              label="6"
                                            />
                                            <FormControlLabel
                                              value="7"
                                              control={<Radio />}
                                              label="7"
                                            />
                                            <FormControlLabel
                                              value="8"
                                              control={<Radio />}
                                              label="8"
                                            />
                                            <FormControlLabel
                                              value="9"
                                              control={<Radio />}
                                              label="9"
                                            />
                                            <FormControlLabel
                                              value="10"
                                              control={<Radio />}
                                              label="10"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Stack>

                                <Stack direction="row" spacing={1}>
                                  {/* <div  className="parentgoal"> */}
                                  <Grid container display="flex">
                                    <Grid item md={2} sx={{ pr: "1rem" }}>
                                      <div className="">
                                        <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                        Camp Team:
                                        </Box>
                                      </div>
                                    </Grid>
                                    <Grid item md={10} sx={{ pl: "2rem" }}>
                                      <ThemeProvider theme={theme1}>
                                        <div className="">
                                          <FormControl>
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                            <RadioGroup
                                              row
                                              aria-labelledby="demo-row-radio-buttons-group-label"
                                              name="achImp"
                                              value={item.achImp}
                                            >
                                              {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                              <FormControlLabel
                                                value="1"
                                                control={<Radio />}
                                                label="1"
                                              />
                                              <FormControlLabel
                                                value="2"
                                                control={<Radio />}
                                                label="2"
                                              />
                                              <FormControlLabel
                                                value="3"
                                                control={<Radio />}
                                                label="3"
                                              />
                                              <FormControlLabel
                                                value="4"
                                                control={<Radio />}
                                                label="4"
                                              />
                                              <FormControlLabel
                                                value="5"
                                                control={<Radio />}
                                                label="5"
                                              />
                                              <FormControlLabel
                                                value="6"
                                                control={<Radio />}
                                                label="6"
                                              />
                                              <FormControlLabel
                                                value="7"
                                                control={<Radio />}
                                                label="7"
                                              />
                                              <FormControlLabel
                                                value="8"
                                                control={<Radio />}
                                                label="8"
                                              />
                                              <FormControlLabel
                                                value="9"
                                                control={<Radio />}
                                                label="9"
                                              />
                                              <FormControlLabel
                                                value="10"
                                                control={<Radio />}
                                                label="10"
                                              />
                                            </RadioGroup>
                                          </FormControl>
                                        </div>
                                      </ThemeProvider>
                                    </Grid>
                                  </Grid>
                                  {/* </div> */}
                                </Stack>
                              </div>
                              <div>
                                <div className="goal-title">
                                  <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                    Satisfaction:
                                  </Box>
                                </div>
                                <Stack direction="row" spacing={1}>
                                  <Grid container display="flex">
                                    <Grid item md={2} sx={{ pr: "1rem" }}>
                                      <div className="">
                                        <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                          Caregiver:
                                        </Box>
                                      </div>
                                    </Grid>
                                    <Grid item md={10} sx={{ pl: "2rem" }}>
                                      <div className="">
                                        <FormControl>
                                          {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                          <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="lastday_satisfaction"
                                            value={item.lastday_satisfaction}
                                          >
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                            <FormControlLabel
                                              value="1"
                                              control={<Radio />}
                                              label="1"
                                            />
                                            <FormControlLabel
                                              value="2"
                                              control={<Radio />}
                                              label="2"
                                            />
                                            <FormControlLabel
                                              value="3"
                                              control={<Radio />}
                                              label="3"
                                            />
                                            <FormControlLabel
                                              value="4"
                                              control={<Radio />}
                                              label="4"
                                            />
                                            <FormControlLabel
                                              value="5"
                                              control={<Radio />}
                                              label="5"
                                            />
                                            <FormControlLabel
                                              value="6"
                                              control={<Radio />}
                                              label="6"
                                            />
                                            <FormControlLabel
                                              value="7"
                                              control={<Radio />}
                                              label="7"
                                            />
                                            <FormControlLabel
                                              value="8"
                                              control={<Radio />}
                                              label="8"
                                            />
                                            <FormControlLabel
                                              value="9"
                                              control={<Radio />}
                                              label="9"
                                            />
                                            <FormControlLabel
                                              value="10"
                                              control={<Radio />}
                                              label="10"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <Grid container display="flex">
                                    <Grid item md={2} sx={{ pr: "1rem" }}>
                                      <div className="">
                                        <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                        Camp Team:
                                        </Box>
                                      </div>
                                    </Grid>
                                    <Grid item md={10} sx={{ pl: "2rem" }}>
                                      <ThemeProvider theme={theme1}>
                                        <div className="">
                                          <FormControl>
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                            <RadioGroup
                                              row
                                              aria-labelledby="demo-row-radio-buttons-group-label"
                                              name="achStasify"
                                              value={item.achStasify}
                                            >
                                              {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                              <FormControlLabel
                                                value="1"
                                                control={<Radio />}
                                                label="1"
                                              />
                                              <FormControlLabel
                                                value="2"
                                                control={<Radio />}
                                                label="2"
                                              />
                                              <FormControlLabel
                                                value="3"
                                                control={<Radio />}
                                                label="3"
                                              />
                                              <FormControlLabel
                                                value="4"
                                                control={<Radio />}
                                                label="4"
                                              />
                                              <FormControlLabel
                                                value="5"
                                                control={<Radio />}
                                                label="5"
                                              />
                                              <FormControlLabel
                                                value="6"
                                                control={<Radio />}
                                                label="6"
                                              />
                                              <FormControlLabel
                                                value="7"
                                                control={<Radio />}
                                                label="7"
                                              />
                                              <FormControlLabel
                                                value="8"
                                                control={<Radio />}
                                                label="8"
                                              />
                                              <FormControlLabel
                                                value="9"
                                                control={<Radio />}
                                                label="9"
                                              />
                                              <FormControlLabel
                                                value="10"
                                                control={<Radio />}
                                                label="10"
                                              />
                                            </RadioGroup>
                                          </FormControl>
                                        </div>
                                      </ThemeProvider>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </div>

                              <div>
                                <div className="goal-title">
                                  <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                    Performance:
                                  </Box>
                                </div>
                                <Stack direction="row" spacing={1}>
                                  <Grid container display="flex">
                                    <Grid item md={2} sx={{ pr: "1rem" }}>
                                      <div className="">
                                        <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                          Caregiver:
                                        </Box>
                                      </div>
                                    </Grid>
                                    <Grid item md={10} sx={{ pl: "2rem" }}>
                                      <div className="">
                                        <FormControl>
                                          {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                          <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="lastday_performance"
                                            value={item.lastday_performance}
                                          >
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                            <FormControlLabel
                                              value="1"
                                              control={<Radio />}
                                              label="1"
                                            />
                                            <FormControlLabel
                                              value="2"
                                              control={<Radio />}
                                              label="2"
                                            />
                                            <FormControlLabel
                                              value="3"
                                              control={<Radio />}
                                              label="3"
                                            />
                                            <FormControlLabel
                                              value="4"
                                              control={<Radio />}
                                              label="4"
                                            />
                                            <FormControlLabel
                                              value="5"
                                              control={<Radio />}
                                              label="5"
                                            />
                                            <FormControlLabel
                                              value="6"
                                              control={<Radio />}
                                              label="6"
                                            />
                                            <FormControlLabel
                                              value="7"
                                              control={<Radio />}
                                              label="7"
                                            />
                                            <FormControlLabel
                                              value="8"
                                              control={<Radio />}
                                              label="8"
                                            />
                                            <FormControlLabel
                                              value="9"
                                              control={<Radio />}
                                              label="9"
                                            />
                                            <FormControlLabel
                                              value="10"
                                              control={<Radio />}
                                              label="10"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <Grid container display="flex">
                                    <Grid item md={2} sx={{ pr: "1rem" }}>
                                      <div className="">
                                        <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                        Camp Team:
                                        </Box>
                                      </div>
                                    </Grid>
                                    <Grid item md={10} sx={{ pl: "2rem" }}>
                                      <ThemeProvider theme={theme1}>
                                        <div className="">
                                          <FormControl>
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                            <RadioGroup
                                              row
                                              aria-labelledby="demo-row-radio-buttons-group-label"
                                              name="achPerformance"
                                              value={item.achPerformance}
                                            >
                                              {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                              <FormControlLabel
                                                value="1"
                                                control={<Radio />}
                                                label="1"
                                              />
                                              <FormControlLabel
                                                value="2"
                                                control={<Radio />}
                                                label="2"
                                              />
                                              <FormControlLabel
                                                value="3"
                                                control={<Radio />}
                                                label="3"
                                              />
                                              <FormControlLabel
                                                value="4"
                                                control={<Radio />}
                                                label="4"
                                              />
                                              <FormControlLabel
                                                value="5"
                                                control={<Radio />}
                                                label="5"
                                              />
                                              <FormControlLabel
                                                value="6"
                                                control={<Radio />}
                                                label="6"
                                              />
                                              <FormControlLabel
                                                value="7"
                                                control={<Radio />}
                                                label="7"
                                              />
                                              <FormControlLabel
                                                value="8"
                                                control={<Radio />}
                                                label="8"
                                              />
                                              <FormControlLabel
                                                value="9"
                                                control={<Radio />}
                                                label="9"
                                              />
                                              <FormControlLabel
                                                value="10"
                                                control={<Radio />}
                                                label="10"
                                              />
                                            </RadioGroup>
                                          </FormControl>
                                        </div>
                                      </ThemeProvider>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </div>
                            {/* </Grid> */}
                          </ThemeProvider>

                          <Box
                            sx={{
                              ml: "2rem",
                              mt: "1.5rem",
                              mb: "1.5rem",
                              width: "100%",
                            }}
                          >
                            <TextField
                              id="outlined-multiline-static"
                              label="Comment:"
                              // multiline
                              rows={3}
                              sx={{ width: "71%", backgroundColor: "#fff" }}
                              name="postComments"
                              value={item.postComments}
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>
                        </Card>
                      </div>
                    </Grid>
                  </AccordionDetails>
                </div>
              </Grid>
            ))}
            <form>
              <Grid my={4} className="medium">
                Overall Strengths:
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  id="outlined-multiline-static"
                  variant="outlined"
                  multiline
                  rows={4}
                  name="overall_strength"
                  value={formData.overall_strength.replaceAll('|','\n')}
                   
                >
                  {formData.overall_strength.split('|').map((item,ind)=>(
                  <Box >
                    {item}
                  </Box>
                ))}
                </TextField>
                {/* {formData.overall_strength.split('|')}
                {formData.overall_strength.split('|').length} */}
                {/* <Box sx={{border:1,p:'1rem'}}>
                {formData.overall_strength.split('|').map((item,ind)=>(
                  <Box >
                    {item}
                  </Box>
                ))}
                </Box> */}
                
              </Grid>
              <Grid className="medium">
                Overall Areas for Improvement:
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  variant="outlined"
                  name="overall_improvement"
                  value={formData.overall_improvement.replaceAll('|','\n')}
                ></TextField>
              </Grid>
              <Grid my={3} className="medium">
                Strategies Used on Camp and Recommendations for Future Learning:
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  variant="outlined"
                  name="overall_strategies"
                  value={formData.overall_strategies.replaceAll('|','\n')}
                ></TextField>
              </Grid>

              <Grid display="row" className="medium">
                If you have any questions about this summary or any of the
                strategies identified, please don't hesitate to contact us.
              </Grid>
              <Grid my={3} display="column" md={12} className="medium">
                Warm regards,
                <TextField
                  id="standard-basic"
                  rows={1}
                  size="small"
                  value={
                    localStorage.getItem("userName").charAt(0).toUpperCase() +
                    localStorage.getItem("userName").slice(1).toLowerCase()
                  }
                ></TextField>
              </Grid>
              <Grid display="row" className="medium">
                E:
                <a href="mailto:contact@calmandconnected.com.au">
                  contact@calmandconnected.com.au
                </a>
                <br></br>
                W:
                <a href="https://calmandconnected.com.au" target="_blank">
                  calmandconnected.com.au
                </a>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
