import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
  Stack,
} from "@mui/material";
import Card from "@mui/material/Card";
import { Link, useHistory, useParams } from "react-router-dom";
import { apiService, apiService2 } from "../../Services/apiService";
import { setCampData } from "../../Redux/slices/campSettingSlice";
import { useDispatch } from "react-redux";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import {
  INVOICE_REPORTS_DOWNLOAD,
  US8_URL,
  SERVER_URL,
} from "../../Services/apiEndpints";

// const cards = [
//   {
//     title: "   Participants Self and plan managed",
//   },
//   {
//     title: " Participants Agency managed",
//   },
//   {
//     title: " Support Staff Self Managed",
//   },
// ];

export default function InvoiceReport() {
  const history = useHistory();
  let { camp_id } = useParams();

  const handleDownload = async (repType) => {
    let gettran = {
      _methodId: "63",
      userId: localStorage.getItem("userId"),
      campId: camp_id,
      repType: repType,
    };

    let apiInfo = {
      url: `${SERVER_URL}${INVOICE_REPORTS_DOWNLOAD}`,
      method: "POST",
      postData: gettran,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let output = await apiService2(apiInfo);
    const invoicedownload = output.data.responseData[0];
    console.log("invoicedownload", invoicedownload);

    // Create an anchor element to download the file programmatically
    const link = document.createElement("a");
    link.href = invoicedownload && invoicedownload.reportPath;
    link.download = "filename.xlsx"; // Set the desired file name (if applicable)
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <>
      <Stack direction="row" sx={{ mt: "0.75rem" }}>
        <Box
          alignSelf="center"
          component="img"
          sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
          alt="Logo"
          src={ArrowBack}
          onClick={() => history.goBack()}
        ></Box>
      </Stack>

      {/* {cards.map((card, index) => ( */}
      <Box>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#F8F8F8",

            color: "#707070",
            my: "0.75rem",
            border: 1,
            borderColor: "#379592",
            borderRadius: "0.75rem",
            py: "0rem",
            maxHeight: 60,
          }}
          onClick={() => handleDownload("Plan")}
        >
          <CardContent>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Box mx="0.75rem" sx={{ color: "#379592" }}>
                    Participants Self and Plan Managed
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      {/* ))} */}
      <Box>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#F8F8F8",

            color: "#707070",
            my: "0.75rem",
            border: 1,
            borderColor: "#379592",
            borderRadius: "0.75rem",
            py: "0rem",
            maxHeight: 60,
          }}
          onClick={() => handleDownload("Agency")}
        >
          <CardContent>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Box mx="0.75rem" sx={{ color: "#379592" }}>
                    Participants Agency Managed
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#F8F8F8",

            color: "#707070",
            my: "0.75rem",
            border: 1,
            borderColor: "#379592",
            borderRadius: "0.75rem",
            py: "0rem",
            maxHeight: 60,
          }}
          onClick={() => handleDownload("SupportStaff")}
        >
          <CardContent>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Box mx="0.75rem" sx={{ color: "#379592" }}>
                    Support Staff Self Managed
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
