import React from "react";
import Box from "@mui/material/Box";
import "../../index.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button/Button";
import { Link, useParams, useHistory } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, alpha } from "@mui/material/styles";
import GreenPdf from "../../Assets/Icons/Icons/GreenPdf.png";
import { apiService, apiService2 } from "../../Services/apiService";
import Logo from "../../Assets/Icons/Icons/Logo-Main.png";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";
import GreenCheck2 from "../../Assets/Icons/Icons/GreenCheck2.png";
import { ContactSupportOutlined } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { US8_URL , SERVER_URL} from '../../Services/apiEndpints'
import html2pdf from 'html2pdf.js';
import InputAdornment from '@mui/material/InputAdornment';

const dummy = {
    applicationId: "",
    childId: "",
    parentId: "",
    servicesFor: "",
    childDOB: "",
    invoiceNumber: "",
    invoiceDate: "",
    dueDate: "",
    particpantsNo: "",
    isssuedTo: "",
    total: "",
    servicesList: [],
    paymentRefno: ""
}
export default function GenerateInvoice() {
    let { campId, applicantId } = useParams();
    const [invoiceInfo, setInvoiceInfo] = React.useState('')
    const [list, setList] = React.useState([])
    const [serviceList, setServiceList] = React.useState([])
    const [readData, setReadData] = React.useState(dummy)
    const [invoiceOpen, setinvoiceOpen] = React.useState(false);
    const [change, setChange] = React.useState(false)
    
    const [fundingRemarks, setFundingRemarks] = React.useState('')
    const [submitStatus, setSubmitStatus] = React.useState(false)

    let history = useHistory()
    const invoiceHandleClose = () => {
        setinvoiceOpen(false);
        setFundingRemarks('')
    }
   
{console.log(submitStatus)}
    const listArray1 = [
        { title: 'Services for', name: 'Rosey Michel' },
        { title: 'DOB', name: '05 Jun 2010' },
        { title: 'Participant No', name: '1234567' },
        { title: 'Issued to', name: 'Jessica Michel' }
    ]
    const listArray2 = [
        { title: 'Invoice Date', name: '09 Jun 2010' },
        { title: 'Invoice Number', name: 'INV-215' },
        { title: 'Due date', name: '23 Jun 2010' },
        { title: 'ABN', name: '22 615 2679 11' }
    ]
    const tableArray = [
        {
            serviceBy: 'Kate Keisler Occupational',
            role: 'Therapist',
            service: 'Therapy Intensive Camp - Camp Out',
            serviceDate: '12-Apr-2023',
            time: '12:00',
            rate: '$50.00',
            cost: '$600.00'
        },
        {
            serviceBy: 'Kate Keisler Occupational',
            role: 'Therapist',
            service: 'Therapy Intensive Camp - Camp Out',
            serviceDate: '12-Apr-2023',
            time: '12:00',
            rate: '$50.00',
            cost: '$600.00'
        },

    ]
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },

        // hide last border

        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: theme.palette.common.black,

            backgroundColor: "rgba(55, 149, 146, 1)",

            color: theme.palette.common.white,
        },

        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 700,
        bgcolor: "background.paper",
        // border: '2px solid #000',
        boxShadow: 24,
        px: 4,
        py: 2,
        borderRadius: '25px'
      };
      const onChangeFundingRemarks = (event) => {
        setFundingRemarks(event.target.value);
    
      };
    React.useEffect(() => {

        (
            async () => {

                let dataSent = {
                    _methodId: 51,
                    userId: localStorage.getItem('userId'),
                    // campId: 50001,
                    // applicationId: 300005
                    campId: campId,
                    applicationId: applicantId
                }

                let apiInfo = {
                    // url: "https://cncdevapi.natsoft.io/api/us8",
                    url: `${SERVER_URL}${US8_URL}`,
                    method: "POST",
                    postData: dataSent,
                    headers: {
                        "Content-Type": "application/json",
                        tokenheaderkey: localStorage.getItem("token"),
                    },

                }

                let output = await apiService2(apiInfo)
                // console.log(output.data.responseData[0])
                let invoiceData = output.data.responseData[0][0]
                setInvoiceInfo(output.data.responseData[0][0])
                console.log(output.data.responseData[0][0], 'lolllll')
                if (output?.data?.responseData[0][0]?.servicesList !== []) {
                    
                    // console.log(output?.data?.responseData[0][0]?.servicesList)
                    let dateFormatChange = JSON.parse(output?.data?.responseData[0][0]?.servicesList)
                    // dateFormatChange.forEach((item) => item.ServiceDate = conversion(item.ServiceDate))
                    
                    // console.log('readydateeeee',dateFormatChange)
                    setList(dateFormatChange)
                    setReadData({
                        ...readData,
    
                        applicationId: output?.data?.responseData[0][0]?.applicationId,
                        childId: output?.data?.responseData[0][0]?.childId,
                        parentId: output?.data?.responseData[0][0]?.parentId,
                        servicesFor: output?.data?.responseData[0][0]?.servicesFor,
                        childDOB: output?.data?.responseData[0][0]?.childDOB,
                        invoiceNumber: output?.data?.responseData[0][0]?.invoiceNumber,
                        invoiceDate: conversion(output?.data?.responseData[0][0]?.invoiceDate),
                        dueDate: conversion(output?.data?.responseData[0][0]?.dueDate),
                        particpantsNo: output?.data?.responseData[0][0]?.particpantsNo,
                        isssuedTo: output?.data?.responseData[0][0]?.isssuedTo,
                        total: output?.data?.responseData[0][0]?.total,
                        servicesList: dateFormatChange,
                        paymentRefno: output?.data?.responseData[0][0]?.paymentRefno
                    })

                    

                }
                else{
                    setReadData({
                        ...readData,
    
                        applicationId: output?.data?.responseData[0][0]?.applicationId,
                        childId: output?.data?.responseData[0][0]?.childId,
                        parentId: output?.data?.responseData[0][0]?.parentId,
                        servicesFor: output?.data?.responseData[0][0]?.servicesFor,
                        childDOB: output?.data?.responseData[0][0]?.childDOB,
                        invoiceNumber: output?.data?.responseData[0][0]?.invoiceNumber,
                        invoiceDate: conversion(output?.data?.responseData[0][0]?.invoiceDate),
                        dueDate: conversion(output?.data?.responseData[0][0]?.dueDate),
                        particpantsNo: output?.data?.responseData[0][0]?.particpantsNo,
                        isssuedTo: output?.data?.responseData[0][0]?.isssuedTo,
                        total: output?.data?.responseData[0][0]?.total,
                        servicesList: output?.data?.responseData[0][0]?.servicesList,
                        paymentRefno: output?.data?.responseData[0][0]?.paymentRefno
                    }) 
                }
                
                
                
            }
        )()


    }

        , [])
    console.log(readData, 'herreee',list)

    function storeInfo(event) {
        const { name, value } = event.target;
        console.log("name,value", name, value);

        setReadData((prev) => ({
            ...prev,
            [name]: value,
        }));
    }
    function storeList(event, index) {

        const { name, value } = event.target;
        console.log("name,value", name, value, index);


        list.forEach((item, i) => {
            if (i === index) {
                if (item[name] === 'ServiceDate') {
                    item[name] = conversion(value)
                }
                else {
                    item[name] = value
                }

            }
        });

        // setReadData((prev)=>({...prev, servicesList: list }))
        setReadData({ ...readData, servicesList: list })
    }
    
const handleSwal = async () => {
    setinvoiceOpen(true)
    setSubmitStatus(true)
}

const finalSubmit = () =>{
    if (localStorage.getItem('invoiceStatus') === 'NA'){


                Swal.fire({
                    iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
                    text: "Are you sure you want to Submit?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    confirmButtonColor: "#379592",
                    cancelButtonText: "No",
                    cancelButtonColor: "#909090",
                    customClass: {
                        container: "my-swal",
                        icon: 'no-border'


                    },
                }).then((result) => {
                   

                    if (result.isConfirmed) {
                        (async () => {
                            let sendData = {
                                _methodId: 53,
                                userId: localStorage.getItem('userId'),
                                campId: campId,
                                applicationId: readData.applicationId,
                                childId: readData.childId,
                                parentId: readData.parentId,
                                servicesFor: readData.servicesFor,
                                childDOB: conversion(readData.childDOB),
                                invoiceNumber: readData.invoiceNumber,
                                invoiceDate: (readData.invoiceDate),
                                dueDate: (readData.dueDate),
                                particpantsNo: readData.particpantsNo,
                                isssuedTo: readData.isssuedTo,
                                total: readData.total,
                                invoiceStatus: 'Submitted',
                                servicesList: readData.servicesList,
                                paymentRefno: readData.paymentRefno,
                                remarks:fundingRemarks
                            }
                            let apiInfo = {
                                // url: "https://cncdevapi.natsoft.io/api/us8",
                                url: `${SERVER_URL}${US8_URL}`,
                                method: "POST",
                                postData: sendData,
                                headers: {
                                    "Content-Type": "application/json",
                                    tokenheaderkey: localStorage.getItem("token"),
                                },

                            }

                            let output = await apiService2(apiInfo)
                            let res = output.data.responseData[0]
                            console.log(res)

                            if (output.data.responseData[0][0].Code === '0') {
                                Swal.fire({
                                    iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
                                    text: `${output?.data?.responseData[0][0]?.Desc}`,
                                    confirmButtonText: 'OK',
                                    confirmButtonColor: "#379592",
                                    customClass: {
                                        container: "my-swal",

                                    },
                                })
                                    .then((result) => {
                                        if (result.isConfirmed) {
                                            history.push(`/ChangeSuitability/${campId}/${applicantId}`)
                                        }
                                    }
                                    );
                            }
                            else if (output.data.responseData[0][0].Code !== '0') {
                                Swal.fire({
                                    icon: 'error',
                                    // iconHtml: `<img src="${GreenCheck}" >`, 
                                    text: output?.data?.responseData[0][0]?.Desc,
                                    confirmButtonColor: "#379592",
                                    customClass: {
                                        container: "my-swal",

                                    },
                                })
                                // .then((result) => {
                                //     if (result.isConfirmed) {
                                //         history.push(`/ChangeSuitability/${campId}/${applicantId}`)
                                //     }
                                // }

                                // )
                            }
                        })
                            ()

                    }
                })
    }
    else if (localStorage.getItem('invoiceStatus') !== 'NA'){
        Swal.fire({
            iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
            text: "Are you sure you want to Submit?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#379592",
            cancelButtonText: "No",
            cancelButtonColor: "#909090",
            customClass: {
                container: "my-swal",
                icon: 'no-border'
    
    
            },
        }) .then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    let sendData = {
                        _methodId: 54,
                        userId: localStorage.getItem('userId'),
                        campId: campId,
                        applicationId: readData.applicationId,
                        servicesFor: readData.servicesFor,
                        childDOB: conversion(readData.childDOB),
                        invoiceNumber: readData.invoiceNumber,
                        invoiceDate: (readData.invoiceDate),
                        dueDate: (readData.dueDate),
                        particpantsNo: readData.particpantsNo,
                        isssuedTo: readData.isssuedTo,
                        total: readData.total,
                        invoiceStatus: 'Submitted',
                        servicesList: readData.servicesList,
                        paymentRefno: readData.paymentRefno,
                        remarks:fundingRemarks
                    }
                    console.log(sendData)
                    let apiInfo = {
                        // url: "https://cncdevapi.natsoft.io/api/us8",
                        url: `${SERVER_URL}${US8_URL}`,
                        method: "POST",
                        postData: sendData,
                        headers: {
                            "Content-Type": "application/json",
                            tokenheaderkey: localStorage.getItem("token"),
                        },
    
                    }
    
                    let output = await apiService2(apiInfo)
                    let res = output.data.responseData[0]
                    console.log(res)
    
                    if (output.data.responseData[0][0].Code === '0') {
                        Swal.fire({
                            iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
                            text: `${output?.data?.responseData[0][0]?.Desc} 
                           `,
                            confirmButtonText: 'OK',
                            confirmButtonColor: "#379592",
                            customClass: {
                                container: "my-swal",
    
                            },
                        })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    history.push(`/ChangeSuitability/${campId}/${applicantId}`)
                                   
                                }
                            }
                            );
                    }
                    else if (output.data.responseData[0][0].Code !== '0') {
                        Swal.fire({
                            icon: 'error',
                            // iconHtml: `<img src="${GreenCheck}" >`, 
                            text: output?.data?.Desc,
                            confirmButtonColor: "#379592",
                            customClass: {
                                container: "my-swal",
    
                            },
                        })
                     
                    }
                })
                    ()
    
            }
        })
    }
    

    
}

    const handleSave = async (status) => {
        console.log(status)
        console.log(localStorage.getItem('invoiceStatus'))
        if (status === 'Submitted') { 
            
            if (localStorage.getItem('invoiceStatus') === 'NA') {
                // let sendData = {
                //     _methodId: 53,
                //     userId: localStorage.getItem('userId'),
                //     campId: campId,
                //     applicationId: readData.applicationId,
                //     childId: readData.childId,
                //     parentId: readData.parentId,
                //     servicesFor: readData.servicesFor,
                //     childDOB: conversion(readData.childDOB),
                //     invoiceNumber: readData.invoiceNumber,
                //     invoiceDate: (readData.invoiceDate),
                //     dueDate: (readData.dueDate),
                //     particpantsNo: readData.particpantsNo,
                //     isssuedTo: readData.isssuedTo,
                //     total: readData.total,
                //     invoiceStatus: status,
                //     servicesList: readData.servicesList,
                //     paymentRefno: readData.paymentRefno
                // }
                // console.log('sendddddd', sendData)

                // Swal.fire({
                //     iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
                //     text: "Are you sure you want to Submit?",
                //     showCancelButton: true,
                //     confirmButtonText: "Yes",
                //     confirmButtonColor: "#379592",
                //     cancelButtonText: "No",
                //     cancelButtonColor: "#909090",
                //     customClass: {
                //         container: "my-swal",
                //         icon: 'no-border'


                //     },
                // }).then((result) => {
                   

                //     if (result.isConfirmed) {
                //         (async () => {
                //             let sendData = {
                //                 _methodId: 53,
                //                 userId: localStorage.getItem('userId'),
                //                 campId: campId,
                //                 applicationId: readData.applicationId,
                //                 childId: readData.childId,
                //                 parentId: readData.parentId,
                //                 servicesFor: readData.servicesFor,
                //                 childDOB: conversion(readData.childDOB),
                //                 invoiceNumber: readData.invoiceNumber,
                //                 invoiceDate: (readData.invoiceDate),
                //                 dueDate: (readData.dueDate),
                //                 particpantsNo: readData.particpantsNo,
                //                 isssuedTo: readData.isssuedTo,
                //                 total: readData.total,
                //                 invoiceStatus: status,
                //                 servicesList: readData.servicesList,
                //                 paymentRefno: readData.paymentRefno
                //             }
                //             let apiInfo = {
                //                 url: "https://cncdevapi.natsoft.io/api/us8",
                //                 method: "POST",
                //                 postData: sendData,
                //                 headers: {
                //                     "Content-Type": "application/json",
                //                     tokenheaderkey: localStorage.getItem("token"),
                //                 },

                //             }

                //             let output = await apiService2(apiInfo)
                //             let res = output.data.responseData[0]
                //             console.log(res)

                //             if (output.data.responseData[0][0].Code === '0') {
                //                 Swal.fire({
                //                     iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
                //                     text: `${output?.data?.responseData[0][0]?.Desc} and
                //                    Kindly update the status`,
                //                     confirmButtonText: 'OK',
                //                     confirmButtonColor: "#379592",
                //                     customClass: {
                //                         container: "my-swal",

                //                     },
                //                 })
                //                     .then((result) => {
                //                         if (result.isConfirmed) {
                //                             history.push(`/ChangeSuitability/${campId}/${applicantId}`)
                //                         }
                //                     }
                //                     );
                //             }
                //             else if (output.data.responseData[0][0].Code !== '0') {
                //                 Swal.fire({
                //                     icon: 'error',
                //                     // iconHtml: `<img src="${GreenCheck}" >`, 
                //                     text: output?.data?.responseData[0][0]?.Desc,
                //                     confirmButtonColor: "#379592",
                //                 })
                //                 // .then((result) => {
                //                 //     if (result.isConfirmed) {
                //                 //         history.push(`/ChangeSuitability/${campId}/${applicantId}`)
                //                 //     }
                //                 // }

                //                 // )
                //             }
                //         })
                //             ()

                //     }
                // })
            }
            else if (localStorage.getItem('invoiceStatus') !== 'NA') {

                let sendData = {
                    _methodId: 54,
                    userId: localStorage.getItem('userId'),
                    campId: campId,
                    applicationId: readData.applicationId,
                    servicesFor: readData.servicesFor,
                    childDOB: conversion(readData.childDOB),
                    invoiceNumber: readData.invoiceNumber,
                    invoiceDate: (readData.invoiceDate),
                    dueDate: (readData.dueDate),
                    particpantsNo: readData.particpantsNo,
                    isssuedTo: readData.isssuedTo,
                    total: readData.total,
                    invoiceStatus: status,
                    servicesList: readData.servicesList,
                    paymentRefno: readData.paymentRefno
                }
                console.log('sendddddd', sendData)

                
                    Swal.fire({
                        iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
                        text: "Are you sure you want to Submit?",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        confirmButtonColor: "#379592",
                        cancelButtonText: "No",
                        cancelButtonColor: "#909090",
                        customClass: {
                            container: "my-swal",
                            icon: 'no-border'
    
    
                        },
                    })
                
               
                .then((result) => {
        

                    if (result.isConfirmed) {
                        
                        
                        // (async () => {
                        //     let sendData = {
                        //         _methodId: 54,
                        //         userId: localStorage.getItem('userId'),
                        //         campId: campId,
                        //         applicationId: readData.applicationId,
                        //         servicesFor: readData.servicesFor,
                        //         childDOB: conversion(readData.childDOB),
                        //         invoiceNumber: readData.invoiceNumber,
                        //         invoiceDate: (readData.invoiceDate),
                        //         dueDate: (readData.dueDate),
                        //         particpantsNo: readData.particpantsNo,
                        //         isssuedTo: readData.isssuedTo,
                        //         total: readData.total,
                        //         invoiceStatus: status,
                        //         servicesList: readData.servicesList,
                        //         paymentRefno: readData.paymentRefno
                        //     }
                        //     console.log(sendData)
                        //     let apiInfo = {
                        //         url: "https://cncdevapi.natsoft.io/api/us8",
                        //         method: "POST",
                        //         postData: sendData,
                        //         headers: {
                        //             "Content-Type": "application/json",
                        //             tokenheaderkey: localStorage.getItem("token"),
                        //         },

                        //     }

                        //     let output = await apiService2(apiInfo)
                        //     let res = output.data.responseData[0]
                        //     console.log(res)

                        //     if (output.data.responseData[0][0].Code === '0') {
                        //         Swal.fire({
                        //             iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
                        //             text: `${output?.data?.responseData[0][0]?.Desc} 
                        //             Kindly update the status`,
                        //             confirmButtonText: 'OK',
                        //             confirmButtonColor: "#379592",
                        //             customClass: {
                        //                 container: "my-swal",

                        //             },
                        //         })
                        //             .then((result) => {
                        //                 if (result.isConfirmed) {
                        //                     history.push(`/ChangeSuitability/${campId}/${applicantId}`)
                                           
                        //                 }
                        //             }
                        //             );
                        //     }
                        //     else if (output.data.responseData[0][0].Code !== '0') {
                        //         Swal.fire({
                        //             icon: 'error',
                        //             // iconHtml: `<img src="${GreenCheck}" >`, 
                        //             text: output?.data?.Desc,
                        //             confirmButtonColor: "#379592",
                        //         })
                        //         // .then((result) => {
                        //         //     if (result.isConfirmed) {
                        //         //         history.push(`/ChangeSuitability/${campId}/${applicantId}`)
                        //         //     }
                        //         // }

                        //         // );
                        //     }
                        // })
                        //     ()

                    }
                })

            }
        }
        else if (status === 'Draft') {
            if (localStorage.getItem('invoiceStatus') === 'NA') {


                console.log(status)
                console.log('newwwwwwwwwww', list)
                let sendData = {
                    _methodId: 53,
                    userId: localStorage.getItem('userId'),
                    campId: campId,
                    applicationId: readData.applicationId,
                    childId: readData.childId,
                    parentId: readData.parentId,
                    servicesFor: readData.servicesFor,
                    childDOB: conversion(readData.childDOB),
                    invoiceNumber: readData.invoiceNumber,
                    invoiceDate: (readData.invoiceDate),
                    dueDate: (readData.dueDate),
                    particpantsNo: readData.particpantsNo,
                    isssuedTo: readData.isssuedTo,
                    total: readData.total,
                    invoiceStatus: status,
                    servicesList: readData.servicesList,
                    paymentRefno: readData.paymentRefno
                }
                console.log('sendddddd', sendData)
                let apiInfo = {
                    // url: "https://cncdevapi.natsoft.io/api/us8",
                    url: `${SERVER_URL}${US8_URL}`,
                    method: "POST",
                    postData: sendData,
                    headers: {
                        "Content-Type": "application/json",
                        tokenheaderkey: localStorage.getItem("token"),
                    },

                }

                let output = await apiService2(apiInfo)
                console.log(output, 'loooooo')
                let res = output.data.responseData[0]
                console.log(res)
                if (output?.data?.responseData[0][0].Code === '0') {
                    Swal.fire({
                        
                        iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
                        text: output?.data?.responseData[0][0]?.Desc,
                        confirmButtonColor: "#379592",
                        customClass: {
                            container: "my-swal",
                            icon: 'no-border'
    
    
                        },
                    }) .then((result) => {
                        if (result.isConfirmed) {
                            history.push(`/ChangeSuitability/${campId}/${applicantId}`)
                          
                        }
                    }
                    );
                   
                }
                else {
                    Swal.fire({
                        icon: "error",
                        text: output?.data?.responseDesc,
                        confirmButtonText: "OK",
                        confirmButtonColor: "#379592",
                    })
                }
            }
            else if (localStorage.getItem('ndisStatus') !== 'NA') {
                let sendData = {
                    _methodId: 54,
                    userId: localStorage.getItem('userId'),
                    campId: campId,
                    applicationId: readData.applicationId,
                    servicesFor: readData.servicesFor,
                    childDOB: conversion(readData.childDOB),
                    invoiceNumber: readData.invoiceNumber,
                    invoiceDate: (readData.invoiceDate),
                    dueDate: (readData.dueDate),
                    particpantsNo: readData.particpantsNo,
                    isssuedTo: readData.isssuedTo,
                    total: readData.total,
                    invoiceStatus: status,
                    servicesList: readData.servicesList,
                    paymentRefno: readData.paymentRefno
                }
                console.log('sendddddd', sendData)
                let apiInfo = {
                    // url: "https://cncdevapi.natsoft.io/api/us8",
                    url: `${SERVER_URL}${US8_URL}`,
                    method: "POST",
                    postData: sendData,
                    headers: {
                        "Content-Type": "application/json",
                        tokenheaderkey: localStorage.getItem("token"),
                    },

                }

                let output = await apiService2(apiInfo)
                console.log(output, 'loooooo')
                let res = output.data.responseData[0]
                console.log(res)
                if (output?.data?.responseData[0][0].Code === '0') {
                    Swal.fire({
                        
                        iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
                        text: output?.data?.responseData[0][0]?.Desc,
                        confirmButtonColor: "#379592",
                        customClass: {
                            container: "my-swal",
                            icon: 'no-border'
    
    
                        },
                    }) .then((result) => {
                        if (result.isConfirmed) {
                            history.push(`/ChangeSuitability/${campId}/${applicantId}`)
                          
                        }
                    }
                    );
                }
                else {
                    Swal.fire({
                        icon: "error",
                        text: output?.data?.responseDesc,
                        confirmButtonText: "OK",
                        confirmButtonColor: "#379592",
                    })
                }
            }
        }


    }
    const handleFieldChange = (event, index, fieldName) => {
        console.log(list,'what exactlyyyyy')
        console.log(fieldName)
        console.log(event.target.value)
        const updatedTableData = [...list];
        updatedTableData[index][fieldName] = event.target.value;
        let total = 0
        if(fieldName ==='Hours'){

            
        for(let i=0; i <list.length; i++ ){
            let a,b
            if(i==index){
                a= Number(event.target.value)
                b= Number(list[index].Rate)
                updatedTableData[i].Cost = a*b
                console.log(updatedTableData[i].Cost = a*b,'is it updating')
            }
            else{
                a = Number(list[i].Hours)
                b= Number(list[i].Rate)
            }
            total = total + (a*b)
            // total = total+ Number(Number(event.target.value) * list[index].Rate)
           
        }
        console.log(total)
        }
        else{
            total = readData.total
        }
        setList(updatedTableData);
        setReadData({ ...readData, servicesList: list,total : total})
        setChange(prev=>!prev)
       
    };
    console.log(list)
    const conversion = (inputDate) => {

        if (inputDate) {
            var formattedDate = inputDate.replace(/\//g, '-');
            var dateParts = formattedDate.split('-');
            var day = dateParts[0];
            var month = dateParts[1];
            var year = dateParts[2];
            return year + '-' + month + '-' + day;
        }
    }
    // React.useEffect(()=>{
    //     let total = 0
    //     for(let i=0; i <list.length; i++ ){
    //         total = total+ Number(list[i].Cost)
    //         console.log(list[i].Cost)
    //     }
    //     console.log(total)
    // },[change])
    const savePageAsPDF = () => {
        const element = document.getElementById('pageToConvert');
      
       
      
        html2pdf()
          .set({ html2canvas: { scale: 2 } })
          .from(element)
          .outputPdf()
          .then((pdf) => {
            const url = URL.createObjectURL(pdf);
            // Do something with the PDF URL, such as storing it in a state variable or downloading it
            console.log('PDF URL:', url);
          })
          .catch((error) => {
            console.error('Error saving PDF:', error);
          });
      };
    return (
        <Box>

<Box>
            <Grid container display='flex'>
                <Grid item md={5} sm={12}>
                    <Stack direction='row' spacing={2} >
                        <Link to={`/ChangeSuitability/${campId}/${applicantId}`}>
                            <Box
                                mt="0.75rem"
                                alignSelf="flex-start"
                                component="img"
                                sx={{
                                    height: "0.75rem",
                                    width: "0.65rem",
                                    cursor: "pointer",
                                }}
                                height="20"
                                alt="Logo"
                                src={ArrowBack}
                            ></Box>
                        </Link>



                        <Stack direction='column' >
                            <Box
                                component="img"
                                sx={{ height: "50%", width: "40%", my: "1rem" }}
                                alt="Logo"
                                src={Logo}
                            ></Box>
                            <Box sx={{ fontSize: '12px' }} className='gilroy'>Calm and Connected Pty Ltd <br />5C 11 Erade Drive <br /> Piara Waters WA 6112</Box>
                        </Stack>
                    </Stack>
                    <Box></Box>
                </Grid>
                <Grid item md={4} sm={12} sx={{ alignItems: 'center', alignSelf: 'center' }}>
                    <Box alignContent='center' sx={{ fontSize: '1.45rem' }} className='bold'>TAX INVOICES</Box>
                </Grid>
                <Grid container display='flex' justifyContent='space-around' my='1rem' >
                    <Grid item sm={12} md={6}>
                        <List >

                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>Services for</Grid>
                                    <Grid item md={4} className="medium">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            size="small"
                                            name='servicesFor'
                                            value={readData && readData.servicesFor}
                                            onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>DOB</Grid>
                                    <Grid item md={4} className="medium">

                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            size="small"
                                            name='childDOB'
                                            value={readData && readData.childDOB}
                                            onChange={(event) => storeInfo(event)}
                                        />

                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>Participant No</Grid>
                                    <Grid item md={4} className="medium">
                                            
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            size="small"
                                            name='particpantsNo'
                                            value={(readData && readData.particpantsNo === '') ? ('NA') : (readData && readData.particpantsNo) }
                                            onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>Issued To</Grid>
                                    <Grid item md={4} className="medium">

                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            multiline
                                            size="small"
                                            name='isssuedTo'
                                            value={readData && readData.isssuedTo}
                                            onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>


                        </List>
                    </Grid>
                    <Grid item sm={12} md={6}  >
                        <List >

                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>Invoice Date</Grid>
                                    <Grid item md={4} className="medium">

                                        {/* <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            size="small"
                                            name='invoiceDate'
                                            value={readData && readData.invoiceDate}
                                            onChange={(event) => storeInfo(event)}
                                        /> */}
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            format="YYYY-MM-DD"
                                            type="date"
                                            size="small"
                                            // sx={{ width: 200, px: '0.25rem' }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="invoiceDate"
                                            // value={ndisInfo && moment(ndisInfo.periodFrom).format("YYYY-MM-DD")}
                                            value={readData && readData.invoiceDate}
                                            onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem >
                                <Grid container alignItems='center' >
                                    <Grid item md={4}>Invoice number</Grid>
                                    <Grid item md={4} className="medium">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            sx={{ width: 165 }}
                                            size="small"
                                            name='invoiceNumber'
                                            value={readData && readData.invoiceNumber}
                                            onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>Due Date</Grid>
                                    <Grid item md={4} className="medium">

                                        {/* <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            size="small"
                                            name='dueDate'
                                            value={readData && readData.dueDate}
                                            onChange={(event) => storeInfo(event)}
                                        /> */}
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            format="YYYY-MM-DD"
                                            type="date"
                                            size="small"
                                            // sx={{ width: 200, px: '0.25rem' }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="dueDate"
                                            // value={ndisInfo && moment(ndisInfo.periodFrom).format("YYYY-MM-DD")}
                                            value={readData && readData.dueDate}
                                            onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>ABN</Grid>
                                    <Grid item md={4} className="medium">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            sx={{ width: 165 }}
                                            size="small"
                                            name='dueDate'
                                            value='22 613 026 430'
                                            onChange={(event) => storeInfo(event)}
                                        />

                                    </Grid>
                                </Grid>
                            </ListItem>


                        </List>
                    </Grid>
                </Grid>
            </Grid>

            <TableContainer component={Paper} sx={{ my: "2rem", mt: "5rem" }}>
                <Table sx={{ minWidth: 450 }} aria-label="customized table">
                    <TableHead>

                        <StyledTableRow >
                            <StyledTableCell align="center">Service by</StyledTableCell>
                            <StyledTableCell align="center">Service</StyledTableCell>
                            <StyledTableCell align="center">Service Date</StyledTableCell>
                            <StyledTableCell align="center">Time</StyledTableCell>
                            <StyledTableCell align="center">Rate</StyledTableCell>
                            <StyledTableCell align="center">Cost</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((item, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    "& .MuiTableCell-root": { py: "0.45rem" },
                                }}
                            >
                                <TableCell component="th" scope="row" align="center">
                                    <Stack direction="column"
                                        justifyContent="center"
                                        alignItems="center">
                                        <Box>

                                            <TextField
                                                id={`serviceBy-${index}`}
                                                variant="standard"
                                                name="ServiceBy"
                                                className="txt-grey"
                                                value={item.ServiceBy}
                                                onChange={(event) => handleFieldChange(event, index, 'ServiceBy')}
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                id={`serviceBy-${index}`}
                                                variant="standard"
                                                name="Profession"
                                                className="txt-grey"
                                                value={item.Profession}
                                                onChange={(event) => handleFieldChange(event, index, 'Profession')}
                                            />
                                        </Box>
                                    </Stack>

                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Stack direction="column" alignItems="center" spacing={1}>
                                        <Box>
                                            {/* <TextField
                      id={`service-${index}`}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true
                      }}
                      size="small"
                      name="Service"
                      value={item.Service}
                      onChange={(event) => handleFieldChange(event, index, 'Service')}
                    /> */}
                                            {item.Service}

                                        </Box>
                                        <Box sx={{ color: 'red',textAlign:'center' }}>
                                        {item.itemCode}
                                            {/* <TextField
                                                id={`itemCode-${index}`}
                                                variant="standard"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                size="small"
                                                name="itemCode"
                                                value={item.itemCode}
                                                onChange={(event) => handleFieldChange(event, index, 'itemCode')}
                                            /> */}
                                        </Box>
                                    </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                                            {/* <TextField
                      id={`serviceDate-${index}`}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true
                      }}
                      size="small"
                      name="ServiceDate"
                      value={item.ServiceDate}
                      sx={{ width: '60%' }}
                      onChange={(event) => handleFieldChange(event, index, 'ServiceDate')}
                    /> */}
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                format="YYYY-MM-DD"
                                                type="date"
                                                size="small"
                                                // sx={{ width: 200, px: '0.25rem' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="ServiceDate"
                                                // value={ndisInfo && moment(ndisInfo.periodFrom).format("YYYY-MM-DD")}
                                                value={(item.ServiceDate)}
                                                onChange={(event) => handleFieldChange(event, index, 'ServiceDate')}
                                            />
                                            {/* {item.ServiceDate} */}
                                        </Box>
                                    </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>

                                            <TextField
                                                id={`hours-${index}`}
                                                variant="standard"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                sx={{ width: '70px' }}
                                                size="small"
                                                name="Hours"
                                                value={item.Hours}
                                                onChange={(event) => handleFieldChange(event, index, 'Hours')}
                                            />
                                        </Box>
                                    </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                                            ${item.Rate}
                                        </Box>
                                    </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                                            {/* ${item.Cost} */}
                                            <TextField
                                                id={`hours-${index}`}
                                                variant="standard"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start">$</InputAdornment>
                                                      ),
                                                }}
                                                sx={{ width: '70px' }}
                                                size="small"
                                                name="Cost"
                                                value={Number(item.Hours) * Number(item.Rate)}
                                                // onChange={(event) => handleFieldChange(event, index, 'Cost')}
                                            />
                                        </Box>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid container justifyContent='flex-end'   >

                <Grid item>
                    <Grid container alignItems='center'>
                        <Grid item mx='0.5rem'>Total</Grid>
                        <Grid item >
                            <TextField
                                id="outlined-basic"
                                label="Cost"
                                variant="outlined"
                                 InputProps={{
                                   readOnly: true,
                                startAdornment: (
                                    <InputAdornment position="start">$</InputAdornment>
                                  ),
                                 }}
                                
                                InputLabelProps={{
                                    shrink: true
                                }}
                                size="small"
                                // value={invoiceInfo.total}
                                value={readData && readData.total}
                               
                                sx={{ width: 100 }}
                            />
                           
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <Grid container justifyContent='flex-end'   >
                <Grid item>
                    <Box sx={{ fontSize: '12px' }}>GST included in total amount $0.00</Box>
                </Grid>
            </Grid>

            <Grid>
                <List className='medium'>

                    <ListItem>Please put the following reference for bank transfer: <span className='bold'>{readData && readData.paymentRefno}</span></ListItem>
                    <ListItem >Payment Details:</ListItem>
                    <ListItem  >Account Name: <span  className='bold'>Calm and Connected</span> </ListItem>
                    <ListItem>BSB: <span  className='bold'>062692</span></ListItem>
                    <ListItem>Account No:<span className='bold'>77274604</span> </ListItem>
                    <ListItem>Payment is due within 7 days of receiving. Thank you for your prompt payment</ListItem>
                </List>
            </Grid>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Button
                        variant="contained"
                        sx={{
                            px: "2rem",
                            mx: "1rem",
                            bgcolor: "#379592",
                            textTransform: "none",
                            "&:hover": { background: "#379592" },
                        }}
                        className="gilroy"
                        onClick={() => handleSave('Draft')}

                    >
                        Save
                    </Button>
                </Grid>
                <Grid item>
                    {/* <Link className='link' to='/ViewCamp'> */}
                    <Button
                        variant="contained"
                        className="gilroy"

                        sx={{
                            px: "2rem", mx: "1rem", bgcolor: "#379592",
                            textTransform: "none",
                            "&:hover": { background: "#379592" },
                        }}
                        // onClick={() => handleSave('Submitted')}
                        onClick ={()=>{handleSwal()}}
                    >
                        Submit
                    </Button>
                    {/* </Link> */}
                </Grid>
                {/* <Grid item>
                    <Button
                        variant="contained"
                        sx={{
                            px: "2rem",
                            mx: "1rem",
                            bgcolor: "#379592",
                            textTransform: "none",
                            "&:hover": { background: "#379592" },
                        }}
                        className="gilroy"
                        
                        onClick={() => savePageAsPDF()}

                    >
                        Upload
                    </Button>
                </Grid> */}
            </Grid>
        </Box>
                        <Modal
            open={invoiceOpen}
            onClose={invoiceHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Grid
                container
                justifyContent="flex-Start"
                direction="row"
                alignContent="flex-Start"
              >  
              </Grid>
              
              <Box className="gilroy">Add remarks if any</Box>
              
              <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      mt: "0.75rem",
                      width: "100%",
                      height: "20%",
                      mb: "1rem",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={3}
                    label="Enter Remarks..."
                    value={fundingRemarks}
                    onChange={onChangeFundingRemarks}

                  />
                </Box>
                {console.log(fundingRemarks)}
                <Button
                        variant="contained"
                        className="gilroy"
                        sx={{
                            px: "2rem", mx: "1rem", bgcolor: "#379592",
                            textTransform: "none",
                            "&:hover": { background: "#379592" },
                        }}
                        onClick={() => finalSubmit()}
                    >
                        Submit
                    </Button>
            </Box>
          </Modal>
        </Box>
        
    );
}
