import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import image from "./Assets/Logo-Main.png";
import Image from "./Assets/candcimg1.png";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, useHistory } from "react-router-dom";
import "../App.css";
import { apiService } from "../Services/apiService";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import { CardActionArea, CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";
import CircularProgress from "@mui/material/CircularProgress";
import { render } from "@testing-library/react";
import { SERVER_URL, USER_LOGIN } from "../Services/apiEndpints";
import heart from "../Assets/Icons/Icons/heart.png";
import copyright from "../Assets/Icons/Icons/copyright.png";
import Stack from '@mui/material/Stack';

const formFields = {
  email: "",
  password: "",
};

export default function Login() {
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = useState(formFields);
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();

  // React.useEffect(() => {
  //   const isAuthenticated = localStorage.getItem("token");

  //   if (isAuthenticated) {
  //     const userRole = localStorage.getItem("rolesName"); // Assuming 'rolesName' is stored in localStorage
  //     if( localStorage.getItem("rolesName") === "CAMP MANAGER" ||
  //     localStorage.getItem("rolesName") === "ADMIN" ||
  //     localStorage.getItem("rolesName") === "CAMP ADMIN"){
  //       history.push("/UserNotificationLog");
  //     }
  //     else if(localStorage.getItem("rolesName") === "CAMP STAFF" ||
  //     localStorage.getItem("rolesName") === "THERAPIST" ||
  //     localStorage.getItem("rolesName") === "MEDICATION MANAGER"){
  //       history.push("/MedicationAssignedCamps");
  //     }

  //   }
  //   else{
  //     history.push("/");
  //   }
  // }, [history,localStorage]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // console.log("formData", formData);

  const onSubmit = async () => {
    setLoading(true);
    if (localStorage.getItem("token")) {
      // Swal.fire({
      //   icon: "error",
      //   text: "User already logged in",
      //   confirmButtonText: "OK",
      //   confirmButtonColor: "#379592",
      // })
      localStorage.clear("dashboard");
    } else {
      let apiInfo = {
        // url: `${process.env.REACT_APP_API_URL}auth/userlogin`,
        url: `${SERVER_URL}${USER_LOGIN}`,
        method: "POST",
        postData: formData,
      };
      let login = await apiService(apiInfo);

      console.log("login?.data?.responseData[0]", login?.data?.responseData[0]);
      if (+login?.data?.responseCode === 0) {
        setLoading(false);
        // Swal.hideLoading()
        localStorage.setItem(
          "token",
          login?.data?.responseData[0][0].accessToken
        );
        localStorage.setItem("userId", login?.data?.responseData[0][0].userId);
        localStorage.setItem("email", login?.data?.responseData[0][0].email);
        localStorage.setItem(
          "userRole",
          login?.data?.responseData[0][0].userRole
        );
        localStorage.setItem(
          "rolesName",
          login?.data?.responseData[0][0].rolesName
        );
        localStorage.setItem(
          "userName",
          login?.data?.responseData[0][0].userName
        );
        localStorage.setItem(
          "profileName",
          login?.data?.responseData[0][0].profileName
        );
        localStorage.setItem(
          "profileimage",
          login?.data?.responseData[0][0].profileimage
        );

        if (
          localStorage.getItem("rolesName") === "SUPPORT STAFF" ||
          localStorage.getItem("rolesName") === "PARENT" ||
          localStorage.getItem("rolesName") === "CHILD"
        ) {
          Swal.fire({
            icon: "error",
            text: "Unauthorized user",
            confirmButtonText: "OK",
            confirmButtonColor: "#379592",
          }).then(setLoading(false));
        } else if (
          localStorage.getItem("rolesName") === "CAMP MANAGER" ||
          localStorage.getItem("rolesName") === "ADMIN" ||
          localStorage.getItem("rolesName") === "CAMP ADMIN"
        ) {
          if (login?.data?.responseData[0][0].userStatus === "ACTIVE") {
            history.push("/UserNotificationLog");
          } else if (login?.data?.responseData[0][0].userStatus === "INVITED") {
            setLoading(false);
            history.push("/Registration");
          }
        } else if (
          localStorage.getItem("rolesName") === "CAMP STAFF" ||
          localStorage.getItem("rolesName") === "THERAPIST" ||
          localStorage.getItem("rolesName") === "MEDICATION MANAGER"
        ) {
          console.log("Not enetring this");
          if (login?.data?.responseData[0][0].userStatus === "ACTIVE") {
            history.push("/MedicationAssignedCamps");
          } else if (login?.data?.responseData[0][0].userStatus === "INVITED") {
            setLoading(false);
            history.push("/Registration");
          }
        }
      } else {
        // alert(login?.data?.responseDesc);
        setLoading(true);
        Swal.fire({
          icon: "error",
          text: "Invalid Credentials",
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
        }).then(setLoading(false));
      }
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      if (
        localStorage.getItem("rolesName") === "SUPPORT STAFF" ||
        localStorage.getItem("rolesName") === "PARENT" ||
        localStorage.getItem("rolesName") === "CHILD"
      ) {
        Swal.fire({
          icon: "error",
          text: "Unauthorized user",
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
        }).then(setLoading(false));
      } else if (
        localStorage.getItem("rolesName") === "CAMP MANAGER" ||
        localStorage.getItem("rolesName") === "ADMIN" ||
        localStorage.getItem("rolesName") === "CAMP ADMIN"
      ) {
        // if (login?.data?.responseData[0][0].userStatus === "ACTIVE") {
        history.push("/UserNotificationLog");
        // } else if (login?.data?.responseData[0][0].userStatus === "INVITED") {
        //   setLoading(false);
        //   history.push("/Registration");
        // }
      } else if (
        localStorage.getItem("rolesName") === "CAMP STAFF" ||
        localStorage.getItem("rolesName") === "THERAPIST" ||
        localStorage.getItem("rolesName") === "MEDICATION MANAGER"
      ) {
        // if (login?.data?.responseData[0][0].userStatus === "ACTIVE") {
        history.push("/MedicationAssignedCamps");
        // } else if (login?.data?.responseData[0][0].userStatus === "INVITED") {
        //   setLoading(false);
        //   history.push("/Registration");
        // }
      }
    }
  }, []);
  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": {
            fontFamily: "gilroyMedium",
            src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
          },
        },
      },
    },
  });
  React.useEffect(() => {
    localStorage.clear("dashboard");
  }, [localStorage, history]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Grid
          container
          direction="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100vh",
            backgroundImage: `url(${Image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Box>
            {loading === true ? (
              <CircularProgress />
            ) : (
              <>
                <Grid item>
                  <Card
                    sx={{
                      borderRadius: "20px",
                      marginTop: "2rem",
                      marginBottom: "4rem",
                    }}
                  >
                    <CardContent sx={{ mx: "1.5rem", maxWidth: "300px" }}>
                      <Grid item>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <CardMedia
                            component="img"
                            height="40%"
                            image={image}
                            alt="Logo"
                            sx={{ width: "60%" }}
                          />
                        </Box>
                        <Box
                          className="normal headingColor"
                          mt="0.5rem"
                          sx={{ fontSize: "1.25rem" }}
                        >
                          Sign In
                        </Box>
                        <Box className="normal headingColor" mt="0.25rem">
                          to continue to Calm and Connected
                        </Box>
                        <Box component="form" noValidate autoComplete="off">
                          <Box mt="2rem">
                            <TextField
                              id="standard-basic"
                              label="Email"
                              variant="standard"
                              name="email"
                              sx={{ width: "100%" }}
                              value={formData.email}
                              onChange={(event) => handleChange(event)}
                            ></TextField>
                          </Box>

                          <Box sx={{ textAlign: "center" }}>
                            <TextField
                              type={showPassword ? "text" : "password"}
                              id="standard-basic"
                              label="Password"
                              variant="standard"
                              name="password"
                              sx={{ width: "100%" }}
                              value={formData.password}
                              onChange={(event) => handleChange(event)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            ></TextField>
                          </Box>

                          <Grid container mt={1}>
                            <Grid item>
                              <Stack
                                spacing={{ xs: 4, sm: 8 }}
                                direction="row"
                                useFlexGap
                                flexWrap="wrap"
                              >
                                <Link
                                  className="link fs-10"
                                  to="/forgotpassword"
                                >
                                  {"Forgot your Password ?"}
                                </Link>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          mt="0.75rem"
                          className="medium"
                          sx={{ width: "100%", textAlign: "center" }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              width: "100%",
                              bgcolor: "#379592",
                              "&:hover": { background: "#379592" },
                              textTransform: "none",
                            }}
                            onClick={() => onSubmit()}
                          >
                            Sign In
                          </Button>
                        </Box>
                        <Link className="link fs-10" to="/Signup">
                          <Box
                            mt="0.75rem"
                            className="medium"
                            sx={{ width: "100%", textAlign: "center" }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                width: "100%",
                                bgcolor: "#379592",
                                "&:hover": { background: "#379592" },
                                textTransform: "none",
                              }}
                            >
                              Create Account
                            </Button>
                          </Box>
                        </Link>
                        <Box className="medium small" mt="1.75rem">
                          <a
                            href="https://calmandconnected.com.au/termsofservice/"
                            target="_blank"
                            className="link"
                          >
                            Terms and Conditions
                          </a>
                        </Box>
                        <Box className="medium small">
                          <a
                            href="https://calmandconnected.com.au/privacypolicy/"
                            target="_blank"
                            className="medium link"
                          >
                            Privacy Policy
                          </a>
                        </Box>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            )}
          </Box>

          <div className="footer">
            <Grid container className="gilroy">
              <Grid item xs={12} sm={8} className="footerfirst">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // fontSize: "0.7rem",
                    marginTop: "0.4rem",
                    marginLeft: "1rem",
                  }}
                >
                  <img
                    component="img"
                    src={copyright}
                    alt="Logo"
                    style={{ width: "1rem", marginRight: "0.5rem" }}
                  />
                  <span>
                    2023 Calm & Connected Pty Ltd, All Rights Reserved.
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} className="footersec">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    fontSize: "0.7rem",
                    marginRight: "1rem",
                    marginTop: "0.4rem",
                  }}
                >
                  Made With
                  <img
                    component="img"
                    src={heart}
                    alt="Logo"
                    style={{
                      width: "1rem",
                      marginRight: "0.5rem",
                      marginLeft: "0.5rem",
                    }}
                  />
                  <span>by Natsoft</span>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Box>
      {/* {loading===true ?(<CircularProgress />):(<CircularProgress />) } */}
    </ThemeProvider>
  );
}
