import React from 'react';
// import { Route, Redirect } from 'react-router-dom';

import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import VerifyOTP from "./VerifyOTP";
import CreateNewPassword from "./CreateNewPassword";
import PasswordChanged from "./PasswordChanged";
import CampList from "./CampList";
import CampList1 from "./CampList1";
import CreateCamp from "./CreateCamp";
import ViewCamp from "./ViewCamp";
import FullCamp from "./FullCamp";
import UploadingPhoto from "./UploadingPhoto";
import UploadingPhoto6 from "./UploadingPhoto6";
import CampName from "./CampName";
// import "./App.css";
import SideBar from "./SideBar";
import { BrowserRouter, Switch, Route, useHistory, Redirect } from "react-router-dom";
import Dashboard from "./Dashboard";
import ForgotPasswordPopup from "./ForgotPasswordPopup";
import ActivateStaff from "./ActivateStaff";
import AddUser from "./AddUser";
import Email from "./Email";
import UserAddedPopup from "./UserAddedPopup";
// import ActivateCampStaff from "./ActivateCampStaff";
import ActivateCampStaff from './ActivateCampStaff';
import RemoveCamp from "./RemoveCamp";
import EditCamp from "./EditCamp";
import SetReminder from "./Participants/SetReminder";
import ReminderLog from "./Participants/ReminderLog";
// import Demo from "./Participants/demo";
import ChangeSuitability from "./Participants/ChangeSuitability";
import ServiceAgreement from "./Participants/serviceAgreement";
import NotificationLog from "./Participants/NotificationsLog";
import Notifications from "./Participants/Notifications";
import SupportStaffInvoiceSent from "./SupportStaff/SupportStaffInvoiceSent";
import ParticipantsList from "./Participants/ParticipantsList";
import ApplicantsReview from "./Participants/ApplicantsReview";
import ReviewApplication from "./Participants/ReviewApplication";
import PendingSuitabilityPreScreen from "./Participants/PendingSuitabilityPreScreen";
import PendingSuitabilityOther from "./Participants/PendingSuitabilityOther";
import RecentFiles from "./Participants/RecentFiles";
import SupportStaffCampList from "./SupportStaff/SupportStaffCampList";
import SupportStaffApplicants from "./SupportStaff/SupportStaffApplicants";
import ChangeFunding from "./SupportStaff/ChangeFunding";
import SupportStaffApplicationReview from "./SupportStaff/SupportStaffApplicationReview";
import SupportStaffReminderLog from "./SupportStaff/SupportStaffReminderLog";
import SupportStaffFiles from "./SupportStaff/SupportStaffFiles";
import SupportstaffNotificationLog from "./SupportStaff/SupportstaffNotificationLog";
import SupportstaffNotifications from "./SupportStaff/SupportstaffNotifications";
import TherapyCampList from "./Therapy/TherapyCampList";
import GoalSettinginCampManger from "./Therapy/GoalSettinginCampManger";
import CampListForCampStaff from "./TherapyCampStaff/CampListForCampStaff";
import GoalSettingInCampStaff from "./TherapyCampStaff/GoalSettingInCampStaff";
import Sample from "./Sample";
import ViewParticipants from "./ViewParticipants";
import AddParticipants from "./AddParticipants";
import Goals from "./TherapyCampStaff/Goals";
import ProfileCampStaff from "./TherapyCampStaff/ProfileCampStaff";
import CreateWelcomePack from "./Participants/CreateWelcomePack";
import AddDescription from "./Participants/AddDescription";
import WelcomePackPublish from "./Participants/WelcomePackPublish";
import WelcomePackRepublish from "./Participants/WelcomePackRepublish";
import WelcomePackEdit from "./Participants/WelcomePackEdit";
import CampNotificationLog from "./CampNotificationLog";
import CampNotification from "./CampNotification";
import Gallery from "./Gallery";
import CheckInOut from "./CheckInOut";
import CheckInOutLog from "./CheckInOutLog";
import ChildProfile from "./ChildProfile";
import IncidentReport from "./ReportsModule/IncidentReport";
import Reports from "./ReportsModule/reports";
import CreateIncidentReport from "./ReportsModule/createincidentport";
import ViewIncidentReport from "./ReportsModule/viewincidentport";
import EditIncident from "./ReportsModule/editincidentport";
import CampStaffNotificationLog from "./TherapyCampStaff/CampStaffNotificationLog";
import CampStaffNotifications from "./TherapyCampStaff/CampStaffNotifications";
import MedicationAssignedCamps from "./Medication/MedicationAssignedCamps";
import ApplicantsReviewforMedication from "./Medication/ApplicantsReviewforMedication";
import ParticipantsMedicalRecord from "./Medication/ParticipantsMedicalRecord";
import MedicationMangerCheckInandOut from "./Medication/MedicationMangerCheckInandOut";
import EditParticipantMedicalRecord from "./Medication/EditParticipantMedicalRecord";
import MedicalForm from "./Medication/MedicalForm";
import GenerateInvoice from "./Participants/GenerateInvoice";
import SupportStaffCreateWelcomPack from "./SupportStaff/SupportStaffCreateWelcomPack";
import SupportStaffGenerateInvoice from "./SupportStaff/SupportStaffGenerateInvoice";
import NDISAgreement from "./Participants/NDIS Agreement";
import ArchivedCamps from "./ArchivedCamps";
import CampSummaryReports from "./CampSummary/CampSummaryReports";
import Registration from "./Registration";
import CreateCampSummary from "./CampSummary/CreateCampSummary";
import GoalCamp from "./CampSummary/GoalCamp";
import EditCampSummary from "./CampSummary/EditCampSummary";
import ViewCampSummary from "./CampSummary/ViewCampSummary";
import SupportStaffWelcomePackPublish from "./SupportStaff/SupportStaffWelcomePackPublish";
import SupportStaffWelcomePackRepublish from "./SupportStaff/SupportStaffWelcomePackRepublish";
import SupportStaffWelcomePackEdit from "./SupportStaff/SupportStaffWelcomePackEdit";
import ProfileView from "./TherapyCampStaff/ProfileView";
import Registerdata from "./Registerdata";

import DownloadNDIS from "./Participants/DownloadNDIS";
import DownloadInvoice from "./Participants/DownloadInvoice";
import UserProfile from "./UserProfile";
import UserProfile1 from "./UserProfile1";
import ShirtReport from "./ReportsModule/ShirtReport";
import ShirtReportCampwise from "./ReportsModule/ShirtReportCampwise";
import AlertReport from "./ReportsModule/AlertReport";
import AlertReportCampwise from "./ReportsModule/AlertReportCampwise";

import ProfileViewMedication from "./Medication/ProfileViewMedication";
import ReportsForCampManager from "./ReportsModule/ReportsForCampManager";
import CampListContactList from "./ReportsModule/CampListContactList";
import InvoiceReport from "./ReportsModule/InvoiceReport";
import ContactDetails from "./ReportsModule/ContactDetails";
import ViewCampSum from "./CampSummary/ViewCampSum";
import SupportStaffInvoiceDownload from "./SupportStaff/SupportStaffInvoiceDownload";
import Profile from "./Profile";
import ProfileScreen1 from "./ProfileScreen1";
import ProfileScreen2 from "./ProfileScreen2";
import CampListInvoiceReport from "./ReportsModule/CampListInvoiceReport";

import CampMangerViewIncidentReport from "./ReportsModule/CampMangerViewIncidentReport";
import UserNotification from "./UserNotification";

import RoleGuard from "../components/RoleGuard"
import CampMedicalReport from './ReportsModule/CampMedicalReport';
import ManagerMedicationReport from './ReportsModule/ManagerMedicationReport';

const SideBarRoute = () => {
    
    let userToken = localStorage.getItem('token');

    return (
      <>
        <SideBar>
          <Switch>
            <RoleGuard
              path="/UserNotificationLog"
              component={Dashboard}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/CampList"
              component={CampList}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/CampList1"
              component={CampList1}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/CampName"
              component={CampName}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/CreateCamp"
              component={CreateCamp}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ViewCamp/:campId"
              component={ViewCamp}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/RemoveCamp/:campId"
              component={RemoveCamp}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/EditCamp/:campId"
              component={EditCamp}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/FullCamp"
              component={FullCamp}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/UploadingPhoto6"
              component={UploadingPhoto6}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ActivateStaff"
              component={ActivateStaff}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ActivateCampStaff"
              component={ActivateCampStaff}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/AddUser"
              component={AddUser}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SetReminder/:campId"
              component={SetReminder}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ReminderLog/:campId"
              component={ReminderLog}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ChangeSuitability/:campId/:applicantId"
              component={ChangeSuitability}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/serviceAgreement"
              component={ServiceAgreement}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/Notifications/:campId/:applicantId"
              component={Notifications}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/NotificationLog/:campId/:applicantId"
              component={NotificationLog}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/SupportStaffInvoiceSent"
              component={SupportStaffInvoiceSent}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ParticipantsList"
              component={ParticipantsList}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ApplicantsReview/:campId"
              component={ApplicantsReview}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ReviewApplication/:campId/:applicantId"
              component={ReviewApplication}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/PendingSuitabilityPreScreen/:campId/:applicantId"
              component={PendingSuitabilityPreScreen}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/PendingSuitabilityOther"
              component={PendingSuitabilityOther}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/RecentFiles/:campId/:applicantId"
              component={RecentFiles}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SupportStaffCampList"
              component={SupportStaffCampList}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SupportStaffApplicants/:campId"
              component={SupportStaffApplicants}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ChangeFunding/:campId/:staffApplicationId/:staffId"
              component={ChangeFunding}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SupportStaffApplicationReview/:campId/:staffApplicationId/:staffId"
              component={SupportStaffApplicationReview}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SupportStaffReminderLog"
              component={SupportStaffReminderLog}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SupportStaffFiles/:campId/:staffApplicationId/:staffId"
              component={SupportStaffFiles}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SupportstaffNotificationLog/:campId/:staffApplicationId/:staffId"
              component={SupportstaffNotificationLog}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SupportstaffNotifications/:campId/:staffApplicationId/:staffId"
              component={SupportstaffNotifications}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/TherapyCampList"
              component={TherapyCampList}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/CampListContactList"
              component={CampListContactList}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/CampListInvoiceReport"
              component={CampListInvoiceReport}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            {/* <RoleGuard
          path="/GoalSettinginCampManger"
          component={GoalSettinginCampManger}
          allowedRoles={['CAMP MANAGER','CAMP ADMIN','ADMIN','CAMP STAFF']}
        /> */}
            <RoleGuard
              path="/CampListForCampStaff"
              component={CampListForCampStaff}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/GoalSettingInCampStaff/:camp_id"
              component={GoalSettingInCampStaff}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/ParticipantsList"
              component={ParticipantsList}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
                "MEDICATION MANAGER",
              ]}
            />
            {/* <RoleGuard
          path="/ProfileView/:camp_id/:child_id/:applicationId"
          component={ProfileView}
          allowedRoles={['CAMP MANAGER','CAMP ADMIN','ADMIN']}
        /> */}
            {/* <RoleGuard
          path="/ProfileViewMedication/:camp_id/:child_id/:applicationId"
          component={ProfileViewMedication}
          allowedRoles={['CAMP MANAGER','CAMP ADMIN','ADMIN']}
        /> */}
            <RoleGuard
              path="/ViewParticipants/:campId"
              component={ViewParticipants}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/AddParticipants/:campId"
              component={AddParticipants}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/Sample"
              component={Sample}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/Goals/:camp_id/:applicationId"
              component={Goals}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/AddDescription"
              component={AddDescription}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ContactDetails/:camp_id"
              component={ContactDetails}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/Gallery/:campId"
              component={Gallery}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/CheckInOut/:campId"
              component={CheckInOut}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/CheckInOutLog/:campId/:applicantId"
              component={CheckInOutLog}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/MedicationAssignedCamps"
              component={MedicationAssignedCamps}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/InvoiceReport/:camp_id"
              component={InvoiceReport}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ApplicantsReviewforMedication/:camp_id"
              component={ApplicantsReviewforMedication}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/ManagerMedicationReport/:camp_id"
              component={ManagerMedicationReport}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ParticipantsMedicalRecord/:camp_id/:applicationId"
              component={ParticipantsMedicalRecord}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/MedicationMangerCheckInandOut/:camp_id/:applicationId"
              component={MedicationMangerCheckInandOut}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/EditParticipantMedicalRecord/:camp_id/:applicationId"
              component={EditParticipantMedicalRecord}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/MedicalForm/:camp_id/:applicationId"
              component={MedicalForm}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            {/* <RoleGuard
          path="/ProfileCampStaff/:profileId"
          component={ProfileCampStaff}
          allowedRoles={['CAMP MANAGER','CAMP ADMIN','ADMIN','CAMP STAFF']}
        /> */}
            <RoleGuard
              path="/CreateWelcomePack/:campId"
              component={CreateWelcomePack}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/WelcomePackPublish"
              component={WelcomePackPublish}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SupportStaffWelcomePackPublish"
              component={SupportStaffWelcomePackPublish}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/WelcomePackRepublish/:campId"
              component={WelcomePackRepublish}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/WelcomePackEdit/:campId"
              component={WelcomePackEdit}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/CampNotificationLog/:campId"
              component={CampNotificationLog}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/CampNotification/:campId"
              component={CampNotification}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SupportStaffGenerateInvoice/:campId/:staffApplicationId/:staffId"
              component={SupportStaffGenerateInvoice}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ArchivedCamps"
              component={ArchivedCamps}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ChildProfile/:campId/:childId"
              component={ChildProfile}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/IncidentReport"
              component={IncidentReport}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/Reports"
              component={Reports}
              allowedRoles={["CAMP STAFF"]}
            />
            <RoleGuard
              path="/ReportsForCampManager"
              component={ReportsForCampManager}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/CampMedicalReport"
              component={CampMedicalReport}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/createIncidentReport"
              component={CreateIncidentReport}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/viewIncidentReport/:incidentReportId/:campId"
              component={ViewIncidentReport}
              allowedRoles={["CAMP STAFF"]}
            />
            <RoleGuard
              path="/editIncidentReport/:incidentReportId/:campId"
              component={EditIncident}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            {/* <RoleGuard
          path="/CampStaffNotificationLog"
          component={CampStaffNotificationLog}
          allowedRoles={['CAMP MANAGER','CAMP ADMIN','ADMIN']}
        /> */}
            {/* <RoleGuard
          path="/CampStaffNotifications"
          component={CampStaffNotifications}
          allowedRoles={['CAMP MANAGER','CAMP ADMIN','ADMIN']}
        /> */}
            <RoleGuard
              path="/GenerateInvoice/:campId/:applicantId"
              component={GenerateInvoice}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SupportStaffCreateWelcomPack/:campId"
              component={SupportStaffCreateWelcomPack}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SupportStaffWelcomePackRepublish/:campId"
              component={SupportStaffWelcomePackRepublish}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/SupportStaffWelcomePackEdit/:campId"
              component={SupportStaffWelcomePackEdit}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/NDISAgreement/:campId/:applicantId"
              component={NDISAgreement}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/DownloadNDIS/:campId/:applicantId"
              component={DownloadNDIS}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/DownloadInvoice/:campId/:applicantId"
              component={DownloadInvoice}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/ShirtReport"
              component={ShirtReport}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/ShirtReportCampwise/:campId"
              component={ShirtReportCampwise}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/AlertReport"
              component={AlertReport}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/AlertReportCampwise/:campId"
              component={AlertReportCampwise}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/CampSummaryReports"
              component={CampSummaryReports}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/CreateCampSummary/:camp_id/:applicationId/"
              component={CreateCampSummary}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/ViewCampSum/:camp_id/:applicationId/"
              component={ViewCampSum}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/EditCampSummary/:camp_id/:applicationId/"
              component={EditCampSummary}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/ViewCampSummary/:camp_id/:applicationId/"
              component={ViewCampSummary}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/GoalCamp/:camp_id"
              component={GoalCamp}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/SupportStaffInvoiceDownload/:campId/:staffApplicationId/:staffId"
              component={SupportStaffInvoiceDownload}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/Profile"
              component={Profile}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/ProfileScreen1"
              component={ProfileScreen1}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/ProfileScreen2"
              component={ProfileScreen2}
              allowedRoles={[
                "CAMP MANAGER",
                "CAMP ADMIN",
                "ADMIN",
                "CAMP STAFF",
              ]}
            />
            <RoleGuard
              path="/CampMangerViewIncidentReport/:incidentReportId/:campId"
              component={CampMangerViewIncidentReport}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />
            <RoleGuard
              path="/UserNotification"
              component={UserNotification}
              allowedRoles={["CAMP MANAGER", "CAMP ADMIN", "ADMIN"]}
            />

            {/* <Route path="/UserNotificationLog" component={Dashboard}></Route>
            <Route exact path="/CampList" component={CampList}></Route>
            <Route path="/CampList1" component={CampList1}></Route>
            <Route path="/CampName" component={CampName}></Route>
            <Route path="/CreateCamp" component={CreateCamp}></Route>
            <Route path="/ViewCamp/:campId" component={ViewCamp}></Route>
            <Route path="/RemoveCamp/:campId" component={RemoveCamp}></Route>
            <Route path="/EditCamp/:campId" component={EditCamp}></Route>
            <Route path="/FullCamp" component={FullCamp}></Route>
            <Route path="/UploadingPhoto6" component={UploadingPhoto6}></Route>
            <Route path="/ActivateStaff" component={ActivateStaff}></Route>
            <Route
              path="/ActivateCampStaff"
              component={ActivateCampStaff}
            ></Route>
            <Route path="/AddUser" component={AddUser}></Route>
            <Route path="/SetReminder/:campId" component={SetReminder}></Route>
            <Route path="/ReminderLog/:campId" component={ReminderLog}></Route>
            <Route
              path="/ChangeSuitability/:campId/:applicantId"
              component={ChangeSuitability}
            ></Route>
            <Route
              path="/serviceAgreement"
              component={ServiceAgreement}
            ></Route>
            <Route
              path="/Notifications/:campId/:applicantId"
              component={Notifications}
            ></Route>
            <Route
              path="/NotificationLog/:campId/:applicantId"
              component={NotificationLog}
            ></Route>
            <Route
              path="/SupportStaffInvoiceSent"
              component={SupportStaffInvoiceSent}
            ></Route>
            <Route
              path="/ParticipantsList"
              component={ParticipantsList}
            ></Route>
            <Route
              path="/ApplicantsReview/:campId"
              component={ApplicantsReview}
            ></Route>
            <Route
              path="/ReviewApplication/:campId/:applicantId"
              component={ReviewApplication}
            ></Route>
            <Route
              path="/PendingSuitabilityPreScreen/:campId/:applicantId"
              component={PendingSuitabilityPreScreen}
            ></Route>
            <Route
              path="/PendingSuitabilityOther"
              component={PendingSuitabilityOther}
            ></Route>
            <Route
              path="/RecentFiles/:campId/:applicantId"
              component={RecentFiles}
            ></Route>
            <Route
              path="/SupportStaffCampList"
              component={SupportStaffCampList}
            ></Route>
            <Route
              path="/SupportStaffApplicants/:campId"
              component={SupportStaffApplicants}
            ></Route>
            <Route
              path="/ChangeFunding/:campId/:staffApplicationId/:staffId"
              component={ChangeFunding}
            ></Route>
            <Route
              path="/SupportStaffApplicationReview/:campId/:staffApplicationId/:staffId"
              component={SupportStaffApplicationReview}
            ></Route>
            <Route
              path="/SupportStaffReminderLog"
              component={SupportStaffReminderLog}
            ></Route>
            <Route
              path="/SupportStaffFiles/:campId/:staffApplicationId/:staffId"
              component={SupportStaffFiles}
            ></Route>
            <Route
              path="/SupportstaffNotificationLog/:campId/:staffApplicationId/:staffId"
              component={SupportstaffNotificationLog}
            ></Route>
            <Route
              path="/SupportstaffNotifications/:campId/:staffApplicationId/:staffId"
              component={SupportstaffNotifications}
            ></Route>
            <Route path="/TherapyCampList" component={TherapyCampList}></Route>
            <Route
              path="/CampListContactList"
              component={CampListContactList}
            ></Route>
            <Route path="/CampListInvoiceReport" component={CampListInvoiceReport}></Route>
            <Route
              path="/GoalSettinginCampManger"
              component={GoalSettinginCampManger}
            ></Route>
            <Route
              path="/CampListForCampStaff"
              component={CampListForCampStaff}
            ></Route>

            <Route
              path="/GoalSettingInCampStaff/:camp_id"
              component={GoalSettingInCampStaff}
            ></Route>
            <Route
              path="/ProfileView/:camp_id/:child_id/:applicationId"
              component={ProfileView}
            ></Route>
            <Route
              path="/ProfileViewMedication/:camp_id/:child_id/:applicationId"
              component={ProfileViewMedication}
            ></Route>
            <Route
              path="/ViewParticipants/:campId"
              component={ViewParticipants}
            ></Route>
            <Route
              path="/AddParticipants/:campId"
              component={AddParticipants}
            ></Route>
            <Route path="/Sample" component={Sample}></Route>
            <Route
              path="/Goals/:camp_id/:applicationId"
              component={Goals}
            ></Route>
            <Route path="/AddDescription" component={AddDescription}></Route>
            <Route
              path="/ContactDetails/:camp_id"
              component={ContactDetails}
            ></Route>
            <Route path="/Gallery/:campId" component={Gallery}></Route>
            <Route path="/CheckInOut/:campId" component={CheckInOut}></Route>
            <Route
              path="/CheckInOutLog/:campId/:applicantId"
              component={CheckInOutLog}
            ></Route>
            <Route
              path="/MedicationAssignedCamps"
              component={MedicationAssignedCamps}
            ></Route>
            <Route path="/InvoiceReport/:camp_id" component={InvoiceReport}></Route>
            <Route
              path="/ApplicantsReviewforMedication/:camp_id"
              component={ApplicantsReviewforMedication}
            ></Route>
            <Route
              path="/ParticipantsMedicalRecord/:camp_id/:applicationId"
              component={ParticipantsMedicalRecord}
            ></Route>
            <Route
              path="/MedicationMangerCheckInandOut/:camp_id/:applicationId"
              component={MedicationMangerCheckInandOut}
            ></Route>
            <Route
              path="/EditParticipantMedicalRecord/:camp_id/:applicationId"
              component={EditParticipantMedicalRecord}
            ></Route>
            <Route
              path="/MedicalForm/:camp_id/:applicationId"
              component={MedicalForm}
            ></Route>
            <Route
              path="/ProfileCampStaff/:profileId"
              component={ProfileCampStaff}
            ></Route>
            <Route
              path="/CreateWelcomePack/:campId"
              component={CreateWelcomePack}
            ></Route>
            <Route
              path="/WelcomePackPublish"
              component={WelcomePackPublish}
            ></Route>
            <Route
              path="/SupportStaffWelcomePackPublish"
              component={SupportStaffWelcomePackPublish}
            ></Route>
            <Route
              path="/WelcomePackRepublish/:campId"
              component={WelcomePackRepublish}
            ></Route>
            <Route
              path="/WelcomePackEdit/:campId"
              component={WelcomePackEdit}
            ></Route>
            <Route
              path="/CampNotificationLog/:campId"
              component={CampNotificationLog}
            ></Route>
            <Route
              path="/CampNotification/:campId"
              component={CampNotification}
            ></Route>
            <Route
              path="/SupportStaffGenerateInvoice/:campId/:staffApplicationId/:staffId"
              component={SupportStaffGenerateInvoice}
            ></Route>
            <Route path="/ArchivedCamps" component={ArchivedCamps}></Route>
            <Route path="/ChildProfile/:campId/:childId" component={ChildProfile}></Route>
            <Route path="/IncidentReport" component={IncidentReport}></Route>
            <Route path="/Reports" component={Reports}></Route>
            <Route
              path="/ReportsForCampManager"
              component={ReportsForCampManager}
            ></Route>
            <Route
              path="/createIncidentReport"
              component={CreateIncidentReport}
            ></Route>
            <Route
              path="/viewIncidentReport/:incidentReportId/:campId"
              component={ViewIncidentReport}
            ></Route>
            <Route
              path="/editIncidentReport/:incidentReportId/:campId"
              component={EditIncident}
            ></Route>
            <Route
              path="/CampStaffNotificationLog"
              component={CampStaffNotificationLog}
            ></Route>
            <Route
              path="/CampStaffNotifications"
              component={CampStaffNotifications}
            ></Route>
            <Route
              path="/GenerateInvoice/:campId/:applicantId"
              component={GenerateInvoice}
            ></Route>
            <Route
              path="/SupportStaffCreateWelcomPack/:campId"
              component={SupportStaffCreateWelcomPack}
            ></Route>
            <Route
              path="/SupportStaffWelcomePackRepublish/:campId"
              component={SupportStaffWelcomePackRepublish}
            ></Route>
            <Route
              path="/SupportStaffWelcomePackEdit/:campId"
              component={SupportStaffWelcomePackEdit}
            ></Route>
            <Route
              path="/NDISAgreement/:campId/:applicantId"
              component={NDISAgreement}
            ></Route>
            <Route
              path="/DownloadNDIS/:campId/:applicantId"
              component={DownloadNDIS}
            ></Route>
            <Route
              path="/DownloadInvoice/:campId/:applicantId"
              component={DownloadInvoice}
            ></Route>
            <Route path="/ShirtReport" component={ShirtReport}></Route>
            <Route
              path="/ShirtReportCampwise/:campId"
              component={ShirtReportCampwise}
            ></Route>
            <Route path="/AlertReport" component={AlertReport}></Route>
            <Route
              path="/AlertReportCampwise/:campId"
              component={AlertReportCampwise}
            ></Route>
            <Route
              path="/CampSummaryReports"
              component={CampSummaryReports}
            ></Route>
            <Route
              path="/CreateCampSummary/:camp_id/:applicationId/"
              component={CreateCampSummary}
            ></Route>
            <Route
              path="/ViewCampSum/:camp_id/:applicationId/"
              component={ViewCampSum}
            ></Route>
            <Route
              path="/EditCampSummary/:camp_id/:applicationId/"
              component={EditCampSummary}
            ></Route>
            <Route
              path="/ViewCampSummary/:camp_id/:applicationId/"
              component={ViewCampSummary}
            ></Route>
            <Route path="/GoalCamp/:camp_id" component={GoalCamp}></Route>

            <Route
              path="/SupportStaffInvoiceDownload/:campId/:staffApplicationId/:staffId"
              component={SupportStaffInvoiceDownload}
            ></Route>
            <Route path="/Profile" component={Profile}></Route>
            <Route path="/ProfileScreen1" component={ProfileScreen1}></Route>
            <Route path="/ProfileScreen2" component={ProfileScreen2}></Route>
            <Route path="/CampMangerViewIncidentReport/:incidentReportId/:campId" component={CampMangerViewIncidentReport}></Route>
            <Route path="/UserNotification" component={UserNotification}></Route> */}
          </Switch>
        </SideBar>

        {/* </Switch> */}
      </>
    );
};

export default SideBarRoute;
