import React from "react"; 
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

import Image from "./Assets/candcimg1.png";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import "../App.css";

import FormControl from "@mui/material/FormControl";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { apiService } from "../Services/apiService";
import { Link, useHistory } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";
import CircularProgress from "@mui/material/CircularProgress";

import Modal from "@mui/material/Modal";

import {
  SERVER_URL,
  STAFF_INVITE_EMAIL
} from "../Services/apiEndpints";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
/*debugger
let rolesName = localStorage.getItem('rolesName')
debugger
let rolesArr = []

if (rolesName === 'CAMP MANAGER'){
   rolesArr = [
  {
        // rolesId: 9,
        roles_group: "STAFF",
        rolesName: "Camp Staff"
    }
  
  ]
}
else if(rolesName === 'ADMIN' || rolesName === 'CAMP ADMIN'){
    rolesArr = [
      {
            // rolesId: 9,
            roles_group: "STAFF",
            rolesName: "Camp Staff"
        },
        {
          // rolesId: 9,
          roles_group: "STAFF",
          rolesName: "Camp Manager"
      } 
    ]
}*/

export default function AddUser() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  // const current = new Date();
  // const date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;

  // const dateCheck = new Date()
  // const date2 = `${dateCheck.getMonth() + 1}/${dateCheck.getDate()-1}/${dateCheck.getFullYear()}`;
  // { console.log(date, date2) }

  // const phoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/;
  const EMAIL_REGEXP =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const validationSchema = Yup.object().shape({
    staffFirstName: Yup.string().required("First name is required"),
    staffLastName: Yup.string().required("Last name is required"),
    // dateOfBirth: Yup.date().required("Date of birth is required").nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null))
    //   .max(date2, "Future date is not expected"),
    // dateOfBirth: Yup.date()
    // .nullable()
    // .typeError('DOB is required')
    // .max(date2, "Future and current date are not accepted") ,
    // gender: Yup.string().required("Gender is required"),
    // address: Yup.string().required("Address is required"),
    // mobileNumber: Yup.string()
    //   .required("Mobile Number is required")
    //   .matches(phoneRegExp, "Mobile number is not valid"),
    // staffEmail: Yup.string().required("Email is required").email("Email is invalid"),
    staffEmail: Yup.string()
      .required("Email is required")
      .matches(EMAIL_REGEXP, "Email is invalid"),
    staffRoll: Yup.string().required("User role is required"),
  });

 
  const [filteredData, setFilteredData] = React.useState([]);

  const filterData = (category) => {
 
    if (category === 'CAMP MANAGER'){
      const filtered  = [
     {
           // rolesId: 9,
           roles_group: "STAFF",
           rolesName: "Camp Staff"
       }
     
     ]
   
     setFilteredData(filtered);
   }
   else if(category === 'ADMIN' || category === 'CAMP ADMIN'){
    const filtered  = [
         {
               // rolesId: 9,
               roles_group: "STAFF",
               rolesName: "Camp Staff"
           },
           {
             // rolesId: 9,
             roles_group: "STAFF",
             rolesName: "Camp Manager"
         } 
       ]
       setFilteredData(filtered);
    
    
   
  }
}
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    // Filter the data by default when the component mounts
    filterData(localStorage.getItem('rolesName'));
    console.log(filteredData);
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    console.log(JSON.stringify(data, null, 2));

    data["staffRoll"] = data["staffRoll"].toUpperCase();
    const body = { ...data, userId: localStorage.getItem("userId") };

    if (data) {
      let apiInfo = {
        // url: `${process.env.REACT_APP_API_URL}auth/staffinvitemail`,
        url:`${SERVER_URL}${STAFF_INVITE_EMAIL}`,
        method: "POST",
        postData: body,
        headers : {
          "Content-Type": "application/json",
          tokenheaderkey:localStorage.getItem("token"),
        },
      };
      let register = await apiService(apiInfo);
      if (
        register?.data?.responseCode === 0 &&
        register?.data?.responseDesc === "Success"
      ) {
        //  history.push("/userAddedPopup")

        Swal.fire({
          icon: "success",
          iconHtml: `<img src="${GreenCheck}" >`,
          text: register?.data?.responseData,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
            icon: 'no-border'
        },
        }).then(() => {
          history.push("/ActivateStaff");
        });
      } else {
        Swal.fire({
          icon: "error",
          text: register?.data?.responseData,
          // text: register?.data?.responseDesc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
            icon: 'no-border'
        },
        }).then(setLoading(false));
      }
    }
  };

  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    // overrides: {
    //   MuiRadioGroup: {
    //     root: {
    //       color: 'green',
    //     },
    //     colorSecondary: {
    //       '&$checked': {
    //         color: 'green',
    //       },
    //     },
    //   },
    // },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": {
            fontFamily: "gilroyMedium",
            src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Box> */}
      <Grid
        container
        // direction="flex"
        // justifyContent="center"
        // alignItems="center"
        sx={{
          height: "80vh",
          backgroundImage: `url(${Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box mt="1rem" mx="2rem">
          <Box sx={{ color: "white" }} mt="1rem">
            {/* Users - C&C Staff */}
          </Box>
          <Box sx={{ color: "white" }} mt="1rem">
            {/* Add User */}
          </Box>
        </Box>

        <Grid container display="flex" justifyContent="center">
          {loading === true ? (
            <CircularProgress />
          ) : (
            <Grid item>
              <Card sx={{ borderRadius: "20px", minWidth: 700, py: "2rem" }}>
                <CardContent sx={{ mx: "0.5rem" }}>
                  <Box
                    textAlign="center"
                    sx={{ color: "#707070" }}
                    className="test"
                  >
                    Add User
                  </Box>

                  <Box component="form" noValidate autoComplete="off">
                    <Grid
                      container
                      columnSpacing={3}
                      sx={{ textAlign: "center" }}
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          id="standard-basic"
                          label="First Name"
                          variant="standard"
                          name="staffFirstName"
                          {...register("staffFirstName")}
                          error={errors.staffFirstName ? true : false}
                          sx={{ width: "80%" }}
                        />
                        <Typography
                          color="error.main"
                          ml="2rem"
                          sx={{ fontSize: "12px", textAlign: "left" }}
                        >
                          {errors.staffFirstName?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          id="standard-basic"
                          label="Email"
                          variant="standard"
                          name="staffEmail"
                          {...register("staffEmail")}
                          error={errors.staffEmail ? true : false}
                          sx={{ width: "80%" }}
                        />

                        <Typography
                          color="error.main"
                          ml="2rem"
                          sx={{ fontSize: "12px", textAlign: "left" }}
                        >
                          {errors.staffEmail?.message}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      columnSpacing={3}
                      sx={{ textAlign: "center" }}
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          id="standard-basic"
                          label="Last Name"
                          variant="standard"
                          name="staffLastName"
                          {...register("staffLastName")}
                          error={errors.staffLastName ? true : false}
                          sx={{ width: "80%" }}
                        />

                        <Typography
                          color="error.main"
                          ml="2rem"
                          sx={{ fontSize: "12px", textAlign: "left" }}
                        >
                          {errors.staffLastName?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <FormControl variant="standard" sx={{ width: 255 }}>
                          <InputLabel
                            id="demo-simple-select-standard-label"
                            error={errors.userRole ? true : false}
                          >
                            Roles
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Roles"
                            name="staffRoll"
                            {...register("staffRoll")}
                            error={errors.staffRoll ? true : false}
                          >
                            {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem> */}
                            {filteredData.map((role) => (
                              <MenuItem value={role.rolesName}>
                                {role.rolesName}
                              </MenuItem>
                            ))}
                          </Select>
                          <Typography
                            color="error.main"
                            sx={{ fontSize: "12px", textAlign: "left" }}
                          >
                            {errors.staffRoll?.message}
                          </Typography>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>

                <Grid container display="flex" justifyContent="center">
                  <Box
                    sx={{ textAlign: "center" }}
                    mb="0.75rem"
                    mt="1rem"
                    mx="0.5rem"
                  >
                    <Button
                      variant="contained"
                      sx={{
                        px: "3rem",

                        bgcolor: "#379592",
                        textTransform: "none",
                        "&:hover": { background: "#379592" },
                      }}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Invite
                    </Button>
                  </Box>
                  <Box
                    sx={{ textAlign: "center" }}
                    mb="0.75rem"
                    mt="1rem"
                    mx="0.5rem"
                  >
                    <Link to="/ActivateStaff" className="link2">
                      <Button
                        variant="contained"
                        sx={{
                          px: "3rem",

                          bgcolor: "#909090",
                          textTransform: "none",
                          "&:hover": { background: "#909090" },
                        }}
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Box>
                </Grid>
              </Card>
            </Grid>
          )}
          {/* <Grid item >
          <Card
            sx={{ borderRadius: "20px", minWidth: 700, py:'2rem'  }}>
            <CardContent sx={{ mx: "0.5rem" }}  >
            <Box textAlign='center' sx={{color:'#707070'}} className='test'>Add User</Box>
              
              <Box component="form" noValidate autoComplete="off">
                <Grid
                  container
                  columnSpacing={3}
                  sx={{ textAlign: "center" }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="standard-basic"
                      label="First Name"
                      variant="standard"
                      name="staffFirstName"
                      {...register("staffFirstName")}
                      error={errors.staffFirstName ? true : false}
                      sx={{ width: "80%" }}
                    />
                    <Typography color='error.main' ml='2rem' sx={{ fontSize: '12px', textAlign: 'left' }}>
                      {errors.staffFirstName?.message}
                    </Typography>

                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    
                    <TextField
                      id="standard-basic"
                      label="Email"
                      
                      variant="standard"
                      name="staffEmail"
                      {...register("staffEmail")}
                      error={errors.staffEmail ? true : false}
                      sx={{ width: "80%" }}
                    />
                    
                    <Typography color='error.main' ml='2rem' sx={{ fontSize: '12px', textAlign: 'left' }}>
                      {errors.staffEmail?.message}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  columnSpacing={3}
                  sx={{ textAlign: "center" }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="standard-basic"
                      label="Last Name"
                      variant="standard"
                      name="staffLastName"
                      {...register("staffLastName")}
                      error={errors.staffLastName ? true : false}
                      sx={{ width: "80%" }}
                    />
                    
                    <Typography color='error.main' ml='2rem' sx={{ fontSize: '12px', textAlign: 'left' }}>
                      {errors.staffLastName?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <FormControl variant="standard" sx={{ width: 255 }} >
                      <InputLabel id="demo-simple-select-standard-label" error={errors.userRole ? true : false}>
                        Roles
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Roles"
                        name="staffRoll"
                        {...register("staffRoll")}
                        error={errors.staffRoll ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {rolesArr.map((role) => (
                          <MenuItem value={role.rolesName}>
                            {role.rolesName}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography color='error.main' sx={{ fontSize: '12px', textAlign: 'left' }}>
                        {errors.staffRoll?.message}
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>

            <Grid container  display='flex' justifyContent='center'>
            <Box  sx={{ textAlign: "center" }} mb="0.75rem" mt='1rem' mx='0.5rem'>
              <Button
                variant="contained"
                sx={{
                  px: "3rem",
                 
                  bgcolor: "#379592",
                  textTransform: 'none',
                  "&:hover": { background: "#379592" },
                }}
                
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </Box>
            <Box  sx={{ textAlign: "center" }} mb="0.75rem" mt='1rem'mx='0.5rem'>
              <Link to='/ActivateStaff' className='link2'>
              <Button
                variant="contained"
                sx={{
                  px: "3rem",
                  
                  bgcolor: "#909090",
                  textTransform: 'none',
                  "&:hover": { background: "#909090" },
                }}
              >
                Cancel
              </Button>
              </Link>
              
            </Box>
            </Grid>
            
          </Card>
        </Grid> */}
        </Grid>
      </Grid>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      {/* </Box> */}
    </ThemeProvider>
  );
}
