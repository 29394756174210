import React from "react";
import Box from "@mui/material/Box";
import "../../index.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button/Button";
import { Link, useParams, useHistory } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, alpha } from "@mui/material/styles";
import GreenPdf from "../../Assets/Icons/Icons/GreenPdf.png";
import { apiService, apiService2 } from "../../Services/apiService";
import Logo from "../../Assets/Icons/Icons/Logo-Main.png";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";
import GreenCheck2 from "../../Assets/Icons/Icons/GreenCheck2.png";
import { ContactSupportOutlined } from "@material-ui/icons";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { US8_URL , SERVER_URL} from '../../Services/apiEndpints'
import html2pdf from 'html2pdf.js'

const dummy = {
    applicationId: "",
    childId: "",
    parentId: "",
    servicesFor: "",
    childDOB: "",
    invoiceNumber: "",
    invoiceDate: "",
    dueDate: "",
    particpantsNo: "",
    isssuedTo: "",
    total: "",
    servicesList: [],
    paymentRefno: ""
}
export default function DownloadInvoice() {
    let { campId, applicantId } = useParams();
    const [invoiceInfo, setInvoiceInfo] = React.useState('')
    const [list, setList] = React.useState([])
    const [serviceList, setServiceList] = React.useState([])
    const [readData, setReadData] = React.useState(dummy)
    const [invoiceOpen, setinvoiceOpen] = React.useState(false);
    let history = useHistory()
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },

        // hide last border

        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: theme.palette.common.black,

            backgroundColor: "rgba(55, 149, 146, 1)",

            color: theme.palette.common.white,
        },

        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    React.useEffect(() => {

        (
            async () => {

                let dataSent = {
                    _methodId: 51,
                    userId: localStorage.getItem('userId'),
                    // campId: 50001,
                    // applicationId: 300005
                    campId: campId,
                    applicationId: applicantId
                }

                let apiInfo = {
                    // url: "https://cncdevapi.natsoft.io/api/us8",
                    url: `${SERVER_URL}${US8_URL}`,
                    method: "POST",
                    postData: dataSent,
                    headers: {
                        "Content-Type": "application/json",
                        tokenheaderkey: localStorage.getItem("token"),
                    },

                }

                let output = await apiService2(apiInfo)
                // console.log(output.data.responseData[0])
                let invoiceData = output.data.responseData[0]
                setInvoiceInfo(output.data.responseData[0][0])
                console.log(output.data.responseData[0][0], 'lolllll')
                if (output?.data?.responseData[0][0]?.servicesList !== []) {
                    setServiceList(JSON.parse(output?.data?.responseData[0][0]?.servicesList))
                }

                console.log(JSON.parse(output?.data?.responseData[0][0]?.servicesList))

                setReadData({
                    ...readData,

                    applicationId: output?.data?.responseData[0][0]?.applicationId,
                    childId: output?.data?.responseData[0][0]?.childId,
                    parentId: output?.data?.responseData[0][0]?.parentId,
                    servicesFor: output?.data?.responseData[0][0]?.servicesFor,
                    childDOB: output?.data?.responseData[0][0]?.childDOB,
                    invoiceNumber: output?.data?.responseData[0][0]?.invoiceNumber,
                    invoiceDate: output?.data?.responseData[0][0]?.invoiceDate,
                    dueDate: output?.data?.responseData[0][0]?.dueDate,
                    particpantsNo: output?.data?.responseData[0][0]?.particpantsNo,
                    isssuedTo: output?.data?.responseData[0][0]?.isssuedTo,
                    total: output?.data?.responseData[0][0]?.total,
                    servicesList: JSON.parse(output?.data?.responseData[0][0]?.servicesList),
                    paymentRefno: output?.data?.responseData[0][0]?.paymentRefno
                })
                setList(JSON.parse(output?.data?.responseData[0][0]?.servicesList))
            }
        )()


    }

        , [])
    console.log(readData, 'herreee')

    function storeInfo(event) {
        const { name, value } = event.target;
        console.log("name,value", name, value);

        setReadData((prev) => ({
            ...prev,
            [name]: value,
        }));
    }
    function storeList(event, index) {

        const { name, value } = event.target;
        console.log("name,value", name, value, index);


        list.forEach((item, i) => {
            if (i === index) {
                item[name] = value
            }
        });

        // setReadData((prev)=>({...prev, servicesList: list }))
        setReadData({ ...readData, servicesList: list })
    }
    console.log(list, 'loooooooooool')
    console.log('final', readData)

   
    const conversion = (inputDate) => {

        if (inputDate) {
            var formattedDate = inputDate.replace(/\//g, '-');
            var dateParts = formattedDate.split('-');
            var day = dateParts[0];
            var month = dateParts[1];
            var year = dateParts[2];
            return year + '-' + month + '-' + day;
        }
    }

   
        // const generatePDF = () => {
            
        //   // Get the element you want to convert to PDF (e.g., a div with id "my-element")
        //   const element = document.getElementById('my-element');
        //   // Use html2canvas to convert the element to a canvas
        //   html2canvas(element).then((canvas) => {
        //     // Create a new instance of jsPDF
        //     const doc = new jsPDF();
        //     // Calculate the width and height of the PDF document
        //     const width = doc.internal.pageSize.getWidth();
        //     const height = doc.internal.pageSize.getHeight();
        //     // Add the canvas to the PDF document
        //     doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, width, height);
        
        //     // Save the PDF
        //     doc.save('invoice.pdf');
        //   });
        // };
        const generatePDF = ()=> {
            console.log('yes')
                    // document.getElementByID("click-element") .addEventListener("Click", () => {
                        const element = document.getElementById('my-element');
                    // const element = document.getElementByID ("my-element");
                    
                    var opt = {
                    
                    margin: 6,
                    padding: 4,
                    filename: "invoice.pdf",
                    
                    image: {type: "jpeg", quality:2},
                    
                    html2canvas: {scale: 2},
                    
                    jsPDF: {unit: 'mm', format: 'a3', orientation: 'p'}
                    };
                    html2pdf().from (element).set (opt).save();
                    
                    
                    }
    return (
        <Box>
           
                
                <Grid container display='flex' justifyContent='space-between' >
                        <Link to={`/ChangeSuitability/${campId}/${applicantId}`}>
                        <Box
                            mt="0.75rem"
                            alignSelf="flex-start"
                            component="img"
                            sx={{
                                height: "0.75rem",
                                width: "0.65rem",
                                cursor: "pointer",
                            }}
                            height="20"
                            alt="Logo"
                            src={ArrowBack}
                        ></Box>
                        </Link>
                        
                <Button
                        variant="contained"
                        sx={{
                            px: "2rem",
                            mx: "1rem",
                            bgcolor: "#FF9529",
                            textTransform: "none",
                            "&:hover": { background: "#FF9529" },
                        }}
                        className="gilroy"
                        onClick={()=>generatePDF()}
                    >
                        Download
                    </Button>
                

                        {/* <Stack direction='column' >
                            <Box
                                component="img"
                                sx={{ height: "50%", width: "40%", my: "1rem" }}
                                alt="Logo"
                                src={Logo}
                            ></Box>
                            <Box sx={{ fontSize: '12px', maxWidth: '45%' }} className='gilroy'>Calm and Connected Pty Ltd5C 11 Erade Drive Piara Waters WA 6112</Box>
                        </Stack> */}
                    </Grid>


<div id="my-element" >
                    <Grid container display='flex' sx={{mx:'1rem'}}>
                       <Stack direction='column' >
                            <Box
                                component="img"
                                sx={{ height: "45%", width: "45%", my: "1rem" }}
                                alt="Logo"
                                src={Logo}
                            ></Box>
                            <Box sx={{ fontSize: '12px' }} className='gilroy'>Calm and Connected Pty  Ltd <br />5C 11 Erade Drive<br /> Piara Waters WA 6112</Box>
                        </Stack> 
                         <Grid item md={4} sm={12} sx={{ alignItems: 'center', alignSelf: 'center' }}>
                    <Box alignContent='center' sx={{ fontSize: '1.45rem' }} className='bold'>TAX INVOICES</Box>
                </Grid> 
                    </Grid>
                
               
                <Grid container display='flex' justifyContent='space-around' my='1rem' >
                    <Grid item sm={12} md={6}>
                        <List >

                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>Services for</Grid>
                                    <Grid item md={4} className="medium">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            size="small"
                                            name='servicesFor'
                                            value={readData && readData.servicesFor}
                                            // onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>DOB</Grid>
                                    <Grid item md={4} className="medium">

                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            size="small"
                                            name='childDOB'
                                            value={readData && readData.childDOB}
                                            // onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>Participant No</Grid>
                                    <Grid item md={4} className="medium">

                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            size="small"
                                            name='particpantsNo'
                                            value={(readData && readData.particpantsNo === '') ? ('NA') : (readData && readData.particpantsNo) }
                                            // onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>Issued To</Grid>
                                    <Grid item md={4} className="medium">

                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            multiline
                                            size="small"
                                            name='isssuedTo'
                                            value={readData && readData.isssuedTo}
                                            // onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>


                        </List>
                    </Grid>
                    <Grid item sm={12} md={6}  >
                        <List >

                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>Invoice Date</Grid>
                                    <Grid item md={4} className="medium">

                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            size="small"
                                            name='invoiceDate'
                                            value={readData && readData.invoiceDate}
                                            // onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem >
                                <Grid container alignItems='center' >
                                    <Grid item md={4}>Invoice number</Grid>
                                    <Grid item md={4} className="medium">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            
                                            size="small"
                                            name='invoiceNumber'
                                            value={readData && readData.invoiceNumber}
                                            onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem >
                                <Grid container>
                                    <Grid item md={4}>Due Date</Grid>
                                    <Grid item md={4} className="medium">

                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            size="small"
                                            name='dueDate'
                                            value={readData && readData.dueDate}
                                            // onChange={(event) => storeInfo(event)}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem >
                                <Grid container alignItems='center'>
                                    <Grid item md={4}>ABN</Grid>
                                    <Grid item md={4} className="medium">
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            
                                            size="small"
                                            name='dueDate'
                                            value='22 613 026 430'
                                            // onChange={(event) => storeInfo(event)}
                                        />

                                    </Grid>
                                </Grid>
                            </ListItem>


                        </List>
                    </Grid>
                </Grid>
           

            <TableContainer component={Paper} sx={{ my: "2rem", mt: "5rem"}}>
                <Table sx={{ minWidth: 400 }} aria-label="customized table">
                    <TableHead>

                        <StyledTableRow >
                            <StyledTableCell align="center">Service by</StyledTableCell>
                            <StyledTableCell align="center">Service</StyledTableCell>
                            <StyledTableCell align="center">Service Date</StyledTableCell>
                            <StyledTableCell align="center">Time</StyledTableCell>
                            <StyledTableCell align="center">Rate</StyledTableCell>
                            <StyledTableCell align="center">Cost</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((item, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    "& .MuiTableCell-root": { py: "0.45rem" },
                                }}
                            >
                                <TableCell component="th" scope="row" align="center" >
                                    <Stack direction="column"
                                        // justifyContent="center"
                                        alignItems="center"
                                        >
                                        <Box sx={{fontSize:'16px'}}>
                                        {item.ServiceBy}
                                            {/* <TextField
                                                id={`serviceBy-${index}`}
                                                variant="standard"
                                                name="ServiceBy"
                                                className="txt-grey"
                                                value={item.ServiceBy}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                // onChange={(event) => handleFieldChange(event, index, 'ServiceBy')}
                                            /> */}
                                        </Box>
                                        <Box>
                                        {item.Profession}
                                            {/* <TextField
                                                id={`serviceBy-${index}`}
                                                variant="standard"
                                                name="Profession"
                                                className="txt-grey"
                                                value={item.Profession}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                size="small"
                                                // sx={{fontSize:'12px'}}
                                                
                                                // onChange={(event) => handleFieldChange(event, index, 'Profession')}
                                            /> */}
                                        </Box>
                                    </Stack>

                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Stack direction="column" alignItems="flex-start" spacing={1}>
                                        <Box>
                                            {/* <TextField
                      id={`service-${index}`}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true
                      }}
                      size="small"
                      name="Service"
                      value={item.Service}
                      onChange={(event) => handleFieldChange(event, index, 'Service')}
                    /> */}
                                            {item.Service}
                                        </Box>
                                        <Box sx={{ color: 'red' }}>
                                        {item.itemCode}
                                            {/* <TextField
                                                id={`itemCode-${index}`}
                                                variant="standard"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                size="small"
                                                name="itemCode"
                                                value={item.itemCode}
                                                // onChange={(event) => handleFieldChange(event, index, 'itemCode')}
                                            /> */}
                                        </Box>
                                    </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                                            {/* <TextField
                      id={`serviceDate-${index}`}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true
                      }}
                      size="small"
                      name="ServiceDate"
                      value={item.ServiceDate}
                      sx={{ width: '60%' }}
                      onChange={(event) => handleFieldChange(event, index, 'ServiceDate')}
                    /> */}
                                            {item.ServiceDate}
                                        </Box>
                                    </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                                            {item.Hours}
                                        </Box>
                                    </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                                            ${item.Rate}
                                        </Box>
                                    </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                                            ${item.Cost}
                                        </Box>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid container justifyContent='flex-end'   >

                <Grid item>
                    <Grid container alignItems='center'>
                        <Grid item mx='0.5rem'>Total</Grid>
                        <Grid item >
                            <TextField
                                id="outlined-basic"
                                // label="Cost"
                                variant="outlined"
                                // InputProps={{
                                //     readOnly: true
                                // }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                size="small"
                                value={` $ ${invoiceInfo.total}`}
                                sx={{ width: 100 }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <Grid container justifyContent='flex-end'   >
                <Grid item>
                    <Box sx={{ fontSize: '12px' }}>GST included in total amount $0.00</Box>
                </Grid>
            </Grid>

            <Grid>
                <List className='medium'>
                    {console.log(readData && readData.paymentRefno)}
                    <ListItem  >Please put the following reference for bank transfer:<span className='bold'>{readData && readData.paymentRefno}</span></ListItem>
                    <ListItem>Payment Details:</ListItem>
                    <ListItem>Account Name: <span  className='bold'>Calm and Connected</span></ListItem>
                    <ListItem>BSB: <span  className='bold'>062692</span></ListItem>
                    <ListItem>Account No: <span className='bold'>77274604</span></ListItem>
                    <ListItem>Payment is due within 7 days of receiving. Thank you for your prompt payment</ListItem>
                </List>
            </Grid>
                </div>
                    
            
        </Box>
    );
}
