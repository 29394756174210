import React, { useRef, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import image from "./Assets/Logo-Main.png";
import Image from "./Assets/candcimg1.png";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Grid, Stack } from "@mui/material";
import "../App.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { apiService } from "../Services/apiService";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import { deepOrange } from "@mui/material/colors";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import cameraIcon from "../Assets/Icons/Icons/cameraIcon.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import axios from "axios";
import Circle from "../Assets/Icons/Icons/Circle.png";
import SelectedCircle from "../Assets/Icons/Icons/SelectedCircle.png";

import { FormHelperText } from "@mui/material";
import {
  SERVER_URL,
  UPDATE_STAFF_PROFILE,
  GET_PROFILE,
  UPLOAD_PROFILE_PIC,
  SIGNUP,
} from "../Services/apiEndpints";




export default function Signup() {
  const history = useHistory();
  const current = new Date();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [getUserData, setGetUserData] = useState({});
  const [today, setToday] = React.useState(null);
  const [title, setTitle] = React.useState("");
  const [viewData, setViewData] = React.useState({});
  const [thumbnailFile, setThumbnailFile] = React.useState(null);
  const [profilePath, setProfilePath] = React.useState(null);

  const [username, setUserName] = React.useState(null);

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dateofBirth: "",
    address: "",
    email: "",
    mobileNumber: "",
    password: "",
    userRole: "",
    title: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    gender: null,
    address: null,
    mobileNumber: null,
    title: null,
    userRole: null,
    dateofBirth: null,
  });
  React.useEffect(() => {
    var date = new Date();
    var day = date.getTime() - 1 * 24 * 60 * 60 * 1000;
    date.setTime(day);
    const newDateVal = formatDate(new Date(date).toISOString().substr(0, 10));
    setToday(newDateVal);
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const theme2 = createTheme({
    palette: {
      primary: {
        main: "#379592", // Set your desired checkbox color
      },
    },
  });


  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },

    components: {
      MuiInputLabel: {
        styleOverrides: {
          asterisk: {
            color: "red",
            fontSize: 24,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": {
            fontFamily: "gilroyMedium",
            src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
          },
        },
      },
    },
  });

  const passwordRegExp =
    // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;
    

  const doSomething = async (value) => {
    var date = new Date(value);
    var day = date.getTime() - 10 * 24 * 60 * 60 * 1000;
    date.setTime(day);
    const newDateVal = formatDate(new Date(date).toISOString().substr(0, 1));
    setToday(newDateVal);
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    let formObj = {};

    formObj = { ...form, [name]: value };

    setForm(formObj);

    validateField(name, formObj[name]);
  };

  const validateField = (name, value) => {
    let errorMsg = null;

    switch (name) {
      case "gender":
        if (!value) errorMsg = "Gender is required.";

        break;

      case "mobileNumber":
        if (!value) errorMsg = "Please enter contact number.";
        else if (!/^[0-9]{9,10}$/.test(value)) {
          errorMsg = "Contact number should be 9 or 10 digits.";
        }

        break;

      case "title":
        if (form.userRole === "Support Staff") {
          if (!value) errorMsg = "Please enter Profession.";
        }

        break;

      case "userRole":
        if (!value) errorMsg = "Please enter Role.";

        break;

      case "firstName":
        if (!value) errorMsg = "Please enter firstname.";

        break;

      case "lastName":
        if (!value) errorMsg = "Please enter lastname.";

        break;

      // case "email":
      //   if (!value) errorMsg = "Please enter email.";

      //   break;
      case "email":
        if (!value) errorMsg = "Email is required.";
        else if (!/^\S+@\S+\.\S+$/.test(value)) {
          errorMsg = "Invalid email address.";
        }
        break;

      case "dateofBirth":
        if (!value) errorMsg = "Date of birth is required.";

        break;

      case "address":
        if (!value) errorMsg = "Address is required.";

        break;

      case "password":
        if (!value) errorMsg = "Password is required.";
        else if (!passwordRegExp.test(value)) {
          errorMsg =
            "Must be 8 characters with at least 1 uppercase, 1 lowercase, 1 number, and 1 special character.";
        }
        else if(value === form.ConfirmPassword) {
          formErrors.ConfirmPassword = "";
          
        }

        break;

      case "ConfirmPassword":
        if (!value) errorMsg = "Confirm Password should match with password.";
        
        else if (value !== form.password) {
          errorMsg = "Passwords do not match.";
         
        }
        
        

        break;

      default:
        break;
    }

    setFormErrors({ ...formErrors, [name]: errorMsg });

    return errorMsg;
  };

  console.log("i am here", form);

  // const { register } = useForm({
  //   defaultValues: async () => await getProfileDetails(),
  // });
  const validateForm = () => {
    const errors = {};

    Object.keys(form).forEach((name) => {
      const value = form[name];

      const error = validateField(name, value, form.password);

      if (error) {
        errors[name] = error;
      }
    });

    return errors;
  };
  async function uploadThumnails(file) {
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (file.size > maxSize) {
      Swal.fire({
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
        text: "Please select image size less than 2 MB.",
      });
      return; // Exit the function
    }

    const forminputsTwo = new FormData();
    forminputsTwo.append("image", file);
    forminputsTwo.append("userId", localStorage.getItem("userId"));

    console.log(forminputsTwo, "sjcakdjcbdcj");
    try {
      const response = await axios.post(
        `${SERVER_URL}${UPLOAD_PROFILE_PIC}`,
        forminputsTwo,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            tokenheaderkey: localStorage.getItem("token"),
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file.name);

    const reader = new FileReader();
    setThumbnailFile(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);
    uploadThumnails(event.target.files[0]);
    // read file as data url

    reader.onload = (event) => {
      console.log(event.target.result);

      // called once readAsDataURL is completed

      setProfilePath(event.target.result);
    };
  };

  const conversion = (inputDate) => {
    if (inputDate) {
      var formattedDate = inputDate.replace(/\//g, "-");
      var dateParts = formattedDate.split("-");
      var day = dateParts[0];
      var month = dateParts[1];
      var year = dateParts[2];
      return year + "-" + month + "-" + day;
    }
  };

  const handleFormSubmit = async () => {
    const errors = validateForm();

    setFormErrors(errors);
if (Object.keys(errors).length === 0) {
  let formValues = {
    firstName: form.firstName,
    lastName: form.lastName,
    gender: form.gender,
    dateofBirth: form.dateofBirth,
    address: form.address,
    email: form.email,
    mobileNumber: form.mobileNumber,
    password: form.password,
    userRole: form.userRole,
    // title:form.title
    // form.userRole === "Support Staff"
  };

  let apiInfo = {
    url: `${SERVER_URL}${SIGNUP}`,
    method: "POST",
    postData: formValues,
    headers: {
      "Content-Type": "application/json",
      tokenheaderkey: localStorage.getItem("token"),
    },
  };
  let str = JSON.stringify(formValues);
     localStorage.setItem('contacts',str)
     localStorage.setItem("profession", form.title);
     history.push("/Emergency");
  // if (form.userRole === "Support Staff") {
  //   apiInfo.postData.userRole = "4";

  //   apiInfo.postData.title = form.title;
  //   let str = JSON.stringify(formValues);
  //   localStorage.setItem('contacts',str)
  //   // const register = await apiService(apiInfo);
  //   // localStorage.setItem("userIdSignup", register.data.userId);
  //   // localStorage.setItem("signupemail", form.email);
  //   history.push("/Emergency");
  // } else if (form.userRole === "Parent") {
  //   apiInfo.postData.userRole = "7";
  //   // let register = await apiService(apiInfo);
  //   // localStorage.setItem("userIdSignup", register.data.userId);
  //   // localStorage.setItem("signupemail", form.email);
  //   history.push("/Emergency");
  // }
}


else{
  // console.log(formValues);
}

    

  };
React.useEffect(()=>{
  if(localStorage.getItem('contacts') !== null) {
setForm({
  firstName: JSON.parse(localStorage.getItem("contacts")).firstName,
  lastName: JSON.parse(localStorage.getItem("contacts")).lastName,
  gender: JSON.parse(localStorage.getItem("contacts")).gender,
  dateofBirth: JSON.parse(localStorage.getItem("contacts")).dateofBirth,
  address: JSON.parse(localStorage.getItem("contacts")).address,
  email: JSON.parse(localStorage.getItem("contacts")).email,
  mobileNumber: JSON.parse(localStorage.getItem("contacts")).mobileNumber,
  password: "",
  userRole: JSON.parse(localStorage.getItem("contacts")).userRole,
  title: localStorage.getItem("profession"),
});
  }

},[]
)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        container
        direction="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100vh",
          backgroundImage: `url(${Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Grid item>
          <Card sx={{ borderRadius: "20px" }}>
            <CardContent
              sx={{
                mx: "0.5rem",
                maxHeight: "1500px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                component="form"
                xs={4}
                md={4}
                sm={6}
                noValidate
                autoComplete="off"
              >
                <Grid container>
                  <Grid item md={4}>
                    <Box sx={{ alignItems: "center" }}>
                      <CardMedia
                        component="img"
                        height="25%"
                        image={image}
                        alt="Logo"
                        sx={{ width: "50%" }}
                      />
                    </Box>
                    {/* <Grid my={1}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png"
                      />
                      <Box>
                        {profilePath === null ? (
                          <Box className="main-div">
                            <Box
                              my="0.5rem"
                              textAlign="center"
                              sx={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "50px",

                                color: "#fff",
                                paddingTop: "2rem",
                                fontSize: "20px",
                                backgroundColor: "#379592",
                              }}
                              clasaName="bold"
                            >
                              {username}
                            </Box>
                            <Box className="camera-ico">
                              <img onClick={handleClick} src={cameraIcon} />
                            </Box>
                          </Box>
                        ) : (
                          <Box className="main-div">
                            <Box
                              mt="1rem"
                              component="img"
                              src={profilePath}
                              sx={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "50px",
                              }}
                            ></Box>
                            <Box className="camera-ico">
                              <img onClick={handleClick} src={cameraIcon} />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid> */}

                    <Grid item mt={1}>
                      <TextField
                        id="standard-basic"
                        label="First Name"
                        variant="standard"
                        name="firstName"
                        value={form.firstName}
                        onChange={handleChange}
                        sx={{ width: "80%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {formErrors.firstName && (
                        <FormHelperText error>
                          {formErrors.firstName}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item mt={1}>
                      <TextField
                        id="standard-basic"
                        label="Last Name"
                        variant="standard"
                        name="lastName"
                        value={form.lastName}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: "80%" }}
                      />
                      {formErrors.lastName && (
                        <FormHelperText error>
                          {formErrors.lastName}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item mt={1}>
                      <TextField
                        id="standard-basic"
                        label="Email"
                        variant="standard"
                        name="email"
                        value={form.email}
                        onChange={handleChange}
                        sx={{ width: "80%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {formErrors.email && (
                        <FormHelperText error>
                          {formErrors.email}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>

                  <Grid md={8}>
                    <form>
                      <Grid mb={1} mr={5} sx={{ textAlign: "center" }}>
                        Profile
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <Grid item mt={0.5}>
                            <FormControl
                              variant="standard"
                              sx={{ minWidth: 220 }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Role
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Profession"
                                name="userRole"
                                value={form.userRole}
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              >
                                <MenuItem value="Support Staff">
                                  Support Staff
                                </MenuItem>
                                <MenuItem value="Parent">Parent</MenuItem>
                              </Select>
                            </FormControl>
                            {formErrors.userRole && (
                              <FormHelperText error>
                                {formErrors.userRole}
                              </FormHelperText>
                            )}
                          </Grid>

                          <Grid item mt={1}>
                            <TextField
                              id="standard-basic"
                              label="Mobile"
                              variant="standard"
                              name="mobileNumber"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +61
                                  </InputAdornment>
                                ),
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={form.mobileNumber}
                              onChange={handleChange}
                              onBlur={() => ("mobile", form.mobileNumber)}
                              sx={{ width: "80%" }}
                            />

                            {formErrors.mobileNumber && (
                              <FormHelperText error>
                                {formErrors.mobileNumber}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item mt={1}>
                            <TextField
                              id="standard-basic"
                              label="Address"
                              variant="standard"
                              name="address"
                              sx={{ width: "80%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={form.address}
                              onChange={handleChange}
                              onBlur={() => ("Address", form.address)}
                            />
                            {formErrors.address && (
                              <FormHelperText error>
                                {formErrors.address}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item mt={1}>
                            <TextField
                              id="standard-basic"
                              label="New Password"
                              variant="standard"
                              name="password"
                              type={showPassword ? "text" : "password"}
                              value={form.password}
                              // {...register("password")}
                              sx={{ width: "80%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleChange}
                              onBlur={() => ("password", form.password)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {formErrors.password && (
                              <Typography
                                variant="body1"
                                color="error"
                                sx={{ fontSize: "10px" }}
                              >
                                {formErrors.password}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item md={6}>
                          {form.userRole === "Support Staff" && (
                            <Grid item mr={1}>
                              <FormControl
                                variant="standard"
                                sx={{ mt: 0.5, minWidth: 220, mb: 0.5 }}
                              >
                                <InputLabel id="demo-simple-select-standard-label">
                                  Profession
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  label="Profession"
                                  name="title"
                                  value={form.title}
                                  onChange={handleChange}
                                  onBlur={() => ("title", form.title)}
                                >
                                  <MenuItem value="Occupational Therapist">
                                    Occupational Therapist
                                  </MenuItem>
                                  <MenuItem value="Speech Pathologist ">
                                    Speech Pathologist{" "}
                                  </MenuItem>
                                  <MenuItem value="Therapy Assistant">
                                    Therapy Assistant
                                  </MenuItem>
                                  <MenuItem value="Psychologist">
                                    {" "}
                                    Psychologist
                                  </MenuItem>
                                  <MenuItem value="Therapy Student">
                                    Therapy Student
                                  </MenuItem>
                                </Select>
                              </FormControl>

                              {formErrors.title && (
                                <FormHelperText error>
                                  {formErrors.title}
                                </FormHelperText>
                              )}
                            </Grid>
                          )}
                          <Grid item mt={0.5}>
                            <TextField
                              type="date"
                              id="standard-basic"
                              label="DOB"
                              variant="standard"
                              name="dateofBirth"
                              sx={{ width: "80%" }}
                              value={form.dateofBirth}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: today,
                              }}
                              onChange={handleChange}
                              onBlur={() => ("DOB", form.dateofBirth)}
                            />
                            {formErrors.dateofBirth && (
                              <FormHelperText error>
                                {formErrors.dateofBirth}
                              </FormHelperText>
                            )}
                          </Grid>

                          <Grid item mt={0.75}>
                            <ThemeProvider>
                              <Box sx={{ color: "#707070", fontSize: 12 }}>
                                Gender
                              </Box>
                              {/* <RadioGroup
                                row
                                name="Gender"
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                className="small colorSecondary"
                                // value={form.Gender}
                                onChange={handleChange}
                                
                              > */}
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                className="small colorSecondary"
                                // {...controlProps('e')}

                                sx={{
                                  mt: 0.5,
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 10,
                                  },

                                  "& .MuiFormControlLabel-label": {
                                    fontSize: 12,
                                  },

                                  "& .MuiButtonBase-root.MuiRadio-root.Mui-checked ":
                                    {
                                      color: "#379592",
                                    },
                                }}
                                name="gender"
                                value={form.gender}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  className="small"
                                  value="Male"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 10 },
                                        "& .MuiFormControlLabel-label": {
                                          fontSize: 10,
                                        },
                                      }}
                                    />
                                  }
                                  label="Male"
                                />
                                <FormControlLabel
                                  className="small"
                                  value="Female"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 10 },
                                      }}
                                    />
                                  }
                                  label="Female"
                                />
                                <FormControlLabel
                                  className="small"
                                  value="Prefer not to say"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 10 },
                                      }}
                                    />
                                  }
                                  label="Prefer not to say"
                                />
                              </RadioGroup>
                            </ThemeProvider>
                            {formErrors.gender && (
                              <FormHelperText error>
                                {formErrors.gender}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item mt={1}>
                            <TextField
                              id="standard-basic"
                              label="Confirm Password"
                              variant="standard"
                              // type="password"
                              // {...register("ConfirmPassword")}
                              name="ConfirmPassword"
                              type={showConfirmPassword ? "text" : "password"}
                              value={form.ConfirmPassword}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ width: "80%" }}
                              onChange={handleChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowConfirmPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                                      {showConfirmPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />

                            {formErrors.ConfirmPassword && (
                              <FormHelperText error>
                                {formErrors.ConfirmPassword}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item mt={3}>
                        <Button
                          variant="contained"
                          fullWidth
                          //   type="submit"
                          onClick={handleFormSubmit}
                          sx={{
                            px: "1rem",
                            bgcolor: "#379592",
                            textTransform: "none",
                            "&:hover": { background: "#379592" },
                          }}
                        >
                          Next
                        </Button>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  color: "#379592",
                  fontSize:"12px",
                  textAlign:"right",
                  mt:"0.75rem",
                }}
                className="bold"
              >
                Calm & Connected staff have "invite only" access to the
                application and need to be invited by admin.
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
