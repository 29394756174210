import { configureStore } from '@reduxjs/toolkit'
import counterSlice from './slices/counterSlice'
import campSettingSlice from './slices/campSettingSlice' 


export const store = configureStore({
  reducer: {
  counter :  counterSlice,
  Apple :  counterSlice,
  Camp : campSettingSlice
  },
})