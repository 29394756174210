import React, { useState } from "react";
import Button from "@mui/material/Button";

import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";

import { apiService, apiService2 } from "../../Services/apiService";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import {
  GETSTAFF_CAMPAPPLICANTS,
  DECLINE_APPLICATIONS,
  SERVER_URL,
  UPDATECAMP_REGSTATUS,
} from "../../Services/apiEndpints";
export default function SupportStaffApplicants() {
  const getRegStatus = localStorage.getItem("RegStatus");
  const [datatry, setDataTry] = React.useState([]);
  const [appStatus, setAppStatus] = React.useState("All");
  const [loading, setLoading] = React.useState(false);
  // const [campApplicantList, setCampApplicantList] = React.useState();
  const [campApplicantList, setCampApplicantList] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const [regToggle, setRegToggle] = React.useState(getRegStatus);

  let { campId } = useParams();
  const history = useHistory();
  console.log("campId999", campId);
  const getCampName = localStorage.getItem("CampName");

  const handleChange1 = (event) => {
    setAppStatus(event.target.value);
  };

  let filteredList =
    campApplicantList &&
    campApplicantList.length &&
    campApplicantList.filter((item) => {
      return item.status.toLowerCase().includes(appStatus.toLowerCase());
    });

  filteredList = appStatus === "All" ? campApplicantList : filteredList;

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      let gettran = {
        userId: localStorage.getItem("userId"),
        campId: campId,
      };

      // let inputData = {
      //   userId: localStorage.getItem("userId"),
      //   campId : '5133'
      // };
      console.log("Campid", campId);
      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/staff/getstaffcampapplicants",
        url: `${SERVER_URL}${GETSTAFF_CAMPAPPLICANTS}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",

          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let login = await apiService(apiInfo);

      let campApplicantList = login.data.responseData[0];
      console.log(login?.data?.responseCode)
      if(login?.data?.responseCode === 0){
        setLoading(false);

        setCampApplicantList(campApplicantList);
        campApplicantList.forEach((element) => {
          if (element.StaffList != null) {
            console.log(element.StaffList);
            element.StaffList = JSON.parse(element.StaffList);
            console.log(element.StaffList);
          }
        });
      }
      else if(login?.data?.responseCode === 1){
        Swal.fire({
          icon: 'error',
          text: login?.data?.responseData ,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
          },
      }).then(() => {
        setLoading(false)
        history.push("/SupportStaffCampList");
      });
      
    
      console.log(campApplicantList);
    }
      // console.log("campDataList",campDataList[0]);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);

  const supportStaffCampList = datatry;

  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    palette: {
      secondary: {
        main: "#379592",
      },
    },

    Muidisabled: {
      styleOverrides: {
        backgroundColor: "purple",
      },
    },
  });

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      backgroundColor: "rgba(55, 149, 146, 1)",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleDecline = async () => {
    let dataSent = {
      userId: localStorage.getItem("userId"),
      campId: campId,
      applicationStatus: appStatus,
      applicationsType: "SUPPORT_STAFF",
    };

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/camps/declineapplications",
      url: `${SERVER_URL}${DECLINE_APPLICATIONS}`,
      method: "POST",
      postData: dataSent,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let output = await apiService2(apiInfo);
    console.log(output.data.responseData[0]);

    if (output?.data?.responseData[0][0].Code === "0") {
      let statusOutput = output.data.responseData[0][0].Desc;
      Swal.fire({
        iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
        text: statusOutput,
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
        customClass: {
          container: "my-swal",
        },
      }).then(() => {
        setReload((prev) => !prev);
        setAppStatus("All");
      });
    }
  };

  const handleRegistrations = async () => {
    console.log('clicked')
    

    let stat = ''

    if (regToggle === 'REGISTRATION OPEN') {
      stat = 'CLOSED'
    }
    else {
      stat = 'OPEN'
    }

    let dataSent = {
      userId: localStorage.getItem('userId'),
      campId: campId,
      registrationStatus: stat
    }

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/camps/updatecampregstatus",
      url: `${SERVER_URL}${UPDATECAMP_REGSTATUS}`,
      method: "POST",
      postData: dataSent,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },

    }

    let output = await apiService2(apiInfo)
    console.log(output.data.responseData[0])
    if (output?.data?.responseData[0][0].Code === "0") {
      let statusOutput = output.data.responseData[0][0].Desc
      Swal.fire({
        iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
        text: statusOutput,
        confirmButtonText: 'OK',
        confirmButtonColor: "#379592",
        customClass: {
          container: "my-swal",

        },
      }).then(() => {
        history.push("/SupportStaffCampList");
      });
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Grid container direction="flex" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <Link className="link2" to="/SupportStaffCampList">
              <Box
                // alignSelf="center"

                component="img"
                sx={{
                  height: "0.75rem",
                  width: "0.65rem",
                  cursor: "pointer",
                  mt: "0.75rem",
                }}
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>

            <Box alignSelf="center">{getCampName}</Box>
          </Stack>
          <Grid item className="btns">
            <Button
              variant="contained"
              // disabled = {campApplicantList && campApplicantList[0].wpAvailable !== 'Yes'}
              disabled={
                campApplicantList &&
                campApplicantList.length > 0 &&
                campApplicantList[0].wpAvailable !== "Yes"
              }
              sx={{
                bgcolor: "#4C9F70",
                color: "white",
                textTransform: "none",
                "&:hover": { background: "#4C9F70", color: "white" },
                //   width: "150px",
                mx: "0.5rem",
              }}
              className="gilroy viewwelcomepack-btn"
              onClick={() =>
                history.push(`/SupportStaffWelcomePackRepublish/${campId}`)
              }
            >
              View Welcome Pack
            </Button>
            {/* {console.log("campApplicantListwpAvailable",campApplicantList &&campApplicantList[0].wpAvailable )} */}
            {/* <Link to={`/SupportStaffCreateWelcomPack`}> */}
            <Button
              variant="contained"
              // disabled = {campApplicantList && campApplicantList[0].wpAvailable === 'Yes'}
              disabled={
                campApplicantList &&
                campApplicantList.length > 0 &&
                campApplicantList[0].wpAvailable === "Yes"
              }
              sx={{
                bgcolor: "#6E9075",
                color: "white",
                textTransform: "none",
                "&:hover": { background: "#6E9075", color: "white" },
                //   width: "150px",
                mx: "0.5rem",
              }}
              className="gilroy createwelcomepack-btn"
              onClick={() =>
                history.push(`/SupportStaffCreateWelcomPack/${campId}`)
              }
            >
              Create Welcome Pack
            </Button>
            {/* </Link> */}
            <Button
              onClick={handleDecline}
              variant="contained"
              sx={{
                bgcolor: "#F21010",
                color: "white",
                textTransform: "none",
                "&:hover": { background: "#F21010", color: "white" },
                mx: "0.5rem",
                //   width: "150px",
              }}
              className="gilroy declineapp-btn"
            >
              Decline Application
            </Button>
            {/* <Button
              onClick={handleRegistrations}
              variant="contained"
              sx={{
                bgcolor:
                  getRegStatus === "Registrations Open" ? "#F21010" : "#4C9F70",
                color: "white",
                textTransform: "none",
                "&:hover": {
                  background:
                    getRegStatus === "Registrations Open"
                      ? "#F21010"
                      : "#4C9F70",
                  color: "white",
                },

                mx: "0.5rem",
              }}
              className="gilroy"
            >
              {getRegStatus === "Registrations Open"
                ? "Close Registration"
                : "Open Registration"}
            </Button> */}
            <Button
              onClick={handleRegistrations}
              variant="contained"
              sx={{
                bgcolor:
                  getRegStatus === "REGISTRATION OPEN" ? "#F21010" : "#4C9F70",
                color: "white",
                textTransform: "none",
                "&:hover": {
                  background:
                    getRegStatus === "REGISTRATION OPEN"
                      ? "#F21010"
                      : "#4C9F70",
                  color: "white",
                },
                mx: "0.5rem",
              }}
              className="gilroy registration-btn"
            >
              {getRegStatus === "REGISTRATION OPEN"
                ? "Close Registration"
                : "Open Registration"}
            </Button>
            {/* <Link to="/SupportStaffReminderLog" className="link2">
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#FF9529",
                  color: "white",
                  textTransform: "none",
                  "&:hover": { background: "#FF9529", color: "white" },

                  mx: "0.5rem",
                }}
                className="gilroy"
              >
                Reminders
              </Button>
            </Link> */}
          </Grid>
        </Grid>

        <Grid container display="flex" alignItems="center" mt="0.5rem">
          <Box my="0.5rem">
            {appStatus} ({filteredList && filteredList.length})
          </Box>
          <Box>
            <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
              <InputLabel id="demo-simple-select-autowidth-label">
                Select
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={appStatus}
                onChange={handleChange1}
                label="Select"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Application Submitted">
                  Application Submitted
                </MenuItem>
                <MenuItem value="In Progress">In Progress</MenuItem>
                <MenuItem value="Confirmed">Confirmed</MenuItem>
                <MenuItem value="DECLINED">Declined</MenuItem>
                {/* <MenuItem value="Waitlisted">Waitlisted</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {loading === true ? (
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 450 }}
              aria-label="customized table"
              my="1rem"
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Sl. No</StyledTableCell>
                  <StyledTableCell align="center">Applicants</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {filteredList.length > 0 &&
                  filteredList.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "& .MuiTableCell-root": { py: "0.45rem" },
                      }}
                    >
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.applicantName}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={{
                          fontWeight:'medium',
                          color: row.status=== 'Waitlisted' 
                          ? ( 'primary.main') 
                          : (row.status=== 'Confirmed' 
                          ? ('#78C091')
                           :(row.status === 'DECLINED' ? ('#F21010') : ('black')))
                      }}
                      >
                        {row.status}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        <Link
                          to={`/ChangeFunding/${row.campId}/${row.staffApplicationId}/${row.staffId}`}
                          className="link2"
                        >
                          <Button
                            variant="contained"
                            sx={{
                              bgcolor: "#718F94",
                              textTransform: "none",
                              "&:hover": { background: "#718F94" },
                              width: "100px",
                            }}
                          >
                            View
                          </Button>
                        </Link>
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </ThemeProvider>
  );
}
