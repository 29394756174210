import React, { useState } from "react";
import Button from "@mui/material/Button";

import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";

import { apiService, apiService2 } from "../../Services/apiService";
import moment from "moment";
import { Link } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png";
import { GET_PARTICIPANTSCOUNT , SERVER_URL} from '../../Services/apiEndpints'

function ParticipantsList() {
  const [campList, setCampList] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    palette: {
      secondary: {
        main: "#379592",
      },
    },

    Muidisabled: {
      styleOverrides: {
        backgroundColor: "purple",
      },
    },
  });

  const handleSetCampId = (regStatus, campName) => {
    localStorage.setItem("RegStatus", regStatus.toUpperCase());
    localStorage.setItem("CampName", campName);
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      let dataSent = {
        userId: localStorage.getItem("userId"),
        // userId: 8,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/getparticipantscount",
        url: `${SERVER_URL}${GET_PARTICIPANTSCOUNT}`,
        method: "POST",
        postData: dataSent,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let output = await apiService2(apiInfo);
      
      let campListData = output?.data?.responseData[0];
      if (Array.isArray(campListData)) {
        setCampList(campListData);
      }
      setLoading(false);
      // setCampList(campListData);
      
    })();

    // disabling back button for this page
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });

  }, []);

  // console.log(campList)

  return (
    // <ThemeProvider theme={theme}>
    // <CssBaseline />
    // <UserContext.Provider value="Reed">
    <Box>
      <Box>Participants</Box>
      {loading === true ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Box>
          {campList &&
            campList.map((list, index) => (
              <Box
                key={index}
                onClick={() => handleSetCampId(list.regStatus, list.campName)}
              >
                <Link to={`/ApplicantsReview/${list.campId}`} className="link2">
                  <Card
                    variant="outlined"
                    className="cardcamplist"
                    sx={{
                      backgroundColor: "#F8F8F8",
                      color: "#707070",
                      my: "0.75rem",
                      border: 1,
                      borderColor: "#379592",
                      borderRadius: "0.75rem",
                      py: "0rem",
                     
                    }}
                  >
                    <CardContent>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Grid container display="flex" alignItems="center">
                            <Box mx="0.75rem" sx={{ color: "#379592" }}>
                              {list.campName}
                            </Box>
                            <Chip
                              label={
                                list.campType.charAt(0).toUpperCase() +
                                list.campType.slice(1).toLowerCase()
                              }
                              variant="outlined"
                              size="small"
                              className="medium"
                              mx="0.75rem"
                              sx={{
                                color: "#707070",
                                backgroundColor: "white",
                                fontSize: "0.5rem",
                                px: "0.5rem",
                                height: "20px",
                              }}
                            />
                            <Box
                              mx="0.75rem"
                              sx={{ fontSize: "0.85rem", color: "#000000" }}
                              className="medium"
                            >
                              Start Date: {list.campStartDate}
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item className="status-reg">
                          <Grid container display="flex" alignItems="center">
                            <Chip
                              label={
                                list.regStatus.charAt(0).toUpperCase() +
                                list.regStatus.slice(1).toLowerCase()
                              }
                              variant="outlined"
                              size="small"
                              mx="0.75rem"
                              className="medium"
                              sx={{
                                // color:'white',
                                // backgroundColor: (list.regStatus==='REGISTRATION OPEN') ? "#78C091" : "#F21010", 
                                color:
                                      list.regStatus === "DRAFT"
                                        ? "black"
                                        : "white",
                                    backgroundColor:
                                      list.regStatus === "DRAFT"
                                        ? "#E2F157"
                                        : (list.regStatus === "REGISTRATION OPEN") ? ("#78C091") : (list.regStatus === "ACTIVE") ? "#FF9529" : (list.regStatus === "COMPLETED" ? ("#78C091") : (list.regStatus === "IN PROGRESS" ? '#FF9529':(list.regStatus === "REGISTRATION CLOSED") ? '#F21010' : '#78C091' )),
                                px: "1rem",
                                py: "0.85rem",
                              }}
                            />
                            <Box
                              component="img"
                              sx={{
                                height: "1rem",
                                width: "1rem",
                                alignSelf: "center",
                              }}
                              src={BlackUser}
                              alignSelf="center"
                              ml="0.75rem"
                              mr="0.5rem"
                            ></Box>
                            <Box sx={{ fontSize: "15px" }}>
                              {list.countstatus}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Link>
              </Box>
            ))}
        </Box>
      )}
    </Box>

    /* </UserContext.Provider> */

    // </ThemeProvider>
  );
}
// export const UserContextList = () => React.useContext(UserContext)

export default ParticipantsList;
