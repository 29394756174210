import React, { useState } from "react";
import Button from "@mui/material/Button";

import {
    CardActionArea,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,

    Chip,
    CardContent,
    Divider,
    Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";

import { apiService, apiService2 } from "../../Services/apiService";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png"
import TextField from "@mui/material/TextField";
import Swal from 'sweetalert2'
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import dayjs from 'dayjs';
import { UPDATESUITABILITY_STATUS, SERVER_URL } from '../../Services/apiEndpints'



export default function PendingSuitabilityPreScreen() {
    const theme = createTheme({
        typography: {
            fontFamily: "gilroyMedium",
        },
        palette: {
            secondary: {
                main: "#379592",
            },
        },

        Muidisabled: {
            styleOverrides: {
                backgroundColor: "purple",
            },
        },
    });
    let { campId, applicantId } = useParams()
    const history = useHistory();
    const [scheduleDetails, setScheduleDetails] = React.useState({ time: '', date: '', location: '' })
    const [latestDate, setLatestDate] = React.useState(null);
    const [value, onChange] = React.useState('');
    const handleChangeSchedule = (event) => {
        // console.log(event.target)
        let { name, value } = event.target
        setScheduleDetails((prev) => (
            { ...prev, [name]: value }
        ))
        // console.log('date is coming',scheduleDetails.time)
        // var s = moment(scheduleDetails.date).format("DD-MM-YYYY");
        // console.log(s)
        // setScheduleDetails((prev)=>(
        //     {...prev, date : s}
        // ))
        // console.log(scheduleDetails)
    }
    // console.log(scheduleDetails)
    const handleSwalNotification = () => {
        Swal.fire({
            text: 'Notification sent to Parent: A pre screen has been booked for CHILD NAME to attend CAMP NAME on Date, time, location',
            confirmButtonText: 'OK',
            confirmButtonColor: '#379592',
            padding: '1.5rem',
            customClass: {
                container: "my-swal",
                icon: 'no-border'

                //    confirmButton: 'your-confirm-button-class',
            },
        }).then(() => {
            history.push(`/ChangeSuitability/${campId}/${applicantId}`);
        });
    }
    const inputProps = {
        step: 300,
    };


    let childId = localStorage.getItem('childId')

    let data = `${moment(scheduleDetails.date).format("DD-MM-YYYY")} ${scheduleDetails.time} ${scheduleDetails.location}`
    console.log(data)

    const submitSchedulingDetails = async () => {
        console.log(scheduleDetails.time, scheduleDetails.date, scheduleDetails.location, 'dataaaaaaa')
        if (scheduleDetails.time !== '' && scheduleDetails.date !== '' && scheduleDetails.location !== '') {

            let dataSent = {
                userId: localStorage.getItem('userId'),
                campId: campId,
                applicationId: applicantId,
                childId: childId,
                suitabilityStatus: "Pending Suitability-Pre screen",
                suitabilityRemarks: data
            }

            let apiInfo = {
                // url: "https://cncdevapi.natsoft.io/api/camps/updatesuitabilitystatus",
                url: `${SERVER_URL}${UPDATESUITABILITY_STATUS}`,
                method: "POST",
                postData: dataSent,
                headers: {
                    "Content-Type": "application/json",
                    tokenheaderkey: localStorage.getItem("token"),
                },
            }
            let output = await apiService2(apiInfo)
            if (output?.data?.responseData[0][0].Code === "0") {

                let statusOutput = output.data.responseData[0][0].Desc
                // Swal.fire({
                //     iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
                //     text: statusOutput,
                //     confirmButtonText: 'OK',
                //     confirmButtonColor: "#379592",
                //     customClass: {
                //         container: "my-swal",

                //     },
                // })
                Swal.fire({
                    iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
                    text: statusOutput,
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#379592',
                    padding: '1.5rem',
                    customClass: {
                        container: "my-swal",
                        icon: 'no-border'

                        //    confirmButton: 'your-confirm-button-class',
                    },
                }).then(() => {
                    history.push(`/ChangeSuitability/${campId}/${applicantId}`);
                });

            }

        }

        else {

            Swal.fire({
                icon: 'error',
                text: 'Date,Time and Comments needed',
                confirmButtonText: 'OK',
                confirmButtonColor: "#379592",
                customClass: {
                    container: "my-swal",

                },
                customClass: {
                    container: "my-swal",
                    // icon: 'no-border'

                    //    confirmButton: 'your-confirm-button-class',
                },
            })
        }


    }
    React.useEffect(() => {
        var date = new Date();
        var day = date.getTime();
        date.setTime(day);
        const newDate = formatDate(new Date(date).toISOString().substr(0, 10))
        setLatestDate(newDate)
    }, [])
    console.log(latestDate)

    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box>
                <Box sx={{ textAlign: 'center' }}>Pending Suitability - Pre Screen</Box>
                <Box className='medium'>
                    <Box my='2rem' sx={{ color: '#3B3B3B' }}>Please choose the suitable day and time</Box>
                    {/* {scheduleDetails.date && moment(scheduleDetails.date).format("DD-MM-YYYY")} */}
                    <Stack direction='row' alignItems='center' spacing={4} my='2rem'>
                        <Box>Date :</Box>
                        <TextField
                            id="date"
                            variant="outlined"
                            size='small'
                            type="date"
                            name='date'
                            sx={{ width: 200 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // inputProps={{
                            //     min: latestDate,

                            // }}
                            InputProps={{
                                inputProps: {
                                  min: latestDate,
                                },
                                format: 'DD/MM/YYYY', // Custom format
                              }}
                            onChange={handleChangeSchedule}   
                        />
                        {/* <TextField
                            id="input-with-sx"
                            //   label="Start Date"
                            variant="outlined"
                            type="date"
                            sx={{ width: 220 }}
                            size='small'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            //   inputProps={{
                            //     min: latestDate,
                            //   }}

                            name="date"
                            // value={formData.campEnddate}
                            // onChange={(event) => storeInfo(event)}

                            onChange={handleChangeSchedule}
                        /> */}
                    </Stack>
                    <Stack direction='row' alignItems='center' spacing={4} my='2rem'>
                        <Box>Time :</Box>
                        <TextField
                            id="time"
                            variant="outlined"
                            size='small'
                            type="time"
                            name='time'
                            sx={{ width: 204 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={scheduleDetails.time}
                            onChange={handleChangeSchedule}
                        />
                       {/* <input 
                       type="time" 
                       id="myTimePicker" 
                       name="time" 
                       onChange={handleChangeSchedule} 
                       className='timecss medium' 
                       value={scheduleDetails.time}/> */}
                    </Stack>
                    <Box>

                        {/* <TextField id="time" type="time"   /> */}
                    </Box>
                </Box>

                <Stack direction='row' spacing={6} my='3rem' className='medium'>
                    <Box>Comments :</Box>
                    <TextField
                        id="standard-basic"
                        // label="Past Camp History"
                        multiline
                        size='small'
                        variant="outlined"
                        name='location'
                        onChange={handleChangeSchedule}
                        value={scheduleDetails.location}
                        rows={4}
                        InputLabelProps={{
                            shrink: true
                        }}
                        sx={{ width: 300, color: '#000000', my: '0.35rem' }}
                    ></TextField>



                </Stack>
                {/* <TimePicker  onChange={onChange} value={value} style={{ width: '500px' }}/> */}
                {/* <input type="time" id="myTimePicker" name="time" onChange={handleChangeSchedule}  value={scheduleDetails.time}/> */}

                        {console.log(scheduleDetails)}
                <Grid container justifyContent='flex-end' sx={{ my: '2rem', mr: '2rem', px: '2rem' }}>
                    <Link to={`/ReviewApplication/${campId}/${applicantId}`} className='link2'>
                        <Button variant="contained"
                            sx={{
                                bgcolor: "#909090",
                                textTransform: 'none',
                                "&:hover": { background: "#909090" },
                                width: "100px",
                                mx: '0.5rem'
                            }}>
                            Cancel
                        </Button>
                    </Link>

                    <Button variant="contained" onClick={() => submitSchedulingDetails()}
                        sx={{
                            bgcolor: "#379592",
                            textTransform: 'none',
                            "&:hover": { background: "#379592" },
                            width: "100px",
                            mx: '0.5rem'
                        }}>
                        Submit
                    </Button>
                </Grid>

            </Box>
        </ThemeProvider>

    );
}
