import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    campData : {}
}


export const campSlice = createSlice({
    name:'campDetails',
    initialState,
    reducers :{
        setCampData : (state,action) => {
            console.log("payload",action.payload);
            state.campData = {...action.payload}
        },
    }
})

export const {setCampData} = campSlice.actions

export default campSlice.reducer