import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { Link, useHistory, useParams } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { apiService, apiService2 } from "../../Services/apiService";
import { CSVLink } from "react-csv";
import { US8_URL , SERVER_URL} from '../../Services/apiEndpints'
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const headers = [
  { label: 'Medicine', key: 'medicine' },
  { label: 'Time', key: 'medicince_time' },
  { label: 'Prescribed By', key: 'prescribed_by' },
  { label: 'Notes', key: 'notes' },
  { label: 'Provided By', key: 'provided_by' },
  { label: 'Date', key: 'medication_date' }
];









export default function ParticipantsMedicalRecord() {
  const [MedicalHistory, setMedicalHistory] = useState([]);
  const [medicineTime, setMedicineTime] = useState([]);
  let history = useHistory();
  let { camp_id , applicationId} = useParams();
  const selectedCampDetails = useSelector((state) => state?.Camp?.campData);
  const [childName, setChildName] = React.useState()



  React.useEffect(() => {
    (async () => {
      // setLoading(true);
      let gettran = {
        _methodId: "16",
        userId: localStorage.getItem("userId"),
        applicationId: applicationId,
        campId:camp_id
      };
  
      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/us8",
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);
  
      const medicalDataList = login.data.responseData[0];
      // setLoading(false);
    
     console.log(medicalDataList);



     medicalDataList.forEach((element) => {
      element["medicince_time"] = JSON.parse(element.medicince_time);
    });
  
      setMedicalHistory(medicalDataList);

          
   
    
      // const csvReport = {
      //   data: MedicalHistory,
      //   headers: headers,
      //   filename: 'Clue_Mediator_Report.csv'
      // };
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);


  // Excel code starts here


 
  console.log("childNameeeeeeeeeeeeeeee",childName)
  console.log("MedicalHistory",MedicalHistory)
  return (
    <>
      <Grid container display="flex" sx={{ mt: "2rem" }}>
        <Stack direction="row" spacing={1}>
          <Link to={`/ApplicantsReviewforMedication/${camp_id}`} className="link2">
            <Box
              alignSelf="center"
              component="img"
              sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
              alt="Logo"
              src={ArrowBack}
            ></Box>
          </Link>

          <Box alignSelf="center" px="1rem">
        
          {MedicalHistory &&
                MedicalHistory.length &&
                MedicalHistory[0].child_name}
          </Box>
        </Stack>
      </Grid>

      <Grid container justifyContent="flex-end" sx={{ mt: "2rem" }}>

        
      <Grid item>
       
          {/* <Link to="/" className="link2"> */}
          
          <CSVLink data={MedicalHistory} headers={headers}
          
          filename={"MedicalHistory"}
          
          >
            <Button
              variant="contained"
              sx={{
                color: "#FFFFFF",
                bgcolor: "#379592",
                textTransform: "none",
                "&:hover": { background: "#379592" },
                fontFamily: "gilroy",
                mx: "0.5rem",
              }}
              className="editmedred-btnMq"
              // onClick={saveExcel}
            >
              Export Medical Record 
            </Button>
          {/* </Link> */}</CSVLink>
        
      </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              px: "1rem",
              mx: "0.5rem",
              color: "#FFFFFF",
              bgcolor: "#4C9F70",
              textTransform: "none",
              "&:hover": { background: "#4C9F70" },
            }}
            className="gilroy editmedred-btnMq"
              onClick={() => history.push(`/MedicalForm/${camp_id}/${applicationId}`)}
          >
            View Medical Form
          </Button>
        </Grid>

        <Grid item>

          <Button
            variant="contained"
            sx={{
              px: "1rem",
              mx: "0.5rem",
              color: "#FFFFFF",
              bgcolor: "#FF9529",
              textTransform: "none",
              "&:hover": { background: "#FF9529" },
            }}
            className="gilroy editmedred-btnMq"
              onClick={() => history.push(`/EditParticipantMedicalRecord/${camp_id}/${applicationId}`)}
          >
            Edit Medical Record
          </Button>
          
        </Grid>
      </Grid>

      {/* Table code starts here */}

      <TableContainer component={Paper} sx={{ marginTop: "3rem" }}>
        <Table sx={{ minWidth: 450 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Medicine & Dosage</StyledTableCell>

              <StyledTableCell sx={{ textAlign: "center" }}>
                {/* <Button  onClick={()=>testFuncton('firstName')}>First Name</Button>  */}
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Time
                  </Box>
                </Grid>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Prescribed By
                  </Box>
                </Grid>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Notes
                  </Box>
                </Grid>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Provided By
                  </Box>
                </Grid>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Date
                  </Box>
                </Grid>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
           { console.log("MedicalHistory",MedicalHistory)}
            {MedicalHistory.map((row, index) => (
            <TableRow
              // key={row.userId}
              className="normal"
              sx={{
                "& .MuiTableCell-root": { py: "0.35rem" },
              }}
            >
              <StyledTableCell
                component="th"
                scope="row"
                align="center"
                // sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
              >
               {row.medicine}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
              >
                {/* {row?.childName} */}
                {/* {row.medicince_time} <br /> */}
                {(row?.medicince_time || []).map((as) => (
                      <Box>{as}</Box>
                    ))}
                {/* Lunch
                <br />
                3/3:30pm */}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="center"
                //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
              >
               {row.prescribed_by}
              </StyledTableCell>
              <StyledTableCell align="center">
              {row.notes} <br /> 
              
              {/* experiencing <br /> fever */}
              </StyledTableCell>

              <StyledTableCell align="center">{row.provided_by} </StyledTableCell>

              <StyledTableCell align="center">{row.medication_date} </StyledTableCell>
            </TableRow>
            ))} 
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
