import Grid from "@mui/material/Grid";
import { Chip, CardContent, Box } from "@mui/material";
import Card from "@mui/material/Card";
import { Link ,useHistory} from "react-router-dom";
import { apiService, apiService2 } from "../../Services/apiService";
import React, { useState } from "react";
import { Group } from "@material-ui/icons";
import { US8_URL , SERVER_URL} from '../../Services/apiEndpints'
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Stack from "@mui/material/Stack";
export default function ShirtReport() {
  const [campListData, setCampListData] = useState([]);

  const history = useHistory()
  let user = localStorage.getItem('rolesName') 
//   React.useEffect(() => {
//     (async () => {
//       // setLoading(true); 
//       let gettran = {
//         _methodId: "12",
//         userId: localStorage.getItem("userId"),
//       };

//       let apiInfo = {
//         url: "https://cncdevapi.natsoft.io/api/us8",
//         method: "POST",
//         postData: gettran,
//         headers: {
//           "Content-Type": "application/json",
//           tokenheaderkey: localStorage.getItem("token"),
//         },
//       };
//       let login = await apiService2(apiInfo);

//       const campDataList = login.data.responseData[0];
//       campDataList.forEach(element => {
//         if(element && element.group_info){
//           element.group_info = element.group_info.split(',')

//         }
//       });
//       // setLoading(false);

//       console.log(campDataList);

//       setCampListData(campDataList);
      
//     })()
//       .catch((e) => {
//         console.error(e);
//       })
//       .finally(() => {});
//   }, []);
React.useEffect(() => {
  (async () => {
    // setLoading(true);
    let gettran = {
      _methodId: (user === 'CAMP MANAGER') ||(user === 'CAMP ADMIN') || (user === 'ADMIN') ? ("12") : ('1'),
      userId: localStorage.getItem("userId"),
    };

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/us8",
      url: `${SERVER_URL}${US8_URL}`,
      method: "POST",
      postData: gettran,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    };
    let login = await apiService2(apiInfo);

    const campDataList = login.data.responseData[0];
    campDataList.forEach(element => {
      if(element && element.group_info){
        element.group_info = element.group_info.split(',')
      }
      if(element && element.group_name){
        element.group_name =element.group_name.split(',')
      }
    });
    // setLoading(false);

    console.log(campDataList);

    setCampListData(campDataList);
    
    
  })()
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {});
}, []);

const handleRouting = () =>{
  if((localStorage.getItem("rolesName") === 'CAMP MANAGER') || (localStorage.getItem("rolesName") === 'CAMP ADMIN') || (localStorage.getItem("rolesName") === 'ADMIN')){
history.push('/ReportsForCampManager')
  }
  else if((localStorage.getItem("rolesName") === 'CAMP STAFF') || (localStorage.getItem("rolesName") === 'THERAPIST') || (localStorage.getItem("rolesName") === 'MEDICATION MANAGER') ){
    history.push('/reports')
  }
}
  return (
    <>
     <Stack
            direction="row"
            spacing={1}
            xs={12}
            sm={12}
            md={6}
            alignItems="center"
          >
            
              <Box
                alignSelf="center"
                component="img"
                sx={{
                  height: "0.75rem",
                  width: "0.65rem",
                  cursor: "pointer",
                }}
                height="20"
                alt="Logo"
                src={ArrowBack}
                onClick={()=>{handleRouting()}}
              ></Box>
           
            <Box alignSelf="center">Shirt Report</Box>
          </Stack>
      
    
    {campListData && campListData.map((item,index)=>(
 <Box
 key={index}
 // onClick={() => handleSetCampName( item.campName)} 
 >
   <Link  to={`/ShirtReportCampwise/${item.camp_id}`} className="link2">
     <Card
       variant="outlined"
       sx={{
         backgroundColor: "#F8F8F8",

         color: "#707070",
         my: "0.75rem",
         border: 1,
         borderColor: "#379592",
         borderRadius: "0.75rem",
        //  py: "0rem",
        //  maxHeight: 60,
       }}
     >
       <CardContent sx={{}}>
         <Grid
           container
           display="flex"
           justifyContent="space-between"
           alignItems="center"
         >
           <Grid item >
             <Grid
               container
               display="flex"
               alignItems="center"
               justifyContent="space-evenly"
             >
               <Box mx="0.75rem" sx={{ color: "#379592" }}>
             {item.camp_name}
               </Box>
               <Chip
                 
                 label={item. camp_type}
                 variant="outlined"
                 size="small"
                 className="medium"
                 mx="0.75rem"
                 sx={{
                   color: "#707070",
                   backgroundColor: "white",
                   fontSize: "0.5rem",
                   px: "0.5rem",
                   height: "20px",
                 }}
               />
               <Box
                 mx="0.75rem"
                 sx={{ fontSize: "0.85rem", color: "#000000" }}
                 className="medium"
               >
                 Start Date: {item.camp_startdate}
               </Box>
               {/* <Grid item>
             <Grid container display="flex" alignItems="center"> 
              {item.group_name.map((ele,i)=>(
                   <Chip  
                   key={i}
                     label={ele}
                     variant="outlined"
                     size="small"
                     
                     className="medium"
                     sx={{
                       borderColor: "#379592",
                       fontSize: "0.5rem",
                   px: "0.5rem",
                   mx:'0.5rem',
                 
                     }}
                   /> 
              ))} 
            </Grid>
               
           </Grid> */}
             </Grid>
           </Grid>
           
           {/* <Grid item>
             <Grid container display="flex" alignItems="center"> 
              {item.group_info.map((ele,i)=>(
                   <Chip  
                   key={i}
                     label={ele}
                     variant="outlined"
                     size="small"
                     
                     className="medium"
                     sx={{
                       borderColor: "#379592",
                       fontSize: "0.5rem",
                   px: "0.5rem",
                   mx:'0.5rem'
                      //  px: "1rem",
                      //  py: "0.85rem",
                     }}
                   /> 
              ))} 
            </Grid>
               
           </Grid> */}
         </Grid>
       </CardContent>
     </Card>
   </Link>
 </Box>
    ))}
       
  
      
    </>
  );
}
