import React, { useState } from "react";
import Button from "@mui/material/Button";

import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";

import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import DefaultImage from "../../Assets/Icons/Icons/DefaultImage.png";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import "../../index.css";
import RejectedPdf from "../../Assets/Icons/Icons/RejectedPdf.png";
import CheckboxSelected from "../../Assets/Icons/Icons/CheckboxSelected.png";
import CheckboxNotSelected from "../../Assets/Icons/Icons/CheckboxNotSelected.png";
import { apiService, apiService2 } from "../../Services/apiService";
import Check from "../../Assets/Icons/Icons/Check.png";
import Circle from "../../Assets/Icons/Icons/Circle.png";
import SelectedCircle from "../../Assets/Icons/Icons/SelectedCircle.png";
import { GET_CAMPAPPLICATION ,GETCAMP_APPLICANTSCOMMENTS, SERVER_URL} from '../../Services/apiEndpints'

const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "10px",
  // boxShadow: 24,
  p: 4,
};

const styleSuitability = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "30px",
  // boxShadow: 24
  p: 4,
};

export default function ProfileViewMedication() {
  const [applicationDetails, setApplicationDetails] = React.useState();
  const history = useHistory();
  const [isConditionMet, setIsConditionMet] = useState(true);
  let { camp_id, child_id, applicantId } = useParams();

  // let childId = localStorage.getItem("childId")
  let userName = localStorage.getItem("userName");

  React.useEffect(() => {
    (async () => {
      // setLoading(true)
      let dataSent = {
        userId: localStorage.getItem("userId"),
        campId: camp_id,
        childId: child_id,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/getcampapplication",
        url: `${SERVER_URL}${GET_CAMPAPPLICATION}`,
        method: "POST",
        postData: dataSent,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let output = await apiService2(apiInfo);
      console.log(output.data.responseData[0]);
      let appDetails = output.data.responseData[0][0];
      // setLoading(false)
      setApplicationDetails(appDetails);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      // setLoading(true)
      let dataSent = {
        userId: localStorage.getItem("userId"),
        campId: camp_id,
        applicationId: applicantId,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/getcampapplicantscomments",
        url: `${SERVER_URL}${GETCAMP_APPLICANTSCOMMENTS}`,
        method: "POST",
        postData: dataSent,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let output = await apiService2(apiInfo);

    })();
  }, [isConditionMet]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Stack direction="row" sx={{ mt: "0.75rem" }}>
          <Link
            to={`/ApplicantsReviewforMedication/${camp_id}`}
            className="link2"
          >
            <Box
              alignSelf="center"
              component="img"
              sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
              alt="Logo"
              src={ArrowBack}
            ></Box>
          </Link>

          <Box sx={{ margin: "auto" }}>Application Review</Box>
        </Stack>

        <Grid container display="flex">
          <Grid item xs={12} sm={6} md={9}>
            <Box mt="1rem" mb="1.5rem">
              {applicationDetails && applicationDetails.campName}
            </Box>
            <Grid container display="flex" alignItems="flex-end">
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="Name"
                  variant="standard"
                  value={applicationDetails && applicationDetails.childName}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: 220 }}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="Age"
                  variant="standard"
                  value={applicationDetails && applicationDetails.childAge}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: 220 }}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="Gender"
                  variant="standard"
                  value={applicationDetails && applicationDetails.gender}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: 220,
                  }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container display="flex" my="0.5rem">
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="Email Id"
                  variant="standard"
                  value={applicationDetails && applicationDetails.parentEmail}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: 220,
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="Location"
                  variant="standard"
                  value={applicationDetails && applicationDetails.location}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: 220,
                  }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="standard-basic"
                label="Past Camp History"
                variant="standard"
                value="Past Camp History"
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: 220,
                }}
              ></TextField>
            </Grid>
            <Box sx={{ color: "#AFAFAF", my: "1.5rem" }}>
              Application for New Camp (Camp Name)
            </Box>
            <Grid item sx={{ color: "#676767" }} md={9}>
              <Box mx="0.5rem">
                <Box sx={{ color: "#379592" }} className="light">
                  Medical information
                </Box>
                <Box my="0.5rem">
                  <Box className="medium">
                    If your child has any medical conditions, diagnosis or first
                    aid, please describe below:
                  </Box>
                  <TextField
                    id="standard-basic"
                    // label="Past Camp History"
                    size="small"
                    variant="outlined"
                    value={
                      applicationDetails &&
                      applicationDetails.medicalConditionDiagnosis
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: 350, color: "#000000" }}
                  ></TextField>
                </Box>
                <Box my="0.5rem">
                  <Box className="medium">
                    Does your child have any of the following health conditions?{" "}
                    <span className="red ">*</span>
                  </Box>
                  <TextField
                    id="standard-basic"
                    // label="Past Camp History"
                    size="small"
                    variant="standard"
                    value={
                      applicationDetails && applicationDetails.healthCondition
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: 350, color: "#000000" }}
                  ></TextField>
                </Box>
                <Box my="0.5rem">
                  <Stack direction="row" alignItems="center">
                    <Box className="medium">
                      Does your child have any allergies, if yes please fill
                      table :
                      {/* <Checkbox defaultChecked disabled
                                        sx={{
                                            "& MuiButtonBase-root.MuiCheckbox-root.Mui-checked ": {
                                                color: "pink",
                                            },
                                        }} /> */}
                      <span>
                        {applicationDetails && applicationDetails.allergyInfo
                          ? "Yes"
                          : "No"}
                      </span>
                      <Box
                        mt="1rem"
                        component="img"
                        sx={{ height: "15px", width: "15px" }}
                        alt="Logo"
                        src={
                          applicationDetails && applicationDetails.allergyInfo
                            ? Check
                            : CheckboxNotSelected
                        }
                      ></Box>
                      {/* <Box
                                        mt='1rem'
                                        component="img"
                                        sx={{ height: "15px", width: "15px", }}
                                        alt="Logo"
                                        src={CheckboxNotSelected}

                                    ></Box> */}
                    </Box>
                    {/* <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="End"
                                    labelPlacement="end"
                                    size='small'
                                /> */}
                  </Stack>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 450 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Allergy</TableCell>
                          <TableCell align="center">Reaction</TableCell>
                          <TableCell align="center">Treatment</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {applicationDetails &&
                          applicationDetails.allergyInfo.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">
                                {item.allergy}
                              </TableCell>
                              <TableCell align="center">
                                {item.reaction}
                              </TableCell>
                              <TableCell align="center">
                                {item.treatment}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box my="0.75rem">
                  <Box className="medium">
                    Does your child need to take medication on camp?{" "}
                    <span className="red ">*</span>
                  </Box>
                  {/* <Stack direction='row' spacing={4}>
                                <Stack direction='column' mx='0.25rem' >
                                    <FormControlLabel size='small' control={<Checkbox />} label="Prescribed medication"
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: '12px'
                                            }
                                        }}
                                    />
                                    <FormControlLabel size='small' control={<Checkbox />} label="Vitamins and natural"
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: '12px'
                                            }
                                        }}
                                    />
                                </Stack>
                                <Stack direction='column' >
                                    <FormControlLabel size='small' control={<Checkbox />} label="Other"
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: '12px'
                                            }
                                        }}
                                    />
                                    <FormControlLabel size='small' control={<Checkbox />} label="None"
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: '12px'
                                            }
                                        }}
                                    />
                                </Stack>
                            </Stack> */}
                  <Stack direction="row" spacing={3}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      className="medium"
                    >
                      <Box
                        component="img"
                        sx={{ height: "15px", width: "15px" }}
                        alt="Logo"
                        src={
                          applicationDetails &&
                          applicationDetails.medicationInfo &&
                          applicationDetails.medicationInfo.includes(
                            "prescribedmedication"
                          )
                            ? Check
                            : CheckboxNotSelected
                        }
                      ></Box>
                      <Box>Prescribed medication</Box>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      className="medium"
                    >
                      <Box
                        component="img"
                        sx={{ height: "15px", width: "15px" }}
                        alt="Logo"
                        src={
                          applicationDetails &&
                          applicationDetails.medicationInfo &&
                          applicationDetails.medicationInfo.includes("other")
                            ? Check
                            : CheckboxNotSelected
                        }
                      ></Box>
                      <Box>Other</Box>
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={4}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      className="medium"
                    >
                      <Box
                        component="img"
                        sx={{ height: "15px", width: "15px" }}
                        alt="Logo"
                        src={
                          applicationDetails &&
                          applicationDetails.medicationInfo &&
                          applicationDetails.medicationInfo.includes(
                            "Vitamins and natural"
                          )
                            ? Check
                            : CheckboxNotSelected
                        }
                      ></Box>
                      <Box>Vitamins and natural</Box>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      className="medium"
                    >
                      <Box
                        component="img"
                        sx={{ height: "15px", width: "15px" }}
                        alt="Logo"
                        src={
                          applicationDetails &&
                          applicationDetails.medicationInfo &&
                          applicationDetails.medicationInfo.includes("none")
                            ? Check
                            : CheckboxNotSelected
                        }
                      ></Box>
                      <Box>None</Box>
                    </Stack>
                  </Stack>

                  {/* {applicationDetails && applicationDetails.medicationInfo.includes('none') ? ('Ha') : ('Nah')} */}

                  <Box sx={{ fontSize: "10px" }}>
                    You will be asked to fill in a medication form closer to
                    camp.
                  </Box>
                </Box>
                <Box my="0.75rem">
                  <Box className="medium">
                    Does your child have any dietary needs, restrictions or
                    preferences?<span className="red ">*</span>
                  </Box>
                  <TextField
                    id="standard-basic"
                    // label="Past Camp History"
                    size="small"
                    variant="outlined"
                    multiline
                    value={applicationDetails && applicationDetails.dietaryInfo}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: 350, color: "#000000" }}
                  ></TextField>
                </Box>
                <Box my="1.5rem">
                  <Box sx={{ color: "#379592" }} className="light">
                    Behaviour information
                  </Box>
                  <Box my="0.75rem">
                    <Box className="medium">
                      Does your child have a current Positive Behaviour Support
                      Plan?
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="standard"
                      value={
                        applicationDetails &&
                        applicationDetails.positiveBehaviourSupportPlan
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 350, color: "#000000" }}
                    ></TextField>
                  </Box>
                  <Box my="0.75rem" className="medium">
                    <Box>
                      Does your child have any restrictive practices used?
                    </Box>

                    <Stack direction="column" mx="0.25rem">
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        my="0.25rem"
                      >
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            applicationDetails &&
                            applicationDetails.restrictivePractice === "Yes"
                              ? SelectedCircle
                              : Circle
                          }
                        ></Box>
                        <Box>Yes, appoved by NDIS Commisssion</Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        my="0.25rem"
                      >
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            applicationDetails &&
                            applicationDetails.restrictivePractice ===
                              "Yes Awaiting"
                              ? SelectedCircle
                              : Circle
                          }
                        ></Box>
                        <Box>Yes, awaiting approval by the NDIS Commission</Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        my="0.25rem"
                      >
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            applicationDetails &&
                            applicationDetails.restrictivePractice === "No"
                              ? SelectedCircle
                              : Circle
                          }
                        ></Box>
                        <Box>No</Box>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box my="0.75rem">
                    <Box className="medium">
                      If yes, please provide a brief description of the
                      Restrictive Practices used.
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      multiline
                      value={
                        applicationDetails &&
                        applicationDetails.restrictivePracticeInfo
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 350, color: "#000000" }}
                    ></TextField>
                  </Box>
                  <Box my="0.75rem">
                    <Box className="medium">
                      Please upload a copy of your child's Positive Behaviour
                      Support
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      multiline
                      value="Positive.pdf"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 350,
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          color: "#150B9F",
                        },
                      }}
                    ></TextField>
                  </Box>
                  <Box my="0.75rem">
                    <Box sx={{ color: "black" }}>NDIS Plan (Optional)</Box>
                    <Box className="medium" mt="0.5rem" mb="0.25rem">
                      Please fill in the information for your plan manager
                    </Box>
                    <Stack direction="column">
                      <TextField
                        id="standard-basic"
                        // label="Past Camp History"
                        size="small"
                        variant="standard"
                        value="Name"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          width: 200,
                          my: "0.15rem",
                          "& .MuiInputBase-root.MuiInput-root": {
                            color: "#676767",
                          },
                        }}
                      ></TextField>
                      <TextField
                        id="standard-basic"
                        // label="Past Camp History"
                        size="small"
                        variant="standard"
                        value="Contact Number"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          width: 200,
                          my: "0.15rem",
                          "& .MuiInputBase-root.MuiInput-root": {
                            color: "#676767",
                          },
                        }}
                      ></TextField>
                      <TextField
                        id="standard-basic"
                        // label="Past Camp History"
                        size="small"
                        variant="standard"
                        value="Email"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          width: 200,
                          my: "0.15rem",
                          "& .MuiInputBase-root.MuiInput-root": {
                            color: "#676767",
                          },
                        }}
                      ></TextField>
                      <TextField
                        id="standard-basic"
                        // label="Past Camp History"
                        size="small"
                        variant="standard"
                        value="Company"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          width: 200,
                          my: "0.15rem",
                          "& .MuiInputBase-root.MuiInput-root": {
                            color: "#676767",
                          },
                        }}
                      ></TextField>
                      <Stack direction="row" mt="0.75rem">
                        <Box className="medium">
                          Please upload the NDIS plan
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>

                <Box my="1.5rem">
                  <Box sx={{ color: "#379592" }} className="light">
                    About Your Child
                  </Box>
                  <Box my="0.5rem">
                    <Box className="medium">Shirt Size</Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="standard"
                      value={applicationDetails && applicationDetails.shirtSize}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 300, color: "#000000" }}
                    ></TextField>
                  </Box>
                  <Box my="0.5rem">
                    <Box className="medium">
                      Swimming ability (opportunities are weather dependent)
                      <span className="red">*</span>
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="standard"
                      value={
                        applicationDetails && applicationDetails.swimmingAbility
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 300, color: "#000000" }}
                    ></TextField>
                  </Box>
                  <Box my="0.65rem">
                    <Box className="medium">
                      What are your child's current interests and strengths?
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        applicationDetails &&
                        applicationDetails.InterestStrenghts
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 300,
                      }}
                    ></TextField>
                  </Box>
                  <Box my="0.65rem">
                    <Box className="medium">
                      What does your child hope to gain from camp?
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        applicationDetails && applicationDetails.gainFromCamp
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 300,
                      }}
                    ></TextField>
                  </Box>
                  <Box my="0.65rem">
                    <Box className="medium">
                      Does your child require any support with communication?
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        applicationDetails &&
                        applicationDetails.supportCommunication
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 300,
                      }}
                    ></TextField>
                  </Box>
                  <Box my="0.65rem">
                    <Box className="medium">
                      Does your child experience night terrors?
                      <span className="red">*</span>
                    </Box>
                    <Stack direction="row" spacing={8}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            applicationDetails &&
                            applicationDetails.nightTerrors === "Yes"
                              ? SelectedCircle
                              : Circle
                          }
                        ></Box>
                        <Box>Yes</Box>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            applicationDetails &&
                            applicationDetails.nightTerrors === "No"
                              ? SelectedCircle
                              : Circle
                          }
                        ></Box>
                        <Box>No</Box>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box my="0.65rem">
                    <Box className="medium">
                      Does your child need help with self-care or settling at
                      night?
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        applicationDetails && applicationDetails.nightSelfcare
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 300,
                      }}
                    ></TextField>
                  </Box>
                  <Box my="0.65rem">
                    <Box className="medium">
                      Does your child wake up during the night? If so, for what
                      reason and how often?
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        applicationDetails && applicationDetails.nightAwakes
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 300,
                      }}
                    ></TextField>
                  </Box>
                  <Box my="0.65rem">
                    <Box className="medium">
                      Does your child have a tendency to become anxious,
                      aggressive or run away? Please note that for safety
                      reasons, if your child runs to the busy road , seriously
                      harms another support/camper or frequently becomes
                      aggressive and strategies aren't supporting, we will
                      contact you to pick up your child as camp may then be
                      doing more harm than good
                      <span className="red">*</span>
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        applicationDetails && applicationDetails.aggressiveInfo
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 300,
                      }}
                    ></TextField>
                  </Box>
                  <Box my="0.65rem">
                    <Box className="medium">
                      Is there anything else we need to know?
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        applicationDetails && applicationDetails.anythingElse
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 300,
                      }}
                    ></TextField>
                  </Box>
                  <Box my="0.65rem">
                    <Box className="medium">
                      Is your child currently seeing an Occupational Therapist?
                      If yes, who?
                    </Box>
                    <Stack direction="row" spacing={8} my="0.75rem">
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            applicationDetails &&
                            applicationDetails.occupationalTherapist === "Yes"
                              ? SelectedCircle
                              : Circle
                          }
                        ></Box>
                        <Box>Yes</Box>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            applicationDetails &&
                            applicationDetails.occupationalTherapist === "No"
                              ? SelectedCircle
                              : Circle
                          }
                        ></Box>
                        <Box>No</Box>
                      </Stack>
                    </Stack>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        applicationDetails &&
                        applicationDetails.occupationalTherapistInfo
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 300,
                      }}
                    ></TextField>
                  </Box>
                  <Box my="1rem">
                    <Box sx={{ color: "black" }}>
                      Payment Type<span className="red">*</span>
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="standard"
                      value={
                        applicationDetails && applicationDetails.paymentMode
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 300, color: "#000000", my: "0.35rem" }}
                    ></TextField>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="standard"
                      value={applicationDetails && applicationDetails.ndiNumber}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 300, color: "#000000", my: "0.35rem" }}
                    ></TextField>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={3} container justifyContent="center">
            <Box
              mt="1rem"
              component="img"
              sx={{ height: "90px", width: "90px", borderRadius: "50px" }}
              alt="Logo"
              src={applicationDetails && applicationDetails.childPhotoPath}
            ></Box>
          </Grid>
        </Grid>
        <Grid my="1.5rem">
          <Box sx={{ color: "#379592" }} className="light">
            Commitment Form
          </Box>
          <Box sx={{ color: "#676767" }}>
            <Box my="0.75rem" className="medium">
              Participation in Calm and Connected’s Therapy Intensive programs
              requires a firm commitment due to the intensive planning, staffing
              and purchasing of materials in advance. <br />
              Once your child has been accepted into the program our financial
              commitment to your child in camp has begun. As a courtesy between
              families and Calm and Connected, as well as for clarity regarding
              financial commitment for families, please tick that you agree to
              the following:
            </Box>
            <Stack className="medium">
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  component="img"
                  sx={{ height: "15px", width: "15px" }}
                  alt="Logo"
                  src={
                    applicationDetails &&
                    applicationDetails.despositConfirmationCheck === "Yes"
                      ? Check
                      : CheckboxNotSelected
                  }
                ></Box>
                <Box>
                  I understand that the deposit is required to confirm my
                  child’s place on camp
                </Box>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  component="img"
                  sx={{ height: "15px", width: "15px" }}
                  alt="Logo"
                  src={
                    applicationDetails &&
                    applicationDetails.nonRefundableCheck === "Yes"
                      ? Check
                      : CheckboxNotSelected
                  }
                ></Box>
                <Box>I understand that this deposit is non-refundable</Box>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  component="img"
                  sx={{ height: "15px", width: "15px" }}
                  alt="Logo"
                  src={
                    applicationDetails &&
                    applicationDetails.installmentNonRefundable === "Yes"
                      ? Check
                      : CheckboxNotSelected
                  }
                ></Box>
                <Box>
                  I understand that the remaining camp payment installments are
                  non-refundable after the dates specified on the website.
                </Box>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  component="img"
                  sx={{ height: "15px", width: "15px" }}
                  alt="Logo"
                  src={
                    applicationDetails &&
                    applicationDetails.termsofServiceCheck === "Yes"
                      ? Check
                      : CheckboxNotSelected
                  }
                ></Box>
                <Box>I agree to Calm and Connected’s Terms of Service</Box>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box
                  component="img"
                  sx={{ height: "15px", width: "15px" }}
                  alt="Logo"
                  src={
                    applicationDetails && applicationDetails.riskCheck === "Yes"
                      ? Check
                      : CheckboxNotSelected
                  }
                ></Box>
                <Box>
                  I agree to voluntarily give permission for my child’s
                  participation in the camp, knowing that there are risks
                  involved. You are aware and accept that some children –
                  including yours – may experience strong physical and emotional
                  reactions, and engage in unpredictable behaviour. We do all
                  that we can to create a safe environment, and it is important
                  that you assist us by being honest about your child’s needs
                  and abilities. We reserve the right to deny participation in
                  the camp or any related activities at any time to children
                  demonstrating behaviour that may result in injury to
                  themselves or others. However, we are entitled to rely on the
                  promises you have made in enrolling your child in the camp,
                  and we have no duty to deny participation in any activity.
                  Failure to deny participation will not give rise to any claim
                  for negligence.
                </Box>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box
                  component="img"
                  sx={{ height: "15px", width: "15px" }}
                  alt="Logo"
                  src={
                    applicationDetails &&
                    applicationDetails.medicalAssistanceCheck === "Yes"
                      ? Check
                      : CheckboxNotSelected
                  }
                ></Box>
                <Box>
                  If assistance is called on your child’s behalf in the event of
                  a medical emergency, you agree that they are permitted to go
                  with the paramedics and a camp support staff member in the
                  ambulance to the hospital. We will do our best to notify the
                  child’s primary contact as soon as reasonably possible.
                </Box>
              </Stack>

              <Box mx="2rem" sx={{ color: "#676767" }} className="medium">
                Please have a read of our Terms of Service before continuing{" "}
                <br />
                <span>
                  {/* <Link> */}
                  <Box sx={{ color: "#0075CE" }}>
                    <a
                      href=" http://calmandconnected.com.au/termsofservice/"
                      target="_blank"
                    >
                      http://calmandconnected.com.au/termsofservice/
                    </a>
                  </Box>
                  {/* </Link> */}
                </span>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Box my="1rem">
          <Stack direction="column">
            <TextField
              id="standard-basic"
              // label="Past Camp History"
              size="small"
              variant="standard"
              value={applicationDetails && applicationDetails.parentName}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: "100%", color: "#000000", my: "0.35rem" }}
            ></TextField>
            <TextField
              id="standard-basic"
              // label="Past Camp History"
              size="small"
              variant="standard"
              value={applicationDetails && applicationDetails.applicationDate}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: "100%", color: "#000000", my: "0.35rem" }}
            ></TextField>
          </Stack>
        </Box>
        <Box my="1.5rem">
          <Box sx={{ color: "#379592", my: "0.5rem" }} className="light">
            Photo/Video Consent
          </Box>
          <Box className="medium">
            I hereby give my permission to the staff of Calm and Connected to
            make photographic, audio, or visual recordings of my child while
            attending Calm and Connected’s Therapy Intensive Programs.
            Photos/Video of my child may be used as indicated below:
          </Box>
          <Grid container md={6} my="0.5rem">
            <Box>
              <Box className="medium">
                To make a personal documentary history for my child of their
                camp experience (each child is given copies of photos taken on
                the trip).
                <span className="smallRed">*</span>
              </Box>

              <Stack
                direction="row"
                spacing={8}
                my="0.75rem"
                className="medium"
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box
                    component="img"
                    sx={{ height: "15px", width: "15px" }}
                    alt="Logo"
                    src={
                      applicationDetails &&
                      applicationDetails.photostobetakenCheck === "Yes"
                        ? SelectedCircle
                        : Circle
                    }
                  ></Box>
                  <Box>Yes</Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box
                    component="img"
                    sx={{ height: "15px", width: "15px" }}
                    alt="Logo"
                    src={
                      applicationDetails &&
                      applicationDetails.photostobetakenCheck === "No"
                        ? SelectedCircle
                        : Circle
                    }
                  ></Box>
                  <Box>No</Box>
                </Stack>
              </Stack>
            </Box>
            <Box>
              <Box className="medium">
                To be included in the personal documentary histories of other
                children’s camp experiences (i.e. my child’s photo might be a
                part of a group photo given to another child).
                <span className="smallRed">*</span>
              </Box>
              <Stack
                direction="row"
                spacing={8}
                my="0.75rem"
                className="medium"
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box
                    component="img"
                    sx={{ height: "15px", width: "15px" }}
                    alt="Logo"
                    src={
                      applicationDetails &&
                      applicationDetails.groupPhotoSharingCheck === "Yes"
                        ? SelectedCircle
                        : Circle
                    }
                  ></Box>
                  <Box>Yes</Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box
                    component="img"
                    sx={{ height: "15px", width: "15px" }}
                    alt="Logo"
                    src={
                      applicationDetails &&
                      applicationDetails.groupPhotoSharingCheck === "No"
                        ? SelectedCircle
                        : Circle
                    }
                  ></Box>
                  <Box>No</Box>
                </Stack>
              </Stack>
            </Box>
            <Box>
              <Box className="medium">
                For photos to be used on Calm and Connected’s website and
                marketing materials to support the description of their programs
                – you will be notified.
                <span className="smallRed">*</span>
              </Box>
              <Stack
                direction="row"
                spacing={8}
                my="0.75rem"
                className="medium"
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box
                    component="img"
                    sx={{ height: "15px", width: "15px" }}
                    alt="Logo"
                    src={
                      applicationDetails &&
                      applicationDetails.photosWebsitePromotionCheck === "Yes"
                        ? SelectedCircle
                        : Circle
                    }
                  ></Box>
                  <Box>Yes</Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box
                    component="img"
                    sx={{ height: "15px", width: "15px" }}
                    alt="Logo"
                    src={
                      applicationDetails &&
                      applicationDetails.photosWebsitePromotionCheck === "No"
                        ? SelectedCircle
                        : Circle
                    }
                  ></Box>
                  <Box>No</Box>
                </Stack>
              </Stack>
            </Box>
            <Box>
              <Box className="medium">
                To be uploaded on Calm and Connected’s social media
                <span className="smallRed">*</span>
              </Box>
              <Stack
                direction="row"
                spacing={8}
                my="0.75rem"
                className="medium"
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box
                    component="img"
                    sx={{ height: "15px", width: "15px" }}
                    alt="Logo"
                    src={
                      applicationDetails &&
                      applicationDetails.photosforSocialMediaCheck === "Yes"
                        ? SelectedCircle
                        : Circle
                    }
                  ></Box>
                  <Box>Yes</Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box
                    component="img"
                    sx={{ height: "15px", width: "15px" }}
                    alt="Logo"
                    src={
                      applicationDetails &&
                      applicationDetails.photosforSocialMediaCheck === "No"
                        ? SelectedCircle
                        : Circle
                    }
                  ></Box>
                  <Box>No</Box>
                </Stack>
              </Stack>
            </Box>
            <Box my="0.75rem">
              <Stack direction="column">
                <TextField
                  id="standard-basic"
                  // label="Past Camp History"
                  size="small"
                  variant="standard"
                  value={applicationDetails && applicationDetails.parentName}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: 300, color: "#000000", my: "0.35rem" }}
                ></TextField>
                <TextField
                  id="standard-basic"
                  label="Date Form Completed:"
                  size="small"
                  variant="standard"
                  value={
                    applicationDetails && applicationDetails.applicationDate
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: 300, color: "#000000", my: "0.35rem" }}
                ></TextField>
              </Stack>
            </Box>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
