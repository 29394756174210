import React, { useState, createContext } from "react";
import Button from "@mui/material/Button";

import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";

import { apiService, apiService2 } from "../Services/apiService";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../Assets/Icons/Icons/BlackUser.png";
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import question from "../Assets/Icons/Icons/questionMark.png";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";
import {
  SERVER_URL,
  GROUPWISE_PARTICIPANTLIST,
  CAMP_CHECK_IN,
  GET_GROUPNAME,
} from "../Services/apiEndpints";

export default function CheckInOut() {
  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [checkInInfo, setCheckInInfo] = React.useState(null);
  const [grpName, setGrpName] = React.useState([]);
  const [selectGrp, setSelectGrp] = React.useState("All");
  let { campId, applicantId } = useParams();

  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    palette: {
      secondary: {
        main: "#379592",
      },
    },

    Muidisabled: {
      styleOverrides: {
        backgroundColor: "purple",
      },
    },
  });
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      backgroundColor: "rgba(55, 149, 146, 1)",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const rows = [
    { sno: 1, applicants: "Rosey", group: "Check In" },
    { sno: 2, applicants: "Maria", group: "Check In" },
    { sno: 3, applicants: "Ben", group: "Check Out" },
  ];

  React.useEffect(() => {
    (async () => {
      let dataSent = {
        userId: localStorage.getItem("userId"),
        campId: campId,
        participantstatus: "Yes",
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/actcamp/getgroupwiseparticipantslist",
        url: `${SERVER_URL}${GROUPWISE_PARTICIPANTLIST}`,
        method: "POST",
        postData: dataSent,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let output = await apiService2(apiInfo);
      console.log(output.data.responseData[0]);
      let responseData = output.data.responseData[0];
      setCheckInInfo(responseData);
    })();
  }, [reload]);
  const handleCheckIn = async (status) => {
    console.log(status);
    var stat;
    if (status.checkinStatus === "Check Out") {
      stat = "Yes";
    } else if (status.checkinStatus === "Check In") {
      stat = "No";
    }

    let dataSent = {
      userId: localStorage.getItem("userId"),
      campId: campId,
      check_in: stat,
      applicationId: status.applicationId,
    };
    console.log(dataSent);
    Swal.fire({
      iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
      text: "Are you sure you want to Submit?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#379592",
      cancelButtonText: "No",
      cancelButtonColor: "#909090",
      customClass: {
        container: "my-swal",
        icon: "no-border",

        //    confirmButton: 'your-confirm-button-class',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          let apiInfo = {
            // url: "https://cncdevapi.natsoft.io/api/actcamp/camp_check_in",
            url: `${SERVER_URL}${CAMP_CHECK_IN}`,
            method: "POST",
            postData: dataSent,
            headers: {
              "Content-Type": "application/json",
              tokenheaderkey: localStorage.getItem("token"),
            },
          };
          let output = await apiService2(apiInfo);
          console.log(output.data.responseData[0][0]);

          if (output.data.responseCode === 0) {
            setReload((prev) => !prev);
            console.log(output.data.responseData[0][0].Desc);
            Swal.fire({
              iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
              text: output.data.responseData[0][0].Desc,
              confirmButtonText: "OK",
              confirmButtonColor: "#379592",
              customClass: {
                container: "my-swal",
              },
            });
          }
        })();
      }
    });
  };
  const handleChange = (event) => {
    setSelectGrp(event.target.value);
    console.log(event.target.value, "doooooooooooooooo");
  };

  let filteredList =
    checkInInfo &&
    checkInInfo.filter((item) => {
      // if(item.group_assigned !== null)
      return (
        // item && item.group_assigned.toLowerCase().includes(selectGrp.toLowerCase())
        item && item.group_assigned.toLowerCase() === selectGrp.toLowerCase()
      );
    });

  if (selectGrp === "All") {
    filteredList = checkInInfo && checkInInfo;
  }

  React.useEffect(() => {
    (async () => {
      // setLoading(true)
      console.log(campId);
      let dataSent = {
        userId: localStorage.getItem("userId"),
        campId: campId,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/actcamp/getgroupnames",
        url: `${SERVER_URL}${GET_GROUPNAME}`,
        method: "POST",
        postData: dataSent,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let output = await apiService2(apiInfo);
      if (output.data.responseCode === 0) {
        let groupList = output.data.responseData[0];
        console.log("list", groupList);
        setGrpName(groupList);
      }
    })();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Grid container direction="flex" justifyContent="space-between">
          <Grid item>
            <Stack direction="row" spacing={1}>
              <Link className="link2" to={`/EditCamp/${campId}`}>
                {/* <Link className='link2' to={'/EditCamp'} > */}
                <Box
                  component="img"
                  sx={{
                    height: "0.75rem",
                    width: "0.65rem",
                    cursor: "pointer",
                  }}
                  alt="Logo"
                  src={ArrowBack}
                ></Box>
              </Link>
              <Box alignSelf="center">Check In/Out</Box>
            </Stack>
          </Grid>
        </Grid>
        <Grid container display="flex" alignItems="center" mt="0.5rem">
          <Box my="0.5rem"></Box>
          <Box>
            <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
              <InputLabel id="demo-simple-select-autowidth-label">
                Select
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={selectGrp}
                onChange={handleChange}
                label="Select"
              >
                <MenuItem value="All">All</MenuItem>

                {grpName &&
                  grpName.map((item, index) => (
                    // <div key={index}>
                    <MenuItem key={index} value={item.groupNames}>
                      {item.groupNames}
                    </MenuItem>
                    // </div>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {loading === true ? (
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 450 }}
              aria-label="customized table"
              my="1rem"
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Sl. No</StyledTableCell>
                  <StyledTableCell align="center">Applicants</StyledTableCell>
                  <StyledTableCell align="center">Group</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>

                  <StyledTableCell align="center">View</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {filteredList &&
                  filteredList.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "& .MuiTableCell-root": { py: "0.45rem" },
                      }}
                    >
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.childName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.group_assigned}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        <Button
                          variant="contained"
                          sx={{
                            px: "1rem",
                            //  width:'130px'
                            backgroundColor:
                              row.checkinStatus === "Check In"
                                ? "#F21010"
                                : "#4C9F70",
                            textTransform: "none",
                            "&:hover": {
                              background:
                                row.checkinStatus === "Check In"
                                  ? "#F21010"
                                  : "#4C9F70",
                            },
                            width: "120px",
                          }}
                          className="gilroy"
                          onClick={() => handleCheckIn(row)}
                        >
                          {row.checkinStatus === "Check In"
                            ? "Check Out"
                            : "Check In"}
                        </Button>
                      </StyledTableCell>

                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="center"
                        >
                          <Link
                            to={`/CheckInOutLog/${campId}/${row.applicationId}`}
                            className="link2"
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                bgcolor: "white",
                                color: "#718F94",
                                textTransform: "none",
                                borderColor: "#718F94",
                                "&:hover": {
                                  background: "white",
                                  color: "#718F94",
                                  borderColor: "#718F94",
                                },
                                width: "80px",
                              }}
                            >
                              Log
                            </Button>
                          </Link>
                          <Link
                            to={`/ChildProfile/${campId}/${row.childId}`}
                            className="link2"
                          >
                            <Button
                              variant="outlined"
                              // onClick={localStorage.setItem}
                              sx={{
                                bgcolor: "white",
                                color: "#718F94",
                                textTransform: "none",
                                borderColor: "#718F94",
                                "&:hover": {
                                  background: "white",
                                  color: "#718F94",
                                  borderColor: "#718F94",
                                },
                                width: "80px",
                              }}
                            >
                              Profile
                            </Button>
                          </Link>
                        </Stack>
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </ThemeProvider>
  );
}
