import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography/Typography";
import "../../index.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button/Button";
import { Link, useParams, useHistory } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, alpha } from "@mui/material/styles";
import GreenPdf from "../../Assets/Icons/Icons/GreenPdf.png";
import { apiService, apiService2 } from "../../Services/apiService";
import Logo from "../../Assets/Icons/Icons/Logo-Main.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import GreenCheck2 from "../../Assets/Icons/Icons/GreenCheck2.png";
import moment from "moment";
import Swal from "sweetalert2";
import Modal from "@mui/material/Modal";
import question from "../../Assets/Icons/Icons/questionMark.png";
import { US8_URL, SERVER_URL } from "../../Services/apiEndpints";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import html2pdf from 'html2pdf.js'

export default function SupportStaffInvoiceDownload() {
  const [invoiceInfo, setInvoiceInfo] = React.useState();
  let history = useHistory();

  let { campId, staffApplicationId, staffId } = useParams();
  React.useEffect(() => {
    (async () => {
      let dataSent = {
        _methodId: 52,
        userId: localStorage.getItem("userId"),
        campId: campId,
        staffApplicationId: staffApplicationId,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/us8",
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: dataSent,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let output = await apiService2(apiInfo);
      // console.log(output.data.responseData[0])
      let invoiceData = output.data.responseData[0];
      // setInvoiceInfo(output.data.responseData[0][0]);
      // console.log(output.data.responseData[0][0], "lolllll");

      setInvoiceInfo({
        ...invoiceInfo,

        invoiceId: output.data.responseData[0][0].invoiceId,
        invoiceDate: (output.data.responseData[0][0].invoiceDate),
        invoiceNumber: output.data.responseData[0][0].invoiceNumber,
        unitPrice: output.data.responseData[0][0].unitPrice,
        total: (output.data.responseData[0][0].total),
        dueDate: (output.data.responseData[0][0].dueDate),
        supportStaffName: output.data.responseData[0][0].supportStaffName,
        campName: output.data.responseData[0][0].campName,
        campType: output.data.responseData[0][0].campType,
        paymentRefno: output.data.responseData[0][0].paymentRefno,
        lessAmountPaid: (output.data.responseData[0][0].lessAmountPaid),
        balance: (output.data.responseData[0][0].balance)
      });
    })();
  }, []);
  console.log(invoiceInfo, "herreeeeee");

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // hide last border

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,

      backgroundColor: "rgba(55, 149, 146, 1)",

      color: theme.palette.common.white,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  // const generatePDF = () => {
  //   // Get the element you want to convert to PDF (e.g., a div with id "my-element")
  //   const element = document.getElementById("my-element");
  //   // Use html2canvas to convert the element to a canvas
  //   html2canvas(element).then((canvas) => {
  //     // Create a new instance of jsPDF
  //     const doc = new jsPDF();
  //     // Calculate the width and height of the PDF document
  //     const width = doc.internal.pageSize.getWidth();
  //     const height = doc.internal.pageSize.getHeight();
  //     // Add the canvas to the PDF document
  //     doc.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, width, height);

  //     // Save the PDF
  //     doc.save("invoice.pdf");
  //   });
  // };
  const generatePDF = ()=> {
    console.log('yes')
            // document.getElementByID("click-element") .addEventListener("Click", () => {
                const element = document.getElementById('my-element');
            // const element = document.getElementByID ("my-element");
            
            var opt = {
            
            margin:6,
            padding : 4,
            filename: "supportInvoice.pdf",
            
            image: {type: "jpeg", quality:1},
            
            html2canvas: {scale: 2},
            
            jsPDF: {unit: 'mm', format: 'a3', orientation: 'p'}
            };
            html2pdf().from (element).set (opt).save();
            
            
            }
  return (
    <Box>
      <Grid container display="flex" justifyContent="space-between">
        <Link to={`/ChangeFunding/${campId}/${staffApplicationId}/${staffId}`}>
          <Box
            mt="0.75rem"
            alignSelf="flex-start"
            component="img"
            sx={{
              height: "0.75rem",
              width: "0.65rem",
              cursor: "pointer",
            }}
            height="20"
            alt="Logo"
            src={ArrowBack}
          ></Box>
        </Link>

        <Button
          variant="contained"
          sx={{
            px: "2rem",
            mx: "1rem",
            bgcolor: "#FF9529",
            textTransform: "none",
            "&:hover": { background: "#FF9529" },
          }}
          className="gilroy"
          onClick={() => generatePDF()}
        >
          Download
        </Button>

        {/* <Stack direction='column' >
                            <Box
                                component="img"
                                sx={{ height: "50%", width: "40%", my: "1rem" }}
                                alt="Logo"
                                src={Logo}
                            ></Box>
                            <Box sx={{ fontSize: '12px', maxWidth: '45%' }} className='gilroy'>Calm and Connected Pty Ltd5C 11 Erade Drive Piara Waters WA 6112</Box>
                        </Stack> */}
      </Grid>
      <div id="my-element">
        <Grid container display="flex" >
          <Grid item md={4} sm={12}>
            <Stack direction="column">
              <Box
                component="img"
                sx={{ height: "50%", width: "40%", my: "1rem" }}
                alt="Logo"
                src={Logo}
              ></Box>
              <Box
                sx={{ fontSize: "12px", maxWidth: "45%" }}
                className="gilroy"
              >
                Calm and Connected Pty Ltd5C 11 Erade Drive Piara Waters WA 6112
              </Box>
            </Stack>

            <Box>
              {/* <TextField
                sx={{
                  maxWidth: "50%",
                  marginTop: "2rem",
                  marginLeft: "1.5rem",
                }}
                id="outlined-basic"
              variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                size="large"
                // name="invoiceNumber"
                value={invoiceInfo && invoiceInfo.supportStaffName}
                // onChange={(event) => storeInfo(event)}
              /> */}
              <Grid container alignItems="center" sx={{mt:'3rem'}} >
                <Grid item md={6} className="customername">
                 Customer Name
                </Grid>
                <Grid item md={6} className="medium">
                <TextField
                // sx={{
                //   maxWidth: "50%",
                //   marginTop: "2rem",
                //   marginLeft: "1.5rem",
                // }}
                id="outlined-basic"
                className="customernamefield"
              variant="outlined"
              multiline
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                // name="invoiceNumber"
                value={invoiceInfo && invoiceInfo.supportStaffName}
                // onChange={(event) => storeInfo(event)}
              />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            md={3}
            sm={12}
            sx={{ alignItems: "center", alignSelf: "center" }}
          >
            <Box
              alignContent="center"
              sx={{ fontSize: "1.45rem" }}
              className="bold taxinvoice"
            >
              TAX INVOICES
            </Box>
          </Grid>

          <Grid item md={5} sm={12} sx={{ marginTop: "7rem" }}>
            <List>
              <ListItem>
                <Grid container alignItems='center'>
                  <Grid item md={4}  sx={{fontSize:'17px'}} >
                    Invoice Date
                  </Grid>
                  <Grid item md={5} className="medium">
                    <TextField
                     id="outlined-basic"
                     variant="outlined"
                     className="customernamefield"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      name="invoiceDate"
                      value={invoiceInfo && invoiceInfo.invoiceDate}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container alignItems="center">
                  <Grid item md={4} sx={{fontSize:'17px'}} >
                    Invoice number
                  </Grid>
                  <Grid item md={5} className="medium">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="customernamefield"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      name="invoiceNumber"
                      value={invoiceInfo && invoiceInfo.invoiceNumber}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container alignItems="center">
                  <Grid item md={4} sx={{fontSize:'17px'}}>
                    Reference
                  </Grid>
                  <Grid item md={5} className="medium">
                  <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="customernamefield"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      
                      value='Support Staff'
                    />
                    
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container alignItems="center">
                  <Grid item md={4}>
                    ABN
                  </Grid>
                  <Grid item md={5} className="medium">
                  <TextField
                      id="outlined-basic"
                      variant="outlined"
                      className="customernamefield"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      
                      value='22 613 026 430'
                    />
                    
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ my: "2rem", mt: "5rem" }}>
        <Table sx={{ minWidth: 400 }} aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="flex-start">Description</StyledTableCell>
              {/* <StyledTableCell align="flex-start"></StyledTableCell> */}
              <StyledTableCell align="right">Quantity</StyledTableCell>
              <StyledTableCell align="right">Unit Price</StyledTableCell>
              <StyledTableCell align="right">GST</StyledTableCell>
              <StyledTableCell align="right">Amount AUD</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {/* {tableArray.map((item, index) => ( */}
            <TableRow
              // key={index}
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row" style={{ borderBottom: "1px solid black" }} >
              <Stack direction="column" alignItems="flex-start" spacing={1}>
                  <Box>
                    {invoiceInfo && invoiceInfo.campName}  {invoiceInfo && invoiceInfo.campType}
                  </Box>
                  {/* <Box sx={{ color: "red" }}>item code goes</Box> */}
                </Stack>
              </StyledTableCell>
              {/* <StyledTableCell component="th" scope="row" align="flex-start" style={{ borderBottom: "1px solid black" }}>
                
              </StyledTableCell> */}

              <StyledTableCell
                // component="th"
                // scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" ,}}>
                      1.00
                    </Box>
                  </Stack>
                </Stack>
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                      ${invoiceInfo && invoiceInfo.unitPrice}.00
                    </Box>

                  </Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    GST Free
                    </Box>
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" ,}}>
                    Subtotal
                    </Box>
                  </Stack>
                    {/* <Typography variant="body1">
                    GST Free
                    </Typography>
                    <br />
                    
                    <Typography variant="body1">Subtotal</Typography> */}
                  </Box>

                </Stack>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  // height={1}
                >
                  
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    ${invoiceInfo && invoiceInfo.total}.00
                  </Box>
                  
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    ${invoiceInfo && invoiceInfo.total}.00
                  </Box>
                </Stack>

              </StyledTableCell>
            </TableRow>
            {/* ))} */}
            <TableRow>
              <StyledTableCell component="th" scope="row" style={{ borderBottom: "1px solid black" }}>
                <Stack direction="column" alignItems="flex-start" spacing={1}>
                  <Box>{""}</Box>
                  <Box></Box>
                </Stack>
              </StyledTableCell>
              {/* <StyledTableCell component="th" scope="row" style={{ borderBottom: "1px solid black" }}>
                <Stack direction="column" alignItems="center" spacing={1}>
                  <Box></Box>
                  
                </Stack>
              </StyledTableCell> */}

              <StyledTableCell
                component="th"
                scope="row"
                align="center"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    <Typography variant="body1"> </Typography>
                    <br />
                    <br />
                    <Typography variant="body1">
                      {""}
                      {""}
                      {""}
                      {""}
                    </Typography>
                  </Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                align="center"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    <Typography variant="body1"></Typography>
                    <br />
                    <br />
                    <Typography variant="body1">{""}</Typography>
                  </Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" ,fontWeight:'bold'}}>
                    TOTAL AUD 
                    </Box>
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" ,fontWeight:'bold'}}>
                    Less Amount Paid
                    </Box>
                  </Stack>
                    {/* <Typography variant="body1" sx={{fontWeight:'bold'}} >TOTAL AUD </Typography>
                    <br />
                    <Typography variant="body1"sx={{fontWeight:'bold'}}>Less Amount Paid</Typography> */}
                  </Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="right"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  // height={1}
                >
                  {/* <Box my="0.5rem" sx={{ alignItems: "flex-end" }}> */}
                    {/* <Typography variant="body1">
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        sx={{ width: "35%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        name="total"
                        value={invoiceInfo && invoiceInfo.total}
                        onChange={(event) => storeInfo(event)}
                      />
                    </Typography> */}
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                      ${invoiceInfo && invoiceInfo.total}.00
                    </Box>
                    {/* <br /> */}
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    ${Number(invoiceInfo && invoiceInfo.lessAmountPaid)}.00
                    {/* </Box> */}
                    


                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell component="th" scope="row" style={{ borderBottom: "1px solid black" }}>
                <Stack direction="column" alignItems="flex-start" spacing={1}>
                  <Box>{""}</Box>
                  <Box></Box>
                </Stack>
              </StyledTableCell>
              {/* <StyledTableCell component="th" scope="row" style={{ borderBottom: "1px solid black" }}>
                <Stack direction="column" alignItems="flex-end" spacing={1}>
                  <Box></Box>
                 
                </Stack>
              </StyledTableCell> */}

              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    <Typography variant="body1"> </Typography>
                    <br />
                    <br />
                    <Typography variant="body1">
                      {""}
                      {""}
                      {""}
                      {""}
                    </Typography>
                  </Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    <Typography variant="body1"></Typography>
                    <br />
                    <br />
                    <Typography variant="body1">{""}</Typography>
                  </Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end",fontWeight:'bold' }}>
                    AMOUNT DUE AUD
                  </Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  // height={1}

                >
                  {/* <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    <Typography variant="body1">
                      {invoiceInfo && invoiceInfo.total}
                    </Typography>
                  </Box> */}
                  {/* <TextField
                    id="standard-basic"
                    variant="standard"
                    sx={{ width: "35%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    name="quantity"
                    value={invoiceInfo && invoiceInfo.total}
                    InputProps={{
                      readOnly: true
                    }}
                  // onChange={(event) => storeInfo(event)}
                  /> */}
                  {/* <TextField
              id="outlined-basic"
              variant="outlined"
              
              size="small"
              sx={{ mx: '0.5rem' }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly:true
              }}
              name="balance"
              // value={ndisInfo && moment(ndisInfo.periodFrom).format("YYYY-MM-DD")}
              // value={invoiceInfo && invoiceInfo.balance}
              // onChange={(event) => storeInfo(event)}
            /> */}
                  <Box my="0.5rem" sx={{ alignItems: "flex-end",fontWeight:'bold' }}>
                    ${Number(invoiceInfo && invoiceInfo.total) - Number(invoiceInfo && invoiceInfo.lessAmountPaid)}.00
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

        <Grid>
          <List className="medium">
            <ListItem>
              <h5>DUE DATE </h5>
              <TextField
              id="outlined-basic"
              variant="outlined"
              
              size="small"
              sx={{ mx: '0.5rem' }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly:true
              }}
              name="dueDate"
              
              value={invoiceInfo && invoiceInfo.dueDate}
              
            />
            </ListItem>
            {/* <ListItem>Please put the following reference for bank transfer: <span className='bold'>{readData && readData.paymentRefno}</span></ListItem> */}
            <ListItem>Please put the following reference for bank transfer: <span className='bold'>{invoiceInfo && invoiceInfo.paymentRefno}</span></ListItem>
            <ListItem>Please complete bank transfer to: </ListItem>
            <ListItem>Calm and Connected</ListItem>
            <ListItem>BSB:<span className="bold">062692</span></ListItem>
            {/* <ListItem>Account No: 2808577</ListItem> */}
            <ListItem>Account:<span className="bold">77274604</span></ListItem>
          </List>
        </Grid>
      </div>
    </Box>
  );
}
