import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import { apiService2, apiService } from "../../Services/apiService";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";
import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  FormHelperText,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import moment from "moment";

import Swal from "sweetalert2";

import { Link, useParams } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Add from "../../Assets/Icons/Icons/add.png";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import "../../index.css";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import {
  SERVER_URL,
  VIEW_INCIDENT,
  CREATE_INCIDENT,
  US8_URL,
  UPDATESUITABILITY_STATUS,GETCAMP_APPLICANTSCOMMENTS,
} from "../../Services/apiEndpints";
const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const theme1 = createTheme({
  palette: {
    primary: {
      main: "#379592", // Set your desired checkbox color
    },
  },
});
export default function CampMangerViewIncidentReport() {
  // form validation code starts from here //
  const history = useHistory();
  let { incidentReportId, campId } = useParams();
  const componentRef = useRef();
  const [selectedValues1, setSelectedValues1] = useState([]);
  let {  applicantId } = useParams();
  let childId = localStorage.getItem("childId")
  const [managerRemarks, setManagerRemarks] = React.useState('')
  const [isConditionMet, setIsConditionMet] = useState(true);

  const [form, setForm] = useState({
    name: "",
    dateCompletingForm: "",
    dateOfIncident: "",
    timeOfIncident: "",
    locationOfIncident: "",
    nameofPerson: "",
    position: "",
    nameThatNotified: "",
    dateandtimenotified: "",
    dateandtimenotified1: "",
    typeOfIncident: "",
    seriousReportableIncident: "",
    seriousReportableIncidentYes: "",
    dateIncidentClosed: "",
    namedesignationandsignature: "",
    staffSignature: "",
    incidentandwitness: "",
    circumstanceincident: "",
    detailincidentdescription: "",
    initialresponse: "",
    otherIncident: "",
    typeOfReporting: "",
    otherTypeOfReporting: "",
    incidentRating: "",
    incidentRatingTextField: "",
    firstAidProvided: "",
    managementInformed: "",
    caregiverInformedYes: "",
    caregiverInformed: "",
    caregiverInformedNo: "",
    facetoface: "",
    firstAid: "",
    facetofaceno: "",
    regardingToPerson: "",
    regardingToPersonOther: "",
    regardingToWitness: "",
    regardingToWitnessOther: "",
    riskAssessment: "",
    riskAssessmentNo: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: null,
    dateCompletingForm: null,
    dateOfIncident: null,
    timeOfIncident: null,
    nameofPerson: null,
    locationOfIncident: null,
    position: null,
    nameThatNotified: null,
    dateandtimenotified: null,
    dateandtimenotified1: null,
    typeOfIncident: null,
    seriousReportableIncident: null,
    seriousReportableIncidentYes: null,
    dateIncidentClosed: null,
    namedesignationandsignature: null,
    staffSignature: null,
    incidentandwitness: null,
    circumstanceincident: null,
    detailincidentdescription: null,
    initialresponse: null,
    firstAid: null,
    otherIncident: null,
    typeOfReporting: null,
    otherTypeOfReporting: null,
    incidentRating: null,
    incidentRatingTextField: null,
    firstAidProvided: null,
    managementInformed: null,
    caregiverInformed: null,
    caregiverInformedYes: null,
    caregiverInformedNo: null,
    facetoface: null,
    facetofaceno: null,
    regardingToPerson: null,
    regardingToPersonOther: null,
    regardingToWitness: null,
    regardingToWitnessOther: null,
    riskAssessment: null,
    riskAssessmentNo: null,
  });
  const [otherComments, setOtherComments] = React.useState('')
  const handleRemarkChange = (event) => {
    setManagerRemarks(event.target.value)
    console.log(managerRemarks)
}
  const [status, setStauts] = useState(null);
  let userName = localStorage.getItem("userName")
  const generatePDF = () => {
    const element = document.getElementById("my-element");

    var opt = {
      margin: 6,
      padding: 6,
      filename: "IncidentReport.pdf",

      image: { type: "jpeg", quality: 1 },

      html2canvas: { scale: 2 },

      jsPDF: { unit: "mm", format: "a3", orientation: "p" },
    };

    html2pdf().from(element).set(opt).save();
  };

  const [assignCampList, setassignCampList] = useState([]);
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    let formObj = {};

    if (
      name === "typeOfIncident" ||
      name === "seriousReportableIncidentYes" ||
      name === "typeOfReporting" ||
      name === "caregiverInformedYes" ||
      name === "caregiverInformedNo" ||
      name === "regardingToPerson" ||
      name === "regardingToWitness"
    ) {
      if (checked) {
        formObj = { ...form, [name]: [...form[name], value] };
      } else {
        formObj = { ...form, [name]: form[name].filter((x) => x !== value) };
      }
    } else {
      formObj = { ...form, [name]: value };
    }

    setForm(formObj);
    validateField(name, formObj[name], formObj.seriousReportableIncident);
  };

  React.useEffect(() => {
    (async () => {
      console.log(incidentReportId);

      // handleAddRow()
      let gettran = {
        

        userId: localStorage.getItem("userId"),
        campId: campId,
        incidentId: incidentReportId,
      };

      let apiInfo = {
        url: `${SERVER_URL}${VIEW_INCIDENT}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);
      const campDataList = login.data.responseData[0];
      console.log(campDataList);

     

      const camps = {
        _methodId: "1",
        userId: localStorage.getItem("userId"),
        roleName: "THERAPIST",
      };

      let apiInfo1 = {
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: camps,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let assign = await apiService2(apiInfo1);
      console.log(assign);
      const campDataList1 = assign.data.responseData[0];
      setassignCampList(campDataList1);
      // const otherObject = JSON.stringify(campDataList[0].typeOfIncident).find(obj => obj.Key === 'Other');
      console.log(campDataList[0].typeOfIncident);

      const otherObject = campDataList[0].typeOfIncident.find(
        (obj) => obj.Key === "Other"
      );
      //       console.log(otherObject)
      const otherTypeOfReportingObject = campDataList[0].typeOfReporting.find(
        (obj) => obj.Key === "reportedByOther"
      );
      console.log(otherTypeOfReportingObject);
      const witnessSuppCaregiverInformedYesNo =
        campDataList[0].witnessSuppCaregiverVia.find(
          (obj) => obj.Key === "Face to Face with"
        );
      const resolutionActionsPersonDescription =
        campDataList[0].resolutionActionsPerson.find(
          (obj) => obj.Key === "Other"
        );
      const resolutionActionsWitnessDescription =
        campDataList[0].resolutionActionsWitness.find(
          (obj) => obj.Key === "Other"
        );
      if (
        campDataList[0]["participantsInvolved"] &&
        campDataList[0]["participantsInvolved"].length > 0
      ) {
        setSelectedValues1(campDataList[0]["participantsInvolved"]);
      }
      setStauts(campDataList[0]["status"]);
      let incidentClosedDate =  moment(
        campDataList[0].incidentClosedDate,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
  
        let dateOfIncident =  moment(
          campDataList[0].dateOfIncident,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD");
          let incidentNotifiedDatetime =  moment(
            campDataList[0].incidentNotifiedDatetime,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD");
  
            console.log(new Date())
            let dateCompletingForm =  moment(
             new Date(),
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD");
              setForm({
                ...form,
                name: campDataList[0].campName,
                nameofPerson: localStorage.getItem("userName"),
                position: localStorage.getItem("rolesName"),
                staffSignature: localStorage.getItem("userName"),
                namedesignationandsignature: localStorage.getItem("userName"),
                namedesignationandsignature1: localStorage.getItem("rolesName"),
                dateCompletingForm:dateCompletingForm ,
                dateOfIncident: dateOfIncident,
                timeOfIncident: campDataList[0].timeOfIncident,
                locationOfIncident: campDataList[0].locationOfIncident,
                nameThatNotified: campDataList[0].incidentNotifiedBy,
                dateandtimenotified: incidentNotifiedDatetime,
                dateandtimenotified1: campDataList[0].incidentNotifiedTime,
                typeOfIncident: campDataList[0].typeOfIncident.map(
                  (incident) => incident.Key
                ),
                otherIncident:
                  otherObject && otherObject.value ? otherObject?.value : "",
                seriousReportableIncident: campDataList[0].seriousReportableIncident,
                seriousReportableIncidentYes: campDataList[0].seriousIncidentType,
                typeOfReporting: campDataList[0].typeOfReporting.map(
                  (incident) => incident.Key
                ),
                otherTypeOfReporting:
                  otherTypeOfReportingObject && otherTypeOfReportingObject.value
                    ? otherTypeOfReportingObject?.value
                    : "",
                incidentRating: campDataList[0].incidentRating,
        
                incidentRatingTextField: campDataList[0].incidentRatingVal,
                incidentandwitness: campDataList[0].witness,
                circumstanceincident: campDataList[0].circumstancesLeadToIncident,
                detailincidentdescription:
                  campDataList[0].detailedDescriptionOfIncident,
                initialresponse: campDataList[0].initialRespose,
                firstAid: campDataList[0].seriousReportableIncident,
                firstAidProvided: campDataList[0].seriousReportableIncidentDesc,
        
                managementInformed: campDataList[0].witnessSuppManangementInformed,
                caregiverInformed: campDataList[0].witnessSuppCaregiverInformed,
                caregiverInformedYes: campDataList[0].witnessSuppCaregiverVia.map(
                  (incident) => incident.Key
                ),
        
                facetoface:
                  campDataList[0].witnessSuppCaregiverInformed === "Yes"
                    ? witnessSuppCaregiverInformedYesNo?.value
                    : "",
                facetofaceno:
                  campDataList[0].witnessSuppCaregiverInformed === "No"
                    ? witnessSuppCaregiverInformedYesNo?.value
                    : "",
        
                caregiverInformedNo: campDataList[0].witnessSuppCaregiverVia.map(
                  (incident) => incident.Key
                ),
        
                regardingToPerson: campDataList[0].resolutionActionsPerson.map(
                  (incident) => incident.Key
                ),
        
                regardingToWitness: campDataList[0].resolutionActionsWitness.map(
                  (incident) => incident.Key
                ),
        
                regardingToPersonOther: resolutionActionsPersonDescription?.value,
                regardingToWitnessOther: resolutionActionsWitnessDescription?.value,
                riskAssessment: campDataList[0].riskAssessment.includes(":")
                  ? campDataList[0].riskAssessment.split(":")[0]
                  : campDataList[0].riskAssessment,
                riskAssessmentNo: campDataList[0].riskAssessment.includes(":")
                  ? campDataList[0].riskAssessment.split(":")[1]
                  : "",
                dateIncidentClosed: incidentClosedDate
                
                // namedesignationandsignature: campDataList[0]["incidentClosedBy"],
              });
      console.log(campDataList[0].incidentClosedDate);
      console.log(form.dateIncidentClosed);
      console.log(selectedValues1);
      if (campDataList && campDataList[0].mediaEngagement.length > 0) {
        console.log(campDataList[0].mediaEngagement);
        const updatedTableRows = campDataList[0].mediaEngagement.map((val) => {
          const rowId = moment().valueOf();
          const newRow = {
            rowId,
            [`outlet${rowId}`]: val["outlet"],
            [`journalist${rowId}`]: val["journalist"],
            [`engagement${rowId}`]: val["engagement"],
          };
          return newRow;
        });
        setRows(updatedTableRows);
        updatedTableRows.forEach((row) => {
          const { rowId, ...rest } = row;
          setForm((prevForm) => ({
            ...prevForm,
            ...rest,
          }));
        });
        console.log(form);
      }
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const validateField = (name, value, seriousReportableIncident) => {
    let errorMsg = null;
    console.log(form.caregiverInformed + "" + form.caregiverInformedYes);
    switch (name) {
      case "name":
        if (!value) errorMsg = "Please select a camp.";
        break;
      case "dateCompletingForm":
        if (!value) errorMsg = "Please enter date of completing form.";
        break;
      case "dateOfIncident":
        if (!value) errorMsg = "Please enter date of incident.";
        break;
      case "timeOfIncident":
        if (!value) errorMsg = "Please enter time of incident.";
        break;
      case "incidentRating":
        if (!value) errorMsg = "Please enter incidentRating.";
        break;
      case "riskAssessment":
        if (!value) errorMsg = "Please select the value.";
        break;

      case "riskAssessmentNo":
        if (form.riskAssessment === "No" && !value) {
          errorMsg = "Please enter the field.";
        }
        break;

      case "managementInformed":
        if (!value) errorMsg = "Please enter managementInformed.";
        break;

      case "incidentRatingTextField":
        if (form.typeOfIncident === "incidentRating" && !value) {
          errorMsg = "Please enter the field.";
        }

      case "locationOfIncident":
        if (!value) errorMsg = "Please enter location of incident.";
        break;
      case "position":
        if (!value) errorMsg = "Please enter position.";
        break;

      case "typeOfIncident":
        if (value.length === 0) errorMsg = "Please select type of incident.";
        break;
      case "otherIncident":
        if (form.typeOfIncident.includes("Other") && !value) {
          errorMsg = "Please enter the other incident.";
        }
        break;
      case "typeOfReporting":
        if (value.length === 0) errorMsg = "Please select type of reporting.";
        break;
      case "otherTypeOfReporting":
        if (form.typeOfReporting.includes("otherTypeOfReporting") && !value) {
          errorMsg = "Please enter the other tyep of reporting.";
        }
        break;

      case "regardingToPerson":
        if (value.length === 0) errorMsg = "Please select the option.";
        break;

      case "regardingToPersonOther":
        if (form.regardingToPerson.includes("Other") && !value) {
          errorMsg = "Please enter the value.";
        }
        break;

      case "regardingToWitness":
        if (value.length === 0) errorMsg = "Please select the option.";
        break;

      case "regardingToWitnessOther":
        if (form.regardingToWitness.includes("Other") && !value) {
          errorMsg = "Please enter the value.";
        }
        break;

      case "nameofPerson":
        if (!value) errorMsg = "Please enter name of person.";
        break;
      case "location":
        if (!value) errorMsg = "Please enter location.";
        break;
      case "nameThatNotified":
        if (!value) errorMsg = "Please enter name of person that notified.";
        break;
      case "dateOfIncident":
        if (!value) errorMsg = "Please enter date of incident.";
        break;
      case "timeOfIncident":
        if (!value) errorMsg = "Please enter time of incident.";
        break;
      case "seriousReportableIncident":
        if (!value) errorMsg = "Please select Serious Reportable Incident.";
        break;
      case "dateIncidentClosed":
        if (!value) errorMsg = "Please enter dateIncidentClosed.";

        break;
      case "incidentandwitness":
        if (!value) errorMsg = "Please enter incidentandwitness.";

        break;

      case "namedesignationandsignature":
        if (!value) errorMsg = "Please enter namedesignationandsignature.";

        break;
      case "caregiverInformed":
        if (!value) errorMsg = "Please enter caregiverInformed.";

        break;

      case "staffSignature":
        if (!value) errorMsg = "Please enter staffSignature.";

        break;
      case "circumstanceincident":
        if (!value) errorMsg = "Please enter circumstanceincident.";
        break;
      case "detailincidentdescription":
        if (!value) errorMsg = "Please enter detailincidentdescription.";
        break;

      case "initialresponse":
        if (value.length === 0) errorMsg = "Please enter initialresponse.";
        break;

      case "seriousReportableIncidentYes":
        if (form.seriousReportableIncident === "Yes" && value.length === 0)
          errorMsg = "Please select seriousReportableIncidentYes.";
        break;

      case "firstAidProvided":
        if (form.seriousReportableIncident === "Yes" && value.length === 0)
          errorMsg = "Please select firstAidProvided.";
        break;
      case "caregiverInformedYes":
        if (form.caregiverInformed === "Yes" && value.length === 0)
          errorMsg = "Please select caregiverInformedYes.";
        break;
      case "caregiverInformedNo":
        if (form.caregiverInformed === "No" && value.length === 0)
          errorMsg = "Please select caregiverInformedNo.";
        break;

      case "facetoface":
        if (
          form.caregiverInformed === "Yes" &&
          form.caregiverInformedYes.includes("Face to Face with") &&
          !value
        )
          errorMsg = "Please select face to face.";
        break;
      case "firstAidProvided":
        if (form.firstAid === "Yes" && value.length === 0)
          errorMsg = "Please select first Aid Provided.";
        break;
      case "facetofaceno":
        if (
          form.caregiverInformed === "No" &&
          form.caregiverInformedNo.includes("Face to Face with") &&
          !value
        )
          errorMsg = "Please select face to face.";
        break;

      default:
        break;
    }

    setFormErrors({ ...formErrors, [name]: errorMsg });
    return errorMsg;
  };
  const [tableErros, settableErros] = useState({});
  const validateForm = () => {
    const errors = {};
    Object.keys(form).forEach((name) => {
      const value = form[name];
      const error = validateField(name, value, form.password);
      if (error) {
        errors[name] = error;
      }
    });
    return errors;
  };
  const tableErrors = () => {
    let errorMsg = {};

    rows.forEach((row) => {
      if (!form[`outlet${row.rowId}`]?.trim()) {
        errorMsg[`outlet${row.rowId}`] = "Outlet is required";
      }

      if (!form[`journalist${row.rowId}`]?.trim()) {
        errorMsg[`journalist${row.rowId}`] = "Journalist is required";
      }
      if (!form[`engagement${row.rowId}`]?.trim()) {
        errorMsg[`engagement${row.rowId}`] = "Engagement is required";
      }
    });
    return errorMsg;
  };
  const [isTableErrors, setisTableErros] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    const tableErros = tableErrors();
    console.log(tableErros);

    setFormErrors(errors); // Update formErrors state with validation errors
    if (Object.keys(tableErros).length === 0 && rows && rows.length > 0) {
      setisTableErros(false);
    } else {
      setisTableErros(true);
    }
    if (
      Object.keys(errors).length === 0 &&
      rows &&
      rows.length > 0 &&
      Object.keys(tableErros).length === 0
    ) {
      console.log("Form Data:", form);
      // Submit form or perform further actions

      console.log(form);
      const body = {};
      body["campName"] = form["name"];
      body["dateOfIncident"] = form["dateOfIncident"];
      body["timeOfIncident"] = form["timeOfIncident"];
      body["locationOfIncident"] = form["locationOfIncident"];
      body["incidentNotifiedBy"] = form["nameofPerson"];
      body["incidentNotifiedDatetime"] = form["dateandtimenotified"];
      body["incidentNotifiedTime"] = form["dateandtimenotified1"];
      if (form.typeOfIncident.includes("Other")) {
        let index = form.typeOfIncident.indexOf("Other");
        form.typeOfIncident[index] = `Other: ${form.otherIncident}`;

        body["typeOfIncident"] = form.typeOfIncident;
        console.log(body);
      } else {
        body["typeOfIncident"] = form.typeOfIncident;
      }
      body["isIncidentSerious"] = form["seriousReportableIncidentYes"];

      if (form["seriousReportableIncidentYes"].length === 0) {
        body["seriousIncidentType"] = [];
      } else {
        body["seriousIncidentType"] = form["seriousReportableIncidentYes"];
      }
      if (form["typeOfReporting"].includes("Other")) {
        let index = form["typeOfReporting"].indexOf("Other");
        form["typeOfReporting"][index] = `Other: ${form.otherTypeOfReporting}`;
        body["typeOfReporting"] = form.typeOfReporting;
      } else {
        body["typeOfIncident"] = form.typeOfIncident;
      }
      body["incidentRating"] = form["incidentRating"];
      body["incidentRatingTextField"] = form["incidentRatingTextField"];
      body["witness"] = form["incidentandwitness"];
      body["circumstancesLeadToIncident"] = form["circumstanceincident"];
      body["detailedDescriptionOfIncident"] = form["detailincidentdescription"];
     
      body["isIncidentSerious"] = form["seriousReportableIncident"];
      if (form["seriousReportableIncidentYes"].length === 0) {
        body["seriousIncidentType"] = [];
      } else {
        body["seriousIncidentType"] = form["seriousReportableIncidentYes"];
      }
      body["incidentRating"] = form["incidentRating"];
      body["incidentRatingVal"] = form["incidentRatingTextField"];
      body["witness"] = form["incidentandwitness"];
      body["circumstancesLeadToIncident"] = form["circumstanceincident"];
      body["detailedDescriptionOfIncident"] = form["detailincidentdescription"];

      body["initialRespose"] = form["initialresponse"];

      body["seriousReportableIncident"] = form["seriousReportableIncident"];
      body["seriousReportableIncidentDesc"] = form["firstAidProvided"];
      body["witnessSuppManangementInformed"] = form["managementInformed"];
      body["witnessSuppCaregiverInformed"] = form["caregiverInformed"];
      if (body["witnessSuppCaregiverInformed"] === "Yes") {
        if (form["caregiverInformedYes"].includes("Face to Face with")) {
          let index = form["caregiverInformedYes"].indexOf("Face to Face with");
          form["caregiverInformedYes"][
            index
          ] = `Face to Face with: ${form.facetoface}`;
          body["witnessSuppCaregiverVia"] = form.caregiverInformedYes;
        } else {
          body["witnessSuppCaregiverVia"] = form.caregiverInformedYes;
        }
      } else {
        if (form["caregiverInformedNo"].includes("Face to Face with")) {
          let index = form["caregiverInformedNo"].indexOf("Face to Face with");
          form["caregiverInformedNo"][
            index
          ] = `Face to Face with: ${form.facetofaceno}`;
          body["witnessSuppCaregiverVia"] = form.caregiverInformedNo;
        } else {
          body["witnessSuppCaregiverVia"] = form.caregiverInformedNo;
        }
      }

      if (form["regardingToPerson"].includes("Other")) {
        let index = form["regardingToPerson"].indexOf("Other");
        form["regardingToPerson"][
          index
        ] = `Other: ${form.regardingToPersonOther}`;
        body["resolutionActionsPerson"] = form.regardingToPerson;
      } else {
        body["resolutionActionsPerson"] = form.regardingToPerson;
      }

      if (form["regardingToWitness"].includes("Other")) {
        let index = form["regardingToWitness"].indexOf("Other");
        form["regardingToWitness"][
          index
        ] = `Other: ${form.regardingToWitnessOther}`;
        body["resolutionActionsWitness"] = form.regardingToWitness;
      } else {
        body["resolutionActionsWitness"] = form.regardingToWitness;
      }
      if (form["riskAssessment"] === "No") {
        body["riskAssessment"] =
          form["riskAssessment"] + ":" + form["riskAssessmentNo"];
      } else {
        body["riskAssessment"] = form["riskAssessment"];
      }

      const resultArray = [];

      rows.forEach((data) => {
        const rowId = data.rowId;
        const rowIdString = rowId.toString();

        const engagementKey = `engagement${rowIdString}`;
        const journalistKey = `journalist${rowIdString}`;
        const outletKey = `outlet${rowIdString}`;

        if (
          form.hasOwnProperty(engagementKey) &&
          form.hasOwnProperty(journalistKey) &&
          form.hasOwnProperty(outletKey)
        ) {
          const engagementValue = form[engagementKey];
          const journalistValue = form[journalistKey];
          const outletValue = form[outletKey];

          const resultObject = {
            engagement: engagementValue,
            journalist: journalistValue,
            outlet: outletValue,
          };

          resultArray.push(resultObject);
        }
      });

      body["mediaEngagement"] = resultArray;
      body["incidentClosedDate"] = form["dateIncidentClosed"];
      body["incidentClosedBy"] = localStorage.getItem("userId");
      body["userId"] = localStorage.getItem("userId");
      body["comments"] = "NA";
      body["status"] = "DRAFT";

      let apiInfo = {
        url: `${SERVER_URL}${CREATE_INCIDENT}`,
        method: "POST",
        postData: body,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let createIncident = await apiService(apiInfo);
      if (createIncident?.data?.responseCode === 0) {
        Swal.fire({
          icon: "success",
          iconHtml: `<img src="${GreenCheck}" >`,
          text: createIncident?.data?.responseData[0][0].Desc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
        }).then(history.push("/IncidentReport"));
      } else {
        Swal.fire({
          icon: "error",
          text: createIncident?.data?.responseData[0][0].Desc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
        });
      }
    }
  };
  const [rows, setRows] = useState([]);

  const typeOfIncident = [
    { value: "Physical Injury", label: "Physical Injury" },
    { value: "Emotional", label: "Emotional" },
    { value: "Medication", label: "Medication" },
    { value: "Complaint", label: "Complaint" },
    { value: "Restrictive Practice", label: "Restrictive Practice" },
    { value: "Other", label: "Other" },
  ];
  const typeOfReporting = [
    {
      value: "observedByWorker",
      label: "Observed by Worker",
    },
    {
      value: "reportedByClient",
      label: "Reported by Client",
    },
    {
      value: "reportedByOther",
      label: "Reported by Other",
    },
  ];
  const seriousReportableIncidentYes = [
    { value: "PhysicalInjury", label: "Physical Injury" },
    { value: "abusedisability", label: "Abuse Of A Person With Disability" },
    { value: "death", label: "Death" },
    { value: "neglect", label: "Neglect" },
    { value: "sexualMisconduct", label: "Sexual Misconduct" },
    {
      value: "unauthorisedRestrictivePractice",
      label: "Un Authorised Restrictive Practice",
    },
    { value: "unlawfulPhysicalContact", label: "Un Lawful Physical Contact" },
    { value: "unlawfulSexualContact", label: "Un Lawful Sexual Contact" },
  ];

  const caregiverInformedYes = [
    {
      value: "Phone Call",
      label: "Phone Call",
    },
    {
      value: "Email",
      label: "Email",
    },
    {
      value: "Face to Face with",
      label: "Face to Face with",
    },
  ];
  const caregiverInformedNo = [
    {
      value: "Phone Call",
      label: "Phone Call",
    },
    {
      value: "Email",
      label: "Email",
    },
    {
      value: "Face to Face with",
      label: "Face to Face with",
    },
  ];

  const regardingToPerson = [
    {
      value: "Advocacy",
      label: "Advocacy",
    },
    {
      value: "Contacted Support Person",
      label: "Contacted Support Person",
    },
    {
      value: "Counselling",
      label: "Counselling",
    },

    {
      value: "Debrief",
      label: "Debrief",
    },
    {
      value: "First Aid",
      label: "First Aid",
    },
    {
      value: "Medical Treatment",
      label: "Medical Treatment",
    },
    {
      value: "None Taken",
      label: "None Taken",
    },
    {
      value: "Removed source of Harm",
      label: "Removed source of Harm",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  const regardingToWitness = [
    {
      value: "Advocacy",
      label: "Advocacy",
    },
    {
      value: "Contacted Support Person",
      label: "Contacted Support Person",
    },

    {
      value: "Counselling",
      label: "Counselling",
    },
    {
      value: "Debrief",
      label: "Debrief",
    },
    {
      value: "First Aid",
      label: "First Aid",
    },
    {
      value: "Medical Treatment",
      label: "Medical Treatment",
    },
    {
      value: "None Taken",
      label: "None Taken",
    },
    {
      value: "Removed source of Harm",
      label: "Removed source of Harm",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  const handleAddRow = () => {
    const newRow = {
      rowId: moment().valueOf(),
    };

    setRows((prevState) => [...prevState, newRow]);
  };
  const handleDeleteRow = (id) => {
    console.log(rows);
    setRows(rows.filter((row) => row.rowId !== id));
  };
  const handleRowChange = (id, field, value, field1, value1) => {
    {
      console.log(id, field, value, field1, value1);
    }
    setRows(
      rows.map((row) =>
        row.id === id ? { ...row, [field]: value, [field1]: value1 } : row
      )
    );
  };

  const sendApplicationComments = async () => {
    let statusData = {
        userId: localStorage.getItem('userId'),
        campId: campId,
        applicationId: incidentReportId,
        applicationRemarks: managerRemarks,
        childId: childId

    }
    let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/updatesuitabilitystatus",
        url: `${SERVER_URL}${UPDATESUITABILITY_STATUS}`,
        method: "POST",
        postData: statusData,
        headers: {
            "Content-Type": "application/json",
            tokenheaderkey: localStorage.getItem("token"),
        },
    }
    let output = await apiService2(apiInfo)
    
    if (output?.data?.responseData[0][0].Code === "0") {

        let statusOutput = output.data.responseData[0][0].Desc
        console.log(statusOutput)
        Swal.fire({
            iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
            text: statusOutput,
            confirmButtonText: 'OK',
            confirmButtonColor: "#379592",
            customClass: {
                container: "my-swal",

            },
        }).then((result) => {
            if (result.value) {
               
                setIsConditionMet((prev) => !prev);
                setManagerRemarks('')
                //   console.log(isConditionMet)
            }
        })
    }
}

React.useEffect(() => {
  (
      async () => {
          // setLoading(true)
          let dataSent = {
              userId: localStorage.getItem('userId'),
              campId: campId,
              applicationId: incidentReportId

          }

          let apiInfo = {
              // url: "https://cncdevapi.natsoft.io/api/camps/getcampapplicantscomments",
              url: `${SERVER_URL}${GETCAMP_APPLICANTSCOMMENTS}`,
              method: "POST",
              postData: dataSent,
              headers: {
                  "Content-Type": "application/json",
                  tokenheaderkey: localStorage.getItem("token"),
              },

          }

          let output = await apiService2(apiInfo)
          let commentList = output.data.responseData[0][0].comments
          // setLoading(false)
          console.log(commentList)
          commentList && commentList.map((item) => {
              item.Others && setOtherComments(item.Others)
          })

      }
  )()
}
  , [isConditionMet])
  const handleRouting = () => {
    const previousPage = localStorage.getItem("incidentRoute");
    console.log(previousPage)
   
      // history.push(`/ChangeSuitability/${campId}/${applicantId}`);
      if (previousPage === "incident") {
        history.push(`/IncidentReport`);
        
       
      } else if (previousPage === "participant") {
        history.push(`/ViewParticipants/${campId}`)
      } 
      
     
    // {
    //   if (previousPage === "") {
    //     history.push(`/viewIncidentReport/${incidentReportId}/${campId}`);
        
       
    //   } 
     
    // }
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container direction="flex" justifyContent="space-between">
        <Stack
          direction="row"
          spacing={1}
          xs={12}
          sm={12}
          md={6}
          alignItems="center"
        >
          {/* <Link to="/IncidentReport"> */}
          <Box
            alignSelf="center"
            component="img"
            sx={{
              height: "0.75rem",
              width: "0.65rem",
              cursor: "pointer",
            }}
            height="20"
            alt="Logo"
            src={ArrowBack}
            onClick={() => handleRouting()}
          ></Box>
          {/* </Link> */}
          <Box alignSelf="center">View Incident Report</Box>
        </Stack>
       
        <Grid item>
          <Stack direction="row">
            <Button
              variant="contained"
              sx={{
                px: "1rem",
                mx: "0.5rem",
                bgcolor: "#379592",
                textTransform: "none",
                "&:hover": { background: "#379592" },
                mt: "2rem",
              }}
              onClick={generatePDF}
            >
              Download Report
            </Button>
            {status && status === "Draft" && (
            <Link
              to={`/editIncidentReport/${incidentReportId}/${campId}`}
              className="link2"
            >
            <Button
                variant="contained"
                sx={{
                  bgcolor: "#FF9529",
                  px: "1rem",
                  mx: "0.5rem",
                 
                  textTransform: "none",
                  "&:hover": { background: "#FF9529"},

                  mt: "2rem",
                }}
                className="gilroy"
              >
                Edit Report
              </Button>
              </Link>
            )}
          </Stack>
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={4}
          sx={{
            padding: "1rem", // Adjust the value as per your requirements

            borderRadius: 4,
            boxShadow: 1,
          }}
          ref={componentRef}
        >
           <Grid id="my-element" >
          <Grid item md={12}>
            <Box mt="1rem" sx={{ mb: "0.75rem" }} className="medium">
              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Selected Assigned Camp
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    onBlur={() => validateField("name", form.name)}
                    sx={{ ml: "1rem", color: "#000000" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                  {formErrors.name && (
                    <FormHelperText error>
                      {formErrors.name}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>
              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Date Completing Form:
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    type="date"
                    format="YYYY-MM-DD"
                    name="dateCompletingForm"
                    value={form.dateCompletingForm}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "dateCompletingForm",
                        form.dateCompletingForm
                      )
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.dateCompletingForm && (
                    <FormHelperText error>
                      {formErrors.dateCompletingForm}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>

              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Date Of Incident:
                  <TextField
                    id="standard-basic"
                    size="small"
                    type="date"
                    format="YYYY-MM-DD"
                    variant="outlined"
                    name="dateOfIncident"
                    value={form.dateOfIncident}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField("dateOfIncident", form.dateOfIncident)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.dateOfIncident && (
                    <FormHelperText error>
                      {formErrors.dateOfIncident}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>
              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Time Of Incident:
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="timeOfIncident"
                    value={form.timeOfIncident}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField("timeOfIncident", form.timeOfIncident)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    type="time"
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.timeOfIncident && (
                    <FormHelperText error>
                      {formErrors.timeOfIncident}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>
              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Location Of Incident:
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="locationOfIncident"
                    value={form.locationOfIncident}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "locationOfIncident",
                        form.locationOfIncident
                      )
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.locationOfIncident && (
                    <FormHelperText error>
                      {formErrors.locationOfIncident}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>

              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Name Of Person Completing The Form:
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="nameofPerson"
                    value={form.nameofPerson}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField("nameofPerson", form.nameofPerson)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.nameofPerson && (
                    <FormHelperText error>
                      {formErrors.nameofPerson}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>

              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Position:
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="position"
                    value={form.position}
                    onChange={handleChange}
                    onBlur={() => validateField("position", form.position)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.position && (
                    <FormHelperText error>{formErrors.position}</FormHelperText>
                  )}
                </Box>
              </Stack>

              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Name that notified was told:
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="nameThatNotified"
                    value={form.nameThatNotified}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField("nameThatNotified", form.nameThatNotified)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.nameThatNotified && (
                    <FormHelperText error>
                      {formErrors.nameThatNotified}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>

              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Date that notified was told:
                  <TextField
                    id="standard-basic"
                    size="small"
                    type="date"
                    format="YYYY-MM-DD"
                    variant="outlined"
                    name="dateandtimenotified"
                    value={form.dateandtimenotified}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "dateandtimenotified",
                        form.dateandtimenotified
                      )
                    }
                    // type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.dateandtimenotified && (
                    <FormHelperText error>
                      {formErrors.dateandtimenotified}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>

              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Time that notified was told:
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="dateandtimenotified1"
                    value={form.dateandtimenotified1}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "dateandtimenotified1",
                        form.dateandtimenotified1
                      )
                    }
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.dateandtimenotified1 && (
                    <FormHelperText error>
                      {formErrors.dateandtimenotified1}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>

              <Box sx={{ color: "#000" }}>Type of Incident:</Box>

              <Box>
                {typeOfIncident.map((language) => (
                  <Box>
                    <FormControlLabel
                      key={language.value}
                      control={
                        <Checkbox
                          name="typeOfIncident"
                          className="custom-checkbox"
                          value={language.value}
                          checked={form.typeOfIncident.includes(language.value)}
                          onChange={handleChange}
                          onBlur={() =>
                            validateField("typeOfIncident", form.typeOfIncident)
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled // Add the `disabled` prop to disable the checkbox
                        />
                      }
                      label={language.label}
                    />
                  </Box>
                ))}

                {formErrors.typeOfIncident && (
                  <FormHelperText error>
                    {formErrors.typeOfIncident}
                  </FormHelperText>
                )}
              </Box>

              {form.typeOfIncident.includes("Other") && (
                <TextField
                  name="otherIncident"
                  id="standard-basic"
                  size="small"
                  variant="outlined"
                  label="Other Incident"
                  value={form.otherIncident}
                  onChange={handleChange}
                  onBlur={() =>
                    validateField("otherIncident", form.otherIncident)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  error={!!formErrors.otherIncident}
                  helperText={formErrors.otherIncident}
                />
              )}

              <Box sx={{ color: "#000" }}>
                If this is a Serious Reportable Incident:
              </Box>
              <ThemeProvider theme={theme1}>
                <FormControl>
                  <RadioGroup
                    name="seriousReportableIncident"
                    value={form.seriousReportableIncident}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled // Add the `disabled` prop to disable the checkbox
                    onBlur={() =>
                      validateField(
                        "seriousReportableIncident",
                        form.seriousReportableIncident
                      )
                    }
                  >
                    <FormControlLabel
                      value="Yes"
                     
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                     
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                {formErrors.seriousReportableIncident &&
                  form.seriousReportableIncident === "Yes" && (
                    <FormHelperText error>
                      {formErrors.seriousReportableIncident}
                    </FormHelperText>
                  )}
              </ThemeProvider>
              {form.seriousReportableIncident === "Yes" && (
                <React.Fragment>
                  <Box sx={{ color: "#000" }}>If Yes, Choose Below</Box>

                  <Box>
                    {seriousReportableIncidentYes.map((language) => (
                      <Box>
                        <FormControlLabel
                          key={language.value}
                          control={
                            <Checkbox
                              name="seriousReportableIncidentYes"
                              className="custom-checkbox"
                              value={language.value}
                              checked={form.seriousReportableIncidentYes.includes(
                                language.value
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled // Add the `disabled` prop to disable the checkbox
                              onChange={handleChange}
                              onBlur={() =>
                                validateField(
                                  "seriousReportableIncidentYes",
                                  form.seriousReportableIncidentYes
                                )
                              }
                            />
                          }
                          label={language.label}
                        />
                      </Box>
                    ))}
                    {formErrors.seriousReportableIncidentYes && (
                      <FormHelperText error>
                        {formErrors.seriousReportableIncidentYes}
                      </FormHelperText>
                    )}
                  </Box>
                </React.Fragment>
              )}

              <Box sx={{ color: "#000" }}>Type of Reporting:</Box>

              <Box>
                {typeOfReporting &&
                  typeOfReporting != null &&
                  typeOfReporting.map((language) => (
                    <Box>
                      <FormControlLabel
                        key={language.value}
                        control={
                          <Checkbox
                            name="typeOfReporting"
                            className="custom-checkbox"
                            value={language.value}
                            checked={
                              form.typeOfReporting &&
                              form.typeOfReporting.includes(language.value)
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled // Add the `disabled` prop to disable the checkbox
                            onChange={handleChange}
                            onBlur={() =>
                              validateField(
                                "typeOfReporting",
                                form.typeOfReporting
                              )
                            }
                          />
                        }
                        label={language.label}
                      />
                    </Box>
                  ))}
                {formErrors.typeOfReporting && (
                  <FormHelperText error>
                    {formErrors.typeOfReporting}
                  </FormHelperText>
                )}
              </Box>
              {form.typeOfReporting.includes("reportedByOther") && (
                <TextField
                  name="otherTypeOfReporting"
                  id="standard-basic"
                  size="small"
                  variant="outlined"
                  label="Other type Of reporting"
                  value={form.otherTypeOfReporting}
                  onChange={handleChange}
                  onBlur={() =>
                    validateField(
                      "otherTypeOfReporting",
                      form.otherTypeOfReporting
                    )
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled // Add the `disabled` prop to disable the checkbox
                  error={!!formErrors.otherTypeOfReporting}
                  helperText={formErrors.otherTypeOfReporting}
                />
              )}
              <Box sx={{ color: "#000",my:'0.5rem' }}>
                Incident Rating (Tick and Provide Number)
              </Box>
              <ThemeProvider theme={theme1}>
                <Box>
                  <RadioGroup
                    aria-label="typeOfIncident"
                    name="incidentRating"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled // Add the `disabled` prop to disable the checkbox
                    value={form.incidentRating}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Near Miss"
                     
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="Near Miss"
                    />
                    <FormControlLabel
                      value="Low"
                     
                      control={<Radio />}
                      label="Low"
                      sx={{ color: "#707070" }}
                    />
                    <FormControlLabel
                      value="Moderate"
                   
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="Moderate"
                    />
                    <FormControlLabel
                      value="Major"
                   
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="Major"
                    />
                    <FormControlLabel
                      value="Extreme"
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      // disabled // Add the `disabled` prop to disable the checkbox
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="Extreme"
                    />
                  </RadioGroup>

                  {formErrors.incidentRating && (
                    <FormHelperText error>
                      {formErrors.incidentRating}
                    </FormHelperText>
                  )}
                </Box>
              </ThemeProvider>
              <Box>
                {(form.incidentRating === "Near Miss" ||
                  form.incidentRating === "Low" ||
                  form.incidentRating === "Moderate" ||
                  form.incidentRating === "Major" ||
                  form.incidentRating === "Extreme") && (
                  <TextField
                    name="incidentRatingTextField"
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    label="Enter a Number"
                    value={form.incidentRatingTextField}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "incidentRatingTextField",
                        form.incidentRatingTextField
                      )
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    error={!!formErrors.incidentRatingTextField}
                    helperText={formErrors.incidentRatingTextField}
                  />
                )}
              </Box>
              <Grid mt={2}>
                <TableContainer style={{ width: 800 }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#379592" }}>
                        <TableCell
                          align="center"
                          colSpan={7}
                          sx={{ color: "#FFFFFF" }}
                        >
                          Consequences
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{ backgroundColor: "#379592" }}
                        ></TableCell>

                        <TableCell></TableCell>

                        <TableCell>1 Insignificant</TableCell>

                        <TableCell>2 Minor</TableCell>

                        <TableCell>3 Moderate</TableCell>

                        <TableCell>4 Major</TableCell>

                        <TableCell>5 Catastropic</TableCell>
                      </TableRow>
                    </TableBody>

                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{ backgroundColor: "#379592" }}
                        ></TableCell>

                        <TableCell>1 Rare</TableCell>

                        <TableCell>Low(1)</TableCell>

                        <TableCell>Low(2)</TableCell>

                        <TableCell>Low(3)</TableCell>

                        <TableCell>Moderate(4)</TableCell>

                        <TableCell>Major(5)</TableCell>
                      </TableRow>
                    </TableBody>

                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{ backgroundColor: "#379592", color: "#FFFFFF" }}
                        >
                          Likelihood
                        </TableCell>

                        <TableCell>2 Unlikely</TableCell>

                        <TableCell>Low(2)</TableCell>

                        <TableCell>Minor(4)</TableCell>

                        <TableCell>Minor(6)</TableCell>

                        <TableCell>Moderate(8)</TableCell>

                        <TableCell>Major(10)</TableCell>
                      </TableRow>
                    </TableBody>

                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{ backgroundColor: "#379592" }}
                        ></TableCell>

                        <TableCell> 3 Possible</TableCell>

                        <TableCell>Low(3)</TableCell>

                        <TableCell>Minor(6)</TableCell>

                        <TableCell>Moderate(9)</TableCell>

                        <TableCell>Major(12)</TableCell>

                        <TableCell>Extreme(15)</TableCell>
                      </TableRow>
                    </TableBody>

                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{ backgroundColor: "#379592" }}
                        ></TableCell>

                        <TableCell>4 Likely</TableCell>

                        <TableCell>Minor(4)</TableCell>

                        <TableCell>Moderate(8)</TableCell>

                        <TableCell>Major(12)</TableCell>

                        <TableCell>Extreme(16)</TableCell>

                        <TableCell>Extreme(20)</TableCell>
                      </TableRow>
                    </TableBody>

                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{ backgroundColor: "#379592" }}
                        ></TableCell>

                        <TableCell>5 Probable</TableCell>

                        <TableCell>Minor(5)</TableCell>

                        <TableCell>Moderate(10)</TableCell>

                        <TableCell>Major(15)</TableCell>

                        <TableCell>Extreme(20)</TableCell>

                        <TableCell>Extreme(25)</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Participants involved in incident and witnesses:
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="incidentandwitness"
                    value={form.incidentandwitness}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "incidentandwitness",
                        form.incidentandwitness
                      )
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                </Box>
              </Stack>

              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  What were the circumstances leading up to the incident:
                  <TextField
                    fullWidth
                    
                    id="outlined-multiline-static"
                    variant="outlined"
                    multiline
                    rows={2}
                   
                    name="circumstanceincident"
                    value={form.circumstanceincident}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "circumstanceincident",
                        form.circumstanceincident
                      )
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: 800,my:"1rem" }}
                  ></TextField>
                  {formErrors.circumstanceincident && (
                    <FormHelperText error>
                      {formErrors.circumstanceincident}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>

              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Please provide a detailed description of the incident:
                  {/* <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="detailincidentdescription"
                    value={form.detailincidentdescription}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "detailincidentdescription",
                        form.detailincidentdescription
                      )
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField> */}
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    variant="outlined"
                    multiline
                    rows={2}
                    name="detailincidentdescription"
                    value={form.detailincidentdescription}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "detailincidentdescription",
                        form.detailincidentdescription
                      )
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: 800,mt:"1rem" }}
                  ></TextField>
                  {formErrors.detailincidentdescription && (
                    <FormHelperText error>
                      {formErrors.detailincidentdescription}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>

              <Stack direction="row" spacing={12} sx={{ mt: "1.5rem" }}>
                <Box sx={{ color: "#000" }}>
                  What was the initial response:
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="initialresponse"
                    value={form.initialresponse}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField("initialresponse", form.initialresponse)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.initialresponse && (
                    <FormHelperText error>
                      {formErrors.initialresponse}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>
              <Box>First aid required:</Box>
              <Box>
                <ThemeProvider theme={theme1}>
                  <RadioGroup
                    name="firstAid"
                    value={form.firstAid}
                    onChange={handleChange}
                    onBlur={() => validateField("firstAid", form.firstAid)}
                  >
                    <FormControlLabel
                      value="Yes"
                      // sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      // sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </ThemeProvider>
                {formErrors.firstAid && form.firstAid === "Yes" && (
                  <FormHelperText error>{formErrors.firstAid}</FormHelperText>
                )}
              </Box>
              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                {form.firstAid === "Yes" && (
                  <Box sx={{ color: "#000" }}>
                    If yes, please describe first aid provided:
                    <TextField
                      name="firstAidProvided"
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      label=""
                      value={form.firstAidProvided}
                      onChange={handleChange}
                      sx={{ml: "1rem"}}
                      onBlur={() =>
                        validateField("firstAidProvided", form.firstAidProvided)
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      error={!!formErrors.firstAidProvided}
                      helperText={formErrors.firstAidProvided}
                    />
                  </Box>
                )}
              </Stack>
              <Box sx={{ color: "#000", fontWeight: 600, mt: "1rem" }}>
                Support given to the witness(es):
              </Box>
              <Box sx={{ color: "#000", mt: "1rem" }}>Management Informed</Box>
              <ThemeProvider theme={theme1}>
                <Box>
                  <RadioGroup
                    name="managementInformed"
                    value={form.managementInformed}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Yes"
                     
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>

                  {formErrors.managementInformed && (
                    <FormHelperText error>
                      {formErrors.managementInformed}
                    </FormHelperText>
                  )}
                </Box>
              </ThemeProvider>
              <Box sx={{ color: "#000", mt: "1rem" }}>Caregiver Informed:</Box>

              <Box>
                <ThemeProvider theme={theme1}>
                  <RadioGroup
                    name="caregiverInformed"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled // Add the `disabled` prop to disable the checkbox
                    value={form.caregiverInformed}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Yes"
                     
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                     
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </ThemeProvider>
                {formErrors.caregiverInformed && (
                  <FormHelperText error>
                    {formErrors.caregiverInformed}
                  </FormHelperText>
                )}
              </Box>

              {form.caregiverInformed === "Yes" && (
                <React.Fragment>
                  <Box sx={{ color: "#000" }}>If Yes, Caregiver Informed:</Box>

                  <Box>
                    {caregiverInformedYes.map((language) => (
                      <Box>
                        <FormControlLabel
                          key={language.value}
                          control={
                            <Checkbox
                              name="caregiverInformedYes"
                              className="custom-checkbox"
                              value={language.value}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled // Add the `disabled` prop to disable the checkbox
                              checked={form.caregiverInformedYes.includes(
                                language.value
                              )}
                              onChange={handleChange}
                              onBlur={() =>
                                validateField(
                                  "caregiverInformedYes",
                                  form.caregiverInformedYes
                                )
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          }
                          label={language.label}
                        />
                      </Box>
                    ))}
                    {formErrors.caregiverInformedYes && (
                      <FormHelperText error>
                        {formErrors.caregiverInformedYes}
                      </FormHelperText>
                    )}
                  </Box>
                </React.Fragment>
              )}

              <Box sx={{ color: "#000", mt: "1rem" }}>
                {form.caregiverInformed === "Yes" &&
                  form.caregiverInformedYes.includes("Face to Face with") && (
                    <TextField
                      name="facetoface"
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      label=""
                      value={form.facetoface}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField("facetoface", form.facetoface)
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                {formErrors.facetoface && (
                  <FormHelperText error>{formErrors.facetoface}</FormHelperText>
                )}
              </Box>

              {form.caregiverInformed === "No" && (
                <React.Fragment>
                  <Box sx={{ color: "#000" }}>
                    If No, Plan to Caregiver Informed:
                  </Box>

                  <Box>
                    {caregiverInformedNo.map((language) => (
                      <Box>
                        <FormControlLabel
                          key={language.value}
                          control={
                            <Checkbox
                              name="caregiverInformedNo"
                              className="custom-checkbox"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled // Add the `disabled` prop to disable the checkbox
                              value={language.value}
                              checked={form.caregiverInformedNo.includes(
                                language.value
                              )}
                              onChange={handleChange}
                              onBlur={() =>
                                validateField(
                                  "caregiverInformedNo",
                                  form.caregiverInformedNo
                                )
                              }
                            />
                          }
                          label={language.label}
                        />
                      </Box>
                    ))}
                    {formErrors.caregiverInformedNo && (
                      <FormHelperText error>
                        {formErrors.caregiverInformedNo}
                      </FormHelperText>
                    )}
                  </Box>
                </React.Fragment>
              )}

              <Box sx={{ color: "#000", mt: "1rem" }}>
                {form.caregiverInformed === "No" &&
                  form.caregiverInformedNo.includes("Face to Face with") && (
                    <TextField
                      name="facetofaceno"
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      label=""
                      value={form.facetofaceno}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField("facetofaceno", form.facetofaceno)
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                {formErrors.facetofaceno && (
                  <FormHelperText error>
                    {formErrors.facetofaceno}
                  </FormHelperText>
                )}
              </Box>

              <Box sx={{ color: "#000", fontWeight: 600, mt: "1rem" }}>
                Resolution actions to be taken in regard to witness(es) and
                others involved
              </Box>
              <Box sx={{ color: "#000", mt: "1rem" }}>
                Resolution actions to be taken in regard to the person involved{" "}
              </Box>

              <Box>
                {regardingToPerson.map((language) => (
                  <Box>
                    <FormControlLabel
                      key={language.value}
                      control={
                        <Checkbox
                          name="regardingToPerson"
                          className="custom-checkbox"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled // Add the `disabled` prop to disable the checkbox
                          value={language.value}
                          checked={form.regardingToPerson.includes(
                            language.value
                          )}
                          onChange={handleChange}
                          onBlur={() =>
                            validateField(
                              "regardingToPerson",
                              form.regardingToPerson
                            )
                          }
                        />
                      }
                      label={language.label}
                    />
                  </Box>
                ))}
                {formErrors.regardingToPerson && (
                  <FormHelperText error>
                    {formErrors.regardingToPerson}
                  </FormHelperText>
                )}
              </Box>

              {form.regardingToPerson.includes("Other") && (
                <TextField
                  name="regardingToPersonOther"
                  id="standard-basic"
                  size="small"
                  variant="outlined"
                  label=""
                  value={form.regardingToPersonOther}
                  onChange={handleChange}
                  onBlur={() =>
                    validateField(
                      "regardingToPersonOther",
                      form.regardingToPersonOther
                    )
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  error={!!formErrors.regardingToPersonOther}
                  helperText={formErrors.regardingToPersonOther}
                />
              )}

              <Box sx={{ color: "#000", mt: "1rem" }}>
                Resolution actions to be taken in regard to the witness(es):
              </Box>

              <Box>
                {regardingToWitness.map((language) => (
                  <Box>
                    <FormControlLabel
                      key={language.value}
                      control={
                        <Checkbox
                          name="regardingToWitness"
                          className="custom-checkbox"
                          value={language.value}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled // Add the `disabled` prop to disable the checkbox
                          checked={form.regardingToWitness.includes(
                            language.value
                          )}
                          onChange={handleChange}
                          onBlur={() =>
                            validateField(
                              "regardingToWitness",
                              form.regardingToWitness
                            )
                          }
                        />
                      }
                      label={language.label}
                    />
                  </Box>
                ))}
                {formErrors.regardingToWitness && (
                  <FormHelperText error>
                    {formErrors.regardingToWitness}
                  </FormHelperText>
                )}
              </Box>

              {form.regardingToWitness.includes("Other") && (
                <TextField
                  name="regardingToWitnessOther"
                  id="standard-basic"
                  size="small"
                  variant="outlined"
                  label=""
                  value={form.regardingToWitnessOther}
                  onChange={handleChange}
                  onBlur={() =>
                    validateField(
                      "regardingToWitnessOther",
                      form.regardingToWitnessOther
                    )
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  error={!!formErrors.regardingToWitnessOther}
                  helperText={formErrors.regardingToWitnessOther}
                />
              )}

              <Box sx={{ color: "#000", mt: "1rem" }}>
                Has a risk assessment note been completed:
              </Box>

              <Box>
                <ThemeProvider theme={theme1}>
                  <RadioGroup
                    name="riskAssessment"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled // Add the `disabled` prop to disable the checkbox
                    value={form.riskAssessment}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField("riskAssessment", form.riskAssessment)
                    }
                  >
                    <FormControlLabel
                      value="Yes"
                      
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="In progress"
                     
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="In progress"
                    />
                    <FormControlLabel
                      value="No"
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      // disabled // Add the `disabled` prop to disable the checkbox
                      sx={{ color: "#707070" }}
                      control={<Radio />}
                      label="No, Please Provide Reason"
                    />
                  </RadioGroup>
                </ThemeProvider>
                {formErrors.riskAssessment && (
                  <FormHelperText error>
                    {formErrors.riskAssessment}
                  </FormHelperText>
                )}
              </Box>
              {form.riskAssessment === "No" && (
                <TextField
                  name="riskAssessmentNo"
                  id="standard-basic"
                  size="small"
                  variant="outlined"
                  label=""
                  value={form.riskAssessmentNo}
                  onChange={handleChange}
                  onBlur={() =>
                    validateField("riskAssessmentNo", form.riskAssessmentNo)
                  }
                  error={!!formErrors.riskAssessmentNo}
                  helperText={formErrors.riskAssessmentNo}
                />
              )}
              <Box mt="2rem">
                <TableContainer component={Paper}>
                  <Table
                    //   sx={{ minWidth: 500 }}
                    aria-label="customized table"
                    my="1rem"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">Outlet</StyledTableCell>
                        <StyledTableCell align="center">
                          Journalist
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Engagement
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">
                        Action
                        </StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.rowId}>
                          <TableCell>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`outlet${row.rowId}`}
                              value={form[`outlet${row.rowId}`]}
                              onChange={handleChange}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            {tableErros[`outlet${row.rowId}`] && (
                              <FormHelperText error>
                                {tableErros[`outlet${row.rowId}`]}
                              </FormHelperText>
                            )}
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`journalist${row.rowId}`}
                              value={form[`journalist${row.rowId}`]}
                              onChange={handleChange}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            {tableErros[`journalist${row.rowId}`] && (
                              <FormHelperText error>
                                {tableErros[`journalist${row.rowId}`]}
                              </FormHelperText>
                            )}
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`engagement${row.rowId}`}
                              value={form[`engagement${row.rowId}`]}
                              onChange={handleChange}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            {tableErros[`engagement${row.rowId}`] && (
                              <FormHelperText error>
                                {tableErros[`engagement${row.rowId}`]}
                              </FormHelperText>
                            )}
                          </TableCell>
                          {/* <TableCell align="right">
                            <Button
                              variant="contained"
                              color="secondary"
                              InputProps={{
                                readOnly: true,
                              }}
                              onClick={() => handleDeleteRow(row.rowId)}
                            >
                              Delete
                            </Button>
                          </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                    {/* <tfoot>
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          <Box
                            component="img"
                            src={Add}
                            onClick={handleAddRow}
                            sx={{ width: "1rem" }}
                          ></Box>
                        </TableCell>
                      </TableRow>
                    </tfoot> */}
                  </Table>
                </TableContainer>
              </Box>

              {isTableErrors && (
                <FormHelperText error>
                  Please Enter the table fields
                </FormHelperText>
              )}
              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Name of staff member writing report:
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="staffSignature"
                    value={form.staffSignature}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField("staffSignature", form.staffSignature)
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.staffSignature && (
                    <FormHelperText error>
                      {formErrors.staffSignature}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>
              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Name and designation of staff member closed off
                  report:
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="namedesignationandsignature"
                    value={form.namedesignationandsignature}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "namedesignationandsignature",
                        form.namedesignationandsignature
                      )
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="namedesignationandsignature1"
                    value={form.namedesignationandsignature1}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "namedesignationandsignature1",
                        form.namedesignationandsignature1
                      )
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.namedesignationandsignature && (
                    <FormHelperText error>
                      {formErrors.namedesignationandsignature}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>

              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Date Incident Closed:
                  <TextField
                    id="standard-basic"
                    size="small"
                    type="date"
                    format="YYYY-MM-DD"
                    variant="outlined"
                    name="dateIncidentClosed"
                    value={form.dateIncidentClosed}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "dateIncidentClosed",
                        form.dateIncidentClosed
                      )
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ ml: "1rem", color: "#000000" }}
                  ></TextField>
                  {formErrors.dateIncidentClosed && (
                    <FormHelperText error>
                      {formErrors.dateIncidentClosed}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>
              <Stack>
                    <TextField
                        id="standard-basic"
                        // label="Past Camp History"
                        size='small'
                        variant="outlined"
                        value={userName}
                        InputProps={{
                            readOnly: true,
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        sx={{
                            width: 300,
                            my: '1rem'
                        }}
                    ></TextField>
                    <TextField
                        id="outlined-multiline-static"
                        // label="Past Camp History"
                        multiline
                        rows={4}
                        size='small'
                        name="comments"
                        // value={form.comments}
                        // onChange={handleChange}
                        value={managerRemarks}
                        onChange={handleRemarkChange}
                        // onBlur={() =>
                        //   validateField(
                        //     "comments",
                        //     form.comments
                        //   )
                        // }
                        variant="outlined"
                        label='Enter Comments '
                       
                        sx={{
                            width: 600,
                        }}
                    ></TextField>
                    {formErrors.comments && (
                        <FormHelperText error>
                          {formErrors.comments}
                        </FormHelperText>
                      )}

                    <Button variant="contained"
                        onClick={() => { sendApplicationComments() }}
                        sx={{
                            bgcolor: "#379592",
                            textTransform: 'none',
                            "&:hover": { background: "#379592" },
                            width: "200px",
                            my: '0.75rem',

                        }}>
                        Submit
                    </Button>
                </Stack>
                
                <Box className='medium' my='1rem'>
                    Previous Comments
                </Box>
                <Grid container >
                    <TableContainer component={Paper} sx={{ width: 600 }}>
                        <Table
                            sx={{ width: 600 }}
                            aria-label="customized table"
                            my="1rem"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Camp Manager</StyledTableCell>
                                    <StyledTableCell align="center">
                                        Remarks
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {otherComments &&
                                    otherComments.map((row, index) => (
                                        <TableRow >
                                            <TableCell align="center">{row.CampManager}
                                            </TableCell>
                                            <TableCell align="center">{row.Remarks}
                                            </TableCell>

                                        </TableRow>
                                     ))} 
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
          </Grid>
        </Grid>
        </Grid>
      </form>
    </ThemeProvider>
  );
}
