import React from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Login from "./components/Login";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";
import VerifyOTP from "./components/VerifyOTP";
import CreateNewPassword from "./components/CreateNewPassword";
import PasswordChanged from "./components/PasswordChanged";
import CampList from "./components/CampList";
import CampList1 from "./components/CampList1";
import CreateCamp from "./components/CreateCamp";
import ViewCamp from "./components/ViewCamp";
import FullCamp from "./components/FullCamp";
import UploadingPhoto from "./components/UploadingPhoto";
import UploadingPhoto6 from "./components/UploadingPhoto6";
import CampName from "./components/CampName";
import "./App.css";
import SideBar from "./components/SideBar";
import { BrowserRouter, Switch, Route, useHistory, Redirect } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import ForgotPasswordPopup from "./components/ForgotPasswordPopup";
import ActivateStaff from "./components/ActivateStaff";
import AddUser from "./components/AddUser";
import Email from "./components/Email";
import UserAddedPopup from "./components/UserAddedPopup";
import ActivateCampStaff from "./components/ActivateCampStaff";
import RemoveCamp from "./components/RemoveCamp";
import EditCamp from "./components/EditCamp";
import SetReminder from "./components/Participants/SetReminder";
import ReminderLog from "./components/Participants/ReminderLog";
// import Demo from "./components/Participants/demo";
import ChangeSuitability from "./components/Participants/ChangeSuitability";
import ServiceAgreement from "./components/Participants/serviceAgreement";
import NotificationLog from "./components/Participants/NotificationsLog";
import Notifications from "./components/Participants/Notifications";
import SupportStaffInvoiceSent from "./components/SupportStaff/SupportStaffInvoiceSent";
import ParticipantsList from "./components/Participants/ParticipantsList";
import ApplicantsReview from "./components/Participants/ApplicantsReview";
import ReviewApplication from "./components/Participants/ReviewApplication";
import PendingSuitabilityPreScreen from "./components/Participants/PendingSuitabilityPreScreen";
import PendingSuitabilityOther from "./components/Participants/PendingSuitabilityOther";
import RecentFiles from "./components/Participants/RecentFiles";
import SupportStaffCampList from "./components/SupportStaff/SupportStaffCampList";
import SupportStaffApplicants from "./components/SupportStaff/SupportStaffApplicants";
import ChangeFunding from "./components/SupportStaff/ChangeFunding";
import SupportStaffApplicationReview from "./components/SupportStaff/SupportStaffApplicationReview";
import SupportStaffReminderLog from "./components/SupportStaff/SupportStaffReminderLog";
import SupportStaffFiles from "./components/SupportStaff/SupportStaffFiles";
import SupportstaffNotificationLog from "./components/SupportStaff/SupportstaffNotificationLog";
import SupportstaffNotifications from "./components/SupportStaff/SupportstaffNotifications";
import TherapyCampList from "./components/Therapy/TherapyCampList";
import GoalSettinginCampManger from "./components/Therapy/GoalSettinginCampManger";
import CampListForCampStaff from "./components/TherapyCampStaff/CampListForCampStaff";
import GoalSettingInCampStaff from "./components/TherapyCampStaff/GoalSettingInCampStaff";
import Sample from "./components/Sample";
import ViewParticipants from "./components/ViewParticipants";
import AddParticipants from "./components/AddParticipants";
import Goals from "./components/TherapyCampStaff/Goals";
import ProfileCampStaff from "./components/TherapyCampStaff/ProfileCampStaff";
import CreateWelcomePack from "./components/Participants/CreateWelcomePack";
import AddDescription from "./components/Participants/AddDescription";
import WelcomePackPublish from "./components/Participants/WelcomePackPublish";
import WelcomePackRepublish from "./components/Participants/WelcomePackRepublish";
import WelcomePackEdit from "./components/Participants/WelcomePackEdit";
import CampNotificationLog from "./components/CampNotificationLog";
import CampNotification from "./components/CampNotification";
import Gallery from "./components/Gallery";
import CheckInOut from "./components/CheckInOut";
import CheckInOutLog from "./components/CheckInOutLog";
import ChildProfile from "./components/ChildProfile";
import IncidentReport from "./components/ReportsModule/IncidentReport";
import Reports from "./components/ReportsModule/reports";
import CreateIncidentReport from "./components/ReportsModule/createincidentport";
import ViewIncidentReport from "./components/ReportsModule/viewincidentport";
import EditIncident from "./components/ReportsModule/editincidentport";
import CampStaffNotificationLog from "./components/TherapyCampStaff/CampStaffNotificationLog";
import CampStaffNotifications from "./components/TherapyCampStaff/CampStaffNotifications";
import MedicationAssignedCamps from "./components/Medication/MedicationAssignedCamps";
import ApplicantsReviewforMedication from "./components/Medication/ApplicantsReviewforMedication";
import ParticipantsMedicalRecord from "./components/Medication/ParticipantsMedicalRecord";
import MedicationMangerCheckInandOut from "./components/Medication/MedicationMangerCheckInandOut";
import EditParticipantMedicalRecord from "./components/Medication/EditParticipantMedicalRecord";
import MedicalForm from "./components/Medication/MedicalForm";
import GenerateInvoice from "./components/Participants/GenerateInvoice";
import SupportStaffCreateWelcomPack from "./components/SupportStaff/SupportStaffCreateWelcomPack";
import SupportStaffGenerateInvoice from "./components/SupportStaff/SupportStaffGenerateInvoice";
import NDISAgreement from "./components/Participants/NDIS Agreement";
import ArchivedCamps from "./components/ArchivedCamps";
import CampSummaryReports from "./components/CampSummary/CampSummaryReports";
import Registration from "./components/Registration";
import CreateCampSummary from "./components/CampSummary/CreateCampSummary";
import GoalCamp from "./components/CampSummary/GoalCamp";
import EditCampSummary from "./components/CampSummary/EditCampSummary";
import ViewCampSummary from "./components/CampSummary/ViewCampSummary";
import SupportStaffWelcomePackPublish from "./components/SupportStaff/SupportStaffWelcomePackPublish";
import SupportStaffWelcomePackRepublish from "./components/SupportStaff/SupportStaffWelcomePackRepublish";
import SupportStaffWelcomePackEdit from "./components/SupportStaff/SupportStaffWelcomePackEdit";
import ProfileView from "./components/TherapyCampStaff/ProfileView";
import Registerdata from "./components/Registerdata";

import DownloadNDIS from "./components/Participants/DownloadNDIS";
import DownloadInvoice from "./components/Participants/DownloadInvoice";
import UserProfile from "./components/UserProfile";
import UserProfile1 from "./components/UserProfile1";
import ShirtReport from "./components/ReportsModule/ShirtReport";
import ShirtReportCampwise from "./components/ReportsModule/ShirtReportCampwise";
import AlertReport from "./components/ReportsModule/AlertReport";
import AlertReportCampwise from "./components/ReportsModule/AlertReportCampwise";

import ProfileViewMedication from "./components/Medication/ProfileViewMedication";
import ReportsForCampManager from "./components/ReportsModule/ReportsForCampManager";
import CampListContactList from "./components/ReportsModule/CampListContactList";
import InvoiceReport from "./components/ReportsModule/InvoiceReport";
import ContactDetails from "./components/ReportsModule/ContactDetails";
import ViewCampSum from "./components/CampSummary/ViewCampSum";
import SupportStaffInvoiceDownload from "./components/SupportStaff/SupportStaffInvoiceDownload";
import Profile from "./components/Profile";
import ProfileScreen1 from "./components/ProfileScreen1";
import ProfileScreen2 from "./components/ProfileScreen2";
import CampListInvoiceReport from "./components/ReportsModule/CampListInvoiceReport";

import CampMangerViewIncidentReport from "./components/ReportsModule/CampMangerViewIncidentReport";
import UserNotification from "./components/UserNotification";
import GuardedRoute from "./components/GuardedRoute";
import SideBarRoute from "./components/SideBarRoute";
import Signup from "./components/Signup";
import Emergency from "./components/Emergency";

const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});

function App() {
  const [authenticated, setAuthenticated] = React.useState(false);

 
  
  // window.onfocus = function() {
  //   // This event is triggered when the tab gains focus (e.g., when you switch tabs)
  //   // Reload the application to ensure the latest data is displayed
  //   window.location.reload();
  // };
 

 
  return (
    <BrowserRouter>

      <Switch>
     
        <Route exact path="/" component={Login} />
        {/* <Route path="/">
          <Login setAuthenticated={setAuthenticated} />
        </Route> */}
        <Route path="/register" component={Register} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/verifyotp" component={VerifyOTP} />
        <Route path="/createNewpassword" component={CreateNewPassword} />
        <Route path="/passwordchanged" component={PasswordChanged} />
        <Route path="/forgotPasswordPopup" component={ForgotPasswordPopup} />
        <Route path="/email" component={Email} />
        <Route path="/userAddedPopup" component={UserAddedPopup} />
        <Route path="/Registration" component={Registration}></Route>
        <Route path="/Registerdata" component={Registerdata}></Route>
        <Route path="/UserProfile/:id" component={UserProfile}></Route>
        <Route path="/UserProfile1/:id" component={UserProfile1}></Route>
        <Route path="/Signup" component={Signup}></Route>
        <Route path="/Emergency" component={Emergency}></Route>
        <ThemeProvider theme={theme}>
          <CssBaseline />
        <GuardedRoute
              component={SideBarRoute} 
        />
        {/* <SideBarRoute isAuthEnabled = {true}/> */}
        {/* <SideBar>
            <Route path="/UserNotificationLog" component={Dashboard}></Route>
            <Route path="/CampList" component={CampList}></Route>
            <Route path="/CampList1" component={CampList1}></Route>
            <Route path="/CampName" component={CampName}></Route>
            <Route path="/CreateCamp" component={CreateCamp}></Route>
            <Route path="/ViewCamp/:campId" component={ViewCamp}></Route>
            <Route path="/RemoveCamp/:campId" component={RemoveCamp}></Route>
            <Route path="/EditCamp/:campId" component={EditCamp}></Route>
            <Route path="/FullCamp" component={FullCamp}></Route>
            <Route path="/UploadingPhoto6" component={UploadingPhoto6}></Route>
            <Route path="/ActivateStaff" component={ActivateStaff}></Route>
            <Route
              path="/ActivateCampStaff"
              component={ActivateCampStaff}
            ></Route>
            <Route path="/AddUser" component={AddUser}></Route>
            <Route path="/SetReminder/:campId" component={SetReminder}></Route>
            <Route path="/ReminderLog/:campId" component={ReminderLog}></Route>
            <Route
              path="/ChangeSuitability/:campId/:applicantId"
              component={ChangeSuitability}
            ></Route>
            <Route
              path="/serviceAgreement"
              component={ServiceAgreement}
            ></Route>
            <Route
              path="/Notifications/:campId/:applicantId"
              component={Notifications}
            ></Route>
            <Route
              path="/NotificationLog/:campId/:applicantId"
              component={NotificationLog}
            ></Route>
            <Route
              path="/SupportStaffInvoiceSent"
              component={SupportStaffInvoiceSent}
            ></Route>
            <Route
              path="/ParticipantsList"
              component={ParticipantsList}
            ></Route>
            <Route
              path="/ApplicantsReview/:campId"
              component={ApplicantsReview}
            ></Route>
            <Route
              path="/ReviewApplication/:campId/:applicantId"
              component={ReviewApplication}
            ></Route>
            <Route
              path="/PendingSuitabilityPreScreen/:campId/:applicantId"
              component={PendingSuitabilityPreScreen}
            ></Route>
            <Route
              path="/PendingSuitabilityOther"
              component={PendingSuitabilityOther}
            ></Route>
            <Route
              path="/RecentFiles/:campId/:applicantId"
              component={RecentFiles}
            ></Route>
            <Route
              path="/SupportStaffCampList"
              component={SupportStaffCampList}
            ></Route>
            <Route
              path="/SupportStaffApplicants/:campId"
              component={SupportStaffApplicants}
            ></Route>
            <Route
              path="/ChangeFunding/:campId/:staffApplicationId/:staffId"
              component={ChangeFunding}
            ></Route>
            <Route
              path="/SupportStaffApplicationReview/:campId/:staffApplicationId/:staffId"
              component={SupportStaffApplicationReview}
            ></Route>
            <Route
              path="/SupportStaffReminderLog"
              component={SupportStaffReminderLog}
            ></Route>
            <Route
              path="/SupportStaffFiles/:campId/:staffApplicationId/:staffId"
              component={SupportStaffFiles}
            ></Route>
            <Route
              path="/SupportstaffNotificationLog/:campId/:staffApplicationId/:staffId"
              component={SupportstaffNotificationLog}
            ></Route>
            <Route
              path="/SupportstaffNotifications/:campId/:staffApplicationId/:staffId"
              component={SupportstaffNotifications}
            ></Route>
            <Route path="/TherapyCampList" component={TherapyCampList}></Route>
            <Route
              path="/CampListContactList"
              component={CampListContactList}
            ></Route>
            <Route path="/CampListInvoiceReport" component={CampListInvoiceReport}></Route>
            <Route
              path="/GoalSettinginCampManger"
              component={GoalSettinginCampManger}
            ></Route>
            <Route
              path="/CampListForCampStaff"
              component={CampListForCampStaff}
            ></Route>

            <Route
              path="/GoalSettingInCampStaff/:camp_id"
              component={GoalSettingInCampStaff}
            ></Route>
            <Route
              path="/ProfileView/:camp_id/:child_id/:applicationId"
              component={ProfileView}
            ></Route>
            <Route
              path="/ProfileViewMedication/:camp_id/:child_id/:applicationId"
              component={ProfileViewMedication}
            ></Route>
            <Route
              path="/ViewParticipants/:campId"
              component={ViewParticipants}
            ></Route>
            <Route
              path="/AddParticipants/:campId"
              component={AddParticipants}
            ></Route>
            <Route path="/Sample" component={Sample}></Route>
            <Route
              path="/Goals/:camp_id/:applicationId"
              component={Goals}
            ></Route>
            <Route path="/AddDescription" component={AddDescription}></Route>
            <Route
              path="/ContactDetails/:camp_id"
              component={ContactDetails}
            ></Route>
            <Route path="/Gallery/:campId" component={Gallery}></Route>
            <Route path="/CheckInOut/:campId" component={CheckInOut}></Route>
            <Route
              path="/CheckInOutLog/:campId/:applicantId"
              component={CheckInOutLog}
            ></Route>
            <Route
              path="/MedicationAssignedCamps"
              component={MedicationAssignedCamps}
            ></Route>
            <Route path="/InvoiceReport/:camp_id" component={InvoiceReport}></Route>
            <Route
              path="/ApplicantsReviewforMedication/:camp_id"
              component={ApplicantsReviewforMedication}
            ></Route>
            <Route
              path="/ParticipantsMedicalRecord/:camp_id/:applicationId"
              component={ParticipantsMedicalRecord}
            ></Route>
            <Route
              path="/MedicationMangerCheckInandOut/:camp_id/:applicationId"
              component={MedicationMangerCheckInandOut}
            ></Route>
            <Route
              path="/EditParticipantMedicalRecord/:camp_id/:applicationId"
              component={EditParticipantMedicalRecord}
            ></Route>
            <Route
              path="/MedicalForm/:camp_id/:applicationId"
              component={MedicalForm}
            ></Route>
            <Route
              path="/ProfileCampStaff/:profileId"
              component={ProfileCampStaff}
            ></Route>
            <Route
              path="/CreateWelcomePack/:campId"
              component={CreateWelcomePack}
            ></Route>
            <Route
              path="/WelcomePackPublish"
              component={WelcomePackPublish}
            ></Route>
            <Route
              path="/SupportStaffWelcomePackPublish"
              component={SupportStaffWelcomePackPublish}
            ></Route>
            <Route
              path="/WelcomePackRepublish/:campId"
              component={WelcomePackRepublish}
            ></Route>
            <Route
              path="/WelcomePackEdit/:campId"
              component={WelcomePackEdit}
            ></Route>
            <Route
              path="/CampNotificationLog/:campId"
              component={CampNotificationLog}
            ></Route>
            <Route
              path="/CampNotification/:campId"
              component={CampNotification}
            ></Route>
            <Route
              path="/SupportStaffGenerateInvoice/:campId/:staffApplicationId/:staffId"
              component={SupportStaffGenerateInvoice}
            ></Route>
            <Route path="/ArchivedCamps" component={ArchivedCamps}></Route>
            <Route path="/ChildProfile/:campId/:childId" component={ChildProfile}></Route>
            <Route path="/IncidentReport" component={IncidentReport}></Route>
            <Route path="/Reports" component={Reports}></Route>
            <Route
              path="/ReportsForCampManager"
              component={ReportsForCampManager}
            ></Route>
            <Route
              path="/createIncidentReport"
              component={CreateIncidentReport}
            ></Route>
            <Route
              path="/viewIncidentReport/:incidentReportId/:campId"
              component={ViewIncidentReport}
            ></Route>
            <Route
              path="/editIncidentReport/:incidentReportId/:campId"
              component={EditIncident}
            ></Route>
            <Route
              path="/CampStaffNotificationLog"
              component={CampStaffNotificationLog}
            ></Route>
            <Route
              path="/CampStaffNotifications"
              component={CampStaffNotifications}
            ></Route>
            <Route
              path="/GenerateInvoice/:campId/:applicantId"
              component={GenerateInvoice}
            ></Route>
            <Route
              path="/SupportStaffCreateWelcomPack/:campId"
              component={SupportStaffCreateWelcomPack}
            ></Route>
            <Route
              path="/SupportStaffWelcomePackRepublish/:campId"
              component={SupportStaffWelcomePackRepublish}
            ></Route>
            <Route
              path="/SupportStaffWelcomePackEdit/:campId"
              component={SupportStaffWelcomePackEdit}
            ></Route>
            <Route
              path="/NDISAgreement/:campId/:applicantId"
              component={NDISAgreement}
            ></Route>
            <Route
              path="/DownloadNDIS/:campId/:applicantId"
              component={DownloadNDIS}
            ></Route>
            <Route
              path="/DownloadInvoice/:campId/:applicantId"
              component={DownloadInvoice}
            ></Route>
            <Route path="/ShirtReport" component={ShirtReport}></Route>
            <Route
              path="/ShirtReportCampwise/:campId"
              component={ShirtReportCampwise}
            ></Route>
            <Route path="/AlertReport" component={AlertReport}></Route>
            <Route
              path="/AlertReportCampwise/:campId"
              component={AlertReportCampwise}
            ></Route>
            <Route
              path="/CampSummaryReports"
              component={CampSummaryReports}
            ></Route>
            <Route
              path="/CreateCampSummary/:camp_id/:applicationId/"
              component={CreateCampSummary}
            ></Route>
            <Route
              path="/ViewCampSum/:camp_id/:applicationId/"
              component={ViewCampSum}
            ></Route>
            <Route
              path="/EditCampSummary/:camp_id/:applicationId/"
              component={EditCampSummary}
            ></Route>
            <Route
              path="/ViewCampSummary/:camp_id/:applicationId/"
              component={ViewCampSummary}
            ></Route>
            <Route path="/GoalCamp/:camp_id" component={GoalCamp}></Route>

            <Route
              path="/SupportStaffInvoiceDownload/:campId/:staffApplicationId/:staffId"
              component={SupportStaffInvoiceDownload}
            ></Route>
            <Route path="/Profile" component={Profile}></Route>
            <Route path="/ProfileScreen1" component={ProfileScreen1}></Route>
            <Route path="/ProfileScreen2" component={ProfileScreen2}></Route>
            <Route path="/CampMangerViewIncidentReport/:incidentReportId/:campId" component={CampMangerViewIncidentReport}></Route>
            <Route path="/UserNotification" component={UserNotification}></Route>
        </SideBar> */}
        </ThemeProvider>
      </Switch>


    </BrowserRouter>
    // </UserContext.Provider>
  );
}

export default App;
