import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import alertreport from "../../Assets/Icons/Icons/alertreport.png";
import shirtsize from "../../Assets/Icons/Icons/shirtsize.png";
import campsummaryreport from "../../Assets/Icons/Icons/campsummaryreport.png";
import incidentreport from "../../Assets/Icons/Icons/incidentreport.png";
import contact from "../../Assets/Icons/Icons/contact.png";

const cards = [
  {
    title: "Incident Reports",
    image: incidentreport,
    path: "/IncidentReport",
  },
  {
    title: "Camp Summary Report",
    image: campsummaryreport,
    path: "/CampSummaryReports",
  },
  {
    title: "Shirt Report",
    image: shirtsize,
    path: "/ShirtReport",
  },
  {
    title: "Alert Report",
    image: alertreport,
    path: "/AlertReport",
  },
  {
    title: "Camp Contact List",
    image: contact,
    path: "/CampListContactList",
  },
];

const Reports = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(()=>{
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
  },[])

  return (
    <Grid container spacing={isSmallScreen ? 2 : 4}>
      {cards.map((card, index) => (
        <Grid item xs={12} sm={6} md={3} key={index} align="center">
          <Link to={card.path} style={{ textDecoration: "none" }}>
            <Card align="center" className="card-reports">
              <Box
                variant="h5"
                align="center"
                component="h2"
                sx={{ fontSize: 14, color: "#379592" }}
              >
                {card.title}
              </Box>
              {/* <Box>
                <img    src={card.image}/>
              </Box> */}

              <Box
                component="img"
                align="center"
                sx={{ height: "2rem", width: "2rem", marginBottom: "2rem" }}
                alt="Logo"
                src={card.image}
              ></Box>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default Reports;
