import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Logo from "../Assets/Icons/Icons/Logo-Main.png";
import "../index.css";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CampName from "./CampName";
import Grid from "@mui/material/Grid";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import DashboardIcon from "../Assets/Icons/Icons/camping.png";
import MedicationIcon from "../Assets/Icons/Icons/Medication.png";
import SettingsIcon from "../Assets/Icons/Icons/settings.png";
import TherapyIcon from "../Assets/Icons/Icons/Group 385.png";
import ParticipantsIcon from "../Assets/Icons/Icons/participants.png";
import HomeIcon from "../Assets/Icons/Icons/Home-grey.png";
import Popover from "@mui/material/Popover";
import { useHistory ,useLocation} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import Reports from "../Assets/Icons/Icons/Reports.png";
import Support from "../Assets/Icons/Icons/Support.png";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
// import User from "../Assets/Icons/Icons/User.png"
import GreenDot from "../Assets/Icons/Icons/Greendot.png";
import Paper from "@mui/material/Paper";
import BackIcon from "../Assets/Icons/Icons/Backicon.png";
import Notifications from "../Assets/Icons/Icons/Notifications.png";
import User from "../Assets/Icons/Icons/User.png";
import UserWhite from "../Assets/Icons/Icons/UserWhite.png";

import Homewhite from "../Assets/Icons/Icons/homewhite.png";
import Medicationwhite from "../Assets/Icons/Icons/medicationwhite.png";
import Participantwhite from "../Assets/Icons/Icons/participantswhite.png";
import Reportswhite from "../Assets/Icons/Icons/reportswhite.png";
import Settingswhite from "../Assets/Icons/Icons/settingswhite.png";
import Therapywhite from "../Assets/Icons/Icons/therapywhite.png";
import Campwhite from "../Assets/Icons/Icons/campwhite.png";
import NewSupportWhite from "../Assets/Icons/Icons/NewSupportWhite.png";
import NewSupport from "../Assets/Icons/Icons/NewSupport.png";
import BellDark from "../Assets/Icons/Icons/BellDark.png"
import BellWhite from "../Assets/Icons/Icons/BellWhite.png"

const drawerWidth = 230;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  // backgroundColor: '#00000003',
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    // backgroundColor:'pink',
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#909090",
  // color:'purple',

  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50ch",
      "&:focus": {
        width: "70ch",
      },
    },
  },
}));



function SideBar(props) {
  let campManagerSideNav = [
    {
      name: "General Notifications",

      path: "/UserNotificationLog",

      icon: BellDark,

      activeIcon: BellWhite,

      isToggle: true,

      id: 1,

      children: [],
    },

    {
      name: "Camps",

      path: "/CampList",

      icon: DashboardIcon,

      activeIcon: Campwhite,

      isToggle: false,

      id: 2,

      children: [],
    },

    {
      name: "Participants",

      path: "/ParticipantsList",

      icon: ParticipantsIcon,

      activeIcon: Participantwhite,

      isToggle: false,

      id: 3,

      children: [],
    },

    {
      name: "Support Staff",

      path: "/SupportStaffCampList",

      icon: NewSupport,

      activeIcon: NewSupportWhite,

      isToggle: false,

      id: 4,

      children: [],
    },

    {
      name: "Medication",

      path: "/MedicationAssignedCamps",

      icon: MedicationIcon,

      activeIcon: Medicationwhite,

      isToggle: false,

      id: 5,

      children: [],
    },

    {
      name: "Therapy",

      path: "/CampListForCampStaff",

      icon: TherapyIcon,

      activeIcon: Therapywhite,

      isToggle: false,

      id: 6,

      children: [],
    },

    {
      name: "Reports",

      path: "/ReportsForCampManager",

      icon: Reports,

      activeIcon: Reportswhite,

      isToggle: false,

      id: 7,

      children: [],
    },
    {
      name: "Users",
      path: "/ActivateStaff",
      icon: User,
      activeIcon: UserWhite,
      isToggle: false,
      id: 8,
      children: [
        // {
        //   id: 10,
        //   name: "C&C Staff",
        //   path: "/ActivateStaff/CCStaff",
        //   isToggle: false,
        // },
        // {
        //   id: 11,
        //   name: "Admin Users",
        //   path: "/ActivateStaff/AdminUsers",
        //   isToggle: false,
        // },
      ],
    },

    {
      name: "Settings",

      path: "/Profile",

      icon: SettingsIcon,

      activeIcon: Settingswhite,

      isToggle: false,

      id: 9,

      children: [],
    },
  ];
  const [dashboardArray, setDashboardArray] = React.useState([])
  const { role } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const userRole = localStorage.getItem("userRole");
  const rolesName = localStorage.getItem("rolesName");
  const userName = localStorage.getItem("userName");
  const location = useLocation();
// console.log(location,'coming here')
  React.useEffect(() => {
    // if (
    //   rolesName !== "CAMP ADMIN" &&
    //   rolesName !== "CAMP MANAGER" &&
    //   rolesName !== "ADMIN"
    // ) {
    //   // if(rolesName === 'CAMP STAFF'){
    //   const users = dashboardArray.filter((arr) => arr.name != "Users");
    //   setDashboardArray(users);
    // }
  
      
    
    const currentUserRole = localStorage.getItem("rolesName");
    if ((currentUserRole === "CAMP MANAGER") || (currentUserRole === "ADMIN") || (currentUserRole === "CAMP ADMIN")) {

      let campManagerSideNav = [
        {
          name: "General Notifications",

          path: "/UserNotificationLog",

          icon: BellDark,

          activeIcon: BellWhite,

          isToggle: true,

          id: 1,

          children: [],
        },

        {
          name: "Camps",

          path: "/CampList",

          icon: DashboardIcon,

          activeIcon: Campwhite,

          isToggle: false,

          id: 2,

          children: [],
        },

        {
          name: "Participants",

          path: "/ParticipantsList",

          icon: ParticipantsIcon,

          activeIcon: Participantwhite,

          isToggle: false,

          id: 3,

          children: [],
        },

        {
          name: "Support Staff",

          path: "/SupportStaffCampList",

          icon: NewSupport,

          activeIcon: NewSupportWhite,

          isToggle: false,

          id: 4,

          children: [],
        },

        {
          name: "Medication",

          path: "/MedicationAssignedCamps",

          icon: MedicationIcon,

          activeIcon: Medicationwhite,

          isToggle: false,

          id: 5,

          children: [],
        },

        {
          name: "Therapy",

          path: "/CampListForCampStaff",

          icon: TherapyIcon,

          activeIcon: Therapywhite,

          isToggle: false,

          id: 6,

          children: [],
        },

        {
          name: "Reports",

          path: "/ReportsForCampManager",

          icon: Reports,

          activeIcon: Reportswhite,

          isToggle: false,

          id: 7,

          children: [],
        },

        {
          name: "Users",
          path: "/ActivateStaff",
          icon: User,
          activeIcon: UserWhite,
          isToggle: false,
          id: 8,
          children: [
            // {
            //   id: 10,
            //   name: "C&C Staff",
            //   path: "/CCStaff",
            //   isToggle: false,
            // },
            // {
            //   id: 11,
            //   name: "Admin Users",
            //   path: "/AdminUsers",
            //   isToggle: false,
            // },
          ],
        },

        {
          name: "Settings",

          path: "/Profile",

          icon: SettingsIcon,

          activeIcon: Settingswhite,

          isToggle: false,

          id: 9,

          children: [],
        },
      ];
      setDashboardArray(campManagerSideNav);
    }

    if (currentUserRole === "CAMP STAFF" || currentUserRole === "THERAPIST") {
      let campStaffMenu = [
        {
          name: "Medication",
          path: "/MedicationAssignedCamps",
          icon: MedicationIcon,
          activeIcon: Medicationwhite,
          isToggle: true,
          id: 5,
          children: [],
        },
        {
          name: "Participants",
          path: "/ParticipantsList",
          icon: ParticipantsIcon,
          activeIcon: Participantwhite,
          isToggle: false,
          id: 8,
          children: [],
        },

        {
          name: "Therapy",
          path: "/CampListForCampStaff",
          icon: TherapyIcon,
          activeIcon: Therapywhite,
          isToggle: false,
          id: 6,
          children: [],
        },

        {
          name: "Reports",
          path: "/reports",
          icon: Reports,
          activeIcon: Reportswhite,
          isToggle: false,
          id: 7,
          children: [],
        },

        {
          name: "Settings",
          path: "/Profile",
          icon: SettingsIcon,
          activeIcon: Settingswhite,
          isToggle: false,
          id: 9,
          children: [],
        },
      ];

      setDashboardArray(campStaffMenu);
    }

   
  }, []);

  React.useEffect(() => {

    let x = JSON.parse(localStorage.getItem('dashboard'))

    if ((localStorage.getItem("dashboard")) && (localStorage.getItem("token")) ) {
      setDashboardArray(x)
      // if ((currentUserRole === "CAMP MANAGER") || (currentUserRole === "ADMIN") || (currentUserRole === "CAMP ADMIN")){
        
      // }
      // if (currentUserRole === "CAMP STAFF" || currentUserRole === "THERAPIST") {
      // }

      
    }
    
    // if(window){

    // }

    // console.log("back code", window)
    // if(window){
    //   window.history.pushState(null, document.title, window.location.href);
    //   window.addEventListener('popstate', function (event){
    //       window.history.pushState(null, document.title,  window.location.href);
    //   });
    // }
    


  }, [])


  const log = () => {
    localStorage.clear();
  };
  
  
  const MultipleSearchComponent = () => {
    return (
      <Grid container display="flex">
        <Paper
          xs={12}
          sm={12}
          md={6}
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            mx: "0.5rem",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by First Name"
          />
        </Paper>
        <Paper
          xs={12}
          sm={12}
          md={6}
          component="form"
          sx={{ display: "flex", alignItems: "center", mx: "0.5rem" }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by Last Name"
          />
        </Paper>
        <Paper
          xs={12}
          sm={12}
          md={6}
          component="form"
          sx={{ display: "flex", alignItems: "center", mx: "0.5rem" }}
        >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search by Title" />
        </Paper>
      </Grid>
    );
  };

  const SearchComponent = () => {
    return (
      <Search
        width="20%"
        sx={{
          backgroundColor: "#F8F8F8",
          border: 1,
          borderColor: "#909090",
          borderRadius: "0.75rem",
          "&:hover": {
            color: "#379592",
            border: 2,
            borderColor: "rgba(55, 149, 146, 0.8)",
          },
        }}
      >
        <SearchIconWrapper>
          <SearchIcon sx={{ color: "#909090" }} />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
        />
      </Search>
    );
  };

  function Component() {
    let component;
    switch (role) {
      case "1":
        component = <SearchComponent />;
        break;
      case "2":
        component = <Box sx={{ color: "black" }}></Box>;
        // component = <MultipleSearchComponent/>
        break;

      default:
        component = <Box sx={{ color: "black" }}></Box>;
    }
    return component;
  }

  // console.log(props)
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlelogout = () => {
    localStorage.clear();
    history.push("/");
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // notification popover
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
const toggleValue = (index) => {
  setDashboardArray((prevData) => {
    const newData = [...prevData];
    newData.forEach((item, i) => {
      if (i === index) {
        item.isToggle = !item.isToggle;
      } else {
        item.isToggle = false;
      }
    });
    return newData;
  });
};

  React.useEffect(() => {
    if(localStorage.getItem('token')){
      let strr = JSON.stringify(dashboardArray)
      localStorage.setItem('dashboard', strr)
    }
  }
    , [dashboardArray])

const toggleValue1 = (index, childIndex) => {
  setDashboardArray((prevData) => {
    const newData = [...prevData];
    newData.forEach((item, i) => {
      if (i === index) {
        item.children.forEach((child, j) => {
          if (j === childIndex) {
            child.isToggle = !child.isToggle;
          } else {
            child.isToggle = false;
          }
        });
      } else {
        item.isToggle = false;
        item.children.forEach((child) => (child.isToggle = false));
      }
    });
    return newData;
  });
};

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover" : undefined;

  // ending

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={() => handlelogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge>

            <Box
              component="img"
              src={Notifications}
              sx={{ height: "2rem" }}
              // onClick={handleClick2}
              // onClick = {()=>{history.push('/UserNotificationLog')}}
            ></Box>
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        {/* < Link to='/CampList'> */}
        <p>{localStorage.getItem("userName")}</p>

        {/* </ Link> */}
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          // aria-controls="primary-search-account-menu"
          // aria-haspopup="true"
          // color="inherit"

          // aria-controls={menuId}
          // aria-haspopup="true"
          // onClick={handleProfileMenuOpen}
          color="#909090"
          sx={{ cursor: 'default' }}
        >
          <Badge>
            {localStorage.getItem("profileimage") !== 'null' ? (
              <Box

                component="img"
                sx={{ height: "15px", width: '15px', borderRadius: '50%', }}
                src={localStorage.getItem("profileimage")}

              ></Box>
            ) :
              (
                <Box textAlign='center'

                  sx={{
                    fontSize: '12px',
                    // mr:'1rem',
                    backgroundColor: '#379592',
                    p: '0.65rem',
                    borderRadius: '50%',
                    color: '#fff',
                  }}
                >{localStorage.getItem("profileName")}</Box>
              )}
            {/* <Box
                component="img"
                src={User}
                sx={{ height: "1.25rem", pr: "1rem" }}
              ></Box> */}
          </Badge>
          {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="test">
      {/* <Toolbar sx={{border:1}}/> */}
      <Box sx={{ width: "100%", textAlign: "center" }}>
        <Box
          component="img"
          sx={{ height: "20%", width: "40%", my: "1rem" }}
          alt="Logo"
          src={Logo}
        ></Box>
      </Box>
      <Divider />

      <List>
        {dashboardArray.map((item, index) => (
          <div>
            <Link className="link2" to={item.path}>
              <ListItemButton
                onClick={() => toggleValue(index)}
                mt="0rem"
                style={{ backgroundColor: item.isToggle ? "#379592" : "white" }}
              >
                <ListItemIcon>
                  <Box
                    component="img"
                    sx={{
                      height: "0.85rem",
                      width: "0.85rem",
                      ml: "1.5rem",
                    }}
                    alt="Logo"
                    src={item.isToggle ? item.activeIcon : item.icon}
                  ></Box>
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  style={{ color: item.isToggle ? "white" : "#909090" }}
                  sx={{
                    " .MuiTypography-root": {
                      fontSize: "0.9rem",
                    },
                  }}
                />
              </ListItemButton>
            </Link>

            {item.children.length > 0 && item.isToggle && (
              <Collapse in={item.isToggle} timeout="auto" unmountOnExit>
                {item.children.map((submenu, chiledIndex) => (
                  <List component="div" disablePadding>
                    <Link to={submenu.path} className="link2">
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => toggleValue1(index, chiledIndex)}
                      >
                        <ListItemIcon>
                          <Box
                            component="img"
                            sx={{
                              height: "0.5rem",
                              width: "0.5rem",
                              ml: "1.5rem",
                            }}
                            alt="Logo"
                            src={GreenDot}
                          ></Box>
                        </ListItemIcon>
                        <ListItemText
                          primary={submenu.name}
                          style={{
                            color: !submenu.isToggle ? "#909090" : "#379592",
                          }}
                          sx={{
                            " .MuiTypography-root": {
                              fontSize: "0.9rem",
                            },
                          }}
                        />
                      </ListItemButton>
                    </Link>
                  </List>
                ))}
              </Collapse>
            )}
          </div>
        ))}
      </List>

      {/* <ListItemButton sx={{
        // color:'white',
        "& MuiButtonBase-root.MuiListItemButton-root:hover": {
          backgroundColor: "#379592",
          color: 'white',
          textDecorationColor: 'white'
        },
        // " .MuiTypography-root":{
        //   fontSize:'0.9rem'
        // } ,
      }}>
        <ListItemIcon >
          <Box
            component="img"
            sx={{
              height: "0.85rem",
              width: "0.85rem",
              ml: "1.5rem",
            }}
            alt="Logo"
            src={SettingsIcon}
          ></Box>

        </ListItemIcon>
        <ListItemText primary="Settings" sx={{
          color: "#909090",
          // "&:hover": {
          //   color: "white",
          // },
          " .MuiTypography-root": {
            fontSize: '0.9rem'
          },
        }}
        />

      </ListItemButton> */}
      <Divider />
      <Box sx={{ width: "100%", textAlign: "center", color: '#379592', mt: "0.5rem" }}>

        {localStorage.getItem("rolesName")}
      </Box>

    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  // const UserContext = React.createContext();
  
  // React.useEffect(() => {
  //   const handlePopState = () => {
  //     // Restore the original history state when leaving the landing page
  //     // This will re-enable the browser's back button
  //     window.history.replaceState(null, null, '/CampList');
  //   };

  //   window.addEventListener('popstate', handlePopState);

  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, []);
  return (
    // <ThemeProvider theme={theme}>
    // <UserContext.Provider value="Reed">
    <Box sx={{ display: "flex" }} className="test">
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
        }}
      >
        <Toolbar>
          <IconButton
            color="#379592"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Box sx={{color:'black'}}>{props.searchName}</Box> */}

          {Component()}

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>


            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="#909090"
            >
              <Badge>
                {/* <NotificationsIcon
                  aria-describedby={id2}
                  onClick={handleClick2}
                /> */}
                <Box
                  component="img"
                  src={Notifications}
                  sx={{ height: "2rem" }}
                  // onClick={handleClick2}
                  // onClick = {()=>{history.push('/UserNotificationLog')}}
                ></Box>
                {/* <Button aria-describedby={id2} variant="contained" onClick={handleClick2}>
        Open Popover
      </Button>  */}
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              // aria-controls={menuId}
              // aria-haspopup="true"
              // onClick={handleProfileMenuOpen}
              color="#909090"
              sx={{ cursor: 'default', mx: '0.15rem' }}
            >
              <Badge>
                {localStorage.getItem("profileimage") !== 'null' ? (
                  <Box

                    component="img"
                    sx={{
                      height: "30px",
                      width: "30px", borderRadius: '50%',
                    }}
                    src={localStorage.getItem("profileimage")}

                  ></Box>
                ) :
                  (
                    <Box textAlign='center'

                      sx={{
                        fontSize: '12px',
                        // mr:'1rem',
                        backgroundColor: '#379592',
                        p: '0.65rem',
                        borderRadius: '50%',
                        color: '#fff',
                      }}
                    >{localStorage.getItem("profileName")}</Box>
                  )}
                {/* <Box
                component="img"
                src={User}
                sx={{ height: "1.25rem", pr: "1rem" }}
              ></Box> */}
              </Badge>

            </IconButton>
          </Box>


          <Box
            className="txt-grey"
            sx={{
              px: "0.5rem",
              whiteSpace: "nowrap",
              textAlign: "center",
              alignSelf: "center",
            }}
          >
            {/* {localStorage.getItem("userName").charAt(0).toUpperCase() +
              localStorage.getItem("userName").slice(1).toLowerCase()} */}
            {localStorage.getItem("userName")}
          </Box>
          <Link className="link2" to="/">
            <Box
              component="img"
              src={BackIcon}
              sx={{ height: "1rem", mt: "0.35rem" }}
              onClick={() => log()}
            ></Box>
          </Link>
        </Toolbar>
      </AppBar>
      {/* {renderMobileMenu} */}
      {/* {renderMenu} */}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >

        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>

      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {/* <BrowserRouter>
            <Switch>
              <Route path="/CampList" component={CampList} />
              <Route path="/CampList1" component={CampList1} />
              <Route path="/CampName" component={CampName} />
              <Route path="/CreateCamp" component={CreateCamp} />
              <Route path="/ViewCamp" component={ViewCamp} />
              <Route path="/FullCamp" component={FullCamp} />
              <Route path="/UploadingPhoto6" component={UploadingPhoto6} />
              <Route path="/UploadingPhoto" component={UploadingPhoto} />
            </Switch>
          </BrowserRouter> */}
        {/* <CampName/> */}
        {/* <CampList/> */}
        {/* <CampList1 /> */}
        {/* <CreateCamp /> */}
        {/* <FullCamp/> */}
        {/* <ViewCamp/> */}
        {/* <UploadingPhoto/> */}
        {/* <UploadingPhoto6/> */}
        {/* <UploadingPhoto4/> */}
        {props.children}
      </Box>
      {/* <Button aria-describedby={id2} variant="contained" onClick={handleClick2}>
        Open Popover
      </Button> */}
      <Popover
        id2={id2}
        open={open2}
        anchorEl2={anchorEl2}
        onClose={handleClose2}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'left',
        // }}

        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'left',
      // }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <Box px="1rem" py="1rem">
          <Grid container justifyContent="space-between">
            <Grid item className="gilroy">
              <Box sx={{ color: "#379592" }}>Notifications</Box>
            </Grid>
            <Grid item>
              <Box
                component="img"
                src={SettingsIcon}
                sx={{ color: "blue" }}
              ></Box>
            </Grid>
          </Grid>

          <Box>
            <List>
              <ListItem>
                <p className="fs-10">
                  Unseen or new notifications will be here
                </p>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Popover>

      {/* <Popover 
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
>
  The content of the Popover.
</Popover> */}
    </Box>
    // </UserContext.Provider>

    // </ThemeProvider>
  );
}

// Dashboard.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

export default SideBar;
