import React from "react";
import { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import { Link, useParams } from "react-router-dom";
import "../../index.css";
import TextField from "@mui/material/TextField";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";
import GreenCheck2 from "../../Assets/Icons/Icons/GreenCheck2.png";
import { useHistory } from "react-router-dom";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CrossGreen from "../../Assets/Icons/Icons/CrossGreen.png";
import { ContactSupportOutlined } from "@material-ui/icons";
import axios from "axios";
import { apiService, apiService2 } from "../../Services/apiService";
import { CREATE_NOTIFICATION, SERVER_URL, FILE_UPLOAD } from '../../Services/apiEndpints'

export default function Notifications() {
  const history = useHistory();


  const hiddenFileInputThum = React.useRef(null);
  const [notificationFile, setNotificationFile] = useState(null);
  const [notificationPath, setNotificationPath] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [notificationHashPath, setNotificationHashPath] = useState(null);
  const [remarks, setRemarks] = React.useState(null);
  const [isPdf, setIsPdf] = React.useState(null)
  const [errorDisplay,setErrorDisplay] = React.useState('')


  let { campId, applicantId } = useParams();
  const shoot = () => {
    Swal.fire({
      iconHtml: `<img src="${question}" width="100px" height="150px"  >`,
      text: "Are you sure you want to Submit?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#379592",
      cancelButtonText: "No",
      cancelButtonColor: "#909090",
      customClass: {
        container: "my-swal",
      },

    }).then((result) => {
      if (result.value) {
        Swal.fire({
          html: `<img src="${GreenCheck2}" width="80px" height="100px"  >`,
          text: "Submitted Succesfully!",
          confirmButtonText: "Yes",
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
          },
        }).then((result2) => {
          if (result2.value) {
            history.push("/NotificationLog");
          }
        });
      }
    });
  };

  const handleNotificationFileChange = (event) => {
    
    console.log(event.target.files[0])
    
    setNotificationFile(event.target.files[0]);

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
       setNotificationPath([
        {
          path: e.target.result,
          fileObj: event.target.files[0],
          docPath: "",
          docTitle: event.target.files[0].name
        }
      ]);
    };
    setSelectedImages((previousImages) =>
      previousImages.concat(notificationFile)
    );
    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "image/jpg" ||
      event.target.files[0].type === "image/svg" ||
      event.target.files[0].type === "image/jfif" ||
      event.target.files[0].type === "image/tiff" || 
      event.target.files[0].type === "application/PDF" ||
      event.target.files[0].type === "application/pdf" ||
      event.target.files[0].type === "application/msword" ||
      event.target.files[0].type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      // event.target.files[0].type === "application/xls" ||
      // event.target.files[0].type === ".xlsx" ||
      // event.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      // event.target.files[0].type === "text/csv" 
      event.target.files[0].name.includes('.xls') ||
      event.target.files[0].name.includes('.xlsx') ||
      event.target.files[0].name.includes('.csv')
     
    ) {
      console.log("insideee");
      if (parseFloat(event.target.files[0].size / (1024 * 1024)).toFixed(2) > 5) {
        console.log(event.target.files[0].size);
        // alert("Please select image size less than 2 MB");
        // setImageAlert(true)
        Swal.fire({
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          text: "Please select image size less than 5 MB",
          customClass: {
            container: "my-swal",
          },
        });
        setTimeout(() => {
            setNotificationFile(null);
            setNotificationPath(null);
        }, 2000);

       
      } else {
        // setImageAlert(false)
        console.log("yes");
      }
    } else {
      Swal.fire({
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
        text: "You can upload only following file types: jpeg, jpg, png, gif, svg, mp4, pdf, PDF, ionic, jfif, xls, xlsx, doc, docx",
        customClass: {
          container: "my-swal",
        },
      });
      setTimeout(() => {
        setNotificationFile(null);
        setNotificationPath(null);
      }, 2000);
    }
    
     
    
  };

  console.log(notificationPath)

  async function uploadNotificationFile(event) {
    console.log(notificationPath);
    event.preventDefault();

    // const uniq = [...new Set(attachments)];


    const forminputs = new FormData();

    forminputs.append("file", notificationFile);
    forminputs.append("userId", localStorage.getItem("userId"));
    forminputs.append("documentKey", "camp");
    forminputs.append("documentContent", "testing content");
    forminputs.append("documentType", "CHILD_FILES");
    forminputs.append("documentKeyId", applicantId);

    axios
      .post(`${SERVER_URL}${FILE_UPLOAD}`, forminputs, {
        headers: {
          "Content-Type": "multipart/form-data",
          tokenheaderkey: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log(response, 'resssssssssssssssssss');
        setNotificationHashPath(
          response["data"]["result"][0][0].documentHash
        );
        // displayThumbnailError()

        // setThumbStatus("Thumbnail uploaded");
      })
      .catch((error) => {
        // Handle errors
      });

   
  }
  const removeimg = () => {
    // console.log(attachmentsArr);
    setTimeout(() => {
      setNotificationFile(null);
      setNotificationPath(null);
      hiddenFileInputThum.current.value = ''
    }, 500);
  }
  const handleRemarks = (event) => {
    console.log(event.target.value)
    setRemarks(event.target.value)
  }

  const sendNotification = async () => {
    if(remarks !== null){
      setErrorDisplay('')
      Swal.fire({
        iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
        text: "Are you sure you want to Submit?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#379592",
        cancelButtonText: "No",
        cancelButtonColor: "#909090",
        customClass: {
          container: "my-swal",
          icon: 'no-border'
  
          //    confirmButton: 'your-confirm-button-class',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            let dataSent = {
              userId: localStorage.getItem('userId'),
              campId: campId,
              applicationId: applicantId,
              notificationType: "camp",
              notificationRemarks: remarks,
              fileHash: notificationHashPath
            }
            let apiInfo = {
              // url: "https://cncdevapi.natsoft.io/api/camps/createnotification",
              url: `${SERVER_URL}${CREATE_NOTIFICATION}`,
              method: "POST",
              postData: dataSent,
              headers: {
                "Content-Type": "application/json",
                tokenheaderkey: localStorage.getItem("token"),
              },
            }
            let output = await apiService2(apiInfo)
            console.log(output)
            if (output?.data?.responseCode === 0) {
              console.log(output.data.responseData[0][0].Desc)
              Swal.fire({
                iconHtml: `<img src="${GreenCheck2}" width="100" height="100" >`,
                text: output.data.responseData[0][0].Desc,
                confirmButtonText: "OK",
                confirmButtonColor: "#379592",
                customClass: {
                  container: "my-swal",
                  icon: 'no-border'
  
                  //    confirmButton: 'your-confirm-button-class',
                },
              }).then((result) => {
                if (result.value) {
                  history.push(`/NotificationLog/${campId}/${applicantId}`);
                }
              });
            }
          })()
        }
      })
    }
    else{
      setErrorDisplay('Please enter remarks')
      console.log('Please enter remarks')
     }

  }
  return (
    <div>
      <Box>
        <Grid container direction="row">
          <Grid container display="flex" justifyContent="space-between">
            <Grid>
              <Link to={`/NotificationLog/${campId}/${applicantId}`}>
                <Box
                  mt="0.65rem"
                  alignSelf="center"
                  component="img"
                  sx={{
                    height: "0.75rem",
                    width: "0.65rem",
                    cursor: "pointer",
                  }}
                  height="20"
                  alt="Logo"
                  src={ArrowBack}
                ></Box>
              </Link>
            </Grid>
            <Grid item>
              <Box
                className="gilroy"
                alignSelf="center"
                sx={{ textAlign: "center" }}
              >
                Notifications
              </Box>
            </Grid>

            <Grid item>
              <Box
                className="gilroy"
                alignSelf="center"
                sx={{ fontSize: "1.2rem" }}
              ></Box>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </Grid>
        <Grid>
          <Box className="gilroy" mt="1.5rem">
            Upload Attachments
          </Box>
        </Grid>
        <Grid>

          <form onSubmit={uploadNotificationFile} encType="multipart/form-data">
            <Button
              onClick={(event) => (hiddenFileInputThum.current.click(), event.currentTarget.value = null)}
              //     (event)=> { 
              //       event.currentTarget.value = null
              //  }
              variant="contained"
              // type="submit"
              mx="0rem"
              sx={{
                px: "2rem",
                bgcolor: "#379592",
                my: "0.45rem",
                // width:"15%",
                textTransform: "none",
                "&:hover": { background: "#379592" },
              }}
              className="gilroy"
            >
              Choose File
            </Button>
            <Box>
              <input
                type="file"
                multiple
                style={{ display: "none" }}
                placeholder=""
                ref={hiddenFileInputThum}
                onChange={handleNotificationFileChange}
                // accept="image/jpeg, image/png"
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              mx="0rem"
              sx={{
                px: "2rem",
                bgcolor: "#379592",
                my: "0.75rem",
                textTransform: "none",
                width: '138px',
                "&:hover": { background: "#379592" },
              }}
              className="gilroy"
            >
              Upload
            </Button>
          </form>
        </Grid>
       


        <Box sx={{ color: '#0075CE' }}>
         {console.log(notificationPath,'nameeeeee')}
                 <span className="thumbnail-span">
            {notificationPath != null ? (
              
              <a href={notificationPath[0].path} target="_blank">
             {notificationPath[0].docTitle} 
          </a>
            ) : (
              ""
            )}

            {notificationPath != null ? (
              <img
                src={CrossGreen}
                 onClick={removeimg}
                className="notification-img "


              />
            ) : (
              ""
            )}
          </span>
                  
              </Box>
              {notificationPath!== null ? (
            <Box>
            {(notificationHashPath !== null )? "Uploaded" : "Not Uploaded"}
          </Box>
          ) : ('')}
        <Grid>
          <Box className="gilroy" mt="0.75rem">
            Remarks
          </Box>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                mt: "0.75rem",
                width: "70%",
                mb: "1rem",
                // borderRadius:"1rem"
              },
            }}
            noValidate
            autoComplete="off"
            onChange={(event) => handleRemarks(event)}
          >
            <TextField id="outlined-multiline-static" multiline rows={5} />
            <Box  sx={{color:'#d32f2f',fontSize:'14px'}}>{errorDisplay}</Box>
          </Box>
        </Grid>

        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link to={`/NotificationLog/${campId}/${applicantId}`}>
              <Button
                variant="contained"
                sx={{
                  px: "2rem",
                  mx: "1rem",
                  bgcolor: "#909090",
                  textTransform: "none",
                  "&:hover": { background: "#909090" },
                }}
                className="gilroy"
              >
                Cancel
              </Button>
            </Link>

          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className="gilroy"
              sx={{
                px: "2rem",
                mx: "0.5rem",
                bgcolor: "#379592",
                "&:hover": { background: "#379592" },
                textTransform: "none",
              }}
              onClick={()=>{sendNotification()}}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
