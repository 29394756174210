import React, { useState } from "react";
import Button from "@mui/material/Button";
import User from "../Assets/Icons/Icons/User.png";
import {
    CardActionArea,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,

    Chip,
    CardContent,
    Divider,
    Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import SearchIcon from "@mui/icons-material/Search";
import List from "@material-ui/core/List";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../Assets/Icons/Icons/BlackUser.png"
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png"

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Input from '@mui/material/Input';
import TextField from "@mui/material/TextField";
import DefaultImage from "../Assets/Icons/Icons/DefaultImage.png"
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Swal from 'sweetalert2'
import { apiService, apiService2 } from "../Services/apiService";
import Searchdvance from "../Assets/Icons/Icons/searchadvanced.png";
import { alpha } from "@mui/material/styles";
import question from "../Assets/Icons/Icons/questionMark.png";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";
import { TrendingUpRounded } from "@material-ui/icons";
import {
    SERVER_URL,
    GROUP_FULLINFO,
    GROUPWISE_PARTICIPANTLIST,
    ASSIGN_PARTICIPANT,
    GET_GROUPNAME
  } from "../Services/apiEndpints";

const theme = createTheme({
    typography: {
        fontFamily: "gilroyMedium",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: "gilroyMedium",
                    src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
                },
            },
        },
    },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: theme.palette.common.black,
        backgroundColor: "rgba(55, 149, 146, 1)",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
};

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#909090",
    // color:'purple',

    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "50ch",
            "&:focus": {
                width: "70ch",
            },
        },
    },
}));

const rows = [
    { sno: 1, applicants: 'Rosey', Action: 'Remove', isShow: true },
    { sno: 2, applicants: 'Maria', Action: 'Remove', isShow: true },
    { sno: 3, applicants: 'Ben', Action: 'Remove', isShow: true },
    { sno: 4, applicants: 'Glen', Action: 'Remove', isShow: true },
    { sno: 5, applicants: 'Mary', Action: 'Remove', isShow: true },
    { sno: 6, applicants: 'Robin', Action: 'Remove', isShow: true },
    { sno: 7, applicants: 'Judy', Action: 'Remove', isShow: true }
]

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    // backgroundColor: '#00000003',
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
        // backgroundColor:'pink',
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

export default function AddParticipants() {
    let { campId } = useParams();
    const [grp, setGrp] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [clickedItem, setClickedItem] = useState("");
    const [display, setDisplay] = useState("");
    const [assignItem, setAssignItem] = React.useState([])
    const [search, setSearch] = React.useState("")
    const [list, setList] = React.useState(rows)
    const [newAssign, setNewAssign] = React.useState([])
    const [finalList, setFinalList] = React.useState([])

    const [grpName, setGrpName] = React.useState([])
    const [groupFullInfo, setGroupFullInfo] = React.useState([])
    const [participantsInfo, setParticipantsInfo] = React.useState([])
    const [staffInfo, setStaffInfo] = React.useState([])
    const [nonAssignParticipants, setNonAssignParticipants] = React.useState([])
    const [reload, setReload] = React.useState(true)

    const history = useHistory()

    React.useEffect(() => {
        (
            async () => {
                console.log("After reload", localStorage)

                if (grp !== null) {

                    let dataSent = {
                        userId: localStorage.getItem('userId'),
                        campId: campId,
                        groupName: grp,
                    }

                    let apiInfo = {
                        // url: "https://cncdevapi.natsoft.io/api/actcamp/getgroupfullinfo",
                        url:`${SERVER_URL}${GROUP_FULLINFO}`,
                        method: "POST",
                        postData: dataSent,
                        headers: {
                            "Content-Type": "application/json",
                            tokenheaderkey: localStorage.getItem("token"),
                        },
                    }
                    let output = await apiService2(apiInfo)
                    if (output.data.responseCode === 0) {
                        let campData = output.data.responseData[0]
                        console.log("jsjs", campData)
                        setGroupFullInfo(campData)

                        let info = campData && campData[0].participantsInfo
                        info = info || []
                        info.forEach(participants => {
                            participants['isShow'] = true;
                            participants['isNew'] = false;
                        })

                        // setParticipantsInfo(info)
                        // assignParticipants.forEach(participants=>{
                        //     participants['isShow'] = true;
                        //     participants['isNew'] = true;
                        // })
                        setStaffInfo(campData && campData[0].staffInfo[0].staffList)
                        console.log('dpoooooo', campData && campData[0].participantsInfo)
                        // setFinalList(campData && campData[0].participantsInfo)
                        // setFinalList(info)
                        setParticipantsInfo(info)
                        let x = JSON.parse(localStorage.getItem('newList'))
                        console.log('brpoo', info, x)
                        setFinalList([...info, ...x])
                        // setFinalList((prev) => [...prev,...x])

                    }
                }
            }
        )()
    }

        , [grp, reload])


    const handleAssign = async () => {
        // console.log(assignItem, list);
        setNewAssign((prev) => [...prev, clickedItem])
        // setAssignItem((prev) => [...prev, clickedItem])
        console.log(localStorage.getItem('newList'))

        // setFinalList((prev) => [...prev, localStorage.getItem('newList')])

        // participantsInfo

        // console.log('hereeeeeeeee', assignItem)

        setDisplay('')
        setOpen(false);
        setSearch('')
        // setDummy((prev) => [...prev,{applicationId : clickedItem.applicationId}])

        // let dataSent = {
        //     userId: localStorage.getItem('userId'),
        //     campId: campId,
        //     groupName: grp,
        //     applicationIds: assignItem,
        //     action: "ASSIGN"
        // }

        // let apiInfo = {
        //     url: "https://cncdevapi.natsoft.io/api/actcamp/assignparticipantstogroup",
        //     method: "POST",
        //     postData: dataSent,
        //     headers: {
        //         "Content-Type": "application/json",
        //         tokenheaderkey: localStorage.getItem("token"),
        //     },
        // }
        // let output = await apiService2(apiInfo)
        // console.log(output)
        // if (output.data.responseCode === 0) {
        //     setReload(prev => !prev)
        // }
    }
    console.log('gooooo', assignItem)
    console.log('loooo', finalList)
    const handleChange = (event) => {
        setGrp(event.target.value)
    }

    // React.useEffect(() => {
    //     // Retrieve the array from local storage
    //     const storedArray = localStorage.getItem('newList');

    //     // Parse the stored JSON string to JavaScript array
    //     if (storedArray) {
    //         setFinalList((prev) => [...prev, JSON.parse(storedArray)])
    //     }
    //   }, []);

    React.useEffect(() => {

        // let arr = [{name : 'asc'},{page :'page'}]
        console.log("Newww", newAssign)
        let arr = newAssign
        let strr = JSON.stringify(arr)
        console.log("Stringify", strr)
        localStorage.setItem('newList', strr)
        console.log("Print", localStorage)
        let x = JSON.parse(localStorage.getItem('newList'))
        console.log('afterr', finalList,)

        setFinalList((prev) => [...participantsInfo, ...x])
    }, [newAssign])


    // const handleOpen = () => {
    //     setOpen(true)

    //     if (assignItem && assignItem.length > 0) {
    //         list.forEach(row => {
    //             assignItem.forEach(assign => {
    //                 if (row.sno === assign.sno) {
    //                     row.isShow = false;
    //                 }
    //             })
    //         })
    //         console.log(list)

    //     }

    // };
    const handleOpen = async () => {
        setOpen(true)
        let dataSent = {
            userId: localStorage.getItem('userId'),
            campId: campId,
            participantstatus: 'No',
        }

        let apiInfo = {
            // url: "https://cncdevapi.natsoft.io/api/actcamp/getgroupwiseparticipantslist",
            url:`${SERVER_URL}${GROUPWISE_PARTICIPANTLIST}`,
            method: "POST",
            postData: dataSent,
            headers: {
                "Content-Type": "application/json",
                tokenheaderkey: localStorage.getItem("token"),
            },
        }
        let output = await apiService2(apiInfo)

        if (output.data.responseCode === 0) {
            let assignParticipants = output.data.responseData[0];
            assignParticipants.forEach(participants => {
                participants['isShow'] = true;
                participants['isNew'] = true;
            })
            console.log(assignParticipants, assignItem)

            if (newAssign && newAssign.length > 0) {
                assignParticipants.forEach(row => {
                    newAssign.forEach(assign => {
                        if (row.applicationId === assign.applicationId) {
                            row.isShow = false;
                            row.isNew = true
                        }
                    })
                })
                console.log('SORTEEDDDDDDDDD', assignParticipants)

            }
            setNonAssignParticipants(assignParticipants)
            setList(assignParticipants)
        }

    };
    const handleRemove = (item) => {
        console.log(item)
        console.log("In remove", localStorage)
        if (item.isNew === false) {
            //     list.forEach(row => {
            //             if (row === item) {
            //                 row.isShow = true;
            //             }  
            //     })
            //     console.log(list)
            // item.isShow = true       
            // setAssignItem(assignItem.filter((row) => row.sno !== item.sno));
            Swal.fire({
                iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
                text: "Are you sure you want to Submit?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                confirmButtonColor: "#379592",
                cancelButtonText: "No",
                cancelButtonColor: "#909090",
                customClass: {
                    container: "my-swal",
                    icon: 'no-border'
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    (async () => {
                        let dataSent = {
                            userId: localStorage.getItem('userId'),
                            campId: campId,
                            groupName: grp,
                            applicationIds: [item.applicationId],
                            action: "REMOVE"
                        }
                        console.log(dataSent)
                        let apiInfo = {
                            // url: "https://cncdevapi.natsoft.io/api/actcamp/assignparticipantstogroup",
                            url:`${SERVER_URL}${ASSIGN_PARTICIPANT}`,
                            method: "POST",
                            postData: dataSent,
                            headers: {
                                "Content-Type": "application/json",
                                tokenheaderkey: localStorage.getItem("token"),
                            },
                        }
                        let output = await apiService2(apiInfo)
                        console.log(output)
                        console.log("After apii", localStorage)
                        if (output.data.responseCode === 0) {
                            setReload(prev => !prev)
                            Swal.fire({
                                iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
                                text:output?.data?.responseData[0][0]?.Desc,
                                confirmButtonText: 'OK',
                                confirmButtonColor: "#379592",
                                customClass: {
                                    container: "my-swal",

                                },
                            })
                        }
                    })()
                }
            })
        }
        else if (item.isNew === true) {
            let myList = JSON.parse(localStorage.getItem('newList'))
            // console.log("Before remo",myList)


            let newList = myList.filter(i => item.applicationId !== i.applicationId)
            localStorage.setItem('newList', JSON.stringify(newList))
            console.log("Afterrr remo", newList)
            setReload(prev => !prev)
            setNewAssign(newList)
            // alert('new element is clicked')
        }



    }
    const handleClose = () => {
        setOpen(false);
        setDisplay('')
        setSearch('')
    }
    const useStyles = makeStyles({
        root: {
            margin: "10px",
            boxShadow: "0px 0px 10px #00000003",
            borderRadius: "20px",
        },
        mainheader: {
            letterSpacing: "0px",
            color: "#6c6c6c",
            textAlign: "center",
            fontSize: "18px",
            marginBottom: "2rem",
        },
        subheader: {
            letterSpacing: "0px",
            color: "#3B3B3B",
            textAlign: "left",
            fontSize: "16px",
            paddingTop: "16px",
        },
        listStyle: {
            height: "300px",
            overflowY: "scroll",
        },
    });
    const classes = useStyles();

    const selectItem = (item) => {
        setClickedItem(item);
        console.log(item);
        setDisplay(item.childName)
    };
    const handleSearchChange = (event) => {
        setSearch(event.target.value);
        console.log(event.target.value)
    };

    // console.log(assignItem && assignItem)

    React.useEffect(() => {
        if (search) {
            var filteredList = nonAssignParticipants && nonAssignParticipants.filter((item) => item.childName.toLowerCase().includes(search.toLowerCase()))
            setList(filteredList)
            console.log('coming inside', list)  
        }
        else {
            setList(nonAssignParticipants)
        }
    }, [search])
    console.log(list,'checkkkkkkkk')
    React.useEffect(() => {
        (
            async () => {
                // setLoading(true)
                console.log(campId)
                let dataSent = {
                    userId: localStorage.getItem('userId'),
                    campId: campId
                }

                let apiInfo = {
                    // url: "https://cncdevapi.natsoft.io/api/actcamp/getgroupnames",
                    url:`${SERVER_URL}${GET_GROUPNAME}`,
                    method: "POST",
                    postData: dataSent,
                    headers: {
                        "Content-Type": "application/json",
                        tokenheaderkey: localStorage.getItem("token"),
                    },

                }

                let output = await apiService2(apiInfo)
                if (output.data.responseCode === 0) {
                    let groupList = output.data.responseData[0]
                    console.log('list', groupList)
                    setGrpName(groupList)
                }
            }
        )()

    }, [])

    const handleSave = async () => {
        let arrr = []
        localStorage.setItem('newList', JSON.stringify(arrr))
        let finalIds = finalList.filter(i => i.isNew == true).map(it => it.applicationId)
        let dataSent = {
            userId: localStorage.getItem('userId'),
            campId: campId,
            groupName: grp,
            applicationIds: finalIds,
            action: "ASSIGN"
        }

        console.log("datasent", dataSent)

        let apiInfo = {
            // url: "https://cncdevapi.natsoft.io/api/actcamp/assignparticipantstogroup",
            url:`${SERVER_URL}${ ASSIGN_PARTICIPANT}`,
            method: "POST",
            postData: dataSent,
            headers: {
                "Content-Type": "application/json",
                tokenheaderkey: localStorage.getItem("token"),
            },
        }
        let output = await apiService2(apiInfo)
        console.log(output, output?.data?.responseData[0][0]?.Desc)

        if (output.data.responseCode === 0) {
            setReload(prev => !prev)
            Swal.fire({
                iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
                text: output?.data?.responseData[0][0]?.Desc,
                confirmButtonText: 'OK',
                confirmButtonColor: "#379592",
                customClass: {
                    container: "my-swal",

                },
            }).then(() => {
                history.push(`/ViewParticipants/${campId}`)
            }

            )
        }
    }

    console.log("Stor", localStorage)

    return (
        <Box>
            <Grid container display='row'>
                <Stack direction='row' spacing={1}>
                    <Link to={`/ViewParticipants/${campId}`}>
                        <Box
                            // mt="0.75rem"
                            component="img"
                            sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
                            height="20"
                            alt="Logo"
                            src={ArrowBack}
                        ></Box>
                    </Link>
                    <Box className="gilroy" alignSelf="center">
                        Edit Participants
                    </Box>
                </Stack>
            </Grid>

            <Grid container >
                <Grid item md={4} sm={12} xs={12}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 185 }}>
                        <InputLabel id="demo-simple-select-standard-label">Group</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={grp}
                            onChange={handleChange}
                            label="Group"
                        >
                            {grpName && grpName.map((item, index) => (
                                <MenuItem key={index} value={item.groupNames}>{item.groupNames}</MenuItem>
                            ))}


                        </Select>
                    </FormControl>


                </Grid>
                <Grid container display='flex' justifyContent='space-between' sx={{ m: 1 }}>
                    <Grid item md={4} sm={12} xs={12} >
                        <TextField
                            id="standard-basic"
                            label="Group Leader"
                            variant="standard"
                            value={staffInfo && staffInfo
                                .filter((item) => item.rolesname === 'GROUP LEADER')
                                .map((item) => {
                                    return (item.staffName)
                                })}

                            InputProps={{
                                readOnly: true,

                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            sx={{
                                width: 185,
                            }}
                        ></TextField>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} >
                        <TextField
                            id="standard-basic"
                            label="Support Staff"
                            variant="standard"
                            value={staffInfo && staffInfo
                                .filter((item) => item.rolesname === 'SUPPORT STAFF')
                                .map((item) => {
                                    return (item.staffName)
                                })}
                            InputProps={{
                                readOnly: true,

                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            sx={{
                                width: 185,
                            }}
                        ></TextField>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} >
                        <TextField
                            id="standard-basic"
                            label="Therapist"
                            variant="standard"
                            value={staffInfo && staffInfo
                                .filter((item) => item.rolesname === 'THERAPIST')
                                .map((item) => {
                                    return (item.staffName)
                                })}
                            InputProps={{
                                readOnly: true,

                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            sx={{
                                width: 185,
                            }}
                        ></TextField>
                    </Grid>
                </Grid>
                <Grid item md={4} sm={12} xs={12} sx={{ m: 1 }} >
                    <TextField
                        id="standard-basic"
                        label="Medication Manager"
                        variant="standard"
                        value={staffInfo && staffInfo
                            .filter((item) => item.rolesname === 'MEDICATION MANAGER')
                            .map((item) => {
                                return (item.staffName)
                            })}
                        InputProps={{
                            readOnly: true,
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        sx={{
                            width: 185,
                        }}
                    ></TextField>
                </Grid>
            </Grid>
            <Grid container display='flex' justifyContent='flex-end' my='1rem'>
                <Grid item>
                    <Button
                        variant="outlined"
                        sx={{
                            px: "1rem",
                            mx: "0.75rem",
                            bgcolor: "white",
                            color: "#379592",
                            textTransform: "none",
                            "&:hover": { background: "white", color: '#379592' },
                            // width: '130px',

                        }}
                        className="gilroy"
                        disabled={grp ? false : true}
                    >
                        <Stack direction='row' justifyContent='center' alignItems='center' onClick={handleOpen}>
                            <Box
                                // mt="0.75rem"
                                component="img"
                                sx={{ height: "1.2rem", width: "1.2rem", cursor: 'pointer', mr: '0.5rem' }}
                                height="20"
                                alt="Logo"
                                src={Searchdvance}

                            ></Box>
                            <Box>Add</Box>
                        </Stack>
                    </Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450 }} aria-label="customized table" my='1rem' >

                    <TableHead >

                        <StyledTableRow>
                            <StyledTableCell align='center'>Sl. No</StyledTableCell>
                            <StyledTableCell align="center">Applicants</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {/* {console.log(assignItem && assignItem.length,assignItem)} */}
                        {/* {assignItem && assignItem.map((item, index) => { */}
                        {finalList && finalList.map((item, index) => {

                            return (
                                <TableRow key={index} sx={{
                                    "& .MuiTableCell-root": { py: "0.45rem" },
                                }}>
                                    <StyledTableCell component="th" scope="row" align="center" >
                                        {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Grid container display='flex' alignItems='center' >
                                            <Grid item md={3}>
                                                <Box
                                                    component="img"
                                                    sx={{ height: "2rem", width: "2rem", cursor: 'pointer' }}
                                                    alt="Logo"
                                                    src={item.childPhoto}
                                                ></Box>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Box>{item.childName}</Box>
                                            </Grid>

                                        </Grid>
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row" align="center" >
                                        <Button
                                            variant="contained"
                                            sx={{
                                                px: "1rem",
                                                width: '130px',
                                                mx: "0.5rem",
                                                bgcolor: item.isNew === false ? "#F21010" : "#FF9529",
                                                textTransform: "none",
                                                "&:hover": { background: item.isNew === false ? "#F21010" : "#FF9529" },
                                            }}
                                            className="gilroy"
                                            onClick={() => handleRemove(item)}
                                        >
                                            Remove
                                        </Button>

                                    </StyledTableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent='flex-end' my='1rem'>
                {/* <Link className='link2' to='/ViewParticipants'> */}
                <Button
                    variant="contained"
                    sx={{
                        px: "2rem",
                        mx: "1rem",
                        bgcolor: "#379592",
                        textTransform: "none",
                        "&:hover": { background: "#379592" },

                    }}
                    className="gilroy"
                    onClick={() => handleSave()}
                    disabled={clickedItem ? false : true}
                >
                    Save
                </Button>
                {/* </Link> */}
                <Link className='link2' to='/ViewParticipants'>
                    <Button
                        variant="contained"
                        sx={{
                            px: "2rem",
                            mx: "1rem",
                            bgcolor: "#909090",
                            textTransform: "none",
                            "&:hover": { background: "#909090" },
                        }}
                        className="gilroy"
                    >
                        Cancel
                    </Button>
                </Link>

            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <Box sx={style}>
                        <Typography className={classes.mainheader}>
                            Participants
                        </Typography>

                        <Search
                            width="20%"
                            sx={{
                                backgroundColor: "#F8F8F8",
                                border: 1,
                                borderColor: "#909090",
                                borderRadius: "0.75rem",
                                "&:hover": {
                                    // backgroundColor: alpha(theme.palette.common.white, 0.25),
                                    // backgroundColor:'pink',
                                    color: "#379592",
                                    border: 2,
                                    borderColor: "rgba(55, 149, 146, 0.8)",
                                },
                            }}
                        >
                            <SearchIconWrapper>
                                <SearchIcon sx={{ color: "#909090" }} />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                value={search}
                                onChange={handleSearchChange}
                                inputProps={{ "aria-label": "display" }}
                            />
                        </Search>
                        <Stack direction="row" spacing={3} my="1.5rem">
                            <Box component="img" src={User}></Box>
                            <Box sx={{ color: "#379592" }}>{display && display}</Box>
                        </Stack>

                        <List className={classes.listStyle}>
                            {/* {list.map((item, index) => {
                                    if (item.isShow === true)
                                        return (
                                            <Typography key={index}
                                                onClick={() => selectItem(item)}
                                                sx={{ cursor: "pointer" }}
                                            >
                                                {item.applicants}
                                            </Typography>
                                        )
                                })} */}
                            {console.log(nonAssignParticipants, 'usdbvdbovndk ')}
                            {list && list.map((item, index) => {

                                return (
                                    <Stack direction='column'>
                                        <Button key={index}
                                            onClick={() => selectItem(item)}

                                            disabled={item.isShow === false ? true : false}
                                            sx={{ cursor: "pointer", color: item.isShow === false ? '#379592' : 'black', textTransform: 'none' }}
                                        >
                                           
                                            <Grid container display='flex' alignItems='center' >
                                            <Grid item md={3}>
                                                <Box
                                                    component="img"
                                                    sx={{ height: "2rem", width: "2rem", cursor: 'pointer' }}
                                                    alt="Logo"
                                                    src={item.childPhoto}
                                                ></Box>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Box>{item.childName}</Box>
                                            </Grid>

                                        </Grid>


                                        </Button>
                                    </Stack>



                                )
                            })}
                        </List>
                        <Box display="flex" justifyContent="center" my="0.5rem">
                            <Button
                                variant="contained"
                                onClick={() => handleAssign()}
                                disabled={clickedItem ? false : true}
                                //   disabled={!seachName}
                                //   onClick={() =>
                                //     handleRowChange(
                                //       rowId,
                                //       selectedType,
                                //       seachName,
                                //       "groupLeaderId",
                                //       userId
                                //     )
                                //   }
                                sx={{
                                    bgcolor: "#379592",
                                    color: "white",
                                    textTransform: "none",
                                    "&:hover": { background: "#379592" },
                                }}
                            >Assign

                            </Button>
                        </Box>
                    </Box>
                </div>
            </Modal>
        </Box>
    )
}