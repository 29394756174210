import React from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@material-ui/core";
import "../../App.css";
import "../../index.css";
import { Link, useHistory, useParams } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import TextField from "@mui/material/TextField";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, alpha } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";
import GreenCheck2 from "../../Assets/Icons/Icons/GreenCheck2.png";
import { apiService, apiService2 } from "../../Services/apiService";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { VIEW_NDISAGREEMENT, GETNDIS_AGREEMENTDETAILS, SERVER_URL } from '../../Services/apiEndpints'
import html2pdf from 'html2pdf.js'
// import {  useHistory } from "react-router-dom";

// const campData ={
//     periodFrom:""
// }
const dummy = {
    applicationId: "",
    childId: "",
    childName: "",
    childDOB: "",
    ndisNumber: "",
    managementType: "",
    itemCode: "",
    parentPerName: "",
    parentPerMobile: "",
    parentPerEmail: "",
    parentPerAddrress: "",
    provPerName: "",
    provPerMobile: "",
    provPerEmail: "",
    provPerAddrress: "",
    campId: "",
    campName: "",
    campLocation: "",
    periodFrom: "",
    periodTo: "",
    cost: "",
    hours: "",
    totalAmount: "",
    dateOfCommencement: ""
}
export default function DownloadNDIS() {
    const history = useHistory();
    let { campId, applicantId } = useParams();

    const [ndisInfo, setNDISInfo] = React.useState({})
    const [readData, setReadData] = React.useState(dummy)
    const [provDate, setProvDate] = React.useState('')

    const dummy2 = {
        userId: localStorage.getItem('userId'),
        campId: campId,
        applicationId: applicantId,
        dateOfCommencement: "",
        periodFrom: "",
        periodTo: "",
        itemCode: "",
        managementType: "",
        provPerName: "",
        provPerMobile: "",
        provPerEmail: "",
        provPerAddrress: "",
        provPerSubmittedDate: '',
        ndisAgreementStatus: "DRAFT"
    }
    const [sendData, setSendData] = React.useState(dummy2)

    // const[formData,setFormData] =React.useState({})
    const shoot = () => {
        Swal.fire({
            icon: "success",
            iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
            // text: register?.data?.responseData,
            text: "",
            confirmButtonText: "OK",
            confirmButtonColor: "#379592",
        }).then(history.push("/CampList1"));
    }


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: theme.palette.common.black,
            backgroundColor: "rgba(55, 149, 146, 1)",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    React.useEffect(() => {
        if (localStorage.getItem('ndisStatus') === 'NA') {
            (
                async () => {

                    let dataSent = {
                        userId: localStorage.getItem('userId'),
                        campId: campId,
                        applicationId: applicantId
                    }

                    let apiInfo = {
                        // url: "https://cncdevapi.natsoft.io/api/child/getndisagreementdetails",
                        url: `${SERVER_URL}${GETNDIS_AGREEMENTDETAILS}`,
                        method: "POST",
                        postData: dataSent,
                        headers: {
                            "Content-Type": "application/json",
                            tokenheaderkey: localStorage.getItem("token"),
                        },

                    }

                    let output = await apiService2(apiInfo)
                    // console.log(output.data.responseData[0])
                    let ndis = output.data.responseData[0]
                    setNDISInfo(output.data.responseData[0][0])
                    console.log(output.data.responseData[0][0], 'lolllll')
                    // if (output?.data?.responseData[0][0].Code === '0') {
                    setReadData({
                        ...readData,
                        applicationId: output.data.responseData[0][0].applicationId,
                        childId: output.data.responseData[0][0].childId,
                        childName: output.data.responseData[0][0].childName,
                        childDOB: output.data.responseData[0][0].childDOB,
                        ndisNumber: output.data.responseData[0][0].ndisNumber,
                        managementType: output.data.responseData[0][0].managementType,
                        itemCode: output.data.responseData[0][0].itemCode,
                        parentPerName: output.data.responseData[0][0].parentPerName,
                        parentPerMobile: output.data.responseData[0][0].parentPerMobile,
                        parentPerEmail: output.data.responseData[0][0].parentPerEmail,
                        parentPerAddrress: output.data.responseData[0][0].parentPerAddrress,
                        provPerName: output.data.responseData[0][0].provPerName,
                        provPerMobile: output.data.responseData[0][0].provPerMobile,
                        provPerEmail: output.data.responseData[0][0].provPerEmail,
                        provPerAddrress: output.data.responseData[0][0].provPerAddrress,
                        campId: output.data.responseData[0][0].campId,
                        campName: output.data.responseData[0][0].campName,
                        campLocation: output.data.responseData[0][0].campLocation,
                        periodFrom: (output.data.responseData[0][0]?.periodFrom),
                        periodTo: (output.data.responseData[0][0]?.periodTo),
                        cost: output.data.responseData[0][0].cost,
                        hours: output.data.responseData[0][0].hours,
                        totalAmount: output.data.responseData[0][0].totalAmount,
                        dateOfCommencement: (output.data.responseData[0][0]?.dateOfCommencement),
                        provPerSubmittedDate: output.data.responseData[0][0]?.provPerSubmittedDate,
                        parentPerSubmittedDate: output.data.responseData[0][0]?.parentPerSubmittedDate,
                        plan: output.data.responseData[0][0]?.plan
                    })
                    // }
                    // else{
                    //     console.log(output?.data?.responseData[0][0].Desc)
                    //     Swal.fire({
                    //         icon: "error",
                    //         // iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
                    //         // text: register?.data?.responseData,
                    //         text: output?.data?.responseData[0][0].Desc,
                    //         confirmButtonText: "OK",
                    //         confirmButtonColor: "#379592",
                    //     }).then (history.push(`/ChangeSuitability/${campId}/${applicantId}`))
                    // }
                }
            )()
        }
        else if (localStorage.getItem('ndisStatus') !== 'NA') {

            (
                async () => {
                    console.log('wanted')
                    let dataSent = {
                        userId: localStorage.getItem('userId'),
                        applicationId: applicantId
                    }

                    let apiInfo = {
                        // url: "https://cncdevapi.natsoft.io/api/child/viewndisagreement",
                        url: `${SERVER_URL}${VIEW_NDISAGREEMENT}`,
                        method: "POST",
                        postData: dataSent,
                        headers: {
                            "Content-Type": "application/json",
                            tokenheaderkey: localStorage.getItem("token"),
                        },

                    }

                    let output = await apiService2(apiInfo)
                    console.log(output.data.responseData[0])
                    let ndis = output.data.responseData[0]
                    setNDISInfo(output.data.responseData[0][0])

                    if (output.data.responseData[0]) {
                        setReadData({
                            ...readData,
                            ndiAgrmntId: output.data.responseData[0][0].ndiAgrmntId,
                            applicationId: output.data.responseData[0][0].applicationId,
                            childId: output.data.responseData[0][0].childId,
                            childName: output.data.responseData[0][0].childName,
                            childDOB: output.data.responseData[0][0].childDOB,
                            ndisNumber: output.data.responseData[0][0].ndisNumber,
                            managementType: output.data.responseData[0][0].managementType,
                            itemCode: output.data.responseData[0][0].itemCode,
                            parentPerName: output.data.responseData[0][0].parentPerName,
                            parentPerMobile: output.data.responseData[0][0].parentPerMobile,
                            parentPerEmail: output.data.responseData[0][0].parentPerEmail,
                            parentPerAddrress: output.data.responseData[0][0].parentPerAddrress,
                            provPerName: output.data.responseData[0][0].provPerName,
                            provPerMobile: output.data.responseData[0][0].provPerMobile,
                            provPerEmail: output.data.responseData[0][0].provPerEmail,
                            provPerAddrress: output.data.responseData[0][0].provPerAddrress,
                            campId: output.data.responseData[0][0].campId,
                            campName: output.data.responseData[0][0].campName,
                            campLocation: output.data.responseData[0][0].campLocation,
                            periodFrom: (output.data.responseData[0][0]?.periodFrom),
                            periodTo: (output.data.responseData[0][0]?.periodTo),
                            cost: output.data.responseData[0][0].cost,
                            hours: output.data.responseData[0][0].hours,
                            totalAmount: output.data.responseData[0][0].totalAmount,
                            dateOfCommencement: (output.data.responseData[0][0]?.dateOfCommencement),
                            provPerSubmitted: output.data.responseData[0][0].provPerSubmitted,
                            provPerSubmittedDate: output.data.responseData[0][0].provPerSubmittedDate,
                            ndisAgreementStatus: output.data.responseData[0][0].ndisAgreementStatus,
                            parentPerSubmittedDate: output.data.responseData[0][0]?.parentPerSubmittedDate,
                            plan: output.data.responseData[0][0]?.plan,
                            parentPerSubmitted: output.data.responseData[0][0]?.parentPerSubmitted

                        })
                    }
                }
            )()
        }
    }

        , [])

    const handleNDISNewInfo = (event) => {
        console.log(event.target.value)
        setProvDate(event.target.value)
    }




    const conversion = (inputDate) => {

        if (inputDate) {
            var formattedDate = inputDate.replace(/\//g, '-');
            var dateParts = formattedDate.split('-');
            var day = dateParts[0];
            var month = dateParts[1];
            var year = dateParts[2];
            return year + '-' + month + '-' + day;
        }
    }

    //     const generatePDF = () => {
    // console.log('clicking')
    //         // Get the element you want to convert to PDF (e.g., a div with id "my-element")
    //         const element = document.getElementById('my-element');
    //         // html2canvas: { width: element_width},
    //         // Use html2canvas to convert the element to a canvas
    //         html2canvas(element).then((canvas) => {
    //           // Create a new instance of jsPDF
    //           const doc = new jsPDF();
    //           // Calculate the width and height of the PDF document
    //           const width = doc.internal.pageSize.getWidth();
    //           const height = doc.internal.pageSize.getHeight();
    //           // Add the canvas to the PDF document
    //           console.log(width,height,'hereeeeeeeeeeee')
    //           doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, width, height);
    //           // Save the PDF
    //           doc.save('ndis.pdf');
    //         });



    //     };

    // const generatePDF = () => {
    //     console.log('clicking');
    //     const element = document.getElementById('my-element');
    //     const canvas = document.createElement('canvas');


    //     // Set canvas size to match the element's dimensions
    //     canvas.width = element.offsetWidth;
    //     canvas.height = (element.offsetHeight);

    //     const elementAspectRatio = element.offsetWidth / element.offsetHeight;
    //     const pdfAspectRatio = 0.707; // Assuming you want to maintain a square aspect ratio for the PDF, change this if needed

    //     // const ctx = canvas.getContext("2d");

    //     // Scaled rectangle
    //     // ctx.scale(210/canvas.width, 297/canvas.height);

    //     html2canvas(element, { canvas: canvas,scale:pdfAspectRatio,}).then(() => {
    //         var doc = new jsPDF('p', 'px', 'a4');
    //         var adjust = Math.ceil(994*297/210); //1050 is my assupmtion of how many pixels each page holds vertically
    //         // var adjust =1050;
    //         var extraNo = Math.ceil(canvas.height / adjust); //Lets me know how many page are needed to accommodate this image
    //         console.log(extraNo, canvas.height, canvas.width)
    //         for (let r = 0; r < extraNo; r++) {

    //             doc.addImage(canvas, 'JPEG', 0, (adjust) * r, undefined, undefined);
    //             //  addImage(imageData, format, x, y, width, height, alias, compression, rotation)
    //             doc.addPage();
    //             // doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, width, height);
    //         }
    //         doc.save('ndis.pdf');
    //     });
    // };

    // const generatePDF = () => {
    //     console.log('Nearly working code');
    //     const element = document.getElementById('my-element');
    //     const canvas = document.createElement('canvas');

    //     // Set canvas size to match the element's dimensions
    //     canvas.width = element.offsetWidth;
    //     canvas.height = element.offsetHeight;

    //     const elementAspectRatio = element.offsetWidth / element.offsetHeight;
    //     const pdfAspectRatio = 0.707;

    //     html2canvas(element, { canvas: canvas, scale: pdfAspectRatio*1.07}).then(() => {
    //       var doc = new jsPDF('p', 'px', 'a4');

    //       var adjust = Math.ceil(994 * 297 / 210); //1050 is my assumption of how many pixels each page holds vertically

    //       var extraNo = Math.ceil(canvas.height / adjust); //Lets me know how many pages are needed to accommodate this image
    //       console.log(extraNo, canvas.height, canvas.width)
    //       for (let r = 0; r < extraNo; r++) {
    //         const offsetY = -adjust * r;
    //         console.log(offsetY)
    //         doc.addImage(canvas.toDataURL('image/jpeg'), 'JPEG',20, offsetY, undefined, undefined);
    //         if (r < extraNo - 1) {
    //           doc.addPage();
    //         }
    //       }
    //       doc.save('ndis.pdf');
    //     });
    //   };

    const generatePDF = () => {
        console.log('yes')
        // document.getElementByID("click-element") .addEventListener("Click", () => {
        const element = document.getElementById('my-element');
        // const element = document.getElementByID ("my-element");

        var opt = {

            margin: 6,
            padding: 4,

            filename: "ndis.pdf",

            image: { type: "jpeg", quality: 1 },

            html2canvas: { scale: 2 },

            // jsPDF: {unit: 'mm', format: 'letter', orientation: 'p'}
            jsPDF: { unit: "mm", format: "a3", orientation: "p" }
        };
        html2pdf().from(element).set(opt).save();


    }
    return (
        <Box sx={{ fontSize: '15px' }}>

            {/* <Grid container > */}
            <Grid item sx={{ color: '#707070' }} className='medium'>
                <Grid container display='flex' justifyContent='space-between'>
                    <Grid>
                        <Link to={`/ChangeSuitability/${campId}/${applicantId}`} className="link2">
                            <Box
                                alignSelf="center"
                                component="img"
                                sx={{
                                    height: "0.75rem",
                                    width: "0.65rem",
                                    cursor: "pointer",

                                }}
                                alt="Logo"
                                src={ArrowBack}
                            ></Box>
                        </Link>
                    </Grid>
                    <Button
                        variant="contained"
                        sx={{
                            px: "2rem",
                            mx: "1rem",
                            bgcolor: "#FF9529",
                            textTransform: "none",
                            "&:hover": { background: "#FF9529" },
                        }}
                        className="gilroy"
                        onClick={() => generatePDF()}
                    disabled={readData && readData.parentPerSubmitted === 'No'}
                    >
                        Download
                    </Button>
                </Grid>
                <div id="my-element" >
                    {/* <div  id="my-element" > */}
                    <Box sx={{ textAlign: 'center', color: '#3B3B3B' }}>
                        <h3>Calm and Connected NDIS Service Agreement</h3>
                    </Box>
                    <Box my='1rem'>
                        <Box my='0.5rem' className='bold'>Parties</Box>
                        <Box >
                            This Service Agreement is for {ndisInfo && ndisInfo.childName} , a participant in the National Disability Insurance Scheme<br />
                            (Participant), and is made between:
                        </Box>
                    </Box>
                    <Grid container my='1rem' >
                        <Grid item md={4} sx={{ color: '#707070' }} className='gilroy'>
                            Participant’s Representative <br />
                            and <br />
                            Provider <br />
                            ABN: 22613026430 <br /> Calm and Connected Pty Ltd
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                // id="outlined-basic"
                                // label="Parent's Name"
                                // variant="outlined"
                                id="standard-basic"
                                variant='standard'
                                InputProps={{
                                    readOnly: true
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                multiline
                                size="small"
                                value={readData && readData.parentPerName}
                            />
                        </Grid>
                    </Grid>
                    <Grid container display='flex' my='1rem'>
                        <Grid item md={4}>NDIS Participant Number :</Grid>
                        <Grid item md={4}>
                            <TextField
                                // id="outlined-basic"
                                // label="NDIS Number"
                                // variant="outlined"
                                id="standard-basic"
                                variant='standard'
                                InputProps={{
                                    readOnly: true
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                size="small"
                                value={readData && (readData.plan === 'Private Funding') || (readData.plan === null) ? ('NA') : (readData && readData.ndisNumber)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container display='flex' my='1rem'>
                        <Grid item md={4}>Participant date of birth: </Grid>
                        <Grid item md={4}>
                            <TextField
                                // id="outlined-basic"
                                // label="DOB"
                                // variant="outlined"
                                id="standard-basic"
                                variant='standard'
                                InputProps={{
                                    readOnly: true
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                size="small"
                                value={readData && readData.childDOB}
                            />
                        </Grid>
                    </Grid>
                    <Grid display='flex' alignItems='center'>
                        <Box >This Service Agreement will commence on </Box>
                        <TextField
                            // id="outlined-basic"
                            // label="DOB"
                            // variant="outlined"
                            id="standard-basic"
                            variant='standard'
                            name='dateOfCommencement'
                            sx={{ mx: '2.6rem' }}
                            InputProps={{
                                readOnly: true
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size="small"
                            value={readData && readData.dateOfCommencement}
                        />
                        <Box>for the period </Box>
                    </Grid>
                    <Grid display='flex' alignItems='center' my='0.25rem'>
                        <TextField
                            // id="outlined-basic"
                            // label="DOB"
                            // variant="outlined"
                            id="standard-basic"
                            variant='standard'
                            name='periodFrom'
                            sx={{ px: '0.25rem' }}
                            InputProps={{
                                readOnly: true
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size="small"
                            value={readData && readData.periodFrom}
                        />
                        <Box mx='1rem'>to</Box>


                        <TextField
                            // id="outlined-basic"
                            // label="DOB"
                            // variant="outlined"
                            id="standard-basic"
                            variant='standard'
                            name='periodTo'
                            sx={{ px: '0.25rem' }}
                            InputProps={{
                                readOnly: true
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size="small"
                            value={readData && readData.periodTo}
                        />
                    </Grid>

                    <Box my='1rem'>
                        <Box className='bold' >The NDIS and this Service Agreement</Box>
                        <Box>
                            This Service Agreement is made for the purpose of providing supports
                            under the Participant’s National
                            Disability Insurance Scheme (NDIS) plan.
                        </Box>
                        <Box>
                            A copy of the Participant’s NDIS plan is to be given to the
                            provider.
                        </Box>
                        <Box>
                            The Parties agree that this Service Agreement is made in the context
                            of the NDIS, which is a scheme that aims to:
                        </Box>
                        <Box>
                            Support the independence and social and economic participation of
                            people with disability, and
                            Enable people with a disability to exercise choice and control in
                            the pursuit of their goals and the planning

                            and delivery of their supports.
                        </Box>
                    </Box>
                    <Box my='1rem'>
                        <Box className='bold'>Schedule of Supports</Box>
                        <Box>The Provider agrees to provide the Participant</Box>
                        <Box>Funding Category: Improved Daily Living (CB Daily Activities)/Core Supports</Box>
                        {/* {(readData && readData.plan === 'Private Funding') || (readData && readData.plan === null)
                            ? ('') : ( */}
                        <Box>
                            <Grid container display='flex' my='1rem'>
                                <Grid item md={3}>Item Code :</Grid>
                                <Grid item md={3}>
                                    <TextField
                                        // id="outlined-basic"
                                        // label="Item Code"
                                        // variant="outlined"
                                        id="standard-basic"
                                        variant='standard'
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size="small"
                                        name='itemCode'
                                        value={(readData && readData.plan === 'Private Funding') || (readData && readData.plan === null) ? ('NA') : (readData && readData.itemCode)}
                                    // value={readData && readData.itemCode}
                                    // onChange={(event) => storeInfo(event)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container display='flex' my='1rem'>
                                <Grid item md={3}>Management Type :</Grid>
                                <Grid item md={3}>
                                    <TextField
                                        // id="outlined-basic"
                                        // label="Management Type"
                                        // variant="outlined"
                                        id="standard-basic"
                                        variant='standard'
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size="small"
                                        name='managementType'
                                        value={(readData && readData.plan === 'Private Funding') || (readData && readData.plan === null) ? ('NA') : (readData && readData.managementType)}
                                    // value={readData && readData.managementType}
                                    // onChange={(event) => storeInfo(event)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        {/* // )} */}

                    </Box>
                    {/* </div> */}
                    <TableContainer component={Paper} my='1rem'
                    //  sx={{ width: 600 }}
                    >
                        <Table
                            // sx={{ width: 600 }}
                            sx={{ minWidth: 420 }}
                            aria-label="customized table"
                            my="1rem"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="flex-start" mx='0.5rem'>Service</StyledTableCell>
                                    <StyledTableCell align="center">Costing</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow >
                                    <TableCell align="flex-start"><li>Therapy Intensive Camp</li></TableCell>
                                    <TableCell align="center">

                                        <Grid container display='flex' alignItems='center' justifyContent='center' >
                                            <Grid item md={5}>Cost</Grid>
                                            <Grid item >
                                                <TextField
                                                    // id="outlined-basic"
                                                    // label="Cost"
                                                    // variant="outlined"
                                                    id="standard-basic"
                                                    variant='standard'
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    size="small"

                                                    value={`$ ${readData && readData.cost} /hr`}
                                                    sx={{ width: 150 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="flex-start"><li>Goal Setting phone call</li></TableCell>
                                    <TableCell align="center">
                                        <Grid container display='flex' spacing={1} alignItems='center' justifyContent='center'>
                                            <Grid item md={5}>Total Hours</Grid>
                                            <Grid item >
                                                <TextField
                                                    // id="outlined-basic"
                                                    // label="Total Hours"
                                                    // variant="outlined"
                                                    id="standard-basic"
                                                    variant='standard'
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    size="small"
                                                    value={readData && readData.hours}
                                                    sx={{ width: 150 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell >
                                        <Box align="flex-start" mb='0.75rem' ><li>Camp Summary Report</li></Box>
                                        <Grid container display='flex' justifyContent='space-between' alignItems='center' mt='0.25rem'>
                                            <Grid item my='0.5rem' sm={12} md={4}>
                                                <TextField
                                                    // id="outlined-basic"
                                                    // label="Camp Name"
                                                    // variant="outlined"
                                                    id="standard-basic"
                                                    variant='standard'
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    size="small"
                                                    value={readData && readData.campName}
                                                />
                                            </Grid>

                                            <Grid item my='0.5rem' sm={12} md={4} sx={{ textAlign: 'center' }}>Attendence</Grid>
                                            <Grid item my='0.5rem' sm={12} md={4}>
                                                <TextField
                                                    // id="outlined-basic"
                                                    // label="Child Name"
                                                    // variant="outlined"
                                                    id="standard-basic"
                                                    variant='standard'
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    size="small"
                                                    value={readData && readData.childName}

                                                />

                                            </Grid>

                                        </Grid>
                                        {/* <Box textAlign='center' >Camp Summary Report</Box> */}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Grid container display='row' spacing={1}  justifyContent='center'>
                                            <Grid item md={5}>Total Amount</Grid>
                                            <Grid item  >
                                                <TextField
                                                    // id="outlined-basic"
                                                    // label="Total Amount"
                                                    // variant="outlined"
                                                    id="standard-basic"
                                                    variant='standard'
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    size="small"
                                                    value={`$ ${readData && readData.totalAmount} `}
                                                    sx={{ width: 150 }}
                                                />
                                            </Grid>
                                        </Grid>

                                    </TableCell>
                                </TableRow>
                                {/* <TableRow >
                                    <TableCell align="center">Total Improved Daily Living Funding Amount:</TableCell>
                                    <TableCell align="center">
                                        <Box>
                                            <TextField
                                                id="outlined-basic"
                                                label="Total Amount"
                                                variant="outlined"
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                size="small"
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow> */}
                            </TableBody>

                        </Table>
                    </TableContainer>
                    <Grid container display='flex' mt='1.5rem' mb='1rem'>
                        <Grid item md={12}>
                            <Box>All prices are GST inclusive (if applicable) and include the cost of providing the supports.</Box>
                        </Grid>

                        <Grid item md={12}>
                            <Box>Additional expenses (i.e. things that are not included as part of a Participant’s NDIS supports) are the responsibility of the Participant and their family and are not included in the cost of the supports. </Box>
                        </Grid>
                        <Grid item md={12}>
                            <Box>Examples include items that are written on the packing list of groups and camp programs.</Box>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ color: '#707070' }} className='medium'>
                        <Grid item md={12} >
                            <Box >
                                <Box className='bold' >Responsibilities of Provider</Box>
                                <Box>The Provider agrees to: </Box>
                                <Box>Review the provision of supports at 6 months with Participant</Box>
                                <Box>Provide pre screen sessions at a mutually agreed time with participants</Box>
                                <Box>Treat the Participant and their caregivers with courtesy and respect</Box>
                                <Box>Consult the Participant on decisions about how supports are provided</Box>
                                <Box>
                                    Give the Participant a minimum of 48 hours’ notice where practical if
                                    the Provider has to change a scheduled appointment to provide supports
                                </Box>
                                <Box>Give the Participant the required notice if the Provider needs to end the Service Agreement (see ‘Ending this Service Agreement’ below for more information) </Box>
                                <Box>Protect the Participant’s privacy and confidential information</Box>
                                <Box>
                                    Provide supports in a manner consistent with all relevant laws,
                                    including the
                                    <span className='bold'> {" "}
                                        <a href='https://www.legislation.gov.au/Details/C2022C00206' target="_blank">
                                            National Disability Insurance Scheme Act 2013
                                        </a>
                                    </span> and <span className='bold'> {" "} <a href='https://www.legislation.gov.au/Details/C2022C00206/Enables' target="_blank">
                                        rules
                                    </a> </span>
                                    and the Australian Consumer Law; keep accurate records on
                                    the supports provided to the Participant, and
                                </Box>
                                <Box>
                                    Plan Manager to quarantine funds for camp. Issue invoices to the Participant's Plan Manager directly following camp. Payment is required within 7 business days of invoice issue. For camps that cost greater than $1500 payment will be requested 10 days in advance of the camp. Refunds will be provided in line with the cancellation policy outlined below if they occur within the 10 day period.
                                </Box>
                                <Box>
                                    Self managed: Issue regular invoices directly to the nominated person self managing the funds once a place on camp is confirmed. Payment is required within 7 business days of invoice issue.
                                </Box>
                                <Box>Agency Managed: weekly claiming of supports delivered through the NDIS portal. Issue monthly statements to the participant for signing to review supports claimed.</Box>
                                <Box>Be available to attend one follow up session for clients, as therapist availability allows, through Calm and Connected’s therapy services to provide handover to the participants supports</Box>
                                <Box>Support the use and engagement of formal or informal advocates. This may be during service delivery or in providing feedback. Information on available advocacy services can be found on our website<span className='bold'>
                                    <a href='https://calmandconnected.com.au/are-you-looking-for-advocacy-and-support-services/' target="_blank"> Click Here</a>
                                </span></Box>
                            </Box>
                        </Grid>
                        <Grid item md={12} my='1rem'>
                            <Box className='bold'>Communication</Box>
                            <Box>Communicate openly and honestly in a timely manner </Box>
                            <Box>Provide reports within 2 weeks of request </Box>
                            <Box>Provide updates and notifications of the participants requirements and experience on camp through the Calm and Connected Camp App.</Box>
                            <Box>Reply to emails and phone calls as soon as practicable and/or within 3 business days. If no reply is given in that time, please forward the email to contact@calmandconnected.com.au</Box>
                        </Grid>
                        <Grid item md={12} my='0.75rem'>
                            <Box className='bold'>Responsibilities of Participant’s representative</Box>
                            <Box >
                                The Participant’s representative agrees to:
                            </Box>
                            <Box >
                                Inform the Provider about how they wish the supports to be delivered
                                to meet the Participant’s needs.
                            </Box>
                            <Box >
                                Complete all documentation and requirements for camp that is detailed
                                in in the pre-information section of the Camp
                            </Box>
                            <Box >
                                App and the Checklist. This is to ensure that we have all the relevant
                                information needed to support the participant and
                            </Box>
                            <Box >
                                create a safe environment for services to be conducted:
                            </Box>
                            <Box >
                                Treat the Provider with courtesy and respect, this includes:
                            </Box>
                            <Box >
                                No smoking, drinking alcohol or taking illicit substances during
                                appointments
                            </Box>
                            <Box >
                                Ensuring pets are kept out of the area that therapy is being conducted
                            </Box>
                            <Box >
                                Disclose any violence in the household and/or restraining orders
                            </Box>
                            <Box >
                                Disclose any violent, aggressive or verbally abusive behaviour of
                                participants prior to therapists beginning services
                            </Box>
                            <Box >
                                Talk to the Provider if the Participant has any concerns about the
                                supports being provided
                            </Box>
                            <Box >
                                Abide by the Cancellation Policy outlined below
                            </Box>
                            <Box >
                                If there are any questions, queries, concerns, changes, developments
                                etc the family should contact the Camp Manager to
                                <br />
                                update them or ask any questions.
                            </Box>
                            <Box >
                                Disclose any medical conditions that their child may have and complete
                                a medical action plan form, or provide one that <br />
                                they currently have.
                            </Box>
                            <Box >
                                Give the Provider the required notice if the Participant needs to end
                                the Service Agreement (see ‘Ending this Service <br />
                                Agreement’ below for more information), and
                            </Box>
                            <Box >
                                Let the Provider know immediately if the Participant’s NDIS plan is
                                suspended or replaced by a new NDIS plan or the <br /> Participant
                                stops being a participant in the NDIS.
                            </Box>
                            <Box>
                                As a registered NDIS provider we are required to be audited by the NDIS Commission. As a participant recipient receiving funding under this scheme you maybe contacted by the commission during our audit period in relation to the services you have received from Calm and Connected.
                            </Box>
                        </Grid>
                        <Grid item md={12} my='0.75rem'>
                            <Box className='bold' >Personal Information</Box>
                            <Box>Personal Information Participant information is securely stored on our Client Management System Echidna and Information Storage System.</Box>
                            <Box>Participants are able to request access to sign into the Family Portal with Echidna. This gives access to documents, appointments and notes as set up in agreement with the therapy team. Participants will be specifically asked for permission to record visual or auditory information and will discuss what this will be used for and how it is stored as the need arises.</Box>
                        </Grid>
                        <Grid item md={12} my='0.75rem'>
                            <Box className='bold' >Cancellation Policy</Box>
                            <Box>Under NDIS Guidelines cancellations with greater than 10 business days notice will be refunded minus an administration fee of $200. If less than 10 business days notice is given to cancel a place on camp we will charge 100% of the full fee.</Box>
                        </Grid>
                        <Grid item md={12} my='0.65rem'>
                            <Box className='bold' >Changes to this Service Agreement</Box>
                            <Box >If changes to this Service Agreement If changes to the supports or their delivery are required, the Parties agree to discuss and review this Service Agreement. The Parties agree that any changes to this Service Agreement will be in writing, signed, and dated by the Parties. </Box>
                            <Box my='0.5rem'>If the provider changes the prices outlined in this service agreement, these changes will be communicated in writing to the Participant/Participants representative with a minimum 1-month notice. Prices will remain in line with NDIS Pricing Arrangements. </Box>
                        </Grid>
                        <Grid item md={12} my='0.65rem'>
                            <Box className='bold' >Ending this Service Agreement</Box>
                            <Box >
                                Should either Party wish to end this Service Agreement they must give a minimum of 14 days notice in writing and all payments claimed/paid for that have been provided until the date of finishing the service agreement.
                            </Box>
                            <Box> If either Party seriously breaches this Service Agreement the requirement of notice will be waived.  Calm and Connected reserves the right to withdraw services if the terms of this agreement are breached, there is a high level of risk, aggression towards staff, or funding package has been exhausted.</Box>
                        </Grid>
                        <Grid item md={12} my='0.65rem'>
                            <Box className='bold' >Feedback, complaints and disputes</Box>
                            <Box>We highly value receiving feedback and welcome any input on how to improve our service. We encourage you to speak directly with your therapist to provide any compliments or criticism, or be in touch with the Director, Kate Keisler with the contact details provided below. Otherwise we have a Customer Feedback Form available online.</Box>
                            <Box>Kate Keisler</Box>
                            <Box> Calm and Connected Director</Box>
                            <Box>0430645086 </Box>
                            <Box>kate.keisler@calmandconnected.com.au </Box>
                            <Box>
                                Every two years we conduct a Client Satisfaction Survey. You will be sent an email to be notified when this is occurring and given the opportunity to opt out if you do not wish to participate. To read our full Feedback and Complaints Policy {" "}
                                <span className='bold'><a href='http://calmandconnected.com.au/complaintspolicy/' target='_blank'> Click Here </a></span>
                                or an Easy Language Guide is available  <span className='bold'><a href='https://calmandconnected.com.au/complaints-and-feedback-policy-guide-easy-language-format/' target='_blank'>Click Here </a></span> .
                            </Box>
                            <Box my='0.65rem'>
                                Calm and Connected support the use and engagement of formal or informal advocates to assist you in providing feedback or making a complaint. Information on available advocacy services can be provided by our team or found on our website <span className='bold'><a href='http://calmandconnected.com.au/customerfeedbackform/' target='_blank'> Click Here </a></span> .
                            </Box>
                            <Box mt='0.65rem'>
                                If the Participant is not satisfied or does not want to talk to someone within the organisation, the Participant can contact the NDIS Commission by calling 1800 035 544, visiting one of their offices in person. You can also visit the NDIS Commission website for details on making a complaint <span className='bold'><a href='https://www.ndiscommission.gov.au/about/making-complaint' target='_blank'> Click Here </a></span>.
                            </Box>
                        </Grid>
                        <Grid item md={12} my='1rem'>
                            <Box className='bold'> Incident Management</Box>
                            <Box>
                                Calm and Connected have an Incident Management System in place in line with the NDIS (Incident and Management and Reportable Incidents) Rules 2018. If an incident occurs the family will be notified and involved in the investigation and determining outcomes. A copy of our Incident Management Policy and Procedures is available to families on request.
                            </Box>
                        </Grid>
                        <Grid item md={12} my='1rem'>
                            <Box className='bold'>Restrictive Practice</Box>
                            <Box>
                                As a registered provider of NDIS, Calm and Connected are required to report on Restrictive Practices to the NDIS Quality and Safeguarding Commission. We will work with your Behaviour Support Practitioner as required to reduce the use of restrictive practice. If you do not have Behaviour Support funding and a restrictive practice becomes evident then we will explain and support you through this process
                            </Box>
                        </Grid>
                        <Grid item md={12} my='1rem'>
                            <Box className='bold'>Goods and services tax (GST) </Box>
                            <Box>
                                For the purposes of GST legislation, the Parties confirm that:
                            </Box>
                            <Box> a supply of supports under this Service Agreement is a supply of one or more of the reasonable and necessary supports specified in the statement included, under subsection 33(2) of the National Disability Insurance Scheme Act 2013 (NDIS Act), in the Participant’s NDIS plan currently in effect under section 37 of the NDIS Act; the Participant’s NDIS plan is expected to remain in effect during the period the supports are provided; and the Participant’s representative will immediately notify the Provider if the Participant’s NDIS Plan is replaced by a new plan or the Participant stops being a participant in the NDIS.
                            </Box>
                        </Grid>
                        <Grid item md={12} my='1rem'>
                            <Box> Contact details </Box>
                            <Box>
                                The Participant’s representative can be contacted on:
                            </Box>
                        </Grid>


                    </Grid>
                    <Box sx={{ color: '#707070' }} className='medium'>
                        <Grid>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableRow align='right'>
                                        <TableCell>Contact Person : </TableCell>
                                        <TableCell>
                                            <TextField
                                                id="standard-basic"
                                                sx={{ width: 300 }}
                                                // label="Camp Name"
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                variant="standard"
                                                className="txt-grey "
                                                name="parentPerName"
                                                value={readData?.parentPerName}
                                            // onChange={(event) => storeInfo(event)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell >
                                                Mobile
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    id="standard-basic"
                                                    sx={{ width: 300 }}
                                                    // label="Camp Name"
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    variant="standard"
                                                    className="txt-grey "
                                                    name="parentPerMobile"
                                                    value={readData?.parentPerMobile}
                                                // onChange={(event) => storeInfo(event)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell >
                                                Email
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    id="standard-basic"
                                                    sx={{ width: 300 }}
                                                    // label="Camp Name"
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    variant="standard"
                                                    className="txt-grey "
                                                    name="parentPerEmail"
                                                    value={readData?.parentPerEmail}
                                                // onChange={(event) => storeInfo(event)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell >
                                                Address
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    id="standard-basic"
                                                    sx={{ width: 300 }}
                                                    // label="Camp Name"
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    variant="standard"
                                                    className="txt-grey "
                                                    name="parentPerAddrress"
                                                    value={readData?.parentPerAddrress}
                                                // onChange={(event) => storeInfo(event)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Box my='1rem'>The Provider can be contacted on</Box>
                        <Grid>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableRow align='right'>
                                        <TableCell>Contact Person : </TableCell>
                                        <TableCell>
                                            <TextField
                                                id="standard-basic"
                                                sx={{ width: 300 }}
                                                // label="Camp Name"
                                                variant="standard"
                                                className="txt-grey "
                                                name="provPerName"
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                value={readData?.provPerName}
                                            // onChange={(event) => storeInfo(event)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell >
                                                Mobile
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    id="standard-basic"
                                                    sx={{ width: 300 }}
                                                    // label="Camp Name"
                                                    variant="standard"
                                                    className="txt-grey "
                                                    name="provPerMobile"
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    value={readData?.provPerMobile}
                                                // onChange={(event) => storeInfo(event)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell >
                                                Email
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    id="standard-basic"
                                                    sx={{ width: 300 }}
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    // label="Camp Name"
                                                    variant="standard"
                                                    className="txt-grey "
                                                    name="provPerEmail"
                                                    value={readData?.provPerEmail}
                                                // onChange={(event) => storeInfo(event)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell >
                                                Address
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    id="standard-basic"
                                                    sx={{ width: 300 }}
                                                    // label="Camp Name"
                                                    variant="standard"
                                                    className="txt-grey "
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    name="provPerAddrress"
                                                    value={readData?.provPerAddrress}
                                                // onChange={(event) => storeInfo(event)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableRow align='right'>
                                <TableCell>Contact Person</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableBody>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell >
                                        Mobile
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell >
                                        Email
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell >
                                        Address
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer> */}
                        </Grid>
                        <Box my='1rem'> Agreement Signatures</Box>
                        <Box>
                            The Parties agree to the terms and conditions of this service agreement
                        </Box>
                        <Box mt='1.75rem'>
                            <Grid container display='flex' my='0.5rem' >
                                <Grid item md={6}>
                                    <TextField
                                        error
                                        // id="outlined-basic"
                                        // label="Parent Name"
                                        // variant="outlined"
                                        variant='standard'
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size="small"
                                        value={readData?.parentPerName}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    {/* <TextField
                                        id="outlined-basic"
                                        label="Parent Name"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size="small"
                                        value={readData?.parentPerName}
                                    /> */}
                                    <TextField
                                        error
                                        // id="outlined-basic"
                                        // label="Enter Date by Parent"
                                        // type='date '
                                        // variant="outlined"
                                        variant='standard'
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size="small"
                                        value={readData && readData.parentPerSubmittedDate}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container display='flex' my='0.5rem' >
                                <Grid item md={6} >
                                    <Box>Name/Signature of [Participant / Participant’s representative]</Box>
                                </Grid>
                                <Grid item md={6} >
                                    <Box>Date</Box>
                                    {/* <Box>Signature of [Participant / Participant’s representative]</Box> */}
                                </Grid>
                            </Grid>
                            {/* <Stack direction='row' spacing={2} alignItems='center' my='1.5rem' >
                                <Box>Date</Box> 
                                <Box>
                                    <TextField
                                        id="outlined-basic"
                                        label="Enter Date by Parent"
                                        // type='date '
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size="small"
                                        value={readData && readData.parentPerSubmittedDate}
                                    />
                                </Box>
                            </Stack> */}
                        </Box>


                        <Box mt='4rem'>
                            <Grid container display='flex' my='0.5rem'>
                                <Grid item md={6} >
                                    <TextField
                                        // id="outlined-basic"
                                        // label="Camp Manager Name"
                                        // variant="outlined"
                                        variant='standard'
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size="small"
                                        value={localStorage.getItem('userName')}
                                    />

                                </Grid>
                                <Grid item md={6} >
                                    {/* <TextField
                                        id="outlined-basic"
                                        label="Camp Manager Name"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size="small"
                                        value={localStorage.getItem('userName')}
                                    /> */}
                                    <TextField
                                        // id="outlined-basic"
                                        // variant="outlined"
                                        variant='standard'
                                        size='small'
                                        // type="date"
                                        // label="Enter Date by Camp Manager"
                                        // sx={{ width: 215, px: '0.25rem' }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        value={readData?.provPerSubmittedDate
                                        }
                                    // onChange={(event) => handleNDISNewInfo(event)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container display='flex' my='0.5rem' >
                                <Grid item md={6} >
                                    <Box>Name /Signature of authorised person from Provider</Box>
                                </Grid>
                                <Grid item md={6} >
                                    <Box>Date</Box>
                                    {/* <Box>Signature of authorised person from Provider</Box> */}
                                </Grid>
                            </Grid>
                            {/* <Stack direction='row' spacing={2} alignItems='center' my='0.75rem'>
                                <Box>Date</Box>
                                <Box>
                                    
                        {console.log(readData&&readData.provPerSubmittedDate)}
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        size='small'
                                        // type="date"
                                        
                                        sx={{ width: 200, px: '0.25rem' }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        value={readData?.provPerSubmittedDate
                                        }                                      
                                    // onChange={(event) => handleNDISNewInfo(event)}
                                    />
                                </Box>
                            </Stack> */}
                        </Box>

                    </Box>
                </div>

            </Grid>



        </Box>
    );
}
