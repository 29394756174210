import React, { useRef, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import image from "./Assets/Logo-Main.png";
import Image from "./Assets/candcimg1.png";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Grid, Stack } from "@mui/material";
import "../App.css";
import { apiService,apiService2 } from "../Services/apiService";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import cameraIcon from "../Assets/Icons/Icons/cameraIcon.png";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";
import { Link } from "react-router-dom";
import "../index.css";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import { FormHelperText } from "@mui/material";
import {
  SERVER_URL,
  SET_EMERGENCY_CONTACT,
  GET_PROFILE,
  UPLOAD_PROFILE_PIC,
  GET_EMERGENCY_CONTACT,
} from "../Services/apiEndpints";
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png";

let rolesName = localStorage.getItem("rolesName");

let rolesArr = [];

if (rolesName === "CAMP MANAGER") {
  rolesArr = [
    {
      // rolesId: 9,
      roles_group: "STAFF",
      rolesName: "Camp Staff",
    },
  ];
} else if (rolesName === "ADMIN" || rolesName === "CAMP ADMIN") {
  rolesArr = [
    {
      // rolesId: 9,
      roles_group: "STAFF",
      rolesName: "Camp Staff",
    },
    {
      // rolesId: 9,
      roles_group: "STAFF",
      rolesName: "Camp Manager",
    },
  ];
}

export default function Registerdata() {
  const history = useHistory();
  const current = new Date();
  const [showPassword, setShowPassword] = useState(false);
  const [getUserData, setGetUserData] = useState({});
  const [profilePath, setProfilePath] = React.useState(null);
  const [viewData, setViewData] = React.useState({});
  const [username, setUserName] = React.useState(null);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [thumbnailFile, setThumbnailFile] = React.useState(null);
  const [contactDetails, setContactDetails] = useState([]);
  let err = 0;
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    rolesName: "",
    name: "",
    contactNo: "",
    address: "",
    secname: "",
    seccontactNo: "",
    secaddress: "",
    emailId: "",
    secemailId: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    rolesName: null,
    name: null,
    contactNo: null,
    address: null,
    secname: null,
    seccontactNo: null,
    secaddress: null,
    emailId: null,
    secemailId: null,
  });
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    let formObj = {};

    formObj = { ...form, [name]: value };

    setForm(formObj);
    validateField(name, formObj[name]);
  };
  const validateField = (name, value) => {
    let errorMsg = null;
    switch (name) {
      case "name":
        if (!value) errorMsg = "Please enter name.";
        break;
      case "emailId":
        if (!value) errorMsg = "Please enter emailId.";
        break;
      case "address":
        if (!value) errorMsg = "Please enter address.";
        break;
        case "contactNo":
          if (!value) errorMsg = "Please enter contact number.";
          else if (!/^[0-9]{9,10}$/.test(value)) {
            errorMsg = "Contact number should be 9 or 10 digits.";
          }
          break;

      default:
        break;
    }

    setFormErrors({ ...formErrors, [name]: errorMsg });
    return errorMsg;
  };
  const validateForm = () => {
    const errors = {};
    Object.keys(form).forEach((name) => {
      const value = form[name];
      const error = validateField(name, value, form.password);
      if (error) {
        errors[name] = error;
      }
    });
    return errors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(form);
    const errors = validateForm();
    setFormErrors(errors); // Update formErrors state with validation errors

    if (Object.keys(errors).length === 0) {
      let body = {};
      const secObj = {
        secname: form.secname,
        seccontactNo: form.seccontactNo,
        secemailId: form.secemailId,
        secaddress: form.secaddress,
      };

      if (checkAtLeastOneKeyHasValue(secObj) === true) {
        if (form.emailId == form.secemailId) {
          Swal.fire({
            icon: "error",
            text: "Primary & Secondary Email can't be same.",
            // text: `${register?.data?.responseData[0][1]?.Desc}`,
            confirmButtonText: "OK",
            confirmButtonColor: "#379592",
          });
          err = 1;
        } else {
          body = {
            emergencyContactDetails: [
              {
                contactType: "PRIMARY",
                name: form.name,
                contactNo: form.contactNo,
                email: form.emailId,
                address: form.address,
              },
              {
                contactType: "SECONDARY",
                name: form.secname,
                contactNo: form.seccontactNo,
                email: form.secemailId,
                address: form.secaddress,
              },
            ],

            userId: localStorage.getItem("userId"),
          };
        }
      } else {
        body = {
          emergencyContactDetails: [
            {
              contactType: "PRIMARY",
              name: form.name,
              contactNo: form.contactNo,
              email: form.emailId,
              address: form.address,
            },
          ],

          userId: localStorage.getItem("userId"),
        };
      }
      if (err == 0) {
        let apiInfo = {
          // url: `${process.env.REACT_APP_API_URL}auth/setemergencycontacts`,
          url: `${SERVER_URL}${SET_EMERGENCY_CONTACT}`,
          method: "POST",
          postData: body,
        };
        let register = await apiService(apiInfo);

        if (register?.data.responseCode === 1) {
          Swal.fire({
            icon: "error",
            text: "Error",
            // text: `${register?.data?.responseData[0][1]?.Desc}`,
            confirmButtonText: "OK",
            confirmButtonColor: "#379592",
          });
        } else {
          Swal.fire({
            iconHtml: `<img src="${GreenCheck}" >`,
            text: `${register?.data?.responseData[0][0]?.Desc}`,
            confirmButtonText: "OK",
            confirmButtonColor: "#379592",
          }).then(() => {
            history.push("/");
          });
        }
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      // setLoading(true)

      let dataSent = {
        // userId: userId,
        userId: localStorage.getItem("userId"),
        email: localStorage.getItem("email"),
      };

      let apiInfo = {
        
        url: `${SERVER_URL}${GET_EMERGENCY_CONTACT}`,
        method: "POST",
        postData: dataSent,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let output = await apiService2(apiInfo);
      if (output.data.responseCode === 0 &&  (output.data['responseData'] && output.data['responseData'][0].length > 0 ) ) {
        setForm({
          ...form,
  
          name:output.data.responseData[0][0]["full_name"],
          contactNo:output.data.responseData[0][0]["mobile_number"],
          emailId:output.data.responseData[0][0]["email"],
          address:output.data.responseData[0][0]["address"],
          secname: (output.data.responseData[0][1] && output.data.responseData[0][1].length > 0) ? output.data.responseData[0][1]["full_name"] : '',
          seccontactNo: (output.data.responseData[0][1] && output.data.responseData[0][1].length > 0) ? output.data.responseData[0][1]["mobile_number"] : '',
          secemailId: (output.data.responseData[0][1] && output.data.responseData[0][1].length > 0) ? output.data.responseData[0][1]["email"] : '',
          secaddress: (output.data.responseData[0][1] && output.data.responseData[0][1].length > 0) ? output.data.responseData[0][1]["address"] : ''



        });
        console.log(form);
        setContactDetails(output.data.responseData[0][0]);
      }
    })();
  }, []);
  function checkAtLeastOneKeyHasValue(obj) {
    return Object.values(obj).some((value) => Boolean(value));
  }
  React.useEffect(() => {
    (async () => {
      getProfileDetails();
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);
  const getProfileDetails = async () => {
    let apiInfo = {
      // url: `${process.env.REACT_APP_API_URL}auth/getprofile`,
      url: `${SERVER_URL}${GET_PROFILE}`,

      method: "POST",
      postData: {
        email: localStorage.getItem("email"),
        userId: localStorage.getItem("userId"),
      },
    };
    let response = await apiService(apiInfo);
    console.log(response.data.responseData[0][0]);
    if (response?.data?.responseCode === 0) {
      let dummy = {
        firstName: response.data.responseData[0][0]["firstName"],
        lastName: response.data.responseData[0][0]["Lastname"],
        email: response.data.responseData[0][0]["email"],
        rolesName: response.data.responseData[0][0]["rolesName"],
      };
      setProfilePath(response.data.responseData[0][0]["profileimage"]);
      // setForm({
      

      //   firstName: response.data.responseData[0][0]["firstName"],
      //   lastName: response.data.responseData[0][0]["Lastname"],
      //   email: response.data.responseData[0][0]["email"],
      //   rolesName: response.data.responseData[0][0]["rolesName"],
      // });
      setViewData(dummy);
      let names = response.data.responseData[0][0]["profileName"];
      setUserName(names);
    }
  };
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };
  async function uploadThumnails(file) {
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (file.size > maxSize) {
        Swal.fire({
            icon: 'error',
            confirmButtonText: "OK",
            confirmButtonColor: "#379592",
            text: 'Please select image size less than 2 MB.',
        });
        return; // Exit the function
    }

    const forminputsTwo = new FormData();
    forminputsTwo.append("image", file);
    forminputsTwo.append("userId", localStorage.getItem("userId"));

    console.log(forminputsTwo, "sjcakdjcbdcj");
    try {
        const response = await axios.post(
            `${SERVER_URL}${UPLOAD_PROFILE_PIC}`,
            forminputsTwo,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    tokenheaderkey: localStorage.getItem("token"),
                },
            }
        );
        console.log(response);
    } catch (error) {
        console.error(error);
    }

  }

  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },

    components: {
      MuiInputLabel: {
        styleOverrides: {
          asterisk: {
            color: "red",
            fontSize: 24,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": {
            fontFamily: "gilroyMedium",
            src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
          },
        },
      },
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file.name);
    const reader = new FileReader();
    setThumbnailFile(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);
    uploadThumnails(event.target.files[0]);
    // read file as data url
    reader.onload = (event) => {
      console.log(event.target.result);
      setProfilePath(event.target.result);
    };
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        container
        direction="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100vh",
          backgroundImage: `url(${Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Grid item>
          <Card sx={{ borderRadius: "20px" }}>
            <CardContent
              sx={{
                mx: "0.5rem",
                maxHeight: "800px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                component="form"
                xs={4}
                md={4}
                sm={6}
                noValidate
                autoComplete="off"
              >
                <form>
                  <Grid container>
                    <Grid item md={4}>
                      <Box sx={{ alignItems: "center" }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Link to={`/Registration`} className="link2">
                            <Box
                              alignSelf="center"
                              component="img"
                              sx={{
                                height: "0.75rem",
                                width: "0.65rem",
                                cursor: "pointer",
                              }}
                              alt="Logo"
                              height="20"
                              src={ArrowBack}
                            ></Box>
                          </Link>
                          <Box sx={{ alignItems: "center", display: "flex " }}>
                            <CardMedia
                              component="img"
                              height="25%"
                              image={image}
                              alt="Logo"
                              sx={{ width: "40%" }}
                            />
                          </Box>
                        </Stack>
                      </Box>
                      <Grid my={1}>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          accept="image/jpeg, image/png"
                        />
                        <Box>
                          {profilePath === null ? (
                            <Box className="main-div">
                              <Box
                                textAlign="center"
                                sx={{
                                  height: "100px",
                                  width: "100px",
                                  borderRadius: "50px",
                                  color: "#fff",
                                  paddingTop: "2rem",
                                  fontSize: "20px",
                                  backgroundColor: "#379592",
                                }}
                                className="bold"
                              >
                                {username}
                              </Box>
                              <Box className="camera-icon">
                                <img onClick={handleClick} src={cameraIcon} />
                              </Box>
                            </Box>
                          ) : (
                            <Box className="main-div">
                              <Box
                                mt="1rem"
                                component="img"
                                src={profilePath}
                                sx={{
                                  height: "100px",
                                  width: "100px",
                                  borderRadius: "50px",
                                }}
                              ></Box>
                              <Box className="camera-icon">
                                <img onClick={handleClick} src={cameraIcon} />
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Grid>

                      <Grid item mt={1}>
                        <TextField
                          id="standard-basic"
                          label="First Name"
                          variant="standard"
                          name="firstName"
                          value={viewData.firstName}
                          // onChange={handleChange}
                          // onBlur={() =>
                          //   validateField("firstName", form.firstName)
                          // }
                          sx={{ width: "80%" }}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item mt={1}>
                        <TextField
                          id="standard-basic"
                          label="Last Name"
                          variant="standard"
                          name="lastName"
                          value={viewData.lastName}
                          // value={form.lastName}
                          // onChange={handleChange}
                          // onBlur={() =>
                          //   validateField("lastName", form.lastName)
                          // }
                          sx={{ width: "80%" }}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item mt={1}>
                        <TextField
                          id="standard-basic"
                          label="Email"
                          variant="standard"
                          name="email"
                          value={viewData.email}
                          // value={form.email}
                          // onChange={handleChange}
                          // onBlur={() => validateField("email", form.email)}
                          sx={{ width: "80%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item mt={1}>
                        <TextField
                          id="standard-basic"
                          label="Role"
                          variant="standard"
                          name="rolesName"
                          value={viewData.rolesName}
                          // value={form.rolesName}
                          // onChange={handleChange}
                          // onBlur={() =>
                          //   validateField("rolesName", form.rolesName)
                          // }
                          sx={{ width: "80%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid md={8}>
                      <Grid sx={{ textAlign: "center" }}>
                        Emergency Contact
                      </Grid>
                      <Grid>Primary Contact</Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <Grid item>
                            <TextField
                              id="standard-basic"
                              label="Name"
                              variant="standard"
                              name="name"
                              sx={{ width: "80%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={form.name}
                              onChange={handleChange}
                              onBlur={() => validateField("name", form.name)}
                            />
                            {formErrors.name && (
                              <FormHelperText error>
                                {formErrors.name}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item mt={1}>
                            <TextField
                              id="standard-basic"
                              label="Address"
                              variant="standard"
                              name="address"
                              sx={{ width: "80%" }}
                              value={form.address}
                              onChange={handleChange}
                              onBlur={() =>
                                validateField("address", form.address)
                              }
                            />
                            {formErrors.address && (
                              <FormHelperText error>
                                {formErrors.address}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item md={6}>
                          <Grid item mr={5}>
                            <TextField
                              id="standard-basic"
                              label="Mobile"
                              variant="standard"
                              name="contactNo"
                              value={form.contactNo}
                              onChange={handleChange}
                              sx={{ width: "80%" }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +61
                                  </InputAdornment>
                                ),
                              }}
                              onBlur={() =>
                                validateField("contactNo", form.contactNo)
                              }
                            />
                            {formErrors.contactNo && (
                              <FormHelperText error>
                                {formErrors.contactNo}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item mt={1}>
                            <TextField
                              id="standard-basic"
                              label="Email"
                              variant="standard"
                              name="emailId"
                              sx={{ width: "80%" }}
                              value={form.emailId}
                              onChange={handleChange}
                              onBlur={() =>
                                validateField("emailId", form.emailId)
                              }
                            />
                            {formErrors.emailId && (
                              <FormHelperText error>
                                {formErrors.emailId}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid mt={2}>Secondary Contact</Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <Grid item>
                            <TextField
                              id="standard-basic"
                              label="Name"
                              name="secname"
                              variant="standard"
                              sx={{ width: "80%" }}
                              value={form.secname}
                              onChange={handleChange}
                              onBlur={() =>
                                validateField("secname", form.secname)
                              }
                            />
                            {formErrors.secname && (
                              <FormHelperText error>
                                {formErrors.secname}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item mt={1}>
                            <TextField
                              id="standard-basic"
                              label="Address"
                              variant="standard"
                              name="secaddress"
                              sx={{ width: "80%" }}
                              value={form.secaddress}
                              onChange={handleChange}
                              onBlur={() =>
                                validateField("secaddress", form.secaddress)
                              }
                            />
                            {formErrors.secaddress && (
                              <FormHelperText error>
                                {formErrors.secaddress}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item md={6}>
                          <Grid item mr={5}>
                            <TextField
                              id="standard-read-only-input"
                              label=" "
                              defaultValue="+ 61"
                              InputProps={{
                                readOnly: true,
                              }}
                              variant="standard"
                              sx={{
                                width: "30px",
                                ".MuiInputBase-input.MuiInput-input": {
                                  color: "#757575",
                                },
                              }}
                            />
                            <TextField
                              id="standard-basic"
                              label="Mobile"
                              variant="standard"
                              name="seccontactNo"
                              value={form.seccontactNo}
                              onChange={handleChange}
                              onBlur={() =>
                                validateField("seccontactNo", form.seccontactNo)
                              }
                              sx={{ width: "80%" }}
                            />
                            {formErrors.seccontactNo && (
                              <FormHelperText error>
                                {formErrors.seccontactNo}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item mt={1}>
                            <TextField
                              id="standard-basic"
                              label="Email"
                              variant="standard"
                              name="secemailId"
                              sx={{ width: "80%" }}
                              value={form.secemailId}
                              onChange={handleChange}
                              onBlur={() =>
                                validateField("secemailId", form.secemailId)
                              }
                            />
                            {formErrors.secemailId && (
                              <FormHelperText error>
                                {formErrors.secemailId}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item mt="5rem">
                        <Button
                          variant="contained"
                          fullWidth
                          type="submit"
                          sx={{
                            px: "1rem",
                            py: "0.5rem",
                            bgcolor: "#379592",
                            textTransform: "none",
                            "&:hover": { background: "#379592" },
                          }}
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
