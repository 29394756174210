import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,

  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import DefaultImage from "../../Assets/Icons/Icons/DefaultImage.png"
import { apiService, apiService2 } from "../../Services/apiService";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png"
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png"
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";
import CrossGreen from "../../Assets/Icons/Icons/CrossGreen.png";
import axios from "axios";
import PdfIcon from "../../Assets/Icons/Icons/PdfIcon.png"
import { VIEW_WELCOMEPACK, EDIT_WELCOMEPACK, SERVER_URL,FILE_UPLOAD } from '../../Services/apiEndpints'
import ThumbnailImage from "../../Assets/Icons/Icons/ThumbnailImage.png"

const rowCount = [1, 2, 3, 4]

export default function WelcomePackEdit() {
  let { campId } = useParams();
  const history = useHistory()
  const [campList, setCampList] = React.useState()
  const [loading, setLoading] = React.useState(false)


  const hiddenFileInputVideo = React.useRef(null);
  const [welcomeVideoFile, setWelcomeVideoFile] = useState(null);
  const [welcomeVideoArray, setWelcomeVideoArray] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  // const [notificationHashPath, setNotificationHashPath] = useState(null);
  const [welcomeVideoHash, setWelcomeVideoHash] = useState([]);

  const hiddenFileImputCombo = React.useRef(null);
  const [combinationArray, setCombinationArray] = useState([]);
  const [combinationHash, setCombinationHash] = useState([]);
  const [comboDescription, setComboDescription] = React.useState(null)

  const hiddenFileInputDocument = React.useRef(null);
  const [documentArray, setDocumentArray] = useState([]);
  const [documentHash, setDocumentHash] = useState([]);

  const [welcomePackData, setWelcomePackData] = React.useState('')
  const [files, setFiles] = React.useState('')

  const [welcome, setWelcome] = useState([])
  const [combo, setCombo] = useState([])
  const [attachment, setAttachment] = useState([])
  const [videoRow, setVideoRow] = React.useState([])

  React.useEffect(
    async () => {
      setLoading(true)
      let inputData = {
        userId: localStorage.getItem("userId"),
        campId: campId,
        // campId: 5198,
        welcomePackType: 'PARENTS'

      };
      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/actcamp/viewwelcomepack",
        url: `${SERVER_URL}${VIEW_WELCOMEPACK}`,
        method: "POST",
        postData: inputData,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      }
      let output = await apiService2(apiInfo)
      console.log(output)
      if (output !== false) {
        let viewOp = output.data.responseData[0][0]
        if (viewOp.videoInfo !== null) {
          viewOp.videoInfo = JSON.parse(viewOp.videoInfo)
          let dummy = viewOp.videoInfo
          console.log(dummy, 'dummmm') 
          setVideoRow(
            viewOp.videoInfo.map((row, index) =>
              ( { ...row, id: index+1 } )
            )
          )
        }
        else {
          setVideoRow([])
        }
        

        setWelcomePackData(viewOp)
        console.log(viewOp)
        setFiles(viewOp.filesInfo)
        let fileData = viewOp.filesInfo


        // fileData.forEach(item => {
        //     console.log(item.documentContent,'hereee')
        //     if(item.documentContent === 'WELCOME_VIDEO'){
        //         setWelcome((prev) => [...prev,item])
        //     }
        //        else if(item.documentContent === 'FILE'){
        //         setAttachment((prev) => [...prev,item])
        //     }
        //     else if(item.documentContent !== 'FILE' && item.documentContent !== 'WELCOME_VIDEO'){
        //         setCombo((prev) => [...prev,item])
        //     }  
        // })
        fileData && fileData.filter((val) => {

          if (val.documentContent === 'WELCOME_VIDEO') {

            setWelcomeVideoArray((attachment) => [...attachment,
            {
              path: val.documentPath,
              fileObj: null,
              docPath: val.documentHash,
              docTitle: val.documentTitle,
              docContent: 'WELCOME_VIDEO'
            }]);
          }

        });
        fileData && fileData.filter((val) => {
          // console.log(val);
          if (val.documentContent !== 'WELCOME_VIDEO' && val.documentContent !== 'FILE') {
            // console.log(val)
            setCombinationArray((attachment) => [...attachment,
            {
              path: val.documentPath,
              fileObj: null,
              docPath: val.documentHash,
              docTitle: val.documentTitle,
              docContent: val.documentContent
            }]);
          }

        });
        fileData && fileData.filter((val) => {
          // console.log(val);
          if (val.documentContent === 'FILE') {
            // console.log(val)
            setDocumentArray((attachment) => [...attachment,
            {
              path: val.documentPath,
              fileObj: null,
              docPath: val.documentHash,
              docTitle: val.documentTitle,
              docContent: val.documentContent
            }]);
          }

        });

      }

    }


    , [])

  console.log(videoRow,'hereeee')
  const SaveWelcomePack = () => {

    Swal.fire({
      text: "Are you sure you want to Save?",
      iconHtml: `<img src="${question}" width="100" height="100" >`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#379592",
      cancelButtonText: "No",
      cancelButtonColor: "#909090",
      customClass: {
        container: "my-swal",
        icon: 'no-border'

      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          iconHtml: `<img src="${GreenCheck}" width="100" height="100" >`,
          text: 'Welcome Pack Saved',
          confirmButtonText: 'OK',
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
          },
        }).then(() => {
          history.push('/WelcomePackRepublish')
        })
      }

    }

    )
  }

  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    palette: {
      secondary: {
        main: "#379592",
      },
    },

    Muidisabled: {
      styleOverrides: {
        backgroundColor: "purple",
      },
    },
  });

  const handlePreInformation = (event) => {
    // setPreInformation(event.target.value)
    setWelcomePackData((prev) => ({ ...prev, PreInformation: event.target.value }))
  }
  // console.log(welcomePackData.PreInformation, 'HERE')

  const handleNotificationFileChange = (event) => {
    // event.preventDefault();
    const numSelectedFiles = event.target.files ? event.target.files.length : 0;
    const numTotalFiles = welcomeVideoArray.length + numSelectedFiles;

    for (const file of event.target.files) {

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // setImgsSrc((imgs) => [...imgs, e.target.result]);
        // setAttachments((paths) => [...paths, file]);
        setWelcomeVideoArray((attachment) => [
          ...attachment,
          {
            path: e.target.result,
            fileObj: file,
            docPath: "",
            docTitle: file.name,
            docContent: 'WELCOME_VIDEO'
          },
        ]);
      };
    }
    // setWelcomeVideoFile(event.target.files[0]);

    // const reader = new FileReader();
    // reader.readAsDataURL(event.target.files[0]);
    // reader.onload = (e) => {
    //   setWelcomeVideoArray([
    //     {
    //       path: e.target.result,
    //       fileObj: event.target.files[0],
    //       docPath: "",
    //       docTitle: event.target.files[0].name
    //     }
    //   ]);
    // };
    // setSelectedImages((previousImages) =>
    //   previousImages.concat(welcomeVideoFile)
    // );
  };

  const uploadNotificationFile = async (event) => {

    event.preventDefault();

    welcomeVideoArray.forEach((file) => {
      if (file.fileObj != null) {
        const forminputs = new FormData();

        forminputs.append("file", file.fileObj);
        forminputs.append("userId", localStorage.getItem("userId"));
        forminputs.append("documentContent", "WELCOME_VIDEO");
        forminputs.append("documentType", "WELCOME_PACK");

        axios
          .post(`${SERVER_URL}${FILE_UPLOAD}`, forminputs, {
            headers: {
              "Content-Type": "multipart/form-data",
              tokenheaderkey: localStorage.getItem("token"),
            },
          })
          .then((response) => {

            setWelcomeVideoHash((paths) => [
              ...paths,
              response["data"]["result"][0][0].documentHash,
            ]);
            setWelcomeVideoArray((attach) => {
              return attach.map((attachment) => {
                if (file.fileObj != null && attachment.fileObj === file.fileObj) {


                  // Update the docPath property for the matching attachment
                  return {
                    ...attachment,
                    docPath: response["data"]["result"][0][0].documentHash,
                  };

                } else {
                  return attachment;
                }

              });

            });
          })
          .catch((error) => {
            // Handle errors
          });
      }
    });

  }

  const removeWelcomeVideoAttachment = (index) => {

    setWelcomeVideoArray([
      ...welcomeVideoArray.slice(0, index),
      ...welcomeVideoArray.slice(index + 1)
    ]);
    hiddenFileInputVideo.current.value = ''

  }


  const handleComboDescription = (event, index) => {
    let local = index
    setCombinationArray((attach) => {

      return attach.map((attachment) => {
        let value = attach.indexOf(attachment)

        if (value === local) {
          return {
            ...attachment,
            docContent: event.target.value,
          };
        } else {
          return attachment;
        }
      });
    });

  }
  const handleCombinationFileChange = (event) => {
    event.preventDefault();
    const numSelectedFiles = event.target.files ? event.target.files.length : 0;
    const numTotalFiles = welcomeVideoArray.length + numSelectedFiles;

    for (const file of event.target.files) {

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // setImgsSrc((imgs) => [...imgs, e.target.result]);
        // setAttachments((paths) => [...paths, file]);
        setCombinationArray((attachment) => [
          ...attachment,
          {
            path: e.target.result,
            fileObj: file,
            docPath: "",
            docTitle: file.name,
            docContent: ''
          },
        ]);
      };
    }
  };
  async function uploadCombinationFile(event) {

    event.preventDefault();

    combinationArray.forEach((file) => {
      if (file.fileObj != null) {
        const forminputs = new FormData();
        forminputs.append("file", file.fileObj);
        forminputs.append("userId", localStorage.getItem("userId"));
        forminputs.append("documentContent", file.docContent);
        forminputs.append("documentType", "WELCOME_PACK");

        axios
          .post(`${SERVER_URL}${FILE_UPLOAD}`, forminputs, {
            headers: {
              "Content-Type": "multipart/form-data",
              tokenheaderkey: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            if (response?.data?.responseCode === 1) {
              console.log('yes')
              Swal.fire({
                icon: 'error',
                // iconHtml: `<img src="${GreenCheck}" >`, 
                text: response?.data?.responseData,
                confirmButtonColor: "#379592",
                customClass: {
                  container: "my-swal",

                },
              })
              setTimeout(() => {
                console.log(combinationArray, file.docTitle); // Log current values for debugging purposes
                setCombinationArray((combinationArray) => {
                  return combinationArray.filter((row) => row.docTitle !== file.docTitle);
                });
              }, 2000);
            }
            else {
              setCombinationHash((paths) => [
                ...paths,
                response["data"]["result"][0][0].documentHash,
              ]);

              setCombinationArray((attach) => {
                return attach.map((attachment) => {
                  if (attachment.fileObj === file.fileObj) {
                    // Update the docPath property for the matching attachment
                    return {
                      ...attachment,
                      docPath: response["data"]["result"][0][0].documentHash,
                    };
                  } else {
                    return attachment;
                  }
                });
              });
            }

          })
          .catch((error) => {
            // Handle errors
          });
      }
    });

  }
  const removeCombinationAttachment = (index) => {

    setCombinationArray([
      ...combinationArray.slice(0, index),
      ...combinationArray.slice(index + 1)
    ]);

    // if(combinationArray ){

    // }
    // hiddenFileImputCombo.current.value = ''

  }


  async function uploadDocuments(event) {

    event.preventDefault();

    documentArray.forEach((file) => {
      console.log(file)
      const forminputs = new FormData();
      if (file.fileObj != null) {
        forminputs.append("file", file.fileObj);
        forminputs.append("userId", localStorage.getItem("userId"));
        forminputs.append("documentContent", "FILE");
        forminputs.append("documentType", "WELCOME_PACK");

        axios
          .post(`${SERVER_URL}${FILE_UPLOAD}`, forminputs, {
            headers: {
              "Content-Type": "multipart/form-data",
              tokenheaderkey: localStorage.getItem("token"),
            },
          })
          .then((response) => {

            if (response?.data?.responseCode === 1) {
              console.log('yes')
              Swal.fire({
                icon: 'error',
                // iconHtml: `<img src="${GreenCheck}" >`, 
                text: response?.data?.responseData,
                confirmButtonColor: "#379592",
                customClass: {
                  container: "my-swal",

                },
              })
              setTimeout(() => {
                console.log(documentArray, file.docTitle); // Log current values for debugging purposes
                setDocumentArray((documentArray) => {
                  return documentArray.filter((row) => row.docTitle !== file.docTitle);
                });
              }, 2000);
            }
            else {
              console.log('booooo')
              setDocumentHash((paths) => [
                ...paths,
                response["data"]["result"][0][0].documentHash,
              ]);
              setDocumentArray((attach) => {
                return attach.map((attachment) => {
                  if (attachment.fileObj === file.fileObj) {
                    // Update the docPath property for the matching attachment
                    return {
                      ...attachment,
                      docPath: response["data"]["result"][0][0].documentHash,
                    };
                  } else {
                    return attachment;
                  }
                });
              });
            }


          }
          )
          .catch((error) => {
            // Handle errors
          });
      }
    });
  }
  const handleDocument = (event) => {
    event.preventDefault();
    const numSelectedFiles = event.target.files ? event.target.files.length : 0;
    const numTotalFiles = welcomeVideoArray.length + numSelectedFiles;

    for (const file of event.target.files) {

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // setImgsSrc((imgs) => [...imgs, e.target.result]);
        // setAttachments((paths) => [...paths, file]);
        setDocumentArray((attachment) => [
          ...attachment,
          {
            path: e.target.result,
            fileObj: file,
            docPath: "",
            docTitle: file.name,
            docContent: 'FILE'
          },
        ]);
      };
    }

  };
  const removeDocument = (index) => {

    setDocumentArray([
      ...documentArray.slice(0, index),
      ...documentArray.slice(index + 1)
    ]);
    // hiddenFileInputVideo.current.value = ''
  }

  const handleSave = (stat) => {
    console.log('finaldata', welcomeVideoArray)
    console.log([...documentArray, ...combinationArray])
    const fileInfo = [];

    const videoPath = [];
    // if (welcomeVideoArray && welcomeVideoArray.length > 0) {
    //   welcomeVideoArray.forEach((attach) => {
    //     console.log(attach, 'herrrrrr')
    //     videoPath.push({ documentHash: attach.docPath, documentContent: attach.docContent })
    //     // videoPath.push(attach.docPath);
    //   });

    // }
    // console.log(videoPath, 'lololol')
    const comboPath = [];
    if (combinationArray && combinationArray.length > 0) {
      combinationArray.forEach((attach) => {
        // comboPath.push(attach.docPath);
        comboPath.push({ documentHash: attach.docPath, documentContent: attach.docContent })
      });
    }
    const documentPath = [];
    if (documentArray && documentArray.length > 0) {
      documentArray.forEach((attach) => {
        // documentPath.push(attach.docPath);
        documentPath.push({ documentHash: attach.docPath, documentContent: attach.docContent })
      });
    }

    const totalFiles = [...comboPath, ...documentPath];
    // const totalFiles = [...videoPath]
    if (totalFiles && totalFiles.length > 0) {
      totalFiles.forEach((file) => {
        // console.log(file)   only hash is coming
        // fileInfo.push({ documentKey: "WELCOME_PACK", documentHash: file });
        fileInfo.push({ ...file, documentKey: "WELCOME_PACK" })
      });
    }
    console.log(videoRow, 'finallll')
    if (stat === 'DRAFT') {
      (async () => {
        console.log(stat, 'whyyyyyyy')
        let finalData = {
          userId: localStorage.getItem('userId'),
          campId: campId,
          welcomePackType: "PARENTS",
          PreInformation: welcomePackData.PreInformation,
          wpStatus: stat,
          filesInfo: fileInfo,
          welcomePackId: welcomePackData.welcomePackId,
          videoInfo: videoRow
        }
        console.log(finalData)
        let apiInfo = {
          // url: "https://cncdevapi.natsoft.io/api/actcamp/editwelcomepack",
          url: `${SERVER_URL}${EDIT_WELCOMEPACK}`,
          method: "POST",
          postData: finalData,
          headers: {
            "Content-Type": "application/json",
            tokenheaderkey: localStorage.getItem("token"),
          },
        };
        let output = await apiService2(apiInfo)
        let data = output.data.responseData[0][0]
        console.log(output)
        console.log(output.data.responseData[0])
        console.log(output.data.responseData[0][0])
        console.log(output.data.responseData[0][0].Code)
        if (output.data.responseData[0][0].Code === '0') {

          Swal.fire({
            iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
            text: data.Desc,
            confirmButtonText: 'OK',
            confirmButtonColor: "#379592",
            customClass: {
              container: "my-swal",

            },
          }).then(() => {
            history.push(`/WelcomePackRepublish/${campId}`)
            // history.push("/WelcomePackRepublish/5198")
          })
        }
        else {
          Swal.fire({
            icon: 'error',
            text: data.Desc,
            confirmButtonText: 'OK',
            confirmButtonColor: "#379592",
            customClass: {
              container: "my-swal",

            },
          }).then(() => {
            history.push(`/WelcomePackRepublish/${campId}`)
            // history.push("/WelcomePackRepublish/5198")
          })
        }
      })()
    }
    else if (stat === 'PUBLISHED') {
      Swal.fire({
        iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
        text: "Are you sure you want to Submit?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#379592",
        cancelButtonText: "No",
        cancelButtonColor: "#909090",
        customClass: {
          container: "my-swal",
          icon: 'no-border'

          //    confirmButton: 'your-confirm-button-class',
        },
      }).then((result) => {
        if (result.isConfirmed && stat) {
          (async () => {
            console.log(stat, 'whyyyyyyy')
            let finalData = {
              userId: localStorage.getItem('userId'),
              campId: campId,
              welcomePackType: "PARENTS",
              PreInformation: welcomePackData.PreInformation,
              wpStatus: stat,
              filesInfo: fileInfo,
              welcomePackId: welcomePackData.welcomePackId,
              videoInfo: videoRow
            }
            console.log(finalData)
            let apiInfo = {
              // url: "https://cncdevapi.natsoft.io/api/actcamp/editwelcomepack",
              url: `${SERVER_URL}${EDIT_WELCOMEPACK}`,
              method: "POST",
              postData: finalData,
              headers: {
                "Content-Type": "application/json",
                tokenheaderkey: localStorage.getItem("token"),
              },
            };
            let output = await apiService2(apiInfo)
            let data = output.data.responseData[0][0]
            console.log(output)
            console.log(output.data.responseData[0])
            console.log(output.data.responseData[0][0])
            console.log(output.data.responseData[0][0].Code)
            if (output.data.responseData[0][0].Code === '0') {

              Swal.fire({
                iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
                text: data.Desc,
                confirmButtonText: 'OK',
                confirmButtonColor: "#379592",
                customClass: {
                  container: "my-swal",

                },
              }).then(() => {
                history.push(`/WelcomePackRepublish/${campId}`)
                // history.push("/WelcomePackRepublish/5198")
              })
            }
            else {
              Swal.fire({
                icon: 'error',
                text: data.Desc,
                confirmButtonText: 'OK',
                confirmButtonColor: "#379592",
                customClass: {
                  container: "my-swal",

                },
              }).then(() => {
                history.push(`/WelcomePackRepublish/${campId}`)
                // history.push("/WelcomePackRepublish/5198")
              })
            }
          })()
        }
      })
    }

  }
  const openVideo = (path) => {
    // const videoUrl = "https://cncdevapi.natsoft.io/download/126c716a-012b-4e1e-8ddf-cdaad783ebb6.mp4";
    const videoUrl = path
    const videoWindow = window.open("", "_blank");
    videoWindow.document.write('<video src="' + videoUrl + '" autoplay controls></video>');
    videoWindow.document.close();
  }

  const handleVideoRow = () => {
    setVideoRow([
      ...videoRow,
      {
        id: videoRow.length + 1,
        actualUrl: '',
        description: '',
        thumbnailurl: '',
        videoType: 'WELCOME_PACK',
        videoProvider: 'vimeo'

      },
    ]);
  };

  //   const generateThumbnail = async (e, batch) => {
  //     console.log(e.target.value)
  //     let videoUrl = e.target.value
  //     setVideoRow(
  //       videoRow.map((row, index) =>
  //         index + 1 === batch.id ? { ...row, actualUrl: e.target.value } : row
  //       )
  //     )
  //     console.log("Got video URL", e.target.value, batch)
  //     let apiUrl = `https://vimeo.com/api/oembed.json?url=${videoUrl}`
  //     let res = await axios({
  //       url: apiUrl,
  //       method: 'get',
  //       timeout: 8000,
  //       headers: {
  //         'Content-Type': 'application/json',
  //       }
  //     })

  //     console.log("Resppp", res)
  //     if (res.status == 200)
  //       // setThumbnailUrl(res.data.thumbnail_url_with_play_button)
  // {
  //   setVideoRow(
  //     videoRow.map((row, index) =>
  //       index + 1 === batch.id ? { ...row, thumbnailurl: res.data.thumbnail_url_with_play_button, actualUrl: e.target.value } : row
  //     )
  //   )
  // }



  //   }
  const generateThumbnail = (e, batch) => {

    (async () => {
      console.log(batch, 'oooooooo')
      let videoUrl = e.target.value
      setVideoRow(
        videoRow.map((row, index) =>
          index + 1 === batch.id ? { ...row, actualUrl: e.target.value } : row
        )
      )
      let apiUrl = `https://vimeo.com/api/oembed.json?url=${videoUrl}`
      let res = await axios({
        url: apiUrl,
        method: 'get',
        timeout: 8000,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      console.log(res)

      if (res.status == 200)
        setVideoRow(
          videoRow.map((row, index) =>
            index + 1 === batch.id ? { ...row, thumbnailurl: res.data.thumbnail_url_with_play_button, actualUrl: e.target.value } : row
          )
        )
    })()
      .catch(() => {
        console.log('wohoooooooooo')
        setVideoRow(
          videoRow.map((row, index) =>
            index + 1 === batch.id ? { ...row, thumbnailurl: ThumbnailImage, actualUrl:  e.target.value } : row
          )
        )
       
      })
      .finally(() => {
        // setLoading(false);
      });

  };
  const handleDesc = (e, item) => {
    setVideoRow(
      videoRow.map((row, index) =>
        index + 1 === item.id ? { ...row, description: e.target.value } : row
      )
    )
  }
  const removeUrlItem = (index) => {

    setVideoRow([
      ...videoRow.slice(0, index),
      ...videoRow.slice(index + 1)
    ]);


  }
  // console.log(videoRow,'elelelelelelel')
  const generateStatic = (e,batch) =>{
    setVideoRow(
      videoRow.map((row, index) =>
        index + 1 === batch.id ? { ...row, actualUrl: e.target.value,thumbnailurl: ThumbnailImage} : row
      )
    )
  }
  return (
    <Box>
      <Grid container display='row' >
        <Grid item md={6} sm={12} xs={12}>
          <Stack direction='row' spacing={1}>
            <Link to={`/WelcomePackRepublish/${campId}`}>
              <Box
                component="img"
                sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
                height="20"
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>
            <Box className="gilroy" alignSelf="center">
              Edit Welcome Pack for Parents
            </Box>
          </Stack>
        </Grid>
        {/* <Grid container item md={6} sm={12} xs={12} justifyContent='flex-end'>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={{
                            mx: "0.75rem",
                            bgcolor: "#379592",
                            textTransform: "none",
                            "&:hover": { background: "#379592" },
                            width: '90px',
                        }}
                        className="gilroy"
                        onClick={() => SaveWelcomePack()}
                    >
                        Save
                    </Button>
                    
                </Grid> */}

      </Grid>

      <Box my='1rem'>
        <TextField
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={4}
          name="Pre-information"
          sx={{ width: '50%' }}
          value={welcomePackData.PreInformation}
          onChange={(event) => handlePreInformation(event)}
          InputLabelProps={{
            shrink: true,
          }}
        // onChange={(event) => storeInfo(event)}

        // defaultValue="Default Value"
        />

      </Box>
      {/* <Box my='1rem'>
        <Box>
          Upload Welcome Video
        </Box>
        <Grid>

          <form onSubmit={uploadNotificationFile} encType="multipart/form-data">
            <Button
              onClick={(event) => (hiddenFileInputVideo.current.click(), event.currentTarget.value = null)}
              //     (event)=> { 
              //       event.currentTarget.value = null
              //  }
              variant="contained"
              // type="submit"
              mx="0rem"
              sx={{
                px: "2rem",
                bgcolor: "#379592",
                my: "0.45rem",
                // width:"15%",
                textTransform: "none",
                "&:hover": { background: "#379592" },
              }}
              className="gilroy"
            >
              Choose File
            </Button>
            <Box>
              <input
                type="file"
                multiple
                style={{ display: "none" }}
                placeholder=""
                ref={hiddenFileInputVideo}
                onChange={handleNotificationFileChange}
                // accept="image/jpeg, image/png"
                accept="video/*"
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              mx="0rem"
              sx={{
                px: "2rem",
                bgcolor: "#379592",
                my: "0.75rem",
                textTransform: "none",
                width: '138px',
                "&:hover": { background: "#379592" },
              }}
              className="gilroy"
            >
              Upload
            </Button>
          </form>
        </Grid>
        <Box sx={{ color: '#0075CE' }}>

          {welcomeVideoArray.map((link, index) => {
            return (
              //   <span className="thumbnail-span">
              //     {link.path != null ? (
              //       <Box sx={{ color: '#0075CE' }}>

              //         <a href={link.path} target="_blank">
              //           {link.docTitle}
              //         </a>
              //       </Box>
              //     ) : (
              //       ""
              //     )}
              //     {link.path != null ? (
              //       <img
              //         src={CrossGreen}
              //         onClick={() => removeWelcomeVideoAttachment(index)}
              //         className="cross-img"
              //       />
              //     ) : (
              //       ""
              //     )}
              //  </span>
              //    <span className="thumbnail-span">
              //    {link.path != null ? (
              //      <Grid container display='flex'>
              //        <Grid item md={4}>
              //          <Box> 
              //            <Box onClick={() => openVideo(link.path)}>
              //            <video style={{ width: "50%", height: "50%" }}>
              //              <source src={link.path} type="video/mp4" />
              //              <source src={link.path} type="video/ogg" />
              //            </video>
              //            </Box>
              //          </Box>
              //        </Grid>
              //      </Grid>
              //    ) : (
              //      ""
              //    )}

              //    {link.path != null ? (
              //      <img
              //        src={CrossGreen}
              //        onClick={() => removeWelcomeVideoAttachment(index)}
              //        className="cross-img"
              //      />
              //    ) : (
              //      ""
              //    )}
              //  </span>
              <div>
                {link.path != null ? (

                  <Grid container display='flex' my='0.5rem' className="thumbnail-span" >

                    <Grid item md={3}>
                      <Box onClick={() => openVideo(link.path)} sx={{ cursor: 'pointer' }} >
                        <video style={{ width: "100%", height: "100%" }}>
                          <source src={link.path} type="video/mp4" />
                          <source src={link.path} type="video/ogg" />
                        </video>
                        <Box>{link.docPath !== '' ? ('Uploaded') : ('Not Uploaded')}</Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <img
                        src={CrossGreen}
                        onClick={() => removeWelcomeVideoAttachment(index)}
                        className="cross-pdf"
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </div>
            )

          })}
        </Box>
      </Box> */}

      <Box py='1.5rem'><Divider /></Box>
      <Box >Welcome Videos</Box>
      <Button
        // type="submit"
        variant="contained"
        mx="0rem"
        sx={{
          px: "2rem",
          bgcolor: "#379592",
          my: "0.75rem",
          textTransform: "none",
          width: "138px",
          "&:hover": { background: "#379592" },
        }}
        onClick={handleVideoRow}
        className="gilroy"
      >Add</Button>

      {videoRow && videoRow.length > 0 && videoRow.map((item, index) => {
        return (
          // <span className="thumbnail-span">
          <div  >
            {/* {item.actualUrl != null ? ( */}
            <div key={index} className="thumbnail-container">
              <Grid container display='row' alignItems='center' >
                <Grid item md={4} sx={{ textAlign: 'center' }}>

                  <TextField
                    id="outlined-basic"
                    multiline
                    label="Vimeo URL"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={item.actualUrl}
                    onChangeCapture={(e) => { generateThumbnail(e, item) }}
                    // onChange={(e) => { handleDesc(e, item) }}

                    size='small'
                    sx={{ my: '0.5rem', width: '90%' }}
                  >

                  </TextField>

                  <Box sx={{ textAlign: 'center' }}>
                    {item.thumbnailurl !== '' ?
                      (
                        <a href={item.actualUrl} target='_blank'>
                          <img
                            src={item.thumbnailurl}

                            style={{ height: "45%", width: "50%", my: '0.5rem' }}
                          />
                        </a>
                      ) : ('')}



                  </Box>

                </Grid>

                <Grid item md={8} >
                  <Stack direction='row' >
                    <Box sx={{ width: '100%' }} >
                      <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        rows={5}
                        name="description"
                        onChange={(e) => { handleDesc(e, item) }}
                        value={item.description}
                        // onChange={(event) => handleComboDescription(event, index)}
                        sx={{ width: '100%', borderRadius: '20px' }}

                      />
                    </Box>
                    < div className="video-thumb">

                      <img
                        src={CrossGreen}
                        onClick={() => removeUrlItem(index)}
                      // className="video-thumb"
                      />

                    </div>
                  </Stack>

                </Grid>
              </Grid>
            </div>


            {/* ) : (
                  ""
                )} */}

            {/* {item.actualUrl != null ? ( */}
            {/* < Box>

                    <img
                      src={CrossGreen}
                      onClick={() => removeUrlItem(index)}
                      className="video-thumb"
                    />

                  </Box> */}

            {/* ) : (
                  ""
                )} */}
          </div>

        )
      })}
      <Box py='1.5rem'><Divider /></Box>
      <Box >
        Photos
      </Box>
      <Grid>

        <form onSubmit={uploadCombinationFile} encType="multipart/form-data">
          <Button
            onClick={(event) => (hiddenFileImputCombo.current.click(), event.currentTarget.value = null)}
            //     (event)=> { 
            //       event.currentTarget.value = null
            //  }
            variant="contained"
            // type="submit"
            mx="0rem"
            sx={{
              px: "2rem",
              bgcolor: "#379592",
              my: "0.45rem",
              // width:"15%",
              textTransform: "none",
              "&:hover": { background: "#379592" },
            }}
            className="gilroy"
          >
            Choose File
          </Button>
          <Box>
            <input
              type="file"
              multiple
              style={{ display: "none" }}
              placeholder=""
              ref={hiddenFileImputCombo}
              onChange={handleCombinationFileChange}
              accept="image/jpeg, image/png,video/*"
            // accept="video/*"
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            mx="0rem"
            sx={{
              px: "2rem",
              bgcolor: "#379592",
              my: "0.75rem",
              textTransform: "none",
              width: '138px',
              "&:hover": { background: "#379592" },
            }}
            className="gilroy"
          >
            Upload
          </Button>
        </form>
      </Grid>
      <Box sx={{ color: '#0075CE' }}>
        {combinationArray.map((link, index) => {
          let x = (link.docTitle).search('mp4') || (link.docTitle).search('ogg')
          // { console.log(x, 'hereeee') }
          return (
            <span className="thumbnail-span" key={index}>
              {link.path != null ? (
                // <Stack direction='row' spacing={2} >
                //   <Box sx={{ color: '#0075CE' }}>
                //   <img
                //     src={link.path}
                //     style={{ width: "5rem" ,height:'100%',border:'1px solid black',borderRadius:'20px'}}
                //   />
                //   </Box>
                //   <Box sx={{width:'100%'}} >
                //   <TextField
                //     id="outlined-multiline-static"
                //     label="Description"
                //     multiline
                //     rows={4}
                //     name="description"
                //   // value={formData.description}
                //   onChange={(event) => handlePreInformation(event)}
                // sx={{width:'100%'}}
                //   // defaultValue="Default Value"
                //   />
                //   </Box>

                // </Stack>
                <Box>
                  <Grid container display='row' alignItems='center' my='0.5rem'>
                    <Grid item md={4}>
                      {x !== -1 ?
                        (<Box onClick={() => openVideo(link.path)} sx={{ cursor: 'pointer' }}>
                          <video style={{ width: "80%", height: "140px" }}>
                            <source src={link.path} type="video/mp4" />
                            <source src={link.path} type="video/ogg" />
                          </video>
                        </Box>) :
                        (<Box sx={{ color: '#0075CE' }}>
                          <img
                            src={link.path}
                            style={{ width: "80%", height: '140px', borderRadius: '20px' , alignSelf: 'center' }}
                          />
                        </Box>)}

                    </Grid>
                    <Grid item md={8}>
                      <Box sx={{ width: '100%' }} >
                        <TextField
                          id="outlined-multiline-static"
                          label="Description"
                          multiline
                          rows={5}
                          name="description"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={link.docContent}
                          onChange={(event) => handleComboDescription(event, index)}
                          sx={{ width: '100%', borderRadius: '20px' }}
                        // defaultValue="Default Value"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>{link.docPath !== '' ? ('Uploaded') : ('Not Uploaded')}</Box>
                </Box>

              ) : (
                ""
              )}

              {link.path != null ? (
                <Box>

                  <img
                    src={CrossGreen}
                    onClick={() => removeCombinationAttachment(index)}
                    className="cross-combo"
                  />

                </Box>

              ) : (
                ""
              )}
            </span>
          )
        })}
        {/* <span className="thumbnail-span">
          {welcomeVideoArray != null ? (
            <a href={welcomeVideoArray[0].path} target="_blank">
              {welcomeVideoArray[0].docTitle}
            </a>
          ) : (
            ""
          )}
          {welcomeVideoArray != null ? (
            <img
              src={CrossGreen}
            onClick={removeimg}
              className="cross-img"
            />
          ) : (
            ""
          )}
        </span> */}
        {/* <span className="thumbnail-span">
              {welcomeVideoArray != null ? (
                <img
                  src={welcomeVideoArray.path}

                  style={{ height: "20%", width: "20%", marginTop: "0.5%" }}
                />
              ) : (
                ""
              )}

              {welcomeVideoArray != null ? (
                <img
                  src={CrossGreen}
                  onClick={removeimg}
                  className="cross-img"


                />
              ) : (
                ""
              )}
            </span> */}
      </Box>
      <Box py='1.5rem'><Divider /></Box>
      <Box >
        Documents
      </Box>
      <Grid>

        <form onSubmit={uploadDocuments} encType="multipart/form-data">
          <Button
            onClick={(event) => (hiddenFileInputDocument.current.click(), event.currentTarget.value = null)}
            //     (event)=> { 
            //       event.currentTarget.value = null
            //  }
            variant="contained"
            // type="submit"
            mx="0rem"
            sx={{
              px: "2rem",
              bgcolor: "#379592",
              my: "0.45rem",
              // width:"15%",
              textTransform: "none",
              "&:hover": { background: "#379592" },
            }}
            className="gilroy"
          >
            Choose File
          </Button>
          <Box>
            <input
              type="file"
              multiple
              style={{ display: "none" }}
              placeholder=""
              ref={hiddenFileInputDocument}
              onChange={handleDocument}
              accept='.doc,.docx,application/pdf'

            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            mx="0rem"
            sx={{
              px: "2rem",
              bgcolor: "#379592",
              my: "0.75rem",
              textTransform: "none",
              width: '138px',
              "&:hover": { background: "#379592" },
            }}
            className="gilroy"
          >
            Upload
          </Button>
        </form>
      </Grid>
      <Box sx={{ color: '#0075CE' }}>
        {documentArray.map((link, index) => {
          return (
            // <span className="thumbnail-span">
            //   {link.path != null ? (

            //     <Grid container display='flex' my='0.5rem'>
            //           <Box
            //             component="img"
            //             sx={{ height: "2%", width: "2%", mx: '0.5rem' }}

            //             alt="Logo"
            //             src={PdfIcon}
            //           ></Box>
            //           <a href={link.path} target="_blank">
            //             {link.docTitle}
            //           </a>

            //         </Grid>
            //   ) : (
            //     ""
            //   )}

            //   {link.path != null ? (
            //     <img
            //       src={CrossGreen}
            //       onClick={() => removeDocument(index)}
            //       // className="cross-img"
            //       className='cross-pdf'
            //     />
            //   ) : (
            //     ""
            //   )}
            // </span>
            <div>
              {link.path != null ? (
                <Box>
                  <Grid container display='flex' my='0.5rem'  >
                    <Grid item alignItems='center' >
                      <Stack direction='row' alignItems='center'  >
                        <Box
                          component="img"
                          sx={{ height: "52%", mx: '0.5rem', alignSelf: 'center' }}

                          alt="Logo"
                          src={PdfIcon}
                        ></Box>
                        <a href={link.path} target="_blank">
                          {link.docTitle}
                        </a>
                      </Stack>

                    </Grid>
                    <Grid item>
                      <img
                        src={CrossGreen}
                        onClick={() => removeDocument(index)}
                        style={{ height: '15px' }}
                      // className="cross-img"
                      // className='cross-pdf'
                      />
                    </Grid>
                  </Grid>
                  <Box>{link.docPath !== '' ? ('Uploaded') : ('Not Uploaded')}</Box>
                </Box>

              ) : (
                ""
              )}
            </div>


          )

        })}

      </Box>
      <Grid container justifyContent='flex-end' >
        <Stack direction='row' >
          <Stack direction='column' >
            {welcomePackData.createdDate !== null && welcomePackData && welcomePackData.createdBy !== null
              ? (
                <Box className='gilroy' sx={{ fontSize: '10px', maxWidth: '120px' }} ml="1rem" my='0.5rem'>
                  Welcome Pack last published on {welcomePackData && welcomePackData.createdDate} by {welcomePackData && welcomePackData.createdBy}
                </Box>
              )
              : ('')}


            <Button
              variant="contained"
              sx={{
                px: "2rem",
                mx: "1rem",
                bgcolor: "#4C9F70",
                textTransform: "none",
                "&:hover": { background: "#4C9F70" },
                width: '100px'
              }}
              className="gilroy"
              onClick={() => handleSave('PUBLISHED')}
            >
              Publish
            </Button>
          </Stack>

          <Button
            variant="contained"
            sx={{
              px: "2rem",
              mx: "1rem",
              bgcolor: "#379592",
              textTransform: "none",
              "&:hover": { background: "#379592" },
              width: '100px',
              alignSelf: 'flex-end'
            }}
            className="gilroy"
            onClick={() => handleSave('DRAFT')}
          >
            Save
          </Button>

          {/* <Link className='link2' to={`/ApplicantsReview/${campId}`}> */}
          <Button
            variant="contained"
            sx={{
              px: "2rem",
              mx: "1rem",
              bgcolor: "#909090",
              textTransform: "none",
              "&:hover": { background: "#909090" },
              width: '100px',
              alignSelf: 'flex-end'
            }}
            className="gilroy"
            onClick={() => history.push(`/ApplicantsReview/${campId}`)}
          >
            Cancel
          </Button>
          {/* </Link> */}
        </Stack>

      </Grid>


    </Box>

  );
}


