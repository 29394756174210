import React, { useState } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import "../../index.css";
import Grid from "@mui/material/Grid";
import { apiService2, apiService } from "../../Services/apiService";
import { GET_INCIDENT_LIST, SERVER_URL } from "../../Services/apiEndpints";

import Swal from "sweetalert2";
import { CssBaseline } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";

import Button from "@mui/material/Button";

import InputBase from "@mui/material/InputBase";

import { Link ,useHistory} from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";

import UpDown from "../../Assets/Icons/Icons/Updown.png";

import CircularProgress from "@mui/material/CircularProgress";
const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  palette: {
    secondary: {
      main: "#379592",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
  Muidisabled: {
    styleOverrides: {
      backgroundColor: "purple",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function IncidentReport() {
  const [incidenReportList, setincidenReportList] = useState([]);
  const [userRole, setUserRole] = useState([]);
  let history = useHistory()

  React.useEffect(() => {
    (async () => {
      setUserRole(localStorage.getItem("rolesName"));

      const body = {
        userId: localStorage.getItem("userId"),
      };

      let apiInfo = {
        url: `${SERVER_URL}${GET_INCIDENT_LIST}`,
        method: "POST",
        postData: body,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let reportsData = await apiService2(apiInfo);
      const list = reportsData.data.responseData[0];
      // debugger
      list.forEach((val) => {
        console.log(val);
        // debugger
        if (val && val["typeOfIncident"]) {
          val["typeOfIncident"] = JSON.parse(val.typeOfIncident);
        }
        //
      });

      setincidenReportList(list);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);
  
  const handleRouting = () =>{
    if((localStorage.getItem("rolesName") === 'CAMP MANAGER') || (localStorage.getItem("rolesName") === 'CAMP ADMIN') || (localStorage.getItem("rolesName") === 'ADMIN')){
  history.push('/ReportsForCampManager')
    }
    else if((localStorage.getItem("rolesName") === 'CAMP STAFF') || (localStorage.getItem("rolesName") === 'THERAPIST') || (localStorage.getItem("rolesName") === 'MEDICATION MANAGER') ){
      history.push('/reports')
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        // px="1.5rem"
      >
        {/* <Grid item  direction='flex'> */}
        <Grid item>
          <Grid container direction="flex">
            <Stack
              direction="row"
              spacing={1}
              xs={12}
              sm={12}
              md={6}
              // mr="4rem"
              alignSelf="center"
            >
              {/* <Link to="/reports"> */}
                <Box
                  // mt="0.65rem"
                  alignSelf="center"
                  component="img"
                  sx={{
                    height: "0.75rem",
                    width: "0.65rem",
                    cursor: "pointer",
                  }}
                  height="20"
                  alt="Logo"
                  src={ArrowBack}
                  onClick={()=>{handleRouting()}}
                ></Box>
              {/* </Link> */}
              <Box className="bold" alignSelf="center">
                Incident Reports
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Grid item>
         
            <Link to="/createIncidentReport">
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#379592",
                  textTransform: "none",
                  "&:hover": { background: "#379592" },
                }}
              >
                Create Incident
              </Button>
            </Link>
          </Grid>
       
      </Grid>

      <Box mt="2rem">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="customized table" my="1rem">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Camp Name</StyledTableCell>
                <StyledTableCell align="center">Incident ID</StyledTableCell>
                <StyledTableCell align="center">Incident Date</StyledTableCell>
                <StyledTableCell align="center">Incident Type</StyledTableCell>
                <StyledTableCell align="center">
                  Incident Rating
                </StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {incidenReportList.map(
                (
                  {
                    campName,
                    campId,
                    incidentId,
                    dateOfIncident,
                    typeOfIncident,
                    incidentRating,
                  },
                  index
                ) => (
                  <TableRow key={incidentId}>
                    <TableCell align="center">{campName}</TableCell>
                    <TableCell align="center">{incidentId}</TableCell>
                    <TableCell align="center">{dateOfIncident}</TableCell>
                    <TableCell align="center">
                      {console.log(typeOfIncident)}
                      {/* Render each type of incident */}
                      {typeOfIncident.map((incident, index) => (
                        <span key={index}>
                          {incident.Key}
                          {index < typeOfIncident.length - 1 && ", "}
                        </span>
                      ))}
                    </TableCell>
                    <TableCell align="center">{incidentRating}</TableCell>
                    <TableCell align="center">
                      <Box
                        className="gilroy"
                        alignSelf="center"
                        sx={{ fontSize: "1.2rem" }}
                      >
                        {userRole && userRole === "CAMP MANAGER" ? (
                           <Link
                           to={`/CampMangerViewIncidentReport/${incidentId}/${campId}`}
                         >
                           <Button
                             variant="contained"
                             sx={{
                               color: "#fff",
                               bgcolor: "#718F94",
                               textTransform: "none",
                               "&:hover": { background: "#718F94" },
                               fontFamily: "gilroy",
                               mx: "0.5rem",
                             }}
                             onClick={()=>{localStorage.setItem('incidentRoute','incident')}}
                           >
                             View
                           </Button>
                         </Link>
                        ) : (
                          <Link
                            to={`/viewIncidentReport/${incidentId}/${campId}`}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                color: "#fff",
                                bgcolor: "#718F94",
                                textTransform: "none",
                                "&:hover": { background: "#718F94" },
                                fontFamily: "gilroy",
                                mx: "0.5rem",
                              }}
                              onClick={()=>{localStorage.setItem('incidentRoute','incident')}}
                            >
                              View
                            </Button>
                          </Link>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {groupError === true ? (
              <Typography color="error">Group info is missing</Typography>
            ) : (
              ""
            )} */}
      </Box>
    </ThemeProvider>
  );
}
