import React, { useState } from "react";
import Button from "@mui/material/Button";

import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import { apiService, apiService2 } from "../../Services/apiService";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Radiobtnno from "../../Assets/Icons/Icons/Radiobtnno.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radiobtnyes from "../../Assets/Icons/Icons/Radiobtnyes.png";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import DefaultImage from "../../Assets/Icons/Icons/DefaultImage.png";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import "../../index.css";
import RejectedPdf from "../../Assets/Icons/Icons/RejectedPdf.png";
import { REVIEWSTAFF_APPLICATION, GETCAMP_APPLICANTSCOMMENTS, SERVER_URL, GETSTAFF_CAMPAPPLICATION,US8_URL } from '../../Services/apiEndpints'

const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const rows = [
  { name: "James Jordon", remarks: "Discussion Required" },
  { name: "", remarks: "" },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "10px",
  // boxShadow: 24,
  p: 4,
};

const styleSuitability = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "30px",
  // boxShadow: 24,
  p: 4,
};

export default function SupportStaffApplicationReview() {
  const [preScreenSelected, setPreScreenSelected] = React.useState(true);
  const [notSuitableRemarks, setNotSuitableRemarks] = React.useState("");
  const [supportStaffDetails, setSupportStaffDetails] = React.useState();
  const [sendRemarks, setSendRemarks] = useState("");
  const [getComments, setGetComments] = React.useState("");

  const [isConditionMet, setIsConditionMet] = useState(true);
  const [pastDetails, setPastDetails] = useState([]);

  // const handleCloseSuitability = () => {
  //   setOpenSuitability(false);
  // };
  let { campId, staffApplicationId, staffId } = useParams();
  const handleCloseNotSuitable = () => {
    setOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const handleOpenSupportNotSuitable = () => {
    setOpen(true);
  };
  const handleCloseSupportNotSuitable = () => {
    setOpen(false);
  };

  const [openSuitabilty, setOpenSuitability] = React.useState(false);
  const handleOpenSupportSuitable = () => {
    setOpenSuitability(true);
  };
  const handleCloseSupportSuitable = () => {
    setOpenSuitability(false);
  };

  const handleSwal = () => {
    Swal.fire({
      // icon: 'success',
      iconHtml: `<img src="${RejectedPdf}" height='100px' >`,
      text: "Application Not Suitable",
      confirmButtonText: "OK",
      confirmButtonColor: "#379592",
      padding: "2rem",
      customClass: {
        container: "my-swal",
      },
    })
      // alert('swal is working')
      .then(() => {
        history.push(
          `/ChangeFunding/${campId}/${staffApplicationId}/${staffId}`
        );
      });
  };



  const handleSupportSuitability = async () => {
    let payload = {
      staffApplicationId: staffApplicationId,
      staffId: staffId,
      campId: campId,
      applicationStatus: "Confirmed",
      applicationRemarks: sendRemarks,
      userId: localStorage.getItem("userId"),
    };

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/staff/reviewstaffappllication",
      url: `${SERVER_URL}${REVIEWSTAFF_APPLICATION}`,
      method: "POST",
      postData: payload,
      headers: {
        "Content-Type": "application/json",

        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let commentsdata = await apiService(apiInfo);

    if (commentsdata?.data?.responseCode === 0) {
      //  history.push("/userAddedPopup")

      Swal.fire({
        icon: "success",
        iconHtml: `<img src="${GreenCheck}" >`,
        text: "Application Approved Successfully!",
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
      }).then(() => {
        // history.push("/ChangeFunding");
        history.push(
          `/ChangeFunding/${campId}/${staffApplicationId}/${staffId}`
        );
      });
    } else {
      Swal.fire({
        icon: "error",
        text: commentsdata?.data?.responseData,
        // text: register?.data?.responseDesc,
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
      });
    }
  };

  const handleSupportStaffNotSuitable = async () => {
    let payload = {
      staffApplicationId: staffApplicationId,
      staffId: staffId,
      campId: campId,
      applicationStatus: "DECLINED",
      applicationRemarks: notSuitableRemarks,
      userId: localStorage.getItem("userId"),
    };

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/staff/reviewstaffappllication",
      url: `${SERVER_URL}${REVIEWSTAFF_APPLICATION}`,
      method: "POST",
      postData: payload,
      headers: {
        "Content-Type": "application/json",

        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let commentsdata = await apiService(apiInfo);

    if (commentsdata?.data?.responseData[0][0].Code === "0") {
      let statusOutput = commentsdata.data.responseData[0][0].Desc;

      Swal.fire({
       iconHtml: `<img src="${RejectedPdf}" height='100px' >`,
       
        text: "Application Not Suitable",
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
        padding: "2rem",
        customClass: {
          container: "my-swal",
          icon: 'no-border'
        },
      }).then((result) => {
        if (result.value) {
          // history.push(`/ChangeFunding/${campId}/${staffApplicationId}/${staffId}`)
          history.push(
            `/ChangeFunding/${campId}/${staffApplicationId}/${staffId}`
            
          )
          setNotSuitableRemarks('')

          // console.log(isConditionMet)
        }
      });
    }
  };

  // let staffId = localStorage.getItem("staffId");

  React.useEffect(() => {
    (async () => {
      let getData = {
        userId: localStorage.getItem("userId"),
        campId: campId,
        staffId: staffId,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/staff/getstaffcampapplication",
        url: `${SERVER_URL}${GETSTAFF_CAMPAPPLICATION}`,
        method: "POST",
        postData: getData,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let output = await apiService2(apiInfo);
      if(output?.data?.responseCode === 0){
        console.log(output.data.responseData[0]);
        let appDetails = output.data.responseData[0][0];
        // setLoading(false)
        
        if(appDetails && appDetails.EMCList){
          appDetails.EMCList = JSON.parse(appDetails.EMCList)
        }
        
        setSupportStaffDetails(appDetails);
      }
     
    }
    )();
  }, []);

  const onChangeNotSuitableRemarks = (event) => {
    setNotSuitableRemarks(event.target.value);
  };

  // const SupportStaffGetComments = async () => {


  //   let dataSent = {
  //     userId: localStorage.getItem("userId"),
  //     // campId: campId,
  //     applicationId: staffApplicationId,
  //   };

  //   let apiInfo = {
  //     url: "https://cncdevapi.natsoft.io/api/camps/getcampapplicantscomments",
  //     method: "POST",
  //     postData: dataSent,
  //     headers: {
  //       "Content-Type": "application/json",
  //       tokenheaderkey: localStorage.getItem("token"),
  //     },
  //   };
  //   let output = await apiService2(apiInfo);
  //   let commentList = output.data.responseData[0][0].comments;
  //   // setLoading(false);
  //   commentList &&
  //     commentList.map((item) => {
  //       item.Others && setGetComments(item.Others);
  //     });
  // };


  React.useEffect(() => {
    (async () => {

      let dataSent = {
        userId: localStorage.getItem("userId"),
        // campId: campId,
        applicationId: staffApplicationId,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/getcampapplicantscomments",
        url: `${SERVER_URL}${GETCAMP_APPLICANTSCOMMENTS}`,
        method: "POST",
        postData: dataSent,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let output = await apiService2(apiInfo);
      let commentList = output.data.responseData[0][0].comments;
      // setLoading(false);
      commentList &&
        commentList.map((item) => {
          item.Others && setGetComments(item.Others);
        });
    })();
  }, [isConditionMet]);


  const onChangeFundingRemarks = (event) => {
    setSendRemarks(event.target.value);
  };


  const handleSubmit = async () => {
    let payload = {
      staffApplicationId: staffApplicationId,
      staffId: staffId,
      campId: campId,
      applicationRemarks: sendRemarks,
      userId: localStorage.getItem("userId"),
    };

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/staff/reviewstaffappllication",
      url: `${SERVER_URL}${REVIEWSTAFF_APPLICATION}`,
      method: "POST",
      postData: payload,
      headers: {
        "Content-Type": "application/json",

        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let commentsdata = await apiService(apiInfo);
    if (commentsdata?.data?.responseData[0][0].Code === "0") {

      let statusOutput = commentsdata.data.responseData[0][0].Desc
      console.log(statusOutput)
      Swal.fire({
          iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
          text: statusOutput,
          confirmButtonText: 'OK',
          confirmButtonColor: "#379592",
          customClass: {
              container: "my-swal",

          },
      }).then((result) => {
          if (result.value) {
              history.push(`/SupportStaffApplicationReview/${campId}/${staffApplicationId}/${staffId}`)
              setIsConditionMet((prev) => !prev);
              setSendRemarks('')
              //   console.log(isConditionMet)
          }
      })
  }
  };

  React.useEffect(() => {
    (
        async () => {
            // setLoading(true)
            let dataSent = {
                userId: localStorage.getItem('userId'),
                // userId: 9,
                _methodId: 64,
                // childId: 200001,
                childId:staffId


            }

            let apiInfo = {
                url: `${SERVER_URL}${US8_URL}`,
                method: "POST",
                postData: dataSent,
                headers: {
                    "Content-Type": "application/json",
                    tokenheaderkey: localStorage.getItem("token"),
                },

            }

            let output = await apiService2(apiInfo)
            
            let appDetails = output.data.responseData[0]
            // setLoading(false)
            // console.log(appDetails)
            if( output.data.responseCode === 0){
                setPastDetails(appDetails)
            }
            
            

        }
    )()
}

    , [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Box sx={{ textAlign: "center" }}>Application Review</Box>
        {/* <Stack direction='row' sx={{ mt: '0.75rem' }}>
                    <Link to='/ChangeSuitability'>
                    <Box
                        alignSelf="center"
                        component="img"
                        sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
                        alt="Logo"
                        src={ArrowBack}
                    ></Box>
                    </Link>
                    
                    
                </Stack> */}

        <Grid container display="flex">
          <Grid item xs={12} sm={6} md={9}>
            <Stack direction="row">
              <Link
                to={`/ChangeFunding/${campId}/${staffApplicationId}/${staffId}`}
              >
                <Box
                  alignSelf="center"
                  component="img"
                  sx={{
                    height: "0.75rem",
                    width: "0.65rem",
                    cursor: "pointer",
                  }}
                  alt="Logo"
                  src={ArrowBack}
                ></Box>
              </Link>

              <Box mb="1.5rem" mx="0.75rem">
                {supportStaffDetails && supportStaffDetails.campName}
              </Box>
            </Stack>

            <Grid container display="flex" alignItems="flex-end">
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="Name"
                  variant="standard"
                  className="namess"
                  value={supportStaffDetails && supportStaffDetails.staffName}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // sx={{ width: 220 }}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="Age"
                  className="agess"
                  variant="standard"
                  value={supportStaffDetails && supportStaffDetails.staffAge}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // sx={{ width: 220 }}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="Gender"
                  variant="standard"
                  value={supportStaffDetails && supportStaffDetails.gender}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // sx={{
                  //   width: 220,
                  // }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container display="flex" my="0.5rem">
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="Email Id"
                  variant="standard"
                  value={supportStaffDetails && supportStaffDetails.email}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // sx={{
                  //   width: 220,
                  // }}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="Address"
                  variant="standard"
                  value={supportStaffDetails && supportStaffDetails.address}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // sx={{
                  //   width: 220,
                  // }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="standard-basic"
                // label="Past Camp History"
                variant="standard"
                // value={applicationDetails && applicationDetails.address}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                // sx={{
                //   width: 220,
                // }}
              ></TextField>
            </Grid>
            <Box my='0.5rem'>Past Camp History</Box>
                        {pastDetails && pastDetails.length>0 
                        ?(
                            <Grid item xs={12} >
                            {/* <TextField
                                id="standard-basic"
                                label="Past Camp History"
                                variant="standard"
                                value='Past Camp History'
                                InputProps={{
                                    readOnly: true,
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                sx={{
                                    width: 220,
                                }}
                            ></TextField> */}
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className='bold' sx={{fontSize:'14px'}}>
                                            <TableCell align="center" sx={{fontWeight:'bold'}}>Camp Name</TableCell>
                                            <TableCell align="center" sx={{fontWeight:'bold'}}>Camp Start Date</TableCell>
                                            <TableCell align="center" sx={{fontWeight:'bold'}}>Camp Type</TableCell>
                                            <TableCell align="center"sx={{fontWeight:'bold'}}>Status</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pastDetails && pastDetails.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">{item.campName}
                                                </TableCell>
                                                <TableCell align="center">{item.campStartDate}</TableCell>
                                                <TableCell align="center">{item.campType}</TableCell>
                                                <TableCell align="center">{item.application_status}</TableCell>

                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        ) 
                        :('')}
            <Box mt="2rem" mb="2.5rem" sx={{ color: "#000000" }}>
              <Box sx={{ color: "#AFAFAF", my: "1.5rem" }}>
                Application for New Camp  ({supportStaffDetails && supportStaffDetails.campName})
              </Box>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="What is your profession?*"
                  variant="standard"
                  value={supportStaffDetails && supportStaffDetails.profession}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: 220,
                  }}
                ></TextField>
              </Grid>
            </Box>

            <Grid item sx={{ color: "#676767" }} md={12}>
              <Box sx={{ color: "#000000" }}>
                <Box sx={{ color: "#AFAFAF" }}>Medical information</Box>
                <Box my="0.65rem">
                  <Box className="medium">
                    Do you have any allergies, medical conditions or medications
                    that we need to be aware of?<span className="red">*</span>
                  </Box>
                  <Stack direction="row" spacing={8}>
                    {/* <FormControlLabel
                        control={<Checkbox />}
                        label="Yes"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                        }}
                      />
                      <FormControlLabel
                        size="small"
                        control={<Checkbox />}
                        label="No"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                        }}
                      /> */}
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Box
                        component="img"
                        sx={{ height: "15px", width: "15px" }}
                        alt="Logo"
                        src={
                          supportStaffDetails &&
                            supportStaffDetails.allergy ===
                            "Yes"
                            ? Radiobtnyes
                            : Radiobtnno
                        }
                      ></Box>
                      <Box>Yes</Box>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Box
                        component="img"
                        sx={{ height: "15px", width: "15px" }}
                        alt="Logo"
                        src={
                          supportStaffDetails &&
                            supportStaffDetails.allergy ===
                            "No"
                            ? Radiobtnyes
                            : Radiobtnno
                        }
                      ></Box>
                      <Box>No</Box>
                    </Stack>
                  </Stack>
                </Box>
                {supportStaffDetails && supportStaffDetails.allergy === 'Yes'
                  ? (
                    <Box my="0.5rem">
                      <Box className="medium">If yes, please describe below:</Box>
                      <TextField
                        id="standard-basic"
                        // label="Past Camp History"
                        multiline
                        rows={3}
                        size="small"
                        variant="outlined"
                        value="NA"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: 350, color: "#000000", my: "0.75rem" }}
                      ></TextField>
                    </Box>
                  )
                  : (
                    ''
                  )}


                <Box my="0.5rem">
                  <Box className="medium">
                    Do you have any dietary needs, restrictions or preferences?<span className="red">*</span>
                  </Box>
                  <TextField
                    id="standard-basic"
                    // label="Past Camp History"
                    multiline
                    rows={3}
                    size="small"
                    variant="outlined"
                    value={
                      supportStaffDetails && supportStaffDetails.dietaryInfo
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: 350, color: "#000000", my: "0.75rem" }}
                  ></TextField>
                </Box>
                <Box my="2rem" sx={{ color: "#000000" }}>
                  <Box sx={{ color: "#AFAFAF" }}>About You</Box>
                  <Box my="0.5rem">
                    <Box className="medium" my="0.75rem">
                      Shirt Size: <span className="red ">*</span>
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="standard"
                      value={
                        supportStaffDetails && supportStaffDetails.shirtSize
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 350, color: "#000000" }}
                    ></TextField>
                  </Box>
                  <Box my="0.5rem">
                    <Box className="medium">
                      What are your relevant training and/or strengths?
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      multiline
                      rows={3}
                      size="small"
                      variant="outlined"
                      value={
                        supportStaffDetails &&
                        supportStaffDetails.InterestStrenghts
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 350, color: "#000000", my: "0.75rem" }}
                    ></TextField>
                  </Box>
                  <Box my="0.5rem">
                    <Box className="medium" my="0.75rem">
                      What are your reason for attending camp/group program?{" "}
                      <span className="red ">*</span>
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="standard"
                      value={
                        supportStaffDetails &&
                        supportStaffDetails.reasonJoiningCamp
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 350, color: "#000000" }}
                    ></TextField>
                  </Box>
                  <Box my="0.5rem">
                    <Box className="medium">
                      Please state the goals that you wish to achieve/learn from
                      camp/group program: <span className="red ">*</span>
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      multiline
                      rows={3}
                      size="small"
                      variant="outlined"
                      value={
                        supportStaffDetails && supportStaffDetails.goalsforCamp
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 350, color: "#000000", my: "0.75rem" }}
                    ></TextField>
                  </Box>
                  <Box my="0.5rem">
                    <Box className="medium">
                      Do you have any special skills or interests that you may
                      be able to contribute? i.e. musical ability, balloon
                      making, jewelry?<span className="red ">*</span>
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      multiline
                      rows={3}
                      size="small"
                      variant="outlined"
                      value={
                        supportStaffDetails && supportStaffDetails.specialSkills
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 350, color: "#000000", my: "0.75rem" }}
                    ></TextField>
                  </Box>
                  <Box my="0.5rem">
                    <Box className="medium">
                      On some of our camps (such as Boys Trip and Camp
                      Confidence) we have therapy dogs in training who also
                      attend. Do you have allergies or are you fearful of dogs?
                      Please comment below.<span className="red ">*</span>
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      multiline
                      rows={3}
                      size="small"
                      variant="outlined"
                      value={
                        supportStaffDetails && supportStaffDetails.afraidDogs
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 350, color: "#000000", my: "0.75rem" }}
                    ></TextField>
                  </Box>
                </Box>

                <Box sx={{ color: "#000000" }}>
                  <Box sx={{ color: "#AFAFAF" }}>Document Attachment</Box>
                  <Box my="0.75rem">
                    {/* <Box className="medium" my="0.75rem">
                      Please upload a copy of your working with Children Check:<span className="red ">*</span>
                    </Box> */}

                    {/* <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        supportStaffDetails &&
                        supportStaffDetails.fileWorkingWithChildren
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 350,
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          color: "#150B9F",
                        },
                      }}
                    ></TextField> */}
                    {supportStaffDetails && (supportStaffDetails.fileWorkingWithChildren !== null) && (supportStaffDetails.fileWorkingWithChildren !== 'null') ?
                      (
                        <Box>
                         <Box className="medium" my="0.75rem">
                      Please upload a copy of your working with Children Check:<span className="red ">*</span>
                    </Box>
                          <Box sx={{ border: 1, width: 350, borderRadius: '5px', py: '0.5rem', color: '#ADADAD', px: '0.5rem' }} >
                            {supportStaffDetails && supportStaffDetails.fileWorkingWithChildren !== null ?
                              (<a href={supportStaffDetails && supportStaffDetails.fileWorkingWithChildren} target="_blank">
                               Working with Children Check
                              </a>)
                              : ('')}

                          </Box>
                        </Box>
                      )

                      : ('')}
                  </Box>
                  <Box my="0.75rem">
                    {/* <Box className="medium" my="0.75rem">
                      Please upload a copy of your NDIS worker screening check:<span className="red ">*</span>
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        supportStaffDetails &&
                        supportStaffDetails.fileNdiWorkerScreenCheck
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 350,
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          color: "#150B9F",
                        },
                      }}
                    ></TextField> */}
                         {supportStaffDetails && (supportStaffDetails.fileNdiWorkerScreenCheck !== null) && (supportStaffDetails.fileNdiWorkerScreenCheck !== 'null') ?
                      (
                        <Box>
                         <Box className="medium" my="0.75rem">
                         Please upload a copy of your NDIS worker screening check:<span className="red ">*</span>
                    </Box>
                          <Box sx={{ border: 1, width: 350, borderRadius: '5px', py: '0.5rem', color: '#ADADAD', px: '0.5rem' }} >
                            {supportStaffDetails && supportStaffDetails.fileNdiWorkerScreenCheck !== null ?
                              (<a href={supportStaffDetails && supportStaffDetails.fileNdiWorkerScreenCheck} target="_blank">
                              NDIS worker screening check
                              </a>)
                              : ('')}

                          </Box>
                        </Box>
                      )

                      : ('')}
                  </Box>
                  <Box my="0.75rem">
                    {/* <Box className="medium" my="0.75rem">
                      Please upload a copy of your NDIS workers orientation
                      module:<span className="red ">*</span>
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        supportStaffDetails &&
                        supportStaffDetails.fileNdiWorkerOrientationModule
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 350,
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          color: "#150B9F",
                        },
                      }}
                    ></TextField> */}
                    {supportStaffDetails && (supportStaffDetails.fileNdiWorkerOrientationModule !== null) && (supportStaffDetails.fileNdiWorkerOrientationModule !== 'null') ?
                      (
                        <Box>
                         <Box className="medium" my="0.75rem">
                         Please upload a copy of your NDIS workers orientation module<span className="red ">*</span>
                    </Box>
                          <Box sx={{ border: 1, width: 350, borderRadius: '5px', py: '0.5rem', color: '#ADADAD', px: '0.5rem' }} >
                            {supportStaffDetails && supportStaffDetails.fileNdiWorkerOrientationModule !== null ?
                              (<a href={supportStaffDetails && supportStaffDetails.fileNdiWorkerOrientationModule} target="_blank">
                              NDIS worker orientation module
                              </a>)
                              : ('')}

                          </Box>
                        </Box>
                      )

                      : ('')}
                  </Box>
                  <Box my="0.75rem">
                    {/* <Box className="medium" my="0.75rem">
                      Please upload a copy of your COVID Vaccination
                      Certificate:<span className="red ">*</span>
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        supportStaffDetails &&
                        supportStaffDetails.fileCovidVaccinationCertificate
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 350,
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          color: "#150B9F",
                        },
                      }}
                    ></TextField> */}
                    {supportStaffDetails && (supportStaffDetails.fileCovidVaccinationCertificate !== null) && (supportStaffDetails.fileCovidVaccinationCertificate !== 'null') ?
                      (
                        <Box>
                         <Box className="medium" my="0.75rem">
                         Please upload a copy of your COVID Vaccination
                      Certificate:<span className="red ">*</span>
                    </Box>
                          <Box sx={{ border: 1, width: 350, borderRadius: '5px', py: '0.5rem', color: '#ADADAD', px: '0.5rem' }} >
                            {supportStaffDetails && supportStaffDetails.fileCovidVaccinationCertificate !== null ?
                              (<a href={supportStaffDetails && supportStaffDetails.fileCovidVaccinationCertificate} target="_blank">
                              Covid Vaccination Certificate
                              </a>)
                              : ('')}

                          </Box>
                        </Box>
                      )

                      : ('')}
                  </Box>
                  <Box my="0.75rem">
                    {/* <Box className="medium" my="0.75rem">
                      Please upload a headshot photo of you (to be sent to
                      families in your group to help prepare them):<span className="red ">*</span>
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={supportStaffDetails && supportStaffDetails.ProfilePhoto}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 350,
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          color: "#150B9F",
                        },
                      }}
                    ></TextField> */}
                    {supportStaffDetails && (supportStaffDetails.ProfilePhoto !== null) && (supportStaffDetails.ProfilePhoto !== 'null') ?
                      (
                        <Box>
                         <Box className="medium" my="0.75rem">
                         Please upload a headshot photo of you (to be sent to
                      families in your group to help prepare them):<span className="red ">*</span>
                    </Box>
                          <Box sx={{ border: 1, width: 350, borderRadius: '5px', py: '0.5rem', color: '#ADADAD', px: '0.5rem' }} >
                            {supportStaffDetails && supportStaffDetails.ProfilePhoto !== null ?
                              (<a href={supportStaffDetails && supportStaffDetails.ProfilePhoto} target="_blank">
                              Headshot photo
                              </a>)
                              : ('')}

                          </Box>
                        </Box>
                      )

                      : ('')}

                  </Box>
                  <Box my="0.75rem">
                    {/* <Box className="medium" my="0.75rem">
                      If you have one, please upload a copy of your First Aid
                      Training Certificate:
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        supportStaffDetails &&
                        supportStaffDetails.fileFirstaidTrainingCertificate
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 350,
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          color: "#150B9F",
                        },
                      }}
                    ></TextField> */}
                            {supportStaffDetails && (supportStaffDetails.fileFirstaidTrainingCertificate !== null) && (supportStaffDetails.fileFirstaidTrainingCertificate !== 'null') ?
                      (
                        <Box>
                         <Box className="medium" my="0.75rem">
                         If you have one, please upload a copy of your First Aid
                      Training Certificate: 
                    </Box>
                          <Box sx={{ border: 1, width: 350, borderRadius: '5px', py: '0.5rem', color: '#ADADAD', px: '0.5rem' }} >
                            {supportStaffDetails && supportStaffDetails.fileFirstaidTrainingCertificate !== null ?
                              (<a href={supportStaffDetails && supportStaffDetails.fileFirstaidTrainingCertificate} target="_blank">
                              First Aid Training Certificate
                              </a>)
                              : ('')}

                          </Box>
                        </Box>
                      )

                      : ('')}
                  </Box>
                  <Box my="0.75rem">
                    {/* <Box className="medium" my="0.75rem">
                      If you have one, please upload a copy of your Police
                      Clearance:
                    </Box>
                    <TextField
                      id="standard-basic"
                      // label="Past Camp History"
                      size="small"
                      variant="outlined"
                      value={
                        supportStaffDetails &&
                        supportStaffDetails.filePoliceClearanceCertificate
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        width: 350,
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          color: "#150B9F",
                        },
                      }}
                    ></TextField> */}
                    {supportStaffDetails && (supportStaffDetails.filePoliceClearanceCertificate !== null) && (supportStaffDetails.filePoliceClearanceCertificate !== 'null') ?
                      (
                        <Box>
                         <Box className="medium" my="0.75rem">
                         If you have one, please upload a copy of your Police
                      Clearance:
                    </Box>
                          <Box sx={{ border: 1, width: 350, borderRadius: '5px', py: '0.5rem', color: '#ADADAD', px: '0.5rem' }} >
                            {supportStaffDetails && supportStaffDetails.filePoliceClearanceCertificate !== null ?
                              (<a href={supportStaffDetails && supportStaffDetails.filePoliceClearanceCertificate} target="_blank">
                              Police Clearance
                              </a>)
                              : ('')}

                          </Box>
                        </Box>
                      )

                      : ('')}
                  </Box>
                </Box>
                <Box my="2rem" sx={{ color: "#000000" }}>
                  <Box sx={{ color: "#AFAFAF" }}>Photo/Video Consent</Box>
                  <Box my="1rem">
                    I hereby give my permission to the staff of Calm and
                    Connected to make photographic, audio, or visual recordings
                    of me while attending Calm and Connected’s Therapy Intensive
                    Programs. Photos/Video of my me may be used as indicated
                    below. This Consent if valid for 5 years and I understand
                    that I can notify Calm and Connected of change in consent at
                    any time.
                  </Box>
                  <Box my="0.65rem">
                    <Box className="medium">
                      To make a personal documentary history for the children's
                      camp experience (each child is given copies of photos
                      taken on the trip).*
                    </Box>
                    <Stack direction="row" spacing={8}>

                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            supportStaffDetails &&
                              supportStaffDetails.photostobetakenCheck ===
                              "Yes"
                              ? Radiobtnyes
                              : Radiobtnno
                          }
                        ></Box>
                        <Box>Yes</Box>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            supportStaffDetails &&
                              supportStaffDetails.photostobetakenCheck ===
                              "No"
                              ? Radiobtnyes
                              : Radiobtnno
                          }
                        ></Box>
                        <Box>No</Box>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box my="0.65rem" sx={{ color: "#000000" }}>
                    <Box className="medium">
                      For photos to be used on Calm and Connected’s website and
                      marketing materials to support the description of their
                      programs* you will be notified.*
                    </Box>
                    <Stack direction="row" spacing={8}>
                      {/* <FormControlLabel
                        control={<Checkbox />}
                        label="Yes"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                        }}
                      />
                      <FormControlLabel
                        size="small"
                        control={<Checkbox />}
                        label="No"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                        }}
                      /> */}
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            supportStaffDetails &&
                              supportStaffDetails.photosWebsitePromotionCheck ===
                              "Yes"
                              ? Radiobtnyes
                              : Radiobtnno
                          }
                        ></Box>
                        <Box>Yes</Box>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            supportStaffDetails &&
                              supportStaffDetails.photosWebsitePromotionCheck ===
                              "No"
                              ? Radiobtnyes
                              : Radiobtnno
                          }
                        ></Box>
                        <Box>No</Box>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box my="0.65rem" sx={{ color: "#000000" }}>
                    <Box className="medium">
                      To be uploaded on Calm and Connected’s social media*
                    </Box>
                    <Stack direction="row" spacing={8}>
                      {/* <FormControlLabel
                        control={<Checkbox />}
                        label="Yes"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                        }}
                      />
                      <FormControlLabel
                        size="small"
                        control={<Checkbox />}
                        label="No"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                        }}
                      /> */}
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            supportStaffDetails &&
                              supportStaffDetails.photosforSocialMediaCheck ===
                              "Yes"
                              ? Radiobtnyes
                              : Radiobtnno
                          }
                        ></Box>
                        <Box>Yes</Box>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box
                          component="img"
                          sx={{ height: "15px", width: "15px" }}
                          alt="Logo"
                          src={
                            supportStaffDetails &&
                              supportStaffDetails.photosforSocialMediaCheck ===
                              "No"
                              ? Radiobtnyes
                              : Radiobtnno
                          }
                        ></Box>
                        <Box>No</Box>
                      </Stack>
                    </Stack>
                  </Box>
                  <Grid container my="1rem">
                    <Grid item md={4} xs={12}>
                      <TextField
                        id="standard-basic"
                        label="Name*"
                        size="small"
                        variant="standard"
                        value={
                          supportStaffDetails && supportStaffDetails.staffName
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ color: "#000000" }}
                      ></TextField>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        id="standard-basic"
                        label="Date*"
                        size="small"
                        variant="standard"
                        value={
                          supportStaffDetails &&
                          supportStaffDetails.applicationDate
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ color: "#000000" }}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Box sx={{ color: "#AFAFAF" }}>
                    <Box sx={{ color: "#AFAFAF" }}>
                      Emergency Contact Details
                    </Box>
                  </Box>
                  <Grid container my="1rem">
                    <Grid item md={4} xs={12}>
                      <TextField
                        id="standard-basic"
                        label="Name*"
                        size="small"
                        variant="standard"
                        value={supportStaffDetails && supportStaffDetails.EMCList[0].Name}
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ color: "#000000" }}
                      ></TextField>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        id="standard-basic"
                        label="Mobile Number*"
                        size="small"
                        variant="standard"
                        value={supportStaffDetails && supportStaffDetails.EMCList[0].Mobile}
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ color: "#000000" }}
                      ></TextField>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} container justifyContent="center">
            <Box
              mt="1rem"
              component="img"
              sx={{ height: "90px", width: "90px", borderRadius: "50px" }}
              alt="Logo"
              // src={DefaultImage}
              src={supportStaffDetails && supportStaffDetails.ProfilePhoto}
            ></Box>
          </Grid>
        </Grid>
        <Stack>
          <TextField
            id="standard-basic"
            // label="Past Camp History"
            size="small"
            variant="outlined"
            value={localStorage.getItem("userName")}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: 300,
              my: "1rem",
            }}
          ></TextField>
          <TextField
            id="outlined-multiline-static"
            // label="Past Camp History"
            multiline
            rows={4}
            size="small"
            variant="outlined"
            value={sendRemarks}
            onChange={onChangeFundingRemarks}

            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: 600,
            }}
          ></TextField>

          <Button
            variant="contained"
            // onClick={() => {
            //   sendApplicationComments();
            // }}
            onClick={() => {
              handleSubmit();
            }}
            sx={{
              bgcolor: "#379592",
              textTransform: "none",
              "&:hover": { background: "#379592" },
              width: "200px",
              my: "0.75rem",
            }}
          >
            Submit
          </Button>
        </Stack>
        <Box className="medium" my="1rem">
          Previous Comments
        </Box>
        <Grid container>
          <TableContainer component={Paper} sx={{ width: 600 }}>
            <Table sx={{ width: 600 }} aria-label="customized table" my="1rem">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Camp Manager</StyledTableCell>
                  <StyledTableCell align="center">Remarks</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getComments &&
                  getComments.map((list, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{list.CampManager}</TableCell>
                      <TableCell align="center">{list.Remarks}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid container display="flex" justifyContent="flex-end" mt="2rem">
          <Button
            variant="contained"
            disabled={supportStaffDetails && supportStaffDetails.applicationStatus === 'DECLINED'}
            onClick={handleOpenSupportNotSuitable}
            sx={{
              bgcolor: "#909090",
              textTransform: "none",
              "&:hover": { background: "#909090" },
              // width: "200px"
              mx: "0.5rem",
            }}
          >
            Not Suitable
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSupportSuitability();
            }}
            sx={{
              bgcolor: "#379592",
              textTransform: "none",
              "&:hover": { background: "#379592" },
              // width: "200px"
              mx: "0.5rem",
            }}
          >
            Approve
          </Button>
        </Grid>
      </Box>

      <div>
        {/* <Button onClick={handleOpenNotSuitable}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleCloseNotSuitable}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={style}>
            <Card
              sx={{
                bgcolor: "#718F9480",
                minHeight: 400,
                pt: "2rem",
                px: "2rem",
              }}
            >
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                sx={{ height: "350px" }}
              >
                {/* <Box>Description box to write the reason</Box> */}
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={6}
                  label="Description box to write the reason"
                  value={notSuitableRemarks}
                  onChange={onChangeNotSuitableRemarks}
                />

                <Button
                  variant="contained"
                  onClick={() => handleSupportStaffNotSuitable()}
                  sx={{
                    bgcolor: "#379592",
                    textTransform: "none",
                    "&:hover": { background: "#379592" },
                    width: "200px",
                    mx: "0.5rem",
                    alignSelf: "flex-end",
                    mb: "2rem",
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Card>
            {/* <ChildModal /> */}
          </Box>
        </Modal>
      </div>

      {/* <div>
      
        <Modal
          open={open}
          onClose={handleCloseSupportNotSuitable}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={style}>
            <Card
              sx={{
                bgcolor: "#718F9480",
                minHeight: 400,
                pt: "2rem",
                px: "2rem",
              }}
            >
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                sx={{ height: "350px" }}
              >
                <Box>Description box to write the reason</Box>
                <Button
                  variant="contained"
                  onClick={() => handleSwal()}
                  sx={{
                    bgcolor: "#379592",
                    textTransform: "none",
                    "&:hover": { background: "#379592" },
                    width: "200px",
                    mx: "0.5rem",
                    alignSelf: "flex-end",
                    mb: "2rem",
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Card>
        
          </Box>
        </Modal>
      </div> */}
      {/* <div>
   
        <Modal
          open={openSuitabilty}
          onClose={handleCloseSupportSuitable}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={styleSuitability}>
         
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ p: "1.5rem" }}
            >
              <Button
                variant="outlined"
                onClick={() => setPreScreenSelected((prev) => !prev)}
                sx={{
                  color: preScreenSelected ? "white" : "#379592",
                  bgcolor: preScreenSelected ? "#379592" : "white",
                  textTransform: "none",
                  "&:hover": {
                    color: preScreenSelected ? "white" : "#379592",
                    borderColor: "#379592",
                    bgcolor: preScreenSelected ? "#379592" : "white",
                  },
                  mx: "0.5rem",
                  mb: "0.75rem",
                  borderColor: "#379592",
                  width: "270px",
                }}
              >
                Pending Suitability - Pre Screen
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#379592",
                  textTransform: "none",
                  "&:hover": { background: "white", borderColor: "#379592" },
                  mx: "0.5rem",

                  mb: "0.75rem",
                  borderColor: "#379592",
                  width: "270px",
                }}
              >
                Pending Suitability - Others
              </Button>
              <Link to="/PendingSuitabilityPreScreen" className="link2">
                <Button
                  variant="contained"
         
                  sx={{
                    bgcolor: "#379592",
                    textTransform: "none",
                    "&:hover": { background: "#379592" },
                    width: "150px",
                    mt: "2rem",
                  }}
                >
                  Confirm
                </Button>
              </Link>
            </Grid>


          </Box>
        </Modal>
      </div> */}
    </ThemeProvider>
  );
}
