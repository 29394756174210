import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Unauthorized from './Unauthorized';
// import { UserContext } from './UserContext';

const RoleGuard = ({ component: Component, allowedRoles, ...rest }) => {
//   const { user } = useContext(UserContext);

//   if (!user.isAuthenticated) {
//     return <Redirect to="/login" />;
//   }

 

  return (<Route
    render={() => {
      // if (!localStorage.getItem('token') ){
      //   return <Redirect to="/" />;
      // }

      // if (!allowedRoles.includes(localStorage.getItem('rolesName'))) {
      //   return <Redirect to="/unauthorized" />;
      // }

      // return <Route {...rest} render={props => <Component {...props} />} />;
        if (localStorage.getItem('token') && (allowedRoles.includes(localStorage.getItem('rolesName')))) {
            // return <Redirect to="/unauthorized" />;
            console.log('correct path')
            return <Route {...rest} render={props => <Component {...props} />} />;
          }
        else{
          console.log('wrong path')
            return <Redirect to="/" />;
        }
    }
}
/>)
};

export default RoleGuard;
