import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Card from "@mui/material/Card";
import { Link,useHistory } from "react-router-dom";
import { apiService, apiService2 } from "../../Services/apiService";
import { setCampData } from "../../Redux/slices/campSettingSlice";
import { useDispatch } from "react-redux";
import { US8_URL, SERVER_URL } from "../../Services/apiEndpints";

export default function MedicationAssignedCamps() {
  const [campListData, setCampListData] = useState([]);
  const dispatch = useDispatch();
  let history = useHistory()

  React.useEffect(() => {
    (async () => {
      // setLoading(true);
      let gettran = {
        _methodId: "1",
        userId: localStorage.getItem("userId"),
        roleName: "MEDICATION MANAGER",
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/us8",
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);

      const campDataList = login.data.responseData[0];
      // setLoading(false);

      console.log(campDataList);

      // setCampListData(campDataList);
      if (Array.isArray(campDataList)) {
        setCampListData(campDataList);
      }
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
      window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
  }, []);

  const handleSelect =(id,grp,camp) =>{
    dispatch(setCampData(camp));
    // history.push(`/GoalCamp/${id}`)
    console.log(id,grp,camp)
   // grp = JSON.parse(grp)
    console.log(id,grp)
    localStorage.setItem('groupInfo',[grp])
    localStorage.setItem('camp_namemed',[camp])
    console.log(localStorage.getItem('groupInfo'),'gettttmed')
   }
  return (
    <>
      {campListData && campListData.length > 0 ? (
        <>
          <Grid mt={4}>
            <Box>Assigned Camps</Box>
          </Grid>
          {campListData && campListData.length &&
            campListData.map((camp, index) => (
              <Box
                key={index}
                // onClick={() => handleSetCampName( item.campName)}
              >
                <Link
                  to={`/ApplicantsReviewforMedication/${camp.camp_id}`}
                  className="link2"
                 
                  onClick={()=>handleSelect(camp.camp_id,camp.group_info,camp.camp_name)}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      backgroundColor: "#F8F8F8",

                      color: "#707070",
                      my: "0.75rem",
                      border: 1,
                      borderColor: "#379592",
                      borderRadius: "0.75rem",
                      py: "0rem",
                     
                    }}
                    className="cardMq"
                  >
                    <CardContent>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Grid
                            container
                            display="flex"
                            alignItems="center"
                            justifyContent="space-evenly"
                          >
                            <Box mx="0.75rem" sx={{ color: "#379592" }}>
                              {camp.camp_name}
                            </Box>
                            <Chip
                              // label={card.campType.charAt(0).toUpperCase()+card.campType.slice(1).toLowerCase()}
                              label={camp.camp_type}
                              variant="outlined"
                              size="small"
                              className="medium"
                              mx="0.75rem"
                              sx={{
                                color: "#707070",
                                backgroundColor: "white",
                                fontSize: "0.5rem",
                                px: "0.5rem",
                                height: "20px",
                              }}
                            />
                            <Box
                              mx="0.75rem"
                              sx={{ fontSize: "0.85rem", color: "#000000" }}
                              className="medium"
                            >
                              Start Date: {camp.camp_startdate}
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container display="flex" alignItems="center">
                            {/* <Chip
                              label={camp.user_name}
                              variant="outlined"
                              size="small"
                              mx="0.75rem"
                              className="medium"
                              sx={{
                                // color: (card.campStatus==='DRAFT') ? "black" : "white",
                                // backgroundColor:  (card.campStatus==='ACTIVE') ? "#379592" : "#E2F157",
                                borderColor: "#379592",
                                mr: "1.75rem",
                                px: "1rem",
                                py: "0.85rem",
                              }}
                            /> */}

                            <Box>
                              <Chip
                                label={camp.roles_name}
                                variant="outlined"
                                size="small"
                                mx="0.75rem"
                                className="medium rolesname-chipMq"
                                sx={{
                                  // color: (card.campStatus==='DRAFT') ? "black" : "white",
                                  // backgroundColor:  (card.campStatus==='ACTIVE') ? "#379592" : "#E2F157",
                                  borderColor: "#379592",
                                  px: "1rem",
                                  py: "0.85rem",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Link>
              </Box>
            ))}
        </>
      ) : (
        <Grid mt={4}>
          <Box>No Assigned Camps</Box>
        </Grid>
      )}
    </>
  );
}
