import React, { useState } from "react";
import Button from "@mui/material/Button";

import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import moment from "moment";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams } from "react-router-dom";
import GreenPdf from "../../Assets/Icons/Icons/GreenPdf.png";
import { apiService, apiService2 } from "../../Services/apiService";
import { GET_CHILDPARTICIPANTFILES , SERVER_URL} from '../../Services/apiEndpints'

export default function SupportStaffFiles() {
  const [datatry, setDataTry] = React.useState([]);
  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    palette: {
      secondary: {
        main: "#379592",
      },
    },

    Muidisabled: {
      styleOverrides: {
        backgroundColor: "purple",
      },
    },
  });

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  let { campId, staffApplicationId, staffId } = useParams();

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      backgroundColor: "rgba(55, 149, 146, 1)",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  React.useEffect(() => {
    (async () => {
      let gettran = {
        userId: localStorage.getItem("userId"),
        // campId:campId,
        applicationId: staffApplicationId,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/child/getchildparticipantfiles",
        url: `${SERVER_URL}${GET_CHILDPARTICIPANTFILES}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",

          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let login = await apiService(apiInfo);

      const campDataList = login.data.responseData[0];

      campDataList.forEach((element) => {
        if (element.StaffList != null) {
          console.log(element.StaffList);
          element.StaffList = JSON.parse(element.StaffList);
          console.log(element.StaffList);
        }
      });
      console.log(campDataList);
      // console.log("campId",campDataList.campId);
      setDataTry(campDataList);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);

  const supportStaffCampList = datatry;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Stack direction="row" sx={{ mt: "0.75rem" }}>
          <Link
            to={`/ChangeFunding/${campId}/${staffApplicationId}/${staffId}`}
          >
            <Box
              alignSelf="center"
              component="img"
              sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
              alt="Logo"
              src={ArrowBack}
            ></Box>
          </Link>
          <Box sx={{ margin: "auto" }}>Recent Files</Box>
        </Stack>
        <TableContainer component={Paper} sx={{ my: "2rem" }}>
          <Table sx={{ minWidth: 450 }} aria-label="customized table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">File name</StyledTableCell>
                {/* <StyledTableCell align="center">File Size</StyledTableCell> */}
                <StyledTableCell align="center">Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {supportStaffCampList.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "& .MuiTableCell-root": { py: "0.45rem" },
                  }}
                >
                  <StyledTableCell align="center">
                    {row.uploadedDate}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Stack direction="row" spacing={3}>
                      <Box
                        alignSelf="center"
                        component="img"
                        sx={{
                          height: "0.75rem",
                          width: "0.65rem",
                          cursor: "pointer",
                        }}
                        alt="Logo"
                        src={GreenPdf}
                      ></Box>
                      <Box>{row.fileName}</Box>
                    </Stack>
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">
                                        {row.fileSize}
                                    </StyledTableCell> */}
                  <StyledTableCell align="center">
                    <Stack
                      direction="row"
                      justifyContent="space-around"
                      sx={{ color: "#718F94" }}
                    >
                      <Box sx={{ cursor: "pointer" }}>
                        <a href={row.viewUrl} target="_blank">
                          View
                        </a>
                      </Box>

                      <Box sx={{ cursor: "pointer" }}>
                        <a href={row.downloadUrl} target="_blank">
                          Download
                        </a>
                      </Box>

                      {/* <Box>Remove</Box> */}
                    </Stack>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ThemeProvider>
  );
}
