import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const GuardedRoute = ({ component: Component }) => {
    let userToken = localStorage.getItem('token');


    return (<Route
        render={(a) => {
            console.log("what is a",a)
        
            return(
                userToken ? <Component /> : <Redirect to="/" />
            )
        }
    }
    />)
};

export default GuardedRoute;
