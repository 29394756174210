import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,

  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";

import { apiService, apiService2 } from "../../Services/apiService";
import moment from "moment";
import { Link, useParams, useHistory } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png"
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import CrossGreen from "../../Assets/Icons/Icons/CrossGreen.png";
import axios from "axios";
import question from "../../Assets/Icons/Icons/questionMark.png";
import Swal from 'sweetalert2'
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import PdfIcon from "../../Assets/Icons/Icons/PdfIcon.png"
import { SERVER_URL, CREATEWELCOME_PACK,  FILE_UPLOAD, } from '../../Services/apiEndpints'
import ThumbnailImage from "../../Assets/Icons/Icons/ThumbnailImage.png"

export default function CreateWelcomePack() {
  let history = useHistory()
  const [campList, setCampList] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const [preInformation, setPreInformation] = React.useState(null)

  const hiddenFileInputVideo = React.useRef(null);
  const [welcomeVideoFile, setWelcomeVideoFile] = useState(null);


  const [welcomeVideoArray, setWelcomeVideoArray] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  // const [notificationHashPath, setNotificationHashPath] = useState(null);
  const [welcomeVideoHash, setWelcomeVideoHash] = useState([]);


  const hiddenFileImputCombo = React.useRef(null);
  const [combinationArray, setCombinationArray] = useState([]);
  const [combinationHash, setCombinationHash] = useState([]);
  const [comboDescription, setComboDescription] = React.useState(null)

  const hiddenFileInputDocument = React.useRef(null);
  const [documentArray, setDocumentArray] = useState([]);
  const [documentHash, setDocumentHash] = useState([]);

  const [finalData, setFinalData] = React.useState([])
  const [accept, setAccept] = useState('');
  const [videoRow, setVideoRow] = React.useState([])

  let { campId } = useParams();

  const videoRef = React.useRef(null);

  const handleClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    }
    else {
      videoRef.current.pause();
    }
  };

  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    palette: {
      secondary: {
        main: "#379592",
      },
    },

    Muidisabled: {
      styleOverrides: {
        backgroundColor: "purple",
      },
    },
  });

  const handlePreInformation = (event) => {

    setPreInformation(event.target.value)
  }

  const handleComboDescription = (event, index) => {

    let local = index


    setCombinationArray((attach) => {

      return attach.map((attachment) => {
        let value = attach.indexOf(attachment)

        if (value === local) {
          return {
            ...attachment,
            docContent: event.target.value,
          };
        } else {
          return attachment;
        }
      });
    });

  }

  const handleNotificationFileChange = (event) => {
    event.preventDefault();


    for (const file of event.target.files) {

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {

        setWelcomeVideoArray((attachment) => [
          ...attachment,
          {
            path: e.target.result,
            fileObj: file,
            docPath: "",
            docTitle: file.name,
            docContent: 'WELCOME_VIDEO',
          },
        ]);
      };
    }


    for (const file of event.target.files) {
      if (parseFloat(file.size / (1024 * 1024)).toFixed(2) > 2) {
        // alert("Please select image size less than 2 MB");
        // setImageAlert(true)
        Swal.fire({
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          text: "Please select image size less than 3 MB",
          customClass: {
            container: "my-swal",
          },
        })
        setTimeout(() => {
          // console.log(attachmentsArr, file.name); // Log current values for debugging purposes
          setWelcomeVideoArray((welcomeVideoArray) => {
            return welcomeVideoArray.filter((row) => row.docTitle !== file.name);
          });
        }, 2000);

        console.log(welcomeVideoArray, 'hereeeeeeeeeeeee')
      }
      else {
        // setImageAlert(false)
        console.log('yes')
      }

    }
  };

  async function uploadNotificationFile(event) {

    event.preventDefault();

    welcomeVideoArray.forEach((file) => {
      const forminputs = new FormData();

      forminputs.append("file", file.fileObj);
      forminputs.append("userId", localStorage.getItem("userId"));
      forminputs.append("documentContent", "WELCOME_VIDEO");
      forminputs.append("documentType", "WELCOME_PACK");

      axios
        .post(`${SERVER_URL}${FILE_UPLOAD}`, forminputs, {
          headers: {
            "Content-Type": "multipart/form-data",
            tokenheaderkey: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response, 'reessssssss')
          console.log(response?.data)
          // console.log(response?.data?.result[0][0].Code )
          console.log(response?.data.responseCode, 'noooooo')
          if (response?.data?.responseCode === 1) {
            console.log('enetring that')

            Swal.fire({
              icon: 'error',
              text: response?.data?.responseData,
              confirmButtonText: 'OK',
              confirmButtonColor: "#379592",
              customClass: {
                container: "my-swal",

              },
            })

          }
          else if (response.data.result[0][0].Code === '0') {
            console.log('entering this')
            setWelcomeVideoHash((paths) => [
              ...paths,
              response["data"]["result"][0][0].documentHash,
            ]);


            setWelcomeVideoArray((attach) => {
              return attach.map((attachment) => {
                if (attachment.fileObj === file.fileObj) {
                  console.log('if is working')

                  // Update the docPath property for the matching attachment
                  return {
                    ...attachment,
                    docPath: response["data"]["result"][0][0].documentHash,
                  };

                } else {
                  return attachment;
                }

              });

            });
          }



        })
        .catch((error) => {
          // Handle errors
        });
    });


    console.log(welcomeVideoArray)


    // const uniq = [...new Set(attachments)];


    //   const forminputs = new FormData();

    //   forminputs.append("file", welcomeVideoFile);
    //   forminputs.append("userId", localStorage.getItem("userId"));
    //   forminputs.append("documentContent", "welcome pack video");
    //   forminputs.append("documentType", "WELCOME_PACK");
    //  ;

    //   axios
    //     .post("https://cncdevapi.natsoft.io/api/camps/fileupload", forminputs, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //         tokenheaderkey: localStorage.getItem("token"),
    //       },
    //     })
    //     .then((response) => {
    //       console.log(response,'resssssssssssssssssss');
    //       setNotificationHashPath(
    //       response["data"]["result"][0][0].documentHash
    //     );
    //     })
    //     .catch((error) => {
    //     });
  }

  const removeWelcomeVideoAttachment = (index) => {

    setWelcomeVideoArray([
      ...welcomeVideoArray.slice(0, index),
      ...welcomeVideoArray.slice(index + 1)
    ]);
    hiddenFileInputVideo.current.value = ''

  }

  const handleCombinationFileChange = (event) => {
    event.preventDefault();
    const numSelectedFiles = event.target.files ? event.target.files.length : 0;
    const numTotalFiles = welcomeVideoArray.length + numSelectedFiles;

    for (const file of event.target.files) {

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // setImgsSrc((imgs) => [...imgs, e.target.result]);
        // setAttachments((paths) => [...paths, file]);
        setCombinationArray((attachment) => [
          ...attachment,
          {
            path: e.target.result,
            fileObj: file,
            docPath: "",
            docTitle: file.name,
            docContent: ''
          },
        ]);
      };
    }
  };
  async function uploadCombinationFile(event) {

    event.preventDefault();

    combinationArray.forEach((file) => {
      const forminputs = new FormData();
      { console.log(file, 'lolololo') }

      forminputs.append("file", file.fileObj);
      forminputs.append("userId", localStorage.getItem("userId"));
      forminputs.append("documentContent", file.docContent);
      forminputs.append("documentType", "WELCOME_PACK");

      axios
        .post(`${SERVER_URL}${FILE_UPLOAD}`, forminputs, {
          headers: {
            "Content-Type": "multipart/form-data",
            tokenheaderkey: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response?.data?.responseCode === 1) {
            console.log('yes')
            Swal.fire({
              icon: 'error',
              // iconHtml: `<img src="${GreenCheck}" >`, 
              text: response?.data?.responseData,
              confirmButtonColor: "#379592",
              customClass: {
                container: "my-swal",

              },
            })
            setTimeout(() => {
              console.log(combinationArray, file.docTitle); // Log current values for debugging purposes
              setCombinationArray((combinationArray) => {
                return combinationArray.filter((row) => row.docTitle !== file.docTitle);
              });
            }, 2000);
          }
          else {
            setCombinationHash((paths) => [
              ...paths,
              response["data"]["result"][0][0].documentHash,
            ]);

            setCombinationArray((attach) => {
              return attach.map((attachment) => {
                if (attachment.fileObj === file.fileObj) {
                  // Update the docPath property for the matching attachment
                  return {
                    ...attachment,
                    docPath: response["data"]["result"][0][0].documentHash,
                  };
                } else {
                  return attachment;
                }
              });
            });
          }
        })
        .catch((error) => {
          // Handle errors
        });
    });

  }
  const removeCombinationAttachment = (index) => {

    setCombinationArray([
      ...combinationArray.slice(0, index),
      ...combinationArray.slice(index + 1)
    ]);
    hiddenFileImputCombo.current.value = ''

  }


  async function uploadDocuments(event) {

    event.preventDefault();

    documentArray.forEach((file) => {
      const forminputs = new FormData();

      forminputs.append("file", file.fileObj);
      forminputs.append("userId", localStorage.getItem("userId"));
      forminputs.append("documentContent", "FILE");
      forminputs.append("documentType", "WELCOME_PACK");

      axios
        .post(`${SERVER_URL}${FILE_UPLOAD}`, forminputs, {
          headers: {
            "Content-Type": "multipart/form-data",
            tokenheaderkey: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response?.data?.responseCode === 1) {

            Swal.fire({
              icon: 'error',
              // iconHtml: `<img src="${GreenCheck}" >`, 
              text: response?.data?.responseData,
              confirmButtonColor: "#379592",
              customClass: {
                container: "my-swal",

              },
            })
            setTimeout(() => {
              console.log(documentArray, file.docTitle); // Log current values for debugging purposes
              setDocumentArray((documentArray) => {
                return documentArray.filter((row) => row.docTitle !== file.docTitle);
              });
            }, 2000);
          }
          else {

            setDocumentHash((paths) => [
              ...paths,
              response["data"]["result"][0][0].documentHash,
            ]);
            setDocumentArray((attach) => {
              return attach.map((attachment) => {
                if (attachment.fileObj === file.fileObj) {
                  // Update the docPath property for the matching attachment
                  return {
                    ...attachment,
                    docPath: response["data"]["result"][0][0].documentHash,
                  };
                } else {
                  return attachment;
                }
              });
            });
          }
        })
        .catch((error) => {
          // Handle errors
        });
    });
  }
  const handleDocument = (event) => {
    event.preventDefault();
    const numSelectedFiles = event.target.files ? event.target.files.length : 0;
    const numTotalFiles = welcomeVideoArray.length + numSelectedFiles;

    for (const file of event.target.files) {
      // if (file.type === 'image/jpeg' || file.type === 'image/png') {
      //   setAccept('image/jpeg, image/png');
      // } else if (file.type === 'application/pdf') {
      //   setAccept('application/pdf');
      // } else {
      //   setAccept('');
      // }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // setImgsSrc((imgs) => [...imgs, e.target.result]);
        // setAttachments((paths) => [...paths, file]);
        setDocumentArray((attachment) => [
          ...attachment,
          {
            path: e.target.result,
            fileObj: file,
            docPath: "",
            docTitle: file.name,
            docContent: 'FILE'
          },
        ]);
      };
    }

  };
  const removeDocument = (index) => {

    setDocumentArray([
      ...documentArray.slice(0, index),
      ...documentArray.slice(index + 1)
    ]);
    hiddenFileInputVideo.current.value = ''

  }

  const handleSave = (stat) => {

    console.log('finaldata', welcomeVideoArray)
    console.log([...welcomeVideoArray, ...documentArray, ...combinationArray])
    const fileInfo = [];

    const videoPath = [];
    // if (welcomeVideoArray && welcomeVideoArray.length > 0) {
    //   welcomeVideoArray.forEach((attach) => {
    //     videoPath.push(attach.docPath);
    //   });
    // }
    const comboPath = [];
    if (combinationArray && combinationArray.length > 0) {
      combinationArray.forEach((attach) => {
        console.log(attach,'what is hereeeeeeeee')
        // comboPath.push(attach.docPath);
        // comboPath.push({documentContent: attach.docContent })
        comboPath.push({ documentHash: attach.docPath, documentContent: attach.docContent })
        
      });
    }
    const documentPath = [];
    if (documentArray && documentArray.length > 0) {
      documentArray.forEach((attach) => {
        // documentPath.push(attach.docPath);
        documentPath.push({ documentHash: attach.docPath, documentContent: attach.docContent })
      });
    }

    // const totalFiles = [...videoPath, ...comboPath, ...documentPath];
    const totalFiles = [...comboPath, ...documentPath]
    if (totalFiles && totalFiles.length > 0) {
      totalFiles.forEach((file) => {
        fileInfo.push({ documentKey: "WELCOME_PACK",  documentHash:file.documentHash, documentContent:file.documentContent });
      });
    }
    // let finalData = {
    //   userId: localStorage.getItem('userId'),
    //   campId: campId,
    //   welcomePackType:"Parents",
    //   PreInformation: preInformation,
    //   wpStatus: "DRAFT",
    //   fileHash: fileInfo
    // }
    // console.log(finalData)

    // let apiInfo = {
    //   url: "https://cncdevapi.natsoft.io/api/actcamp/createwelcomepack",
    //   method: "POST",
    //   postData: finalData,
    //   headers: {
    //     "Content-Type": "application/json",
    //     tokenheaderkey: localStorage.getItem("token"),
    //   },
    // };

    if (stat === 'DRAFT') {
      (async () => {

        let finalData = {
          userId: localStorage.getItem('userId'),
          campId: campId,
          welcomePackType: "PARENTS",
          PreInformation: preInformation,
          wpStatus: stat,
          filesInfo: fileInfo,
          videoInfo: videoRow
        }
        console.log(finalData)
        let apiInfo = {
          // url: "https://cncdevapi.natsoft.io/api/actcamp/createwelcomepack",
          url: `${SERVER_URL}${CREATEWELCOME_PACK}`,
          method: "POST",
          postData: finalData,
          headers: {
            "Content-Type": "application/json",
            tokenheaderkey: localStorage.getItem("token"),
          },
        };
        let output = await apiService2(apiInfo)
        let data = output.data.responseData[0][0]
        console.log(output)
        console.log(output.data.responseData[0])
        console.log(output.data.responseData[0][0])
        console.log(output.data.responseData[0][0].Code)
        if (output.data.responseData[0][0].Code === '0') {

          Swal.fire({
            iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
            text: data.Desc,
            confirmButtonText: 'OK',
            confirmButtonColor: "#379592",
            customClass: {
              container: "my-swal",

            },
          }).then(() => {
            history.push(`/WelcomePackRepublish/${campId}`)
          })
        }
        else {
          Swal.fire({
            icon: 'error',
            text: data.Desc,
            confirmButtonText: 'OK',
            confirmButtonColor: "#379592",
            customClass: {
              container: "my-swal",

            },
          }).then(() => {
            history.push(`/WelcomePackRepublish/${campId}`)
          })
        }
      })()
    }
    else if (stat === 'PUBLISHED') {
      Swal.fire({
        iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
        text: "Are you sure you want to Submit?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#379592",
        cancelButtonText: "No",
        cancelButtonColor: "#909090",
        customClass: {
          container: "my-swal",
          icon: 'no-border'

          //    confirmButton: 'your-confirm-button-class',
        },
      }).then((result) => {

        if (result.isConfirmed) {
          (async () => {

            let finalData = {
              userId: localStorage.getItem('userId'),
              campId: campId,
              welcomePackType: "PARENTS",
              PreInformation: preInformation,
              wpStatus: stat,
              filesInfo: fileInfo,
              videoInfo: videoRow
            }
            console.log(finalData)
            let apiInfo = {
              // url: "https://cncdevapi.natsoft.io/api/actcamp/createwelcomepack",
              url: `${SERVER_URL}${CREATEWELCOME_PACK}`,
              method: "POST",
              postData: finalData,
              headers: {
                "Content-Type": "application/json",
                tokenheaderkey: localStorage.getItem("token"),
              },
            };
            let output = await apiService2(apiInfo)
            let data = output.data.responseData[0][0]
            console.log(output)
            console.log(output.data.responseData[0])
            console.log(output.data.responseData[0][0])
            console.log(output.data.responseData[0][0].Code)
            if (output.data.responseData[0][0].Code === '0') {

              Swal.fire({
                iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
                text: data.Desc,
                confirmButtonText: 'OK',
                confirmButtonColor: "#379592",
                customClass: {
                  container: "my-swal",

                },
              }).then(() => {
                history.push(`/WelcomePackRepublish/${campId}`)
              })
            }
            else {
              Swal.fire({
                icon: 'error',
                text: data.Desc,
                confirmButtonText: 'OK',
                confirmButtonColor: "#379592",
                customClass: {
                  container: "my-swal",

                },
              }).then(() => {
                history.push(`/WelcomePackRepublish/${campId}`)
              })
            }
          })()
        }
      })
    }

  }

  const openVideo = (path) => {
    // const videoUrl = "https://cncdevapi.natsoft.io/download/126c716a-012b-4e1e-8ddf-cdaad783ebb6.mp4";
    const videoUrl = path
    const videoWindow = window.open("", "_blank");
    videoWindow.document.write('<video src="' + videoUrl + '" autoplay controls></video>');
    videoWindow.document.close();
  }

  const handleVideoRow = () => {
    setVideoRow([
      ...videoRow,
      {
        id: videoRow.length + 1,
        actualUrl: '',
        description: '',
        thumbnailurl: '',
        videoType: 'WELCOME_PACK',
        videoProvider: 'vimeo'

      },
    ]);
  };

  // const generateThumbnail = async (e, batch) => {
  //   let videoUrl = e.target.value
  //   console.log("Got video URL", e.target.value, batch)
  //   let apiUrl = `https://vimeo.com/api/oembed.json?url=${videoUrl}`
  //   let res = await axios({
  //     url: apiUrl,
  //     method: 'get',
  //     timeout: 8000,
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   })

  //   console.log("Resppp", res)
  //   if (res.status == 200)
  //     // setThumbnailUrl(res.data.thumbnail_url_with_play_button)

  //     setVideoRow(
  //       videoRow.map((row, index) =>
  //         index + 1 === batch.id ? { ...row, thumbnailurl: res.data.thumbnail_url_with_play_button, actualUrl: e.target.value } : row
  //       )
  //     )


  // }
  const generateThumbnail = (e, batch) => {

    (async () => {
      console.log(batch, 'oooooooo')
      let videoUrl = e.target.value
      setVideoRow(
        videoRow.map((row, index) =>
          index + 1 === batch.id ? { ...row, actualUrl: e.target.value } : row
        )
      )
      let apiUrl = `https://vimeo.com/api/oembed.json?url=${videoUrl}`
      let res = await axios({
        url: apiUrl,
        method: 'get',
        timeout: 8000,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      console.log(res)

      if (res.status == 200)
        setVideoRow(
          videoRow.map((row, index) =>
            index + 1 === batch.id ? { ...row, thumbnailurl: res.data.thumbnail_url_with_play_button, actualUrl: e.target.value } : row
          )
        )
    })()
      .catch(() => {
        console.log('wohoooooooooo')
        setVideoRow(
          videoRow.map((row, index) =>
            index + 1 === batch.id ? { ...row, thumbnailurl: ThumbnailImage, actualUrl:  e.target.value } : row
          )
        )
       
      })
      .finally(() => {
        // setLoading(false);
      });

  };
  const handleDesc = (e, item) => {
    setVideoRow(
      videoRow.map((row, index) =>
        index + 1 === item.id ? { ...row, description: e.target.value } : row
      )
    )
  }
  const removeUrlItem = (index) => {

    setVideoRow([
      ...videoRow.slice(0, index),
      ...videoRow.slice(index + 1)
    ]);


  }
  const generateStatic = (e,batch) =>{
    setVideoRow(
      videoRow.map((row, index) =>
        index + 1 === batch.id ? { ...row, actualUrl: e.target.value,thumbnailurl: ThumbnailImage} : row
      )
    )
  }
  return (
    <Box>
      <Stack direction='row' spacing={1}>
        <Link to={`/ApplicantsReview/${campId}`}>
          <Box
            component="img"
            sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
            height="20"
            alt="Logo"
            src={ArrowBack}
          ></Box>
        </Link>
        <Box className="gilroy" alignSelf="center">
          Create Welcome Pack for Parents
        </Box>
      </Stack>
      <Box my='1rem'>
        <TextField
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={4}
          name="Pre-information"
          sx={{ width: '50%' }}
          onChange={(event) => handlePreInformation(event)}
          value={preInformation}
        // onChange={(event) => storeInfo(event)}

        // defaultValue="Default Value"
        />

      </Box>
      <Box py='1.5rem'><Divider /></Box>
      <Box >Welcome Videos</Box>
      <Button
        // type="submit"
        variant="contained"
        mx="0rem"
        sx={{
          px: "2rem",
          bgcolor: "#379592",
          my: "0.75rem",
          textTransform: "none",
          width: "138px",
          "&:hover": { background: "#379592" },
        }}
        onClick={handleVideoRow}
        className="gilroy"
      >Add</Button>
      {videoRow.length > 0 && videoRow.map((item, index) => {
        return (
          // <span className="thumbnail-span">
          <div  >
            {/* {item.actualUrl != null ? ( */}
            <div key={index} className="thumbnail-container">
              <Grid container display='row' alignItems='center' >
                <Grid item md={4} sx={{ textAlign: 'center' }}>

                  <TextField
                    id="outlined-basic"
                    multiline
                    label="Vimeo URL"
                    variant="outlined"

                    onChangeCapture={(e) => { generateThumbnail(e, item) }}
                    value={item.actualUrl}
                    size='small'
                    sx={{ my: '0.5rem', width: '90%' }}
                  >

                  </TextField>

                  <Box sx={{ textAlign: 'center' }}>
                    {item.thumbnailurl !== '' ?
                      (
                        <a href={item.actualUrl} target='_blank'>
                          <img
                            src={item.thumbnailurl}
                            alt = "Not a valid img"
                            style={{ height: "45%", width: "50%", my: '0.5rem' }}
                          />
                        </a>
                      ) : ('')}



                  </Box>

                </Grid>

                <Grid item md={8} >
                  <Stack direction ='row' >
                  <Box sx={{width:'100%'}} >
                    <TextField
                      id="outlined-multiline-static"
                      label="Description"
                      multiline
                      rows={5}
                      name="description"
                      onChange={(e) => { handleDesc(e, item) }}
                      value={item.description}
                      // onChange={(event) => handleComboDescription(event, index)}
                      sx={{ width: '100%', borderRadius: '20px' }}
                    
                    />
                  </Box>
                  < div className="video-thumb">

                    <img
                      src={CrossGreen}
                      onClick={() => removeUrlItem(index)}
                      // className="video-thumb"
                    />

                  </div>
                  </Stack>
                 
                </Grid>
              </Grid>
            </div>


            {/* ) : (
                  ""
                )} */}

            {/* {item.actualUrl != null ? ( */}
            {/* < Box>

                    <img
                      src={CrossGreen}
                      onClick={() => removeUrlItem(index)}
                      className="video-thumb"
                    />

                  </Box> */}

            {/* ) : (
                  ""
                )} */}
          </div>

        )
      })}
      {/* <Box >
        Upload Welcome Video
      </Box>
      <Grid>

        <form onSubmit={uploadNotificationFile} encType="multipart/form-data">
          <Button
            onClick={(event) => (hiddenFileInputVideo.current.click(), event.currentTarget.value = null)}
            //     (event)=> { 
            //       event.currentTarget.value = null
            //  }
            variant="contained"

            mx="0rem"
            sx={{
              px: "2rem",
              bgcolor: "#379592",
              my: "0.45rem",
              // width:"15%",
              textTransform: "none",
              "&:hover": { background: "#379592" },
            }}
            className="gilroy"
          >
            Choose File
          </Button>
          <Box>
            <input
              type="file"
              multiple
              style={{ display: "none" }}
              placeholder=""
              ref={hiddenFileInputVideo}
              onChange={handleNotificationFileChange}
              // accept="image/jpeg, image/png"
              accept="video/*"

            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            mx="0rem"
            sx={{
              px: "2rem",
              bgcolor: "#379592",
              my: "0.75rem",
              textTransform: "none",
              width: '138px',
              "&:hover": { background: "#379592" },
            }}
            className="gilroy"
          >
            Upload
          </Button>

        </form>
       
      </Grid>
      <Box sx={{ color: '#0075CE' }}>
        {console.log(welcomeVideoArray)}
        {welcomeVideoArray.map((link, index) => {
          return (
            
            <div>
              {link.path != null ? (

                <Grid container display='flex' my='0.5rem' className="thumbnail-span" >

                  <Grid item md={3}>
                    <Box onClick={() => openVideo(link.path)} sx={{ cursor: 'pointer' }} >
                      <video style={{ width: "100%", height: "100%" }}>
                        <source src={link.path} type="video/mp4" />
                        <source src={link.path} type="video/ogg" />
                      </video>
                      
                      <Box>{link.docPath !== '' ? ('Uploaded') : ('Not Uploaded')}</Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <img
                      src={CrossGreen}
                      onClick={() => removeWelcomeVideoAttachment(index)}
                      className="cross-pdf"
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </div>
          )



        })}

      </Box> */}

      <Box py='1.5rem'><Divider /></Box>
      <Box >
        Photos 
      </Box>
      <Grid>

        <form onSubmit={uploadCombinationFile} encType="multipart/form-data">
          <Button
            onClick={(event) => (hiddenFileImputCombo.current.click(), event.currentTarget.value = null)}
            //     (event)=> { 
            //       event.currentTarget.value = null
            //  }
            variant="contained"
            // type="submit"
            mx="0rem"
            sx={{
              px: "2rem",
              bgcolor: "#379592",
              my: "0.45rem",
              // width:"15%",
              textTransform: "none",
              "&:hover": { background: "#379592" },
            }}
            className="gilroy"
          >
            Choose File
          </Button>
          <Box>
            <input
              type="file"
              multiple
              style={{ display: "none" }}
              placeholder=""
              ref={hiddenFileImputCombo}
              onChange={handleCombinationFileChange}
              accept="image/jpeg, image/png,image/jpg,image/gif,image/svg"
            // accept="video/*"
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            mx="0rem"
            sx={{
              px: "2rem",
              bgcolor: "#379592",
              my: "0.75rem",
              textTransform: "none",
              width: '138px',
              "&:hover": { background: "#379592" },
            }}
            className="gilroy"
          >
            Upload
          </Button>
        </form>
      </Grid>
      <Box sx={{ color: '#0075CE' }}>
        {combinationArray.map((link, index) => {
          console.log(link)
          let x = (link.docTitle).search('mp4') || (link.docTitle).search('ogg')

          { console.log(link.docTitle, x, 'hereeee') }

          return (
            <span className="thumbnail-span">

              {link.path != null ? (
                <Box>
                  <Grid container display='row' alignItems='center' my='0.5rem'>
                    <Grid item md={4}>
                      {x !== -1 ?
                        (<Box onClick={() => openVideo(link.path)} sx={{ cursor: 'pointer' }}>
                          <video  style={{ width: "80%", height: "140px", borderRadius: '10px',alignSelf:'center' }}>
                            <source src={link.path} type="video/mp4" />
                            <source src={link.path} type="video/ogg" />
                          </video>

                        </Box>) :
                        (<Box sx={{ color: '#0075CE' }}>
                          <img
                            src={link.path}
                            style={{ width: "80%", height: "140px", borderRadius: '10px',alignSelf:'center' }}
                            // style={{ width: "80%", height: '120px', border: '1px solid black', alignSelf: 'center' }}

                          />

                        </Box>)}

                    </Grid>

                    <Grid item md={8}>
                      <Box sx={{ width: '100%' }} >
                        <TextField
                          id="outlined-multiline-static"
                          label="Description"
                          multiline
                          rows={5}
                          name="description"
                          // value={formData.description}
                          onChange={(event) => handleComboDescription(event, index)}
                          sx={{ width: '100%', borderRadius: '20px' }}
                        // defaultValue="Default Value"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>{link.docPath !== '' ? ('Uploaded') : ('Not Uploaded')}</Box>
                </Box>


              ) : (
                ""
              )}

              {link.path != null ? (
                <Box>

                  <img
                    src={CrossGreen}
                    onClick={() => removeCombinationAttachment(index)}
                    className="cross-combo"
                  />

                </Box>

              ) : (
                ""
              )}
            </span>
            // <Grid container display='flex' my='0.5rem' >
            //   <Grid container display='row' alignItems='center'>
            //     <Grid item md={3}>
            //       <Box sx={{ color: '#0075CE' }}>
            //         <img
            //           src={link.path}
            //           style={{ width: "80%", height: '120px', border: '1px solid black', alignSelf: 'center' }}

            //         />
            //       </Box>
            //     </Grid>
            //     <Grid item md={8}>
            //       <Box sx={{ width: '100%' }} >
            //         <TextField
            //           id="outlined-multiline-static"
            //           label="Description"
            //           multiline
            //           rows={4}
            //           name="description"
            //           // value={formData.description}
            //           onChange={(event) => handleComboDescription(event, index)}
            //           sx={{ width: '100%', borderRadius: '20px' }}
            //         // defaultValue="Default Value"
            //         />
            //       </Box>
            //     </Grid>
            //   </Grid>
            //   <Grid >
            //     <Box>

            //       <img
            //         src={CrossGreen}
            //         onClick={() => removeCombinationAttachment(index)}
            //         // className="cross-img"
            //       />

            //     </Box>
            //   </Grid>

            // </Grid>
          )
        })}
        {/* <span className="thumbnail-span">
          {welcomeVideoArray != null ? (
            <a href={welcomeVideoArray[0].path} target="_blank">
              {welcomeVideoArray[0].docTitle}
            </a>
          ) : (
            ""
          )}
          {welcomeVideoArray != null ? (
            <img
              src={CrossGreen}
            onClick={removeimg}
              className="cross-img"
            />
          ) : (
            ""
          )}
        </span> */}
        {/* <span className="thumbnail-span">
              {welcomeVideoArray != null ? (
                <img
                  src={welcomeVideoArray.path}

                  style={{ height: "20%", width: "20%", marginTop: "0.5%" }}
                />
              ) : (
                ""
              )}

              {welcomeVideoArray != null ? (
                <img
                  src={CrossGreen}
                  onClick={removeimg}
                  className="cross-img"


                />
              ) : (
                ""
              )}
            </span> */}
      </Box>
      <Box py='1.5rem'><Divider /></Box>

      <Box >
        Documents
      </Box>
      <Grid>

        <form onSubmit={uploadDocuments} encType="multipart/form-data">
          <Button
            onClick={(event) => (hiddenFileInputDocument.current.click(), event.currentTarget.value = null)}
            //     (event)=> { 
            //       event.currentTarget.value = null
            //  }
            variant="contained"
            // type="submit"
            mx="0rem"
            sx={{
              px: "2rem",
              bgcolor: "#379592",
              my: "0.45rem",
              // width:"15%",
              textTransform: "none",
              "&:hover": { background: "#379592" },
            }}
            className="gilroy"
          >
            Choose File
          </Button>
          <Box>
            <input
              type="file"
              multiple
              style={{ display: "none" }}
              placeholder=""
              ref={hiddenFileInputDocument}
              onChange={handleDocument}
              accept='application/pdf,application/PDF'
            // accept={accept}
            // accept="image/jpeg, image/png"
            // accept="video/*"
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            mx="0rem"
            sx={{
              px: "2rem",
              bgcolor: "#379592",
              my: "0.75rem",
              textTransform: "none",
              width: '138px',
              "&:hover": { background: "#379592" },
            }}
            className="gilroy"
          >
            Upload
          </Button>
        </form>
      </Grid>
      <Box sx={{ color: '#0075CE' }}>
        {documentArray.map((link, index) => {
          return (
            // <span className="thumbnail-span">
            //   {/* <img src={link} className="img-height" style={{ height: "17%", width: "17%" ,marginRight:"1%"}} /> */}

            //   {link.path != null ? (
            //     // <Box sx={{ color: '#0075CE' }}>


            //     // </Box>
            //     <Grid container display='flex' my='0.5rem'>
            //       <Box
            //         component="img"
            //         sx={{ height: "2%", width: "2%", mx: '0.5rem' }}

            //         alt="Logo"
            //         src={PdfIcon}
            //       ></Box>
            //       <a href={link.path} target="_blank">
            //         {link.docTitle}
            //       </a>

            //     </Grid>
            //   ) : (
            //     ""
            //   )}

            //   {link.path != null ? (
            //     <img
            //       src={CrossGreen}
            //       onClick={() => removeDocument(index)}
            //       className="cross-img"
            //     />
            //   ) : (
            //     ""
            //   )}
            // </span>
            <Box>
              <Grid container display='flex' my='0.5rem' >
                <Grid item  >
                  <Box
                    component="img"
                    sx={{ height: "70%", mx: '0.5rem' }}

                    alt="Logo"
                    src={PdfIcon}
                  ></Box>
                  <a href={link.path} target="_blank">
                    {link.docTitle}
                  </a>

                </Grid>
                <Grid item>
                  <img
                    src={CrossGreen}
                    onClick={() => removeDocument(index)}
                    style={{ height: '15px', cursor: 'pointer' }}
                  // className="cross-img"
                  // className='cross-pdf'
                  />
                </Grid>
              </Grid>
              <Box>{link.docPath !== '' ? ('Uploaded') : ('Not Uploaded')}</Box>
            </Box>
          )
        })}
        {/* <span className="thumbnail-span">
    {welcomeVideoArray != null ? (
      <a href={welcomeVideoArray[0].path} target="_blank">
        {welcomeVideoArray[0].docTitle}
      </a>
    ) : (
      ""
    )}
    {welcomeVideoArray != null ? (
      <img
        src={CrossGreen}
      onClick={removeimg}
        className="cross-img"
      />
    ) : (
      ""
    )}
  </span> */}
        {/* <span className="thumbnail-span">
        {welcomeVideoArray != null ? (
          <img
            src={welcomeVideoArray.path}

            style={{ height: "20%", width: "20%", marginTop: "0.5%" }}
          />
        ) : (
          ""
        )}

        {welcomeVideoArray != null ? (
          <img
            src={CrossGreen}
            onClick={removeimg}
            className="cross-img"


          />
        ) : (
          ""
        )}
      </span> */}
      </Box>
      {/* <div>
        <h1>The a target attribute</h1>
        <p>
          Open link in a new window or tab:{" "}
          <a href="https://www.w3schools.com" target="_blank">
            Visit W3Schools!
          </a>
        </p>
        <button onClick={()=>openVideo()}>Open video in new tab</button>
      </div> */}

      <Grid container justifyContent='flex-end' >
        <Stack direction='row' >
          <Button
            variant="contained"
            sx={{
              px: "2rem",
              mx: "1rem",
              bgcolor: "#4C9F70",
              textTransform: "none",
              "&:hover": { background: "#4C9F70" },
              width: '100px'
            }}
            className="gilroy"
            onClick={() => handleSave('PUBLISHED')}
          >
            Publish
          </Button>
          <Button
            variant="contained"
            sx={{
              px: "2rem",
              mx: "1rem",
              bgcolor: "#379592",
              textTransform: "none",
              "&:hover": { background: "#379592" },
              width: '100px'
            }}
            className="gilroy"
            onClick={() => handleSave('DRAFT')}
          >
            Save
          </Button>

          {/* <Link className='link2' to={`/ApplicantsReview/${campId}`}> */}
          <Button
            variant="contained"
            sx={{
              px: "2rem",
              mx: "1rem",
              bgcolor: "#909090",
              textTransform: "none",
              "&:hover": { background: "#909090" },
              width: '100px'
            }}
            className="gilroy"
            onClick={() => history.push(`/ApplicantsReview/${campId}`)}
          >
            Cancel
          </Button>
          {/* </Link> */}
        </Stack>

      </Grid>

    </Box>

  );
}


