import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { FormControl, Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Link, useHistory, useParams } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "../../index.css";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { apiService, apiService2 } from "../../Services/apiService";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import {
  SERVER_URL,
  US8_URL
} from "../../Services/apiEndpints";
import Popover from '@mui/material/Popover';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const rows = [
  { sno: 1, Participants: "Rosey", Status: "Pending", profileId: 2 },
  { sno: 2, Participants: "Maria", Status: "Draft", profileId: 3 },
  { sno: 3, Participants: "Ben", Status: "Completed", profileId: 4 },
];

export default function GoalCamp() {
  const [group, setGroup] = useState("");
  const [groupData, setGroupData] = useState("");
  const [campDataList, setCampDataList] = useState({});
  const [campStaffInfo, setCampStaffInfo] = useState([]);
  const [campParticipantsInfo, setCampParticipantsfInfo] = useState([]);
  const selectedCampDetails = useSelector((state) => state?.Camp?.campData);
  const [campListData, setCampListData] = useState([]);
  const [asignGrp, setAssignGrp] = React.useState([])
  const history = useHistory();
  const ShowButton = false;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const groupHandleChange = async (event) => {
    setGroup(event.target.value);

    let group = event.target.value;

    let gettran = {
      _methodId: "4",
      campId: camp_id,

      userId: localStorage.getItem("userId"),

      groupName: group,
    };

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/us8",
      url: `${SERVER_URL}${US8_URL}`,
      method: "POST",
      postData: gettran,
      headers: {
        "Content-Type": "application/json",

        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let login = await apiService2(apiInfo);
    console.log("login", login);

    if (login?.data?.responseCode === 0) {
      console.log("loginrespon", login?.data?.responseData[0].length);
      if (
        login?.data?.responseData &&
        // login?.data?.responseData.length &&
        login?.data?.responseData[0].length > 0
      ) {

        const campDataList = login?.data?.responseData[0];
        const staffInfo = JSON.parse(campDataList[0]?.staffInfo);
        const participantsInfo = JSON.parse(campDataList[0]?.participantsInfo);
        console.log("participantsInfo", participantsInfo);

        if (participantsInfo && participantsInfo.length) {
          setCampParticipantsfInfo(participantsInfo);
          console.log(participantsInfo);
        }
        if (staffInfo.length && staffInfo[0].staffList.length) {
          setCampStaffInfo(staffInfo[0].staffList);
        } else {
          setCampStaffInfo([]);
        }
        if (participantsInfo && participantsInfo.length) {
          setCampParticipantsfInfo(participantsInfo);
        } else {
          setCampParticipantsfInfo([]);
        }

        // setLoading(false);
        console.log("campDataList", campDataList);
        setCampDataList(campDataList[0]);
      } else {
        setCampDataList({});
        setCampStaffInfo([]);
      }
    }
  };

  // item.rolesname === roleName

  // const getStaffNameByRole = (roleName) => {
  //   console.log("campStaffInfo", campStaffInfo, roleName);
  //   let staffData = (campStaffInfo || []).find(
  //     (item) => item.rolesname === roleName
  //   );

  //   return staffData?.staffName;
  // };

  const getStaffNameByRole = (roleName) => {

    console.log("campStaffInfo", campStaffInfo, roleName);

    let staffData = (campStaffInfo || []).filter(

      (item) => item.rolesname === roleName

    );

 

    return staffData;

  };
  console.log("group", group);
  let { camp_id } = useParams();

  React.useEffect(() => {
    (async () => {

      let gettran = {
        _methodId: "3",

        campId: camp_id,
        userId: localStorage.getItem("userId"),
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/us8",
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);

      const DataCampList = login.data.responseData[0];
      // setLoading(false);

      console.log(DataCampList);

      setGroupData(DataCampList);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => { });
  }, []);


  console.log("campStaffInfo", campStaffInfo);

  console.log("campParticipantsInfo", campParticipantsInfo);
  React.useEffect(() => {

    console.log(localStorage.getItem('groupInfo'), 'insideee')
    console.log(JSON.parse(localStorage.getItem('groupInfo')), 'nowwww')
    // arr.push(localStorage.getItem('groupInfo'))
    setAssignGrp(JSON.parse(localStorage.getItem('groupInfo')))

  }, [])
  return (
    <>
      <Grid container display="flex" sx={{ mt: "2rem" }}>
        <Grid item xs={12} sm={6} md={9}>
          <Stack direction="row" spacing={1}>
            <Link to="/CampSummaryReports" className="link2">
              <Box
                alignSelf="center"
                component="img"
                sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>

            <Box alignSelf="center" px="1rem">
              {""}
              Camp Summary Reports
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={9} sx={{ ml: "0.5rem" }}>
        <Grid container display="flex" alignItems="flex-end">

          <Grid item xs={12} md={4}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Group
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={group}
                onChange={groupHandleChange}
                label="Group"
              >
                {/* {(groupData || []).map((camp, index) => (
                  <MenuItem value={camp.groupNames}>{camp.groupNames}</MenuItem>
                ))}  */}

                {/* {camp.group_info.map((item)=>(
                  <MenuItem value={camp.groupNames}>{camp.groupNames}</MenuItem>
                  ))}  */}

                {(asignGrp && asignGrp).map((item, index) => (
                  <MenuItem key={index} value={item}>{item}</MenuItem>
                ))}

              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="(Name of Camp Manager Created this camp)"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className="text-applireviewMedMq"
              value={campDataList?.createdCampManager}
              sx={{ width: 350 }}
            ></TextField>
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          alignItems="flex-end"
          sx={{ mt: "1rem" }}
        >
          <Grid item xs={12} md={4}>
            {/* {console.log('hereeee',campDataList?.campStartDateTime)} */}
            <TextField
              id="standard-basic"
              label="Start Date"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              className="width-text"
              value={campDataList?.campStartDateTime}
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="End Date"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className="text-listitemMq"
              value={campDataList?.campEndDateTime}
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Group Leader"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className="text-listitemMq"
              value={

                getStaffNameByRole("GROUP LEADER")[0]?.staffName || ""

              }
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          alignItems="flex-end"
          sx={{ mt: "1rem" }}
        >
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Therapist"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className="width-text"
              value={

                getStaffNameByRole("THERAPIST")[0]?.staffName || ""

              }
              
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Medication Manager"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={

                getStaffNameByRole("MEDICATION MANAGER")[0]?.staffName || ""

              }
              className="text-listitemMq"
              
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Support Staff"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={

                getStaffNameByRole("SUPPORT STAFF")

                  .map((item) => item.staffName)

                  .join(", ")

              }
              className="text-listitemMq"
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
        </Grid>
      </Grid>

      {/* Table code starts here */}

      <TableContainer component={Paper} sx={{ marginTop: "3rem" }}>
        <Table sx={{ minWidth: 450 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Sl. No</StyledTableCell>

              <StyledTableCell sx={{ textAlign: "center" }}>
                {/* <Button  onClick={()=>testFuncton('firstName')}>First Name</Button>  */}
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Participants
                  </Box>
                </Grid>
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                   Camp Summary Status
                  </Box>
                </Grid>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Action
                  </Box>
                </Grid>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {console.log(campParticipantsInfo)}
            {campParticipantsInfo.map((row, index) => (
              <TableRow
                // key={row.userId}
                className="normal"
                sx={{
                  "& .MuiTableCell-root": { py: "0.35rem" },
                }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                // sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                >
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                >
                  {row?.childName}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                >
                  {row?.camp_summary_status}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                >
                  <Grid container justifyContent="center">
                    <Grid item>
                      {/* {row.campParticipantsInfo} */}
                      {console.log(row.goal_status, 'hereeeeeeeeeeeeeeee')}
                      {(row.camp_summary_status === "Pending") && (
                        <Button
                          variant="outlined"
                          disabled={row.goal_status === 'Pending'}
                          sx={{
                            px: "2rem",
                            mx: "1rem",
                            color: "#379592",
                            textTransform: "none",
                          }}
                          className="gilroy goal-btn"
                          onClick={() =>
                            history.push(
                              `/CreateCampSummary/${camp_id}/${row?.applicationId}`
                            )
                          }
                          // onMouseEnter={handlePopoverOpen}
                          // onMouseLeave={handlePopoverClose}
                        >
                          Camp Summary
                        </Button>
                      )}
                      {(row.camp_summary_status === "Completed") && (
                        <Button
                          variant="outlined"

                          disabled={row.goal_status === 'Pending'}
                          sx={{
                            px: "2rem",
                            mx: "1rem",
                            color: "#379592",
                            textTransform: "none",
                          }}
                          className="gilroy goal-btn"
                          onClick={() =>
                            history.push(
                              `/ViewCampSum/${camp_id}/${row?.applicationId}`
                            )
                          }
                          // onMouseEnter={handlePopoverOpen}
                          // onMouseLeave={handlePopoverClose}
                        >
                          Camp Summary
                        </Button>
                      )}

                      {(row.camp_summary_status === "Draft") && (
                        <Button
                          variant="outlined"
                          disabled={row.goal_status === 'Pending'}
                          sx={{
                            px: "2rem",
                            mx: "1rem",
                            color: "#379592",
                            textTransform: "none",
                          }}
                          className="gilroy goal-btn"
                          onClick={() =>
                            history.push(
                              `/ViewCampSummary/${camp_id}/${row?.applicationId}`
                            )
                          }
                          // onMouseEnter={handlePopoverOpen}
                          // onMouseLeave={handlePopoverClose}
                        >
                          Camp Summary
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 1,fontSize:'12px' }}>
          "Camp summary report requires all the goals created to be rated by both parent and camp staff, Please check and complete it and try again"
          </Box>
      </Popover>
    </>
  );
}
