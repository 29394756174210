import React, { useState, createContext } from "react";
import Button from "@mui/material/Button";

import {
    CardActionArea,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,

    Chip,
    CardContent,
    Divider,
    Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";

import { apiService, apiService2 } from "../Services/apiService";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../Assets/Icons/Icons/BlackUser.png"
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png"

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams} from "react-router-dom";
import Swal from 'sweetalert2'
import { useReactToPrint } from 'react-to-print';
import {
    SERVER_URL,
    GET_GROUPNAME,
    GROUPWISE_PARTICIPANTLIST,
    VIEW_INCIDENT
} from "../Services/apiEndpints";

export default function ViewParticipants() {
    const [grpName, setGrpName] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [selectGrp, setSelectGrp] = React.useState('All')
    const [campApplicantList, setCampApplicantList] = React.useState([

    ])
    const [array, setArray] = React.useState([
        { sno: 1, applicants: 'Rosey', group: 'Group 1' },
        { sno: 2, applicants: 'Maria', group: 'Group 2' },
        { sno: 3, applicants: 'Ben', group: 'Group 3' },
        { sno: 4, applicants: 'Den', group: 'Group 3' }
    ])


    const componentRef = React.useRef();
    let { campId } = useParams();
    const history = useHistory()
    const theme = createTheme({
        typography: {
            fontFamily: "gilroyMedium",
        },
        palette: {
            secondary: {
                main: "#379592",
            },
        },

        Muidisabled: {
            styleOverrides: {
                backgroundColor: "purple",
            },
        },
    });
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: theme.palette.common.black,
            backgroundColor: "rgba(55, 149, 146, 1)",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));


    const handleChange = (event) => {
        setSelectGrp(event.target.value);
        console.log(event.target.value, 'doooooooooooooooo')
    };

    let filteredList = campApplicantList && campApplicantList.filter((item) => {
        // if(item.group_assigned !== null)
        return (
            // item && item.group_assigned.toLowerCase().includes(selectGrp.toLowerCase())
            item && item.group_assigned.toLowerCase() === selectGrp.toLowerCase()
        )


    })

    if (selectGrp === 'All') {
        filteredList = campApplicantList && campApplicantList
    }

    React.useEffect(() => {
        (
            async () => {
                // setLoading(true)
                console.log(campId)
                let dataSent = {
                    userId: localStorage.getItem('userId'),
                    campId: campId
                }

                let apiInfo = {
                    // url: "https://cncdevapi.natsoft.io/api/actcamp/getgroupnames",
                    url: `${SERVER_URL}${GET_GROUPNAME}`,
                    method: "POST",
                    postData: dataSent,
                    headers: {
                        "Content-Type": "application/json",
                        tokenheaderkey: localStorage.getItem("token"),
                    },

                }

                let output = await apiService2(apiInfo)
                if (output.data.responseCode === 0) {
                    let groupList = output.data.responseData[0]
                    console.log('list', groupList)
                    setGrpName(groupList)
                }
            }
        )()

    }

        , [])
    React.useEffect(() => {
        (
            async () => {
                // setLoading(true)
                let dataSent = {
                    userId: localStorage.getItem('userId'),
                    campId: campId
                }

                let apiInfo = {
                    // url: "https://cncdevapi.natsoft.io/api/actcamp/getgroupwiseparticipantslist",
                    url: `${SERVER_URL}${GROUPWISE_PARTICIPANTLIST}`,
                    method: "POST",
                    postData: dataSent,
                    headers: {
                        "Content-Type": "application/json",
                        tokenheaderkey: localStorage.getItem("token"),
                    },
                }
                let output = await apiService2(apiInfo)
                if (output.data.responseCode === 0) {
                    let campList = output.data.responseData[0]
                    console.log('list', campList)
                    setCampApplicantList(campList)
                }
            }
        )()

    }

        , [])
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    // React.useEffect(() => {
    //     (
    //         async () => {
    //             // setLoading(true)
    //             let dataSent = {
    //                 userId: localStorage.getItem('userId'),
    //                 campId: campId,
                    
    //                 incidentId: incidentReportId,
    //             }

    //             let apiInfo = {
    //                 url: `${SERVER_URL}${VIEW_INCIDENT}`,
    //                 method: "POST",
    //                 postData: gettran,
    //                 headers: {
    //                   "Content-Type": "application/json",
    //                   tokenheaderkey: localStorage.getItem("token"),
    //                 },
    //               };
    //             let output = await apiService2(apiInfo)
    //             if (output.data.responseCode === 0) {
    //                 let incidentDetails = output.data.responseData[0]
                    
    //                 setIncidentId(incidentDetails)
    //             }
    //         }
    //     )()

    // }

    //     , [])
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box>
                <Grid container display='flex' justifyContent='space-between'>
                    <Grid item>
                        <Stack direction='row' spacing={1}>
                            <Link className='link2' to={`/EditCamp/${campId}`} >
                                {/* <Link className='link2' to={'/EditCamp'} > */}
                                <Box
                                    component="img"
                                    sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
                                    alt="Logo"
                                    src={ArrowBack}
                                ></Box>
                            </Link>
                            <Box alignSelf="center">Participants</Box>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Link to={`/AddParticipants/${campId}`} className="link2">
                            <Button
                                variant="contained"
                                sx={{
                                    px: "1rem",
                                    //  width:'130px',
                                    mx: "0.5rem",
                                    bgcolor: "#379592",
                                    textTransform: "none",
                                    "&:hover": { background: "#379592" },
                                }}
                                className="gilroy"
                            >
                                Edit Participants
                            </Button>
                        </Link>
                    </Grid>

                </Grid>
                <Grid container display='flex' alignItems='center' mt='0.5rem'>
                    <Box my='0.5rem'></Box>
                    <Box>
                        <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                            <InputLabel id="demo-simple-select-autowidth-label">Select</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={selectGrp}
                                onChange={handleChange}
                                label="Select"

                            >
                                <MenuItem value='All'>All</MenuItem>
                                <MenuItem value='Not Assigned'>Not Assigned</MenuItem>
                                {grpName && grpName.map((item, index) => (
                                    // <div key={index}>
                                    <MenuItem key={index} value={item.groupNames} >{item.groupNames}</MenuItem>
                                    // </div>
                                ))}


                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                {loading === true
                    ? (
                        <Grid container justifyContent='center' alignItems='center' >
                            <CircularProgress />
                        </Grid >
                    )
                    : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 450 }} aria-label="customized table" my='1rem' >

                                <TableHead >

                                    <StyledTableRow>
                                        <StyledTableCell align='center'>Sl. No</StyledTableCell>
                                        <StyledTableCell align="center">Applicants</StyledTableCell>
                                        <StyledTableCell align="center">Group</StyledTableCell>
                                        <StyledTableCell align="center">View</StyledTableCell>

                                    </StyledTableRow>
                                </TableHead>
                                <TableBody >
                                    {filteredList && filteredList.map((row, index) => (
                                        <TableRow key={index} sx={{
                                            "& .MuiTableCell-root": { py: "0.45rem" },
                                        }}>
                                            <StyledTableCell component="th" scope="row" align="center" >
                                                {index + 1}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" >

                                                <Grid container display='flex' alignItems='center' >
                                                    <Grid item md={3}>
                                                        <Box
                                                            component="img"
                                                            sx={{ height: "2rem", width: "2rem", cursor: 'pointer' }}
                                                            alt="Logo"
                                                            src={row.childPhoto}
                                                        ></Box>
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <Box>{row.childName}</Box>
                                                    </Grid>

                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="center" >
                                                {row.group_assigned}
                                            </StyledTableCell>

                                            <StyledTableCell component="th" scope="row" align="center" >
                                                <Stack direction='row' spacing={3} justifyContent='center' >
                                                    <Link to={`/ChildProfile/${campId}/${row.childId}`} className='link2'>
                                                        <Button variant="outlined"
                                                            //  onClick={handlePrint}
                                                            sx={{
                                                                bgcolor: "white",
                                                                color: '#718F94',
                                                                textTransform: 'none',
                                                                borderColor: '#718F94',
                                                                "&:hover": { background: "white", color: '#718F94', borderColor: '#718F94', },
                                                                width: "140px"
                                                            }}>
                                                            Profile
                                                        </Button>
                                                    </Link>
                                                    <Link to={`/CampMangerViewIncidentReport/${row.incidentId}/${campId}`} className='link2'>
                                                    <Button variant="outlined"
                                                    disabled={row.incidentId === null}
                                                        sx={{
                                                            bgcolor: "white",
                                                            color: '#718F94',
                                                            textTransform: 'none',
                                                            borderColor: '#718F94',
                                                            "&:hover": { background: "white", color: '#718F94', borderColor: '#718F94', },
                                                            width: "140px"
                                                        }}
                                                        onClick={()=>{localStorage.setItem('incidentRoute','participant')}}
                                                        >
                                                        
                                                        Incident Report
                                                    </Button>
                                                    </Link>

                                                </Stack>


                                            </StyledTableCell>
                                        </TableRow>
                                    ))}


                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}



            </Box>
        </ThemeProvider>



    );
}
