import React, { useState } from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Modal from '@mui/material/Modal';
import Stack from "@mui/material/Stack";
import "../index.css";
import Grid from "@mui/material/Grid";
import { alpha } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Calender from "../Assets/Icons/Icons/calendar.png";
import Add from "../Assets/Icons/Icons/add.png"
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import FileIcon from "../Assets/Icons/Icons/file.svg"


import CampImage from "../Assets/Icons/Icons/Background-image.png"

import {Link} from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  textTransform:'none',
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#909090',
  '&:hover': {
    backgroundColor: '#909090',
  },
}));

const PublishButton = styled(Button)(({ theme }) => ({
  textTransform:'none',
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#379592',
  '&:hover': {
    backgroundColor: '#379592',
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  textTransform:'none',
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: 'white',
  color:'#379592',
  '&:hover': {
    backgroundColor: 'white',
    borderColor:'#379592'
  },
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}



const currencies = [
  {
    value: 'Girls Camp',
    label: 'Girls Camp',
  },
  {
    value: 'Boys Camp',
    label: 'Boys Camp',
  },
  {
    value: 'Support Camp',
    label: 'Support Camp',
  },
  
];

const rowCount =[1,2,3,4]
const rows = [
  createData(" "," ", "", "", ""),
  createData(
    " ",
    " ",
    "",
    "",
    ""
  ),
  createData(
    " ",
    "",
    "",
    "",
    ""
  ),
  
];

const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});


function ChildModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };



  
   // Create a reference to the hidden file input element
   const hiddenFileInput = React.useRef(null);
   // Programatically click the hidden file input element
   // when the Button component is clicked
   const handleClick = event => {hiddenFileInput.current.click();
   };
   // Call a function (passed as a prop from the parent component)
   // to handle the user-selected file 
   const handleChange = async event => {
     const fileUploaded = event.target.files[0];
     console.log("fileUploaded",fileUploaded);
    //  let forminputs = new FormData()
    //  forminputs.append("file",fileUploaded)
    //  forminputs.append("userId",7)
    //  forminputs.append("documentKey","childphoto")
    //  forminputs.append("documentContent","content")
    //  let apiInfo = {
    //    url: "https://cncdevapi.natsoft.io/api/camps/fileupload",
    //    method: "POST",
    //    postData: forminputs,
    //  };
    //  console.log("process", process.env);
    //  let response = await apiService(apiInfo);
    //  console.log("response", response);
    //  // props.handleFile(fileUploaded);
   };

  //  const image_input = document.querySelector("#image_input");
  //  var uploaded_image = ""


  //  image_input.addEventListener("change", function(){
  //   const reader = new FileReader();
  //     reader.addEventListener("load", () => {
  //       uploaded_image = reader.result;
  //       document.querySelector("#display_images").style.backgroundImage = `url(${uploaded_image})`
  //     })
  //     reader.readAsDataURL(this.files[0]);
  
  //  })


 
    // const [selectedImage, setSelectedImage] = useState(null);
  
  
  const [file, setFile] = useState(null);
  
  return (
    <React.Fragment>
      {/* <Button onClick={handleOpen}>Open Child Modal</Button> */}
              <UploadButton variant="outlined"  onClick={handleClick}>Select From Device</UploadButton>
              <input 
            type="file"
            id="image_input"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{display:"none"}}
            />
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        
        <Box sx={{ ...style, width: 500, height:250 }}>
          {/* <Card> */}
          <Box>Selected Photo/Video</Box>
          <Box>
          <Grid container>
            {rowCount.map((ele,index)=>(
              <Grid xs={4} key={index}>
             
            <Box
                  id="display_images"
                  alignSelf="center"
                  component="img"
                  sx={{ height: "60%", width: "60%" ,my:'0.5rem'}}
                  height="20"
                  alt="Logo"
                  src={ URL.createObjectURL(file) } 
                ></Box>
        
            </Grid>
            ))}
              
          </Grid>
          <Grid display='flex' justifyContent='flex-end'>
            <Link to='/UploadingPhoto6' className='link'>
            <PublishButton variant="contained" className='gilroy' sx={{px:'2rem',mx:'1rem'}}>Next</PublishButton>
            </Link>
          
          </Grid>
          
          </Box>
          
          {/* </Card> */}
        </Box>
      </Modal>
    </React.Fragment>
  );
}
export default function FullCamp() { 
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };










  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Stack direction='row'>
        {/* <ArrowBackIosIcon sx={{color:'#909090'}}/> */}
        <Box>Camp Withnell</Box>
        </Stack>
        
        <Grid mx="1rem" className="txt-grey">
          {/* <Stack direction='column' spacing={1}> */}
          <Box>
            <TextField
              id="standard-basic"
              label="Camp Name"
              variant="standard"
            //   defaultValue="Camp Downtown"
              className="txt-grey"
            ></TextField>
          </Box>
          <Box>
            <TextField
              id="standard-basic"
              label="Location"
              variant="standard"
              className="txt-grey"
            //   defaultValue="The Dubai Mall"
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <TextField
              id="input-with-sx"
              label="Start Date & Time"
              variant="standard"
              alignSelf='center'
            //   defaultValue="01/15/23"
            />
            <Box 
              alignSelf="center"
              component="img"
              sx={{ height: "1rem", width: "1rem", transform: "rotate(90deg)", mr: 1, my: 0.5 }}
              height="20"
              alt="Logo"
              src={Calender}
            ></Box>
          </Box>
          <Stack direction='row' spacing={12}>
          <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '18ch',my:'1rem' },
      }}
      noValidate
      autoComplete="off"
    ><TextField
          
    id="standard-basic"
    select
    label="Select Age Group"
    // defaultValue="10Yrs-20Yrs"
    SelectProps={{
      native: true,
    }}
    // helperText="Please select your currency"
    variant="standard"
  >
    {currencies.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </TextField></Box>
          </Stack>
          {/* </Stack> */}
        </Grid>

        <Grid mt="2rem" display='flex' >
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="customized table" my='1rem'>

        <TableHead>
           
          <TableRow>
            <StyledTableCell align='center'>Group</StyledTableCell>
            <StyledTableCell align="center">Camp Manager</StyledTableCell>
            <StyledTableCell align="center">Support Staff</StyledTableCell>
            <StyledTableCell align="center">Therapist</StyledTableCell>
            <StyledTableCell align="center">Medication Manager</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row" align="center" >
              {row.name}
              </StyledTableCell>
                  <StyledTableCell align="center">
                {row.calories}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center" >
              {row.fat}
              </StyledTableCell><StyledTableCell component="th" scope="row" align="center" >
              {row.carbs}
              </StyledTableCell><StyledTableCell component="th" scope="row" align="center" >
              {row.protein}
              </StyledTableCell>
            </StyledTableRow>        
          ))}     
        </TableBody>    
      </Table>    
    </TableContainer>
    {/* <Box>button</Box> */}
        </Grid>
         <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { mt:'3rem', width: '40%',mb:'1rem' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={4}
        //   defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at scelerisque sapien. Nullam ut tempus elit, sed volutpat diam. In tortor lacus, commodo ut tempor eget."
        />
    </Box>
    <Box>
            <TextField
              id="standard-basic"
              label="Fee"
              variant="standard"
              className="txt-grey"
            //   defaultValue='$ 50'
            ></TextField>
          </Box>
          <Box sx={{ color:'#AFAFAF',my:'1rem'}} >
            File Attachment
         </Box>
          {/* <Grid display='flex' justifyContent='center'> */}
          <Box mx='1.5rem'
              component="img"
              sx={{ height: "5%", width: "5%",border:1, p:'1rem',borderRadius:'5px' }}
              alt="Logo"
              src={Add}
              onClick={handleOpen}
              
            ></Box>
            
         {/* </Grid> */}
      <Grid container justifyContent='flex-end'>
<Grid item>
<SaveButton variant="contained" sx={{px:'2rem',mx:'1rem'}} className='gilroy'>Cancel</SaveButton>
</Grid>
<Grid item><PublishButton variant="contained" className='gilroy' sx={{px:'2rem',mx:'1rem'}}>Save</PublishButton></Grid>
      </Grid>
      <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Grid sx={style} textAlign='center'  justifyContent='center'>
  <Box mx='1.5rem'
              component="img"
              sx={{ height: "20%", width: "20%", p:'1rem',borderRadius:'5px',color:'#379592' }}
              alt="Logo"
              src={FileIcon}
              onClick={handleOpen}
            ></Box>
            <Box>
              </Box>
            <ChildModal />   
  </Grid>   
      </Modal>
    </div>
      </div>
    </ThemeProvider>
  );
}
