import React from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Modal from '@mui/material/Modal';
import Stack from "@mui/material/Stack";
import "../index.css";
import Grid from "@mui/material/Grid";
import { alpha } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import BackgroundImage from "../Assets/Icons/Icons/Background-image.png"
import DefaultImage from "../Assets/Icons/Icons/DefaultImage.png";
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png";
import SearchIcon from '@mui/icons-material/Search';
import { Link, useParams } from "react-router-dom"
import { apiService, apiService2 } from "../Services/apiService";
import moment from "moment";
import CrossGreen from "../Assets/Icons/Icons/CrossGreen.png";
import Typography from '@mui/material/Typography';


import {
  SERVER_URL,
  GET_CAMP_GALLERY
} from "../Services/apiEndpints";

const SaveButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#909090',
  '&:hover': {
    backgroundColor: '#909090',
  },
}));

const PublishButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#379592',
  '&:hover': {
    backgroundColor: '#379592',
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: 'white',
  color: '#379592',
  '&:hover': {
    backgroundColor: 'white',
    borderColor: '#379592'
  },
}));


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 500,
  // height: 500,
  bgcolor: "background.paper",
  border: '1px solid #fff',
  boxShadow: 24,
  px: 4,
  pt:1,
  pb:4,

  alignSelf:'center',
  
  borderRadius: '25px',
  
};
const imgStyle ={
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}
const rowCount = [1, 2, 3]
const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});


export default function Gallery() {
  const [open, setOpen] = React.useState(false);
  const [dat, setDat] = React.useState('');
  const handleOpen = (dat) => {
    setOpen(true);
    setDat(dat)
  }
  const handleClose = () => setOpen(false);
  const [galleryInfo, setGalleryInfo] = React.useState([])
  const [campName, setCampName] = React.useState('')
  let { campId } = useParams();
  //   const rows = [{created_timestamp: '22nd JAN',document_path:'/',document_content:'Tseting'},
  //   {created_timestamp: '22nd FEB',document_path:'/',document_content:'Tseting'}
  // ]
  React.useEffect(() => {
    (
      async () => {
        // setLoading(true)
        let dataSent = {
          userId: localStorage.getItem('userId'),
          campId: campId
        }

        let apiInfo = {
          // url: "https://cncdevapi.natsoft.io/api/actcamp/getcampgallery",
          url: `${SERVER_URL}${GET_CAMP_GALLERY}`,
          method: "POST",
          postData: dataSent,
          headers: {
            "Content-Type": "application/json",
            tokenheaderkey: localStorage.getItem("token"),
          },

        }

        let output = await apiService2(apiInfo)
        if (output?.data?.responseCode === 0) {

          // let galleryData = output.data.responseData[0]
          // console.log(galleryData)
          // {moment(ele.campStartDateTime.slice(0,10), "DD/MM/YYYY").format("D MMM").toUpperCase()}
          //  let  data = galleryData.map((item)=>{
          //   item.created_timestamp = moment(item.created_timestamp, "YYYY/MM/DD").format("D MMM").toUpperCase()
          //  }
          //  return item
          //   )
          // galleryData.forEach((data)=>{
          //     return moment(data.created_timestamp).formate('YYYY-MM-DD');
          //     })
          // console.log(data)

          if (output?.data?.responseData[0]?.length > 0) {

            setCampName(output.data.responseData[0][0].camp_name)
          }

          let res = Object.values(output.data.responseData[0].reduce((acc, cur) => {
            if (!acc[cur.created_timestamp])
              acc[cur.created_timestamp] = { Date: cur.created_timestamp, Products: [] };
            acc[cur.created_timestamp].Products.push(cur);
            return acc;

          }, {}));
          setGalleryInfo(res.reverse())

        }
      }
    )()

  }

    , [])
    const openVideo = (path) => {
      const videoUrl = "https://cncdevapi.natsoft.io/download/126c716a-012b-4e1e-8ddf-cdaad783ebb6.mp4";
      // const videoUrl = path
      const videoWindow = window.open("", "_blank");
      videoWindow.document.write('<video src="' + videoUrl + '" autoplay controls></video>');
      videoWindow.document.close();
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Link to={`/EditCamp/${campId}`}>
            <Box
              component="img"
              sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer', alignSelf: "center" }}
              height="20"
              alt="Logo"
              src={ArrowBack}
            ></Box>
          </Link>
          <Box className="gilroy" alignSelf="center" sx={{ fontSize: '18px' }}>
            Gallery
          </Box>
        </Stack>

        <Box my='1.5rem' >{campName}</Box>
        {console.log(galleryInfo && galleryInfo.length)}
        {galleryInfo && galleryInfo.length === 0
          ? ('No images uploaded')
          : (galleryInfo && galleryInfo.map((item, index1) => (
            <Box key={index1}>
              <Box sx={{ color: '#379592', fontSize: '20px' }} className='gilroy'>{item.Date}</Box>
              {item.Products.map((row, index2) => (
                <Grid container display='flex' sx={{ my: '2rem' }} key={index2}>
                  <Grid item md={4} sx={{ textAlign: 'center' }}>
                    {row.documentExtType === 'VIDEO' 
                    ? (
                      <Box 
                      // onClick={() => openVideo(row.document_path)} 
                      onClick={() => { handleOpen(row) }}
                      sx={{cursor: 'pointer',alignSelf:'center',textAlign:'center',borderRadius: '10px'}}
                      >
                      <video 
                      // style={{ width: "150px", height: "150px" , borderRadius: '10px', }} 
                      style={{ width: "80%", height: "140px", borderRadius: '10px',alignSelf:'center'}}
                      >
                          <source src={row.document_path} type="video/mp4" />
                          <source src={row.document_path} type="video/ogg" />
                      </video>
                  </Box>
                    ) 
                    : (
                      <Box
                      component="img"
                      // sx={{ width: "150px", height: "150px", borderRadius: '10px', cursor: 'pointer',alignSelf:'center' }}
                      sx={{ width: "80%", height: "140px", borderRadius: '10px',alignSelf:'center' ,cursor: 'pointer',textAlign:'center'}}
                      alt="Logo"
                      src={row.document_path}
                      onClick={() => { handleOpen(row) }}
                    ></Box>
                    )}
                    
                    
                  </Grid>
                  <Grid item md={8} >
                    <Box  >
                      <TextField sx={{ borderColor: '#707070', width: '100%' }}
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        rows={5}
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        defaultValue={row.document_content}
                      />
                    </Box>
                  </Grid>


                </Grid>
              ))}


            </Box>
          )))
        }


        {/* <Box >
            <Box sx={{color:'#379592',fontSize:'20px'}} className='gilroy'>24 March 2023</Box>
         {rowCount.map(()=>(
            <Grid container display='flex' sx={{my:'2rem'}}>
          <Box
              component="img"
              sx={{  width: "15%" ,borderRadius:'10px' }}
              alt="Logo"
              src={DefaultImage}
            ></Box>
            <Box alignSelf='center' sx={{width:'60%',ml:'2rem'}}>
            <TextField  sx={{borderColor:'#707070',width:'100%',color:'#AFAFAF'}}
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={3}
          defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at scelerisque sapien. Nullam ut tempus elit, sed volutpat diam. In tortor lacus, commodo ut tempor eget."
        />
            </Box>
            
          </Grid>
         ))}
         </Box> */}


        {/* <Button onClick={handleOpen}>Open modal</Button> */}
      </Box>

      <Modal

        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{alignSelf:'center'}}
      >
        <Box sx={style} textAlign='center'alignSelf='center'>
          <Box>
            <Grid container display='flex' justifyContent='end'>
            <Box
                 component="img"
                 sx={{ width: "20px", height: '20px',my:1,cursor:'pointer'}}
                 alt="Logo"
                 src={CrossGreen}
                 onClick={()=>setOpen(false)}
                >
                  </Box>
            </Grid>
         <Box sx={{width:'80%'}}>
         {dat.documentExtType === 'VIDEO' 
                    ? (
                      <Box 
                     
                      sx={{ cursor: 'pointer' }}>
                      <video style={{width: "400px", height: '400px', borderRadius: '10px', textAlign: 'center',border:1 }} controls>
                          <source src={dat.document_path}type="video/mp4" />
                          <source src={dat.document_path}type="video/ogg" />
                      </video>
                  </Box>
                    ) 
                    : (
                      <Box
                      component="img"
                      sx={{ width: "400px", height: '400px', borderRadius: '10px', textAlign: 'center',border:1 }}
                      alt="Logo"
                      src={dat.document_path}
                    ></Box>
                    
                    )}
                    
        
         </Box>
          
                
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
