import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { Link, useHistory, useParams,useLocation } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import "../../index.css";
import { apiService, apiService2 } from "../../Services/apiService";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useSelector } from "react-redux";
import { US8_URL, SERVER_URL } from "../../Services/apiEndpints";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function GoalSettingInCampStaff() {
  const [group, setGroup] = useState("");
  const [groupData, setGroupData] = useState("");
  const [campListData, setCampListData] = useState({});
  const [campStaffInfo, setCampStaffInfo] = useState([]);
  const [campParticipantsInfo, setCampParticipantsfInfo] = useState([]);
  const selectedCampDetails = useSelector((state) => state?.Camp?.campData);
  const [asignGrp, setAssignGrp] = React.useState([]);

  const history = useHistory();
  const location = useLocation();
  const groupHandleChange = async (event) => {
    setGroup(event);

    let group = event;
    console.log("event", event);
    let gettran = {
      _methodId: "4",
      campId: camp_id,
      // campId: 5199,
      userId: localStorage.getItem("userId"),
      groupName: group,
    };

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/us8",
      url: `${SERVER_URL}${US8_URL}`,
      method: "POST",
      postData: gettran,
      headers: {
        "Content-Type": "application/json",

        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let login = await apiService2(apiInfo);
    console.log("login", login);
    if (login?.data?.responseCode === 0) {
      console.log("loginrespon", login?.data?.responseData[0].length);
      if (
        login?.data?.responseData[0] &&
        login?.data?.responseData[0].length > 0
      ) {
        // debugger
        console.log(login?.data?.responseData[0][0]);
        const campDataList = login?.data?.responseData[0][0];
        const staffInfo = campDataList?.staffInfo
          ? JSON.parse(campDataList?.staffInfo)
          : [];
        const participantsInfo = campDataList?.participantsInfo
          ? JSON.parse(campDataList?.participantsInfo)
          : [];
        console.log("participantsInfo", participantsInfo);
        if (participantsInfo && participantsInfo.length) {
          setCampParticipantsfInfo(participantsInfo);
        }
        if (staffInfo.length && staffInfo[0].staffList.length) {
          setCampStaffInfo(staffInfo[0].staffList);
        } else {
          setCampStaffInfo([]);
        }
        console.log("campDataList", campDataList);
        setCampListData(campDataList);
      } else {
        setCampListData({});
        setCampStaffInfo([]);
      }
    } else {
      setCampListData({});
      setCampStaffInfo([]);
    }
  };

  // item.rolesname === roleName

  // const getStaffNameByRole = (roleName) => {
  //   console.log("campStaffInfo", campStaffInfo, roleName);
  //   let staffData = (campStaffInfo || []).find(
  //     (item) => item.rolesname === roleName
  //   );

  //   return staffData?.staffName;
  // };

  const getStaffNameByRole = (roleName) => {
    console.log("campStaffInfo", campStaffInfo, roleName);
    let staffData = (campStaffInfo || []).filter(
      (item) => item.rolesname === roleName
    );

    return staffData;
  };

  console.log("group", group);
  let { camp_id } = useParams();

  // React.useEffect(() => {
  //   (async () => {
  //     // setLoading(true);
  //     let gettran = {
  //       _methodId: "3",
  //       campId: camp_id,
  //       userId: localStorage.getItem("userId"),
  //     };

  //     let apiInfo = {
  //       // url: "https://cncdevapi.natsoft.io/api/us8",
  //       url: `${SERVER_URL}${US8_URL}`,
  //       method: "POST",
  //       postData: gettran,
  //       headers: {
  //         "Content-Type": "application/json",
  //         tokenheaderkey: localStorage.getItem("token"),
  //       },
  //     };
  //     let login = await apiService2(apiInfo);

  //     const CampDataList = login.data.responseData[0];
  //     // setLoading(false);
  //     setGroup(CampDataList[0].groupNames);
  //     groupHandleChange(CampDataList[0].groupNames);
  //     console.log("CampDataListtttttt", CampDataList);

  //     setGroupData(CampDataList);
  //   })()
  //     .catch((e) => {
  //       console.error(e);
  //     })
  //     .finally(() => {});
  // }, []);

  console.log("groupWise", campListData);
  console.log("campStaffInfo", campStaffInfo);

  React.useEffect(() => {
    console.log(localStorage.getItem("groupInfo"), "insideee");
    console.log(JSON.parse(localStorage.getItem("groupInfo")), "nowwww");
    // arr.push(localStorage.getItem('groupInfo'))
    setAssignGrp(JSON.parse(localStorage.getItem("groupInfo1")));
  }, []);
  
  return (
    <>
      <Grid container display="flex" sx={{ mt: "2rem" }}>
        <Grid item xs={12} sm={6} md={9}>
          <Stack direction="row" spacing={1}>
            <Link to="/CampListForCampStaff" className="link2">
              <Box
                alignSelf="center"
                component="img"
                sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>

            <Box alignSelf="center" px="1rem">
              {localStorage.getItem("camp_name")}
            </Box>
          </Stack>
        </Grid>
        {/* 
        <Grid item xs={12} sm={6} md={3} container justifyContent="center">
          <Box>
            <Link to="/CampStaffNotificationLog" className="link2">
              <Button
                variant="contained"
                sx={{
                  color: "#FFFFFF",
                  bgcolor: "#FF9529",
                  textTransform: "none",
                  "&:hover": { background: "#FF9529" },
                  fontFamily: "gilroy",
                  mx: "0.5rem",
                }}
              >
                Notifications
              </Button>
            </Link>
          </Box>
        </Grid> */}
      </Grid>
      <Grid item xs={12} sm={6} md={9} sx={{ ml: "0.5rem" }}>
        <Grid container display="flex" alignItems="flex-end">
          <Grid item md={4} sm={12} xs={12}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Group
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={group}
                onChange={(e) => groupHandleChange(e.target.value)}
                label="Group"
              >
                {/* {(groupData || []).map((camp, index) => (
                  <MenuItem
                    defaultValue={groupData[0].groupNames}
                    value={camp.groupNames}
                  >
                    {camp.groupNames}
                  </MenuItem>
                ))} */}

                {(asignGrp && asignGrp).map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}

                {/* {campListData.map((option) => (
                  <option key={option.groupNames} value={option.groupNames}>
                    {option.groupNames}
                  </option>
                ))} */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Name of Camp Manager created this camp"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className="text-applireviewMedMq"
              value={campListData?.createdCampManager}
              sx={{ width: 350 }}
            ></TextField>
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          alignItems="flex-end"
          sx={{ mt: "1rem" }}
        >
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Start Date"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              className="width-text"
              value={campListData?.campStartDateTime}
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="End Date"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className="text-listitemMq"
              value={campListData?.campEndDateTime}
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Group Leader"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className="text-listitemMq"
              // value={getStaffNameByRole("GROUP LEADER")}
              value={getStaffNameByRole("GROUP LEADER")[0]?.staffName || ""}
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          alignItems="flex-end"
          sx={{ mt: "1rem" }}
        >
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Therapist"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className="width-text"
              value={getStaffNameByRole("THERAPIST")[0]?.staffName || ""}
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Medication Manager"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              // value={getStaffNameByRole("MEDICATION MANAGER")}
              className="text-listitemMq"
              value={
                getStaffNameByRole("MEDICATION MANAGER")[0]?.staffName || ""
              }
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Support Staff"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              // value={getStaffNameByRole("SUPPORT STAFF")}
              className="text-listitemMq"
              value={getStaffNameByRole("SUPPORT STAFF")
                .map((item) => item.staffName)
                .join(", ")}
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
        </Grid>
      </Grid>

      {/* Table code starts here */}

      <TableContainer component={Paper} sx={{ marginTop: "3rem" }}>
        <Table sx={{ minWidth: 450 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Sl. No</StyledTableCell>

              <StyledTableCell sx={{ textAlign: "center" }}>
                {/* <Button  onClick={()=>testFuncton('firstName')}>First Name</Button>  */}
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Participants
                  </Box>
                </Grid>
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>
                {/* <Button  onClick={()=>testFuncton('firstName')}>First Name</Button>  */}
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Goal Status
                  </Box>
                </Grid>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Action
                  </Box>
                </Grid>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campParticipantsInfo.map((row, index) => (
              <TableRow
                // key={row.userId}
                className="normal"
                sx={{
                  "& .MuiTableCell-root": { py: "0.35rem" },
                }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  // sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                >
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                >
                  {row?.childName}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                >
                  {row?.goal_status}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                >
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Button
                        variant="outlined"
                        sx={{
                          px: "2rem",
                          mx: "1rem",
                          color: "#379592",
                          textTransform: "none",

                          // "&:hover":{background:"#379592"}
                        }}
                        className="gilroy goal-btn"
                        onClick={() =>
                          history.push(
                            `/ChildProfile/${camp_id}/${row?.child_id}`
                          )
                        }
                      >
                        Profile
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        sx={{
                          px: "2rem",
                          mx: "1rem",
                          color: "#379592",
                          textTransform: "none",
                        }}
                        className="gilroy goal-btn"
                        onClick={() =>
                          history.push(
                            `/Goals/${camp_id}/${row?.applicationId}`
                          )
                        }
                      >
                        Goals
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        sx={{
                          color: "#379592",
                          textTransform: "none",
                        }}
                        className="gilroy goal-btn"
                        onClick={() =>{
                          history.push(
                            `/NotificationLog/${camp_id}/${row?.applicationId}`
                          )
                          localStorage.setItem("previousPage", 'GoalSettingInCampStaff')
                        }
                          
                        }
                      >
                        Notification
                      </Button>
                    </Grid>
                  </Grid>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
