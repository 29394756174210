import React from "react";
import Box from "@mui/material/Box";
import "../../index.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button/Button";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, alpha } from "@mui/material/styles";
import GreenPdf from "../../Assets/Icons/Icons/GreenPdf.png";
import { apiService, apiService2 } from "../../Services/apiService";
import { GET_NOTIFICATIONS, SERVER_URL } from "../../Services/apiEndpints";

export default function NotificationLog() {
  const currentPage = window.location.pathname;
 

  let history = useHistory();
  const location = useLocation();
  console.log(location,history)
  let { campId, applicantId } = useParams();
  const [notificationLog, setNotificationLog] = React.useState([]);
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // hide last border

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,

      backgroundColor: "rgba(55, 149, 146, 1)",

      color: theme.palette.common.white,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  React.useEffect(() => {
    (async () => {
      let dataSent = {
        userId: localStorage.getItem("userId"),
        campId: campId,
        applicationId: applicantId,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/getnotifications",
        url: `${SERVER_URL}${GET_NOTIFICATIONS}`,
        method: "POST",
        postData: dataSent,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let output = await apiService2(apiInfo);
      console.log(output.data.responseData[0]);
      let notification = output.data.responseData[0];
      setNotificationLog(notification);
    })();
  }, []);
  console.log(notificationLog);

  const handleRouting = () => {
    const previousPage = localStorage.getItem("previousPage");
    console.log(previousPage)
    if (
      localStorage.getItem("rolesName") === "CAMP MANAGER" ||
      localStorage.getItem("rolesName") === "CAMP ADMIN" ||
      localStorage.getItem("rolesName") === "ADMIN"
    ) {
      // history.push(`/ChangeSuitability/${campId}/${applicantId}`);
      if (previousPage === "ChangeSuitability") {
        history.push(`/ChangeSuitability/${campId}/${applicantId}`);
       
      } else if (previousPage === "GoalSettingInCampStaff") {
        history.push(`/GoalSettingInCampStaff/${campId}`)
      } 
      // else {
      //   // Default path if previousPage is unknown
      //   history.push(`/ChangeSuitabilityDefaultPath/${campId}/${applicantId}`);
      // }
    } else if (
      localStorage.getItem("rolesName") === "CAMP STAFF" ||
      localStorage.getItem("rolesName") === "THERAPIST" ||
      localStorage.getItem("rolesName") === "MEDICATION MANAGER"
    ) {
      history.push(`/GoalSettingInCampStaff/${campId}`);
    }
  };

  return (
    <Box>
      <Grid container direction="row">
        <Grid container display="flex" justifyContent="space-between">
          <Grid>
            {/* <Link to={`/ChangeSuitability/${campId}/${applicantId}`}> */}
            <Box
              mt="0.65rem"
              alignSelf="center"
              component="img"
              sx={{
                height: "0.75rem",
                width: "0.65rem",
                cursor: "pointer",
              }}
              height="20"
              alt="Logo"
              src={ArrowBack}
              onClick={() => {
                handleRouting();
              }}
            ></Box>
            {/* </Link> */}
          </Grid>
          <Grid item>
            <Box
              className="gilroy"
              alignSelf="center"
              sx={{ textAlign: "center" }}
            >
              Notifications
            </Box>
          </Grid>

          <Grid item>
            <Box
              className="gilroy"
              alignSelf="center"
              sx={{ fontSize: "1.2rem" }}
            >
              <Link
                to={`/Notifications/${campId}/${applicantId}`}
                className="link2"
              >
                <Button
                  variant="contained"
                  sx={{
                    color: "#FFFFFF",
                    bgcolor: "#FF9529",
                    textTransform: "none",
                    "&:hover": { background: "#FF9529" },
                    fontFamily: "gilroy",
                    mx: "0.5rem",
                  }}
                >
                  Send Notifications
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </Grid>

      <TableContainer component={Paper} sx={{ my: "2rem", mt: "5rem" }}>
        <Table sx={{ minWidth: 450 }} aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">Date</StyledTableCell>

              <StyledTableCell align="center">
                {localStorage.getItem("rolesName") === "CAMP STAFF" ||
                localStorage.getItem("rolesName") === "THERAPIST" ||
                (localStorage.getItem("rolesName") === "MEDICATION MANAGER") 
                 
                  ? "Camp Staff"
                  :"Camp Manager"}
              </StyledTableCell>

              <StyledTableCell align="center">Attachments</StyledTableCell>

              <StyledTableCell align="center">Descriptions</StyledTableCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {notificationLog &&
              notificationLog.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "& .MuiTableCell-root": { py: "0.45rem" },
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Box alignSelf="center">{item.createdDate}</Box>
                    </Stack>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Box alignSelf="center">{item.createdBy}</Box>
                    </Stack>
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row" align="center">
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {/* <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                      <Box
                        mt="0.65rem"
                        mr="0.5rem"
                        alignSelf="center"
                        component="img"
                        sx={{
                          height: "0.75rem",
                          width: "0.65rem",

                        }}
                        height="20"
                        src={GreenPdf}
                      ></Box>
                      <a href={item.filePath} target="_blank">
                      {item.fileName}
                      </a>
                      
                    </Box> */}
                      {item.fileName !== "" &&
                      item.fileName !== "null" &&
                      item.fileName !== null ? (
                        <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                          <Box
                            mt="0.65rem"
                            mr="0.5rem"
                            alignSelf="center"
                            component="img"
                            sx={{
                              height: "0.75rem",
                              width: "0.65rem",
                            }}
                            height="20"
                            src={GreenPdf}
                          ></Box>
                          <a href={item.filePath} target="_blank">
                            {item.fileName}
                          </a>
                        </Box>
                      ) : (
                        "nil"
                      )}
                    </Stack>
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row" align="center">
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                        {item.message}
                      </Box>
                    </Stack>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
