import React, { useState } from "react";
import Button from "@mui/material/Button";
import User from "../../Assets/Icons/Icons/User.png";
import {
    CardActionArea,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,

    Chip,
    CardContent,
    Divider,
    Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import SearchIcon from "@mui/icons-material/Search";
import List from "@material-ui/core/List";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png"
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png"

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Input from '@mui/material/Input';
import TextField from "@mui/material/TextField";
import DefaultImage from "../../Assets/Icons/Icons/DefaultImage.png"
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Swal from 'sweetalert2'
import { apiService, apiService2 } from "../../Services/apiService";
import Searchdvance from "../../Assets/Icons/Icons/searchadvanced.png";
import { alpha } from "@mui/material/styles";
import question from "../../Assets/Icons/Icons/questionMark.png";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import { CSVLink, CSVDownload } from "react-csv";
import { US8_URL , SERVER_URL} from '../../Services/apiEndpints'
// import { csvData } from './Data'

export default function ShirtReportCampwise() {
    const [shirtData,setShirtData] =React.useState([])

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: theme.palette.common.black,
            backgroundColor: "rgba(55, 149, 146, 1)",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));
    const data = [
        { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
        { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
        { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
      ];
      const headers = [
        { label: "Name", key: "Name" },
        { label: "Group", key: "GroupName" },
        { label: "Role", key: "Role" },
        { label: "Shirt Size", key: "ShirtSsize" }
      ];

let {campId} = useParams()
      React.useEffect(() => {
        (
            async () => {
                    let dataSent = {
                        userId: localStorage.getItem('userId'),
                        campId: campId,
                        _methodId: 60
                    }

                    let apiInfo = {
                        // url: "https://cncdevapi.natsoft.io/api/us8",
                        url: `${SERVER_URL}${US8_URL}`,
                        method: "POST",
                        postData: dataSent,
                        headers: {
                            "Content-Type": "application/json",
                            tokenheaderkey: localStorage.getItem("token"),
                        },
                    }
                    let output = await apiService2(apiInfo)
                    if (output.data.responseCode === 0) {
                        let campData = output.data.responseData[0]
                        console.log("jsjs", campData)
                        setShirtData(campData)
                    }
                
            }
        )()
    }

        , [])


    return (
        <div>
            <Grid container display='flex' justifyContent='space-between'>
        <Grid item>
        <Stack direction='row' spacing={2} sx={{ mt: '0.75rem' }}>
                <Link to='/ShirtReport' className='link2'>
                    <Box
                        alignSelf="center"
                        component="img"
                        sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
                        alt="Logo"
                        src={ArrowBack}
                    ></Box>
                </Link>

                <Box>Shirt Report</Box>
            </Stack>
        </Grid>
        <Grid item>
        
          <div>
          <CSVLink data={shirtData} headers={headers}
          filename={"shirtreport.csv"}>
        <Button
            
            variant="contained"
            sx={{
              bgcolor: "#4C9F70",
              color: "white",
              textTransform: "none",
              "&:hover": { background: "#4C9F70", color: "white" },
              width: "250px",
            //   mx: "0.5rem",
              my: "0.5rem",
            }}
            className="gilroy"
            // onClick={() => handleConfirmCampPlace()}
          >
           Export Shirt Report to Excel
          </Button>
            </CSVLink>
          </div>
          
        </Grid>
            </Grid>
            
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450 }} aria-label="customized table" my='1rem' >

                    <TableHead >

                        <StyledTableRow>
                            <StyledTableCell align='center'>S.no</StyledTableCell>
                            <StyledTableCell align='center'>Name</StyledTableCell>
                            <StyledTableCell align="center">Group</StyledTableCell>
                            <StyledTableCell align="center">Role</StyledTableCell>
                            <StyledTableCell align="center">Shirt Size</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                    {shirtData && shirtData.map((item,index)=>(
                             <TableRow key={index}>
                           
                             <StyledTableCell component="th" scope="row" align="center" >
                            {index+1}
                             </StyledTableCell>
                             <StyledTableCell align="center">
                                {item.Name}
                             </StyledTableCell>
                             <StyledTableCell component="th" scope="row" align="center" >
                             {item.GroupName}
                             </StyledTableCell>
                             <StyledTableCell align="center">
                              
                                {item.Role.charAt(0).toUpperCase() +
                        item.Role.slice(1).toLowerCase()}
                             </StyledTableCell>
                             <StyledTableCell align="center">
                                {item.ShirtSsize}
                             </StyledTableCell>
                         </TableRow>   
                                ))}
                        


                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )

}