import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png";
import { Link, useHistory, useParams } from "react-router-dom";
import "../index.css";
import TextField from "@mui/material/TextField";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import question from "../Assets/Icons/Icons/questionMark.png";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck2.png";
import { apiService, apiService2 } from "../Services/apiService";
import {
  SERVER_URL,
  CREATE_NOTIFICATION
} from "../Services/apiEndpints";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CampNotification() {
  const history = useHistory();
  const [message, setMessage] = React.useState(null)
  let { campId } = useParams();
  const [errorDisplay,setErrorDisplay] = React.useState('')
  const [isChecked, setIsChecked] = React.useState(false);
  // const sendNotification = () => {
  //   Swal.fire({
  //       iconHtml: `<img src="${GreenCheck}" width="100" height="100" >`,
  //       text: "Notification Sent Succesfully!",
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#379592",
  //     }).then((result) => {
  //       if (result.value) {
  //         history.push("/CampNotificationLog");
  //       }
  //     });
  // };
  const sendNotification = async () => {
if(message !== null){
  setErrorDisplay('')
  let dataSent = {
    userId: localStorage.getItem('userId'),
    campId: campId,
    notificationType: "camp",
    notificationRemarks: message,
    recipient : isChecked ? 'All' : 'Parents'

  }
  console.log(dataSent)
  let apiInfo = {
    // url: "https://cncdevapi.natsoft.io/api/camps/createnotification", 
    url:`${SERVER_URL}${CREATE_NOTIFICATION}`,
    method: "POST",
    postData: dataSent,
    headers: {
      "Content-Type": "application/json",
      tokenheaderkey: localStorage.getItem("token"),
    },
  }
  let output = await apiService2(apiInfo)
  console.log(output)
  if (output?.data?.responseCode === 0) {
    console.log(output.data.responseData[0][0].Desc)
    Swal.fire({
      iconHtml: `<img src="${GreenCheck}" width="100" height="100" >`,
      text: output.data.responseData[0][0].Desc,
      confirmButtonText: "OK",
      confirmButtonColor: "#379592",
      customClass: {
        container: "my-swal",
        icon: 'no-border'
    },
    }).then((result) => {
      if (result.value) {
        history.push(`/CampNotificationLog/${campId}`);
      }
    });
  }
}
else{
  setErrorDisplay('Please enter remarks')
}
   
  }

  const handleMessageSubmit = (event) => {
    console.log(event.target.value)
    setMessage(event.target.value)
  }


  

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    console.log(event.target.checked,'hereeeee')
  };
  return (
    <div>
      <Box>

        <Stack direction='row' sx={{ mt: '0.75rem' }}>
          <Link to={`/CampNotificationLog/${campId}`} className='link2'>
            <Box
              alignSelf="center"
              component="img"
              sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
              alt="Logo"
              src={ArrowBack}
            ></Box>
          </Link>

          <Box sx={{ margin: 'auto' }}>Notifications</Box>
          
        </Stack>


        {/* <Grid> */}
        <Stack direction='row' alignItems='center'sx={{mt:'1rem'}} spacing={4} >
        <Box className="gilroy" >
          Message
        </Box>
        <FormGroup>
  <FormControlLabel control={<Checkbox
      checked={isChecked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      sx={{
        color: '#379592',
        '&.Mui-checked': {
          color:'#379592' ,
        },
      }}
    />} label="Support Staff" />
  
</FormGroup>
        </Stack>
        
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": {
              mt: "0.75rem",
              width: "70%",
              mb: "1rem",
              // borderRadius:"1rem"
            },
          }}
          noValidate
          autoComplete="off"
          onChange={(e) => handleMessageSubmit(e)}
        >
          <TextField id="outlined-multiline-static" multiline rows={5} />
          <Box  sx={{color:'#d32f2f',fontSize:'14px'}}>{errorDisplay}</Box>
        </Box>
        {/* </Grid> */}

        <Grid container justifyContent="flex-end" my='1rem'>
          <Grid item>
            <Link to={`/EditCamp/${campId}`} className='link2'>
              <Button
                variant="contained"
                sx={{
                  px: "2rem",
                  mx: "1rem",
                  bgcolor: "#909090",
                  textTransform: "none",
                  "&:hover": { background: "#909090" },
                }}
                className="gilroy"
              >
                Cancel
              </Button>
            </Link>

          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className="gilroy"
              sx={{
                px: "2rem",
                mx: "0.5rem",
                bgcolor: "#379592",
                "&:hover": { background: "#379592" },
                textTransform: "none",
              }}
              onClick={()=>{sendNotification()}}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
