import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import "../index.css";
import Grid from "@mui/material/Grid";
import { styled} from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
// import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor:'rgba(55, 149, 146, 1)',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Jessica',' Sent', '-', '-', 'in review'),
  createData('Tony',' Signed', 'Pending Funding', 'Pending Funding', 'in review'),
  createData('Applicant Name', 'Completed', 'Pending Funding', 'Pending Funding', 'in progress'),
  createData('Applicant Name', 'Completed', 'Funding Confirmed', 'Funding Confirmed', 'in progress'),
  createData('Applicant Name', 'Sent', 'Funding Confirmed', 'Funding Confirmed', 'confirmed'),
];


export default function CampName() {
  return (
    <div>
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px="1.5rem"
        >
          <Grid item>
            {/* <Box><Chip label="Chip Outlined" variant="outlined" /></Box> */}
            <Stack direction="row" spacing={1}>
              <Box alignSelf="center">Camp Name</Box>
              <Chip label="Girls Camp" variant="outlined" />
            </Stack>
          </Grid>
          <Grid item>16/50</Grid>
        </Grid>
      </Box>
    <Box  mt='2rem' >
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="customized table" my='1rem'>
        <TableHead>
          <TableRow>
            <StyledTableCell align='center'>Applicants</StyledTableCell>
            <StyledTableCell align="center">Service Agreement</StyledTableCell>
            <StyledTableCell align="center">Funding</StyledTableCell>
            <StyledTableCell align="center">Suitability</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row" align="center" >
            <Stack direction="row" spacing={1}>
              
                
                {/* <Box> */}
                <Avatar mx='1rem' sx={{ width: 24, height: 24 }} alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                {/* </Box> */}
                <Box alignSelf='center'>{row.name}</Box>
                </Stack>
                
              </StyledTableCell>
              <StyledTableCell align="center">{row.calories}</StyledTableCell>
              <StyledTableCell align="center">{row.fat}</StyledTableCell>
              <StyledTableCell align="center">{row.carbs}</StyledTableCell>
              <StyledTableCell align="center">{row.protein}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
    
    </div>
  );
}
