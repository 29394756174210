import React from "react";
import Box from "@mui/material/Box";
import "../index.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button/Button";
import { Link ,useParams } from "react-router-dom";
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, alpha } from "@mui/material/styles";
import GreenPdf from "../Assets/Icons/Icons/GreenPdf.png";
import { apiService, apiService2 } from "../Services/apiService";
import {
  SERVER_URL,
  CAMP_CHECK_IN_LOG
} from "../Services/apiEndpints";

export default function CheckInOutLog() {
    let { campId,applicantId } = useParams();

     const[checkInInfo,setCheckInInfo] = React.useState(null)
     const[userName,setUserName] = React.useState('')
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // hide last border

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,

      backgroundColor: "rgba(55, 149, 146, 1)",

      color: theme.palette.common.white,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const rows = [
    {date:'21/03/2023',time:'16:44 pm',campManager:'James Jordon',status:'Check Out'},
    {date:'21/03/2023',time:'20:45 pm',campManager:'Tomas Micheal',status:'Check In'}
]

  React.useEffect(() => {
        (
            async () => {
               
                let dataSent = {
                    userId: localStorage.getItem('userId'),
                    campId: campId,
                    applicationId:applicantId
                }
    
                let apiInfo = {
                    // url: "https://cncdevapi.natsoft.io/api/actcamp/camp_check_in_log",
                    url:`${SERVER_URL}${CAMP_CHECK_IN_LOG}`,
                    method: "POST",
                    postData: dataSent,
                    headers: {
                        "Content-Type": "application/json",
                        tokenheaderkey: localStorage.getItem("token"),
                    },
    
                }
    
                let output = await apiService2(apiInfo)
                console.log(output.data.responseData[0])
                let checkInData = output.data.responseData[0]
                console.log(checkInData)
                setCheckInInfo(checkInData)
                setUserName(checkInData[0].childName)
            }
        )()
    }
    
        , [])
  return (
    <Box>
      {/* <Grid container direction="row"> */}
        {/* <Grid container display="flex" justifyContent="space-between">
          <Grid>
            <Link  to={`/EditCamp/${campId}`} className='/link2'>
              <Box
                mt="0.65rem"
                alignSelf="center"
                component="img"
                sx={{
                  height: "0.75rem",
                  width: "0.65rem",
                  cursor: "pointer",
                }}
                height="20"
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>
          </Grid>
          <Grid item>
            <Box
              className="gilroy"
              alignSelf="center"
              sx={{ textAlign: "center" }}
            >
              Check In/Out Record
            </Box>
          </Grid>

          
        </Grid> */}
         
        {/* </Grid> */}
      {/* </Grid> */}
      <Stack direction='row' sx={{ mt: '0.75rem' }}>
                    <Link to={`/CheckInOut/${campId}/${applicantId}`} className='link2'>
                        <Box
                            alignSelf="center"
                            component="img"
                            sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
                            alt="Logo"
                            src={ArrowBack}
                        ></Box>
                    </Link>

                    <Box sx={{ margin: 'auto' }}>Check In/Out Record</Box>
                </Stack>
        <Box mt='1.5rem'>Name : {userName}</Box>
      <TableContainer component={Paper} sx={{ my: "2rem"}}>
        <Table sx={{ minWidth: 450 }} aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">Date</StyledTableCell>

              <StyledTableCell align="center">Time</StyledTableCell>
              <StyledTableCell align="center">Camp Manager</StyledTableCell>
              

              <StyledTableCell align="center">Status</StyledTableCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {checkInInfo && checkInInfo.map((item,index)=>(
                <TableRow key={index}
                sx={{
                  "& .MuiTableCell-root": { py: "0.45rem" },
                }}
              >
                <StyledTableCell component="th" scope="row">
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <Box alignSelf="center">{item.checkedDate}</Box>
                  </Stack>
                </StyledTableCell>
  
                <StyledTableCell align="center">
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <Box alignSelf="center">{item.checkedTime}</Box>
                  </Stack>
                </StyledTableCell>
  
               
                <StyledTableCell component="th" scope="row" align="center">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                   {item.userName}
                    </Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" ,
                  color: item.camp_check_in === 'Yes' ? '#4C9F70' : '#F21010'
                  }}>
                   {item.camp_check_in === 'Yes' ? 'Check In' : 'Check Out'}
                    </Box>
                  </Stack>
                </StyledTableCell>
              </TableRow>
            ))}
            
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
