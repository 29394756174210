import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography/Typography";
import "../../index.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button/Button";
import { Link, useParams, useHistory } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, alpha } from "@mui/material/styles";
import GreenPdf from "../../Assets/Icons/Icons/GreenPdf.png";
import { apiService, apiService2 } from "../../Services/apiService";
import Logo from "../../Assets/Icons/Icons/Logo-Main.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import GreenCheck2 from "../../Assets/Icons/Icons/GreenCheck2.png";
import moment from "moment";
import Swal from "sweetalert2";
import Modal from "@mui/material/Modal";
import question from "../../Assets/Icons/Icons/questionMark.png";
import { US8_URL, SERVER_URL } from '../../Services/apiEndpints'
import Divider from '@mui/material/Divider';
const dataofinvoice = {
  invoiceDate: "",
  invoiceNumber: "",
  unitPrice: "",
  total: "",
  dueDate: "",
  invoiceId: "",
};
export default function SupportStaffGenerateInvoice() {
  const [invoiceInfo, setInvoiceInfo] = React.useState(dataofinvoice);
  // const [invoicesend, setinvoicesend] = React.useState(dataofinvoice);
  let { campId, staffApplicationId, staffId } = useParams();
  const [invoiceOpen, setinvoiceOpen] = React.useState(false);
  const [submitStatus, setSubmitStatus] = React.useState(false);
  const [fundingRemarks, setFundingRemarks] = React.useState("");
  let history = useHistory();
  {
    console.log(submitStatus);
  }

  const invoiceHandleClose = () => {
    setinvoiceOpen(false);
    setFundingRemarks("");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    px: 4,
    py: 2,
    borderRadius: "25px",
  };

  React.useEffect(() => {
    (async () => {
      let dataSent = {
        _methodId: 52,
        userId: localStorage.getItem("userId"),
        campId: campId,
        staffApplicationId: staffApplicationId,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/us8",
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: dataSent,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let output = await apiService2(apiInfo);
      // console.log(output.data.responseData[0])
      let invoiceData = output.data.responseData[0];
      // setInvoiceInfo(output.data.responseData[0][0]);
      // console.log(output.data.responseData[0][0], "lolllll");

      setInvoiceInfo({
        ...invoiceInfo,

        invoiceId: output.data.responseData[0][0].invoiceId,
        invoiceDate: conversion(output.data.responseData[0][0].invoiceDate),
        invoiceNumber: output.data.responseData[0][0].invoiceNumber,
        unitPrice: output.data.responseData[0][0].unitPrice,
        total: Number(output.data.responseData[0][0].total),
        dueDate: conversion(output.data.responseData[0][0].dueDate),
        supportStaffName: output.data.responseData[0][0].supportStaffName,
        campName: output.data.responseData[0][0].campName,
        campType: output.data.responseData[0][0].campType,
        paymentRefno: output.data.responseData[0][0].paymentRefno,
        lessAmountPaid: Number(output.data.responseData[0][0].lessAmountPaid),
        balance: Number(output.data.responseData[0][0].balance)
      });
    })();
  }, []);
  console.log(invoiceInfo, "herreeeeee");

  const conversion = (inputDate) => {

    if (inputDate) {
      var formattedDate = inputDate.replace(/\//g, '-');
      var dateParts = formattedDate.split('-');
      var day = dateParts[0];
      var month = dateParts[1];
      var year = dateParts[2];
      return year + '-' + month + '-' + day;
    }
  }

  const tableArray = [
    {
      Item: "",
      Description: "Support Staff - Young Ladies Gateway",
      Quantity: "1.00",
      UnitPrice: "100.00",
      GST: "GST Free",
      AmountUSD: "100.00",
      TOTALAUD: "100.00",
      LessAmountPaid: "100.00",
    },
    // {
    //     serviceBy: 'Kate Keisler Occupational',
    //     role: 'Therapist',
    //     service: 'Therapy Intensive Camp - Camp Out',
    //     serviceDate: '12-Apr-2023',
    //     time: '12:00',
    //     rate: '$50.00',
    //     cost: '$600.00'
    // },
  ];
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // hide last border

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,

      backgroundColor: "rgba(55, 149, 146, 1)",

      color: theme.palette.common.white,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  function storeInfo(event) {
    const { name, value } = event.target;
    console.log("name,value", name, value);

    setInvoiceInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  }




  const handleSave = async (event) => {
    console.log('save is working', invoiceInfo.invoiceId)
    let dataSent = {
      // _methodId: 55,
      userId: localStorage.getItem("userId"),
      campId: campId,
      staffApplicationId: staffApplicationId,
      supportStaffName: invoiceInfo.supportStaffName,
      invoiceStatus: "Draft",
      // invoiceDate: moment(invoiceInfo.invoiceDate, "DD/MM/YYYY").format(
      //   "YYYY-MM-DD"
      // ),
      invoiceDate: invoiceInfo.invoiceDate,
      invoiceNumber: invoiceInfo.invoiceNumber,
      invoiceId: invoiceInfo.invoiceId,
      unitPrice: invoiceInfo.unitPrice,
      total: invoiceInfo.total,
      // dueDate: moment(invoiceInfo.dueDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      dueDate: invoiceInfo.dueDate,
      lessAmountPaid: Number(invoiceInfo.lessAmountPaid),
      balance: Number(invoiceInfo && invoiceInfo.total) - Number(invoiceInfo && invoiceInfo.lessAmountPaid),
      // lessAmntPaid:invoiceInfo.lessAmntPaid
      paymentRefno: invoiceInfo.paymentRefno,
    };

    if (invoiceInfo.invoiceId) {
      dataSent = {
        ...dataSent,
        _methodId: 56,
      };
    } else {
      dataSent = {
        ...dataSent,
        _methodId: 55,
      };
    }
    console.log(dataSent, "lollllllllllllllll");

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/us8",
      url: `${SERVER_URL}${US8_URL}`,
      method: "POST",
      postData: dataSent,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let output = await apiService2(apiInfo);

    let invoiceData = output.data.responseData[0];

    console.log(output.data.responseData[0][0], "lolllll");

    if (output?.data?.responseData[0][0].Code === "0") {
      Swal.fire({
        iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
        text: output?.data?.responseData[0][0]?.Desc,
        confirmButtonColor: "#379592",
        customClass: {
          container: "my-swal",
          icon: "no-border",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(
            `/ChangeFunding/${campId}/${staffApplicationId}/${staffId}`
          );
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        text: output?.data?.responseDesc,
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
      });
    }
  };

  const handleSwal = async () => {
    setinvoiceOpen(true);
    setSubmitStatus(true);
  };

  const onChangeFundingRemarks = (event) => {
    setFundingRemarks(event.target.value);
  };

  const finalSubmit = () => {
    if (localStorage.getItem("invoiceStatusSupportStaff") === "NA") {
      Swal.fire({
        iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
        text: "Are you sure you want to Submit?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#379592",
        cancelButtonText: "No",
        cancelButtonColor: "#909090",
        customClass: {
          container: "my-swal",
          icon: "no-border",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            console.log('workingggggggggggggggg')
            let dataSent = {
              _methodId: 55,
              userId: localStorage.getItem("userId"),
              campId: campId,
              staffApplicationId: staffApplicationId,
              supportStaffName: invoiceInfo.supportStaffName,
              invoiceStatus: "Submitted",
              // invoiceDate: moment(invoiceInfo.invoiceDate, "DD/MM/YYYY").format(
              //   "YYYY-MM-DD"
              // ),
              invoiceDate: invoiceInfo.invoiceDate,
              invoiceNumber: invoiceInfo.invoiceNumber,
              invoiceId: invoiceInfo.invoiceId,
              unitPrice: invoiceInfo.unitPrice,
              total: invoiceInfo.total,
              remarks: fundingRemarks,
              // dueDate: moment(invoiceInfo.dueDate, "DD/MM/YYYY").format(
              //   "YYYY-MM-DD"
              // ),
              dueDate: invoiceInfo.dueDate,
              lessAmountPaid: Number(invoiceInfo.lessAmountPaid),
              balance: Number(invoiceInfo && invoiceInfo.total) - Number(invoiceInfo && invoiceInfo.lessAmountPaid),
              paymentRefno: invoiceInfo.paymentRefno
            };

            console.log(dataSent, "lollllllllllllllll");
            let apiInfo = {
              // url: "https://cncdevapi.natsoft.io/api/us8",
              url: `${SERVER_URL}${US8_URL}`,
              method: "POST",
              postData: dataSent,
              headers: {
                "Content-Type": "application/json",
                tokenheaderkey: localStorage.getItem("token"),
              },
            };

            let output = await apiService2(apiInfo);
            let res = output.data.responseData[0];
            console.log(res);

            if (output.data.responseData[0][0].Code === "0") {
              Swal.fire({
                iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
                text: `${output?.data?.responseData[0][0]?.Desc} 
                `,
                confirmButtonText: "OK",
                confirmButtonColor: "#379592",
                customClass: {
                  container: "my-swal",
                  icon: "no-border",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  history.push(
                    `/ChangeFunding/${campId}/${staffApplicationId}/${staffId}`
                  );
                }
              });
            } else if (output.data.responseData[0][0].Code !== "0") {
              Swal.fire({
                icon: "error",
                // iconHtml: `<img src="${GreenCheck}" >`,
                text: output?.data?.responseData[0][0]?.Desc,
                confirmButtonColor: "#379592",
                customClass: {
                  container: "my-swal",

              },
              });
              // .then((result) => {
              //     if (result.isConfirmed) {
              //         history.push(`/ChangeSuitability/${campId}/${applicantId}`)
              //     }
              // }

              // )
            }
          })();
        }
      });
    } else if (localStorage.getItem("invoiceStatusSupportStaff") !== "NA") {
      Swal.fire({
        iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
        text: "Are you sure you want to Submit?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#379592",
        cancelButtonText: "No",
        cancelButtonColor: "#909090",
        customClass: {
          container: "my-swal",
          icon: "no-border",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            let dataSent = {
              _methodId: 56,
              userId: localStorage.getItem("userId"),
              campId: campId,
              staffApplicationId: staffApplicationId,
              supportStaffName: invoiceInfo.supportStaffName,
              invoiceStatus: "Submitted",
              // invoiceDate: moment(invoiceInfo.invoiceDate, "DD/MM/YYYY").format(
              //   "YYYY-MM-DD"
              // ),
              invoiceDate: invoiceInfo.invoiceDate,
              invoiceNumber: invoiceInfo.invoiceNumber,
              invoiceId: invoiceInfo.invoiceId,
              unitPrice: invoiceInfo.unitPrice,
              total: invoiceInfo.total,
              remarks: fundingRemarks,
              // dueDate: moment(invoiceInfo.dueDate, "DD/MM/YYYY").format(
              //   "YYYY-MM-DD"
              // ),
              dueDate: invoiceInfo.dueDate,
              lessAmountPaid: Number(invoiceInfo.lessAmountPaid),
              balance: Number(invoiceInfo && invoiceInfo.total) - Number(invoiceInfo && invoiceInfo.lessAmountPaid),
              paymentRefno: invoiceInfo.paymentRefno,
            };

            console.log(dataSent, "lollllllllllllllll");
            let apiInfo = {
              // url: "https://cncdevapi.natsoft.io/api/us8",
              url: `${SERVER_URL}${US8_URL}`,
              method: "POST",
              postData: dataSent,
              headers: {
                "Content-Type": "application/json",
                tokenheaderkey: localStorage.getItem("token"),
              },
            };

            let output = await apiService2(apiInfo);
            let res = output.data.responseData[0];
            console.log(res);

            if (output.data.responseData[0][0].Code === "0") {
              Swal.fire({
                iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
                text: `${output?.data?.responseData[0][0]?.Desc} 
                         `,
                confirmButtonText: "OK",
                confirmButtonColor: "#379592",
                customClass: {
                  container: "my-swal",
                },
                
              }).then((result) => {
                if (result.isConfirmed) {
                  history.push(
                    `/ChangeFunding/${campId}/${staffApplicationId}/${staffId}`
                  );
                }
              });
            } else if (output.data.responseData[0][0].Code !== "0") {
              Swal.fire({
                icon: "error",
                // iconHtml: `<img src="${GreenCheck}" >`,
                text: output?.data?.Desc,
                confirmButtonColor: "#379592",
                customClass: {
                  container: "my-swal",
                },
              });
            }
          })();
        }
      });
    }
  };

  const handleSubmit = async (event) => {
    let dataSent = {
      _methodId: 56,
      userId: localStorage.getItem("userId"),
      campId: campId,
      staffApplicationId: staffApplicationId,
      supportStaffName: invoiceInfo.supportStaffName,
      invoiceStatus: "Submitted",
      invoiceDate: moment(invoiceInfo.invoiceDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
      invoiceNumber: invoiceInfo.invoiceNumber,
      invoiceId: invoiceInfo.invoiceId,
      unitPrice: invoiceInfo.unitPrice,
      total: invoiceInfo.total,
      dueDate: moment(invoiceInfo.dueDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
    };

    console.log(dataSent, "lollllllllllllllll");

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/us8",
      url: `${SERVER_URL}${US8_URL}`,
      method: "POST",
      postData: dataSent,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let output = await apiService2(apiInfo);
    // console.log(output.data.responseData[0])
    let invoiceData = output.data.responseData[0];
    // setInvoiceInfo(output.data.responseData[0][0]);
    console.log(output.data.responseData[0][0], "lolllll");
  };

  console.log(invoiceInfo, "invoiceInfoooooooooo");

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    console.log(event.target)

    console.log(event.target.value)
    // total = readData.total

    // setInvoiceInfo((prev) => ({
    //   ...prev,
    //   [name]: value,
    // }));
    const re = /^[0-9\b]+$/
    if(event.target.value === '' || re.test(event.target.value)){
      setInvoiceInfo({ ...invoiceInfo, lessAmountPaid: event.target.value })
    }

  };
  return (
    <Box>
      <Grid container display="flex">
        <Grid item md={4} sm={12}>
          <Stack direction="row" spacing={2}>
            <Link
              className="link2"
              to={`/ChangeFunding/${campId}/${staffApplicationId}/${staffId}`}
            >
              <Box
                mt="0.75rem"
                alignSelf="flex-start"
                component="img"
                sx={{
                  height: "0.75rem",
                  width: "0.65rem",
                  cursor: "pointer",
                }}
                height="20"
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>

            <Stack direction="column">
              <Box
                component="img"
                sx={{ height: "50%", width: "40%", my: "1rem" }}
                alt="Logo"
                src={Logo}
              ></Box>
              <Box
                sx={{ fontSize: "12px", maxWidth: "45%" }}
                className="gilroy"
              >
                Calm and Connected Pty Ltd5C 11 Erade Drive Piara Waters WA 6112
              </Box>
            </Stack>
          </Stack>
          <Box >
          <Grid container alignItems="center" sx={{mt:'3rem'}} >
                <Grid item md={6}>
                 Customer Name
                </Grid>
                <Grid item md={6} className="medium">
                <TextField
              // sx={{ marginTop: "2rem", marginLeft: "1.5rem" }}
              id="outlined-basic"
              multiline
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              name="supportStaffName"
              value={invoiceInfo && invoiceInfo.supportStaffName}
              onChange={(event) => storeInfo(event)}
            />
                </Grid>
              </Grid>
            
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sm={12}
          sx={{ alignItems: "center", alignSelf: "center" }}
        >
          <Box
            alignContent="center"
            sx={{ fontSize: "1.45rem" }}
            className="bold"
          >
            TAX INVOICES
          </Box>
        </Grid>

        <Grid item md={5} sm={12} sx={{ marginTop: "7rem" }}>
          <List>
            <ListItem>
              <Grid container alignItems='center'>
                <Grid item md={4} sx={{fontSize:'17px'}}>
                  Invoice Date
                </Grid>
                <Grid item md={4} className="medium">
                  {/* <TextField
                    id="standard-basic"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    name="invoiceDate"
                    value={invoiceInfo && invoiceInfo.invoiceDate}
                    onChange={(event) => storeInfo(event)}
                  /> */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    format="YYYY-MM-DD"
                    type="date"
                    size="small"
                    // sx={{ width: 200, px: '0.25rem' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="invoiceDate"
                    // value={ndisInfo && moment(ndisInfo.periodFrom).format("YYYY-MM-DD")}
                    value={invoiceInfo && invoiceInfo.invoiceDate}
                    onChange={(event) => storeInfo(event)}
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container alignItems="center">
                <Grid item md={4} sx={{fontSize:'17px'}} >
                  Invoice number
                </Grid>
                <Grid item md={5} className="medium">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true
                    }}

                    size="small"
                    name="invoiceNumber"
                    value={invoiceInfo && invoiceInfo.invoiceNumber}
                    onChange={(event) => storeInfo(event)}
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container alignItems="center">
                <Grid item md={4}sx={{fontSize:'17px'}} >
                  Reference
                </Grid>
                <Grid item md={5} className="medium">
                  {/* Support Staff */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    // name="invoiceNumber"
                    value=' Support Staff'
                  // onChange={(event) => storeInfo(event)}
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container alignItems='center'>
                <Grid item md={4} sx={{fontSize:'17px'}}>
                  ABN
                </Grid>
                <Grid item md={5} className="medium">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    // name="invoiceNumber"
                    value='22 613 026 430'
                  // onChange={(event) => storeInfo(event)}
                  />

                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ my: "2rem", mt: "5rem" }}>
        <Table sx={{ minWidth: 450 }} aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="flex-start">Description</StyledTableCell>
              <StyledTableCell align="flex-start"></StyledTableCell>
              <StyledTableCell align="right">Quantity</StyledTableCell>
              <StyledTableCell align="right">Unit Price</StyledTableCell>
              <StyledTableCell align="right">GST</StyledTableCell>
              <StyledTableCell align="right">Amount AUD</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {/* {tableArray.map((item, index) => ( */}
            <TableRow
              // key={index}
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell  style={{ borderBottom: "1px solid black" }} >
              <Stack direction="column" alignItems="flex-start" spacing={1}>
                  <Box>
                    {invoiceInfo && invoiceInfo.campName}  {invoiceInfo && invoiceInfo.campType}
                  </Box>
                  {/* <Box sx={{ color: "red" }}>item code goes</Box> */}
                </Stack>
              </StyledTableCell>
              <StyledTableCell  align="flex-start" style={{ borderBottom: "1px solid black" }}>
                {/* <Stack direction="column" alignItems="flex-start" spacing={1}>
                  <Box>
                    {invoiceInfo && invoiceInfo.campName}  {invoiceInfo && invoiceInfo.campType}
                  </Box>
                 
                </Stack> */}
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" ,}}>
                      1.00
                    </Box>
                  </Stack>
                </Stack>
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                      ${invoiceInfo && invoiceInfo.unitPrice}.00
                    </Box>

                  </Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" ,}}>
                    GST Free
                    </Box>
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" ,}}>
                    Subtotal
                    </Box>
                  </Stack>
                    {/* <Typography variant="body1">
                    GST Free
                    </Typography>
                    <br />
                    
                    <Typography variant="body1">Subtotal</Typography> */}
                  </Box>

                </Stack>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  height={1}
                >
                  
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    ${invoiceInfo && invoiceInfo.total}.00
                  </Box>
                  
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    ${invoiceInfo && invoiceInfo.total}.00
                  </Box>
                </Stack>

              </StyledTableCell>
            </TableRow>
            {/* ))} */}
            <TableRow>
              <StyledTableCell component="th" scope="row" style={{ borderBottom: "1px solid black" }}>
                <Stack direction="column" alignItems="flex-start" spacing={1}>
                  <Box>{""}</Box>
                  <Box></Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" style={{ borderBottom: "1px solid black" }}>
                <Stack direction="column" alignItems="center" spacing={1}>
                  <Box></Box>
                  {/* <Box sx={{ color: "red" }}>item code goes</Box> */}
                </Stack>
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                align="center"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    <Typography variant="body1"> </Typography>
                    <br />
                    <br />
                    <Typography variant="body1">
                      {""}
                      {""}
                      {""}
                      {""}
                    </Typography>
                  </Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                align="center"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    <Typography variant="body1"></Typography>
                    <br />
                    <br />
                    <Typography variant="body1">{""}</Typography>
                  </Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" ,fontWeight:'bold'}}>
                    TOTAL AUD 
                    </Box>
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" ,fontWeight:'bold'}}>
                    Less Amount Paid
                    </Box>
                  </Stack>
                    {/* <Typography variant="body1" sx={{fontWeight:'bold'}} >TOTAL AUD </Typography>
                    <br />
                    <Typography variant="body1"sx={{fontWeight:'bold'}}>Less Amount Paid</Typography> */}
                  </Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="right"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  // height={1}
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    {/* <Typography variant="body1">
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        sx={{ width: "35%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        name="total"
                        value={invoiceInfo && invoiceInfo.total}
                        onChange={(event) => storeInfo(event)}
                      />
                    </Typography> */}
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                      ${invoiceInfo && invoiceInfo.total}.00
                    </Box>
                    <br />
                    {/* <Typography variant="body1">0</Typography> */}
                    <TextField
                      // id="standard-basic"
                      // id="outlined-basic"
                      variant="outlined"
                      id={`lessAmountPaid`}
                      // type="number"
                      // variant="standard"
                      sx={{ width: "70%" }}
                      // sx={{mx:'2rem'}}
                      //   InputLabelProps={{
                      //     shrink: true,
                      //   }}
                      // InputProps={{
                      //   inputProps: {
                      //     min: 0
                      //   }
                      // }}
                      size="small"
                      name="lessAmountPaid"
                      value={Number(invoiceInfo && invoiceInfo.lessAmountPaid)}
                      onChange={(event) => handleFieldChange(event)}
                    // onChange={(event) => storeInfo(event)}
                    />


                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell component="th" scope="row" style={{ borderBottom: "1px solid black" }}>
                <Stack direction="column" alignItems="flex-start" spacing={1}>
                  <Box>{""}</Box>
                  <Box></Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" style={{ borderBottom: "1px solid black" }}>
                <Stack direction="column" alignItems="flex-end" spacing={1}>
                  <Box></Box>
                  {/* <Box sx={{ color: "red" }}>item code goes</Box> */}
                </Stack>
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    <Typography variant="body1"> </Typography>
                    <br />
                    <br />
                    <Typography variant="body1">
                      {""}
                      {""}
                      {""}
                      {""}
                    </Typography>
                  </Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    <Typography variant="body1"></Typography>
                    <br />
                    <br />
                    <Typography variant="body1">{""}</Typography>
                  </Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end",fontWeight:'bold' }}>
                    AMOUNT DUE AUD
                  </Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                align="flex-end"
                style={{ borderBottom: "1px solid black" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  height={1}

                >
                  {/* <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    <Typography variant="body1">
                      {invoiceInfo && invoiceInfo.total}
                    </Typography>
                  </Box> */}
                  {/* <TextField
                    id="standard-basic"
                    variant="standard"
                    sx={{ width: "35%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    name="quantity"
                    value={invoiceInfo && invoiceInfo.total}
                    InputProps={{
                      readOnly: true
                    }}
                  // onChange={(event) => storeInfo(event)}
                  /> */}
                  {/* <TextField
              id="outlined-basic"
              variant="outlined"
              
              size="small"
              sx={{ mx: '0.5rem' }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly:true
              }}
              name="balance"
              // value={ndisInfo && moment(ndisInfo.periodFrom).format("YYYY-MM-DD")}
              // value={invoiceInfo && invoiceInfo.balance}
              // onChange={(event) => storeInfo(event)}
            /> */}
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" ,fontWeight:'bold'}}>
                    ${Number(invoiceInfo && invoiceInfo.total) - Number(invoiceInfo && invoiceInfo.lessAmountPaid)}.00
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid>
        <List className="medium">
          <ListItem>
            <h5>DUE DATE </h5>
            {/* <TextField
              sx={{ maxWidth: "15%", marginLeft: "1.5rem" }}
              id="standard-basic"
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              name="dueDate"
              value={invoiceInfo && invoiceInfo.dueDate}
              onChange={(event) => storeInfo(event)}
            /> */}
            <TextField
              id="outlined-basic"
              variant="outlined"
              format="YYYY-MM-DD"
              type="date"
              size="small"
              sx={{ mx: '0.5rem' }}
              InputLabelProps={{
                shrink: true,
              }}
              name="dueDate"
              // value={ndisInfo && moment(ndisInfo.periodFrom).format("YYYY-MM-DD")}
              value={invoiceInfo && invoiceInfo.dueDate}
              onChange={(event) => storeInfo(event)}
            />
          </ListItem>
          <ListItem>Please put the following reference for bank transfer:<span className='bold'>{invoiceInfo && invoiceInfo.paymentRefno}</span></ListItem>
          <ListItem>Please complete bank transfer to: </ListItem>
          <ListItem>Calm and Connected</ListItem>
          <ListItem>BSB: <span className="bold">062692</span></ListItem>
          {/* <ListItem>Account No: 2808577</ListItem> */}
          <ListItem>Account:<span className="bold">77274604</span></ListItem>
        </List>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            sx={{
              px: "2rem",
              mx: "1rem",
              bgcolor: "#379592",
              textTransform: "none",
              "&:hover": { background: "#379592" },
            }}
            className="gilroy"
            onClick={(event) => handleSave(event)}
          >
            Save
          </Button>
        </Grid>
        <Grid item>
          {/* <Link className='link' to='/ViewCamp'> */}
          <Button
            variant="contained"
            className="gilroy"
            sx={{
              px: "2rem",
              mx: "1rem",
              bgcolor: "#379592",
              textTransform: "none",
              "&:hover": { background: "#379592" },
            }}
            // onClick={() => handleSubmit()}
            onClick={() => {
              handleSwal();
            }}
          >
            Submit
          </Button>
          {/* </Link> */}
        </Grid>
      </Grid>

      <Modal
        open={invoiceOpen}
        onClose={invoiceHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            justifyContent="flex-Start"
            direction="row"
            alignContent="flex-Start"
          ></Grid>

          <Box className="gilroy">Add remarks if any</Box>

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                mt: "0.75rem",
                width: "100%",
                height: "20%",
                mb: "1rem",
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={3}
              label="Enter Remarks..."
              value={fundingRemarks}
              onChange={onChangeFundingRemarks}
            />
          </Box>
          {console.log(fundingRemarks)}
          <Button
            variant="contained"
            className="gilroy"
            sx={{
              px: "2rem",
              mx: "1rem",
              bgcolor: "#379592",
              textTransform: "none",
              "&:hover": { background: "#379592" },
            }}
            onClick={() => finalSubmit()}
          >
            Send
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
