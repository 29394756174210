import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import User from "../../Assets/Icons/Icons/User.png";
import {
    CardActionArea,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,

    Chip,
    CardContent,
    Divider,
    Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import SearchIcon from "@mui/icons-material/Search";
import List from "@material-ui/core/List";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png"
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png"

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Input from '@mui/material/Input';
import TextField from "@mui/material/TextField";
import DefaultImage from "../../Assets/Icons/Icons/DefaultImage.png"
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Swal from 'sweetalert2'
import { apiService, apiService2 } from "../../Services/apiService";
import Searchdvance from "../../Assets/Icons/Icons/searchadvanced.png";
import { alpha } from "@mui/material/styles";
import question from "../../Assets/Icons/Icons/questionMark.png";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import image from "../../Assets/Icons/Icons/Background-image.png";
import ListItem from '@mui/material/ListItem';
import { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js'
import {
    SERVER_URL,
    US8_URL,ALERT_REPORT
  } from "../../Services/apiEndpints";

export default function AlertReportCampwise() {
    let { campId } = useParams()
    const [alertData, setAlertData] = React.useState([])
    const [name, setName] = React.useState('')
    const [alert, setAlert] = React.useState('')
    const [alertPath, setAlertPath] = React.useState('')

    const componentRef = useRef();
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: theme.palette.common.black,
            backgroundColor: "rgba(55, 149, 146, 1)",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    React.useEffect(() => {
        (async () => {
            // setLoading(true);
            let gettran = {
                _methodId: "61",
                userId: localStorage.getItem("userId"),
                campId: campId
            };

            let apiInfo = {
                url: `${SERVER_URL}${US8_URL}`,
                method: "POST",
                postData: gettran,
                headers: {
                    "Content-Type": "application/json",
                    tokenheaderkey: localStorage.getItem("token"),
                },
            };
            let login = await apiService2(apiInfo);
            console.log(login?.data?.responseCode)
            if(login?.data?.responseCode === 0){
                console.log('yesss')
                const campDataList = login.data.responseData[0];
                console.log(campDataList);
    
                if (Array.isArray(campDataList)) {
                    campDataList.forEach(ele => {
                        if (ele && ele.MedicationList) {
                            ele.MedicationList = JSON.parse(ele.MedicationList)
                            // ele.MedicationList = [ele.MedicationList]
                        }
                        if (ele && ele.other) {
                            ele.other = [JSON.parse(ele.other)]
                        }
                    })
                    setAlertData(campDataList);
                    // console.log(campDataList[0].campName)
                    setName(campDataList[0].campName)
    
                }
            }
           
else if(login?.data?.responseCode === 1){
    setAlert(login?.data?.responseData)
}


        })()
       
    }, []);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    // const generatePDF = () => {

    //     // Get the element you want to convert to PDF (e.g., a div with id "my-element")
    //     const element = document.getElementById('my-element');
    //     // Use html2canvas to convert the element to a canvas
    //     html2canvas(element).then((canvas) => {
    //         // Create a new instance of jsPDF
    //         const doc = new jsPDF();
    //         // Calculate the width and height of the PDF document
    //         const width = doc.internal.pageSize.getWidth();
    //         const height = doc.internal.pageSize.getHeight();
    //         // Add the canvas to the PDF document
    //         doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, width, height);

    //         // Save the PDF
    //         doc.save(`${name}alertReport.pdf`);
    //     });
    // };

React.useEffect(()=>
{
    (async () => {
        // setLoading(true);
        let gettran = {
            _methodId: "61",
            userId: localStorage.getItem("userId"),
            campId: campId
        };

        let apiInfo = {
            url: `${SERVER_URL}${ALERT_REPORT}`,
            method: "POST",
            postData: gettran,
            headers: {
                "Content-Type": "application/json",
                tokenheaderkey: localStorage.getItem("token"),
            },
        };
        let output = await apiService2(apiInfo);
        console.log(output?.data[0])
            if (Array.isArray(output?.data)) {
                setAlertPath(output?.data[0].pdfPath)

            }
        
       



    })()
}
,[])

    const generatePDF = ()=> {
        console.log('yes')
                // document.getElementByID("click-element") .addEventListener("Click", () => {
                    const element = document.getElementById('my-element');
                // const element = document.getElementByID ("my-element");
                
                var opt = {
                
                margin:6,
                padding : 4,
                filename: "alertReport.pdf",
                
                image: {type: "jpeg", quality:1},
                
                html2canvas: {useCORS:true, scale: 2},
                
                jsPDF: {unit: 'mm', format: 'a3', orientation: 'p'}
                };
                html2pdf().from (element).set (opt).save();
               
                }

    return (
        <div>
            <Grid container display='flex' justifyContent='space-between'>
                <Grid item>
                    <Stack direction='row' spacing={2} sx={{ mt: '0.75rem' }}>
                        <Link to='/AlertReport' className='link2'>
                            <Box
                                alignSelf="center"
                                component="img"
                                sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
                                alt="Logo"
                                src={ArrowBack}
                            ></Box>
                        </Link>

                        <Box>Alert Report</Box>
                    </Stack>
                </Grid>
                <Grid item>

<Button

                        variant="contained"
                        sx={{
                            bgcolor: "#4C9F70",
                            color: "white",
                            textTransform: "none",
                            "&:hover": { background: "#4C9F70", color: "white" },
                            width: "250px",
                            // mx: "0.5rem",
                            my: "0.5rem",
                        }}
                        className="gilroy"
                        
                        // onClick={handlePrint}
                    // onClick={()=>generatePDF()}
                    // onClick={() => handleConfirmCampPlace()}
                    >
<a href={alertPath} className="link2">
Export Alert Report to Pdf
</a>
                        
                    </Button>

                    

                </Grid>
            </Grid>
            <div id="my-element" >
                <Grid ref={componentRef}>
                    {alert}
                    {/* {console.log(alertData && alertData[0].campId)} */}
                    {/* {filteredList.sort(toggleSide ? (sortTypes[sortType].fn) : (sortTypes[sortType].fn1)).map((card, index) => ( */}
                    <Box sx={{ textAlign: 'center', pt: '1rem' }} className='bold'>{name}</Box>
                    {alertData && alertData.map((item, index) => (
                        <Card key={index}

                            sx={{
                                backgroundColor: "#F8F8F8",
                                color: "#707070",
                                my: "1rem",
                                border: 1,
                                borderColor: "#379592",
                                borderRadius: "0.75rem",
                                mx: '2rem'
                            }}
                        >

                            {/* <Link to={`/ViewCamp/${card.campId}`} className="link2"> */}
                            <CardContent>
                                <Grid
                                    container
                                    display="flex"
                                // alignItems='center'
                                >
                                    <Grid item md={3} textAlign='center'>
                                        <Box
                                            mt='1rem'
                                            component="img"
                                            sx={{ height: "100px", width: "100px", borderRadius: '50px' }}
                                            alt="Logo"
                                            src={item.childPhoto}
                                        // src={image}

                                        ></Box>

                                        <Box>{item.Name}</Box>
                                        <Box className="bold" sx={{ fontSize: '12px' }} >DOB : <span className='medium'>{item.childDOB}</span></Box>
                                        <Box className="bold" sx={{ fontSize: '12px' }}>Group : <span className='medium'>{item.GroupName}</span> </Box>
                                    </Grid>
                                    {/* <Grid item > */}
                                    <Divider orientation="vertical" flexItem />
                                    {/* </Grid> */}

                                    <Grid item md={8}>
                                        {/* {console.log(item.MedicationList && item.MedicationList.length, item.MedicationList)} */}
                                        <List>
                                            <ListItem >
                                                <Grid container alignItems='center'>
                                                    <Grid item md={4}>Medical Conditions:</Grid>
                                                    <Grid item md={8} className="medium">

                                                        {item.medicalConditions}
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem >
                                                <Grid container alignItems='center'>
                                                    <Grid item md={4}>Dietary Needs:</Grid>
                                                    <Grid item md={8} className="medium">
                                                        {item.dietaryNeeds}
                                                    </Grid>
                                                </Grid>
                                            </ListItem>

                                            <ListItem >
                                                <Grid container alignItems='center'>
                                                    <Grid item md={4}>Medication List:</Grid>
                                                    <Grid item md={8} className="medium">

                                                        {item.MedicationList.map((item, index, arr) => {
                                                            // console.log(Number(index) + 1, arr.length, 'forrrr')
                                                            return (
                                                                <span key={index}>

                                                                    {item}

                                                                    {(Number(index) + 1) === arr.length ? ('') : (',')}
                                                                </span>
                                                            )
                                                        }


                                                        )}
                                                        {/* {item.MedicationList} */}
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem >
                                                <Grid container  >
                                                    <Grid item md={4}>Other :</Grid>
                                                    <Grid item md={8} >
                                                        <Grid item>
                                                            {/* {item.other.Swimming} */}
                                                            {item.other.map((ele, index) => (
                                                                <Box key={index}>
                                                                    {ele.PBSPlan === 'Yes' && (<Box>PBS Plan : <span className='medium'>{ele.PBSPlan}</span></Box>)}

                                                                    {ele.Swimming === 'Poor' ?
                                                                        (
                                                                            <Box>Swimming : <span className='medium'>{ele.Swimming}</span></Box>
                                                                        ) : ('')}
                                                                    {ele.PhotosTobeTaken === 'No' && (<Box>Photos to be taken : <span className='medium'>{ele.PhotosTobeTaken}</span></Box>)}
                                                                    {ele.GroupPhoto === 'No' && (<Box>Group Photo : <span className='medium'>{ele.GroupPhoto}</span></Box>)}
                                                                    {ele.SocialMediaPhoto === 'No' && (<Box>Social Media Photo : <span className='medium'>{ele.SocialMediaPhoto}</span></Box>)}
                                                                    {ele.websitePhoto === 'No' && (<Box>Website Photo : <span className='medium'>{ele.websitePhoto}</span></Box>)}

                                                                </Box>

                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            {/* </Link> */}

                        </Card>
                    ))}


                </Grid>
            </div>



        </div>
    )

}