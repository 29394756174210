import React from "react";
import Box from "@mui/material/Box";
import "../../index.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button/Button";
import { Link } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, alpha } from "@mui/material/styles";

export default function SupportStaffReminderLog() {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // hide last border

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,

      backgroundColor: "rgba(55, 149, 146, 1)",

      color: theme.palette.common.white,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return (
    <Box>
      <Grid container direction="row">
        <Grid container display="flex" justifyContent="space-between">
          <Grid>
            <Link to="/CampList">
              <Box
                mt="0.65rem"
                alignSelf="center"
                component="img"
                sx={{
                  height: "0.75rem",
                  width: "0.65rem",
                  cursor: "pointer",
                }}
                height="20"
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>
          </Grid>
          <Grid item>
            <Box
              className="gilroy"
              alignSelf="center"
              sx={{ textAlign: "center" }}
            >
              Reminders
            </Box>
          </Grid>

          <Grid item>
            <Box
              className="gilroy"
              alignSelf="center"
              sx={{ fontSize: "1.2rem" }}
            >
              <Link to="/SetReminder" className="link2">
                <Button
                  variant="contained"
                  sx={{
                    color: "#FFFFFF",
                    bgcolor: "#FF9529",
                    textTransform: "none",
                    "&:hover": { background: "#FF9529" },
                    fontFamily: "gilroy",
                    mx: "0.5rem",
                  }}
                >
                  Set Reminder
                </Button>
              </Link>
              
            </Box>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </Grid>

      <TableContainer component={Paper} sx={{ my: "2rem", mt: "5rem" }}>
        <Table sx={{ minWidth: 450 }} aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">Date</StyledTableCell>

              <StyledTableCell align="center">Camp Manager</StyledTableCell>

              <StyledTableCell align="center">Reminders</StyledTableCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center">21/03/2023</Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center">James Jordon</Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                    Bring Sweaters since temperature are changing
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
            {/* <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
              
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
            <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
              
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
