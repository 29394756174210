import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { apiService2, apiService } from "../../Services/apiService";
import { useHistory } from "react-router-dom";
import question from "../../Assets/Icons/Icons/questionMark.png";

import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  FormHelperText,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import moment from "moment";

import Swal from "sweetalert2";

import { Link, useParams } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Add from "../../Assets/Icons/Icons/add.png";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import "../../index.css";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import {
  SERVER_URL,
  VIEW_INCIDENT,
  CREATE_INCIDENT,
  EDIT_INCIDENT,
  GET_GROUP_WISE_PARTICIPENT,
  US8_URL,
} from "../../Services/apiEndpints";
const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});
const theme1 = createTheme({
  palette: {
    primary: {
      main: "#379592", // Set your desired checkbox color
    },
  },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function EditIncident() {
  // form validation code starts from here //
  const history = useHistory();
  let { incidentReportId, campId } = useParams();
  const [participentList, setParticipentList] = useState([]);

  const [form, setForm] = useState({
    name: "",
    dateCompletingForm: "",
    dateOfIncident: "",
    timeOfIncident: "",
    locationOfIncident: "",
    nameofPerson: "",
    position: "",
    nameThatNotified: "",
    dateandtimenotified: "",
    dateandtimenotified1: "",
    typeOfIncident: "",
    seriousReportableIncident: "",
    seriousReportableIncidentYes: "",
    dateIncidentClosed: "",
    namedesignationandsignature: "",
    staffSignature: "",
    incidentandwitness: "",
    circumstanceincident: "",
    detailincidentdescription: "",
    initialresponse: "",
    otherIncident: "",
    typeOfReporting: "",
    otherTypeOfReporting: "",
    incidentRating: "",
    incidentRatingTextField: "",
    firstAidProvided: "",
    managementInformed: "",
    caregiverInformedYes: "",
    caregiverInformed: "",
    caregiverInformedNo: "",
    facetoface: "",
    firstAid: "",
    facetofaceno: "",
    regardingToPerson: "",
    regardingToPersonOther: "",
    regardingToWitness: "",
    regardingToWitnessOther: "",
    riskAssessment: "",
    riskAssessmentNo: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: null,
    dateCompletingForm: null,
    dateOfIncident: null,
    timeOfIncident: null,
    nameofPerson: null,
    locationOfIncident: null,
    position: null,
    nameThatNotified: null,
    dateandtimenotified: null,
    dateandtimenotified1: null,
    typeOfIncident: null,
    seriousReportableIncident: null,
    seriousReportableIncidentYes: null,
    dateIncidentClosed: null,
    namedesignationandsignature: null,
    staffSignature: null,
    incidentandwitness: null,
    circumstanceincident: null,
    detailincidentdescription: null,
    initialresponse: null,
    otherIncident: null,
    typeOfReporting: null,
    otherTypeOfReporting: null,
    incidentRating: null,
    incidentRatingTextField: null,
    firstAidProvided: null,
    managementInformed: null,
    caregiverInformed: null,
    caregiverInformedYes: null,
    caregiverInformedNo: null,
    facetoface: null,
    firstAid: null,
    facetofaceno: null,
    regardingToPerson: null,
    regardingToPersonOther: null,
    regardingToWitness: null,
    regardingToWitnessOther: null,
    riskAssessment: null,
    riskAssessmentNo: null,
  });

  const [assignCampList, setassignCampList] = useState([]);
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    let formObj = {};

    if (
      name === "typeOfIncident" ||
      name === "seriousReportableIncidentYes" ||
      name === "typeOfReporting" ||
      name === "caregiverInformedYes" ||
      name === "caregiverInformedNo"
    ) {
      if (checked) {
        formObj = { ...form, [name]: [...form[name], value] };
      } else {
        formObj = { ...form, [name]: form[name].filter((x) => x !== value) };
      }
    } else if (name === "regardingToPerson") {
      if (checked && value === "None Taken") {
        formObj = {
          ...form,
          regardingToPerson: [value],
          regardingToPersonOther: "",
        };
      } else if (value === "None Taken") {
        formObj = { ...form, regardingToPerson: [] };
      } else {
        const currentRegardingToPerson = Array.isArray(form.regardingToPerson)
          ? form.regardingToPerson
          : [];
        formObj = {
          ...form,
          regardingToPerson: [
            ...currentRegardingToPerson.filter((x) => x !== "None Taken"),
            value,
          ],
        };
      }
    } else if (name === "regardingToWitness") {
      if (checked && value === "None Taken") {
        formObj = {
          ...form,
          regardingToWitness: [value],
          regardingToWitnessOther: "",
        };
      } else if (value === "None Taken") {
        formObj = { ...form, regardingToWitness: [] };
      } else {
        const currentRegardingToPerson = Array.isArray(form.regardingToWitness)
          ? form.regardingToWitness
          : [];
        formObj = {
          ...form,
          regardingToWitness: [
            ...currentRegardingToPerson.filter((x) => x !== "None Taken"),
            value,
          ],
        };
      }
    } else {
      formObj = { ...form, [name]: value };
    }

    // ... Rest of the handleChange function ...
    setForm(formObj);
    validateField(name, formObj[name], formObj.seriousReportableIncident);

    if (name === "name") {
      getParticipentList(value);
    }
  };
  const [selectedValues1, setSelectedValues1] = useState([]);

  const handleChange1 = (event) => {
    setSelectedValues1(event.target.value);
    console.log(selectedValues1);
  };
  async function getParticipentList(value) {
    let gettran = {
      userId: localStorage.getItem("userId"),
      campId: value,
    };

    let apiInfo = {
      url: `${SERVER_URL}${GET_GROUP_WISE_PARTICIPENT}`,
      method: "POST",
      postData: gettran,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let login = await apiService2(apiInfo);
    console.log(login);
    setParticipentList(login["data"]["responseData"][0]);
  }
  React.useEffect(() => {
    (async () => {
      console.log(incidentReportId);

      // handleAddRow()
      let gettran = {
        userId: localStorage.getItem("userId"),
        campId: campId,
        incidentId: incidentReportId,
      };

      let apiInfo = {
        url: `${SERVER_URL}${VIEW_INCIDENT}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);
      const campDataList = login.data.responseData[0];
      console.log(campDataList);

      const camps = {
        _methodId: "1",
        userId: localStorage.getItem("userId"),
        roleName: "THERAPIST",
      };

      let apiInfo1 = {
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: camps,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let assign = await apiService2(apiInfo1);
      console.log(assign);
      const campDataList1 = assign.data.responseData[0];
      setassignCampList(campDataList1);
      // const otherObject = JSON.stringify(campDataList[0].typeOfIncident).find(obj => obj.Key === 'Other');
      console.log(campDataList[0].typeOfIncident);

      const otherObject = campDataList[0].typeOfIncident.find(
        (obj) => obj.Key === "Other"
      );
      //       console.log(otherObject)
      const otherTypeOfReportingObject = campDataList[0].typeOfReporting.find(
        (obj) => obj.Key === "reportedByOther"
      );
      console.log(otherTypeOfReportingObject);
      const witnessSuppCaregiverInformedYesNo =
        campDataList[0].witnessSuppCaregiverVia.find(
          (obj) => obj.Key === "Face to Face with"
        );
      const resolutionActionsPersonDescription =
        campDataList[0].resolutionActionsPerson.find(
          (obj) => obj.Key === "Other"
        );
      const resolutionActionsWitnessDescription =
        campDataList[0].resolutionActionsWitness.find(
          (obj) => obj.Key === "Other"
        );
      if (
        campDataList[0]["participantsInvolved"] &&
        campDataList[0]["participantsInvolved"].length > 0
      ) {
        setSelectedValues1(campDataList[0]["participantsInvolved"]);
      }

      let incidentClosedDate = moment(
        campDataList[0].incidentClosedDate,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      let dateOfIncident = moment(
        campDataList[0].dateOfIncident,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      let incidentNotifiedDatetime = moment(
        campDataList[0].incidentNotifiedDatetime,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      console.log(new Date());
      let dateCompletingForm = moment(new Date(), "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      setForm({
        ...form,
        name: campDataList[0].campId,
        nameofPerson: localStorage.getItem("userName"),
        position: localStorage.getItem("rolesName"),
        staffSignature: localStorage.getItem("userName"),
        namedesignationandsignature: localStorage.getItem("userName"),
        namedesignationandsignature1: localStorage.getItem("rolesName"),
        dateCompletingForm: dateCompletingForm,
        dateOfIncident: dateOfIncident,
        timeOfIncident: campDataList[0].timeOfIncident,
        locationOfIncident: campDataList[0].locationOfIncident,
        nameThatNotified: campDataList[0].incidentNotifiedBy,
        dateandtimenotified: incidentNotifiedDatetime,
        dateandtimenotified1: campDataList[0].incidentNotifiedTime,
        typeOfIncident: campDataList[0].typeOfIncident.map(
          (incident) => incident.Key
        ),
        otherIncident:
          otherObject && otherObject.value ? otherObject?.value : "",
        seriousReportableIncident: campDataList[0].seriousReportableIncident,
        seriousReportableIncidentYes: campDataList[0].seriousIncidentType,
        typeOfReporting: campDataList[0].typeOfReporting.map(
          (incident) => incident.Key
        ),
        otherTypeOfReporting:
          otherTypeOfReportingObject && otherTypeOfReportingObject.value
            ? otherTypeOfReportingObject?.value
            : "",
        incidentRating: campDataList[0].incidentRating,

        incidentRatingTextField: campDataList[0].incidentRatingVal,
        incidentandwitness: campDataList[0].witness,
        circumstanceincident: campDataList[0].circumstancesLeadToIncident,
        detailincidentdescription:
          campDataList[0].detailedDescriptionOfIncident,
        initialresponse: campDataList[0].initialRespose,
        firstAid: campDataList[0].seriousReportableIncident,
        firstAidProvided: campDataList[0].seriousReportableIncidentDesc,

        managementInformed: campDataList[0].witnessSuppManangementInformed,
        caregiverInformed: campDataList[0].witnessSuppCaregiverInformed,
        caregiverInformedYes: campDataList[0].witnessSuppCaregiverVia.map(
          (incident) => incident.Key
        ),

        facetoface:
          campDataList[0].witnessSuppCaregiverInformed === "Yes"
            ? witnessSuppCaregiverInformedYesNo?.value
            : "",
        facetofaceno:
          campDataList[0].witnessSuppCaregiverInformed === "No"
            ? witnessSuppCaregiverInformedYesNo?.value
            : "",

        caregiverInformedNo: campDataList[0].witnessSuppCaregiverVia.map(
          (incident) => incident.Key
        ),

        regardingToPerson: campDataList[0].resolutionActionsPerson.map(
          (incident) => incident.Key
        ),

        regardingToWitness: campDataList[0].resolutionActionsWitness.map(
          (incident) => incident.Key
        ),

        regardingToPersonOther: resolutionActionsPersonDescription?.value,
        regardingToWitnessOther: resolutionActionsWitnessDescription?.value,
        riskAssessment: campDataList[0].riskAssessment.includes(":")
          ? campDataList[0].riskAssessment.split(":")[0]
          : campDataList[0].riskAssessment,
        riskAssessmentNo: campDataList[0].riskAssessment.includes(":")
          ? campDataList[0].riskAssessment.split(":")[1]
          : "",
        dateIncidentClosed: incidentClosedDate,

        // namedesignationandsignature: campDataList[0]["incidentClosedBy"],
      });
      console.log(campDataList[0].incidentClosedDate);
      console.log(form.dateIncidentClosed);
      if (campDataList && campDataList[0].mediaEngagement.length > 0) {
        console.log(campDataList[0].mediaEngagement);
        const updatedTableRows = campDataList[0].mediaEngagement.map((val) => {
          const rowId = moment().valueOf();
          const newRow = {
            rowId,
            [`outlet${rowId}`]: val["outlet"],
            [`journalist${rowId}`]: val["journalist"],
            [`engagement${rowId}`]: val["engagement"],
          };
          return newRow;
        });
        setRows(updatedTableRows);
        updatedTableRows.forEach((row) => {
          const { rowId, ...rest } = row;
          setForm((prevForm) => ({
            ...prevForm,
            ...rest,
          }));
        });
        console.log(form);
      }
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);

  const validateField = (name, value, seriousReportableIncident) => {
    let errorMsg = null;
    console.log(form.caregiverInformed + "" + form.caregiverInformedYes);
    switch (name) {
      case "name":
        if (!value) errorMsg = "Please select a camp.";
        break;
      case "dateCompletingForm":
        if (!value) errorMsg = "Please enter date of completing form.";
        break;
      case "dateOfIncident":
        if (!value) errorMsg = "Please enter date of incident.";
        break;
      case "timeOfIncident":
        if (!value) errorMsg = "Please enter time of incident.";
        break;
      case "incidentRating":
        if (!value) errorMsg = "Please enter incident Rating.";
        break;
      case "riskAssessment":
        if (!value) errorMsg = "Please select the value.";
        break;

      case "riskAssessmentNo":
        if (form.riskAssessment === "No" && !value) {
          errorMsg = "Please enter the field.";
        }
        break;

      case "managementInformed":
        if (!value) errorMsg = "Please enter management Informed.";
        break;

      case "incidentRatingTextField":
        if (form.typeOfIncident === "incidentRating" && !value) {
          errorMsg = "Please enter the field.";
        }
        break;
      case "locationOfIncident":
        if (!value) errorMsg = "Please enter location of incident.";
        break;
      case "position":
        if (!value) errorMsg = "Please enter position.";
        break;

      case "typeOfIncident":
        if (value.length === 0) errorMsg = "Please select type of incident.";
        break;
      case "otherIncident":
        if (form.typeOfIncident.includes("Other") && !value) {
          errorMsg = "Please enter the other incident.";
        }
        break;
      case "typeOfReporting":
        if (value.length === 0) errorMsg = "Please select type of reporting.";
        break;
      case "otherTypeOfReporting":
        if (form.typeOfReporting.includes("otherTypeOfReporting") && !value) {
          errorMsg = "Please enter the other type of reporting.";
        }
        break;

      case "regardingToPerson":
        if (value.length === 0) errorMsg = "Please select the option.";
        break;

      case "regardingToPersonOther":
        if (form.regardingToPerson.includes("Other") && !value) {
          errorMsg = "Please enter the value.";
        }
        break;

      case "regardingToWitness":
        if (value.length === 0) errorMsg = "Please select the option.";
        break;

      case "regardingToWitnessOther":
        if (form.regardingToWitness.includes("Other") && !value) {
          errorMsg = "Please enter the value.";
        }
        break;

      case "nameofPerson":
        if (!value) errorMsg = "Please enter name of person.";
        break;
      case "location":
        if (!value) errorMsg = "Please enter location.";
        break;
      case "nameThatNotified":
        if (!value) errorMsg = "Please enter name of person that notified.";
        break;
      case "dateandtimenotified":
        if (!value) errorMsg = "Please enter date notified.";
        break;
      case "dateandtimenotified1":
        if (!value) errorMsg = "Please enter time notified.";
        break;
      case "seriousReportableIncident":
        if (!value) errorMsg = "Please select Serious Reportable Incident.";
        break;
      case "dateIncidentClosed":
        if (!value) errorMsg = "Please enter date Incident Closed.";

        break;
      case "incidentandwitness":
        if (!value) errorMsg = "Please enter incident and witness.";

        break;

      case "namedesignationandsignature":
        if (!value) errorMsg = "Please enter name designation and signature.";

        break;
      case "caregiverInformed":
        if (!value) errorMsg = "Please enter caregiver Informed.";

        break;

      case "staffSignature":
        if (!value) errorMsg = "Please enter staff Signature.";

        break;
      case "circumstanceincident":
        if (!value) errorMsg = "Please enter circumstance incident.";
        break;
      case "detailincidentdescription":
        if (!value) errorMsg = "Please enter detail incident description.";
        break;

      case "initialresponse":
        if (form.seriousReportableIncident === "Yes" && value.length === 0)
          errorMsg = "Please enter initialresponse.";
        break;

      case "seriousReportableIncidentYes":
        if (form.seriousReportableIncident === "Yes" && value.length === 0)
          errorMsg = "Please select serious Reportable Incident Yes.";
        break;
      case "firstAid":
        if (!value) errorMsg = "Please select First Aid.";
        break;
      case "firstAidProvided":
        if (form.firstAid === "Yes" && value.length === 0)
          errorMsg = "Please select first Aid Provided.";
        break;
      case "caregiverInformedYes":
        if (form.caregiverInformed === "Yes" && value.length === 0)
          errorMsg = "Please select caregiver Informed Yes.";
        break;
      case "caregiverInformedNo":
        if (form.caregiverInformed === "No" && value.length === 0)
          errorMsg = "Please select caregiver Informed No.";
        break;

      case "facetoface":
        if (
          form.caregiverInformed === "Yes" &&
          form.caregiverInformedYes.includes("Face to Face with") &&
          !value
        )
          // errorMsg = "Please select face to face.";
          break;

      case "facetofaceno":
        if (
          form.caregiverInformed === "No" &&
          form.caregiverInformedNo.includes("Face to Face with") &&
          !value
        )
          // errorMsg = "Please select face to face.";
          break;

      default:
        break;
    }

    setFormErrors({ ...formErrors, [name]: errorMsg });
    return errorMsg;
  };
  const [tableErros, settableErros] = useState({});
  const validateForm = () => {
    const errors = {};
    Object.keys(form).forEach((name) => {
      const value = form[name];
      const error = validateField(name, value, form.password);
      if (error) {
        errors[name] = error;
      }
    });
    return errors;
  };
  const tableErrors = () => {
    let errorMsg = {};

    rows.forEach((row) => {
      if (!form[`outlet${row.rowId}`]?.trim()) {
        errorMsg[`outlet${row.rowId}`] = "Outlet is required";
      }

      if (!form[`journalist${row.rowId}`]?.trim()) {
        errorMsg[`journalist${row.rowId}`] = "Journalist is required";
      }
      if (!form[`engagement${row.rowId}`]?.trim()) {
        errorMsg[`engagement${row.rowId}`] = "Engagement is required";
      }
    });
    return errorMsg;
  };
  const [isTableErrors, setisTableErros] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    const tableErros = tableErrors();
    console.log(tableErros);
    let selectedChildIDs = [];
    participentList.forEach((user) => {
      if (selectedValues1 && selectedValues1.length > 0) {
        selectedValues1.forEach((val) => {
          if (val === user.childName) {
            selectedChildIDs.push(user.childId.toString());
          }
        });
      }
    });
    setFormErrors(errors); // Update formErrors state with validation errors
    if (Object.keys(tableErros).length === 0 && rows && rows.length > 0) {
      setisTableErros(false);
    } else {
      setisTableErros(true);
    }
    if (
      Object.keys(errors).length === 0 &&
      rows &&
      rows.length > 0 &&
      Object.keys(tableErros).length === 0
    ) {
      console.log("Form Data:", form);

      console.log(form);
      const body = {};
      body["campId"] = form["name"];
      body["incidentId"] = incidentReportId;
      body["dateOfIncident"] = form["dateOfIncident"];
      body["timeOfIncident"] = form["timeOfIncident"];
      body["locationOfIncident"] = form["locationOfIncident"];
      body["incidentNotifiedBy"] = form["nameofPerson"];
      body["incidentNotifiedDatetime"] = form["dateandtimenotified"];
      body["incidentNotifiedTime"] = form["dateandtimenotified1"];
      body["participantsInvolved"] = selectedChildIDs;

      body["typeOfIncident"] = [];

      debugger;
      //const parsedVal = JSON.parse(form.typeOfIncident);

      form.typeOfIncident.forEach((incident) => {
        const incidentObject = {
          Key: incident,
          value: incident === "Other" ? form.otherIncident : "Yes",
        };
        body["typeOfIncident"].push(incidentObject);
      });

      body["isIncidentSerious"] = form["seriousReportableIncident"];
      if (form["seriousReportableIncidentYes"].length === 0) {
        body["seriousIncidentType"] = [];
      } else {
        body["seriousIncidentType"] = form["seriousReportableIncidentYes"];
      }

      body["typeOfReporting"] = [];
      //const parsedValOne = JSON.parse(form.typeOfReporting)
      form.typeOfReporting.forEach((incident) => {
        const incidentObject = {
          Key: incident,
          value:
            incident === "reportedByOther" ? form.otherTypeOfReporting : "Yes",
        };
        body["typeOfReporting"].push(incidentObject);
      });

      body["incidentRating"] = form["incidentRating"];
      body["incidentRatingVal"] = form["incidentRatingTextField"];
      body["witness"] = form["incidentandwitness"];
      body["circumstancesLeadToIncident"] = form["circumstanceincident"];
      body["detailedDescriptionOfIncident"] = form["detailincidentdescription"];

      body["initialRespose"] = form["initialresponse"];

      body["seriousReportableIncident"] = form["firstAid"];
      body["seriousReportableIncidentDesc"] = form["firstAidProvided"];
      body["witnessSuppManangementInformed"] = form["managementInformed"];
      body["witnessSuppCaregiverInformed"] = form["caregiverInformed"];

      if (body["witnessSuppCaregiverInformed"] === "Yes") {
        body["witnessSuppCaregiverVia"] = [];
        //  const parsedValTwo = JSON.parse(form.caregiverInformedYes)

        form.caregiverInformedYes.forEach((incident) => {
          const incidentObject = {
            Key: incident,
            value: incident === "Face to Face with" ? form.facetoface : "Yes",
          };
          body["witnessSuppCaregiverVia"].push(incidentObject);
        });
      } else {
        body["witnessSuppCaregiverVia"] = [];
        //const parsedValThree = JSON.parse(form.caregiverInformedNo)

        form.caregiverInformedNo.forEach((incident) => {
          const incidentObject = {
            Key: incident,
            value: incident === "Face to Face with" ? form.facetofaceno : "Yes",
          };
          body["witnessSuppCaregiverVia"].push(incidentObject);
        });
      }

      body["resolutionActionsPerson"] = [];
      //const parsedValFour = JSON.parse(form.regardingToPerson)

      form.regardingToPerson.forEach((incident) => {
        const incidentObject = {
          Key: incident,
          value: incident === "Other" ? form.regardingToPersonOther : "Yes",
        };
        body["resolutionActionsPerson"].push(incidentObject);
      });

      body["resolutionActionsWitness"] = [];
      // const parsedValFive = JSON.parse(form.regardingToWitness)
      form.regardingToWitness.forEach((incident) => {
        const incidentObject = {
          Key: incident,
          value: incident === "Other" ? form.regardingToWitnessOther : "Yes",
        };
        body["resolutionActionsWitness"].push(incidentObject);
      });

      if (form["riskAssessment"] === "No") {
        body["riskAssessment"] =
          form["riskAssessment"] + ":" + form["riskAssessmentNo"];
      } else {
        body["riskAssessment"] = form["riskAssessment"];
      }

      const resultArray = [];

      rows.forEach((data) => {
        const rowId = data.rowId;
        const rowIdString = rowId.toString();

        const engagementKey = `engagement${rowIdString}`;
        const journalistKey = `journalist${rowIdString}`;
        const outletKey = `outlet${rowIdString}`;

        if (
          form.hasOwnProperty(engagementKey) &&
          form.hasOwnProperty(journalistKey) &&
          form.hasOwnProperty(outletKey)
        ) {
          const engagementValue = form[engagementKey];
          const journalistValue = form[journalistKey];
          const outletValue = form[outletKey];

          const resultObject = {
            engagement: engagementValue,
            journalist: journalistValue,
            outlet: outletValue,
          };

          resultArray.push(resultObject);
        }
      });

      body["mediaEngagement"] = resultArray;
      body["incidentClosedDate"] = form["dateIncidentClosed"];
      body["incidentClosedBy"] = localStorage.getItem("userId");
      body["userId"] = localStorage.getItem("userId");
      body["comments"] = "NA";
      body["status"] = "Submitted";

      let apiInfo = {
        url: `${SERVER_URL}${EDIT_INCIDENT}`,
        method: "POST",
        postData: body,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      // let createIncident = await apiService(apiInfo);
      Swal.fire({
        iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
        text: "Are you sure you want to Submit?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#379592",
        cancelButtonText: "No",
        cancelButtonColor: "#909090",
        customClass: {
          container: "my-swal",
          icon: "no-border",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          let createIncident = await apiService(apiInfo);

          if (createIncident?.data?.responseCode === 0) {
            Swal.fire({
              iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
              text: createIncident?.data?.responseData[0][0].Desc,
              confirmButtonText: "OK",
              confirmButtonColor: "#379592",
              customClass: {
                container: "my-swal",
              },
            }).then(history.push("/IncidentReport"));
          }
        }
      });
    }
  };
  const handleSave = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    const tableErros = tableErrors();
    console.log(tableErros);
    console.log(selectedValues1);
    let selectedChildIDs = [];
    participentList.forEach((user) => {
      if (selectedValues1 && selectedValues1.length > 0) {
        selectedValues1.forEach((val) => {
          if (val === user.childName) {
            selectedChildIDs.push(user.childId.toString());
          }
        });
      }
    });
    setFormErrors(errors); // Update formErrors state with validation errors
    if (Object.keys(tableErros).length === 0 && rows && rows.length > 0) {
      setisTableErros(false);
    } else {
      setisTableErros(true);
    }
    if (
      Object.keys(errors).length === 0 &&
      rows &&
      rows.length > 0 &&
      Object.keys(tableErros).length === 0
    ) {
      console.log("Form Data:", form);

      console.log(form);
      const body = {};
      body["campId"] = form["name"];
      body["incidentId"] = incidentReportId;

      body["dateOfIncident"] = form["dateOfIncident"];
      body["timeOfIncident"] = form["timeOfIncident"];
      body["locationOfIncident"] = form["locationOfIncident"];
      body["incidentNotifiedBy"] = form["nameofPerson"];
      body["incidentNotifiedDatetime"] = form["dateandtimenotified"];
      body["incidentNotifiedTime"] = form["dateandtimenotified1"];
      body["participantsInvolved"] = selectedChildIDs;

      body["typeOfIncident"] = [];

      form.typeOfIncident.forEach((incident) => {
        const incidentObject = {
          Key: incident,
          value: incident === "Other" ? form.otherIncident : "Yes",
        };
        body["typeOfIncident"].push(incidentObject);
      });

      body["isIncidentSerious"] = form["seriousReportableIncident"];
      if (form["seriousReportableIncidentYes"].length === 0) {
        body["seriousIncidentType"] = [];
      } else {
        body["seriousIncidentType"] = form["seriousReportableIncidentYes"];
      }

      // if (form["typeOfReporting"].includes("Other")) {
      //   let index = form["typeOfReporting"].indexOf("Other");
      //   form["typeOfReporting"][index] = `Other: ${form.otherTypeOfReporting}`;
      //   body["typeOfReporting"] = form.typeOfReporting;
      // } else {
      //   body["typeOfIncident"] = form.typeOfIncident;
      // }

      body["typeOfReporting"] = [];

      form.typeOfReporting.forEach((incident) => {
        const incidentObject = {
          Key: incident,
          value:
            incident === "reportedByOther" ? form.otherTypeOfReporting : "Yes",
        };
        body["typeOfReporting"].push(incidentObject);
      });

      body["incidentRating"] = form["incidentRating"];
      body["incidentRatingVal"] = form["incidentRatingTextField"];
      body["witness"] = form["incidentandwitness"];
      body["circumstancesLeadToIncident"] = form["circumstanceincident"];
      body["detailedDescriptionOfIncident"] = form["detailincidentdescription"];

      body["initialRespose"] = form["initialresponse"];

      body["seriousReportableIncident"] = form["seriousReportableIncident"];
      body["seriousReportableIncidentDesc"] = form["firstAidProvided"];
      body["witnessSuppManangementInformed"] = form["managementInformed"];
      body["witnessSuppCaregiverInformed"] = form["caregiverInformed"];

      if (body["witnessSuppCaregiverInformed"] === "Yes") {
        body["witnessSuppCaregiverVia"] = [];

        form.caregiverInformedYes.forEach((incident) => {
          const incidentObject = {
            Key: incident,
            value: incident === "Face to Face with" ? form.facetoface : "Yes",
          };
          body["witnessSuppCaregiverVia"].push(incidentObject);
        });
      } else {
        body["witnessSuppCaregiverVia"] = [];

        form.caregiverInformedNo.forEach((incident) => {
          const incidentObject = {
            Key: incident,
            value: incident === "Face to Face with" ? form.facetofaceno : "Yes",
          };
          body["witnessSuppCaregiverVia"].push(incidentObject);
        });
      }

      body["resolutionActionsPerson"] = [];

      form.regardingToPerson.forEach((incident) => {
        const incidentObject = {
          Key: incident,
          value: incident === "Other" ? form.regardingToPersonOther : "Yes",
        };
        body["resolutionActionsPerson"].push(incidentObject);
      });

      body["resolutionActionsWitness"] = [];

      form.regardingToWitness.forEach((incident) => {
        const incidentObject = {
          Key: incident,
          value: incident === "Other" ? form.regardingToWitnessOther : "Yes",
        };
        body["resolutionActionsWitness"].push(incidentObject);
      });

      if (form["riskAssessment"] === "No") {
        body["riskAssessment"] =
          form["riskAssessment"] + ":" + form["riskAssessmentNo"];
      } else {
        body["riskAssessment"] = form["riskAssessment"];
      }

      const resultArray = [];

      rows.forEach((data) => {
        const rowId = data.rowId;
        const rowIdString = rowId.toString();

        const engagementKey = `engagement${rowIdString}`;
        const journalistKey = `journalist${rowIdString}`;
        const outletKey = `outlet${rowIdString}`;

        if (
          form.hasOwnProperty(engagementKey) &&
          form.hasOwnProperty(journalistKey) &&
          form.hasOwnProperty(outletKey)
        ) {
          const engagementValue = form[engagementKey];
          const journalistValue = form[journalistKey];
          const outletValue = form[outletKey];

          const resultObject = {
            engagement: engagementValue,
            journalist: journalistValue,
            outlet: outletValue,
          };

          resultArray.push(resultObject);
        }
      });

      body["mediaEngagement"] = resultArray;
      body["incidentClosedDate"] = form["dateIncidentClosed"];
      body["incidentClosedBy"] = localStorage.getItem("userId");
      body["userId"] = localStorage.getItem("userId");
      body["comments"] = "NA";
      body["status"] = "Draft";

      let apiInfo = {
        url: `${SERVER_URL}${EDIT_INCIDENT}`,
        method: "POST",
        postData: body,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let createIncident = await apiService(apiInfo);

      if (createIncident?.data?.responseCode === 0) {
        // if (Submit) {
        Swal.fire({
          icon: "success",
          iconHtml: `<img src="${GreenCheck}" >`,
          text: "Incident Report Saved Successfully!",
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
        }).then(history.push("/IncidentReport"));
      } else {
        Swal.fire({
          icon: "error",
          text: createIncident?.data?.responseData[0][0].Desc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
        });
      }
    }
    // }
  };
  const [rows, setRows] = useState([]);

  const typeOfIncident = [
    { value: "Physical Injury", label: "Physical Injury" },
    { value: "Emotional", label: "Emotional" },
    { value: "Medication", label: "Medication" },
    { value: "Complaint", label: "Complaint" },
    { value: "Restrictive Practice", label: "Restrictive Practice" },
    { value: "Other", label: "Other" },
  ];
  const typeOfReporting = [
    {
      value: "observedByWorker",
      label: "Observed by Worker",
    },
    {
      value: "reportedByClient",
      label: "Reported by Client",
    },
    {
      value: "reportedByOther",
      label: "Reported by Other",
    },
  ];
  const seriousReportableIncidentYes = [
    { value: "PhysicalInjury", label: "Physical Injury" },
    { value: "abusedisability", label: "Abuse Of A Person With Disability" },
    { value: "death", label: "Death" },
    { value: "neglect", label: "Neglect" },
    { value: "sexualMisconduct", label: "Sexual Misconduct" },
    {
      value: "unauthorisedRestrictivePractice",
      label: "Un AuthorisedRestrictivePractice",
    },
    { value: "unlawfulPhysicalContact", label: "Un Lawful Physical Contact" },
    { value: "unlawfulSexualContact", label: "Un Lawful Sexual Contact" },
  ];

  const caregiverInformedYes = [
    {
      value: "Phone Call",
      label: "Phone Call",
    },
    {
      value: "Email",
      label: "Email",
    },
    {
      value: "Face to Face with",
      label: "Face to Face with",
    },
  ];
  const caregiverInformedNo = [
    {
      value: "Phone Call",
      label: "Phone Call",
    },
    {
      value: "Email",
      label: "Email",
    },
    {
      value: "Face to Face with",
      label: "Face to Face with",
    },
  ];

  const regardingToPerson = [
    {
      value: "Advocacy",
      label: "Advocacy",
    },
    {
      value: "Contacted Support Person",
      label: "Contacted Support Person",
    },
    {
      value: "Counselling",
      label: "Counselling",
    },

    {
      value: "Debrief",
      label: "Debrief",
    },
    {
      value: "First Aid",
      label: "First Aid",
    },
    {
      value: "Medical Treatment",
      label: "Medical Treatment",
    },
    {
      value: "None Taken",
      label: "None Taken",
    },
    {
      value: "Removed source of Harm",
      label: "Removed source of Harm",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  const regardingToWitness = [
    {
      value: "Advocacy",
      label: "Advocacy",
    },
    {
      value: "Contacted Support Person",
      label: "Contacted Support Person",
    },
    {
      value: "Counselling",
      label: "Counselling",
    },

    {
      value: "Debrief",
      label: "Debrief",
    },
    {
      value: "First Aid",
      label: "First Aid",
    },
    {
      value: "Medical Treatment",
      label: "Medical Treatment",
    },
    {
      value: "None Taken",
      label: "None Taken",
    },
    {
      value: "Removed source of Harm",
      label: "Removed source of Harm",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  const handleAddRow = () => {
    const newRow = {
      rowId: moment().valueOf(),
    };

    setRows((prevState) => [...prevState, newRow]);
  };
  const handleDeleteRow = (id) => {
    console.log(rows);
    setRows(rows.filter((row) => row.rowId !== id));
  };
  const handleRowChange = (id, field, value, field1, value1) => {
    {
      console.log(id, field, value, field1, value1);
    }
    setRows(
      rows.map((row) =>
        row.id === id ? { ...row, [field]: value, [field1]: value1 } : row
      )
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box style={{ position: "relative" }}>
        <form>
          <Box
            position="absolute"
            top={0}
            left={0}
            width={200}
            height={100}
            pt={2}
          >
            <Grid display="flex">
              <Grid item xs={12} sm={12} md={12} justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                  {/* <Link
                    to={`/viewIncidentReport/${incidentReportId}/${campId}`}
                  > */}
                    <Box
                      component="img"
                      sx={{
                        height: "0.75rem",

                        width: "0.65rem",

                        cursor: "pointer",
                      }}
                      height="20"
                      alt="Logo"
                      src={ArrowBack}
                      onClick={()=>{history.goBack()}}
                    ></Box>
                  {/* </Link> */}

                  <Box className="gilroy" alignSelf="center">
                    Edit Incident Reports
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} >
            </Grid>

            <Grid item md={12}>
              <Box mt="1rem" sx={{ mb: "0.75rem" }} className="medium">
              <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                <Box sx={{ color: "#000" }}>
                  Selected Assigned Camp:
                  <TextField
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    onBlur={() => validateField("name", form.name)}
                    sx={{ ml: "1rem", color: "#000000" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  ></TextField>
                  {formErrors.name && (
                    <FormHelperText error>
                      {formErrors.name}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>
                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Date Completing Form:
                    <TextField
                      id="standard-basic"
                      size="small"
                      type="date"
                      format="YYYY-MM-DD"
                      variant="outlined"
                      name="dateCompletingForm"
                      value={form.dateCompletingForm}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField(
                          "dateCompletingForm",
                          form.dateCompletingForm
                        )
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                    ></TextField>
                    {formErrors.dateCompletingForm && (
                      <FormHelperText error>
                        {formErrors.dateCompletingForm}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>
                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Date Of Incident:
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      type="date"
                      format="YYYY-MM-DD"
                      name="dateOfIncident"
                      value={form.dateOfIncident}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField("dateOfIncident", form.dateOfIncident)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: false,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                      inputProps={{ max: new Date().toISOString().split("T")[0] }}
                    ></TextField>
                    {formErrors.dateOfIncident && (
                      <FormHelperText error>
                        {formErrors.dateOfIncident}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>
                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Time Of Incident:
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="timeOfIncident"
                      value={form.timeOfIncident}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField("timeOfIncident", form.timeOfIncident)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: false,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                    ></TextField>
                    {formErrors.timeOfIncident && (
                      <FormHelperText error>
                        {formErrors.timeOfIncident}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>
                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Location Of Incident:
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="locationOfIncident"
                      value={form.locationOfIncident}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField(
                          "locationOfIncident",
                          form.locationOfIncident
                        )
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: false,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                    ></TextField>
                    {formErrors.locationOfIncident && (
                      <FormHelperText error>
                        {formErrors.locationOfIncident}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>

                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Name Of Person Completing The Form:
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="nameofPerson"
                      value={form.nameofPerson}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField("nameofPerson", form.nameofPerson)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: false,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                    ></TextField>
                    {formErrors.nameofPerson && (
                      <FormHelperText error>
                        {formErrors.nameofPerson}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>

                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Position:
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="position"
                      value={form.position}
                      onChange={handleChange}
                      onBlur={() => validateField("position", form.position)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: false,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                    ></TextField>
                    {formErrors.position && (
                      <FormHelperText error>
                        {formErrors.position}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>

                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Name that notified was told:
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="nameThatNotified"
                      value={form.nameThatNotified}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField("nameThatNotified", form.nameThatNotified)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: false,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                    ></TextField>
                    {formErrors.nameThatNotified && (
                      <FormHelperText error>
                        {formErrors.nameThatNotified}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>

                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Date that notified was told:
                    <TextField
                      id="standard-basic"
                      size="small"
                      type="date"
                      format="YYYY-MM-DD"
                      variant="outlined"
                      name="dateandtimenotified"
                      value={form.dateandtimenotified}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField(
                          "dateandtimenotified",
                          form.dateandtimenotified
                        )
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: false,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                      inputProps={{ max: new Date().toISOString().split("T")[0] }}
                    ></TextField>
                    {formErrors.dateandtimenotified && (
                      <FormHelperText error>
                        {formErrors.dateandtimenotified}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>

                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Time that notified was told:
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="dateandtimenotified1"
                      value={form.dateandtimenotified1}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField(
                          "dateandtimenotified1",
                          form.dateandtimenotified1
                        )
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                    ></TextField>
                    {formErrors.dateandtimenotified1 && (
                      <FormHelperText error>
                        {formErrors.dateandtimenotified1}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>
                <Box sx={{ color: "#000" }}>Type of Incident:</Box>

                <Box>
                  {typeOfIncident.map((language) => (
                    <Box>
                      <FormControlLabel
                        key={language.value}
                        control={
                          <Checkbox
                            name="typeOfIncident"
                            className="custom-checkbox"
                            value={language.value}
                            checked={form.typeOfIncident.includes(
                              language.value
                            )}
                            onChange={handleChange}
                            onBlur={() =>
                              validateField(
                                "typeOfIncident",
                                form.typeOfIncident
                              )
                            }
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        }
                        label={language.label}
                      />
                    </Box>
                  ))}
                  {formErrors.typeOfIncident && (
                    <FormHelperText error>
                      {formErrors.typeOfIncident}
                    </FormHelperText>
                  )}
                </Box>

                {form.typeOfIncident.includes("Other") && (
                  <TextField
                    name="otherIncident"
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    label="Other Incident"
                    value={form.otherIncident}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField("otherIncident", form.otherIncident)
                    }
                    InputProps={{
                      readOnly: false,
                    }}
                    error={!!formErrors.otherIncident}
                    helperText={formErrors.otherIncident}
                  />
                )}

                <Box sx={{ color: "#000" }}>
                  If this is a Serious Reportable Incident:
                </Box>

                <Box>
                  <ThemeProvider theme={theme1}>
                    <RadioGroup
                      name="seriousReportableIncident"
                      value={form.seriousReportableIncident}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: false,
                      }}
                      onBlur={() =>
                        validateField(
                          "seriousReportableIncident",
                          form.seriousReportableIncident
                        )
                      }
                    >
                      <FormControlLabel
                        value="Yes"
                        sx={{ color: "#707070" }}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        sx={{ color: "#707070" }}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </ThemeProvider>
                  {formErrors.seriousReportableIncident &&
                    form.seriousReportableIncident === "Yes" && (
                      <FormHelperText error>
                        {formErrors.seriousReportableIncident}
                      </FormHelperText>
                    )}
                </Box>

                {form.seriousReportableIncident === "Yes" && (
                  <React.Fragment>
                    <Box sx={{ color: "#000" }}>If Yes, Choose Below</Box>

                    <Box>
                      {seriousReportableIncidentYes.map((language) => (
                        <Box>
                          <FormControlLabel
                            key={language.value}
                            control={
                              <Checkbox
                                name="seriousReportableIncidentYes"
                                className="custom-checkbox"
                                value={language.value}
                                checked={form.seriousReportableIncidentYes.includes(
                                  language.value
                                )}
                                onChange={handleChange}
                                onBlur={() =>
                                  validateField(
                                    "seriousReportableIncidentYes",
                                    form.seriousReportableIncidentYes
                                  )
                                }
                                InputProps={{
                                  readOnly: false,
                                }}
                              />
                            }
                            label={language.label}
                          />
                        </Box>
                      ))}
                      {formErrors.seriousReportableIncidentYes && (
                        <FormHelperText error>
                          {formErrors.seriousReportableIncidentYes}
                        </FormHelperText>
                      )}
                    </Box>
                  </React.Fragment>
                )}

                <Box sx={{ color: "#000" }}>Type of Reporting:</Box>

                <Box>
                  {typeOfReporting.map((language) => (
                    <Box>
                      <FormControlLabel
                        key={language.value}
                        control={
                          <Checkbox
                            name="typeOfReporting"
                            className="custom-checkbox"
                            value={language.value}
                            checked={form.typeOfReporting.includes(
                              language.value
                            )}
                            onChange={handleChange}
                            onBlur={() =>
                              validateField(
                                "typeOfReporting",
                                form.typeOfReporting
                              )
                            }
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        }
                        label={language.label}
                      />
                    </Box>
                  ))}
                  {formErrors.typeOfReporting && (
                    <FormHelperText error>
                      {formErrors.typeOfReporting}
                    </FormHelperText>
                  )}
                </Box>
                {form.typeOfReporting.includes("reportedByOther") && (
                  <TextField
                    name="otherTypeOfReporting"
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    label="Other type Of reporting"
                    value={form.otherTypeOfReporting}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "otherTypeOfReporting",
                        form.otherTypeOfReporting
                      )
                    }
                    InputProps={{
                      readOnly: false,
                    }}
                    error={!!formErrors.otherTypeOfReporting}
                    helperText={formErrors.otherTypeOfReporting}
                  />
                )}
                <Box sx={{ color: "#000" }}>
                  Incident Rating (Tick and Provide Number)
                </Box>

                <Box>
                  <ThemeProvider theme={theme1}>
                    <RadioGroup
                      aria-label="typeOfIncident"
                      name="incidentRating"
                      value={form.incidentRating}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Near Miss"
                        control={<Radio />}
                        label="Near Miss"
                      />
                      <FormControlLabel
                        value="Low"
                        control={<Radio />}
                        label="Low"
                      />
                      <FormControlLabel
                        value="Moderate"
                        control={<Radio />}
                        label="Moderate"
                      />
                      <FormControlLabel
                        value="Major"
                        control={<Radio />}
                        label="Major"
                      />
                      <FormControlLabel
                        value="Extreme"
                        control={<Radio />}
                        label="Extreme"
                      />
                    </RadioGroup>
                  </ThemeProvider>
                  {formErrors.incidentRating && (
                    <FormHelperText error>
                      {formErrors.incidentRating}
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  {(form.incidentRating === "Near Miss" ||
                    form.incidentRating === "Low" ||
                    form.incidentRating === "Moderate" ||
                    form.incidentRating === "Major" ||
                    form.incidentRating === "Extreme") && (
                    <TextField
                      name="incidentRatingTextField"
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      label="Enter a Number"
                      value={form.incidentRatingTextField}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField(
                          "incidentRatingTextField",
                          form.incidentRatingTextField
                        )
                      }
                      InputProps={{
                        readOnly: false,
                      }}
                      error={!!formErrors.incidentRatingTextField}
                      helperText={formErrors.incidentRatingTextField}
                    />
                  )}
                </Box>
                <Grid mt={2}>
                  <TableContainer style={{ width: 800 }}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#379592" }}>
                          <TableCell
                            align="center"
                            colSpan={7}
                            sx={{ color: "#FFFFFF" }}
                          >
                            Consequences
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{ backgroundColor: "#379592" }}
                          ></TableCell>

                          <TableCell></TableCell>

                          <TableCell>1 Insignificant</TableCell>

                          <TableCell>2 Minor</TableCell>

                          <TableCell>3 Moderate</TableCell>

                          <TableCell>4 Major</TableCell>

                          <TableCell>5 Catastropic</TableCell>
                        </TableRow>
                      </TableBody>

                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{ backgroundColor: "#379592" }}
                          ></TableCell>

                          <TableCell>1 Rare</TableCell>

                          <TableCell>Low(1)</TableCell>

                          <TableCell>Low(2)</TableCell>

                          <TableCell>Low(3)</TableCell>

                          <TableCell>Moderate(4)</TableCell>

                          <TableCell>Major(5)</TableCell>
                        </TableRow>
                      </TableBody>

                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              backgroundColor: "#379592",
                              color: "#FFFFFF",
                            }}
                          >
                            Likelihood
                          </TableCell>

                          <TableCell>2 Unlikely</TableCell>

                          <TableCell>Low(2)</TableCell>

                          <TableCell>Minor(4)</TableCell>

                          <TableCell>Minor(6)</TableCell>

                          <TableCell>Moderate(8)</TableCell>

                          <TableCell>Major(10)</TableCell>
                        </TableRow>
                      </TableBody>

                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{ backgroundColor: "#379592" }}
                          ></TableCell>

                          <TableCell> 3 Possible</TableCell>

                          <TableCell>Low(3)</TableCell>

                          <TableCell>Minor(6)</TableCell>

                          <TableCell>Moderate(9)</TableCell>

                          <TableCell>Major(12)</TableCell>

                          <TableCell>Extreme(15)</TableCell>
                        </TableRow>
                      </TableBody>

                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{ backgroundColor: "#379592" }}
                          ></TableCell>

                          <TableCell>4 Likely</TableCell>

                          <TableCell>Minor(4)</TableCell>

                          <TableCell>Moderate(8)</TableCell>

                          <TableCell>Major(12)</TableCell>

                          <TableCell>Extreme(16)</TableCell>

                          <TableCell>Extreme(20)</TableCell>
                        </TableRow>
                      </TableBody>

                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{ backgroundColor: "#379592" }}
                          ></TableCell>

                          <TableCell>5 Probable</TableCell>

                          <TableCell>Minor(5)</TableCell>

                          <TableCell>Moderate(10)</TableCell>

                          <TableCell>Major(15)</TableCell>

                          <TableCell>Extreme(20)</TableCell>

                          <TableCell>Extreme(25)</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {/* <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Participants involved in incident and witnesses:
                    <Select
                      multiple
                      value={selectedValues1}
                      onChange={handleChange1}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {participentList.map(({ childId, childName }, index) => (
                        <MenuItem value={childName}>{childName}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Stack> */}

                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Persons involved in incident and witnesses:
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      variant="outlined"
                      multiline
                      rows={2}
                      name="incidentandwitness"
                      value={form.incidentandwitness}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField(
                          "incidentandwitness",
                          form.incidentandwitness
                        )
                      }
                      sx={{ width: 900 }}
                    ></TextField>
                    {formErrors.incidentandwitness && (
                      <FormHelperText error>
                        {formErrors.incidentandwitness}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>

                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    What were the circumstances leading up to the incident:
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      variant="outlined"
                      multiline
                      rows={2}
                      name="circumstanceincident"
                      value={form.circumstanceincident}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField(
                          "circumstanceincident",
                          form.circumstanceincident
                        )
                      }
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 900 }}
                    ></TextField>
                    {formErrors.circumstanceincident && (
                      <FormHelperText error>
                        {formErrors.circumstanceincident}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>

                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Please provide a detailed description of the incident:
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      variant="outlined"
                      multiline
                      rows={2}
                      name="detailincidentdescription"
                      value={form.detailincidentdescription}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField(
                          "detailincidentdescription",
                          form.detailincidentdescription
                        )
                      }
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 900 }}
                    ></TextField>
                    {formErrors.detailincidentdescription && (
                      <FormHelperText error>
                        {formErrors.detailincidentdescription}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>

                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    What was the initial response:
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="initialresponse"
                      value={form.initialresponse}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField("initialresponse", form.initialresponse)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: false,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                    ></TextField>
                    {formErrors.initialresponse && (
                      <FormHelperText error>
                        {formErrors.initialresponse}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>
                <Box>First aid required:</Box>
                <Box>
                  <ThemeProvider theme={theme1}>
                    <RadioGroup
                      name="firstAid"
                      value={form.firstAid}
                      onChange={handleChange}
                      onBlur={() => validateField("firstAid", form.firstAid)}
                    >
                      <FormControlLabel
                        value="Yes"
                        // sx={{ color: "#707070" }}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        // sx={{ color: "#707070" }}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </ThemeProvider>
                  {formErrors.firstAid && (
                    <FormHelperText error>{formErrors.firstAid}</FormHelperText>
                  )}
                </Box>
                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    If yes, please describe first aid provided:
                    {form.firstAid === "Yes" && (
                      <TextField
                        name="firstAidProvided"
                        id="standard-basic"
                        size="small"
                        variant="outlined"
                        label=""
                        value={form.firstAidProvided}
                        onChange={handleChange}
                        onBlur={() =>
                          validateField(
                            "firstAidProvided",
                            form.firstAidProvided
                          )
                        }
                        InputProps={{
                          readOnly: false,
                        }}
                        error={!!formErrors.firstAidProvided}
                        helperText={formErrors.firstAidProvided}
                      />
                    )}
                  </Box>
                </Stack>
                <Box sx={{ color: "#000", fontWeight: 600, mt: "1rem" }}>
                  Support given to the witness(es):
                </Box>
                <Box sx={{ color: "#000", mt: "1rem" }}>
                  Management Informed
                </Box>

                <Box>
                  <ThemeProvider theme={theme1}>
                    <RadioGroup
                      name="managementInformed"
                      value={form.managementInformed}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </ThemeProvider>
                  {formErrors.managementInformed && (
                    <FormHelperText error>
                      {formErrors.managementInformed}
                    </FormHelperText>
                  )}
                </Box>

                <Box sx={{ color: "#000", mt: "1rem" }}>
                  Caregiver Informed:
                </Box>

                <Box>
                  <ThemeProvider theme={theme1}>
                    <RadioGroup
                      name="caregiverInformed"
                      value={form.caregiverInformed}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </ThemeProvider>
                  {formErrors.caregiverInformed && (
                    <FormHelperText error>
                      {formErrors.caregiverInformed}
                    </FormHelperText>
                  )}
                </Box>

                {form.caregiverInformed === "Yes" && (
                  <React.Fragment>
                    <Box sx={{ color: "#000" }}>
                      If Yes, Caregiver Informed:
                    </Box>

                    <Box>
                      {caregiverInformedYes.map((language) => (
                        <Box>
                          <FormControlLabel
                            key={language.value}
                            control={
                              <Checkbox
                                name="caregiverInformedYes"
                                className="custom-checkbox"
                                value={language.value}
                                checked={form.caregiverInformedYes.includes(
                                  language.value
                                )}
                                onChange={handleChange}
                                onBlur={() =>
                                  validateField(
                                    "caregiverInformedYes",
                                    form.caregiverInformedYes
                                  )
                                }
                                InputProps={{
                                  readOnly: false,
                                }}
                              />
                            }
                            label={language.label}
                          />
                        </Box>
                      ))}
                      {formErrors.caregiverInformedYes && (
                        <FormHelperText error>
                          {formErrors.caregiverInformedYes}
                        </FormHelperText>
                      )}
                    </Box>
                  </React.Fragment>
                )}

                <Box sx={{ color: "#000", mt: "1rem" }}>
                  {form.caregiverInformed === "Yes" &&
                    form.caregiverInformedYes.includes("Face to Face with") && (
                      <TextField
                        name="facetoface"
                        id="standard-basic"
                        size="small"
                        variant="outlined"
                        label=""
                        value={form.facetoface}
                        onChange={handleChange}
                        onBlur={() =>
                          validateField("facetoface", form.facetoface)
                        }
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    )}
                  {formErrors.facetoface && (
                    <FormHelperText error>
                      {formErrors.facetoface}
                    </FormHelperText>
                  )}
                </Box>

                {form.caregiverInformed === "No" && (
                  <React.Fragment>
                    <Box sx={{ color: "#000" }}>
                      If No, Plan to Caregiver Informed:
                    </Box>

                    <Box>
                      {caregiverInformedNo.map((language) => (
                        <Box>
                          <FormControlLabel
                            key={language.value}
                            control={
                              <Checkbox
                                name="caregiverInformedNo"
                                className="custom-checkbox"
                                value={language.value}
                                checked={form.caregiverInformedNo.includes(
                                  language.value
                                )}
                                onChange={handleChange}
                                onBlur={() =>
                                  validateField(
                                    "caregiverInformedNo",
                                    form.caregiverInformedNo
                                  )
                                }
                              />
                            }
                            label={language.label}
                          />
                        </Box>
                      ))}
                      {formErrors.caregiverInformedNo && (
                        <FormHelperText error>
                          {formErrors.caregiverInformedNo}
                        </FormHelperText>
                      )}
                    </Box>
                  </React.Fragment>
                )}

                <Box sx={{ color: "#000", mt: "1rem" }}>
                  {form.caregiverInformed === "No" &&
                    form.caregiverInformedNo.includes("Face to Face with") && (
                      <TextField
                        name="facetofaceno"
                        id="standard-basic"
                        size="small"
                        variant="outlined"
                        label=""
                        value={form.facetofaceno}
                        onChange={handleChange}
                        onBlur={() =>
                          validateField("facetofaceno", form.facetofaceno)
                        }
                        InputProps={{
                          readOnly: false,
                        }}
                      />
                    )}
                  {formErrors.facetofaceno && (
                    <FormHelperText error>
                      {formErrors.facetofaceno}
                    </FormHelperText>
                  )}
                </Box>

                <Box sx={{ color: "#000", fontWeight: 600, mt: "1rem" }}>
                  Resolution actions to be taken in regard to witness(es) and
                  others involved
                </Box>
                <Box sx={{ color: "#000", mt: "1rem" }}>
                  Resolution actions to be taken in regard to the person
                  involved{" "}
                </Box>

                <Box>
                  {regardingToPerson.map((language) => (
                    <Box>
                      <FormControlLabel
                        key={language.value}
                        control={
                          <Checkbox
                            name="regardingToPerson"
                            className="custom-checkbox"
                            value={language.value}
                            checked={form.regardingToPerson.includes(
                              language.value
                            )}
                            onChange={handleChange}
                            onBlur={() =>
                              validateField(
                                "regardingToPerson",
                                form.regardingToPerson
                              )
                            }
                            InputProps={{
                              readOnly: false,
                            }}
                          />
                        }
                        label={language.label}
                      />
                    </Box>
                  ))}
                  {formErrors.regardingToPerson && (
                    <FormHelperText error>
                      {formErrors.regardingToPerson}
                    </FormHelperText>
                  )}
                </Box>

                {form.regardingToPerson.includes("Other") && (
                  <TextField
                    name="regardingToPersonOther"
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    label=""
                    value={form.regardingToPersonOther}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "regardingToPersonOther",
                        form.regardingToPersonOther
                      )
                    }
                    InputProps={{
                      readOnly: false,
                    }}
                    error={!!formErrors.regardingToPersonOther}
                    helperText={formErrors.regardingToPersonOther}
                  />
                )}

                <Box sx={{ color: "#000", mt: "1rem" }}>
                  Resolution actions to be taken in regard to the witness(es):
                </Box>

                <Box>
                  {regardingToWitness.map((language) => (
                    <Box>
                      <FormControlLabel
                        key={language.value}
                        control={
                          <Checkbox
                            name="regardingToWitness"
                            className="custom-checkbox"
                            value={language.value}
                            checked={form.regardingToWitness.includes(
                              language.value
                            )}
                            onChange={handleChange}
                            onBlur={() =>
                              validateField(
                                "regardingToWitness",
                                form.regardingToWitness
                              )
                            }
                          />
                        }
                        label={language.label}
                      />
                    </Box>
                  ))}
                  {formErrors.regardingToWitness && (
                    <FormHelperText error>
                      {formErrors.regardingToWitness}
                    </FormHelperText>
                  )}
                </Box>

                {form.regardingToWitness.includes("Other") && (
                  <TextField
                    name="regardingToWitnessOther"
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    label=""
                    value={form.regardingToWitnessOther}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField(
                        "regardingToWitnessOther",
                        form.regardingToWitnessOther
                      )
                    }
                    InputProps={{
                      readOnly: false,
                    }}
                    error={!!formErrors.regardingToWitnessOther}
                    helperText={formErrors.regardingToWitnessOther}
                  />
                )}

                <Box sx={{ color: "#000", mt: "1rem" }}>
                  Has a risk assessment note been completed:
                </Box>

                <Box>
                  <ThemeProvider theme={theme1}>
                    <RadioGroup
                      name="riskAssessment"
                      value={form.riskAssessment}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField("riskAssessment", form.riskAssessment)
                      }
                    >
                      <FormControlLabel
                        value="Yes"
                        sx={{ color: "#707070" }}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="In progress"
                        sx={{ color: "#707070" }}
                        control={<Radio />}
                        label="In progress"
                      />
                      <FormControlLabel
                        value="No"
                        sx={{ color: "#707070" }}
                        control={<Radio />}
                        label="No, Please Provide Reason"
                      />
                    </RadioGroup>
                  </ThemeProvider>
                  {formErrors.riskAssessment && (
                    <FormHelperText error>
                      {formErrors.riskAssessment}
                    </FormHelperText>
                  )}
                </Box>
                {form.riskAssessment === "No" && (
                  <TextField
                    name="riskAssessmentNo"
                    id="standard-basic"
                    size="small"
                    variant="outlined"
                    label=""
                    value={form.riskAssessmentNo}
                    onChange={handleChange}
                    onBlur={() =>
                      validateField("riskAssessmentNo", form.riskAssessmentNo)
                    }
                    error={!!formErrors.riskAssessmentNo}
                    helperText={formErrors.riskAssessmentNo}
                  />
                )}
                <Box mt="2rem">
                  <TableContainer component={Paper}>
                    <Table
                      //   sx={{ minWidth: 500 }}
                      aria-label="customized table"
                      my="1rem"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            Outlet
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Journalist
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Engagement
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.rowId}>
                            <TableCell>
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                name={`outlet${row.rowId}`}
                                value={form[`outlet${row.rowId}`]}
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: false,
                                }}
                              />
                              {tableErros[`outlet${row.rowId}`] && (
                                <FormHelperText error>
                                  {tableErros[`outlet${row.rowId}`]}
                                </FormHelperText>
                              )}
                            </TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                name={`journalist${row.rowId}`}
                                value={form[`journalist${row.rowId}`]}
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: false,
                                }}
                              />
                              {tableErros[`journalist${row.rowId}`] && (
                                <FormHelperText error>
                                  {tableErros[`journalist${row.rowId}`]}
                                </FormHelperText>
                              )}
                            </TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                name={`engagement${row.rowId}`}
                                value={form[`engagement${row.rowId}`]}
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: false,
                                }}
                              />
                              {tableErros[`engagement${row.rowId}`] && (
                                <FormHelperText error>
                                  {tableErros[`engagement${row.rowId}`]}
                                </FormHelperText>
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                variant="contained"
                                sx={{
                                  bgcolor: "#379592",
                                  color: "white",
                                  textTransform: "none",
                                  "&:hover": {
                                    background: "#379592",
                                    color: "white",
                                  },
                                  mx: "0.5rem",
                                }}
                                InputProps={{
                                  readOnly: false,
                                }}
                                onClick={() => handleDeleteRow(row.rowId)}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <tfoot>
                        <TableRow>
                          <TableCell colSpan={12} align="center">
                            <Button
                              variant="contained"
                              sx={{
                                bgcolor: "#379592",
                                color: "white",
                                textTransform: "none",
                                "&:hover": {
                                  background: "#379592",
                                  color: "white",
                                },
                                mx: "0.5rem",
                              }}
                              onClick={handleAddRow}
                            >
                              Add
                            </Button>
                          </TableCell>
                        </TableRow>
                      </tfoot>
                    </Table>
                  </TableContainer>
                </Box>

                {isTableErrors && (
                  <FormHelperText error>
                    Please Enter the table fields
                  </FormHelperText>
                )}
                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Name of staff member writing report:
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="staffSignature"
                      value={form.staffSignature}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField("staffSignature", form.staffSignature)
                      }
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                    ></TextField>
                    {formErrors.staffSignature && (
                      <FormHelperText error>
                        {formErrors.staffSignature}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>
                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Name and designation of staff member closed off report:
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="namedesignationandsignature"
                      value={form.namedesignationandsignature}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField(
                          "namedesignationandsignature",
                          form.namedesignationandsignature
                        )
                      }
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                    ></TextField>
                    <TextField
                      id="standard-basic"
                      size="small"
                      variant="outlined"
                      name="namedesignationandsignature1"
                      value={form.namedesignationandsignature1}
                      onChange={handleChange}
                      onBlur={() =>
                        validateField(
                          "namedesignationandsignature1",
                          form.namedesignationandsignature1
                        )
                      }
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                    ></TextField>
                    {formErrors.namedesignationandsignature && (
                      <FormHelperText error>
                        {formErrors.namedesignationandsignature}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>

                <Stack direction="row" spacing={12} sx={{ mt: "0.75rem" }}>
                  <Box sx={{ color: "#000" }}>
                    Date Incident Closed:
                    <TextField
                      id="standard-basic"
                      size="small"
                      type="date"
                      format="YYYY-MM-DD"
                      variant="outlined"
                      name="dateIncidentClosed"
                      value={form.dateIncidentClosed}
                      onChange={handleChange}
                      // onChange={(e) => doSomething(e)}
                      onBlur={() =>
                        validateField(
                          "dateIncidentClosed",
                          form.dateIncidentClosed
                        )
                      }
                      InputProps={{
                        readOnly: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ ml: "1rem", color: "#000000" }}
                      inputProps={{ max: new Date().toISOString().split("T")[0] }}
                    ></TextField>
                    {formErrors.dateIncidentClosed && (
                      <FormHelperText error>
                        {formErrors.dateIncidentClosed}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>

                <Grid
                  sx={{
                    mt: "1rem",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Link
                    to={`/viewIncidentReport/${incidentReportId}/${campId}`}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: "#909090",
                        color: "white",
                        textTransform: "none",
                        "&:hover": { background: "#909090", color: "white" },
                        mx: "0.5rem",
                      }}
                      className="gilroy"
                    >
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#379592",
                      color: "white",
                      textTransform: "none",
                      "&:hover": { background: "#379592", color: "white" },
                      mx: "0.5rem",
                      width: "87px",
                    }}
                    className="gilroy"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      bgcolor: "#379592",
                      color: "white",
                      textTransform: "none",
                      "&:hover": { background: "#379592", color: "white" },
                      mx: "0.5rem",
                    }}
                    className="gilroy"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </ThemeProvider>
  );
}
