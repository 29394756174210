import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import image from "./Assets/Logo-Main.png";
import Image from "./Assets/candcimg1.png";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Grid, Stack } from "@mui/material";
import "../App.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { apiService, apiService2 } from "../Services/apiService";
import { useHistory } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";
import { Link, useParams } from "react-router-dom";
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png";
import Circle from "../Assets/Icons/Icons/Circle.png"
import SelectedCircle from "../Assets/Icons/Icons/SelectedCircle.png"
import {
    SERVER_URL,
    GET_PROFILE
  } from "../Services/apiEndpints";
  import CircularProgress from '@mui/material/CircularProgress';

const formFields = {
    firstName: "",
    lastName: "",
    dateofBirth: "",
    gender: "",
    address: "",
    email: "",
    mobileNumber: "",
    password: "",
    userRole: "",
};
let rolesName = localStorage.getItem('rolesName')


// let rolesName =localStorage.getItem("rolesName").charAt(0).toUpperCase() + localStorage.getItem("rolesName").slice(1).toLowerCase()



export default function UserProfile1() {
    const history = useHistory();
    const current = new Date();
    const [showPassword, setShowPassword] = useState(false);
    const [profileData, setProfileData] = useState([]);
    const [today, setToday] = React.useState(null);
    const [imageLoader,setImageLoader] = React.useState(false)
    let { id } = useParams()

console.log(id)
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    React.useEffect(() => {
        (
            async () => {
                // setLoading(true)
                setImageLoader(true)
                let dataSent = {
                    // userId: localStorage.getItem('userId'),
                    userId: id,
                    email: localStorage.getItem("staffEmail"),
                }

                let apiInfo = {
                    // url: "https://cncdevapi.natsoft.io/api/auth/getprofile",
                    url:`${SERVER_URL}${GET_PROFILE}`,
                    method: "POST",
                    postData: dataSent,
                    headers: {
                        "Content-Type": "application/json",
                        tokenheaderkey: localStorage.getItem("token"),
                    },

                }

                let output = await apiService2(apiInfo)
                if (output.data.responseCode === 0) {
                    let groupList = output.data.responseData[0][0]
                    console.log('list', groupList)
                    setProfileData(groupList)
                    setImageLoader(false)
                }
            }
        )()

    }

        , [])

    const theme = createTheme({
        typography: {
            fontFamily: "gilroyMedium",
        },
        // overrides: {
        //   MuiRadioGroup: {
        //     root: {
        //       color: 'green',
        //     },
        //     colorSecondary: {
        //       '&$checked': {
        //         color: 'green',
        //       },
        //     },
        //   },
        // },  // MuiFormLabel-root-MuiInputLabel-root

        components: {
            MuiInputLabel: {
                styleOverrides: {
                    asterisk: {
                        color: "red",
                        fontSize: 24
                    }
                }
            },
            MuiCssBaseline: {
                styleOverrides: {
                    "@font-face": {
                        fontFamily: "gilroyMedium",
                        src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
                    },
                },
            },
        },
    });

    return (

        <ThemeProvider theme={theme}>
            <CssBaseline />
            {/* <Box> */}
            <Grid
                container
                direction="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: "100vh",
                    backgroundImage: `url(${Image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    // overflowY:'hidden'
                }}
            >
                 {imageLoader === true 
                                ? (<Grid container justifyContent='center' alignItems='center' ><CircularProgress /></Grid >) 
                                : (
                                    <Grid item>
                    <Card sx={{ borderRadius: "20px", mx: '3rem' }}>
                        <CardContent
                            sx={{
                                mx: "1.5rem",
                                maxHeight: "800px",
                                overflow: "auto",
                                overflowX: "hidden",
                            }}

                        >
                            <Box
                                component="form"
                                xs={4}
                                md={4}
                                sm={6}
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container >
                                    <Grid item md={4}>
                                        <Box sx={{ alignItems: "center" }}>
                                            <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'   >
                                                <Link className='/link2' to='/ActivateStaff'>
                                                    <Box
                                                        // mt="0.65rem"
                                                        alignSelf="center"
                                                        component="img"
                                                        sx={{
                                                            height: "0.75rem",
                                                            width: "0.65rem",
                                                            cursor: "pointer",
                                                        }}
                                                        height="20"
                                                        alt="Logo"
                                                        src={ArrowBack}
                                                    ></Box>
                                                </Link>
                                                <Box sx={{ display: "flex " }} >
                                                    <CardMedia
                                                        component="img"
                                                        height="50%"
                                                        image={image}
                                                        alt="Logo"
                                                        sx={{ width: "50%"}}
                                                    />
                                                </Box>
                                            </Stack>

                                        </Box>
                                        <Grid item sx={{ width: "80%", textAlign: 'center' }}>
                                            {profileData && profileData.profileimage ? (
                                                <Box
                                                mt='1rem'
                                                component="img"
                                                sx={{ height: "100px", width: "100px", borderRadius: '50px'}}
                                                alt="Logo"
                                                src={profileData && profileData.profileimage}

                                            ></Box>
                                            ) :
                                             (
                                                <Box my='0.5rem' textAlign='center' 
                                                sx={{
                                                    height: "70px",
                                                    width: "70px",
                                                    borderRadius: '50px',
                                                    
                                                    backgroundColor: '#379592',
                                                    color: '#fff',
                                                   paddingTop:'1rem',
                                                    fontSize:'20px'
                                                }}
                                                clasaName='bold'
                                            >{profileData && profileData.profileName}</Box>
                                             )}
                                            
                                            
                                        </Grid>

                                        <Grid item>
                                            {/* {console.log(profileData && profileData.firstName)} */}
                                            <TextField
                                                id="standard-basic"
                                                label="First Name"
                                                variant="standard"
                                                name="firstName"
                                                sx={{ width: "80%" }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={profileData && profileData.firstName}
                                            // value={'noting'}
                                            />

                                        </Grid>
                                        <Grid item mt={1}>
                                            <TextField
                                                id="standard-basic"
                                                label="Last Name"
                                                variant="standard"
                                                name="Lastname"
                                                sx={{ width: "80%" }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={profileData && profileData.Lastname}


                                            />


                                        </Grid>
                                        <Grid item mt={1}>
                                            <TextField
                                                id="standard-basic"
                                                label="Email"
                                                variant="standard"
                                                name="email"
                                                sx={{ width: "80%" }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={profileData && profileData.email}
                                            />


                                        </Grid>
                                        <Grid item mt={1}>
                                            <TextField
                                                id="standard-basic"
                                                label="Role"
                                                variant="standard"
                                                name="rolesName"
                                                // sx={{ width: "80%" }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}

                                                sx={{ width: "80%", }}
                                                value={profileData && profileData.rolesName}
                                            />

                                        </Grid>
                                    </Grid>
                                    <Grid md={8}>
                                        <Grid sx={{  width: "90%",textAlign:'center',my:'0.5rem'}} className='bold'>Profile</Grid>

                                        <Grid container spacing={2}>
                                            <Grid item md={6} my='0.5rem'>
                                                <Grid item>

                                                    <TextField
                                                        id="standard-basic"
                                                        label="Profession"
                                                        variant="standard"
                                                        name="title"
                                                        sx={{ width: "80%" }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={profileData && profileData.title}
                                                    />

                                                </Grid>
                                                <Grid item my='0.5rem'>
                                                    <TextField
                                                        id="standard-basic"
                                                        label="Mobile"
                                                        variant="standard"
                                                        name="contactNo"
                                                        sx={{ width: "80%" }}
                                                        InputProps={{
                                                            readOnly: true,
                                                            startAdornment: (
                                                                <InputAdornment position="start">+61</InputAdornment>
                                                              ),
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        // InputProps={{
                                                        //     startAdornment: (
                                                        //       <InputAdornment position="start">$</InputAdornment>
                                                        //     ),
                                                        //   }}
                                                        value={profileData && profileData.contactNo}
                                                    />
                                                </Grid>
                                                <Grid item my='0.5rem'  >
                                                    <TextField
                                                        id="standard-basic"
                                                        label="Address"
                                                        variant="standard"
                                                        name="Address"
                                                        sx={{ width: "80%" }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={profileData && profileData.Address}
                                                    />

                                                </Grid>
                                                {/* <Grid item my='0.5rem'>
                                                    <TextField
                                                        id="standard-basic"
                                                        label="New Password"
                                                        variant="standard"
                                                        name="New Password"
                                                        sx={{ width: "80%" }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    // value={profileData && profileData.allergyInfo}
                                                    />
                                                    <Typography
                                                        color="#000000"
                                                        // ml="2rem"
                                                        sx={{ fontSize: "12px", textAlign: "left" }}
                                                    >
                                                        Must be at least 8 characters 1 uppercase, 1 lowercase and 1 special character.
                                                    </Typography>
                                                </Grid> */}
                                                <Grid item my='0.5rem'>
                                                    <Box my='1rem'>Allergy Information</Box>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        // label="Address"
                                                        // variant="standard"
                                                        // name="Address"
                                                        multiline
                                                        rows={3}
                                                        sx={{ width: "80%" }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={profileData && profileData.allergyInfo}
                                                    />

                                                </Grid>
                                            </Grid>
                                            <Grid item md={6} my='0.5rem'>
                                                <Grid item>

                                                    <TextField
                                                        id="standard-basic"
                                                        label="DOB"
                                                        variant="standard"
                                                        name="DOB"
                                                        sx={{ width: "80%" }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={profileData && profileData.DOB}
                                                    />

                                                </Grid>
                                                <Grid item my='0.5rem'>
                                                    <TextField
                                                        id="standard-basic"
                                                        label="Shirt Size"
                                                        variant="standard"
                                                        name="shirtSize"
                                                        sx={{ width: "80%" }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={profileData && profileData.shirtSize}
                                                    />
                                                </Grid>
                                                <Grid item my='0.5rem'  >
                                                    {/* <TextField
                                                        id="standard-basic"
                                                        label="Gender"
                                                        variant="standard"
                                                        name="Gender"
                                                        sx={{ width: "80%" }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={profileData && profileData.Gender}
                                                    /> */}
                                                    <Box sx={{ color: '#707070' }}>Gender</Box>
                                                    <Stack direction='row' spacing={1} my='0.25rem'>

                                                        <Stack direction='row' spacing={1} alignItems='center' >
                                                            <Box
                                                                component="img"
                                                                sx={{ height: "10px", width: "10px", }}
                                                                alt="Logo"
                                                                src={profileData && profileData.Gender === 'Male' ? SelectedCircle : Circle}

                                                            ></Box>
                                                            <Box sx={{ fontSize: '12px' }}>Male</Box>
                                                        </Stack>
                                                        <Stack direction='row' spacing={1} alignItems='center'>
                                                            <Box
                                                                component="img"
                                                                sx={{ height: "10px", width: "10px", }}
                                                                alt="Logo"
                                                                src={profileData && profileData.Gender === 'Female' ? SelectedCircle : Circle}

                                                            ></Box>
                                                            <Box sx={{ fontSize: '12px' }}>Female</Box>
                                                        </Stack>
                                                        <Stack direction='row' spacing={1} alignItems='center'>
                                                            <Box
                                                                component="img"
                                                                sx={{ height: "10px", width: "10px", }}
                                                                alt="Logo"
                                                                src={profileData && profileData.Gender === 'Other' ? SelectedCircle : Circle}

                                                            ></Box>
                                                            <Box sx={{ fontSize: '12px' }}>Prefer not to say</Box>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item my='0.5rem'>
                                                    <TextField
                                                        id="standard-basic"
                                                        label="Confirm Password"
                                                        variant="standard"
                                                        name="Confirm Password"
                                                        sx={{ width: "80%" }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    // value={profileData && profileData.Gender}
                                                    />
                                                    <Typography
                                                        color="#000000"
                                                        // ml="2rem"
                                                        sx={{ fontSize: "12px", textAlign: "left" }}
                                                    >
                                                        Confirm Password should match the new password.
                                                    </Typography>
                                                </Grid> */}
                                                <Grid item mt='1rem'>
                                                    <Box>Dietary Information</Box>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        // label="dietaryInfo"
                                                        // variant="standard"
                                                        // name="Address"
                                                        multiline
                                                        rows={3}
                                                        sx={{ width: "80%", mt: '1rem' }}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={profileData && profileData.dietaryInfo}
                                                    />

                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        <Grid item >
                                            <Link to={`/UserProfile1/${id}`}>
                                                <Button
                                                    variant="contained"

                                                    sx={{
                                                        px: "1rem",
                                                        width: '90%',
                                                        bgcolor: "#379592",
                                                        textTransform: "none",
                                                        "&:hover": { background: "#379592" },
                                                    }}
                                                // onClick={() => onSubmit()}
                                                // onClick={handleSubmit(onSubmit)}
                                                >
                                                    Next
                                                </Button>
                                            </Link>

                                        </Grid>
                                        {/* <Grid mt={2}>Secondary Contact</Grid>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <Grid item>
                          <TextField
                            id="standard-basic"
                            label="Name"
                            variant="standard"
                            name="name"
                            sx={{ width: "80%" }}
                            // {...register("name")}
                          />
                          <Typography
                            color="error.main"
                            ml="2rem"
                            sx={{ fontSize: "12px", textAlign: "left" }}
                          ></Typography>
                        </Grid>
                        <Grid item mt={1}>
                          <TextField
                            id="standard-basic"
                            label="Address"
                            variant="standard"
                            name="address"
                            sx={{ width: "80%" }}
                            // {...register("address")}
                          />
                          <Typography
                            color="error.main"
                            ml="2rem"
                            sx={{ fontSize: "12px", textAlign: "left" }}
                          ></Typography>
                        </Grid>
                      </Grid>
                      <Grid item md={6}>
                        <Grid item>
                          <TextField
                            id="standard-read-only-input"
                            label=" "
                            defaultValue="+ 61"
                            variant="standard"
                            sx={{
                              width: "30px",
                              ".MuiInputBase-input.MuiInput-input": {
                                color: "#757575",
                              },
                            }}
                          />
                          <TextField
                            id="standard-basic"
                            label="Mobile"
                            variant="standard"
                            name="mobileNumber"
                            // {...register("contactNo")}
                            sx={{ width: "70%" }}
                          />

                          <Typography
                            color="error.main"
                            ml="2rem"
                            sx={{ fontSize: "12px", textAlign: "left" }}
                          ></Typography>
                        </Grid>

                        <Grid item mt={1}>
                          <TextField
                            id="standard-basic"
                            label="Email"
                            variant="standard"
                            name="email"
                            sx={{ width: "80%" }}
                            // {...register("email")}
                          />
                          <Typography
                            color="error.main"
                            ml="2rem"
                            sx={{ fontSize: "12px", textAlign: "left" }}
                          ></Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          px: "1rem",
                          py: "0.5rem",
                          bgcolor: "#379592",
                          textTransform: "none",
                          "&:hover": { background: "#379592" },
                        }}
                        // onClick={() => onSubmit()}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Submit
                      </Button>
                    </Grid> */}
                                    </Grid>
                                </Grid>
                                
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                                )}
                
                

            </Grid>

        </ThemeProvider>
    );
}


