import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
    CardActionArea,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,

    Chip,
    CardContent,
    Divider,
    Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import DefaultImage from "../../Assets/Icons/Icons/DefaultImage.png"
import { apiService, apiService2 } from "../../Services/apiService";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png"
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png"
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";
import PdfIcon from "../../Assets/Icons/Icons/PdfIcon.png"
import { VIEW_WELCOMEPACK, SERVER_URL } from '../../Services/apiEndpints'

const rowCount = [1, 2, 3, 4]

export default function SupportStaffWelcomePackRepublish() {
    const history = useHistory()
    const [campList, setCampList] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [welcomePackData, setWelcomePackData] = React.useState('')
    const [files, setFiles] = React.useState('')
    let { campId } = useParams()

    React.useEffect(
        async () => {
            setLoading(true)
            let inputData = {
                userId: localStorage.getItem("userId"),
                campId: campId,
                // campId:5198,
                welcomePackType: 'SUPPORT_STAFF'

            };
            let apiInfo = {
                // url: "https://cncdevapi.natsoft.io/api/actcamp/viewwelcomepack",
                url: `${SERVER_URL}${VIEW_WELCOMEPACK}`,
                method: "POST",
                postData: inputData,
                headers: {
                    "Content-Type": "application/json",
                    tokenheaderkey: localStorage.getItem("token"),
                },
            }
            let output = await apiService2(apiInfo)
            console.log(output)
            if (output !== false) {
                let viewOp = output.data.responseData[0][0]
                if (viewOp.videoInfo) {
                    viewOp.videoInfo = JSON.parse(viewOp.videoInfo)
            
                  }
                setWelcomePackData(viewOp)
                console.log(viewOp)
                setFiles(viewOp.filesInfo)
            }


            //   setViewData(viewOp)
            //   setRows(viewOp.groupInfo)
            //   setFlist(viewOp.fileList && viewOp.fileList)

        }


        , [])
    console.log(files)
    let videoInfo = [
        {
            id: 1,
            videoType:"PRE_CAMP",
            actualUrl: "https://vimeo.com/364844164",
            description: "Hongkong",
            thumbnailurl: "https://i.vimeocdn.com/video/820384336-71aa057ba8bfc1bdc250b7547c851336e09cb92d867612372d6ad435854ad35d-d_295x166",
            videoProvider:"vimeo"
    
        },
        {
            id: 2,
            videoType:"PRE_CAMP",
            actualUrl: "https://vimeo.com/358604771",
            description: "Flowers",
            thumbnailurl: "https://i.vimeocdn.com/video/812282791-c599c3322164449bad3862cb4b8b8ee969d91482ca2b74e71afa02f683bd43fb-d_295x166",
            videoProvider:"vimeo"
        }
    ]
    const SaveWelcomePack = () => {

        Swal.fire({
            text: "Do you want to republish the welcome pack?",
            iconHtml: `<img src="${question}" width="100" height="100" >`,
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#379592",
            cancelButtonText: "No",
            cancelButtonColor: "#909090",
            customClass: {
                container: "my-swal",
                icon: 'no-border'

            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    iconHtml: `<img src="${GreenCheck}" width="100" height="100" >`,
                    text: 'Welcome Pack Republished',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#379592",
                    customClass: {
                        container: "my-swal",
                    },
                }).then(() => {
                    history.push('/SupportStaffWelcomePackPublish')
                })
            }

        }

        )
    }

    const theme = createTheme({
        typography: {
            fontFamily: "gilroyMedium",
        },
        palette: {
            secondary: {
                main: "#379592",
            },
        },

        Muidisabled: {
            styleOverrides: {
                backgroundColor: "purple",
            },
        },
    });
    const openVideo = (path) => {
        // const videoUrl = "https://cncdevapi.natsoft.io/download/126c716a-012b-4e1e-8ddf-cdaad783ebb6.mp4";
        const videoUrl = path
        const videoWindow = window.open("", "_blank");
        videoWindow.document.write('<video src="' + videoUrl + '" autoplay controls></video>');
        videoWindow.document.close();
    }
    return (
        <Box>
            <Grid container direction='row' >
                <Grid item md={6} sm={12} xs={12}>
                    <Stack direction='row' spacing={1}>
                    <Link to={`/SupportStaffApplicants/${campId}`}>
                            <Box
                                component="img"
                                sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
                                height="20"
                                alt="Logo"
                                src={ArrowBack}
                            ></Box>
                        </Link>
                        <Box className="gilroy" alignSelf="center">
                            View Welcome Pack for Support Staff
                        </Box>
                    </Stack>
                    <Box my='1rem'>
                        <TextField
                            id="outlined-multiline-static"
                            label="Pre-information"
                            multiline
                            rows={4}
                            name="Pre-information"
                            sx={{ width: '100%' }}
                            InputProps={{
                                readOnly: true,
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={welcomePackData.PreInformation}

                        />

                    </Box>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <Grid container justifyContent='flex-end' alignItems='flex-start' >
                        <Stack display='column' justifyContent='flex-start' >
                            {/* <Button
                                variant="contained"
                                type="submit"
                                sx={{
                                    mx: "1rem",
                                    bgcolor: "#4C9F70",
                                    textTransform: "none",
                                    "&:hover": { background: "#4C9F70" },
                                    width: '120px',

                                }}
                                className="gilroy"
                                onClick={() => SaveWelcomePack()}
                            >
                                Publish
                            </Button> */}
                            {/* <Box className='gilroy' sx={{ fontSize: '10px', maxWidth: '120px' }} ml="1rem" my='0.5rem'>
                                Welcome Pack last edited on {welcomePackData && welcomePackData.createdDate} by {welcomePackData && welcomePackData.createdBy}
                            </Box> */}
                        </Stack>
                        <Link to={`/SupportStaffWelcomePackEdit/${campId}`} className='link2'>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{
                                    mx: "1rem",
                                    bgcolor: "#4C9F70",
                                    textTransform: "none",
                                    width: '120px',
                                    "&:hover": { background: "#4C9F70" },

                                }}
                                className="gilroy"
                            >
                                Edit
                            </Button>
                        </Link>

                    </Grid>

                </Grid>


            </Grid>

            <Box py='1.5rem'><Divider /></Box>
            {/* <Box my='1rem'>
                <Box>
                    Welcome Video
                </Box>
            </Box>
            <Grid container display='flex'>
                {files && files.filter((ele) => ele.documentContent === 'WELCOME_VIDEO').map((item, index) => (
                    
                    <Grid container display='flex' key={index}>
                        <Grid item md={4}>
                            <Box >

                                <Box onClick={() => openVideo(item.documentPath)} sx={{ cursor: 'pointer' }}>
                                    <video style={{ width: "50%", height: "50%" }}>
                                        <source src={item.documentPath} type="video/mp4" />
                                        <source src={item.documentPath} type="video/ogg" />
                                    </video>
                                </Box>
                                
                            </Box>
                        </Grid>

                    </Grid>
                ))}
            </Grid> */}
            <Box >Welcome Videos</Box>
            {welcomePackData.videoInfo && welcomePackData.videoInfo.map((item, index) => {
                return (
                    <span className="thumbnail-span" key={index}>
                        {/* <span  > */}
                        {item.actualUrl != null ? (
                            <Box my='1rem'>
                                <Grid container display='row' alignItems='center' >
                                    <Grid item md={4}>

                                        <Box sx={{ cursor: 'pointer', }}>
                                            <a href={item.actualUrl} target='_blank'>
                                                <img
                                                    src={item.thumbnailurl}

                                                    style={{ height: "80%", width: '80%', borderRadius: '10px' }}
                                                />
                                            </a>
                                        </Box>
                                    </Grid>

                                    <Grid item md={8} >
                                        <Box sx={{ width: '100%' }} >
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Description"
                                                multiline
                                                rows={5}
                                                name="description"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                  }}
                                                value={item.description}
                                                // onChange={(event) => handleComboDescription(event, index)}
                                                sx={{ width: '100%', borderRadius: '20px' }}
                                            // defaultValue="Default Value"
                                            />
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>


                        ) : (
                            ""
                        )}


                    </span>

                )
            })}
            <Box py='1.5rem'><Divider /></Box>

            <Grid container display='row' justifyContent='space-between'>
                <Box mt='1rem'>Photos</Box>

            </Grid>
            {files && files.filter((ele) => ele.documentContent !== 'WELCOME_VIDEO' && ele.documentContent !== 'FILE').map((item, index) => {
                let x = (item.documentPath).search('mp4') || (item.documentPath).search('ogg')
                console.log(x, 'hereeeeeee')
                return (
                    <Grid container display='flex' sx={{ my: '1rem' }} key={index}>
                        <Grid item md={4}>
                            {x !== -1 ?

                                (
                                    <Box onClick={() => openVideo(item.documentPath)} sx={{ cursor: 'pointer', }}>
                                        <video  style={{ width: "80%", height: "140px", borderRadius: '10px',alignSelf:'center'}}>
                                            <source src={item.documentPath} type="video/mp4" />
                                            <source src={item.documentPath} type="video/ogg" />
                                        </video>
                                    </Box>)
                                :
                                (
                                    <Box
                                        component="img"
                                        sx={{ width: "80%", height: "140px", borderRadius: '10px',alignSelf:'center' }}
                                        alt="image"
                                        src={item.documentPath}
                                    ></Box>)
                            }
                        </Grid>
                        <Grid item md={8}>
                            <Box sx={{ width: '100%' }}>
                                <TextField sx={{ borderColor: '#707070', width: '100%' }}
                                    id="outlined-multiline-static"
                                    label="Description"
                                    multiline
                                    rows={5}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                      }}
                                    value={item.documentContent}
                                />
                            </Box>
                        </Grid>


                    </Grid>
                )

            })}
            <Box py='1.5rem'><Divider /></Box>
            <Box my='1rem'>
                <Box>
                    Documents
                </Box>


            </Box>

            <Grid container>
                {files && files.filter((ele) => ele.documentContent === 'FILE').map((item, index) => (
                    //     <Grid item key={index}>
                    //     <a href={item.documentPath}>{item.documentTitle}</a>
                    // </Grid>
                    <Grid container display='flex' my='0.5rem' key={index} alignItems='center'>
                        <Box
                            component="img"
                            sx={{ height: "70%", mx: '0.5rem' }}
                            alt="Logo"
                            src={PdfIcon}
                        ></Box>
                        <a href={item.documentPath} target="_blank">
                            {item.documentTitle}
                        </a>

                    </Grid>
                ))}

            </Grid>

            {/* <Grid container justifyContent='flex-end'>
                <Link to='/WelcomePackRepublish' className='link2'>
                    <Button variant="contained"
                        sx={{
                            px: '2rem',
                            mx: '1rem',
                            bgcolor: "#909090",
                            "&:hover": { background: "#909090" },
                            textTransform: "none",
                        }} className='gilroy'>Cancel</Button>
                </Link>

            </Grid> */}

        </Box>

    );
}


