import React, { useState } from "react";
import Button from "@mui/material/Button";

import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import { apiService, apiService2 } from "../../Services/apiService";
import moment from "moment";
import { Link,  useHistory } from "react-router-dom";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { GETCAMP_APPLICANTSCOMMENTS ,GETSTAFF_CAMPAPPLICANTS, SERVER_URL,REVIEWSTAFF_APPLICATION} from '../../Services/apiEndpints'
import Swal from "sweetalert2";
import GreenCheck2 from "../../Assets/Icons/Icons/GreenCheck2.png";

const suitabilityStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  px: 4,
  pt: 2,
  pb:2
};

export default function ChangeFunding() {
  const [isConditionMet, setIsConditionMet] = useState(true);
  const [supportStaffInvoiceOpen, setSupportStaffInvoiceOpen] =
    React.useState(false);
  const [fundingStatusOptions, setFundingStatusOptions] = React.useState("");
  const [fundingRemarks, setFundingRemarks] = useState("");
  // const supportStaffInvoiceHandlOpen = () => setSupportStaffInvoiceOpen(true);
  const supportStaffInvoiceHandleClose = () => {
    setFundingRemarks('')
    setSupportStaffInvoiceOpen(false);
  }
  
  const [fundingComments, setFundingComments] = React.useState("");
  const [campApplicantFundingList, setCampApplicantFundingList] =
    React.useState([]);
  let { campId, staffApplicationId, staffId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [invoiceStat, setInvoiceStat] = React.useState(false);
  console.log("campId999", campId);
  let history = useHistory();
  const listArray = [
    { title: "Sent By", name: "Thomas Micheal" },
    { title: "Sent Date", name: "23 03 2023" },
    { title: "Paid By", name: "Rosy" },
    { title: "Paid Date", name: "25 03 2023" },
  ];



  const SupportStaffinvoiceHandleOpen = async () => {
    setSupportStaffInvoiceOpen(true);

    let dataSent = {
      userId: localStorage.getItem("userId"),
      // campId: campId,
      applicationId: staffApplicationId,
    };

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/camps/getcampapplicantscomments",
      url: `${SERVER_URL}${GETCAMP_APPLICANTSCOMMENTS}`,
      method: "POST",
      postData: dataSent,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    };
    let output = await apiService2(apiInfo);
    let commentList = output.data.responseData[0][0].comments;
    setLoading(false);
    commentList &&
      commentList.map((item) => {
        item.Funding && setFundingComments(item.Funding);
      });
  };

  const handleFundingChange = (event) => {
    setFundingStatusOptions(event.target.value);
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      let gettran = {
        userId: localStorage.getItem("userId"),
        campId: campId,
        staffApplicationId: staffApplicationId,
      };

      console.log("Campid", campId);
      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/staff/getstaffcampapplicants",
        url: `${SERVER_URL}${GETSTAFF_CAMPAPPLICANTS}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",

          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let login = await apiService(apiInfo);

      const campDataList = login.data.responseData[0];
      if(campDataList[0].invoiceInfo){
        campDataList[0].invoiceInfo = JSON.parse( campDataList[0].invoiceInfo)
      }
      setCampApplicantFundingList(campDataList);
      setLoading(false);
      localStorage.setItem("staffId", campDataList[0].staffId);
      localStorage.setItem(
        "invoiceStatusSupportStaff",
        campDataList[0].invoiceStatus
      );
      let inv = localStorage.getItem("invoiceStatusSupportStaff");
      console.log(inv, "hereeeeeeee");

      if (inv === "Submitted") {
        console.log("shouldddddddddd");
        setInvoiceStat(true);
      } else {
        console.log("notttttttt");
        setInvoiceStat(false);
      }
      campDataList.forEach((element) => {
        if (element.StaffList != null) {
          console.log(element.StaffList);
          element.StaffList = JSON.parse(element.StaffList);
          console.log(element.StaffList);
        }
      });
      console.log(campDataList);
      // console.log("campDataList",campDataList[0]);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, [isConditionMet]);

  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    palette: {
      secondary: {
        main: "#379592",
      },
    },

    Muidisabled: {
      styleOverrides: {
        backgroundColor: "purple",
      },
    },
  });

  const [age, setAge] = React.useState("");

  const onChangeFundingRemarks = (event) => {
    setFundingRemarks(event.target.value);
  };

  const handleSubmit = async () => {
    let payload = {
      staffApplicationId: staffApplicationId,
      staffId: staffId,
      campId: campId,
      fundingStatus: fundingStatusOptions,
      applicationRemarks: fundingRemarks,
      userId: localStorage.getItem("userId"),
    };

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/staff/reviewstaffappllication",
      url: `${SERVER_URL}${REVIEWSTAFF_APPLICATION}`,
      method: "POST",
      postData: payload,
      headers: {
        "Content-Type": "application/json",

        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let commentsdata = await apiService(apiInfo);
    console.log(commentsdata,'hereeeee')
    if (commentsdata?.data?.responseData[0][0].Code === "0") {

      Swal.fire({
        iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
        text: commentsdata?.data?.responseData[0][0].Desc,
        confirmButtonText: 'OK',
        confirmButtonColor: "#379592",
        customClass: {
          container: "my-swal",
         
        },
      }).then((result2) => {
        if (result2.value) {
          setSupportStaffInvoiceOpen(false);
          setFundingRemarks('')
          setIsConditionMet((prev) => !prev);
       

        }
      });
      
       

      
    }
  };

  const rows = [
    { sno: 1, name: "Rosy", status: "Application Submitted", action: "View" },
  ];

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      backgroundColor: "rgba(55, 149, 146, 1)",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Grid container direction="flex" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <Link className="link2" to={`/SupportStaffApplicants/${campId}`}>
              <Box
                alignSelf="center"
                component="img"
                sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>

            <Box alignSelf="center">
              {campApplicantFundingList &&
                campApplicantFundingList.length &&
                campApplicantFundingList[0].applicantName}{" "}
              Application Status
            </Box>
          </Stack>
        </Grid>
        <TableContainer component={Paper} sx={{ my: "2rem" }}>
          <Table sx={{ minWidth: 450 }} aria-label="customized table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Funding</StyledTableCell>
                {/* <StyledTableCell align="center">Suitability</StyledTableCell> */}
                <StyledTableCell align="center">Application</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {loading === true ? (
              <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
              </Grid>
            ) : (
              <TableBody>
                {campApplicantFundingList &&
                  campApplicantFundingList.length > 0 &&
                  campApplicantFundingList.map((list, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "& .MuiTableCell-root": { py: "0.45rem" },
                      }}
                    >
                      <StyledTableCell component="th" scope="row">
                        <Stack
                          direction="row"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Box alignSelf="center">{list.Funding}</Box>
                          {/* <Link
                            className="link2"
                            to={`/ChangeFunding/${list.campId}/${list.staffId}`}
                          > */}
                          <Chip
                            // label={card.campType.charAt(0).toUpperCase()+card.campType.slice(1).toLowerCase()}
                            label="View"
                            variant="outlined"
                            size="small"
                            className="medium"
                            mx="0.75rem"
                            sx={{
                              color: "#707070",
                              backgroundColor: "white",
                              fontSize: "0.75rem",
                              px: "0.5rem",
                              height: "25px",
                              cursor: "pointer",
                            }}
                            onClick={SupportStaffinvoiceHandleOpen}
                          />
                          {/* </Link> */}
                        </Stack>
                      </StyledTableCell>

                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                            {list.status ? list.status : "Unknown"}
                          </Box>
                          <Link
                            to={`/SupportStaffApplicationReview/${campId}/${staffApplicationId}/${staffId}`}
                            className="link2"
                          >
                            <Button
                              variant="contained"
                              sx={{
                                bgcolor: "#379592",
                                color: "white",
                                textTransform: "none",
                                "&:hover": {
                                  background: "#379592",
                                  color: "white",
                                },
                                // width: "60%",
                             
                              }}
                              className="gilroy reviewapp"
                            >
                              Review Application
                            </Button>
                          </Link>

                          <Link
                            to={`/SupportstaffNotificationLog/${campId}/${staffApplicationId}/${staffId}`}
                            className="link2"
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                color: "#379592",
                                textTransform: "none",
                                "&:hover": { color: "#379592" },
                                width: "155px",
                                mx: "0.5rem",
                                my: "0.5rem",
                              }}
                              className="gilroy"
                            >
                              Notifications
                            </Button>
                          </Link>
                          {list.welcomePackAccessed === 'Yes' 
                        ? (
                          // <Chip label="Welcome Pack Accessed" color="success" />
                          <Box sx={{color:'#78C091',fontSize:'14px'}} >Welcome Pack Accessed</Box>
                        ) : 
                        (
                          <Box sx={{color:'#F21010',fontSize:'14px'}}>Welcome Pack Not Accessed</Box>
                          // <Chip label="Welcome Pack Not Accessed" color="error"  />
                        )}
                         
                        </Stack>
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            <TableRow>
              <StyledTableCell>
                <Stack>
                  <Card
                    sx={{
                      backgroundColor: "#F8F8F8",
                      color: "#707070",
                      my: "1rem",
                      border: 1,

                      borderRadius: "0.75rem",
                    }}
                  >
                    <CardContent>
                      <Grid
                        container
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Box
                            sx={{
                              width: "15px",
                              color: "black",
                              fontWeight: "bold",
                              marginLeft: "1rem",
                            }}
                          >
                            Invoice
                          </Box>
                        </Grid>
                        <Grid item>
                          <Stack direction="row" spacing={1}>
                            {/* <Link
                              to={`/SupportStaffGenerateInvoice/${campId}/${staffApplicationId}/${staffId}`}
                              className="link2"
                            >
                              <Chip
                                label="Generate"
                                variant="outlined"
                                disabled={invoiceStat === true}
                                size="small"
                                className="medium"
                                mx="0.75rem"
                                sx={{
                                  color: "#707070",
                                  backgroundColor: "white",
                                  fontSize: "0.75rem",
                                  px: "0.5rem",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                              />
                            </Link> */}
                            <Button
                                variant="outlined"
                                // disabled={ === true}
                                disabled={campApplicantFundingList[0] && campApplicantFundingList[0].invoiceStatus === 'Submitted'}
                                sx={{
                                  
                                  mx: "0.5rem",
                                  bgcolor: "white",
                                  color: "#379592",
                                  textTransform: "none",
                                  borderColor: '#379592',
                                  borderRadius: '20px',
                                  "&:hover": { background: "white", color: '#379592', borderColor: '#379592', borderRadius: '20px' },
                                  width: '90px',
                                  py: '0rem'
                                }}
                                className="gilroy"
                                onClick={() => { history.push(`/SupportStaffGenerateInvoice/${campId}/${staffApplicationId}/${staffId}`) }}
                              >Generate
                              </Button>

                            {/* <Button
                              // label="Download"
                              variant="outlined"
                              size="small"
                              className="medium"
                              mx="0.75rem"
                              sx={{
                                color: "#707070",
                                backgroundColor: "white",
                                fontSize: "0.75rem",
                                px: "0.5rem",
                                height: "25px",
                                cursor: "pointer",
                              }}
                              onClick={()=>{history.push(`/SupportStaffInvoiceDownload/${campId}/${staffApplicationId}/${staffId}`)}}
                            >Download</Button> */}
                            <Button
                                variant="outlined"
                                disabled={!(campApplicantFundingList[0] && campApplicantFundingList[0].invoiceStatus === 'Submitted')}
                                // disabled={localStorage.getItem('ndisStatus') !== 'Parent-Submitted'}
                                sx={{
                                  // px: "1rem",
                                  mx: "0.5rem",
                                  bgcolor: "white",
                                  color: "#379592",
                                  textTransform: "none",
                                  borderColor: '#379592',
                                  borderRadius: '20px',
                                  "&:hover": { background: "white", color: '#379592', borderColor: '#379592', borderRadius: '20px' },
                                  width: '90px',
                                  py: '0rem'
                                }}
                                className="gilroy"
                              onClick={()=>{history.push(`/SupportStaffInvoiceDownload/${campId}/${staffApplicationId}/${staffId}`)}}
                              >Download
                              </Button>
                          </Stack>
                        </Grid>
                      </Grid>

                      {/* <List>
                        {listArray.map((item, index) => (
                          <ListItem key={index} sx={{ color: "black" }}>
                            <Grid
                              container
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Grid item md={5}>
                                {item.title}
                              </Grid>
                              <Grid item md={5} className="medium">
                                {item.name}
                              </Grid>
                            </Grid>
                          </ListItem>
                        ))}
                      </List> */}
                      <List >
                          
                          <ListItem sx={{ color: 'black' }} >
                            <Grid container display='flex' justifyContent='space-between'>
                              <Grid item md={4}>Sent by :</Grid>
                              <Grid item md={8} className="medium">{campApplicantFundingList[0] && campApplicantFundingList[0].invoiceInfo?.sentBy}</Grid>
                            </Grid>
                          </ListItem>
                          <ListItem sx={{ color: 'black' }} >
                            <Grid container display='flex' justifyContent='space-between'>
                              <Grid item md={4}>Sent Date : </Grid>
                              <Grid item md={8} className="medium">{campApplicantFundingList[0] && campApplicantFundingList[0].invoiceInfo?.sentDate}</Grid>
                            </Grid>
                          </ListItem>
                          {/* <ListItem sx={{ color: 'black' }} >
                            <Grid container display='flex' justifyContent='space-between'>
                              <Grid item md={4}>Paid by : </Grid>
                              <Grid item md={8} className="medium">{campApplicantFundingList[0] && campApplicantFundingList[0].invoiceInfo?.paidBy}</Grid>
                            </Grid>
                          </ListItem> */}
                          <ListItem sx={{ color: 'black' }} >
                            <Grid container display='flex' justifyContent='space-between'>
                              <Grid item md={4}>Paid Date : </Grid>
                              <Grid item md={8} className="medium">{campApplicantFundingList[0] && campApplicantFundingList[0].invoiceInfo?.paidDate}</Grid>
                            </Grid>
                          </ListItem>
                          {/* ))} */}
                        </List>
                    </CardContent>
                  </Card>
                </Stack>
              </StyledTableCell>
            </TableRow>
          </Table>
        </TableContainer>
        {/* <Stack direction='column' spacing={1}  justifyContent='flex-end'> */}
        <Grid
          container
          justifyContent="flex-end"
          direction="column"
          alignContent="flex-end"
          sx={{ mr: "1.5rem" }}
        >
          {/* <Link className="link2" to="/ChangeSuitability">
            <Button
              variant="contained"
              sx={{
                bgcolor: "#4C9F70",
                color: "white",
                textTransform: "none",
                "&:hover": { background: "#4C9F70", color: "white" },
                //  width:'110%',
                //  mr:'0.5rem',
                my: "0.5rem",
                px: "2.5rem",
              }}
              className="gilroy"
            >
              Confirm Camp Place
            </Button>
          </Link> */}

          <Link
            to={`/SupportStaffFiles/${campId}/${staffApplicationId}/${staffId}`}
            className="link2"
          >
            <Button
              variant="outlined"
              sx={{
                color: "#379592",
                textTransform: "none",
                "&:hover": { color: "#379592" },
                width: "180px",
                mx: "0.5rem",
                my: "0.5rem",
              }}
              className="gilroy"
            >
              Files
            </Button>
          </Link>
        </Grid>
        <Grid>
          <Modal
            open={supportStaffInvoiceOpen}
            onClose={supportStaffInvoiceHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        
            
          >
            <Box sx={suitabilityStyle}>
              <Box sx={{maxHeight: "500px", overflow: "auto", overflowX: "hidden"}}>
              <Grid
                container
                justifyContent="flex-Start"
                direction="row"
                alignContent="flex-Start"
              >
                <FormControl sx={{ m: 1, minWidth: 225 }} size="small">
                  <InputLabel id="demo-select-small">
                    Choose Funding Status
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={fundingStatusOptions}
                    label="Choose Suitability Status"
                    onChange={handleFundingChange}
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    <MenuItem value="Invoice Sent">Invoice Sent</MenuItem>
                    <MenuItem value="Funding Confirmed">
                    Funding Confirmed
                    </MenuItem>
                    
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      mt: "0.5rem",
                      width: "100%",
                      height: "20%",
                      mb: "0.5rem",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={2}
                    label="Enter Remarks..."
                    value={fundingRemarks}
                    onChange={onChangeFundingRemarks}
                  />
                </Box>
              </Grid>
              <Box className="gilroy">Previous Comments</Box>
              <Grid>
                <TableContainer
                  component={Paper}
                  sx={{ my: "2rem", mt: "0.5rem" }}
                >
                  <Table sx={{ minWidth: 450 }} aria-label="customized table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">Date</StyledTableCell>

                        <StyledTableCell align="center">
                          Camp Manager
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Suitability Status
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Remarks
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>

                    <TableBody>
                      {fundingComments &&
                        fundingComments.map((list, index) => (
                          <TableRow
                            sx={{
                              "& .MuiTableCell-root": { py: "0.45rem" },
                            }}
                          >
                            <StyledTableCell component="th" scope="row">
                              <Stack
                                direction="row"
                                justifyContent="center"
                                spacing={1}
                              >
                                <Box alignSelf="center"> {list.Date}</Box>
                              </Stack>
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <Stack
                                direction="row"
                                justifyContent="center"
                                spacing={1}
                              >
                                <Box alignSelf="center">
                                  {" "}
                                  {list.CampManager}
                                </Box>
                              </Stack>
                            </StyledTableCell>

                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Box
                                  my="0.5rem"
                                  sx={{ alignItems: "flex-end" }}
                                >
                                  {list.Status}
                                </Box>
                              </Stack>
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <Stack
                                direction="row"
                                justifyContent="center"
                                spacing={1}
                              >
                                <Box alignSelf="center">{list.Remarks}</Box>
                              </Stack>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container sx={{ justifyContent: "center" }}>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      px: "2rem",
                      mx: "0.5rem",
                      bgcolor: "#909090",
                      textTransform: "none",
                      "&:hover": { background: "#909090" },
                    }}
                    className="gilroy"
                    onClick={supportStaffInvoiceHandleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className="gilroy"
                    // onClick={shoot}
                    onClick={() => {
                      handleSubmit();
                    }}
                    sx={{
                      px: "2rem",
                      mx: "0.5rem",
                      bgcolor: "#379592",
                      "&:hover": { background: "#379592" },
                      textTransform: "none",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              </Box>
              
            </Box>
          </Modal>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
