import React, { useState, createContext } from "react";
import Button from "@mui/material/Button";

import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,

  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";

import { apiService, apiService2 } from "../../Services/apiService";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png"
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png"

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams } from "react-router-dom";
import { SERVER_URL,DECLINE_APPLICATIONS , UPDATECAMP_REGSTATUS,GETCAMP_APPLICATION} from '../../Services/apiEndpints'
import Swal from 'sweetalert2'
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";

export default function ApplicantsReview() {
  const getRegStatus = localStorage.getItem("RegStatus")

  const [campApplicantList, setCampApplicantList] = React.useState()
  const [appStatus, setAppStatus] = React.useState('All');
  const [loading, setLoading] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [regToggle, setRegToggle] = React.useState(getRegStatus)

  console.log(regToggle)
  const history = useHistory()
  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    palette: {
      secondary: {
        main: "#379592",
      },
    },

    Muidisabled: {
      styleOverrides: {
        backgroundColor: "purple",
      },
    },
  });


  const handleChange = (event) => {
    setAppStatus(event.target.value);

  };

  let filteredList = campApplicantList && campApplicantList.filter((item) => {
    return (
      // item.status.toLowerCase().includes(appStatus.toLowerCase())
      item.status.toLowerCase() === appStatus.toLowerCase()
    );
  });

  filteredList = appStatus === 'All' ? campApplicantList : filteredList;

  //  if (filteredList = filteredList && filteredList.length >0 ? (filteredList) : (campApplicantList))

  // console.log('here',filteredList)
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      backgroundColor: "rgba(55, 149, 146, 1)",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));


  let { campId } = useParams();
  // const getId = localStorage.getItem("CmpId")
  const getCampName = localStorage.getItem("CampName")


  const handleSetAppId = (id, name, childId) => {
    // localStorage.setItem("AppId",id)
    localStorage.setItem("AppName", name)
    // localStorage.setItem("ChildId",childId)
    // setApplicantId(id,childId)
  }

  React.useEffect(() => {
    (
      async () => {
        setLoading(true)
        let dataSent = {
          userId: localStorage.getItem('userId'),
          campId: campId
        }

        let apiInfo = {
          // url: "https://cncdevapi.natsoft.io/api/camps/getcampapplicants",
          url: `${SERVER_URL}${GETCAMP_APPLICATION}`,
          method: "POST",
          postData: dataSent,
          headers: {
            "Content-Type": "application/json",
            tokenheaderkey: localStorage.getItem("token"),
          },

        }

        let output = await apiService2(apiInfo)
        console.log(output.data.responseData[0])
        let campApplicantList = output.data.responseData[0]
        setLoading(false)
        setCampApplicantList(campApplicantList)
      }
    )()
  }

    , [reload])

  const handleDecline = async () => {
    let dataSent = {
      userId: localStorage.getItem('userId'),
      campId: campId,
      applicationStatus: appStatus
    }

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/camps/declineapplications",
      url: `${SERVER_URL}${DECLINE_APPLICATIONS}`,
      method: "POST",
      postData: dataSent,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },

    }

    let output = await apiService2(apiInfo)
    console.log(output.data.responseData[0])

    if (output?.data?.responseData[0][0].Code === "0") {
      let statusOutput = output.data.responseData[0][0].Desc
      Swal.fire({
        iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
        text: statusOutput,
        confirmButtonText: 'OK',
        confirmButtonColor: "#379592",
        customClass: {
          container: "my-swal",

        },
      }).then(() => {
        setReload((prev) => !prev)
        setAppStatus('All')
      })
    }

  }

  const handleRegistrations = async () => {
    console.log('clicked')
    

    let stat = ''

    if (regToggle === 'REGISTRATION OPEN') {
      stat = 'CLOSED'
    }
    else {
      stat = 'OPEN'
    }


    let dataSent = {
      userId: localStorage.getItem('userId'),
      campId: campId,
      registrationStatus: stat
    }

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/camps/updatecampregstatus",
      url: `${SERVER_URL}${UPDATECAMP_REGSTATUS}`,
      method: "POST",
      postData: dataSent,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },

    }

    let output = await apiService2(apiInfo)
    console.log(output.data.responseData[0])
    if (output?.data?.responseData[0][0].Code === "0") {
      let statusOutput = output.data.responseData[0][0].Desc
      Swal.fire({
        iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
        text: statusOutput,
        confirmButtonText: 'OK',
        confirmButtonColor: "#379592",
        customClass: {
          container: "my-swal",

        },
      }).then(() => {
        history.push('/ParticipantsList')
      })
    }
  }
  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>

        <Grid container direction='flex' justifyContent='space-between'>
          <Stack direction='row' spacing={1}>
            <Link className='link2' to='/ParticipantsList' >
              <Box
                component="img"
                sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer', mt: '0.75rem' }}
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>

            <Box alignSelf="center">{getCampName}</Box>
          </Stack>
          <Grid item className="btns">
          {/* <Link to={`/WelcomePackRepublish/5198`}> */}
              <Button
                variant="contained"
                disabled = {campApplicantList && campApplicantList[0].wpAvailable !== 'Yes'}
                sx={{
                  bgcolor: "#4C9F70",
                  color: 'white',
                  textTransform: "none",
                  "&:hover": { background: "#4C9F70", color: 'white' },
                  //   width: "150px",
                  mx: '0.5rem'
                }}
                className="gilroy viewwelcomepack-btn"
                onClick={()=> (history.push(`/WelcomePackRepublish/${campId}`)) }
              >
                View Welcome Pack
              </Button>
            {/* </Link> */}

            {/* <Link to={`/CreateWelcomePack/${campId}`}> */}
            {console.log(campApplicantList &&campApplicantList[0].wpAvailable )}
              <Button
                variant="contained"
                disabled = {campApplicantList && campApplicantList[0].wpAvailable === 'Yes'}
                sx={{
                  bgcolor: "#4C9F70",
                  color: 'white',
                  textTransform: "none",
                  "&:hover": { background: "#4C9F70", color: 'white' },
                  //   width: "150px",
                  mx: '0.5rem'
                }}
                className="gilroy createwelcomepack-btn"
                onClick={()=> (history.push(`/CreateWelcomePack/${campId}`)) }
              >
                Create Welcome Pack
              </Button>
            {/* </Link> */}

            <Button
              onClick={handleDecline}
              variant="contained"
              sx={{
                bgcolor: "#F21010",
                color: 'white',
                textTransform: "none",
                "&:hover": { background: "#F21010", color: 'white' },
                mx: '0.5rem',
                //   width: "150px",

              }}
             
              className="gilroy declineapp-btn"
            >
              Decline Application
            </Button>
            <Button
              onClick={handleRegistrations}
              variant="contained"
              sx={{
                bgcolor: getRegStatus === 'REGISTRATION OPEN' ? ("#F21010") : ('#4C9F70'),
                color: 'white',
                textTransform: "none",
                "&:hover": { background: getRegStatus === 'REGISTRATION OPEN' ? ("#F21010") : ('#4C9F70'), color: 'white' },
                mx: '0.5rem'
              }}
              className="gilroy registration-btn"
            >
              {getRegStatus === 'REGISTRATION OPEN' ? ('Close Registration') : ('Open Registration')}
            </Button>
            {/* <Link to={`/ReminderLog/${campId}`} className='link2'>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#FF9529",
                  color: 'white',
                  textTransform: "none",
                  "&:hover": { background: "#FF9529", color: 'white' },

                  mx: '0.5rem'
                }}
                className="gilroy"
              >
                Reminders
              </Button>
            </Link> */}

          </Grid>

        </Grid>
        <Grid container display='flex' alignItems='center' mt='0.5rem'>
          <Box my='0.5rem'>{appStatus} ({filteredList && filteredList.length})</Box>
          <Box>
            <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
              <InputLabel id="demo-simple-select-autowidth-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={appStatus}
                onChange={handleChange}
                label="Select"
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='Application Submitted'>Application Submitted</MenuItem>
                <MenuItem value='In Progress'>In Progress</MenuItem>
                <MenuItem value='Pending Suitability and Funding'>Pending Suitability and Funding</MenuItem>
                <MenuItem value='Pending Suitability, Funding Confirmed'>Pending Suitability, Funding Confirmed</MenuItem>
                <MenuItem value='Suitability Confirmed, Pending Funding'>Suitability Confirmed, Pending Funding</MenuItem>
                <MenuItem value='DECLINED'>Declined</MenuItem>
                <MenuItem value='Waitlisted'>Waitlisted</MenuItem>
                <MenuItem value='Confirmed'>Confirmed</MenuItem>
               
                
                {/* <MenuItem value='Not Suitable'>Not Suitable</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {loading === true
          ? (
            <Grid container justifyContent='center' alignItems='center' >
              <CircularProgress />
            </Grid >
          )
          : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 450 }} aria-label="customized table" my='1rem' >

                <TableHead >

                  <StyledTableRow>
                    <StyledTableCell align='center'>Sl. No</StyledTableCell>
                    <StyledTableCell align="center">Applicants</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>

                  </StyledTableRow>
                </TableHead>
                <TableBody >
                  {filteredList && filteredList.map((list, index) => (
                    <TableRow key={index} sx={{
                      "& .MuiTableCell-root": { py: "0.45rem" },
                    }}>
                      <StyledTableCell component="th" scope="row" align="center" >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list.applicantName}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" align="center" 
                      sx={{
                        fontWeight:'medium',
                        color: list.status=== 'Waitlisted' 
                        ? ( 'primary.main') 
                        : (list.status=== 'Confirmed' 
                        ? ('#78C091')
                         :(list.status === 'DECLINED' ? ('#F21010') : ('black')))
                    }} >
                        {list.status}
                      </StyledTableCell><StyledTableCell component="th" scope="row" align="center" >
                        <Link to={`/ChangeSuitability/${list.campId}/${list.applicationId}`} className='link2'>
                          <Button variant="contained" onClick={() => { handleSetAppId(list.applicationId, list.appName, list.childId) }}
                            sx={{
                              bgcolor: "#718F94",
                              textTransform: 'none',
                              "&:hover": { background: "#718F94" },
                              width: "100px"
                            }}>
                            View
                          </Button>
                        </Link>

                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}



      </Box>
    </ThemeProvider>



  );
}
