import React, { useState } from "react";
import Button from "@mui/material/Button";

import {
    CardActionArea,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,

    Chip,
    CardContent,
    Divider,
    Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";

import { apiService, apiService2 } from "../Participants/ParticipantsList";
import moment from "moment";
import { Link,useHistory } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png"
import TextField from "@mui/material/TextField";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png"
import Swal from 'sweetalert2'

export default function PendingSuitabilityOther() {
    const theme = createTheme({
        typography: {
            fontFamily: "gilroyMedium",
        },
        palette: {
            secondary: {
                main: "#379592",
            },
        },
        // components: {
        //   MuiCssBaseline: {
        //     styleOverrides: {
        //       "@font-face": {
        //         fontFamily: "gilroyMedium",
        //         src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        //       },
        //     },
        //   },
        // },
        
    });
    const history = useHistory();
    const handleSwalSuccessful = () =>{
        Swal.fire({
            
            text: 'Submitted Succesfully!',
            confirmButtonText: 'OK',
            confirmButtonColor: '#379592',
            // customClass: {
            //     container: "my-swal",
            //      },
          }).then(() => {
            history.push("/ChangeSuitability");
          });
    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box>
            <Stack direction='row' spacing={1}>
            <Link to='/ChangeSuitability' className='link2'>
            <Box
                 
                  alignSelf="center"
                  component="img"
                  sx={{ height: "0.75rem", width: "0.65rem" ,cursor:'pointer'}}
                  alt="Logo"
                  src={ArrowBack}
                ></Box>
            </Link>
        
        <Box alignSelf="center" px='1rem'> Pending Suitability - Other</Box>
        </Stack>
                
                    <Box my='2rem' sx={{color:'pink'}}>
                    <TextField
                    color="secondary" focused
                        id="standard-basic"
                        // label="Past Camp History"
                        multiline
                        size='small'
                        variant="outlined"
                        value='Status for CHILD NAME to attend CAMP NAME has been changed to ‘Pending Suitabiltity – Other’ due to… enter description'
                        rows={8}
                        InputLabelProps={{
                            shrink: true
                        }}
                        sx={{ width: 800,  my: '0.35rem' ,color:'#718F94',"& .MuiInputBase-root.MuiOutlinedInput-root" : {color: "#3B3B3B",}}}
                    ></TextField>
                    </Box>
                    
                
                <Grid container justifyContent='flex-end' sx={{my:'2rem',mr:'2rem',px:'2rem'}}>
                    <Link to='/ChangeSuitability' className='link2'>
                    <Button variant="contained"
                        sx={{

                            bgcolor: "#909090",
                            textTransform: 'none',
                            "&:hover": { background: "#909090" },
                            width: "100px",
                            mx: '0.5rem'
                        }}>
                       Cancel
                    </Button>
                    </Link>
                    
                    <Button variant="contained" 
                    onClick={()=>handleSwalSuccessful()}
                        sx={{

                            bgcolor: "#379592",
                            textTransform: 'none',
                            "&:hover": { background: "#379592" },
                            width: "100px",
                            mx: '0.5rem'
                        }}>
                        Submit
                    </Button>
                    
                    
                </Grid>

            </Box>
        </ThemeProvider>

    );
}
