import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { Link, useHistory, useParams } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import "../../index.css";
import { apiService, apiService2 } from "../../Services/apiService";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Searchdvance from "../../Assets/Icons/Icons/searchadvanced.png";
import { alpha } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import CrossGreen from "../../Assets/Icons/Icons/CrossGreen.png";
import { Checkbox, ListItemText, Select } from "@mui/material";
import { US8_URL, SERVER_URL } from "../../Services/apiEndpints";
import moment from "moment";

const dataofMedicalForm = {
  application_id: "",
  camp_id: "",
  child_id: "",
  created_by: "",
  created_timestamp: "",
  medication_date: "",
  medicince_time: "",
  medicine: "",
  medicine_id: "",
  notes: "",
  prescribed_by: "",
  provided_by: "",
};

// Custom theme with checkbox color override
const theme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#379592", // Change the color value here
          },
        },
      },
    },
  },
});

export default function EditParticipantMedicalRecord() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [MedicalHistory, setMedicalHistory] = useState([]);
  const [open, setOpen] = React.useState(false);
  // const [medicineTime, setMedicineTime] = useState([]);
  let history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [medicalFormData, setMedicalFormData] =
    React.useState(dataofMedicalForm);
  const [search, setSearch] = useState("");

  function storeInfo(event, id) {
    const { name, value } = event.target;
    console.log("name, value", name, value, id);

    const updatedMedicalHistory = MedicalHistory.map((obj, index) => {
      if (index === id) {
        return { ...obj, [name]: value };
      }
      return obj;
    });

    setMedicalHistory(updatedMedicalHistory);
  }

  const useStyles = makeStyles({
    root: {
      margin: "10px",
      boxShadow: "0px 0px 10px #00000003",
      borderRadius: "20px",
    },
    mainheader: {
      letterSpacing: "0px",
      color: "#6c6c6c",
      textAlign: "center",
      fontSize: "18px",
      marginBottom: "2rem",
    },
    subheader: {
      letterSpacing: "0px",
      color: "#3B3B3B",
      textAlign: "left",
      fontSize: "16px",
      paddingTop: "16px",
    },
    listStyle: {
      maxHeight: "300px", // Adjust this value as needed
      overflowY: "scroll",
    },
    customScrollbar: {
      "&::-webkit-scrollbar": {
        width: "6px", // Adjust the width as needed
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#6c6c6c", // Change this to the color you want
        borderRadius: "3px",
      },
    },
  });
  const classes = useStyles();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const handleRemoveRow = async (index, medicineId) => {
    console.log(medicineId);
    if (medicineId === null) {
      setMedicalHistory((MedicalHistory) =>
        MedicalHistory.filter((_, i) => i !== index)
      );
    } else {
      try {
        const gettran = {
          _methodId: "17",
          userId: localStorage.getItem("userId"),

          applicationId: applicationId,

          campId: camp_id,
          medicine_id: medicineId,
        };

        let apiInfo = {
          url: `${SERVER_URL}${US8_URL}`,

          method: "POST",

          postData: gettran,

          headers: {
            "Content-Type": "application/json",

            tokenheaderkey: localStorage.getItem("token"),
          },
        };

        let login = await apiService2(apiInfo);
        const medicalDataList = login.data.responseData[0];

        if (medicalDataList[0].ResponseCode === "Success") {
          const updatedMedicalHistory = [...MedicalHistory];
          updatedMedicalHistory.splice(index, 1);
          setMedicalHistory(updatedMedicalHistory);
          getMedcationDetails();
        }
      } catch (error) {
        console.error("API request failed:", error);
      }
    }
  };

  const handleAddRow = () => {
    // console.log('currrrrrrr',(moment(new Date().toISOString().slice(0, 10)).format("DD-MM-YYYY")))
    const newRow = {
      _methodId: "15",
      application_id: applicationId,
      userId: localStorage.getItem("userId"),
      camp_id: camp_id,
      child_id: "",
      created_by: "",
      created_timestamp: "",
      medicine_date: new Date().toISOString().slice(0, 10),
      medicince_time: [],
      medicine: "",
      medicine_id: null,
      notes: "",
      prescribed_by: "",
      provided_by: localStorage.getItem("userName"),
      // selectedItems: [],
    };
    setMedicalHistory((prevData) => [...prevData, newRow]);
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // hide last border

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,

      backgroundColor: "rgba(55, 149, 146, 1)",

      color: theme.palette.common.white,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  let { camp_id, applicationId } = useParams();

  React.useEffect(() => {
    (async () => {
      // setLoading(true);
      await getMedcationDetails();
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);

  const handleOpen = (id, name) => {
    console.log(name);
    console.log(id);
    setOpen(true);

    // console.log(rowId)
  };

  const formatDate = (date) => {
    console.log("what is coming", date);
    if (date) {
      const [day, month, year] = date.split("/"); // Split the date string

      const formattedDate = new Date(`${year}/${month}/${day}`); // Construct a new date string

      const formattedYear = formattedDate.getFullYear();

      const formattedMonth = String(formattedDate.getMonth() + 1).padStart(
        2,
        "0"
      );

      const formattedDay = String(formattedDate.getDate()).padStart(2, "0");

      return `${formattedYear}-${formattedMonth}-${formattedDay}`;
    }

    return "";
  };
  const getMedcationDetails = async () => {
    let gettran = {
      _methodId: "16",

      userId: localStorage.getItem("userId"),

      applicationId: applicationId,

      campId: camp_id,
    };

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/us8",

      url: `${SERVER_URL}${US8_URL}`,

      method: "POST",

      postData: gettran,

      headers: {
        "Content-Type": "application/json",

        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let login = await apiService2(apiInfo);

    const medicalDataList = login.data.responseData[0];

    console.log(medicalDataList);

    if (medicalDataList && medicalDataList.length > 0) {
      // const updatedMedicalDataList = medicalDataList.map((element) => { return { ...element, medication_date: dateConversion(element.medication_date) }; });
      medicalDataList.forEach((element) => {
        //   element["medicince_time"] = dateConversion(element.medicine_date)
        element["medicince_time"] = JSON.parse(element?.medicince_time);
        // });
      });
      // console.log(medicalDataList)

      if (medicalDataList && medicalDataList.length > 0) {
        setMedicalHistory(
          medicalDataList.map((item) => ({
            ...item,

            medicine_date: formatDate(item.medicine_date), // Format the date here
          }))
        );

        console.log(JSON.stringify(medicalDataList));
      }

      // setMedicalHistory(medicalDataList);
    }

    // medicalDataList.forEach((element) => {

    //   element["medicince_time"] = dateConversion(element.medicine_date)

    // });
  };

  const handleChange = (event, row, index) => {
    console.log("event,row, index", event, row, index);
    setMedicalHistory((prevData) =>
      prevData.map((item, index1) => {
        if (index === index1) {
          return { ...item, medicince_time: event.target.value };
        }
        return item;
      })
    );
  };

  const handleSubmit = async () => {
    try {
      const gettran = {
        _methodId: "26",
        userId: localStorage.getItem("userId"),
        applicationId: applicationId,
        campId: camp_id,
      };

      let apiInfo = {
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let login = await apiService2(apiInfo);
      const medicalDataList = login.data.responseData[0];

      if (medicalDataList[0].ResponseCode === "Success") {
        Swal.fire({
          // icon: "success",
          iconHtml: `<img src="${GreenCheck}" >`,
          // text: register?.data?.responseData,
          text: medicalDataList[0].Desc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
        }).then(
          history.push(`/ParticipantsMedicalRecord/${camp_id}/${applicationId}`)
        );
      }
    } catch (error) {
      console.error("API request failed:", error);
      // Handle error or display error message
    }
  };

  const handleSave = async (type) => {
    console.log(MedicalHistory);
    const keysToCheck = [
      "medicince_time",
      "prescribed_by",
      "notes",
      "provided_by",
      "medication_date",
      "medicine",
    ];
    const filteredData = MedicalHistory.filter((item) => {
      for (const key of keysToCheck) {
        if (
          item[key] !== undefined &&
          item[key] !== null &&
          item[key] !== "" &&
          item[key].length !== 0
        ) {
          return true;
        }
      }
      return false;
    });
    console.log(filteredData);
    // const finalArray = filteredData.filter((data) => data.medicine_id === null);
    // console.log("finalArrayyyyyyyyyyyyy", finalArray);
    if (filteredData && filteredData.length > 0) {
      filteredData.forEach((arr, index) => {
        saveMedicalform(arr, index, filteredData, type);
        console.log("arrrrrrrrrrrrrrrrrrrrrrrrrrrr", arr);
      });
    }
  };

  const saveMedicalform = async (body, index, mainArray, type) => {
    console.log("type", type);
    const postBody = {
      _methodId: body.medicine_id ? 25 : body._methodId,
      userId: localStorage.getItem("userId"),
      applicationId: body.application_id,
      medicine: body.medicine,
      medicine_time: body?.medicince_time,
      prescribed_by: body?.prescribed_by,
      provided_by: body?.provided_by,
      medicine_id: body?.medicine_id,
      status: type,
      medicine_date: body.medicine_date,
      notes: body.notes,
      campId: body.camp_id,
    };
    console.log("postBodyyyyyyyyyyyyyyyyyy", postBody);
    let apiInfo = {
      url: `${SERVER_URL}${US8_URL}`,
      method: "POST",
      postData: postBody,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    };
    let saveResponse = await apiService2(apiInfo);
    debugger;
    const saveResponseoutput = saveResponse.data.responseData[0][0];
    const disc = saveResponseoutput.Desc;
    console.log("discccccccccccccccccccccccccccccc", disc);
    if (
      index === mainArray.length - 1 &&
      saveResponse?.data?.responseData &&
      saveResponse?.data?.responseData.length > 0 &&
      saveResponse?.data?.responseData[0] &&
      saveResponse?.data?.responseData[0].length > 0 &&
      saveResponse?.data?.responseData[0][0] &&
      saveResponse?.data?.responseData[0][0].ResponseCode === "Success"
    ) {
      Swal.fire({
        // icon: "success",
        iconHtml: `<img src="${GreenCheck}" >`,
        // text: register?.data?.responseData,
        text: disc,
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
      }).then(
        history.push(`/ParticipantsMedicalRecord/${camp_id}/${applicationId}`)
      );
      await getMedcationDetails();
    }
  };
  console.log("MedicalHistoryyyyyyyyyyyyyyyyyyyyy", MedicalHistory);
  return (
    <>
      <Grid container display="flex" sx={{ mt: "2rem" }}>
        <Grid item xs={12} sm={6} md={9}>
          <Stack direction="row" spacing={1}>
            <Link
              to={`/ParticipantsMedicalRecord/${camp_id}/${applicationId}`}
              className="link2"
            >
              <Box
                alignSelf="center"
                component="img"
                sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>

            <Box alignSelf="center" px="1rem">
              {MedicalHistory &&
                MedicalHistory.length &&
                MedicalHistory[0].child_name}{" "}
              Medical Record
            </Box>
          </Stack>
        </Grid>
      </Grid>

      {/* Table code starts here */}

      <TableContainer component={Paper} sx={{ marginTop: "3rem" }}>
        <Table sx={{ minWidth: 450 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                Medicine & Dosage
              </StyledTableCell>

              <StyledTableCell sx={{ textAlign: "center" }}>
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center">Time</Box>
                </Grid>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center">Prescribed By</Box>
                </Grid>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center">Notes</Box>
                </Grid>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center">Provided By</Box>
                </Grid>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center">Date</Box>
                </Grid>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center">Action</Box>
                </Grid>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {console.log("MedicalHistory", MedicalHistory)}

            {MedicalHistory.map((row, index) =>
              row.medicine_id ? (
                <TableRow key={row.medicine_id} className="normal">
                  {console.log(row, "kkkkkkkkkkkkkkkkkkkkkkk")}
                  <TableCell component="th" scope="row" align="center">
                    <TextField
                      sx={{ width: "100%" }}
                      id="standard-basic"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      name="medicine"
                      value={row.medicine}
                      onChange={(event) => storeInfo(event, index)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <ThemeProvider theme={theme}>
                      <Select
                        sx={{ maxWidth: "100%" }}
                        multiple
                        value={row.medicince_time}
                        onChange={(e) => handleChange(e, row, index)}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        <MenuItem value="Before Breakfast">
                          <Checkbox
                            checked={
                              row.medicince_time.indexOf("Before Breakfast") >
                              -1
                            }
                          />

                          <ListItemText primary="Before Breakfast" />
                        </MenuItem>

                        <MenuItem value="Breakfast">
                          <Checkbox
                            checked={
                              row.medicince_time.indexOf("Breakfast") > -1
                            }
                          />

                          <ListItemText primary="Breakfast" />
                        </MenuItem>

                        <MenuItem value="Lunch">
                          <Checkbox
                            checked={row.medicince_time.indexOf("Lunch") > -1}
                          />

                          <ListItemText primary="Lunch" />
                        </MenuItem>

                        <MenuItem value="3/3:30pm">
                          <Checkbox
                            checked={
                              row.medicince_time.indexOf("3/3:30pm") > -1
                            }
                          />

                          <ListItemText primary="3/3:30pm" />
                        </MenuItem>

                        <MenuItem value="Dinner">
                          <Checkbox
                            checked={row.medicince_time.indexOf("Dinner") > -1}
                          />

                          <ListItemText primary="Dinner" />
                        </MenuItem>

                        <MenuItem value="Bed">
                          <Checkbox
                            checked={row.medicince_time.indexOf("Bed") > -1}
                          />

                          <ListItemText primary="Bed" />
                        </MenuItem>
                      </Select>
                    </ThemeProvider>
                  </TableCell>{" "}
                  <TableCell component="th" scope="row" align="center">
                    <TextField
                      sx={{ width: "5rem" }}
                      id="standard-basic"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      name="prescribed_by"
                      value={row.prescribed_by}
                      onChange={(event) => storeInfo(event, index)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      sx={{ width: "5rem" }}
                      id="standard-basic"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      name="notes"
                      value={row.notes}
                      onChange={(event) => storeInfo(event, index)}
                    />
                  </TableCell>{" "}
                  <TableCell align="center">
                    <TextField
                      sx={{ width: "5rem" }}
                      id="standard-basic"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      name="provided_by"
                      value={row.provided_by}
                      onChange={(event) => storeInfo(event, index)}
                    />
                  </TableCell>{" "}
                  <TableCell align="center">
                    {/* {console.log(row.medicine_date,'78666')} */}
                    <TextField
                      type="date"
                      id={`input-with-sx-${row.medicine_id}`}
                      format="yyyy-MM-dd"
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: 120 }}
                      name="medicine_date"
                      value={row.medicine_date}
                      onChange={(event) => storeInfo(event, index)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Button
                      variant="outlined"
                      sx={{
                        px: "0.5rem",

                        mx: "0.5rem",

                        color: "#FFFFFF",

                        bgcolor: "#F21010",

                        textTransform: "none",

                        borderRadius: "10px",

                        "&:hover": { background: "#F21010" },
                      }}
                      className="gilroy"
                      onClick={() => handleRemoveRow(index, row.medicine_id)}
                    >
                      {" "}
                      Remove{" "}
                    </Button>{" "}
                  </TableCell>{" "}
                </TableRow>
              ) : (
                <TableRow key={row.medicine_id} className="normal">
                  {" "}
                  <TableCell component="th" scope="row" align="center">
                    <TextField
                      sx={{ width: "100%" }}
                      id="standard-basic"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      name="medicine"
                      value={row.medicine}
                      onChange={(event) => storeInfo(event, index)}
                    />
                  </TableCell>{" "}
                  <TableCell align="center">
                    <ThemeProvider theme={theme}>
                      <Select
                        sx={{ maxWidth: "100%" }}
                        multiple
                        value={row.medicince_time}
                        onChange={(e) => handleChange(e, row, index)}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        <MenuItem value="Before Breakfast">
                          <Checkbox
                            checked={
                              row.medicince_time.indexOf("Before Breakfast") >
                              -1
                            }
                          />

                          <ListItemText primary="Before Breakfast" />
                        </MenuItem>

                        <MenuItem value="Breakfast">
                          <Checkbox
                            checked={
                              row.medicince_time.indexOf("Breakfast") > -1
                            }
                          />

                          <ListItemText primary="Breakfast" />
                        </MenuItem>

                        <MenuItem value="Lunch">
                          <Checkbox
                            checked={row.medicince_time.indexOf("Lunch") > -1}
                          />

                          <ListItemText primary="Lunch" />
                        </MenuItem>

                        <MenuItem value="3/3:30pm">
                          <Checkbox
                            checked={
                              row.medicince_time.indexOf("3/3:30pm") > -1
                            }
                          />

                          <ListItemText primary="3/3:30pm" />
                        </MenuItem>

                        <MenuItem value="Dinner">
                          <Checkbox
                            checked={row.medicince_time.indexOf("Dinner") > -1}
                          />

                          <ListItemText primary="Dinner" />
                        </MenuItem>

                        <MenuItem value="Bed">
                          <Checkbox
                            checked={row.medicince_time.indexOf("Bed") > -1}
                          />

                          <ListItemText primary="Bed" />
                        </MenuItem>
                      </Select>
                    </ThemeProvider>
                  </TableCell>{" "}
                  <TableCell component="th" scope="row" align="center">
                    <TextField
                      sx={{ maxWidth: "100%" }}
                      id="standard-basic"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      name="prescribed_by"
                      value={row.prescribed_by}
                      onChange={(event) => storeInfo(event, index)}
                    />
                  </TableCell>{" "}
                  <TableCell align="center">
                    <TextField
                      sx={{ maxWidth: "100%" }}
                      id="standard-basic"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      name="notes"
                      value={row.notes}
                      onChange={(event) => storeInfo(event, index)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      sx={{ maxWidth: "100%" }}
                      id="standard-basic"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      name="provided_by"
                      value={row.provided_by}
                      onChange={(event) => storeInfo(event, index)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      type="date"
                      id={`input-with-sx-${row.medicine_id}`}
                      format="yyyy-MM-dd"
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: 120 }}
                      name="medicine_date"
                      value={row.medicine_date}
                      onChange={(event) => storeInfo(event, index)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      sx={{
                        px: "0.5rem",

                        mx: "0.5rem",

                        color: "#FFFFFF",

                        bgcolor: "#F21010",

                        textTransform: "none",

                        borderRadius: "10px",

                        "&:hover": { background: "#F21010" },
                      }}
                      className="gilroy"
                      onClick={() => handleRemoveRow(index, row.medicine_id)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid justifyContent="center" align="center" mt={4}>
        <Button
          variant="outlined"
          sx={{
            px: "0.5rem",
            mx: "0.5rem",
            color: "#FFFFFF",
            bgcolor: "#4C9F70",
            textTransform: "none",
            borderRadius: "10px",
            "&:hover": { background: "#4C9F70" },
          }}
          className="gilroy "
          onClick={handleAddRow}
        >
          Add
        </Button>
      </Grid>

      <Grid container justifyContent="flex-end" sx={{ mt: "5rem" }}>
        <Grid item>
          <Button
            variant="outlined"
            sx={{
              px: "2rem",
              mx: "0.5rem",
              color: "#FFFFFF",
              bgcolor: "#909090",
              textTransform: "none",
              // borderRadius: "10px",
              "&:hover": { background: "#909090" },
            }}
            className="gilroy "
            onClick={() =>
              history.push(
                `/ParticipantsMedicalRecord/${camp_id}/${applicationId}`
              )
            }
          >
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="outlined"
            sx={{
              px: "2rem",
              mx: "0.5rem",
              color: "#FFFFFF",
              bgcolor: "#379592",
              textTransform: "none",
              // borderRadius: "10px",
              "&:hover": { background: "#379592" },
            }}
            className="gilroy "
            onClick={() => handleSave("Save")}
          >
            Save
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="outlined"
            sx={{
              px: "2rem",
              mx: "0.5rem",
              color: "#FFFFFF",
              bgcolor: "#379592",
              textTransform: "none",
              // borderRadius: "10px",
              "&:hover": { background: "#379592" },
            }}
            className="gilroy "
            onClick={() => handleSubmit()}
            disabled={MedicalHistory.length === 0}
          >
            Send to Parent
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
