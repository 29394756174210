import React, { useState } from "react";
import Box from "@mui/material/Box";
import "../../index.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button/Button";
import { Link, useParams } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, alpha } from "@mui/material/styles";
import { apiService2 } from "../../Services/apiService";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import { CAMP_CHECKIN_LOG , SERVER_URL} from '../../Services/apiEndpints'

export default function MedicationMangerCheckInandOut() {
  const [checkInAndOutLog, setCheckInAndOutLog] = useState([]);
  const [reload, setReload] = React.useState(false);

  let { camp_id, applicationId } = useParams();

  React.useEffect(() => {
    (async () => {
      // setLoading(true);
      let gettran = {
        applicationId: applicationId,
        campId: camp_id,
        userId: localStorage.getItem("userId"),
        checkInType:"MM"
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/actcamp/camp_check_in_log",
        url: `${SERVER_URL}${CAMP_CHECKIN_LOG}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);

      const checkinandOutDataList = login.data.responseData[0];
      // setLoading(false);

      console.log(checkinandOutDataList);

      setCheckInAndOutLog(checkinandOutDataList);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);


  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // hide last border

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,

      backgroundColor: "rgba(55, 149, 146, 1)",

      color: theme.palette.common.white,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return (
    <>
      <Grid container direction="row">
        <Grid container display="flex" justifyContent="space-between">
          <Grid>
            <Link to={`/ApplicantsReviewforMedication/${camp_id}/${applicationId}`}>
              <Box
                mt="0.65rem"
                alignSelf="center"
                component="img"
                sx={{
                  height: "0.75rem",
                  width: "0.65rem",
                  cursor: "pointer",
                }}
                height="20"
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>
          </Grid>
          <Grid item>
            <Box
              className="gilroy"
              alignSelf="center"
              sx={{ textAlign: "center" }}
            >
              Check In/Out Record
            </Box>
          </Grid>

          <Grid item>
            {/* <Box
              className="gilroy"
              alignSelf="center"
              sx={{ fontSize: "1.2rem" }}
            >
              <Button
                variant="contained"
                sx={{
                  px: "1rem",
               
                  backgroundColor:
                    checkInAndOutLog &&
                    checkInAndOutLog.length &&
                    checkInAndOutLog[0].camp_check_in === "Check In"
                      ? "#F21010"
                      : "#4C9F70",
                  textTransform: "none",
                  "&:hover": {
                    background:
                      checkInAndOutLog &&
                      checkInAndOutLog.length &&
                      checkInAndOutLog[0].camp_check_in === "Check In"
                        ? "#F21010"
                        : "#4C9F70",
                  },
                  width: "120px",
                }}
                className="gilroy"
                onClick={() => handleCheckIn()}
              >
          
              </Button>
            </Box> */}
          </Grid>
        </Grid>
        {/* </Grid> */}
      </Grid>

      <Grid mt={5}>
        <Box>
          Name:
          {checkInAndOutLog &&
            checkInAndOutLog.length &&
            checkInAndOutLog[0].childName}
        </Box>
      </Grid>

      <TableContainer component={Paper} sx={{ my: "2rem", mt: "5rem" }}>
        <Table sx={{ minWidth: 450 }} aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">Time</StyledTableCell>
              <StyledTableCell align="center">Camp Staff</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {checkInAndOutLog.map((row, index) => (
              <TableRow
                sx={{
                  "& .MuiTableCell-root": { py: "0.45rem" },
                }}
              >
                <StyledTableCell component="th" scope="row">
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <Box alignSelf="center">{row?.checkedDate}</Box>
                  </Stack>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <Box alignSelf="center"> {row?.checkedTime}</Box>
                  </Stack>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <Box alignSelf="center"> {row?.userName}</Box>
                  </Stack>
                </StyledTableCell>

                <StyledTableCell component="th" scope="row" align="center">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      my="0.5rem"
                      sx={{
                        alignItems: "flex-end",
                        color:
                          row.camp_check_in === "Yes" ? "#4C9F70":  "#F21010" ,
                      }}
                    >
                      {row?.camp_check_in === "Yes" ? "Check In" : "Check Out"}
                    </Box>
                  </Stack>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
