import React, { useEffect, useState, useRef } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Grid, Stack } from "@mui/material";
import "../App.css";
import Swal from "sweetalert2";
import { apiService, apiService2 } from "../Services/apiService";
import { useHistory } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Link, useParams } from "react-router-dom";
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png";
import Circle from "../Assets/Icons/Icons/Circle.png";
import SelectedCircle from "../Assets/Icons/Icons/SelectedCircle.png";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import cameraIcon from "../Assets/Icons/Icons/cameraIcon.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useForm, Controller } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import { FormHelperText } from "@mui/material";
import {
  SERVER_URL,
  GET_PROFILE,
  UPLOAD_PROFILE_PIC,
  UPDATE_STAFF_PROFILE,
} from "../Services/apiEndpints";

// let userId = localStorage.getItem('userId')
let rolesName = localStorage.getItem("rolesName");
let rolesArr = [];

if (rolesName === "CAMP MANAGER") {
  rolesArr = [
    {
      // rolesId: 9,
      roles_group: "STAFF",
      rolesName: "Camp Staff",
    },
  ];
} else if (rolesName === "ADMIN" || rolesName === "CAMP ADMIN") {
  rolesArr = [
    {
      // rolesId: 9,
      roles_group: "STAFF",
      rolesName: "Camp Staff",
    },
    {
      // rolesId: 9,
      roles_group: "STAFF",
      rolesName: "Camp Manager",
    },
  ];
}

export default function ProfileScreen1() {
  const history = useHistory();
  const current = new Date();
  const [showPassword, setShowPassword] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [today, setToday] = React.useState(null);
  const [thumbnailFile, setThumbnailFile] = React.useState(null);
  const [profilePath, setProfilePath] = React.useState(null);
  const [username, setUserName] = React.useState(null);
  const [viewData, setViewData] = React.useState({});
  const [title, setTitle] = React.useState("");

  let { userId } = useParams();
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    Gender: "",
    Address: "",
    contactNo: "",
    title: "",
    DOB: "",
    allergyInfo: "",
    dietaryInfo: "",
    shirtSize: "",
  });
  // let rolesName = localStorage.getItem("rolesName");
  
  const [formErrors, setFormErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    Gender: null,
    Address: null,
    contactNo: null,
    title: null,
    allergyInfo: null,
    dietaryInfo: null,
    shirtSize: null,
    DOB: null,
  });
  let rolesName = localStorage.getItem("rolesName");
  
  const validateForm = () => {
    const errors = {};
    Object.keys(form).forEach((name) => {
      const value = form[name];
    });
    // return errors;
  };
  React.useEffect(() => {
    var date = new Date();
    var day = date.getTime() - 1 * 24 * 60 * 60 * 1000;
    date.setTime(day);
    const newDateVal = formatDate(new Date(date).toISOString().substr(0, 10));
    setToday(newDateVal);
  }, []);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    let formObj = {};

    formObj = { ...form, [name]: value };

    setForm(formObj);
    validateField(name, formObj[name]);
  };
  const validateField = (name, value) => {
    let errorMsg = null;
    switch (name) {
      case "contactNo":
        if (!value) errorMsg = "Please enter contact number.";
        else if (!/^[0-9]{9,10}$/.test(value)) {
          errorMsg = "Contact number should be 9 or 10 digits.";
        }
        break;
    }
    setFormErrors({ ...formErrors, [name]: errorMsg });
    return errorMsg;
  }
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const doSomething = async (value) => {
    var date = new Date(value);
    var day = date.getTime() - 10 * 24 * 60 * 60 * 1000;
    date.setTime(day);
    const newDateVal = formatDate(new Date(date).toISOString().substr(0, 1));
    setToday(newDateVal);
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  // React.useEffect(() => {
  //   (async () => {
  //     getProfileDetails();
  //   })()
  //     .catch((e) => {
  //       console.error(e);
  //     })
  //     .finally(() => {});
  // }, []);

  const getProfileDetails = async () => {
    let apiInfo = {
      // url: `${process.env.REACT_APP_API_URL}auth/getprofile`,
      url: `${SERVER_URL}${GET_PROFILE}`,

      method: "POST",
      postData: {
        email: localStorage.getItem("email"),
        userId: localStorage.getItem("userId"),
      },
    };
    let response = await apiService(apiInfo);
    console.log(response.data.responseData[0][0]);

    if (response?.data?.responseCode === 0) {
      let dummy = {
        firstName: response.data.responseData[0][0]["firstName"],
        lastName: response.data.responseData[0][0]["Lastname"],
        email: response.data.responseData[0][0]["email"],
        rolesName: response.data.responseData[0][0]["rolesName"],
      };
      setProfilePath(response.data.responseData[0][0]["profileimage"]);

      setForm({
        ...form,

        firstName: response.data.responseData[0][0]["firstName"],
        lastName: response.data.responseData[0][0]["Lastname"],
        email: response.data.responseData[0][0]["email"],
        rolesName: response.data.responseData[0][0]["rolesName"],
        Address: response.data.responseData[0][0]["Address"],
        Gender: response.data.responseData[0][0]["Gender"],
        contactNo: response.data.responseData[0][0]["contactNo"],
        title: response.data.responseData[0][0]["title"],
        allergyInfo: response.data.responseData[0][0]["allergyInfo"],
        dietaryInfo: response.data.responseData[0][0]["dietaryInfo"],
        DOB: conversion(response.data.responseData[0][0]["DOB"]),
        shirtSize: response.data.responseData[0][0]["shirtSize"],
      });
      setViewData(dummy);
      let names = response.data.responseData[0][0]["profileName"];
      setUserName(names);
    }
  };

  const { handleSubmit } = useForm({
    defaultValues: async () => await getProfileDetails(),
  });
  const onSubmit = async (data) => {
    data["shirtSize"] = form.shirtSize;
    data["title"] = form.title;
    data["address"] = form.Address;
    data["mobile"] = form.contactNo;
    data["firstName"] = form.firstName;
    data["gender"] = form.Gender;
    data["lastName"] = form.lastName;
    data["allergyInfo"] = form.allergyInfo;
    data["dietaryInfo"] = form.dietaryInfo;
    data["DOB"] = form.DOB;
    data["email"] = form.email;
    data["rolesName"] = form.rolesName;
    data["userId"] = localStorage.getItem("userId");

    console.log(JSON.stringify(data, null, 2));
    if (data) {
      let apiInfo = {
        // url: `${process.env.REACT_APP_API_URL}auth/UpdateStaffProfile`,
        url: `${SERVER_URL}${UPDATE_STAFF_PROFILE}`,
        method: "POST",
        postData: JSON.stringify(data, null, 2),
        headers : {
          "Content-Type": "application/json",
          tokenheaderkey:localStorage.getItem("token"),
        },
      };
      let register = await apiService(apiInfo);
    }
    history.push("/ProfileScreen2");
  };
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  async function uploadThumnails(file) {
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (file.size > maxSize) {
        Swal.fire({
            icon: 'error',
            confirmButtonText: "OK",
            confirmButtonColor: "#379592",
            text: 'Please select image size less than 2 MB.',
        });
        return; // Exit the function
    }

    const forminputsTwo = new FormData();
    forminputsTwo.append("image", file);
    forminputsTwo.append("userId", localStorage.getItem("userId"));

    console.log(forminputsTwo, "sjcakdjcbdcj");
    try {
        const response = await axios.post(
            `${SERVER_URL}${UPLOAD_PROFILE_PIC}`,
            forminputsTwo,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    tokenheaderkey: localStorage.getItem("token"),
                },
            }
        );
        console.log(response);
    } catch (error) {
        console.error(error);
    }

  }
  const theme2 = createTheme({
    palette: {
      primary: {
        main: "#379592", // Set your desired checkbox color
      },
    },
  });
  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },

    components: {
      MuiInputLabel: {
        styleOverrides: {
          asterisk: {
            color: "red",
            fontSize: 24,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": {
            fontFamily: "gilroyMedium",
            src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
          },
        },
      },
    },
  });
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file.name);
    const reader = new FileReader();
    setThumbnailFile(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);
    uploadThumnails(event.target.files[0]);
    // read file as data url

    reader.onload = (event) => {
      console.log(event.target.result);

      // called once readAsDataURL is completed

      setProfilePath(event.target.result);
    };
  };

  const conversion = (inputDate) => {
    if (inputDate) {
      var formattedDate = inputDate.replace(/\//g, '-');
      var dateParts = formattedDate.split('-');
      var day = dateParts[0];
      var month = dateParts[1];
      var year = dateParts[2];
      return year + '-' + month + '-' + day;
    }

  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        container
        direction="flex"
        justifyContent="center"
        mt={2}
        sx={{
          height: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Grid item>
          <Card sx={{ borderRadius: "20px", mx: "2rem" }}>
            <CardContent
              sx={{
                mx: "1.5rem",
                maxHeight: "800px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                component="form"
                xs={4}
                md={4}
                sm={6}
                noValidate
                autoComplete="off"
              >
                <Grid container>
                  <Grid item md={4}>
                    <Box sx={{ alignItems: "center" }}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Link className="/link2" to="/Profile">
                          <Box
                            // mt="0.65rem"
                            alignSelf="center"
                            component="img"
                            sx={{
                              height: "0.75rem",
                              width: "0.65rem",
                              cursor: "pointer",
                            }}
                            height="20"
                            alt="Logo"
                            src={ArrowBack}
                          ></Box>
                        </Link>
                        <Box ml={1}>Profile</Box>
                      </Stack>
                    </Box>
                    <Grid my={1}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png"
                      />
                      <Box>
                        {profilePath === null ? (
                          <Box className="main-div">
                            <Box
                              textAlign="center"
                              sx={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "50px",
                                color: "#fff",
                                paddingTop: "2rem",
                                fontSize: "20px",
                                backgroundColor: "#379592",
                              }}
                              className="bold"
                            >
                              {username}
                            </Box>
                            <Box className="camera-ico">
                              <img onClick={handleClick} src={cameraIcon} />
                            </Box>
                          </Box>
                        ) : (
                          <Box className="main-div">
                            <Box
                              mt="1rem"
                              component="img"
                              src={profilePath}
                              sx={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "50px",
                              }}
                            ></Box>
                            <Box className="camera-ico">
                              <img onClick={handleClick} src={cameraIcon} />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid item>
                      {console.log(form)}
                      <TextField
                        id="standard-basic"
                        label="First Name"
                        variant="standard"
                        name="firstName"
                        sx={{ width: "90%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={form.firstName}
                        onChange={handleChange}
                        onBlur={() => ("firstName", form.firstName)}
                      />
                    </Grid>
                    <Grid item mt={1}>
                      <TextField
                        id="standard-basic"
                        label="Last Name"
                        variant="standard"
                        name="lastName"
                        sx={{ width: "90%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={form.lastName}
                        onChange={handleChange}
                        onBlur={() => ("lastName", form.lastName)}
                      />
                    </Grid>
                    <Grid item mt={1}>
                      <TextField
                        id="standard-basic"
                        label="Email"
                        variant="standard"
                        name="email"
                        sx={{ width: "90%" }}
                        value={form.email}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        onBlur={() => ("email", form.email)}
                      />
                    </Grid>
                    <Grid item mt={1}>
                      <TextField
                        id="standard-basic"
                        label="Role"
                        variant="standard"
                        name="rolesName"
                        sx={{ width: "90%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={form.rolesName}
                        onChange={handleChange}
                        onBlur={() => ("rolesName", form.rolesName)}
                      />
                    </Grid>
                  </Grid>
                  <Grid md={8}>
                    <Grid container spacing={2}>
                      <Grid item md={6} my="0.5rem">
                        <Grid item>
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, width: "90%" }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Profession
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              label="Profession"
                              name="title"
                              value={form.title}
                              onChange={handleChange}
                              onBlur={() => ("title", form.title)}
                            >
                              <MenuItem value="Occupational Therapist">
                                Occupational Therapist
                              </MenuItem>
                              <MenuItem value="Speech Pathologist ">
                                Speech Pathologist
                              </MenuItem>
                              <MenuItem value="Therapy Assistant">
                                Therapy Assistant
                              </MenuItem>
                              <MenuItem value="Psychologist">
                                Psychologist
                              </MenuItem>
                              <MenuItem value="Therapy Student">
                                Therapy Student
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item my="1rem" ml={1}>
                          <TextField
                            id="standard-basic"
                            label="Mobile"
                            variant="standard"
                            name="contactNo"
                            sx={{ width: "80%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +61
                                </InputAdornment>
                              ),
                            }}
                            value={form.contactNo}
                            onChange={handleChange}
                            onBlur={() => ("mobile", form.contactNo)}
                          />
                          {formErrors.contactNo && (
                            <FormHelperText error>
                              {formErrors.contactNo}
                            </FormHelperText>
                          )}
                        </Grid>

                        <Grid item my="1rem" ml={1}>
                          <TextField
                            id="standard-basic"
                            label="Address"
                            variant="standard"
                            name="Address"
                            sx={{ width: "90%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={form.Address}
                            onChange={handleChange}
                            onBlur={() => ("Address", form.Address)}
                          />
                        </Grid>

                        <Grid item mt={2} ml={1}>
                          <Box my="0.50" className="Allergyinfo">
                            Allergy Information
                          </Box>
                          <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={3}
                            name="allergyInfo"
                            sx={{ width: "90%" }}
                            value={form.allergyInfo}
                            onChange={handleChange}
                            onBlur={() => ("allergyInfo", form.allergyInfo)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item md={6} mt={2}>
                        <Grid item ml={1}>
                          <TextField
                            type="date"
                            id="standard-basic"
                            label="DOB"
                            variant="standard"
                            name="DOB"
                            sx={{ width: "90%" }}
                            value={form.DOB}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={handleChange}
                            onBlur={() => ("DOB", form.DOB)}
                          />
                        </Grid>

                        <Grid item mt="0.5rem">
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, width: "90%" }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Shirt Size
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              label="shirtSize"
                              name="shirtSize"
                              value={form.shirtSize}
                              onChange={handleChange}
                              onBlur={() => ("shirtSize", form.shirtSize)}
                            >
                              <MenuItem value="Kids 6">Kids 6</MenuItem>
                              <MenuItem value="Kids 8">Kids 8</MenuItem>
                              <MenuItem value="Kids 10">Kids 10</MenuItem>
                              <MenuItem value="Kids 12">Kids 12</MenuItem>
                              <MenuItem value="Kids 14">Kids 14</MenuItem>
                              <MenuItem value="Ladies 6">Ladies 6</MenuItem>
                              <MenuItem value="Ladies 8">Ladies 8</MenuItem>
                              <MenuItem value="Ladies 10">Ladies 10</MenuItem>
                              <MenuItem value="Ladies 12">Ladies 12</MenuItem>
                              <MenuItem value="Ladies 14">Ladies 14</MenuItem>
                              <MenuItem value="Ladies 16">Ladies 16</MenuItem>
                              <MenuItem value="Mens XS">Mens XS</MenuItem>
                              <MenuItem value="Mens S">Mens S</MenuItem>
                              <MenuItem value="Mens M">Mens M</MenuItem>
                              <MenuItem value="Mens L">Mens L</MenuItem>
                              <MenuItem value="Mens XL">Mens XL</MenuItem>
                              <MenuItem value="Mens 2XL">Mens 2XL</MenuItem>
                              <MenuItem value="Mens 3XL">Mens 3XL</MenuItem>
                              <MenuItem value="Mens 5XL">Mens 5XL</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item ml={1}>
                          <ThemeProvider theme={theme2}>
                            <Box sx={{ color: "#707070" }}>Gender</Box>
                            <RadioGroup
                              row
                              name="Gender"
                              value={form.Gender}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="Male"
                                control={
                                  <Radio
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 10 },
                                    }}
                                  />
                                }
                                label="Male"
                              />
                              <FormControlLabel
                                value="Female"
                                control={
                                  <Radio
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 10 },
                                    }}
                                  />
                                }
                                label="Female"
                              />
                              <FormControlLabel
                                value="Prefer not to say"
                                control={
                                  <Radio
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 10 },
                                    }}
                                  />
                                }
                                label="Prefer not to say"
                              />
                            </RadioGroup>
                          </ThemeProvider>
                        </Grid>

                        {/* <Grid item >
                          <Box sx={{ color: "#707070" }}>Gender</Box>
                          <Stack direction="row" spacing={1}>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              onChange={handleChange}
                                onBlur={() => ("gender", form.Gender)}
                            >
                              <Box
                                component="img"
                                sx={{ height: "10px", width: "10px" }}
                                alt="Logo"
                              
                                src={
                                  form.Gender === "Male"
                                    ? SelectedCircle
                                    : Circle
                                }
                              ></Box>
                              <Box sx={{ fontSize: "12px" }}>Male</Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            
                            >
                              <Box
                                component="img"
                                sx={{ height: "10px", width: "10px" }}
                                alt="Logo"
                               
                                src={
                                  form.Gender === "Female"
                                    ? SelectedCircle
                                    : Circle
                                }
                              ></Box>
                              <Box sx={{ fontSize: "12px" }}>Female</Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              
                            >
                              <Box
                                component="img"
                                sx={{ height: "10px", width: "10px" }}
                                alt="Logo"
                                
                                src={
                                  form.Gender === "Other"
                                    ? SelectedCircle
                                    : Circle
                                }
                              ></Box>
                              <Box sx={{ fontSize: "12px" }}>
                                Prefer not to say
                              </Box>
                            </Stack>
                          </Stack>
                        </Grid> */}
                        <Grid item mb={0.9} ml={0.5}>
                          <Box my="0.50">Dietary Information</Box>
                          <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={3}
                            sx={{ width: "90%" }}
                            name="dietaryInfo"
                            value={form.dietaryInfo}
                            onChange={handleChange}
                            onBlur={() => ("dietaryInfo", form.dietaryInfo)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {/* <Link to={`/ProfileScreen2`}> */}
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          px: "1rem",

                          bgcolor: "#379592",
                          textTransform: "none",
                          "&:hover": { background: "#379592" },
                        }}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Next
                      </Button>
                      {/* </Link> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
