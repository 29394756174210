import React from "react";

import Grid from "@mui/material/Grid";
import { Chip, CardContent, Box } from "@mui/material";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";

export default function Profile() {
  React.useEffect(()=>{
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
  },[])
  return (
    <Grid>
      Settings
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "#F8F8F8",

          color: "#707070",
          my: "0.75rem",
          border: 1,
          borderColor: "#379592",
          borderRadius: "0.75rem",
          py: "0rem",
          maxHeight: 60,
        }}
      >
        <CardContent>
          <Link to={`/ProfileScreen1`} className="link2">
            <Grid
              ml={4}
              container
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              color="#379592"
            >
             Update Profile
            </Grid>
          </Link>
        </CardContent>
      </Card>
    </Grid>
  );
}
