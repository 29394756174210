import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { Link, useHistory, useParams } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import "../../index.css";
import { apiService, apiService2 } from "../../Services/apiService";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { US8_URL , SERVER_URL} from '../../Services/apiEndpints'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const headers = [
  {
    heading: "Sl. No",
  },
  {
    heading: "Name",
  },
  {
    heading: "Role",
  },
  {
    heading: "Group",
  },
  {
    heading: "Primary Contact",
  },
  {
    heading: "Email",
  },
  {
    heading: "Phone",
  },
  {
    heading: "Secondary Contact",
  },
  {
    heading: "Email",
  },
  {
    heading: "Phone",
  },
];

export default function ContactDetails() {
  const [groupData, setGroupData] = useState("");
  const [campParticipantsInfo, setCampParticipantsfInfo] = useState([]);
  const selectedCampDetails = useSelector((state) => state?.Camp?.campData);
  const history = useHistory();
  let { camp_id } = useParams();

  React.useEffect(() => {
    (async () => {
      // setLoading(true);
      let gettran = {
        _methodId: "62",
        userId: localStorage.getItem("userId"),
        campId: camp_id,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/us8",
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);

      const CampDataList = login.data.responseData[0];
      // setLoading(false);

      console.log("CampDataListtttttt", CampDataList);

      setGroupData(CampDataList);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);
  function exportToExcel(data) {
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
  
   
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
   
  
    // Generate a file name
    const filename = 'Contact List.xlsx';
  
   
  
    // Save the workbook as a file
    XLSX.writeFile(workbook, filename);
  }
  console.log("groupData", groupData);

  return (
    <>
      <Grid container display="flex" sx={{ mt: "2rem" }}>
        <Grid item xs={12} sm={6} md={9}>
          <Stack direction="row" spacing={1}>
            <Link to="/CampListContactList" className="link2">
              <Box
                alignSelf="center"
                component="img"
                sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>

            <Box alignSelf="center" px="1rem">
              {groupData[0]?.campName}

            </Box>
          </Stack>
        </Grid>
        {console.log('groupDataincode', groupData )}
 {/* {groupData && 
 <Grid item xs={12} sm={6} md={3} container justifyContent="center">
   
 <CSVLink
   data={groupData}
   headers={headers}
   filename={"Contact Details"}
 >
   <Button
     variant="contained"
     sx={{
       color: "#FFFFFF",
       bgcolor: "#379592",
       textTransform: "none",
       "&:hover": { background: "#379592" },
       fontFamily: "gilroy",
       mx: "0.5rem",
     }}
     onClick={() => exportToExcel(data)}
   >
     Export Medical Record to XLS
   </Button>
 </CSVLink>

</Grid>

       
}  */}
<Button
     variant="contained"
     sx={{
       color: "#FFFFFF",
       bgcolor: "#379592",
       textTransform: "none",
       "&:hover": { background: "#379592" },
       fontFamily: "gilroy",
       mx: "0.5rem",
     }}
     onClick={() => exportToExcel(groupData)}
   >
     Export Contact List
   </Button>
      </Grid>

      {/* Table code starts here */}

      <TableContainer component={Paper} sx={{ marginTop: "3rem" }}>
        <Table sx={{ minWidth: 450 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {headers.map((row, index) => (
                <StyledTableCell align="center">{row.heading}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {console.log("groupData", groupData)}
            {groupData &&
              groupData.map((row, index) => (
                <TableRow
                  // key={row.userId}
                  className="normal"
                  sx={{
                    "& .MuiTableCell-root": { py: "0.35rem" },
                  }}
                >
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    // sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                  >
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row?.Name}</StyledTableCell>
                  <StyledTableCell align="center">{row?.Role}</StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                  >
                    {row?.GroupName}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row?.PrimaryContact}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                  >
                    {row?.PrimaryEmail}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                  >
                    {row?.PrimaryPhone}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                  >
                    {row?.SecondaryContact}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                  >
                    {row?.SecondaryEmail}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                  >
                    {row?.SecondaryPhone}
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
