import React from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Modal from '@mui/material/Modal';
import Stack from "@mui/material/Stack";
import "../index.css";
import Grid from "@mui/material/Grid";
import { alpha } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import BackgroundImage from "../Assets/Icons/Icons/Background-image.png"
import SearchIcon from '@mui/icons-material/Search';
import {Link} from "react-router-dom"
const SaveButton = styled(Button)(({ theme }) => ({
  textTransform:'none',
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#909090',
  '&:hover': {
    backgroundColor: '#909090',
  },
}));

const PublishButton = styled(Button)(({ theme }) => ({
  textTransform:'none',
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#379592',
  '&:hover': {
    backgroundColor: '#379592',
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
    textTransform:'none',
    // color: theme.palette.getContrastText(purple[500]),
    backgroundColor: 'white',
    color:'#379592',
    '&:hover': {
      backgroundColor: 'white',
      borderColor:'#379592'
    },
  }));
  

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const rowCount =[1,2,3,4]
const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});


export default function UploadingPhoto6() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Stack direction='row'>
        {/* <ArrowBackIosIcon sx={{color:'#909090'}}/> */}
        <Box sx={{fontSize:'1.5rem'}}>Add Description</Box>
        </Stack> 
    
          
          <Grid container display='flex' justifyContent='space-between' mr='1rem'>

          </Grid>  
         {rowCount.map(()=>(
            <Grid container display='flex' sx={{my:'2rem'}}>
          <Box
              component="img"
              sx={{  width: "20%" }}
              alt="Logo"
              src={BackgroundImage}
            ></Box>
            <Box alignSelf='center' sx={{width:'60%',ml:'2rem'}}>
            <TextField  sx={{borderColor:'#707070',width:'100%'}}
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={3}
        
          defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at scelerisque sapien. Nullam ut tempus elit, sed volutpat diam. In tortor lacus, commodo ut tempor eget."
        />
            </Box>
            
          </Grid>
         ))}
          
      <Grid container justifyContent='flex-end' my='2rem'>
<Grid item>
<SaveButton variant="contained" sx={{px:'2rem',mx:'1rem'}} className='gilroy'>Cancel</SaveButton>
</Grid>
<Link to='/UploadingPhoto' className='link'>
<Grid item><PublishButton variant="contained" className='gilroy' sx={{px:'2rem',mx:'1rem'}}>Save</PublishButton></Grid>
</Link>

      </Grid>
      <div>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          
          <Box>
            <Box textAlign='center' my='1rem'>Camp Leader</Box>
            <Paper
      component="form"
      sx={{border:1,mx:'1rem', display: 'flex', alignItems: 'center', width: 300, justifyContent:'center'}}
    >
      
      <InputBase
        sx={{ ml: 2, flex: 1 }}
        textAlign='center'
        placeholder="Search"
        inputProps={{ 'aria-label': 'search google maps' }}
      />
      <IconButton type="button" sx={{ py: '10px',px:'2rem' }} aria-label="search">
        <SearchIcon />
      </IconButton>
     
      
      
    </Paper>
    <Grid container display='flex' mx='1rem' my='1rem' >
    <AccountCircleIcon px='1rem'/>
    <AccountCircleIcon px='1rem' />
    <AccountCircleIcon  px='1rem'/>
    </Grid>
    
      <Divider orientation="horizontal" color='#379592'/>
      <Box sx={{my:'1rem',mx:'1rem'}}>
        <Stack direction='row' spacing={1} my='0.75rem'>
          <AccountCircleIcon/>
          <Box sx={{color:'#6C6C6C'}}>Name</Box>
        </Stack>
        <Stack direction='row' spacing={1} my='0.75rem'>
          <AccountCircleIcon/>
          <Box sx={{color:'#6C6C6C'}}>Name</Box>
        </Stack>
        <Stack direction='row' spacing={1} my='0.75rem'>
          <AccountCircleIcon/>
          <Box sx={{color:'#6C6C6C'}}>Name</Box>
        </Stack>
      </Box>
      <Box textAlign='center'>
      <PublishButton variant="contained" className='gilroy' sx={{px:'2rem',mx:'1rem'}}>Save</PublishButton>
      </Box>
          </Box>
        </Box>
      </Modal>
    </div>
      </div>
    </ThemeProvider>
  );
}
