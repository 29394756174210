import React from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Modal from '@mui/material/Modal';
import Stack from "@mui/material/Stack";
import "../../index.css";
import Grid from "@mui/material/Grid";
import { alpha } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import BackgroundImage from "../../Assets/Icons/Icons/Background-image.png"
import DefaultImage from "../../Assets/Icons/Icons/DefaultImage.png";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import SearchIcon from '@mui/icons-material/Search';
import {Link} from "react-router-dom"
const SaveButton = styled(Button)(({ theme }) => ({
  textTransform:'none',
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#909090',
  '&:hover': {
    backgroundColor: '#909090',
  },
}));

const PublishButton = styled(Button)(({ theme }) => ({
  textTransform:'none',
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#379592',
  '&:hover': {
    backgroundColor: '#379592',
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
    textTransform:'none',
    // color: theme.palette.getContrastText(purple[500]),
    backgroundColor: 'white',
    color:'#379592',
    '&:hover': {
      backgroundColor: 'white',
      borderColor:'#379592'
    },
  }));
  

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const rowCount =[1,2,3,4]
const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});


export default function AddDescription() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Stack direction='row' spacing={1}>
        <Link to="/CreateWelcomePack">
          <Box
            component="img"
            sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
            height="20"
            alt="Logo"
            src={ArrowBack}
          ></Box>
        </Link>
        <Box className="gilroy" alignSelf="center">
          Add Description
        </Box>
      </Stack>
    
          
         
         {rowCount.map(()=>(
            <Grid container display='flex' sx={{my:'2rem'}}>
          <Box
              component="img"
              sx={{  width: "15%" ,borderRadius:'10px' }}
              alt="Logo"
              src={DefaultImage}
            ></Box>
            <Box alignSelf='center' sx={{width:'60%',ml:'2rem'}}>
            <TextField  sx={{borderColor:'#707070',width:'100%'}}
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={3}
        
          defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at scelerisque sapien. Nullam ut tempus elit, sed volutpat diam. In tortor lacus, commodo ut tempor eget."
        />
            </Box>
            
          </Grid>
         ))}
          
      <Grid container justifyContent='flex-end' my='2rem'>
<Grid item>
<SaveButton variant="contained" sx={{px:'2rem',mx:'1rem'}} className='gilroy'>Cancel</SaveButton>
</Grid>
<Link to='/WelcomePackPublish' className='link2'>
<Grid item><PublishButton variant="contained" className='gilroy' sx={{px:'2rem',mx:'1rem'}}>Next</PublishButton></Grid>
</Link>

      </Grid>
      
      </Box>
    </ThemeProvider>
  );
}
