import React, { useState } from "react";
import Button from "@mui/material/Button";
import { FormControl, Chip, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";
import GreenCheck2 from "../../Assets/Icons/Icons/GreenCheck2.png";
import { useHistory } from "react-router-dom";
import "../../index.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const suitabilityStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function SupportStaffInvoiceSent() {
  const [invoiceOpen, setinvoiceOpen] = React.useState(false);
  const [suitabilityOpen, setSuitabilityOpen] = React.useState(false);
  const [age, setAge] = React.useState("");
  const invoiceHandleOpen = () => setinvoiceOpen(true);
  const invoiceHandleClose = () => setinvoiceOpen(false);
  const suitabilityHandleOpen = () => setSuitabilityOpen(true);
  const suitabilityHandleClose = () => setSuitabilityOpen(false);

  const history = useHistory();
  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    palette: {
      secondary: {
        main: "#379592",
      },
    },

    Muidisabled: {
      styleOverrides: {
        backgroundColor: "purple",
      },
    },
  });
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // hide last border

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,

      backgroundColor: "rgba(55, 149, 146, 1)",

      color: theme.palette.common.white,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const shoot = () => {
    Swal.fire({
      iconHtml: `<img src="${question}" width="100px" height="150px"  >`,
      text: "Are you sure you want to Submit?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#379592",
      cancelButtonText: "No",
      cancelButtonColor: "#909090",
      customClass: {
        container: "my-swal",
        icon:'no-border'
        
      },
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          //  text: 'Something went wrong!',
          iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
          text: "Submitted Succesfully!",

          confirmButtonText: "Yes",
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
            //    confirmButton: 'your-confirm-button-class',
          },
        }).then((result2) => {
          if (result2.value) {
            history.push("/ChangeSuitability");
          }
        });
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Grid container direction="flex" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <Link to="/ApplicantsReview" className="link2">
              <Box
                alignSelf="center"
                component="img"
                sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>

            <Box alignSelf="center">Rosy Application Status</Box>
          </Stack>
        </Grid>
        <TableContainer component={Paper} sx={{ my: "2rem" }}>
          <Table sx={{ minWidth: 450 }} aria-label="customized table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Funding</StyledTableCell>
                <StyledTableCell align="center">Suitability</StyledTableCell>
                <StyledTableCell align="center">Application</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": { py: "0.45rem" },
                }}
              >
                <StyledTableCell component="th" scope="row">
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <Box alignSelf="center">Invoice Sent</Box>
                    <Link className="link2" to="/ChangeSuitability">
                      <Chip
                        // label={card.campType.charAt(0).toUpperCase()+card.campType.slice(1).toLowerCase()}
                        label="View"
                        variant="outlined"
                        size="small"
                        className="medium"
                        mx="0.75rem"
                        sx={{
                          color: "#707070",
                          backgroundColor: "white",
                          fontSize: "0.75rem",
                          px: "0.5rem",
                          height: "25px",
                          cursor: "pointer",
                        }}
                        onClick={invoiceHandleOpen}
                      />
                    </Link>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Stack direction="row" justifyContent="center" spacing={1}>
                    <Box alignSelf="center">
                      Pending Suitability - Pre Screen
                    </Box>
                    <Link className="link2" to="/ChangeSuitability">
                      <Chip
                        // label={card.campType.charAt(0).toUpperCase()+card.campType.slice(1).toLowerCase()}
                        label="View"
                        variant="outlined"
                        size="small"
                        className="medium"
                        mx="0.75rem"
                        sx={{
                          color: "#707070",
                          backgroundColor: "white",
                          fontSize: "0.75rem",
                          px: "0.5rem",
                          height: "25px",
                          cursor: "pointer",
                        }}
                        onClick={suitabilityHandleOpen}
                      />
                    </Link>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                      In progress
                    </Box>
                    <Link to="/ReviewApplication" className="link2">
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: "#FF9529",
                          color: "white",
                          textTransform: "none",
                          "&:hover": { background: "#FF9529", color: "white" },
                          // width: "60%",
                          mx: "0.5rem",
                          my: "0.5rem",
                        }}
                        className="gilroy"
                      >
                        Review Application
                      </Button>
                    </Link>
                  </Stack>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Stack direction='column' spacing={1}  justifyContent='flex-end'> */}
        <Grid
          container
          justifyContent="flex-end"
          direction="column"
          alignContent="flex-end"
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: "#4C9F70",
              color: "white",
              textTransform: "none",
              "&:hover": { background: "#4C9F70", color: "white" },
              width: "20%",
              mx: "0.5rem",
              my: "0.5rem",
            }}
            className="gilroy"
          >
            Confirm Camp Place
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#379592",
              textTransform: "none",
              "&:hover": { color: "#379592" },
              width: "20%",
              mx: "0.5rem",
              my: "0.5rem",
            }}
            className="gilroy"
          >
            Recent files
          </Button>
        </Grid>
        <Grid>
          <Modal
            open={invoiceOpen}
            onClose={invoiceHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Grid
                container
                justifyContent="flex-Start"
                direction="row"
                alignContent="flex-Start"
              >
                  <Link to="/serviceAgreement" className="link2">
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#718F94",
                    color: "white",
                    textTransform: "none",
                    borderColor:"#707070",
                    "&:hover": { background: "#718F94", color: "white" },
                    width: "95%",
                    mx: "0.5rem",
                    my: "0.5rem",
                  }}
                  className="gilroy"
                >
                  Send Agreement
                </Button>
                </Link>
                
                <Link to="/serviceAgreement" className="link2">
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#718F94",
                    color: "white",
                    borderColor:"#707070",
                    textTransform: "none",
                    "&:hover": { background: "#718F94", color: "white" },
                    width: "95%",
                    // height:"25%",
                    mx: "0.5rem",
                    my: "0.5rem",
                  }}
                  className="gilroy"
                >
                 Send Invoice
                </Button>
                </Link>

                <FormControl sx={{ m: 1, minWidth: 215 }} size="small">
                  <InputLabel id="demo-select-small">
                    Choose Funding Status
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={age}
                    label="Choose Funding Status"
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Agreement Sent</MenuItem>
                    <MenuItem value={20}>Agreement Signed</MenuItem>
                    <MenuItem value={30}>Invoice Sent</MenuItem>
                    <MenuItem value={30}>Funding Confirmed</MenuItem>
                    <MenuItem value={30}>Funding Declined</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      mt: "0.75rem",
                      width: "100%",
                      height: "20%",
                      mb: "1rem",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={3}
                    label="Enter Remarks..."
                  />
                </Box>
              </Grid>
              <Box className="gilroy">Previous Comments</Box>
              <Grid>
                <TableContainer
                  component={Paper}
                  sx={{ my: "2rem", mt: "0.5rem" }}
                >
                  <Table sx={{ minWidth: 450 }} aria-label="customized table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">Date</StyledTableCell>

                        <StyledTableCell align="center">
                          Camp Manager
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Funding Status
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Remarks
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": { py: "0.45rem" },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center">21/03/2023</Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center">James Jordon</Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                              Agreement Sent
                            </Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center">
                              Awaiting response from Parent
                            </Box>
                          </Stack>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": { py: "0.45rem" },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box
                              my="0.5rem"
                              sx={{ alignItems: "flex-end" }}
                            ></Box>
                          </Stack>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": { py: "0.45rem" },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box
                              my="0.5rem"
                              sx={{ alignItems: "flex-end" }}
                            ></Box>
                          </Stack>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": { py: "0.45rem" },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box
                              my="0.5rem"
                              sx={{ alignItems: "flex-end" }}
                            ></Box>
                          </Stack>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": { py: "0.45rem" },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box
                              my="0.5rem"
                              sx={{ alignItems: "flex-end" }}
                            ></Box>
                          </Stack>
                        </StyledTableCell>
                      </TableRow>
                      {/* <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              
              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
              
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container sx={{ justifyContent: "center" }}>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      px: "2rem",
                      mx: "0.5rem",
                      bgcolor: "#909090",
                      textTransform: "none",
                      "&:hover": { background: "#909090" },
                    }}
                    className="gilroy"
                    onClick={invoiceHandleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className="gilroy"
                    onClick={shoot}
                    sx={{
                      px: "2rem",
                      mx: "0.5rem",
                      bgcolor: "#379592",
                      "&:hover": { background: "#379592" },
                      textTransform: "none",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
        <Grid>
        <Modal
            open={suitabilityOpen}
            onClose={suitabilityHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={suitabilityStyle}>
              <Grid
                container
                justifyContent="flex-Start"
                direction="row"
                alignContent="flex-Start"
              >
              
                <FormControl sx={{ minWidth: 225 }} size="small">
                  <InputLabel id="demo-select-small">
                  Choose Suitability Status
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={age}
                    label="Choose Suitability Status"
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Pending Suitability - Pre Screen</MenuItem>
                    <MenuItem value={20}>Pending Suitabilty - Other</MenuItem>
                    <MenuItem value={30}>Suitability Confirmed</MenuItem>
                    <MenuItem value={30}>Funding Confirmed</MenuItem>
                    <MenuItem value={30}>Suitability Declined</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      mt: "0.5rem",
                      width: "100%",
                      height: "20%",
                      mb: "0.5rem",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={2}
                    label="Enter Remarks..."
                  />
                </Box>
              </Grid>
              <Box className="gilroy">Previous Comments</Box>
              <Grid>
                <TableContainer
                  component={Paper}
                  sx={{ my: "2rem", mt: "0.5rem" }}
                >
                  <Table sx={{ minWidth: 450 }} aria-label="customized table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">Date</StyledTableCell>

                        <StyledTableCell align="center">
                          Camp Manager
                        </StyledTableCell>

                        <StyledTableCell align="center">
                        Suitability Status
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Remarks
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": { py: "0.45rem" },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center">21/03/2023</Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center">James Jordon</Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                            Pending Suitability- Pre Screen
                            </Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center">
                              Awaiting response from Parent
                            </Box>
                          </Stack>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": { py: "0.45rem" },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box
                              my="0.5rem"
                              sx={{ alignItems: "flex-end" }}
                            ></Box>
                          </Stack>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": { py: "0.45rem" },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box
                              my="0.5rem"
                              sx={{ alignItems: "flex-end" }}
                            ></Box>
                          </Stack>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": { py: "0.45rem" },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box
                              my="0.5rem"
                              sx={{ alignItems: "flex-end" }}
                            ></Box>
                          </Stack>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": { py: "0.45rem" },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Box alignSelf="center"></Box>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box
                              my="0.5rem"
                              sx={{ alignItems: "flex-end" }}
                            ></Box>
                          </Stack>
                        </StyledTableCell>
                      </TableRow>
                      {/* <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              
              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
              
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container sx={{ justifyContent: "center" }}>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      px: "2rem",
                      mx: "0.5rem",
                      bgcolor: "#909090",
                      textTransform: "none",
                      "&:hover": { background: "#909090" },
                    }}
                    className="gilroy"
                    onClick={suitabilityHandleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className="gilroy"
                    onClick={shoot}
                    sx={{
                      px: "2rem",
                      mx: "0.5rem",
                      bgcolor: "#379592",
                      "&:hover": { background: "#379592" },
                      textTransform: "none",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
