import React, { useState } from "react";
import Button from "@mui/material/Button";

import {
    CardActionArea,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Chip,
    CardContent,
    Divider,
    Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";


import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png"
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { apiService, apiService2 } from "../../Services/apiService";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { GET_CHILDPARTICIPANTFILES, SERVER_URL } from '../../Services/apiEndpints'
import download from 'downloadjs';

export default function RecentFiles() {

    let { campId, applicantId } = useParams()
    const [files, setFiles] = React.useState([])

    const theme = createTheme({
        typography: {
            fontFamily: "gilroyMedium",
        },
        palette: {
            secondary: {
                main: "#379592",
            },
        },

        Muidisabled: {
            styleOverrides: {
                backgroundColor: "purple",
            },
        },
    });



    const rows = [
        { date: '21/03/2023', fileName: 'eligibilityrequirment.pdf', fileSize: '1.2 MB', action: '' },
        { date: '21/03/2023', fileName: 'Covidcertificate.jpg', fileSize: '100 KB', action: '' }

    ]


    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: theme.palette.common.black,
            backgroundColor: "rgba(55, 149, 146, 1)",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    React.useEffect(() => {
        (
            async () => {

                let dataSent = {
                    userId: localStorage.getItem('userId'),
                    applicationId: applicantId

                }

                let apiInfo = {
                    // url: "https://cncdevapi.natsoft.io/api/child/getchildparticipantfiles",
                    url: `${SERVER_URL}${GET_CHILDPARTICIPANTFILES}`,
                    method: "POST",
                    postData: dataSent,
                    headers: {
                        "Content-Type": "application/json",
                        tokenheaderkey: localStorage.getItem("token"),
                    },

                }

                let output = await apiService2(apiInfo)
                console.log(output.data.responseData[0])
                setFiles(output.data.responseData[0])



            }
        )()
    }

        , [])

    const generatePDF = (name) => {

        // Get the element you want to convert to PDF (e.g., a div with id "my-element")
        const element = document.getElementById('my-element');
        // Use html2canvas to convert the element to a canvas
        html2canvas(element).then((canvas) => {
            // Create a new instance of jsPDF
            const doc = new jsPDF();
            // Calculate the width and height of the PDF document
            const width = doc.internal.pageSize.getWidth();
            const height = doc.internal.pageSize.getHeight();
            // Add the canvas to the PDF document
            doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, width, height);
            console.log(doc)
            // Save the PDF
            doc.save(name);
        });
    };

    function handleDownload( fileUrl, fileName) {
        download(fileUrl, fileName);
        
        //   const link = document.createElement('a');
        //   link.href = 'https://cncdevapi.natsoft.io/file/466c34e3-f4c8-4386-b9a1-59ef7bb74626.pdf';
        //   link.download = 'name';
        //   link.click();
        

    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box>
                <Stack direction='row' sx={{ mt: '0.75rem' }}>
                    <Link to={`/ChangeSuitability/${campId}/${applicantId}`}>
                        <Box
                            alignSelf="center"
                            component="img"
                            sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
                            alt="Logo"
                            src={ArrowBack}
                        ></Box>
                    </Link>
                    <Box sx={{ margin: 'auto' }}>Recent Files</Box>
                </Stack>
                <TableContainer component={Paper} sx={{ my: '2rem' }}>
                    <Table sx={{ minWidth: 450 }} aria-label="customized table"  >

                        <TableHead >

                            <StyledTableRow>
                                <StyledTableCell align='center'>Date</StyledTableCell>
                                <StyledTableCell align="center">File name</StyledTableCell>
                                {/* <StyledTableCell align="center">File Size</StyledTableCell> */}
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </StyledTableRow>

                        </TableHead>
                        <TableBody >
                            {files && files.map((row, index) => (
                                <TableRow key={index} sx={{
                                    "& .MuiTableCell-root": { py: "0.45rem" },
                                }}>
                                    <StyledTableCell align="center" >
                                        {row.uploadedDate}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.fileName}
                                    </StyledTableCell>
                                    {/* <StyledTableCell align="center">
                                        {row.fileSize}
                                    </StyledTableCell> */}
                                    <StyledTableCell align="center">
                                        <Stack direction='row' justifyContent='space-around' sx={{ color: '#718F94' }}>

                                            <Box sx={{ cursor: 'pointer' }}>
                                                <a href={row.viewUrl} target="_blank">View</a>
                                            </Box>
                                            <Box sx={{ cursor: 'pointer' }}>
                                                <a href={row.downloadUrl} target="_blank">Download</a>
                                            </Box>
                                    {/* <Box onClick={()=>{handleDownload(row.downloadUrl,row.fileName)}} sx={{ cursor: 'pointer' }}>
                                        
                                    Download File
                                    </Box> */}
                                            {/* <div sx={{cursor:'pointer'}}
                                            
                                             >
                                               
                                               <a 
                                               href={row.downloadUrl}
                                            
                                                target="_blank" 
                                                >
                                                    Download
                                                    </a>
                                            </div> */}
                                            {/* <Button onClick={()=>{handleDownload(row.downloadUrl,row.fileName)}}>
                                                Download File
                                            </Button> */}
                                            <div>

                                            </div>


                                            {/* <Box>Remove</Box> */}
                                        </Stack>
                                    </StyledTableCell>
                                </TableRow>
                            ))}


                        </TableBody>
                    </Table>
                </TableContainer>


            </Box>
        </ThemeProvider>

    );
}
