import React from "react";
import { useState, useRef } from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material/styles";
import Swal from "sweetalert2";
import editIcon from "../Assets/Icons/Icons/edit.png";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useParams } from "react-router-dom";
import Searchdvance from "../Assets/Icons/Icons/searchadvanced.png";
import "../index.css";

import Grid from "@mui/material/Grid";
import Add from "../Assets/Icons/Icons/add.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import {
  apiService,
  apiService2,
  apiServiceImage,
} from "../Services/apiService";
import { BrowserRouter, Link, useHistory } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import Minus from "../Assets/Icons/Icons/Minus.png";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import { ContactsOutlined } from "@material-ui/icons";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import User from "../Assets/Icons/Icons/User.png";
import InputAdornment from "@mui/material/InputAdornment";
import moment from "moment";
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png";
import CrossGreen from "../Assets/Icons/Icons/CrossGreen.png";
import sscross from "../Assets/Icons/Icons/sscross.png";
import crossss from "../Assets/Icons/Icons/crossss.png";
import {
  SERVER_URL,
  GETLIST_STAFF,
  FILE_UPLOAD,
  VIEW_CAMP,
  EDIT_CAMP,
  SENDMAIL_TO_CAMPSTAFF,
} from "../Services/apiEndpints";
import PdfIcon from "../Assets/Icons/Icons/PdfIcon.png";
import ThumbnailImage from "../Assets/Icons/Icons/ThumbnailImage.png"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  // backgroundColor: '#00000003',
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    // backgroundColor:'pink',
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#909090",

  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50ch",
      "&:focus": {
        width: "70ch",
      },
    },
  },
}));
const SaveButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  backgroundColor: "#909090",
  "&:hover": {
    backgroundColor: "#909090",
  },
}));

const PublishButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  backgroundColor: "#379592",
  "&:hover": {
    backgroundColor: "#379592",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const rowHead = [
  "Group",
  "Camp Manager",
  "Support Staff",
  "Therapist",
  "Medication Manager",
];

const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});

const campData = {
  campName: "",
  location: "",
  campStartdate: "",
  campRegStartdate: "",
  campRegEnddate: "",
  campType: "",
  campMinAge: "",
  campMaxAge: "",
  maxSpots: "",
  description: "",
  campFeeParticipants: "",
  campFeeSS: "",
  groupInfo: "",
  campEnddate: "",
  campHoursDatewise: "",
  campThumbnail: "",
};

export default function EditCamp() {
  let { campId } = useParams();
  console.log("campId999", campId);
  const [campStatus, setCampStatus] = React.useState(false);
  const [buttonState, setButtonState] = React.useState(false);
  const [filesPath, setFilePath] = useState();
  const [filesPathTwo, setFilePathTwo] = useState();
  const [imgsSrc, setImgsSrc] = useState([]);
  const [attachmentsArr, setAtachmentsArr] = useState([]);
  const [docPath, setDocPath] = useState([]);
  const [file, setFile] = useState();
  const [thumbnail, setThumbnail] = useState();
  const history = useHistory();
  const [groupError, setGroupError] = React.useState(false);
  const dateFormat = "dd/mm/yyyy";
  const [refDate, setRefDate] = useState({});
  const [selectedType, setSelectedType] = useState(null);
  const [search, setSearch] = useState("");
  const [rowId, setRowId] = useState("");
  const [seachName, setSearchName] = useState(null);
  const [seachName1, setSearchName1] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userId1, setUserId1] = useState([]);
  const [formData, setFormData] = React.useState(campData);
  const [list, setList] = React.useState([]);
  const [supportStafflist, setsupportStaffList] = React.useState([]);
  const [fileStatus, setFileStatus] = React.useState("Not uploaded");
  const [tableformData, setTableFormData] = useState([]);
  const [rows, setRows] = useState([]);
  const [row1, setRow1] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [allSupportStaffList, setAllSupportStaffList] = React.useState([]);
  const [today, setToday] = React.useState(null);
  const [groupinfo, setGroupInfo] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const hiddenFileInputThum = React.useRef(null);
  const [attachmentsStatus, setAttachmentsStatus] = useState("");
  const [thumbnailPath, setThumbnailPath] = useState(null);
  const inputRef = useRef(null);
  const [campHoursErr, setCampHoursErr] = React.useState(false);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [thumbnailDocPath, setThumbnailthumbnailDocPath] = useState(null);
  const [errorThumbnail, seterrorThumbnail] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const [thumbStatus, setThumbStatus] = React.useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [flist, setFlist] = React.useState([]);
  const [regDate, setRegDate] = React.useState(null);
  const [latestDate, setLatestDate] = React.useState(null);
  const [errMsg, setErrMsg] = React.useState(null);
  const [tableStart, setTableStart] = React.useState("");
  const [tableEnd, setTableEnd] = React.useState("");
  const [finalDateArray, setFinalDateArray] = React.useState([]);
  const [hoursTable, setHoursTable] = React.useState({});
  const [campErr, setCampErr] = React.useState("");
  const [regStart, setRegStart] = React.useState("");
  const [regEnd, setRegEnd] = React.useState("");
  const [regErr, setRegErr] = React.useState("");
  const [spotErr, setSpotErr] = React.useState("");

  const [dateAndHours, setDateAndHours] = React.useState([]);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = React.useState("");
  const [datesBetween, setDatesBetween] = useState([]);
  const [viewCampDate, setViewCampDate] = React.useState({});
  const [videosSrc, setVideosSrc] = React.useState([]);
  const [videoRow, setVideoRow] = React.useState([]);
  const [data, setData] = useState(/* Your initial data here */);

  // React.useEffect(() => {
  //   getCmpDetails();
  // }, []);

  const handleVideoRow = () => {
    setVideoRow([
      ...videoRow,
      {
        id: videoRow.length + 1,
        actualUrl: "",
        description: "",
        thumbnailurl: "",
        videoType: "PRE_CAMP",
        videoProvider: "vimeo",
      },
    ]);
  };

  const generateThumbnail = (e, batch) => {

    (async () => {
      console.log(batch, 'oooooooo')
      let videoUrl = e.target.value
      setVideoRow(
        videoRow.map((row, index) =>
          index + 1 === batch.id ? { ...row, actualUrl: e.target.value } : row
        )
      )
      let apiUrl = `https://vimeo.com/api/oembed.json?url=${videoUrl}`
      let res = await axios({
        url: apiUrl,
        method: 'get',
        timeout: 8000,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      console.log(res)

      if (res.status == 200)
        setVideoRow(
          videoRow.map((row, index) =>
            index + 1 === batch.id ? { ...row, thumbnailurl: res.data.thumbnail_url_with_play_button, actualUrl: e.target.value } : row
          )
        )
    })()
      .catch(() => {
        console.log('wohoooooooooo')
        setVideoRow(
          videoRow.map((row, index) =>
            index + 1 === batch.id ? { ...row, thumbnailurl: ThumbnailImage, actualUrl:  e.target.value } : row
          )
        )
       
      })
      .finally(() => {
        // setLoading(false);
      });

  };
  const generateStatic = (e,batch) =>{
    setVideoRow(
      videoRow.map((row, index) =>
        index + 1 === batch.id ? { ...row, actualUrl: e.target.value,thumbnailurl: ThumbnailImage} : row
      )
    )
  }
  const handleDesc = (e, item) => {
    setVideoRow(
      videoRow.map((row, index) =>
        index + 1 === item.id ? { ...row, description: e.target.value } : row
      )
    );
  };
  const removeUrlItem = (index) => {
    setVideoRow([...videoRow.slice(0, index), ...videoRow.slice(index + 1)]);
  };

  const getCampDetails = async () => {
    let editData = {
      userId: localStorage.getItem("userId"),
      campId: campId,
    };
    console.log("editData6666", editData);

    let editInfo = {
      // url: "https://cncdevapi.natsoft.io/api/camps/viewcamp",
      url: `${SERVER_URL}${VIEW_CAMP}`,
      method: "POST",
      postData: editData,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    };
    let editcmp = await apiService(editInfo);
    console.log("editcmp999999", editcmp?.data?.responseCode);
    if (+editcmp?.data?.responseCode === 0) {
      console.log(
        "editcmp?.data?.responseCode 898989=====@@@",
        editcmp?.data.responseData[0][0].groupInfo
      );
      const editCmdData = editcmp?.data.responseData[0][0];
      console.log(editCmdData.campStatus);

      setViewCampDate(editCmdData);
      setCampStatus(editCmdData.campStatus);
      setFlist(editCmdData.fileList && editCmdData.fileList);

      if (editCmdData.videoInfo !== null) {
        editCmdData.videoInfo = JSON.parse(editCmdData.videoInfo);
        let dummy = editCmdData.videoInfo;
        console.log(dummy, "dummmm");
        setVideoRow(
          editCmdData.videoInfo.map((row, index) => ({ ...row, id: index + 1 }))
        );
      } else {
        setVideoRow([]);
      }

      setVideosSrc(editCmdData.videoInfo || []);

      console.log("editCmdData.videoInfo$$$$$$", editCmdData.videoInfo);
      console.log("editCmdData.videoInfo$$$$$$", editCmdData.videoInfo);
      console.log(
        "editCmdData.campRegEndDateTime$$$$$$",
        editCmdData?.campRegEndDateTime
      );

      if (editCmdData?.campHoursDatewise) {
        let campHoursDatewise = JSON.parse(editCmdData?.campHoursDatewise);
        console.log(JSON.stringify(campHoursDatewise), "daaa");
        setDateAndHours(campHoursDatewise);
      }

      // var date = new Date(editCmdData?.campRegEndDateTime);

      // const newDateVal = formatDate(date.toISOString().substr(0, 10));

      if (
        editCmdData &&
        editCmdData.fileList &&
        editCmdData.fileList.length > 0
      ) {
        const images = [];
        const filepaths = [];
        editCmdData.fileList.filter((val) => {
          console.log(val);
          if (val.documentContent === "Thumbnail") {
            setThumbnailPath(val.documentPath);
            setThumbnailFile(val.documentHash);
          } else {
            // images.push(val.documentPath);
            // filepaths.push(val.documentHash);
            // // setImgsSrc([val.documentPath]);

            setAtachmentsArr((attachment) => [
              ...attachment,

              {
                path: val.documentPath,
                fileObj: null,
                docPath: val.documentHash,
                docTitle: val.documentTitle,
              },
            ]);
          }
        });

        setImgsSrc(images);
        setDocPath(filepaths);
        const file = editCmdData.fileList.filter(
          (val) => val.documentContent === "Thumbnail"
        );
        if (file && file.length > 0) {
          console.log(file);
          setThumbnailthumbnailDocPath(file[0].documentHash);
        }
      }

      //       var dateParts = editCmdData.campRegStartDateTime.split('/');
      // var day = dateParts[0];
      // var month = dateParts[1];
      // var year = dateParts[2];

      // // Reformatting the date
      // var reversedDate = day + '/' + month + '/' + year;

      // console.log(newDateVal);
      let campStart = moment(
        editCmdData.campStartDateTime,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      let campEnd = moment(editCmdData.campEndDateTime, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      let regStartDate = moment(
        editCmdData.campRegStartDateTime,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      let regEndDate = moment(
        editCmdData.campRegEndDateTime,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      const campData = {
        campName: editCmdData.campName,
        location: editCmdData.campLocation,
        campStartdate: campStart,
        campEnddate: campEnd,
        campRegStartdate: regStartDate,
        campRegEnddate: regEndDate,

        campType: editCmdData.campType,

        // campAgegroup: editCmdData?.ageGroup
        //   ? editCmdData?.ageGroup.split(" Years")[0]
        //   : "",
        campMinAge: editCmdData.campMinAge,
        campMaxAge: editCmdData.campMaxAge,
        maxSpots: editCmdData.campSpots,
        description: editCmdData.campDescription,
        campFeeSS: editCmdData.campFeeSS,
        campFeeParticipants: editCmdData.campFee,
        groupInfo: "",
      };

      console.log(campData);
      setTableStart(campStart);
      setTableEnd(campEnd);
      setFormData(campData);

      let grouoInfoData = [];
      let groupObj = {};
      setGroupInfo(editCmdData.groupInfo);
      const groupInfoData = [];

      (editCmdData?.groupInfo || []).forEach((item, index) => {
        let groupObj = {
          id: index + 1,
          group: item.groupName,
        };

        const supportStaff = [];
        const supportStaffId = [];

        item.staffList.forEach((item1) => {
          if (+item1.rolesId === 9) {
            groupObj = {
              ...groupObj,
              groupLeader: item1.staffName,
              groupLeaderId: item1.staffuserId,
            };
          }
          if (+item1.rolesId === 4) {
            supportStaff.push(item1.staffName);
            supportStaffId.push(item1.staffuserId);
          }
          if (+item1.rolesId === 5) {
            groupObj = {
              ...groupObj,
              therapist: item1.staffName,
              therapistId: item1.staffuserId,
            };
          }
          if (+item1.rolesId === 6) {
            groupObj = {
              ...groupObj,
              medicationManager: item1.staffName,
              medicationManagerId: item1.staffuserId,
            };
          }
        });

        if (supportStaff.length > 0) {
          groupObj = {
            ...groupObj,
            supportStaff: supportStaff,
            supportStaffId: supportStaffId,
          };
        }

        groupInfoData.push(groupObj);
      });

      console.log(groupInfoData);

      console.log(" grouoInfoData***(((((", grouoInfoData);
      setRows([...groupInfoData]);

      return campData;
      // console.log("campData5%%%%%%", campData);
    } else {
    }
  };

  const handleCampHoursChange = (event, slno) => {
    const updatedArr = dateAndHours.map((item) => {
      if (item.slno === slno) {
        return { ...item, campHours: event.target.value };
      }
      return item;
    });
    setDateAndHours(updatedArr);
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const useStyles = makeStyles({
    root: {
      margin: "10px",
      boxShadow: "0px 0px 10px #00000003",
      borderRadius: "20px",
    },
    mainheader: {
      letterSpacing: "0px",
      color: "#6c6c6c",
      textAlign: "center",
      fontSize: "18px",
      marginBottom: "2rem",
    },
    subheader: {
      letterSpacing: "0px",
      color: "#3B3B3B",
      textAlign: "left",
      fontSize: "16px",
      paddingTop: "16px",
    },
    listStyle: {
      height: "300px",
      overflowY: "scroll",
    },
    fixedHeightModal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
      maxHeight: "100%",
      margin: "0 auto",
    },
  });
  const classes = useStyles();

  const [personName, setPersonName] = React.useState([]);

  const uploadFile = (e) => {
    document.getElementById("selectFile").click();
  };

  async function imgSelectHandler(e) {
    console.log(e);

    if (e.target.files.length !== 0) {
      let file = "";
      file = e.target.files[0];
      //   console.log(file);
      setFile(e.target.files[0]);
      setThumbnail(e.target.files[0]);
      // setFileName(file.name);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      // read file as data url
      reader.onload = (e) => {
        console.log(e.target.result);
        // called once readAsDataURL is completed
        setFilePath(e.target.result);
      };
      //  let forminputs = new FormData()
      //     forminputs.set("file", 'aaaaa')
      //     forminputs.set("userId", 7)
      //     forminputs.set("documentKey", "childphoto")
      //     forminputs.set("documentContent", "content")

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/fileupload",
        url: `${SERVER_URL}${FILE_UPLOAD}`,
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      console.log("process", process.env);
      let response = await apiServiceImage(apiInfo);
      console.log("response", response);
      // props.handleFile(fileUploaded);
    }
  }
  const handleChange3 = (event) => {
    console.log("valueTarget", event.target);
    console.log("list", list);
    const {
      target: { value },
    } = event;

    let data = list.find((item) => {
      return item.userId === value[0];
    });

    setPersonName((prev) => [...prev, data.userName]);

    console.log("data", data);
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   // typeof value === "string" ? value.split(",") : value
    //   data.userName
    // );
  };

  // const [formData, setFormData] = React.useState(campData);
  // const handleOpen = (head) => {
  //   setOpen(true);
  //   console.log(head);
  // };
  const handleClose = () => setOpen(false);

  const validationSchema = Yup.object().shape({
    campName: Yup.string().required("Camp name is required"),
    location: Yup.string().required("Location is required"),
    // dateOfBirth: Yup.date().required("Date of birth is required").nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null))
    //   .max(date2, "Future date is not expected"),
    campStartdate: Yup.date().nullable().typeError("Start date is required"),
    // .max(date2, "Future and current date are not accepted") ,
    campRegStartdate: Yup.date()
      .nullable()
      .typeError("Reg Start date is required"),
    campRegEnddate: Yup.date().nullable().typeError("Reg End date is required"),

    campEnddate: Yup.date().nullable().typeError("End date is required"),

    campType: Yup.string().required("Camp type is required"),

    campMinAge: Yup.string().required("Minimum Age is required"),
    campMaxAge: Yup.string().required("Maximum Age is required"),

    maxSpots: Yup.number()
      .required("Max spots is required")
      .typeError("Enter Number Of Max Spots "),

    description: Yup.string().required("Description is required"),

    campFeeParticipants: Yup.number()
      .required("Fee is required")
      .typeError("Fee is required"),

    campFeeSS: Yup.number()
      .required("Fee is required")
      .typeError("Fee is required"),
  });

  const removeimg = () => {
    console.log(attachmentsArr);
    setTimeout(() => {
      setThumbnailFile(null);
      setThumbnailPath(null);
    }, 500);
  };

  const removeAttachment = (index) => {
    setAtachmentsArr([
      ...attachmentsArr.slice(0, index),
      ...attachmentsArr.slice(index + 1),
    ]);
  };

  const removeVideoItem = (index) => {
    setVideosSrc((prevVideos) => {
      const updatedVideos = [...prevVideos];
      updatedVideos.splice(index, 1);
      return updatedVideos;
    });
  };

  const {
    register,
    trigger,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: async () => await getCampDetails(),
  });

  const doSomething = async (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setStartDate(formData.campStartdate);
    setEndDate(formData.campEnddate);
    setTableStart(value);
    var date = new Date(value);

    var day = date.getTime() - 10 * 24 * 60 * 60 * 1000;
    var regDay = date.getTime();

    date.setTime(day);
    const newDateVal = formatDate(new Date(date).toISOString().substr(0, 10));
    // console.log(newDateVal);
    setToday(newDateVal);

    date.setTime(regDay);
    const regDateValue = formatDate(new Date(date).toISOString().substr(0, 10));
    setRegDate(regDateValue);
    // setDateState(true)
  };

  const getEndDate = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    const selectedEndDate = event.target.value;
    setEndDate(selectedEndDate);
    setStartDate(formData.campStartdate);
    setTableEnd(value);
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  // const getTableFormData = (data) => {
  //   setTableFormData((prev) =>
  //     prev.formIndex === formIndex
  //       ? (prev = [
  //           prev,
  //           ...[
  //             {
  //               StaffInfo: [...data],
  //             },
  //           ],
  //         ])
  //       : [
  //           ...prev,
  //           ...[
  //             {
  //               StaffInfo: [...data],
  //             },
  //           ],
  //         ]
  //   );
  //   setOpen(false);
  // };

  // const getStaffInfo = (userRole, index) => {
  //   let data = [];
  //   (tableformData || []).forEach((item) => {
  //     data = [...data, ...item.StaffInfo];
  //   });
  //   let userNames = (data || []).filter((ele) => {
  //     return ele.formIndex === index && ele.rolesId === userRole;
  //   });
  //   return (userNames || []).map((item) => item.userName);
  // };

  const getGroupId = (rolesId, staffuserId) => {
    let groupId = "";
    (groupinfo || []).forEach((item) => {
      item.staffList.forEach((item1, index1) => {
        if (
          +item1.rolesId === +rolesId &&
          +item1.staffuserId === +staffuserId
        ) {
          groupId = "";
        }
      });
    });
    return groupId;
  };
  const getStaffInfo = (groupName) => {
    let item = rows.find((item) => item.group == groupName);

    let staffArr = [
      {
        staffUserId: item?.groupLeaderId,
        rolesId: 9,
        groupId: "",
      },
      {
        staffUserId: item?.supportStaffId,
        rolesId: 4,
        groupId: "",
      },
      {
        staffUserId: item?.therapistId,
        rolesId: 5,
        groupId: "",
      },
      {
        staffUserId: item?.medicationManagerId,
        rolesId: 6,
        groupId: "",
      },
    ];
    return staffArr;
  };

  function storeInfo(event) {
    const { name, value } = event.target;
    console.log("name,value", name, value);
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(name, "reccccccccc");
    if (name === "campStartdate") {
      var date = new Date(value);

      // var day = date.getTime() - 10 * 24 * 60 * 60 * 1000;
      var regDay = date.getTime();

      // date.setTime(day);
      // const newDateVal = formatDate(new Date(date).toISOString().substr(0, 10));
      // console.log(newDateVal)
      // setToday(newDateVal);

      date.setTime(regDay);
      const regDateValue = formatDate(
        new Date(date).toISOString().substr(0, 10)
      );
      setRegDate(regDateValue);
    }
  }

  React.useEffect(() => {
    var date = new Date();
    var day = date.getTime();
    date.setTime(day);
    const newDate = formatDate(new Date(date).toISOString().substr(0, 10));
    setLatestDate(newDate);
  }, []);

  console.log("FormData", formData);
  // const onSubmit = async (data) => {

  // displayThumbnailError();

  //  displayFileError();
  // alert(errorThumbnail)

  //   let finalData = data;

  //   var s = moment(data.campStartdate).format("YYYY-MM-DD");
  //   var s1 = moment(data.campEnddate).format("YYYY-MM-DD");
  //   var s2 = moment(data.campRegStartdate).format("YYYY-MM-DD");
  //   var s3 = moment(data.campRegEnddate).format("YYYY-MM-DD");
  //   finalData["campStartdate"] = s;
  //   finalData["campEnddate"] = s1;
  //   finalData["campRegStartdate"] = s2;
  //   finalData["campRegEnddate"] = s3;
  //   const fileInfo = [];
  //   const thum = [thumbnailDocPath]
  //   const totalFiles =[...docPath,...thum];
  //   if(totalFiles && totalFiles.length >0){
  //     totalFiles.forEach(file=>{
  //       fileInfo.push({ "documentKey": "camp",documentHash:file})
  //     })

  //   }
  //   const finalArray = [];
  //   const obj = {
  //     groupName: "",
  //     staffinfo: [
  //       {
  //         rolesId: "",
  //         staffUserId: "",
  //       },
  //       {
  //         rolesId: "",
  //         staffUserId: "",
  //       },
  //       {
  //         rolesId: "",
  //         staffUserId: "",
  //       },
  //       {
  //         rolesId: "",
  //         staffUserId: "",
  //       },
  //     ],
  //   };
  //   rows.forEach((row) => {
  //     obj["groupName"] = row.group;

  //     filteredList.forEach((list) => {
  //       console.log(list);
  //       if (row.groupLeaderId === list.userId) {
  //         obj["staffinfo"][0]["rolesId"] = 9;
  //         obj["staffinfo"][0]["staffUserId"] = list.userId;
  //       }

  //       if (row.supportStaffId === list.userId) {
  //         obj["staffinfo"][1]["rolesId"] = 4;
  //         obj["staffinfo"][1]["staffUserId"] = list.userId;
  //       }
  //       if (row.therapistId === list.userId) {
  //         obj["staffinfo"][2]["rolesId"] = 5;
  //         obj["staffinfo"][2]["staffUserId"] = list.userId;
  //       }
  //       if (row.medicationManagerId === list.userId) {
  //         obj["staffinfo"][3]["rolesId"] = 6;
  //         obj["staffinfo"][3]["staffUserId"] = list.userId;
  //       }
  //     });
  //     finalArray.push(obj);
  //   });
  //   console.log("finalArray",  finalArray);
  //   // if ((finalArray && finalArray.length != 0) && (!errorThumbnail && !errorFile) ) {
  //   //   setGroupError(false);
  //   //   finalData.groupInfo = finalArray;
  //   //   finalData["userId"] = localStorage.getItem("userId");
  //   //   finalData["filesInfo"] = fileInfo;
  //   //   console.log("finalData", finalData);
  //   //   let apiInfo = {
  //   //     url: "https://cncdevapi.natsoft.io/api/camps/createcamp",
  //   //     method: "POST",
  //   //     postData: finalData,
  //   //     headers: {
  //   //       "Content-Type": "application/json",
  //   //       tokenheaderkey: localStorage.getItem("token"),
  //   //     },
  //   //   };

  //   //   let createcmp = await apiService(apiInfo);
  //   //   if (createcmp?.data?.responseCode === 0) {
  //   //     // alert("Register successfull");
  //   //     Swal.fire({
  //   //       icon: "success",
  //   //       iconHtml: `<img src="${GreenCheck}" >`,
  //   //       // text: register?.data?.responseData,
  //   //       text: createcmp?.data?.responseData[0][0].Desc,
  //   //       confirmButtonText: "OK",
  //   //       confirmButtonColor: "#379592",
  //   //     }).then(history.push("/CampList1"));
  //   //   } else {
  //   //     Swal.fire({
  //   //       icon: "error",

  //   //       // text: register?.data?.responseData,
  //   //       text: createcmp?.data?.responseData[0][0].Desc,
  //   //       confirmButtonText: "OK",
  //   //       confirmButtonColor: "#379592",
  //   //     });
  //   //     // alert(createcmp?.data?.responseData[0][0].Desc);
  //   //   }
  //   // } else {
  //   //   setGroupError(true);
  //   // }

  //   // if(groupError === false && finalData ){

  //   // }
  //   // else{
  //   //   console.log('group info is not set')
  //   // }
  // };

  React.useEffect(() => {
    console.log("general", startDate, endDate);
    if (startDate && endDate) {
      console.log("comingggggggggggg", startDate, endDate, tableStart);
      const start = moment(tableStart);
      const end = moment(tableEnd);
      const dates = [];
      let slno = 1;

      while (start <= end) {
        const dateObject = {
          slno: slno,
          campDate: start.format("DD/MM/YYYY"),
          campHours: 12,
        };
        dates.push(dateObject);
        start.add(1, "day");
        slno++;
      }
      setDateAndHours(dates);
    } else {
      console.log("not cominggggggggg");
    }
  }, [tableStart, tableEnd]);
  console.log(dateAndHours, "what is cominggggg");
  function getDatesBetween(startDate, endDate) {
    const currentDate = new Date(startDate.getTime());
    const dates = [];
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
      // moment(data.campStartdate).format("YYYY-MM-DD")
    }
    return dates;
  }

  const onSubmit = async (data) => {
    // console.log('yoooooo',data.campMinAge)
    let isMinAge = false;
    if (Number(data.campMinAge) < Number(data.campMaxAge)) {
      isMinAge = true;
      console.log("looping inside min age");
      setErrMsg("");
    } else {
      setErrMsg("Min age should be less than Max age");
    }
    displayThumbnailError();

    displayFileError();

    let finalData = formData;

    const finalArray = rows.map((item) => {
      return {
        groupName: item.group,
        staffList: getStaffInfo(item.group),
      };
    });

    // console.clear();
    console.log("rows======>====", rows);

    // if (finalArray && finalArray.length != 0 && !errorThumbnail) {
    const fileInfo = [];
    const thum = [thumbnailDocPath];
    const attachmentsPaths = [];
    if (attachmentsArr && attachmentsArr.length > 0) {
      attachmentsArr.forEach((attach) => {
        attachmentsPaths.push(attach.docPath);
      });
    }
    const totalFiles = [...attachmentsPaths, ...thum];
    if (totalFiles && totalFiles.length > 0) {
      totalFiles.forEach((file) => {
        fileInfo.push({ documentKey: "Thumbnail", documentHash: file });
      });
    }
    if (isMinAge) {
      // finalData.groupInfo = finalArray;
      finalData.groupInfo =
        finalArray && finalArray.length === 0 ? [] : finalArray;
      finalData["userId"] = localStorage.getItem("userId");
      finalData["filesInfo"] = fileInfo;

      finalData["campId"] = campId;
      // finalData["ageGroup"] = finalData.campAgegroup;
      finalData["campDescription"] = finalData.description;
      finalData["campEndDateTime"] = finalData.campEnddate;
      finalData["campLocation"] = finalData.location;
      finalData["campRegStartDateTime"] = finalData.campRegStartdate;
      finalData["campRegEndDateTime"] = finalData.campRegEnddate;
      finalData["campSpots"] = finalData.maxSpots;
      finalData["campStartDateTime"] = finalData.campStartdate;
      finalData["campFeeParticipants"] = finalData.campFeeParticipants;
      finalData["campFeeSS"] = finalData.campFeeSS;
      // finalData["campStatus"] = viewCampDate.campStatus;
      // finalData["videoInfo"] = JSON.stringify(videosSrc);
      finalData["videoInfo"] = videoRow;

      finalData["campStatus"] = "DRAFT";
      if (dateAndHours && dateAndHours.length > 0) {
        dateAndHours.forEach((date) => {
          delete date["slno"];
          date["campDate"] = date["campDate"].split("/").reverse().join("-");
        });
      }
      finalData["campHoursDatewise"] = dateAndHours;
      // finalData["campStatus"] = "Active";

      delete finalData.campAgegroup;
      delete finalData.description;
      delete finalData.location;
      delete finalData.campRegStartdate;
      delete finalData.campRegEnddate;
      delete finalData.maxSpots;
      delete finalData.campStartdate;

      // console.clear();

      finalData.groupInfo.forEach((group) => {
        group.staffList = group.staffList.flatMap((staff) => {
          if (staff.rolesId === 4 && Array.isArray(staff.staffUserId)) {
            return staff.staffUserId.map((userId) => ({
              staffUserId: String(userId),
              rolesId: staff.rolesId,
              groupId: staff.groupId,
            }));
          } else {
            return staff;
          }
        });
      });

      console.log("finalData", finalData);
      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/editcamp",
        url: `${SERVER_URL}${EDIT_CAMP}`,
        method: "POST",
        postData: finalData,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let createcmp = await apiService(apiInfo);
      // if (createcmp?.data?.responseCode === "0") {
      //   history.push("/CampList1");
      // } else {
      //   alert(createcmp?.data?.responseData[0][0].Desc);
      // }
      if (createcmp?.data?.responseCode === 1) {
        // alert("Register successfull");
        Swal.fire({
          icon: "error",
          // iconHtml: `<img src="${GreenCheck}" >`,
          // text: register?.data?.responseData,
          text: "Error",
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
          },
        });
      } else if (createcmp?.data?.responseCode === 0) {
        Swal.fire({
          // icon: "success",
          iconHtml: `<img src="${GreenCheck}" >`,
          // text: register?.data?.responseData,
          text: createcmp?.data?.responseData[0][0].Desc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",

          customClass: {
            container: "my-swal",
            icon: "no-border",
          },
        }).then(history.push("/CampList1"));
        // alert(createcmp?.data?.responseData[0][0].Desc);
      }
      // }
      // else {
      //   setGroupError(true);
    }
  };

  const sendMail = async () => {
    const gettran = {
      userId: localStorage.getItem("userId"),
      campId: campId,
    };

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/us8",

      url: `${SERVER_URL}${SENDMAIL_TO_CAMPSTAFF}`,

      method: "POST",

      postData: gettran,

      headers: {
        "Content-Type": "application/json",

        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let login = await apiService2(apiInfo);

    if (login?.data?.responseCode === 1) {
      // alert("Register successfull");
      Swal.fire({
        // icon: "error",
        iconHtml: `<img src="${GreenCheck}" >`,
        // text: register?.data?.responseData,
        text: "Action Completed Successfully",
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
        customClass: {
          container: "my-swal",
        },
      });
    } else if (login?.data?.responseCode === 0) {
      Swal.fire({
        // icon: "success",
        iconHtml: `<img src="${GreenCheck}" >`,
        // text: register?.data?.responseData,
        text: "Action Completed Successfully",
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
        customClass: {
          container: "my-swal",
          icon: "no-border",
        },
      }).then(history.push(`/EditCamp/${campId}`));
      // alert(createcmp?.data?.responseData[0][0].Desc);
    }
  };

  const onPublish = async (data) => {
    let isMinAge = false;
    if (Number(data.campMinAge) < Number(data.campMaxAge)) {
      isMinAge = true;
      console.log("looping inside min age");
      setErrMsg("");
    } else {
      setErrMsg("Min age should be less than Max age");
    }
    // console.log("groupByconsole", groupBy("groupName"));

    // // let finalData = formData;

    // let finalData = data;

    // const finalArray = [];
    // const obj = {
    //   groupName: "",
    //   staffinfo: [
    //     {
    //       rolesId: "",
    //       staffUserId: "",
    //     },
    //     {
    //       rolesId: "",
    //       staffUserId: "",
    //     },
    //     {
    //       rolesId: "",
    //       staffUserId: "",
    //     },
    //     {
    //       rolesId: "",
    //       staffUserId: "",
    //     },
    //   ],
    // };
    // rows.forEach((row) => {
    //   obj["groupName"] = row.group;

    //   filteredList.forEach((list) => {
    //     console.log(list);
    //     if (row.groupLeaderId === list.userId) {
    //       obj["staffinfo"][0]["rolesId"] = 9;
    //       obj["staffinfo"][0]["staffUserId"] = list.userId;
    //     }

    //     if (row.supportStaffId === list.userId) {
    //       obj["staffinfo"][1]["rolesId"] = 4;
    //       obj["staffinfo"][1]["staffUserId"] = list.userId;
    //     }
    //     if (row.therapistId === list.userId) {
    //       obj["staffinfo"][2]["rolesId"] = 5;
    //       obj["staffinfo"][2]["staffUserId"] = list.userId;
    //     }
    //     if (row.medicationManagerId === list.userId) {
    //       obj["staffinfo"][3]["rolesId"] = 6;
    //       obj["staffinfo"][3]["staffUserId"] = list.userId;
    //     }
    //   });
    //   finalArray.push(obj);
    // });
    // console.log(finalArray);

    displayThumbnailError();

    displayFileError();

    let finalData = formData;

    const finalArray = rows.map((item) => {
      return {
        groupName: item.group,
        staffList: getStaffInfo(item.group),
      };
    });

    // console.clear();
    console.log("rows======>====", rows);
    const fileInfo = [];
    const thum = [thumbnailDocPath];
    const attachmentsPaths = [];
    if (attachmentsArr && attachmentsArr.length > 0) {
      attachmentsArr.forEach((attach) => {
        attachmentsPaths.push(attach.docPath);
      });
    }
    const totalFiles = [...attachmentsPaths, ...thum];
    if (totalFiles && totalFiles.length > 0) {
      totalFiles.forEach((file) => {
        fileInfo.push({ documentKey: "Thumbnail", documentHash: file });
      });
    }
    // finalData.groupInfo = finalArray;
    if (isMinAge) {
      finalData.groupInfo =
        finalArray && finalArray.length === 0 ? [] : finalArray;
      // filesInfo
      finalData["userId"] = localStorage.getItem("userId");
      finalData["filesInfo"] = fileInfo;
      finalData["campId"] = campId;
      // finalData["ageGroup"] = finalData.campAgegroup;
      finalData["campDescription"] = finalData.description;
      finalData["campEndDateTime"] = finalData.campEnddate;
      finalData["campLocation"] = finalData.location;
      finalData["campRegStartDateTime"] = finalData.campRegStartdate;
      finalData["campRegEndDateTime"] = finalData.campRegEnddate;
      finalData["campSpots"] = finalData.maxSpots;
      finalData["campStartDateTime"] = finalData.campStartdate;
      finalData["campFeeParticipants"] = finalData.campFeeParticipants;
      finalData["campFeeSS"] = finalData.campFeeSS;
      finalData["videoInfo"] = videoRow;
      // finalData["campStatus"] = viewCampDate.campStatus;
      finalData["campStatus"] = "PUBLISHED";
      if (dateAndHours && dateAndHours.length > 0) {
        dateAndHours.forEach((date) => {
          delete date["slno"];
          date["campDate"] = date["campDate"].split("/").reverse().join("-");
        });
      }
      finalData["campHoursDatewise"] = dateAndHours;
      finalData["filesInfo"] = fileInfo;

      delete finalData.campAgegroup;
      delete finalData.description;
      delete finalData.location;
      delete finalData.campRegStartdate;
      delete finalData.campRegEnddate;
      delete finalData.maxSpots;
      delete finalData.campStartdate;

      // console.clear();

      finalData.groupInfo.forEach((group) => {
        group.staffList = group.staffList.flatMap((staff) => {
          if (staff.rolesId === 4 && Array.isArray(staff.staffUserId)) {
            return staff.staffUserId.map((userId) => ({
              staffUserId: String(userId),

              rolesId: staff.rolesId,

              groupId: staff.groupId,
            }));
          } else {
            return staff;
          }
        });
      });

      console.log("finalData", finalData);
      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/editcamp",
        url: `${SERVER_URL}${EDIT_CAMP}`,
        method: "POST",
        postData: finalData,
        headers : {
          "Content-Type": "application/json",
          tokenheaderkey:localStorage.getItem("token"),
        },
      };

      let createcmp = await apiService(apiInfo);
      // if (createcmp?.data?.responseCode === "0") {
      //   history.push("/CampList1");
      // } else {
      //   alert(createcmp?.data?.responseData[0][0].Desc);
      // }
      if (createcmp?.data?.responseCode === 1) {
        // alert("Register successfull");
        Swal.fire({
          icon: "error",
          // iconHtml: `<img src="${GreenCheck}" >`,
          // text: register?.data?.responseData,
          text: "Error",
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
          },
        });
      } else if (createcmp?.data?.responseCode === 0) {
        Swal.fire({
          icon: "success",

          // text: register?.data?.responseData,
          text: createcmp?.data?.responseData[0][0].Desc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
            icon: "no-border",
          },
        }).then(history.push("/CampList1"));
        // alert(createcmp?.data?.responseData[0][0].Desc);
      }
    }
  };
  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        group: "",
        groupLeader: "",
        groupLeaderId: "",

        supportStaff: "",
        supportStaffId: "",

        therapist: "",
        therapistId: "",

        medicationManager: "",
        medicationManagerId: "",
      },
    ]);
    console.log(rows);
  };
  const handleDeleteRow = (id) => {
    console.log(rows);
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleRowChange = (id, field, value, field1, value1) => {
    setRows(
      rows.map((row) =>
        row.id === id ? { ...row, [field]: value, [field1]: value1 } : row
      )
    );

    setSearchName(null);
    setOpen(false);
    setSearch("");
    setSelectedType("");
    setUserId("");
    setUserId1("");
    setSearchName1("");
  };
  const selectItem = (selectedName, userId) => {
    setSearchName(selectedName);
    setUserId(userId);

    console.log(seachName);
  };

  const selectItemSS = (selectedName, userId) => {
    if (!seachName1.includes(selectedName)) {
      setSearchName1((prev) => [...prev, selectedName]);
      setUserId1((prev) => [...prev, userId]);
    } else {
      console.log(`${selectedName} is already selected.`);
    }
  };

  const handleRemove = (itemToRemove) => {
    // Implement the logic to remove the item from the seachName1 array
    setSearchName1((prev) => prev.filter((item) => item !== itemToRemove));
  };

  const maxSelectedItemsHeight = "50px";

  const handleOpen = (id, name) => {
    console.log(name);
    console.log(id);
    console.log(JSON.stringify(supportStafflist));
    console.log(JSON.stringify(rows));
    let staff = [];
    if (rows && rows.length === 1) {
      const row = rows[0]; // Get the first (and only) row
      if (row.supportStaff && row.supportStaff.length === 0) {
        if (allSupportStaffList && allSupportStaffList.length > 0) {
          allSupportStaffList.forEach((ss) => {
            staff.push({ userName: ss.userName, userId: ss.userId });
          });
        }
      } else {
        if (allSupportStaffList && allSupportStaffList.length > 0) {
          allSupportStaffList.forEach((ss) => {
            staff.push({ userName: ss.userName, userId: ss.userId });
          });
        }
      }

      setsupportStaffList(staff);
    } else if (rows && rows.length === 0) {
      // If there are no rows, push all users from allSupportStaffList

      if (allSupportStaffList && allSupportStaffList.length > 0) {
        allSupportStaffList.forEach((ss) => {
          staff.push({ userName: ss.userName, userId: ss.userId });
        });
      }

      setsupportStaffList(staff);
    } else if (rows && rows.length > 1) {
      // Handle the case when rows has a length greater than 1

      // Your specific logic here

      // For example:

      // Extract the usernames from the allSupportStaffList array

      const allUsernames = allSupportStaffList.map((staff) => staff.userName);

      // Create an array to store unselected users' information

      const unselectedUsers = [];

      // Iterate through the allSupportStaffList array

      for (const staff of allSupportStaffList) {
        const staffName = staff.userName;

        const staffId = staff.userId;

        // Check if staffName is not present in any of the rows' supportStaffNames

        const isStaffSelected = rows.some(
          (row) =>
            Array.isArray(row.supportStaff) &&
            row.supportStaff.includes(staffName)
        );

        if (!isStaffSelected) {
          unselectedUsers.push({ userName: staffName, userId: staffId });
        }
      }

      // Now the 'unselectedUsers' array should contain the desired unselected usernames

      setsupportStaffList(unselectedUsers);

      console.log(unselectedUsers);
    }

    setOpen(true);

    setSelectedType(name);

    setRowId(id);

    // console.log(rowId)
  };
  React.useEffect(() => {
    (async () => {
      let inputData = {
        userId: localStorage.getItem("userId"),
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/staff/getlistofstaff",
        url: `${SERVER_URL}${GETLIST_STAFF}`,
        method: "POST",
        postData: inputData,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
          // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RwYXJlbnQyMDIyQHlvcG1haWwuY29tIiwiaWF0IjoxNjc1ODM4MTE5LCJleHAiOjE2ODM2MTQxMTl9.rKRXPZ3DsXe4AsSeVR9AF_8R3jFWF5vZ4UQF2Q7UXPw",
        },
      };

      let output = await apiService2(apiInfo);
      let listofStaff = output.data.responseData[0];
      if (Array.isArray(listofStaff)) {
        setList(listofStaff);
      }

      console.log(list);
    })()
      .catch((e) => {
        // console.error(e);
      })
      .finally(() => {});
  }, []);

  React.useEffect(() => {
    (async () => {
      let inputData = {
        userId: localStorage.getItem("userId"),
        rolesId: "4",
        campId: campId,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/staff/getlistofstaff",
        url: `${SERVER_URL}${GETLIST_STAFF}`,
        method: "POST",
        postData: inputData,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
          // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RwYXJlbnQyMDIyQHlvcG1haWwuY29tIiwiaWF0IjoxNjc1ODM4MTE5LCJleHAiOjE2ODM2MTQxMTl9.rKRXPZ3DsXe4AsSeVR9AF_8R3jFWF5vZ4UQF2Q7UXPw",
        },
      };

      let output = await apiService2(apiInfo);
      let listofSupportStaff = output.data.responseData[0];
      if (Array.isArray(listofSupportStaff)) {
        setsupportStaffList(listofSupportStaff);
        setAllSupportStaffList(listofSupportStaff);
      }

      console.log(supportStafflist);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);

  console.log(list);
  if (list && list.length > 0) {
    var filteredList = list.filter((item) =>
      item.userName.toLowerCase().includes(search.toLowerCase())
    );
  } else {
    filteredList = [];
  }

  console.log("getStaffInfo", getStaffInfo(3, 0));
  const callApi = () => {
    let groupInfo = groupBy("groupName");
    // here assign groupInfo into formField state and send in req payload
  };
  function groupBy(property) {
    let dataArr = [];
    (tableformData || []).forEach((item) => {
      dataArr = [...dataArr, ...item.StaffInfo];
    });
    let prepareData = [];
    if (dataArr.length) {
      prepareData = (dataArr || []).reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
      }, {});
    }
    let finalTableData = [];
    for (let item in prepareData) {
      finalTableData = [
        ...finalTableData,
        {
          groupName: prepareData[item][0].groupName,
          staffInfo: removeKeys(prepareData[item]),
        },
      ];
    }
    return finalTableData;
    console.log("finalTableData", finalTableData);
  }
  const removeKeys = (arr) => {
    return arr.map((ele) => {
      return {
        rolesId: ele.rolesId,
        staffUserID: ele.staffUserID,
      };
    });
  };
  //  const groupedPeople = groupBy(formData, 'groupName');
  console.log("groupBy", groupBy("groupName"));

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const documentPaths = [];
  const resetFileInput = () => {
    // 👇️ reset input value
    inputRef.current.value = null;
  };
  async function uploadThumnails(event) {
    event.preventDefault();
    // setFileName('')
    const forminputsTwo = new FormData();
    console.log(thumbnail);
    forminputsTwo.append("file", thumbnailFile);
    forminputsTwo.append("userId", localStorage.getItem("userId"));
    forminputsTwo.append("documentKey", "camp");
    forminputsTwo.append("documentContent", "Thumbnail");
    forminputsTwo.append("documentType", "THUMBNAIL");
    axios
      // .post(
      //    "https://cncdevapi.natsoft.io/api/camps/fileupload",
      .post(`${SERVER_URL}${FILE_UPLOAD}`, forminputsTwo, {
        forminputsTwo,
       
          headers: {
            "Content-Type": "multipart/form-data",
            tokenheaderkey: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log(response);
        setThumbnailthumbnailDocPath(
          response["data"]["result"][0][0].documentHash
        );
        displayThumbnailError();
        setThumbStatus("Thumbnail uploaded");

        // Handle server response
      })
      .catch((error) => {
        // Handle errors
      });

    // console.log(fileName);
  }

  // methods for upload thunbnail  start//

  const handleThumbnailChange = (event) => {
    //
    // setAttachments(event.target.files);
    // console.log(attachments)
    if (parseFloat(event.target.files[0].size / (1024 * 1024)).toFixed(2) > 3) {
      // alert('Please select image size less than 500 KB');
      Swal.fire({
        icon: "error",
        // iconHtml: `<img src="${GreenCheck}" >`,
        // text: register?.data?.responseData,
        text: "Please select image size less than 3 MB",
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
      }).then(history.push("/EditCamp"));
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setThumbnailFile(event.target.files[0]);
      setThumbStatus("Thumbnail Not Uploaded");
      console.log(thumbnailPath);
      reader.onload = (e) => {
        setThumbnailPath(e.target.result);
      };
      setSelectedImages((previousImages) =>
        previousImages.concat(thumbnailFile)
      );
    }
  };

  function displayThumbnailError() {
    // alert()
    if (thumbnailPath === null) {
      seterrorThumbnail(true);
    } else {
      seterrorThumbnail(false);
    }
  }

  const formatData = () => {
    const finalArray = [];
    const obj = {
      groupName: "",
      staffinfo: [
        {
          rolesId: "",
          staffUserId: [],
        },
        {
          rolesId: "",
          staffUserId: [],
        },
        {
          rolesId: "",
          staffUserId: [],
        },
        {
          rolesId: "",
          staffUserId: [],
        },
      ],
    };
    rows.forEach((row) => {
      obj["groupName"] = row.group;

      filteredList.forEach((list) => {
        console.log(list);
        if (row.groupLeaderId === list.userId) {
          obj["staffinfo"][0]["rolesId"] = 9;
          obj["staffinfo"][0]["staffUserId"].push(list.userId);
        }

        if (row.supportStaffId === list.userId) {
          obj["staffinfo"][1]["rolesId"] = 4;
          obj["staffinfo"][1]["staffUserId"].push(list.userId);
        }
        if (row.therapistId === list.userId) {
          obj["staffinfo"][2]["rolesId"] = 5;
          obj["staffinfo"][2]["staffUserId"].push(list.userId);
        }
        if (row.medicationManagerId === list.userId) {
          obj["staffinfo"][3]["rolesId"] = 6;
          obj["staffinfo"][3]["staffUserId"].push(list.userId);
        }
      });
      finalArray.push(obj);
    });

    console.log(finalArray);
  };

  

  const handleFileChange = (event) => {
    console.log(event);

    const numSelectedFiles = event.target.files ? event.target.files.length : 0;
    const numTotalFiles = attachmentsArr.length + numSelectedFiles;

    if (numTotalFiles > 5) {
      // alert("Please select only 5 files");

      Swal.fire({
        icon: "error",
        // iconHtml: `<img src="${GreenCheck}" >`,
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
        text: "Please select only 5 files",
        customClass: {
          container: "my-swal",
        },
      }).then((result) => {});
      return;
    }

    if (
      (event.target.files &&
        (event.target.files.length === 4 || event.target.files.length < 4)) ||
      (attachmentsArr && (attachmentsArr === 4 || attachmentsArr.length < 4))
    ) {
      for (const file of event.target.files) {
        console.log(file, "fileeeeeeeeeeeeee");
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          setImgsSrc((imgs) => [...imgs, e.target.result]);
          setAttachments((paths) => [...paths, file]);
          setAtachmentsArr((attachment) => [
            ...attachment,
            {
              path: e.target.result,
              fileObj: file,
              docPath: "",
              docTitle: file.name,
            },
          ]);
        };
      }

      if (event.target.files && event.target.files.length > 0) {
        for (const file of event.target.files) {
          console.log(file, "lolollolololololol", file.type);
          // ||"image/png" || "image/jpg" || "image/gif" || "image/svg"
          // accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          if (
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/svg" ||
            file.type === "image/jfif" ||
            file.type === "image/tiff" ||
            file.type === "application/pdf" ||
            file.type === "application/PDF" ||
            file.type === "application/msword" ||
            file.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/xls" ||
            file.type === ".xlsx"
          ) {
            console.log("insideee");
            if (parseFloat(file.size / (1024 * 1024)).toFixed(2) > 5) {
              console.log(file.size);
              // alert("Please select image size less than 2 MB");
              // setImageAlert(true)
              Swal.fire({
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#379592",
                text: "Please select image size less than 5 MB",
                customClass: {
                  container: "my-swal",
                },
              });
              setTimeout(() => {
                console.log(attachmentsArr, file.name); // Log current values for debugging purposes
                setAtachmentsArr((attachmentsArr) => {
                  return attachmentsArr.filter(
                    (row) => row.docTitle !== file.name
                  );
                });
              }, 2000);

              console.log(attachmentsArr, "hereeeeeeeeeeeee");
            } else {
              // setImageAlert(false)
              console.log("yes");
            }
          } else {
            Swal.fire({
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#379592",
              text: "Please select correct file type",
              customClass: {
                container: "my-swal",
              },
            });
            setTimeout(() => {
              console.log(attachmentsArr, file.name); // Log current values for debugging purposes
              setAtachmentsArr((attachmentsArr) => {
                return attachmentsArr.filter(
                  (row) => row.docTitle !== file.name
                );
              });
            }, 2000);
          }
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        // iconHtml: `<img src="${GreenCheck}" >`,
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
        text: "Please select only 5 files",
        customClass: {
          container: "my-swal",
        },
      }).then((result) => {});
    }
  };
  async function uploadAttachments(event) {
    console.log(attachmentsArr);
    event.preventDefault();
    // setFileName('')
    const uniq = [...new Set(attachments)];

    attachmentsArr.forEach((file) => {
      if(file.docPath === '' &&  file.fileObj != null){
      const forminputs = new FormData();
      console.log(file);
      forminputs.append("file", file.fileObj);
      forminputs.append("userId", localStorage.getItem("userId"));
      forminputs.append("documentKey", "camp");
      forminputs.append("documentContent", "content");
      forminputs.append("documentType", "PRE_CAMP");

      axios
        // .post("https://cncdevapi.natsoft.io/api/camps/fileupload", forminputs, {
          .post(`${SERVER_URL}${FILE_UPLOAD}`, forminputs, {
          headers: {
            "Content-Type": "multipart/form-data",
            tokenheaderkey: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response?.data?.responseCode === 1) {
            console.log("enetring that");

            Swal.fire({
              icon: "error",
              text: response?.data?.responseData,
              // text: "TESTING",
              confirmButtonText: "OK",
              confirmButtonColor: "#379592",
              customClass: {
                container: "my-swal",
              },
            });
          } else if (response.data.result[0][0].Code === "0") {
            console.log("entering this");
            setDocPath((paths) => [
              ...paths,
              response["data"]["result"][0][0].documentHash,
            ]);
            displayFileError();
            setAttachmentsStatus("Attachments Uploaded");

            // return { ...file, [file.docPath]: response['data']['result'][0][0].documentHash };
            // Handle server response

            setAtachmentsArr((attach) => {
              return attach.map((attachment) => {
                if (attachment.fileObj === file.fileObj) {
                  // Update the docPath property for the matching attachment
                  return {
                    ...attachment,
                    docPath: response["data"]["result"][0][0].documentHash,
                  };
                } else {
                  return attachment;
                }
              });
            });
          }
        })
        .catch((error) => {
          // Handle errors
        });
      }
    });
    //  setAtachmentsArr(newattachments);

    console.log(documentPaths);
  }
  function displayFileError() {
    // alert()
    if (thumbnailFile === null) {
      setErrorFile(true);
    } else {
      setErrorFile(false);
    }
  }

  // method end upload attachments //

  const handleFileChangeTwo = (event) => {
    console.log(docPath);
  }
  function getDatesBetween(startDate, endDate) {
    const currentDate = new Date(startDate.getTime());
    const dates = [];
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
      // moment(data.campStartdate).format("YYYY-MM-DD")
    }
    return dates;
  }

  const getRegStartDate = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,

      [name]: value,
    }));

    setRegStart(value);
  };

  const getRegEndDate = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,

      [name]: value,
    }));
    setRegEnd(value);
  };

  React.useEffect(() => {
    console.log("YAYYAYAYAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", tableStart, tableEnd);
    if (tableStart && tableEnd) {
      const date1 = new Date(tableStart);
      const date2 = new Date(tableEnd);

      console.log("dates", date1, date2);

      let allDates = getDatesBetween(date1, date2);
      console.log(allDates);
      // setFinalDateArray(allDates);
      const arr = [];
      if (allDates && allDates.length > 0) {
        console.log('is it setting to 12')
        allDates.forEach((item, index) => {
          arr.push({
            campDate: moment(item).format("YYYY-MM-DD"),
            campHours: 12,
          });
        });
        setFinalDateArray(allDates)
        setHoursTable(arr);
        console.log(arr)
      }
    }

    if (new Date(tableStart).getTime() <= new Date(tableEnd).getTime()) {
      console.log("yesssssssssss");
      setCampErr(false);
    } else if (new Date(tableStart).getTime() > new Date(tableEnd).getTime()) {
      setCampErr(true);
    }

    if (new Date(regStart).getTime() < new Date(regEnd).getTime()) {
      setRegErr(false);
    } else if (
      new Date(regStart).getTime() > new Date(regEnd).getTime() ||
      new Date(regStart).getTime() > new Date(tableEnd).getTime() ||
      new Date(regStart).getTime() > new Date(tableStart).getTime() ||
      new Date(regEnd).getTime() > new Date(tableStart).getTime()
    ) {
      setRegErr(true);
    }

    let isMaxSpots = false;
    if (
      (Number(formData.maxSpots) <= 60 && Number(formData.maxSpots) >= 10) ||
      formData.maxSpots === ""
    ) {
      isMaxSpots = true;
      setSpotErr("");
    } else {
      setSpotErr("Max spots allowed is 60");
    }

    let isMinAge = false;
    if (
      Number(formData.campMinAge) < Number(formData.campMaxAge) ||
      formData.campMinAge === "" ||
      formData.campMaxAge === ""
    ) {
      isMinAge = true;
      console.log("looping inside min age");
      setErrMsg("");
    } else {
      setErrMsg("Min age should be less than Max age");
    }
  }, [tableStart, tableEnd, regStart, regEnd, formData]);

  const setHoursValue = (event, i) => {
    console.log(event.target.value);
    console.log(hoursTable[i]);

    // Add a check to ensure hoursTable is an array before calling map
    if (Array.isArray(dateAndHours)) {
      const updatedDateAndHours = dateAndHours.map((item, index) =>
        index === i ? { ...item, campHours: event.target.value } : item
      );
      setDateAndHours(updatedDateAndHours);
    } else {
      console.error("hoursTable is not an array.");
    }
  };
  console.log(hoursTable, "testtt");

  const handleRemoveSupportStaff = (rowId, index) => {
    console.log(rowId, index)
    const updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        const updatedSupportStaff = row.supportStaff.filter(
          (_, i) => i !== index
        );
        const updatedSupportStaffId = row.supportStaffId.filter(
          (_, i) => i !== index
        );
        console.log(updatedSupportStaff)
        return { ...row, supportStaff: updatedSupportStaff , supportStaffId: updatedSupportStaffId  };
      }
      return row;
    });

    setRows(updatedRows);
    console.log(rows)
  };

  return (
    <>
      {/* <BrowserRouter> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div>
          {/* <Box>Edit Camp</Box> */}
          <Grid
            display="flex"
            // spacing={1}
            xs={12}
            sm={12}
            md={6}
            // my='0.5rem'
            // alignSelf="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Stack direction="row" spacing={1}>
                <Link to="/CampList1">
                  <Box
                    // mt="0.75rem"
                    component="img"
                    sx={{
                      height: "0.75rem",
                      width: "0.65rem",
                      cursor: "pointer",
                    }}
                    height="20"
                    alt="Logo"
                    src={ArrowBack}
                  ></Box>
                </Link>
                <Box className="gilroy editcamp" alignSelf="center">
                  Edit Camp
                </Box>
              </Stack>
            </Grid>

            <Grid item>
              <Stack direction="row">
                <Button
                  variant="contained"
                  disabled={viewCampDate.campStatus === "DRAFT"}
                  sx={{
                    px: "0.5rem",
                    mx: "0.5rem",
                    bgcolor: "#FF9529",
                    textTransform: "none",
                    "&:hover": { background: "#FF9529" },
                  }}
                  onClick={() => sendMail()}
                  className="gilroy sendMailToStaff"
                >
                  Send Mail to Staff
                </Button>

                {/* <Link to={`/ViewParticipants/${campId}`} className="link2"> */}
                  <Button
                    variant="contained"
                    disabled={viewCampDate.campStatus === "DRAFT"}
                    sx={{
                      px: "1rem",
                      width: "130px",
                      mx: "0.5rem",
                      bgcolor: "#379592",
                      textTransform: "none",
                      "&:hover": { background: "#379592" },
                    }}
                    onClick={()=>{
                      history.push(`/ViewParticipants/${campId}`)
                    }}
                    className="gilroy participants-btn"
                  >
                    Participants
                  </Button>
                {/* </Link> */}
                {/* <Link to={`/CampNotificationLog/${campId}`} className="link2"> */}
                  <Button
                    variant="outlined"
                    disabled={viewCampDate.campStatus === "DRAFT"}
                    sx={{
                      px: "1rem",
                      mx: "0.5rem",
                      bgcolor: "white",
                      color: "#379592",
                      textTransform: "none",
                      borderColor: "#379592",
                      "&:hover": {
                        background: "white",
                        color: "#379592",
                        borderColor: "#379592",
                      },
                      width: "130px",
                    }}
                    onClick={()=>{
                      history.push(`/CampNotificationLog/${campId}`)
                    }}
                    className="gilroy notifications-btn"
                  >
                    Notifications
                  </Button>
                {/* </Link> */}
                {/* <Link to={`/Gallery/${campId}`} className="link2"> */}
                  <Button
                    variant="outlined"
                    disabled={viewCampDate.campStatus === "DRAFT"}
                    sx={{
                      px: "1rem",
                      mx: "0.5rem",
                      bgcolor: "white",
                      color: "#379592",
                      textTransform: "none",
                      borderColor: "#379592",
                      "&:hover": {
                        background: "white",
                        color: "#379592",
                        borderColor: "#379592",
                      },
                      width: "130px",
                    }}
                    onClick={()=>{
                      history.push(`/Gallery/${campId}`)
                    }}
                    className="gilroy gallery-btn"
                  >
                    Gallery
                  </Button>
                {/* </Link> */}
                {/* <Link to={`/CheckInOut/${campId}`} className="link2"> */}
                  <Button
                    variant="outlined"
                    disabled={viewCampDate.campStatus === "DRAFT"}
                    sx={{
                      px: "1rem",
                      mx: "0.5rem",
                      bgcolor: "white",
                      color: "#379592",
                      textTransform: "none",
                      borderColor: "#379592",
                      "&:hover": {
                        background: "white",
                        color: "#379592",
                        borderColor: "#379592",
                      },
                      width: "130px",
                    }}
                    onClick={()=>{
                      history.push(`/CheckInOut/${campId}`)
                    }}
                    className="gilroy checkin-btn"
                  >
                    Check In/Out
                  </Button>
                {/* </Link> */}
              </Stack>
            </Grid>
          </Grid>
          {/* <Grid container direction="flex" mb="1.65rem">
            <Stack
              direction="row"
              spacing={1}
              xs={12}
              sm={12}
              md={6}
              // mr="4rem"
              alignSelf="center"
            >
              <Link to="/CampList1">
                <Box
                  mt="0.65rem"
                  alignSelf="center"
                  component="img"
                  sx={{
                    height: "0.75rem",
                    width: "0.65rem",
                    cursor: "pointer",
                  }}
                  height="20"
                  alt="Logo"
                  src={ArrowBack}
                ></Box>
              </Link>
              <Box className="bold" alignSelf="center">
                Edit Camp
              </Box>
            </Stack>
          </Grid> */}
          <Grid mx="1rem" className="txt-grey">
            <Box>
              {/* <TextField
                id="standard-basic"
                label="Camp Name"
                variant="standard"
                name="campName"
                className="txt-grey"
                value={formData.campName}
                onChange={(e) => doSomething1(e)}
                {...register("campName")}
                error={errors.campName ? true : false}
                sx={{
                  width: 220,
                }}
              ></TextField> */}

              <TextField
                id="standard-basic"
                // sx={{ width: 200 }}
                sx={{ width: 185 }}
                label="Camp Name"
                variant="standard"
                className="txt-grey campnameedit"
                name="campName"
                {...register("campName")}
                error={errors.campName ? true : false}
                value={formData.campName}
                onChange={(event) => storeInfo(event)}
              />

              <Typography
                color="error.main"
                // ml="2rem"
                sx={{ fontSize: "12px", textAlign: "left" }}
              >
                {errors.campName?.message}
              </Typography>
            </Box>
            {/* <Box>
                <TextField
                  id="standard-basic"
                  label="Location"
                  variant="standard"
                  className="txt-grey "
                  name="location"
                  // value={formData.location}
                  onChange={(event) => storeInfo(event)}
                />
              </Box> */}
            <Grid container spacing={4}>
              {/* <Box sx={{ display: "flex", alignItems: "flex-end" }}> */}
              <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  type="date"
                  id="input-with-sx"
                  label="Start Date "
                  format="YYYY-MM-DD"
                  defaultValue=" "
                  variant="standard"
                  {...register("campStartdate")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: latestDate,
                  }}
                  // sx={{ width: 220 }}
                  sx={{ width: 185 }}
                  value={formData.campStartdate}
                  name="campStartdate"
                  onChange={(e) => doSomething(e)}
                  error={errors.campStartdate ? true : false}
                />

                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campStartdate?.message}
                  {campErr ? "Start date should be before End date" : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  id="input-with-sx"
                  label="End Date"
                  variant="standard"
                  format="YYYY-MM-DD"
                  type="date"
                  defaultValue=" "
                  // sx={{ width: 220 }}
                  sx={{ width: 185 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    // max: latestDate,
                    min: regDate,
                  }}
                  // align="center"
                  name="campEnddate"
                  {...register("campEnddate")}
                  error={errors.campEnddate ? true : false}
                  value={formData.campEnddate}
                  onChange={(e) => getEndDate(e)}
                />
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campEnddate?.message}
                  {campErr ? "End date should be after Start date" : ""}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  id="standard-basic"
                  // sx={{ width: 200 }}
                  sx={{ width: 185 }}
                  label="Location"
                  variant="standard"
                  className="txt-grey "
                  name="location"
                  {...register("location")}
                  error={errors.location ? true : false}
                  value={formData.location}
                  onChange={(event) => storeInfo(event)}
                />
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.location?.message}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              {/* <Box sx={{ display: "flex", alignItems: "flex-end" }}> */}
              <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  id="input-with-sx"
                  label="Registration open date "
                  variant="standard"
                  format="YYYY-MM-DD"
                  type="date"
                  defaultValue=" "
                  sx={{ width: 185 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    // max: latestDate,
                    max: regDate,
                  }}
                  // align="center"
                  name="campRegStartdate"
                  {...register("campRegStartdate")}
                  error={errors.campRegStartdate ? true : false}
                  value={formData.campRegStartdate}
                  onChange={(e) => getRegStartDate(e)}
                  // onChange={(event) => storeInfo(event)}
                />
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campRegStartdate?.message}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  id="input-with-sx"
                  label="Registration Close date "
                  variant="standard"
                  format="YYYY-MM-DD"
                  type="date"
                  defaultValue=" "
                  sx={{ width: 185 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    // max: latestDate,
                    max: regDate,
                  }}
                  // align="center"
                  name="campRegEnddate"
                  {...register("campRegEnddate")}
                  error={errors.campRegEnddate ? true : false}
                  value={formData.campRegEnddate}
                  // onChange={(event) => storeInfo(event)}
                  onChange={(e) => getRegEndDate(e)}
                />
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campRegEnddate?.message}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  id="input-with-sx"
                  label="Registration close date"
                  format="YYYY-MM-DD"
                  variant="standard"
                  type="date"
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  align="center"
                  name="campRegEnddate"
                  value={formData.campRegEnddate}
                  onChange={(event) => storeInfo(event)}
                  {...register("campRegEnddate")}
                  error={errors.campRegEnddate ? true : false}
                  inputProps={{
                    max: today,
                  }}
                />
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campRegEnddate?.message}
                </Typography>
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    id="input-with-sx"
                    label="Registration close date & time"
                    variant="standard"
                    type="datetime-local"
                    sx={{ width: 180 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    align="center"
                    name="campRegEnddate"
                    value={formData.campRegEnddate}
                    onChange={(event) => storeInfo(event)}
                  />
                </Grid > */}
              <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  id="standard-basic"
                  label="Max Spots:   "
                  variant="standard"
                  sx={{ width: 185 }}
                  // sx={{ my: "1rem" }}
                  className="txt-grey"
                  name="maxSpots"
                  {...register("maxSpots")}
                  value={formData.maxSpots}
                  onChange={(event) => storeInfo(event)}
                  error={errors.maxSpots ? true : false}
                />
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.maxSpots?.message}
                  {spotErr}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={4} mt="0.25rem">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Minimum Age
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="campMinAge"
                    sx={{ width: 185 }}
                    {...register("campMinAge")}
                    value={formData.campMinAge}
                    onChange={(event) => storeInfo(event)}
                    error={errors.campMinAge ? true : false}
                    label="Select Age Group"
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campMinAge?.message}
                  {errMsg}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} mt="0.25rem">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Maximum Age
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="campMaxAge"
                    sx={{ width: 185 }}
                    {...register("campMaxAge")}
                    value={formData.campMaxAge}
                    onChange={(event) => storeInfo(event)}
                    error={errors.campMaxAge ? true : false}
                    label="Select Age Group"
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campMaxAge?.message}
                  {errMsg}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={4} mt="0.35rem">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Camp Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="campType"
                    sx={{ width: 185 }}
                    {...register("campType")}
                    value={formData.campType}
                    onChange={(event) => storeInfo(event)}
                    error={errors.campType ? true : false}
                    label="Select Camp Type"
                    inputProps={{
                      readOnly: campStatus === "PUBLISHED" ? true : false,
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="BOYS CAMP">Boys Camp</MenuItem>
                    <MenuItem value="GIRLS CAMP">Girls Camp</MenuItem>
                    <MenuItem value="SUPPORT CAMP">Support Camp</MenuItem>
                    <MenuItem value="CAMP CONFIDENCE">Camp Confidence</MenuItem>
                    <MenuItem value="ADVENTURE CAMP">Adventure Camp</MenuItem>
                    <MenuItem value="YOUNG LADIES GETAWAY">
                      Young Ladies Getaway
                    </MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campMaxAge?.message}
                </Typography>
              </Grid>
            </Grid>
            {/* </Stack> */}
          </Grid>

          <Box mt="2rem">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 500 }}
                aria-label="customized table"
                my="1rem"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Group</StyledTableCell>
                    <StyledTableCell align="center">
                      Group Leader
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Support Staff
                    </StyledTableCell>
                    <StyledTableCell align="center">Therapist</StyledTableCell>
                    <StyledTableCell align="center">
                      Medication Manager
                    </StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">
                        {/* <input
                            type="text"
                            value={rows.groupLeader}
                            // onChange={(e) =>
                            //   handleRowChange(
                            //     row.id,
                            //     "groupLeader",
                            //     e.target.value
                            //   )
                            // }
                          /> */}

                        <Box>
                          <TextField
                            id="standard-basic"
                            // label="Camp Name"
                            variant="standard"
                            name="campName"
                            className="txt-grey"
                            value={row.group}
                            sx={{ width: 150 }}
                            onChange={(e) =>
                              handleRowChange(
                                row.id,
                                "group",
                                e.target.value,
                                "",
                                ""
                              )
                            }
                          ></TextField>
                        </Box>
                      </TableCell>

                      <TableCell align="center">
                        {row.groupLeader ? (
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                          >
                            <Grid item>{row.groupLeader}</Grid>
                            <Grid
                              item
                              mt="0.2rem"
                              ml="0.75rem"
                              component="img"
                              sx={{
                                width: "0.75rem",
                                height: "0.75rem",
                              }}
                              alt="Logo"
                              onClick={() => handleOpen(row.id, "groupLeader")}
                              src={editIcon}
                            ></Grid>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            mx="1.5rem"
                            component="img"
                            sx={{
                              // height: "30px",
                              width: "1.5rem",
                            }}
                            alt="Logo"
                            src={Searchdvance}
                            onClick={() => handleOpen(row.id, "groupLeader")}
                          ></Grid>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.supportStaff ? (
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                          >
                            {/* <Grid item>{row.supportStaff}</Grid> */}
                            {row.supportStaff.map((ssStaff, index) => (
                              <Grid
                                item
                                key={index}
                                sx={{
                                  marginLeft: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    background: "#f5f5f5",
                                    borderRadius: "5px",
                                    padding: "5px",
                                    marginBottom: "5px",
                                    marginLeft: "6px",
                                  }}
                                >
                                  <span>{ssStaff}</span>
                                  <img
                                    sx={{
                                      width: "0.5rem",
                                      height: "0.5rem",
                                      cursor: "pointer",
                                    }}
                                    alt="Logo"
                                    onClick={() =>
                                      handleRemoveSupportStaff(row.id, index)
                                    }
                                    src={crossss}
                                  />
                                </div>
                              </Grid>
                            ))}

                            <Grid
                              item
                              mt="0.2rem"
                              ml="0.75rem"
                              component="img"
                              sx={{
                                width: "0.75rem",
                                height: "0.75rem",
                              }}
                              alt="Logo"
                              onClick={() => handleOpen(row.id, "supportStaff")}
                              src={editIcon}
                            ></Grid>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            mx="1.5rem"
                            component="img"
                            sx={{
                              width: "1.5rem",
                            }}
                            alt="Logo"
                            src={Searchdvance}
                            onClick={() => handleOpen(row.id, "supportStaff")}
                          ></Grid>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.therapist ? (
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                          >
                            <Grid item>{row.therapist}</Grid>
                            <Grid
                              item
                              mt="0.2rem"
                              ml="0.75rem"
                              component="img"
                              sx={{
                                width: "0.75rem",
                                height: "0.75rem",
                              }}
                              alt="Logo"
                              onClick={() => handleOpen(row.id, "therapist")}
                              src={editIcon}
                            ></Grid>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            mx="1.5rem"
                            component="img"
                            sx={{
                              width: "1.5rem",
                            }}
                            alt="Logo"
                            src={Searchdvance}
                            onClick={() => handleOpen(row.id, "therapist")}
                          ></Grid>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.medicationManager ? (
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                          >
                            <Grid item>{row.medicationManager}</Grid>
                            <Grid
                              item
                              mt="0.2rem"
                              ml="0.75rem"
                              component="img"
                              sx={{
                                width: "0.75rem",
                                height: "0.75rem",
                              }}
                              alt="Logo"
                              onClick={() =>
                                handleOpen(row.id, "medicationManager")
                              }
                              src={editIcon}
                            ></Grid>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            mx="1.5rem"
                            component="img"
                            sx={{
                              width: "1.5rem",
                            }}
                            alt="Logo"
                            src={Searchdvance}
                            onClick={() =>
                              handleOpen(row.id, "medicationManager")
                            }
                          ></Grid>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {/* <Grid
                          item
                          mx="1.5rem"
                          component="img"
                          sx={{
                            width: "1rem",
                          }}
                          alt="Logo"
                          src={Minus}
                          onClick={() => handleDeleteRow(row.id)}
                        ></Grid> */}
                        <Button
                          onClick={() => handleDeleteRow(row.id)}
                          variant="contained"
                          sx={{
                            bgcolor: "#F21010",
                            color: "white",
                            textTransform: "none",
                            "&:hover": {
                              background: "#F21010",
                              color: "white",
                            },
                            mx: "0.5rem",
                            //   width: "150px",
                          }}
                          className="gilroy"
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <tfoot>
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      {/* <IconButton onClick={handleAddRow}>Add Row</IconButton> */}
                      {/* <Box
                       disabled={rows.length > 6 ? true : false}
                        component="img"
                        src={Add}
                        onClick={handleAddRow}
                        sx={{ width: "1rem" }}
                      ></Box> */}
                      <Button
                        disabled={rows.length > 6 ? true : false}
                        variant="contained"
                        sx={{
                          bgcolor: "#4C9F70",
                          color: "white",
                          textTransform: "none",
                          "&:hover": { background: "#4C9F70", color: "white" },
                          //   width: "150px",
                          mx: "0.5rem",
                        }}
                        className="gilroy"
                        onClick={handleAddRow}
                      >
                        Add
                      </Button>
                    </TableCell>
                  </TableRow>
                </tfoot>
              </Table>
            </TableContainer>
            {/* {groupError === true ? (
              <Typography color="error">Group info is missing</Typography>
            ) : (
              ""
            )} */}
          </Box>

          {/* <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                mt: "3rem",
                width: "40%",
                mb: "1rem",
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              name="description"
              {...register("description")}
              value={formData.description}
              onChange={(event) => storeInfo(event)}
              error={errors.description ? true : false}
            // defaultValue="Default Value"
            />
            <Typography
              color="error.main"
              sx={{ fontSize: "12px", textAlign: "left" }}
            >
              {errors.description?.message}
            </Typography>
          </Box>
          <Box>
           
            <TextField
              id="standard-basic"
              label="Fee"
              variant="standard"
              className="txt-grey"
              name="campFee"
              placeholder="$"
              {...register("campFee")}
              value={formData.campFee}
              onChange={(event) => storeInfo(event)}
              error={errors.campFee ? true : false}
            ></TextField>
            <Typography
              color="error.main"
              sx={{ fontSize: "12px", textAlign: "left" }}
            >
              {errors.campFee?.message}
            </Typography>
          </Box> */}

          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item md={8} sm={12}>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": {
                    mt: "3rem",
                    width: "100%",
                    mb: "1rem",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  name="description"
                  {...register("description")}
                  value={formData.description}
                  onChange={(event) => storeInfo(event)}
                  error={errors.description ? true : false}
                />
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.description?.message}
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item md={5} sm={12} >
              <Box>
                
                <TextField
                  // id="standard-basic"
                  id="outlined-basic"
                  label="Fee for Support Staff"
                  variant="outlined"
                  className="txt-grey"
                  name="campFeeSS"
                  // size='small'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  
                  {...register("campFeeSS")}
                  error={errors.campFeeSS ? true : false}
                ></TextField>
               
                
                
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campFeeSS?.message}
                </Typography>
              </Box>
            </Grid> */}
          </Grid>
          <Box mt={5} mb={5}>
            {" "}
            <Divider />
          </Box>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item md={6} sm={12}>
              <Box mt="2rem">
                <TableContainer component={Paper}>
                  <Table
                    // sx={{ minWidth: 500 }}
                    aria-label="customized table"
                    my="1rem"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">Date</StyledTableCell>
                        <StyledTableCell align="center">Hours</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {console.log(dateAndHours, "hussssssssss")}
                      {dateAndHours?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {/* {moment(item).format("DD-MM-YYYY")} */}
                            {item.campDate}
                          </TableCell>
                          <TableCell align="center">
                            {/* <TextField
                              id="standard-basic"
                              // label="Camp Name"
                              type='number'
                              variant="standard"
                              name="campHours"
                              className="txt-grey"
                                InputProps={{
                                inputProps: { 
                                    max: 24, min: 0 
                                }
                            }}
                              // value={row.group}
                              sx={{ width: 80 }}
                              onChange={(event) => setHoursValue(event, index)}
                            ></TextField> */}
                            <FormControl variant="standard">
                              {/* <InputLabel id="demo-simple-select-standard-label">
                    Select Minimum Age
                  </InputLabel> */}

                              <Select
                                labelId="demo-simple-select-standard-label"
                                id={`campHours-${item.slno}`}
                                name="campHours"
                                sx={{ width: 120, maxHeight: 50 }}
                                // value={formData.campAgegroup}
                                // defaultValue={12}
                                // label="Select Age Group"
                                onChange={(event) =>
                                  setHoursValue(event, index)
                                }
                                value={item.campHours}
                                // onChange={(event) => storeInfo(event)}
                              >
                                {/* <MenuItem value="0">0</MenuItem> */}
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="3">3</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="6">6</MenuItem>
                                <MenuItem value="7">7</MenuItem>
                                <MenuItem value="8">8</MenuItem>
                                <MenuItem value="9">9</MenuItem>
                                <MenuItem value="10">10</MenuItem>
                                <MenuItem value="11">11</MenuItem>
                                <MenuItem value="12">12</MenuItem>
                                <MenuItem value="13">13</MenuItem>
                                <MenuItem value="14">14</MenuItem>
                                <MenuItem value="15">15</MenuItem>
                                <MenuItem value="16">16</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {campErr ? "Start and End Dates are not valid" : ""}
                  {/* {campHoursErr ? ('Hours should only be numbers and less than 24') : ('')} */}
                </Typography>

                {/* {groupError === true ? (
              <Typography color="error">Group info is missing</Typography>
            ) : (
              ""
            )} */}
              </Box>
            </Grid>

            {/* <Grid item md={5} sm={12} >
              <Box>
                
                <TextField
                  id="outlined-basic"
                  label="Fee for Participants/hr"
                  variant="outlined"
                  className="txt-grey"
                  name="campFeeParticipants"
                  
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  {...register("campFeeParticipants")}
                  error={errors.campFeeParticipants ? true : false}
                ></TextField>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campFeeParticipants?.message}
                </Typography>
              </Box>
            </Grid> */}
          </Grid>
          {/* <Box mt={5} mb={5}>
            {" "}
            <Divider />
          </Box> */}
          <Grid container display="flex" mt="2rem" columnSpacing={10}>
            <Grid item md={3} sm={12}>
              <Box>
                <TextField
                  id="outlined-basic"
                  label="Fee for Participants/hr"
                  variant="outlined"
                  className="txt-grey"
                  name="campFeeParticipants"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  value={formData.campFeeParticipants}
                  {...register("campFeeParticipants")}
                  onChange={(event) => storeInfo(event)}
                  error={errors.campFeeParticipants ? true : false}
                ></TextField>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campFeeParticipants?.message}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={3} sm={12} className="feessedit">
              <Box>
                <TextField
                  // id="standard-basic"
                  id="outlined-basic"
                  label="Fee for Support Staff"
                  variant="outlined"
                  className="txt-grey"
                  name="campFeeSS"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  value={formData.campFeeSS}
                  {...register("campFeeSS")}
                  onChange={(event) => storeInfo(event)}
                  error={errors.campFeeSS ? true : false}
                ></TextField>

                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campFeeSS?.message}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box mt={5} mb={5}>
            {" "}
            <Divider />
          </Box>

          {/* {list.map((ele, index) => {
            { console.log(list.length) }
            return (
              
            )

          })} */}

          <Box sx={{ color: "#AFAFAF", my: "1rem" }}>
            Upload Thumbnail (Only One Picture)
          </Box>

          <form onSubmit={uploadThumnails} encType="multipart/form-data">
            {/* <Box>
              <input type="file" ref={inputRef} onChange={handleThumbnailChange} />
            </Box> */}

            <Button
              onClick={() => hiddenFileInputThum.current.click()}
              variant="contained"
              // type="submit"
              // variant="contained"
              mx="0rem"
              sx={{
                px: "2rem",
                bgcolor: "#379592",
                my: "0.45rem",
                // width:"15%",
                textTransform: "none",
                "&:hover": { background: "#379592" },
              }}
              className="gilroy"
            >
              Choose File
            </Button>
            <Box>
              <input
                type="file"
                multiple
                style={{ display: "none" }}
                placeholder=""
                ref={hiddenFileInputThum}
                onChange={handleThumbnailChange}
                accept="image/jpeg, image/png"
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              mx="0rem"
              sx={{
                px: "2rem",
                bgcolor: "#379592",
                my: "0.75rem",
                textTransform: "none",
                width: "138px",
                "&:hover": { background: "#379592" },
              }}
              className="gilroy"
            >
              Upload
            </Button>
          </form>

          <Box>{thumbStatus}</Box>

          {/* <Box>
            <img src={thumbnailPath} style={{ height: "20%", width: "20%" }} />
          </Box> */}
          <Box>
            <span className="thumbnail-span">
              {thumbnailPath != null ? (
                <img
                  src={thumbnailPath}
                  style={{ height: "20%", width: "20%", marginTop: "0.5%" }}
                  // style={{ height: "70%", mx: "0.5rem" }}
                />
              ) : (
                ""
              )}

              {thumbnailPath != null ? (
                <img
                  src={CrossGreen}
                  onClick={removeimg}
                  className="cross-img"
                />
              ) : (
                ""
              )}
            </span>
          </Box>

          {errorThumbnail ? <Box> please select thumbnail </Box> : ""}
          <Divider />
          <Box sx={{ color: "#AFAFAF", my: "1rem" }}>Videos</Box>

          <Button
            // type="submit"
            variant="contained"
            mx="0rem"
            sx={{
              px: "2rem",
              bgcolor: "#379592",
              my: "0.75rem",
              textTransform: "none",
              width: "138px",
              "&:hover": { background: "#379592" },
            }}
            onClick={handleVideoRow}
            className="gilroy"
          >
            Add
          </Button>

          {videoRow &&
            videoRow.length > 0 &&
            videoRow.map((item, index) => {
              return (
                // <span className="thumbnail-span">
                <div>
                  {/* {item.actualUrl != null ? ( */}
                  <div key={index} className="thumbnail-container">
                    <Grid container display="row" alignItems="center">
                      <Grid item md={4} sx={{ textAlign: "center" }}>
                        <TextField
                          id="outlined-basic"
                          multiline
                          label="Vimeo URL"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={item.actualUrl}
                          onChangeCapture={(e) => {
                            generateThumbnail(e, item);
                          }}
                          // onChange={(e) => { handleDesc(e, item) }}

                          size="small"
                          sx={{ my: "0.5rem", width: "90%" }}
                        ></TextField>

                        <Box sx={{ textAlign: "center" }}>
                          {item.thumbnailurl !== "" ? (
                            <a href={item.actualUrl} target="_blank">
                              <img
                                src={item.thumbnailurl}
                                style={{
                                  height: "45%",
                                  width: "50%",
                                  my: "0.5rem",
                                }}
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>

                      <Grid item md={8}>
                        <Stack direction="row">
                          <Box sx={{ width: "100%" }}>
                            <TextField
                              id="outlined-multiline-static"
                              label="Description"
                              multiline
                              rows={5}
                              name="description"
                              onChange={(e) => {
                                handleDesc(e, item);
                              }}
                              value={item.description}
                              // onChange={(event) => handleComboDescription(event, index)}
                              sx={{ width: "100%", borderRadius: "20px" }}
                            />
                          </Box>
                          <div className="video-thumb">
                            <img
                              src={CrossGreen}
                              onClick={() => removeUrlItem(index)}
                              // className="video-thumb"
                            />
                          </div>
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>

                  {/* ) : (
                  ""
                )} */}

                  {/* {item.actualUrl != null ? ( */}
                  {/* < Box>

                    <img
                      src={CrossGreen}
                      onClick={() => removeUrlItem(index)}
                      className="video-thumb"
                    />

                  </Box> */}

                  {/* ) : (
                  ""
                )} */}
                </div>
              );
            })}

          <Divider />

          <Box sx={{ color: "#AFAFAF", my: "1rem" }}>Attachments</Box>

          <form onSubmit={uploadAttachments} encType="multipart/form-data">
            <Button
              onClick={() => hiddenFileInput.current.click()}
              variant="contained"
              // variant="contained"
              mx="0rem"
              sx={{
                px: "2rem",
                bgcolor: "#379592",
                my: "0.45rem",
                // width:"15%",
                textTransform: "none",
                "&:hover": { background: "#379592" },
              }}
              className="gilroy"
            >
              Choose File
            </Button>
            <Box>
              <input
                type="file"
                multiple
                style={{ display: "none" }}
                placeholder=""
                ref={hiddenFileInput}
                onChange={handleFileChange}
                // accept="image/jpeg, image/png, image/jpg"
                // accept='.doc,.docx,application/pdf'
                accept="image/jpeg, image/png, image/jpg,image/svg,application/pdf,application/PDF "
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              mx="0rem"
              maxcount={5}
              sx={{
                px: "2rem",
                bgcolor: "#379592",
                my: "0.75rem",
                textTransform: "none",
                width: "138px",
                "&:hover": { background: "#379592" },
              }}
              className="gilroy"
            >
              Upload
            </Button>
          </form>

        
          <Box>
            <Box>
              {attachmentsArr.map((link, index) => {
                let x = link.docTitle.search(".pdf");
                let y = link.docTitle.search(".doc");
                {
                  console.log(link.docTitle, x);
                }
                if (x !== -1 || y !== -1)
                  return (
                    <Box>
                      <Grid container display="flex" my="0.5rem">
                        <Grid item>
                          <Box
                            component="img"
                            sx={{ height: "70%", mx: "0.5rem" }}
                            alt="Logo"
                            src={PdfIcon}
                          ></Box>
                          <a href={link.path} target="_blank">
                            {link.docTitle}
                          </a>
                        </Grid>
                        <Grid item>
                          <img
                            src={CrossGreen}
                            onClick={() => removeAttachment(index)}
                            style={{ height: "15px", cursor: "pointer" }}
                            // className="cross-img"
                       
                          />
                        </Grid>
                      </Grid>
                      <Box>
                        {link.docPath !== "" ? "Uploaded" : "Not Uploaded"}
                      </Box>
                    </Box>
                    // <span className="thumbnail-span">
                    //   {link.path != null ? (
                    //     <Box sx={{ color: '#0075CE'}}>

                    //   <a href={link.path} target="_blank">
                    //     {link.docTitle}
                    //   </a>
                    // </Box>
                    //   ) : (
                    //     ""
                    //   )}

                    //   {link.path != null ? (
                    //     <img
                    //       src={CrossGreen}
                    //       onClick={() => removeAttachment(index)}
                    //       className="cross-img"
                    //     />
                    //   ) : (
                    //     ""
                    //   )}
                    // </span>
                  );
                else if (x === -1 || y === -1)
                  return (
                    // <span className="thumbnail-span">
                    //   {link.path != null ? (
                    //     <img
                    //       src={link.path}
                    //       style={{ height: "20%", width: "20%", marginTop: '0.5%', marginLeft: '2%' }}

                    //     />
                    //   ) : (
                    //     ""
                    //   )}

                    //   {link.path != null ? (
                    //     <img
                    //       src={CrossGreen}
                    //       onClick={() => removeAttachment(index)}
                    //       className="cross-img"
                    //     />
                    //   ) : (
                    //     ""
                    //   )}
                    // </span>
                    <Box>
                      <Grid
                        container
                        display="flex"
                        my="0.5rem"
                        className="thumbnail-span"
                      >
                        <Grid item>
                          <Box sx={{ color: "#0075CE" }}>
                            <img
                              src={link.path}
                              style={{
                                height: "120px",
                                width: "200px",
                                alignSelf: "center",
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item>
                          <img
                            src={CrossGreen}
                            onClick={() => removeAttachment(index)}
                            style={{ height: "15px", cursor: "pointer" }}
                            // className="cross-img"
                            // className='cross-pdf'
                            className="removeIcon"
                          />
                        </Grid>
                      </Grid>
                      <Box>
                        {link.docPath !== "" ? "Uploaded" : "Not Uploaded"}
                      </Box>
                    </Box>
                  );
              })}
            </Box>
          </Box>
          {/* {errorFile ? <Box> please select Attachments </Box> : ""} */}

          {/* <Grid display='flex' justifyContent='center'> */}

          {/* <input 
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{display:"none"}}
            /> */}

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  px: "2rem",
                  mx: "1rem",
                  bgcolor: "#379592",
                  textTransform: "none",
                  "&:hover": { background: "#379592" },
                }}
                className="gilroy"
                onClick={handleSubmit(onSubmit)}
                // disabled={campStatus === 'ACTIVE' ? true : false}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              {/* <Link className='link' to='/ViewCamp'> */}
              <PublishButton
                variant="contained"
                className="gilroy"
                sx={{ px: "2rem", mx: "1rem" }}
                // onClick={() => onSubmit()}
                onClick={handleSubmit(onPublish)}
              >
                Publish
              </PublishButton>
              {/* </Link> */}
            </Grid>
          </Grid>
          <div>
            {/* <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box>
                  <Box textAlign="center" my="1rem">
                    {heading}
                  </Box>
                  <Paper
                    component="form"
                    sx={{
                      border: 1,
                      mx: "1rem",
                      display: "flex",
                      alignItems: "center",
                      width: 300,
                      justifyContent: "center",
                    }}
                  >
                    <InputBase
                      sx={{ ml: 2, flex: 1 }}
                      textAlign="center"
                      placeholder="Search"
                      inputProps={{ "aria-label": "search google maps" }}
                    />
                    <IconButton
                      type="button"
                      sx={{ py: "10px", px: "2rem" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  <Grid container display="flex" mx="1rem" my="1rem">
                    <AccountCircleIcon px="1rem" />
                  </Grid>

                  <Divider orientation="horizontal" color="#379592" />

                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-name-label">Name</InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={personName}
                      onChange={handleChange3}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                    >
                      {list.map((name, index) => (
                        <MenuItem
                          key={index}
                          value={name.userName}
                          style={getStyles(name.userName, personName, theme)}
                        >
                          {name.userName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box textAlign="center">
                    <PublishButton
                      variant="contained"
                      className="gilroy"
                      sx={{ px: "2rem", mx: "1rem" }}
                      onClick={() => {
                        assignData(ind, personName, heading);
                      }}
                    >
                      Save
                    </PublishButton>
                  </Box>
                </Box>
              </Box>
            </Modal> */}
          </div>
        </div>
      </ThemeProvider>
      {/* </BrowserRouter> */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{ maxHeight: "400px" }}>
          {(() => {
            if (selectedType === "groupLeader") {
              return (
                <div>
                  <Box sx={style}>
                    <Typography className={classes.mainheader}>
                      Group Leader
                    </Typography>

                    <Search
                      width="20%"
                      sx={{
                        backgroundColor: "#F8F8F8",
                        border: 1,
                        borderColor: "#909090",
                        borderRadius: "0.75rem",
                        "&:hover": {
                          // backgroundColor: alpha(theme.palette.common.white, 0.25),
                          // backgroundColor:'pink',
                          color: "#379592",
                          border: 2,
                          borderColor: "rgba(55, 149, 146, 0.8)",
                        },
                      }}
                    >
                      <SearchIconWrapper>
                        <SearchIcon sx={{ color: "#909090" }} />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        value={search}
                        onChange={handleSearchChange}
                        inputProps={{ "aria-label": "search" }}
                      />
                    </Search>
                    <Stack direction="row" spacing={3} my="1.5rem">
                      <Box component="img" src={User}></Box>
                      <Box sx={{ color: "#379592" }}>{seachName}</Box>
                    </Stack>

                    <List className={classes.listStyle}>
                      {filteredList
                        .filter(
                          (ele) =>
                            ele.rolesName !== "SUPPORT STAFF" &&
                            ele.userStatus === "ACTIVE"
                        )
                        .map((item) => (
                          <Typography
                            onClick={() =>
                              selectItem(item.userName, item.userId)
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            {item.userName}
                          </Typography>
                        ))}
                    </List>
                    <Box display="flex" justifyContent="center" my="0.5rem">
                      <Button
                        variant="contained"
                        disabled={!seachName}
                        onClick={() =>
                          handleRowChange(
                            rowId,
                            selectedType,
                            seachName,
                            "groupLeaderId",
                            userId
                          )
                        }
                        sx={{
                          bgcolor: "#379592",
                          color: "white",
                          textTransform: "none",
                          "&:hover": { background: "#379592" },
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </div>
              );
            } else if (selectedType === "supportStaff") {
              return (
                <div>
                  <Box sx={style}>
                    <Typography className={classes.mainheader}>
                      Support Staff
                    </Typography>

                    <Search
                      width="20%"
                      sx={{
                        backgroundColor: "#F8F8F8",
                        border: 1,
                        borderColor: "#909090",
                        borderRadius: "0.75rem",
                        "&:hover": {
                          // backgroundColor: alpha(theme.palette.common.white, 0.25),
                          // backgroundColor:'pink',
                          color: "#379592",
                          border: 2,
                          borderColor: "rgba(55, 149, 146, 0.8)",
                        },
                      }}
                    >
                      <SearchIconWrapper>
                        <SearchIcon sx={{ color: "#909090" }} />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        value={search}
                        onChange={handleSearchChange}
                        inputProps={{ "aria-label": "search" }}
                      />
                    </Search>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        maxWidth: "400px",
                        maxHeight: maxSelectedItemsHeight,
                        overflowY: "auto", // Add a scrollbar when the content overflows the maximum height
                      }}
                    >
                      {seachName1 &&
                        seachName1.length > 0 &&
                        seachName1.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              background: "#f5f5f5",
                              borderRadius: "5px",
                              padding: "5px",
                              marginBottom: "5px",
                              marginLeft: "6px",
                            }}
                          >
                            {/* <img
                              src={User}
                              alt="User"
                              style={{ height: "30px" }}
                            /> */}
                            <span style={{ color: "#379592" }}>{item}</span>
                            <img
                              src={CrossGreen}
                              alt="Remove"
                              style={{ height: "15px", cursor: "pointer" }}
                              onClick={() => handleRemove(item)}
                            />
                          </div>
                        ))}
                    </div>
                    <List className={classes.listStyle}>
                      {supportStafflist
                        .filter((item) =>
                          item.userName

                            .toLowerCase()

                            .includes(search.toLowerCase())
                        )
                        .map((item) => (
                          <Typography
                            onClick={() =>
                              selectItemSS(item.userName, item.userId)
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            {item.userName}
                          </Typography>
                        ))}
                    </List>
                    <Box display="flex" justifyContent="center" my="0.5rem">
                      {userId}
                      <Button
                        variant="contained"
                        disabled={!seachName1}
                        onClick={() =>
                          handleRowChange(
                            rowId,
                            selectedType,
                            seachName1,
                            "supportStaffId",
                            userId1
                          )
                        }
                        sx={{
                          bgcolor: "#379592",
                          color: "white",
                          textTransform: "none",
                          "&:hover": { background: "#379592" },
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </div>
              );
            } else if (selectedType === "therapist") {
              return (
                <div>
                  <Box sx={style}>
                    <Typography className={classes.mainheader}>
                      Therapist
                    </Typography>

                    <Search
                      width="20%"
                      sx={{
                        backgroundColor: "#F8F8F8",
                        border: 1,
                        borderColor: "#909090",
                        borderRadius: "0.75rem",
                        "&:hover": {
                          // backgroundColor: alpha(theme.palette.common.white, 0.25),
                          // backgroundColor:'pink',
                          color: "#379592",
                          border: 2,
                          borderColor: "rgba(55, 149, 146, 0.8)",
                        },
                      }}
                    >
                      <SearchIconWrapper>
                        <SearchIcon sx={{ color: "#909090" }} />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        value={search}
                        onChange={handleSearchChange}
                        inputProps={{ "aria-label": "search" }}
                      />
                    </Search>
                    <Stack direction="row" spacing={3} my="1.5rem">
                      <Box component="img" src={User}></Box>
                      <Box sx={{ color: "#379592" }}>{seachName}</Box>
                    </Stack>
                    <List className={classes.listStyle}>
                      {filteredList
                        .filter(
                          (ele) =>
                            ele.rolesName !== "SUPPORT STAFF" &&
                            ele.userStatus === "ACTIVE"
                        )
                        .map((item) => (
                          <Typography
                            onClick={() =>
                              selectItem(item.userName, item.userId)
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            {item.userName}
                          </Typography>
                        ))}
                    </List>
                    <Box display="flex" justifyContent="center" my="0.5rem">
                      <Button
                        variant="contained"
                        disabled={!seachName}
                        onClick={() =>
                          handleRowChange(
                            rowId,
                            selectedType,
                            seachName,
                            "therapistId",
                            userId
                          )
                        }
                        sx={{
                          bgcolor: "#379592",
                          color: "white",
                          textTransform: "none",
                          "&:hover": { background: "#379592" },
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </div>
              );
            } else {
              return (
                <div>
                  <Box sx={style}>
                    <Typography className={classes.mainheader}>
                      Medication Manager
                    </Typography>

                    <Search
                      width="20%"
                      sx={{
                        backgroundColor: "#F8F8F8",
                        border: 1,
                        borderColor: "#909090",
                        borderRadius: "0.75rem",
                        "&:hover": {
                          // backgroundColor: alpha(theme.palette.common.white, 0.25),
                          // backgroundColor:'pink',
                          color: "#379592",
                          border: 2,
                          borderColor: "rgba(55, 149, 146, 0.8)",
                        },
                      }}
                    >
                      <SearchIconWrapper>
                        <SearchIcon sx={{ color: "#909090" }} />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        value={search}
                        onChange={handleSearchChange}
                        inputProps={{ "aria-label": "search" }}
                      />
                    </Search>
                    <Stack direction="row" spacing={3} my="1.5rem">
                      <Box component="img" src={User}></Box>
                      <Box sx={{ color: "#379592" }}>{seachName}</Box>
                    </Stack>
                    <List className={classes.listStyle}>
                      {filteredList
                        .filter(
                          (ele) =>
                            ele.rolesName !== "SUPPORT STAFF" &&
                            ele.userStatus === "ACTIVE"
                        )
                        .map((item) => (
                          <Typography
                            onClick={() =>
                              selectItem(item.userName, item.userId)
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            {item.userName}
                          </Typography>
                        ))}
                    </List>
                    <Box display="flex" justifyContent="center" my="0.5rem">
                      <Button
                        variant="contained"
                        disabled={!seachName}
                        onClick={() =>
                          handleRowChange(
                            rowId,
                            selectedType,
                            seachName,
                            "medicationManagerId",
                            userId
                          )
                        }
                        sx={{
                          bgcolor: "#379592",
                          color: "white",
                          textTransform: "none",
                          "&:hover": { background: "#379592" },
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </div>
              );
            }
          })()}
        </div>
      </Modal>
    </>
  );
}
