import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
    CardActionArea,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,

    Chip,
    CardContent,
    Divider,
    Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import DefaultImage from "../../Assets/Icons/Icons/DefaultImage.png"
import { apiService, apiService2 } from "../../Services/apiService";
import moment from "moment";
import { Link ,useHistory} from "react-router-dom";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png"
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png"
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";

const rowCount = [1, 2, 3, 4]

export default function SupportStaffWelcomePackPublish() {
    const history = useHistory()
    const [campList, setCampList] = React.useState()
    const [loading, setLoading] = React.useState(false)

    const SaveWelcomePack = () =>{
        
        Swal.fire({
            text: "Are you sure you want to Save?",
            iconHtml: `<img src="${question}" width="100" height="100" >`, 
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#379592",
            cancelButtonText: "No",
            cancelButtonColor: "#909090",
            customClass: {
              container: "my-swal",
              icon:'no-border'
             
            },
          }).then((result)=>{
            if(result.isConfirmed){
                Swal.fire({
                    iconHtml: `<img src="${GreenCheck}" width="100" height="100" >`,
                    text: 'Welcome Pack Saved',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#379592",
                    customClass: {
                      container: "my-swal",
                    },
                  }).then(()=>{
                    history.push('/SupportStaffWelcomePackRepublish')
                  })
            }
            
          }

          )
    }

    const theme = createTheme({
        typography: {
            fontFamily: "gilroyMedium",
        },
        palette: {
            secondary: {
                main: "#379592",
            },
        },

        Muidisabled: {
            styleOverrides: {
                backgroundColor: "purple",
            },
        },
    });

  return (
    <Box>
    <Grid container display='row' >
        <Grid item md={6} sm={12} xs={12}>
            <Stack direction='row' spacing={1}>
                <Link to="/CreateWelcomePack">
                    <Box
                        component="img"
                        sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
                        height="20"
                        alt="Logo"
                        src={ArrowBack}
                    ></Box>
                </Link>
                <Box className="gilroy" alignSelf="center">
                    Review Welcome Pack for Support Staff
                </Box>
            </Stack>
        </Grid>
        <Grid container item md={6} sm={12} xs={12} justifyContent='flex-end'>
            <Button
                variant="contained"
                type="submit"
                sx={{
                    mx: "0.75rem",
                    bgcolor: "#379592",
                    textTransform: "none",
                    "&:hover": { background: "#379592" },
                    width: '90px',
                }}
                className="gilroy"
                onClick={()=>SaveWelcomePack()}
            >
                Save
            </Button>
            <Link to='/WelcomePackRepublish' className='link2'>
            <Button
                variant="contained"
                type="submit"
                sx={{
                    mx: "0.75rem",
                    bgcolor: "#4C9F70",
                    textTransform: "none",
                    width: '90px',
                    "&:hover": { background: "#4C9F70" },
                }}
                className="gilroy"
            >
                Publish
            </Button>
            </Link>
            
        </Grid>

    </Grid>

    <Box my='1rem'>
        <TextField
            id="outlined-multiline-static"
            label="Description"
            multiline
            rows={3}
            name="Pre-information"
            sx={{ width: '50%' }}
        // value={formData.description}
        // onChange={(event) => storeInfo(event)}

        // defaultValue="Default Value"
        />

    </Box>
    <Box my='1rem'>
        <Box>
            Upload Welcome Video
        </Box>

        <form encType="multipart/form-data">
            {/* <Box>
      <input type="file" onChange={handleThumbnailChange} />
    </Box> */}

            <Button
                // onClick={(event) => (hiddenFileInputThum.current.click(),event.currentTarget.value = null)}
                variant="contained"
                type="submit"
                // variant="contained"
                mx="0rem"
                sx={{
                    px: "2rem",
                    bgcolor: "#379592",
                    my: "0.45rem",
                    // width:"15%",
                    textTransform: "none",
                    "&:hover": { background: "#379592" },
                }}
                className="gilroy"
            >
                Choose File
            </Button>
            <Box>
                <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    placeholder=""
                    // ref={hiddenFileInputThum}
                    // onChange={handleThumbnailChange}
                    accept="image/jpeg, image/png"
                />
            </Box>
        </form>
    </Box>

    <Box >
        Welcome Video Uploaded
    </Box>
    <Box component='img' src={DefaultImage} sx={{ width: '20%', my: '0.75rem' }} />
    <Grid container display='row' justifyContent='space-between'>
        <Box>Photos and Videos</Box>
        <Button
            variant="contained"
            type="submit"
            sx={{
                mx: "1rem",
                bgcolor: "#379592",
                textTransform: "none",
                "&:hover": { background: "#379592" },

            }}
            className="gilroy"
        >
            Upload Photo / Video
        </Button>
    </Grid>
    {rowCount.map(() => (
        <Grid container display='flex' sx={{ my: '2rem' }}>
            <Box
                component="img"
                sx={{ width: "15%", borderRadius: '10px' }}
                alt="Logo"
                src={DefaultImage}
            ></Box>
            <Box alignSelf='center' sx={{ width: '80%', ml: '2rem' }}>
                <TextField sx={{ borderColor: '#707070', width: '100%' }}
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={3}
                    InputProps={{
                        readOnly: true,
                    }}
                    defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at scelerisque sapien. Nullam ut tempus elit, sed volutpat diam. In tortor lacus, commodo ut tempor eget."
                />
            </Box>

        </Grid>
    ))}
    <Grid container justifyContent='flex-end'>
        <Button variant="contained" 
        sx={{ 
            px: '2rem',
            mx: '1rem',
            bgcolor: "#909090", 
            "&:hover": { background: "#909090" },
            textTransform: "none",
        }} className='gilroy'>Cancel</Button>
    </Grid>

</Box>

  )
}
