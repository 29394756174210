import React, { useState } from "react";
import {
  Chip,
  CardContent,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { apiService, apiService2 } from "../../Services/apiService";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BlackUser from "../../Assets/Icons/Icons/BlackUser.png";
import { GETCMPWISE_STAFFREGSCOUNT , SERVER_URL} from '../../Services/apiEndpints'

const campList = [
  {
    name: "Rainbow Rock",
    type: "Boys camp",
    date: "22/10/2022",
    status: "Registration Open",
    count: "7/30",
  },
  {
    name: "Camp Salt Waters",
    type: "Boys camp",
    date: "22/10/2022",
    status: "Registration Open",
    count: "7/30",
  },
  {
    name: "Sunset Groove",
    type: "Boys camp",
    date: "22/10/2022",
    status: "Registration Open",
    count: "7/30",
  },
];

export default function SupportStaffCampList() {
  const [datatry, setDataTry] = React.useState([]);
  const [campNo, setCampNo] = React.useState([]);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  // const [campNo, setCampNo] = React.useState([]);


  const handleClickPopover = (event, id) => {
    setAnchorEl2(event.currentTarget);
    console.log(event.currentTarget);
    console.log(id);
    setCampNo(id);
  };

  const handleSetCampName = (campName) => {
    localStorage.setItem("CampName", campName);
  };


  React.useEffect(() => {
    (async () => {
      setLoading(true);
      let gettran = {
        userId: localStorage.getItem("userId"),
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/getcmpwisestaffregscount",
        url: `${SERVER_URL}${GETCMPWISE_STAFFREGSCOUNT}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",

          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let login = await apiService(apiInfo);
      

      const campDataList = login?.data?.responseData[0];
      setLoading(false);
    

 
      campDataList.forEach((element) => {
        if (element.StaffList != null) {
          console.log(element.StaffList);
          element.StaffList = JSON.parse(element.StaffList);
          console.log(element.StaffList);
        }
      });
      console.log(campDataList);
      // console.log("campId",campDataList.campId);
      setDataTry(campDataList);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event){
          window.history.pushState(null, document.title,  window.location.href);
      });
  
  }, []);

  const supportStaffCampList = datatry;
 

  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    palette: {
      secondary: {
        main: "#379592",
      },
    },
    // components: {
    //   MuiCssBaseline: {
    //     styleOverrides: {
    //       "@font-face": {
    //         fontFamily: "gilroyMedium",
    //         src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
    //       },
    //     },
    //   },
    // },
    Muidisabled: {
      styleOverrides: {
        backgroundColor: "purple",
      },
    },
  });

  return (
    // <ThemeProvider theme={theme}>
    // <CssBaseline />
    
    <Box>

      
      <Box>Support Staff</Box>
      {loading === true ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Box>
    
      {supportStaffCampList.map((item, index) => (
        <Box  key={index}
        onClick={() => handleSetCampName( item.campName)}>
             
        {/* <Link to="/SupportStaffApplicants" className="link2"  onClick={(e) =>
          handleClickPopover(e, item.campId)
        } key={index}> */}
            <Link to={`/SupportStaffApplicants/${item.campId}`} className="link2">
    
         
          <Card
            variant="outlined"
            className="cardcamplist"
            sx={{
              backgroundColor: "#F8F8F8",

              color: "#707070",
              my: "0.75rem",
              border: 1,
              borderColor: "#379592",
              borderRadius: "0.75rem",
              py: "0rem",
             
            }}
          >
            <CardContent>
              <Grid
                container
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid
                    container
                    display="flex"
                    alignItems="center"
                    justifyContent="space-evenly"
                  >
                    <Box mx="0.75rem" sx={{ color: "#379592" }}>
                      {item.campName}
                    </Box>
                    <Chip
                      // label={card.campType.charAt(0).toUpperCase()+card.campType.slice(1).toLowerCase()}
                      label={item.campType}
                      variant="outlined"
                      size="small"
                      className="medium"
                      mx="0.75rem"
                      sx={{
                        color: "#707070",
                        backgroundColor: "white",
                        fontSize: "0.5rem",
                        px: "0.5rem",
                        height: "20px",
                      }}
                    />
                    <Box
                      mx="0.75rem"
                      sx={{ fontSize: "0.85rem", color: "#000000" }}
                      className="medium"
                    >
                      Start Date:{item.campStartDate}{" "}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item className="status-reg">
                  <Grid container display="flex" alignItems="center">
                    <Chip
                      label={item.regStatus}
                      variant="outlined"
                      size="small"
                      mx="0.75rem"
                      className="medium"
                      sx={{
                        // color: (card.campStatus==='DRAFT') ? "black" : "white",
                        // backgroundColor:  (item.regStatus==='Registrations Open') ? "#78C091" : "#F21010",
                        color:
                        item.regStatus === "DRAFT"
                          ? "black"
                          : "white",
                      backgroundColor:
                      item.regStatus === "DRAFT"
                          ? "#E2F157"
                          : (item.regStatus === "Registrations Open") ? ("#78C091") : (item.regStatus === "ACTIVE") ? "#FF9529" : (item.regStatus === "COMPLETED" ? ("#78C091") : (item.regStatus === "IN PROGRESS" ? '#FF9529':(item.regStatus === "Registrations Closed") ? '#F21010' : '#78C091' )),
                        px: "1rem",
                        py: "0.85rem",
                      }}
                    />
                    <Box
                      component="img"
                      sx={{
                        height: "1rem",
                        width: "1rem",
                        alignSelf: "center",
                      }}
                      src={BlackUser}
                      alignSelf="center"
                      ml="0.75rem"
                      mr="0.5rem"
                    ></Box>
                    <Box sx={{ fontSize: "15px" }}>{item.countstatus}</Box>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Link>

        {/* </Link> */}
        </Box>
      ))}
      </Box>
       )}
      </Box>
     
      

    // </ThemeProvider>
  );
}
