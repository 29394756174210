import Grid from "@mui/material/Grid";
import { Chip, CardContent, Box } from "@mui/material";
import Card from "@mui/material/Card";
import { Link, useHistory } from "react-router-dom";
import { apiService, apiService2 } from "../../Services/apiService";
import React, { useState } from "react";
import { Group } from "@material-ui/icons";
import { SERVER_URL, US8_URL } from "../../Services/apiEndpints";
import Stack from "@mui/material/Stack";

import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
export default function CampSummaryReports() {
  const [campListData, setCampListData] = useState([]);
  const [rolename, setRolename] = useState(localStorage.getItem("rolesName"));
  let history = useHistory();

  // var string = "GROUP LEADER,THERAPIST"
  //  let = string.split("");

  // React.useEffect(() => {
  //   (async () => {
  //     // setLoading(true);
  //     let gettran = {
  //       _methodId: "12",
  //       userId: localStorage.getItem("userId"),
  //     };

  //     let apiInfo = {
  //       // url: "https://cncdevapi.natsoft.io/api/us8",
  //       url:`${SERVER_URL}${US8_URL}`,
  //       method: "POST",
  //       postData: gettran,
  //       headers: {
  //         "Content-Type": "application/json",
  //         tokenheaderkey: localStorage.getItem("token"),
  //       },
  //     };
  //     let login = await apiService2(apiInfo);

  //     const campDataList = login.data.responseData[0];
  //     campDataList.forEach(element => {
  //       if(element && element.group_info){
  //         element.group_info = element.group_info.split(',')

  //       }
  //     });
  //     // setLoading(false);

  //     console.log(campDataList);

  //     setCampListData(campDataList);

  //   })()
  //     .catch((e) => {
  //       console.error(e);
  //     })
  //     .finally(() => {});
  // }, []);

  React.useEffect(() => {
    (async () => {
      // setLoading(true);
      const rolename = localStorage.getItem("rolesName");
      console.log("rolenamelililili", rolename);

      let gettran = {
        _methodId: rolename === "CAMP STAFF" ? "1" : "12",
        userId: localStorage.getItem("userId"),
      };

      if (rolename === "CAMP STAFF") {
        gettran.roleName = "THERAPIST";
      }

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/us8",
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);

      const campDataList = login.data.responseData[0];
      // setLoading(false);
      // campDataList.forEach(element => {
      //    if(element && element.group_info){
      //       element.group_info = element.group_info.split(',')
      //    }
      //   })
      console.log(campDataList, "hereee");

      // setCampListData(campDataList);
      if (Array.isArray(campDataList)) {
        setCampListData(campDataList);
      }
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);

  const handleSelect = (id, grp) => {
    history.push(`/GoalCamp/${id}`);
    console.log(id, grp);
    // grp = JSON.parse(grp)
    console.log(id, grp);
    localStorage.setItem("groupInfo", [grp]);
    console.log(localStorage.getItem("groupInfo"), "getttt");
  };
  console.log("rolenameeeeeeeeeeeeeeee", rolename);
  const handleRouting = () =>{
    if((localStorage.getItem("rolesName") === 'CAMP MANAGER') || (localStorage.getItem("rolesName") === 'CAMP ADMIN') || (localStorage.getItem("rolesName") === 'ADMIN')){
  history.push('/ReportsForCampManager')
    }
    else if((localStorage.getItem("rolesName") === 'CAMP STAFF') || (localStorage.getItem("rolesName") === 'THERAPIST') || (localStorage.getItem("rolesName") === 'MEDICATION MANAGER') ){
      history.push('/reports')
    }
  }
  return (
    <>
    {campListData && campListData.length > 0 ? (
    <>
    
       <Stack direction="row" spacing={1}>
            {/* <Link to={`/ReportsForCampManager`} className="link2"> */}
              <Box
                alignSelf="center"
                component="img"
                sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
                alt="Logo"
                src={ArrowBack}
                onClick={()=>{handleRouting()}}
              ></Box>
            {/* </Link> */}

            <Box alignSelf="center" px="1rem" className="bold">
              {" "}
              Camp Summary Reports
            </Box>
          </Stack>
   
      {campListData.map((camp, index) => (
        <Box
        // key={index}
        // onClick={() => handleSetCampName( item.campName)}
        >
          {/* <Link to={`/GoalCamp/${camp.camp_id}`} className="link2"> */}
          <Card
            variant="outlined"
            sx={{
              backgroundColor: "#F8F8F8",

              color: "#707070",
              my: "0.75rem",
              border: 1,
              borderColor: "#379592",
              borderRadius: "0.75rem",
              py: "0rem",
              maxHeight: 60,
              cursor: "pointer",
            }}
            onClick={() => handleSelect(camp.camp_id, camp.group_info)}
          >
            <CardContent>
              <Grid
                container
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid
                    container
                    display="flex"
                    alignItems="center"
                    justifyContent="space-evenly"
                  >
                    <Box mx="0.75rem" sx={{ color: "#379592" }}>
                      {camp.camp_name}
                    </Box>
                    <Chip
                      // label={card.campType.charAt(0).toUpperCase()+card.campType.slice(1).toLowerCase()}
                      label={camp.camp_type}
                      variant="outlined"
                      size="small"
                      className="medium"
                      mx="0.75rem"
                      sx={{
                        color: "#707070",
                        backgroundColor: "white",
                        fontSize: "0.5rem",
                        px: "0.5rem",
                        height: "20px",
                      }}
                    />
                    <Box
                      mx="0.75rem"
                      sx={{ fontSize: "0.85rem", color: "#000000" }}
                      className="medium"
                    >
                      Start Date:{camp.camp_startdate}
                    </Box>
                  </Grid>
                </Grid>

             
              </Grid>
            </CardContent>
          </Card>
          {/* </Link> */}
        </Box>
      ))}
    </>
    ) : (
      <Grid mt={4}>
        <Box>No Assigned Camps</Box>
      </Grid>
    )}
    </>
  );
}
