import React, { useRef, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import image from "./Assets/Logo-Main.png";
import Image from "./Assets/candcimg1.png";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Grid, Stack } from "@mui/material";
import "../App.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { apiService } from "../Services/apiService";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import { deepOrange } from "@mui/material/colors";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import cameraIcon from "../Assets/Icons/Icons/cameraIcon.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import axios from "axios";
import Circle from "../Assets/Icons/Icons/Circle.png";
import SelectedCircle from "../Assets/Icons/Icons/SelectedCircle.png";

import { FormHelperText } from "@mui/material";
import {
  SERVER_URL,
  UPDATE_STAFF_PROFILE,
  GET_PROFILE,
  UPLOAD_PROFILE_PIC,
} from "../Services/apiEndpints";

let rolesName = localStorage.getItem("rolesName");

let rolesArr = [];

if (rolesName === "CAMP MANAGER") {
  rolesArr = [
    {
      // rolesId: 9,
      roles_group: "STAFF",
      rolesName: "Camp Staff",
    },
  ];
} else if (rolesName === "ADMIN" || rolesName === "CAMP ADMIN") {
  rolesArr = [
    {
      // rolesId: 9,
      roles_group: "STAFF",
      rolesName: "Camp Staff",
    },
    {
      // rolesId: 9,
      roles_group: "STAFF",
      rolesName: "Camp Manager",
    },
  ];
}

export default function Registration() {
  const history = useHistory();
  const current = new Date();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [getUserData, setGetUserData] = useState({});
  const [today, setToday] = React.useState(null);
  const [title, setTitle] = React.useState("");
  const [viewData, setViewData] = React.useState({});
  const [thumbnailFile, setThumbnailFile] = React.useState(null);
  const [profilePath, setProfilePath] = React.useState(null);

  const [username, setUserName] = React.useState(null);
  const [shirtSize, setshirtSize] = React.useState("");
  // const titlehandleChange = (event) => {
  //   setTitle(event.target.value);
  // };
  // const shirthandleChange = (event) => {
  //   setshirtSize(event.target.value);
  // };
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    DOB: "",
    email: "",
    Gender: "",
    Address: "",
    contactNo: "",
    password: "",
    title: "",
    allergyInfo: "",
    dietaryInfo: "",
    shirtSize: "",
  });
  const [formErrors, setFormErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    Gender: null,
    Address: null,
    contactNo: null,
    title: null,
    allergyInfo: null,
    dietaryInfo: null,
    shirtSize: null,
    DOB: null,
  });
  React.useEffect(() => {
    var date = new Date();
    var day = date.getTime() - 1 * 24 * 60 * 60 * 1000;
    date.setTime(day);
    const newDateVal = formatDate(new Date(date).toISOString().substr(0, 10));
    setToday(newDateVal);
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const theme2 = createTheme({
    palette: {
      primary: {
        main: "#379592", // Set your desired checkbox color
      },
    },
  });
  const getProfileDetails = async () => {
    let apiInfo = {
      // url: `${process.env.REACT_APP_API_URL}auth/getprofile`,
      url: `${SERVER_URL}${GET_PROFILE}`,

      method: "POST",
      postData: {
        email: localStorage.getItem("email"),
        userId: localStorage.getItem("userId"),
      },
    };
    let response = await apiService(apiInfo);
    console.log(response.data.responseData[0][0]);

    if (response?.data?.responseCode === 0) {
      let dummy = {
        firstName: response.data.responseData[0][0]["firstName"],
        lastName: response.data.responseData[0][0]["Lastname"],
        email: response.data.responseData[0][0]["email"],
        rolesName: response.data.responseData[0][0]["rolesName"],
      };
      setProfilePath(response.data.responseData[0][0]["profileimage"]);

      setForm({
        ...form,

        firstName: response.data.responseData[0][0]["firstName"],
        lastName: response.data.responseData[0][0]["Lastname"],
        email: response.data.responseData[0][0]["email"],
        rolesName: response.data.responseData[0][0]["rolesName"],
        Address: response.data.responseData[0][0]["Address"],
        Gender: response.data.responseData[0][0]["Gender"],
        contactNo: response.data.responseData[0][0]["contactNo"],
        title: response.data.responseData[0][0]["title"],
        allergyInfo: response.data.responseData[0][0]["allergyInfo"],
        dietaryInfo: response.data.responseData[0][0]["dietaryInfo"],
        DOB: conversion(response.data.responseData[0][0]["DOB"]),
        shirtSize: response.data.responseData[0][0]["shirtSize"],
      });
      setViewData(dummy);
      let names = response.data.responseData[0][0]["profileName"];
      setUserName(names);
    }
  };

  // const phoneRegExp = /^(\d{1,9})$/
  // const phoneRegExp = /^[1-9][0-9]{9}$/;
  const passwordRegExp =
    // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

  const doSomething = async (value) => {
    var date = new Date(value);
    var day = date.getTime() - 10 * 24 * 60 * 60 * 1000;
    date.setTime(day);
    const newDateVal = formatDate(new Date(date).toISOString().substr(0, 1));
    setToday(newDateVal);
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    let formObj = {};

    formObj = { ...form, [name]: value };

    setForm(formObj);
    validateField(name, formObj[name]);
  };

  const validateField = (name, value) => {
    console.log(value,'hereee')
    let errorMsg = null;
    switch (name) {
      case "Gender":
        if (!value) errorMsg = "Gender is required.";
        break;
      
      case "contactNo":
        if (!value) errorMsg = "Please enter contact number.";
        else if (!/^[0-9]{9,10}$/.test(value)) {
          errorMsg = "Contact number should be 9 or 10 digits.";
        }
        break;
      case "title":
        if (!value) errorMsg = "Please enter Profession.";
        break;
      case "allergyInfo":
        if (!value) errorMsg = "Allergy information is required.";
        break;
      case "dietaryInfo":
        if (!value) errorMsg = "Dietary information is required.";
        break;
      case "shirtSize":
        if (!value) errorMsg = "Shirt size is required.";
        break;
      case "DOB":
        if (!value) errorMsg = "Date of birth is required.";
        break;
        case "Address":
        if (!value) errorMsg = "Address is required.";
        break;
        
      case "password":
        if (!value) errorMsg = "Password is required.";
        else if (!passwordRegExp.test(value)) {
          errorMsg =
            "Must be 8 characters with at least 1 uppercase, 1 lowercase, 1 number, and 1 special character.";
        }
        break;

      case "ConfirmPassword":
        if (!value) errorMsg = "Confirm Password should match with password.";
        else if (value !== form.password) {
          errorMsg = "Passwords do not match.";
        }
        break;
      default:
        break;
    }

    setFormErrors({ ...formErrors, [name]: errorMsg });
    return errorMsg;
  };

  const { register } = useForm({
    defaultValues: async () => await getProfileDetails(),
  });
  const validateForm = () => {
    const errors = {};
    Object.keys(form).forEach((name) => {
      const value = form[name];
      const error = validateField(name, value, form.password);
      if (error) {
        errors[name] = error;
      }
    });
    return errors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(form);
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const body = {};
      body["title"] = form.title;
      body["shirtSize"] = form.shirtSize;
      body["DOB"] = form.DOB;
      body["mobile"] = form.contactNo;
      body["address"] = form.Address;
      body["firstName"] = viewData.firstName;
      body["lastName"] = viewData.lastName;
      body["email"] = viewData.email;
      body["gender"] = form.Gender;
      body['dietaryInfo'] = form.dietaryInfo;
      body['allergyInfo'] = form.allergyInfo;
      body["rolesName"] = viewData.rolesName;
      body['password'] = form.password;
      body["userId"] = localStorage.getItem("userId");

      let apiInfo = {
        // url: `${process.env.REACT_APP_API_URL}auth/UpdateStaffProfile`,
        url: `${SERVER_URL}${UPDATE_STAFF_PROFILE}`,
        method: "POST",
        postData: body,
        headers : {
          "Content-Type": "application/json",
          tokenheaderkey:localStorage.getItem("token"),
        },
      };
      let register = await apiService(apiInfo);

      history.push("/Registerdata");
    }
  };

  // async function uploadThumnails(file) {
  //   const forminputsTwo = new FormData();

  //   forminputsTwo.append("image", file);
  //   forminputsTwo.append("userId", localStorage.getItem("userId"));

  //   console.log(forminputsTwo, "sjcakdjcbdcj");
  //   axios
  //     .post(
  //       // "https://cncdevapi.natsoft.io/api/auth/uploadprofilepic",
  //       `${SERVER_URL}${UPLOAD_PROFILE_PIC}`,

  //       forminputsTwo,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           tokenheaderkey: localStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log(response);
  //     })
  //     .catch((error) => {});
  // }
  async function uploadThumnails(file) {
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (file.size > maxSize) {
        Swal.fire({
            icon: 'error',
            confirmButtonText: "OK",
            confirmButtonColor: "#379592",
            text: 'Please select image size less than 2 MB.',
        });
        return; // Exit the function
    }

    const forminputsTwo = new FormData();
    forminputsTwo.append("image", file);
    forminputsTwo.append("userId", localStorage.getItem("userId"));

    console.log(forminputsTwo, "sjcakdjcbdcj");
    try {
        const response = await axios.post(
            `${SERVER_URL}${UPLOAD_PROFILE_PIC}`,
            forminputsTwo,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    tokenheaderkey: localStorage.getItem("token"),
                },
            }
        );
        console.log(response);
    } catch (error) {
        console.error(error);
    }

  }
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file.name);

    const reader = new FileReader();
    setThumbnailFile(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);
    uploadThumnails(event.target.files[0]);
    // read file as data url

    reader.onload = (event) => {
      console.log(event.target.result);

      // called once readAsDataURL is completed

      setProfilePath(event.target.result);
    };
  };

  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },

    components: {
      MuiInputLabel: {
        styleOverrides: {
          asterisk: {
            color: "red",
            fontSize: 24,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": {
            fontFamily: "gilroyMedium",
            src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
          },
        },
      },
    },
  });
  const conversion = (inputDate) => {
    if (inputDate) {
      var formattedDate = inputDate.replace(/\//g, "-");
      var dateParts = formattedDate.split("-");
      var day = dateParts[0];
      var month = dateParts[1];
      var year = dateParts[2];
      return year + "-" + month + "-" + day;
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        container
        direction="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100vh",
          backgroundImage: `url(${Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Grid item>
          <Card sx={{ borderRadius: "20px" }}>
            <CardContent
              sx={{
                mx: "0.5rem",
                maxHeight: "600px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                component="form"
                xs={4}
                md={4}
                sm={6}
                noValidate
                autoComplete="off"
              >
                <Grid container>
                  <Grid item md={4}>
                    <Box sx={{ alignItems: "center" }}>
                      <CardMedia
                        component="img"
                        height="25%"
                        image={image}
                        alt="Logo"
                        sx={{ width: "40%" }}
                      />
                    </Box>
                    <Grid my={1}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png"
                      />
                      <Box>
                        {profilePath === null ? (
                          <Box className="main-div">
                            <Box
                              my="0.5rem"
                              textAlign="center"
                              sx={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "50px",

                                color: "#fff",
                                paddingTop: "2rem",
                                fontSize: "20px",
                                backgroundColor: "#379592",
                              }}
                              className="bold"
                            >
                              {username}
                            </Box>
                            <Box className="camera-icons">
                              <img onClick={handleClick} src={cameraIcon} />
                            </Box>
                          </Box>
                        ) : (
                          <Box className="main-div">
                            <Box
                              mt="1rem"
                              component="img"
                              src={profilePath}
                              sx={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "50px",
                              }}
                            ></Box>
                            <Box className="camera-icons">
                              <img onClick={handleClick} src={cameraIcon} />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid>

                    <Grid item>
                      <TextField
                        id="standard-basic"
                        label="First Name"
                        variant="standard"
                        name="firstName"
                        sx={{ width: "80%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={viewData.firstName}
                      />
                      <Typography
                        color="error.main"
                        ml="2rem"
                        sx={{ fontSize: "12px", textAlign: "left" }}
                      ></Typography>
                    </Grid>
                    <Grid item mt={1}>
                      <TextField
                        id="standard-basic"
                        label="Last Name"
                        variant="standard"
                        // name="lastName"
                        value={viewData.lastName}
                        sx={{ width: "80%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // {...register("lastName")}
                      />
                    </Grid>
                    <Grid item mt={1}>
                      <TextField
                        id="standard-basic"
                        label="Email"
                        variant="standard"
                        value={viewData.email}
                        sx={{ width: "80%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />

                      <Typography
                        color="error.main"
                        ml="2rem"
                        sx={{ fontSize: "12px", textAlign: "left" }}
                      ></Typography>
                    </Grid>
                    <Grid item mt={1}>
                      <TextField
                        id="standard-basic"
                        label="Role"
                        variant="standard"
                        value={viewData.rolesName}
                        sx={{ width: "80%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <Typography
                        color="error.main"
                        sx={{ fontSize: "12px", textAlign: "left" }}
                      ></Typography>
                    </Grid>
                  </Grid>

                  <Grid md={8}>
                    <form>
                      <Grid mb={1} mr={5} sx={{ textAlign: "center" }}>
                        Profile
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <Grid item mr="4.5rem">
                            <FormControl
                              variant="standard"
                              sx={{ m: 1, minWidth: 270 }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Profession
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Profession"
                                name="title"
                                value={form.title}
                                onChange={handleChange}
                                onBlur={() => ("title", form.title)}
                              >
                                <MenuItem value="Occupational Therapist">
                                  Occupational Therapist
                                </MenuItem>
                                <MenuItem value="Speech Pathologist ">
                                  Speech Pathologist{" "}
                                </MenuItem>
                                <MenuItem value="Therapy Assistant">
                                  Therapy Assistant
                                </MenuItem>
                                <MenuItem value="Psychologist">
                                  {" "}
                                  Psychologist
                                </MenuItem>
                                <MenuItem value="Therapy Student">
                                  Therapy Student
                                </MenuItem>
                              </Select>
                            </FormControl>

                            {formErrors.title && (
                              <FormHelperText error>
                                {formErrors.title}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item mt={1} mr="0.50">
                            <TextField
                              id="standard-basic"
                              label="Mobile"
                              variant="standard"
                              // name="mobile"
                              // {...register("mobile")}

                              name="contactNo"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +61
                                  </InputAdornment>
                                ),
                              }}
                              value={form.contactNo}
                              onChange={handleChange}
                              onBlur={() => ("mobile", form.contactNo)}
                              sx={{ width: "80%" }}
                            />

                            {formErrors.contactNo && (
                              <FormHelperText error>
                                {formErrors.contactNo}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item mt={1}>
                            <TextField
                              id="standard-basic"
                              label="Address"
                              variant="standard"
                              name="Address"
                              sx={{ width: "80%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={form.Address}
                              onChange={handleChange}
                              onBlur={() => ("Address", form.Address)}
                            />
                            {formErrors.Address && (
                              <FormHelperText error>
                                {formErrors.Address}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item mt={1}>
                            <TextField
                              id="standard-basic"
                              label="New Password"
                              variant="standard"
                              name="password"
                              type={showPassword ? "text" : "password"}
                              {...register("password")}
                              sx={{ width: "80%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleChange}
                              onBlur={() => ("password", form.password)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {formErrors.password && (
                              <Typography
                                variant="body1"
                                color="error"
                                sx={{ fontSize: "10px" }}
                              >
                                {formErrors.password}
                              </Typography>
                            )}
                          </Grid>

                          <Grid item my={2}>
                            Allergy Information
                            <TextField
                              id="outlined-multiline-static"
                              multiline
                              rows={2}
                              variant="outlined"
                              name="allergyInfo"
                              sx={{ width: "80%" }}
                              value={form.allergyInfo}
                              onChange={handleChange}
                              onBlur={() => ("allergyInfo", form.allergyInfo)}
                            />
                            {formErrors.allergyInfo && (
                              <FormHelperText error>
                                {formErrors.allergyInfo}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item md={6} mt={1}>
                          <Grid item ml={0.5}>
                            <TextField
                              type="date"
                              id="standard-basic"
                              label="DOB"
                              variant="standard"
                              name="DOB"
                              sx={{ width: "80%" }}
                              value={form.DOB}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: today,
                              }}
                              onChange={handleChange}
                              onBlur={() => ("DOB", form.DOB)}
                            />
                            {formErrors.DOB && (
                              <FormHelperText error>
                                {formErrors.DOB}
                              </FormHelperText>
                            )}
                          </Grid>

                          <Grid item mt={1} mr={2}>
                            <FormControl
                              variant="standard"
                              sx={{ m: 1, minWidth: 270 }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Shirt Size
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="shirtSize"
                                name="shirtSize"
                                value={form.shirtSize}
                                onChange={handleChange}
                                onBlur={() => ("shirtSize", form.shirtSize)}
                              >
                                <MenuItem value="Kids 6">Kids 6</MenuItem>
                                <MenuItem value="Kids 8">Kids 8</MenuItem>
                                <MenuItem value="Kids 10">Kids 10</MenuItem>
                                <MenuItem value="Kids 12">Kids 12</MenuItem>
                                <MenuItem value="Kids 14">Kids 14</MenuItem>
                                <MenuItem value="Ladies 6">Ladies 6</MenuItem>
                                <MenuItem value="Ladies 8">Ladies 8</MenuItem>
                                <MenuItem value="Ladies 10">Ladies 10</MenuItem>
                                <MenuItem value="Ladies 12">Ladies 12</MenuItem>
                                <MenuItem value="Ladies 14">Ladies 14</MenuItem>
                                <MenuItem value="Ladies 16">Ladies 16</MenuItem>
                                <MenuItem value="Mens XS">Mens XS</MenuItem>
                                <MenuItem value="Mens S">Mens S</MenuItem>
                                <MenuItem value="Mens M">Mens M</MenuItem>
                                <MenuItem value="Mens L">Mens L</MenuItem>
                                <MenuItem value="Mens XL">Mens XL</MenuItem>
                                <MenuItem value="Mens 2XL">Mens 2XL</MenuItem>
                                <MenuItem value="Mens 3XL">Mens 3XL</MenuItem>
                                <MenuItem value="Mens 5XL">Mens 5XL</MenuItem>
                              </Select>
                            </FormControl>

                            {formErrors.shirtSize && (
                              <FormHelperText error>
                                {formErrors.shirtSize}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item>
                            <ThemeProvider theme={theme2}>
                              <Box sx={{ color: "#707070" }}>Gender</Box>
                              <RadioGroup
                                row
                                name="Gender"
                                value={form.Gender}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  value="Male"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 12 },
                                      }}
                                    />
                                  }
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="Female"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 12 },
                                      }}
                                    />
                                  }
                                  label="Female"
                                />
                                <FormControlLabel
                                  value="Prefer not to say"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 12 },
                                      }}
                                    />
                                  }
                                  label="Prefer not to say"
                                />
                              </RadioGroup>
                              {formErrors.Gender && (
                                <FormHelperText error>
                                  {formErrors.Gender}
                                </FormHelperText>
                              )}
                            </ThemeProvider>
                          </Grid>
                          <Grid item>
                            <TextField
                              id="standard-basic"
                              label="Confirm Password"
                              variant="standard"
                              // type="password"
                              name="ConfirmPassword"
                              type={showConfirmPassword ? "text" : "password"}
                              {...register("ConfirmPassword")}
                              value={form.ConfirmPassword}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ width: "80%" }}
                              onChange={handleChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowConfirmPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                                      {showConfirmPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {formErrors.ConfirmPassword && (
                              <FormHelperText error>
                                {formErrors.ConfirmPassword}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item my={2}>
                            Dietary Information
                            <TextField
                              id="outlined-multiline-static"
                              multiline
                              rows={2}
                              variant="outlined"
                              name="dietaryInfo"
                              sx={{ width: "80%" }}
                              value={form.dietaryInfo}
                              onChange={handleChange}
                              onBlur={() => ("dietaryInfo", form.dietaryInfo)}
                            />
                            {formErrors.dietaryInfo && (
                              <FormHelperText error>
                                {formErrors.dietaryInfo}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          fullWidth
                          type="submit"
                          onClick={(e) => handleSubmit(e)}
                          sx={{
                            px: "1rem",
                            py: "0.5rem",
                            bgcolor: "#379592",
                            textTransform: "none",
                            "&:hover": { background: "#379592" },
                          }}
                        >
                          Next
                        </Button>

                        {/* </Link> */}
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
