import React, { useState } from "react";
import { FormControlLabel, FormGroup, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { apiService2 } from "../../Services/apiService";
import { Link, useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Radiobtnno from "../../Assets/Icons/Icons/Radiobtnno.png";
import Radiobtnyes from "../../Assets/Icons/Icons/Radiobtnyes.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Swal from "sweetalert2";
import "../../index.css";
import { US8_URL, SERVER_URL } from "../../Services/apiEndpints";
import Check from "../../Assets/Icons/Icons/Check.png";
import CheckboxNotSelected from "../../Assets/Icons/Icons/CheckboxNotSelected.png";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function MedicalForm() {
  const [MedicalHistory, setMedicalHistory] = useState([]);
  const [medicalForm, setMedicalForm] = useState([]);
  let history = useHistory();
  let { camp_id, applicationId } = useParams();

  React.useEffect(() => {
    (async () => {
      // setLoading(true);
      let gettran = {
        _methodId: "16",
        userId: localStorage.getItem("userId"),
        applicationId: applicationId,
        campId: camp_id,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/us8",
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);

      const medicalDataList = login.data.responseData[1];
      // setLoading(false);

      console.log(medicalDataList);
      medicalDataList.forEach((element) => {
        element["medicince_time"] = JSON.parse(element.medicince_time);
      });

      setMedicalHistory(medicalDataList);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);

  React.useEffect(() => {
    (async () => {
      // setLoading(true);
      let gettran = {
        _methodId: "21",
        userId: localStorage.getItem("userId"),
        applicationId: applicationId,
        campId: camp_id,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/us8",
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);

      if (login?.data?.responseCode === 1) {
        Swal.fire({
          // icon: "success",
          icon: "error",
          // iconHtml: `<img src="${GreenCheck}" >`,
          // text: register?.data?.responseData,
          text: login?.data?.responseData,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
        }).then(
          // history.push(`/ParticipantsMedicalRecord/${camp_id}/${applicationId}`)
          history.goBack()
        );
        // alert(createcmp?.data?.responseData[0][0].Desc);
      }

      const medicalFormDataList = login.data.responseData[0];
      // setLoading(false);

      console.log(
        medicalFormDataList[0],
        "medicalFormDataList[0]mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm"
      );
      if (medicalFormDataList[0].therapyAssistanceDogs) {
        medicalFormDataList[0].therapyAssistanceDogs = JSON.parse(
          medicalFormDataList[0].therapyAssistanceDogs
        );
      }

      setMedicalForm(medicalFormDataList[0]);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);

  console.log("medicalFormmmmmmmmmmmmmmmmmmmmmmmmmmm", medicalForm);
  return (
    <>
      <Grid container display="flex" sx={{ mt: "2rem" }}>
        <Stack direction="row" spacing={1}>
          <Link
            to={`/ParticipantsMedicalRecord/${camp_id}/${applicationId}`}
            className="link2"
          >
            <Box
              alignSelf="center"
              component="img"
              sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
              alt="Logo"
              src={ArrowBack}
            ></Box>
          </Link>
        </Stack>
      </Grid>
      <div>
        <h3 style={{ color: "#379592" }}>Health Care Information</h3>
        <hr />
      </div>
      <div>
        <p>Ambulance Cover?*</p>
        <Stack direction="row" spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.ambulanceCover === "Yes"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>Yes</Box>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.ambulanceCover === "No"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>No</Box>
          </Stack>
        </Stack>
      </div>

      <div>
        <p>
          Do you give permission for us to call and ambulance on behalf of your
          child?*
        </p>
        <Stack direction="row" spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.ambulancePermission === "Yes"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>Yes</Box>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.ambulancePermission === "No"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>No</Box>
          </Stack>
        </Stack>
      </div>

      <div>
        <p>Doctors Name and Contact:*</p>
        <TextField
          id="standard-basic"
          // label="Past Camp History"
          multiline
          rows={2}
          size="small"
          variant="outlined"
          value={medicalForm.doctorContact}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: 580, color: "#000000" }}
        ></TextField>
      </div>

      <div>
        <h3 style={{ color: "#379592" }}>Medical Conditions and Diagnosis</h3>
        <hr />
      </div>

      <div>
        <p>Does your child experience Asthma?*</p>
        <Stack direction="row" spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.asthamaExperience === "Yes"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>Yes</Box>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.asthamaExperience === "No"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>No</Box>
          </Stack>
        </Stack>
      </div>

      {medicalForm && medicalForm.asthamaExperience === "Yes" ? (
        <div>
          <p>If yes, Please attach action plan:*</p>
          {/* <TextField
            id="standard-basic"
            // label="Past Camp History"
            multiline
            rows={2}
            size="small"
            variant="outlined"
            value={medicalForm.asthamaActionPlan}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: 380, color: "#000000" }}
          ></TextField> */}
          <Box
            sx={{
              border: 1,
              width: 350,
              borderRadius: "5px",
              py: "0.5rem",
              color: "#ADADAD",
              px: "0.5rem",
            }}
          >
            {medicalForm && medicalForm.asthamaActionPlan !== null ? (
              <a
                href={
                  medicalForm &&
                  `${SERVER_URL}/${medicalForm.asthamaActionPlan}`
                }
                target="_blank"
              >
                Asthama Action Plan
              </a>
            ) : (
              ""
            )}
          </Box>
        </div>
      ) : (
        ""
      )}

      <div>
        <p>Does your child experience Heart Problems?*</p>
        <Stack direction="row" spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.heartProblem === "Yes"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>Yes</Box>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.heartProblem === "No"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>No</Box>
          </Stack>
        </Stack>
      </div>

      {medicalForm && medicalForm.heartProblem === "Yes" ? (
        <div>
          <p>If yes, Please provide details:*</p>
          {/* <TextField
          id="standard-basic"
          // label="Past Camp History"
          multiline
          rows={2}
          size="small"
          variant="outlined"
          value={medicalForm.heartProblemDetails}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: 580, color: "#000000" }}
        ></TextField> */}
          <Box
            sx={{
              border: 1,
              width: 350,
              borderRadius: "5px",
              py: "0.5rem",
              color: "#ADADAD",
              px: "0.5rem",
            }}
          >
            {medicalForm && medicalForm.heartProblemDetails !== null ? (
              <a
                href={
                  medicalForm &&
                  `${SERVER_URL}/${medicalForm.heartProblemDetails}`
                }
                target="_blank"
              >
                Heart Problem Details
                {console.log(
                  `${SERVER_URL}/${medicalForm.heartProblemDetails}`.replace(
                    "private/profilepics",
                    "file"
                  ),
                  "AYYYYY"
                )}
                {console.log(
                  "heartProblemDetailsooooooooooooooooooo",
                  medicalForm.heartProblemDetails
                )}
              </a>
            ) : (
              ""
            )}
          </Box>
        </div>
      ) : (
        ""
      )}

      <div>
        <p>Does your child experience Seizures?*</p>
        <Stack direction="row" spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.seisuresExperience === "Yes"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>Yes</Box>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.seisuresExperience === "No"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>No</Box>
          </Stack>
        </Stack>
      </div>

      {medicalForm && medicalForm.seisuresExperience === "Yes" ? (
        <div>
          <p>If yes, Please attach action plan:*</p>
          {/* <TextField
          id="standard-basic"
          // label="Past Camp History"
          multiline
          rows={2}
          size="small"
          variant="outlined"
          value={medicalForm.seisuresActionPlan}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: 580, color: "#000000" }}
        ></TextField> */}

          <Box
            sx={{
              border: 1,
              width: 350,
              borderRadius: "5px",
              py: "0.5rem",
              color: "#ADADAD",
              px: "0.5rem",
            }}
          >
            {medicalForm && medicalForm.seisuresActionPlan !== null ? (
              <a
                href={
                  medicalForm &&
                  `${SERVER_URL}/${medicalForm.seisuresActionPlan}`
                }
                target="_blank"
              >
                Seisures Action Plan
              </a>
            ) : (
              ""
            )}
          </Box>
        </div>
      ) : (
        ""
      )}
      {/* <div>
        <p>
          For any anaphylaxis reactions, please attach your child's action plan*
        </p>
        <TextField
          id="standard-basic"
          // label="Past Camp History"
          multiline
          rows={2}
          size="small"
          variant="outlined"
          value={medicalForm.anaphylaxisActionPlan}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: 580, color: "#000000" }}
        ></TextField>
      </div> */}

      {medicalForm && medicalForm.anaphylaxisActionPlan !== null ? (
        <Box my="0.75rem">
          <Box className="medium">
            For any anaphylaxis reactions, please attach your child's action
            plan
          </Box>

          <Box
            sx={{
              border: 1,
              width: 350,
              borderRadius: "5px",
              py: "0.5rem",
              color: "#ADADAD",
              px: "0.5rem",
            }}
          >
            {medicalForm && medicalForm.anaphylaxisActionPlan !== null ? (
              <a
                href={
                  medicalForm &&
                  `${SERVER_URL}/${medicalForm.anaphylaxisActionPlan}`
                }
                target="_blank"
              >
                Anaphylaxis Action Plan
              </a>
            ) : (
              ""
            )}
          </Box>
        </Box>
      ) : (
        ""
      )}

      <div>
        <p>
          Does your child have any other existing diagnosis or medical
          conditions?*
        </p>
        <Stack direction="row" spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.medicalCondition === "Yes"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>Yes</Box>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.medicalCondition === "No"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>No</Box>
          </Stack>
        </Stack>
      </div>

      {medicalForm && medicalForm.medicalCondition === "Yes" ? (
        <div>
          <p>If yes, Please provide details:*</p>
          {/* <TextField
          id="standard-basic"
          // label="Past Camp History"
          multiline
          rows={2}
          size="small"
          variant="outlined"
          value={medicalForm.medicalConditionDetails}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: 580, color: "#000000" }}
        ></TextField> */}

          <Box
            sx={{
              border: 1,
              width: 350,
              borderRadius: "5px",
              py: "0.5rem",
              color: "#ADADAD",
              px: "0.5rem",
            }}
          >
            {medicalForm && medicalForm.medicalConditionDetails !== null ? (
              <a
                href={
                  medicalForm &&
                  `${SERVER_URL}/${medicalForm.medicalConditionDetails}`
                }
                target="_blank"
              >
                Medical Condition Details
              </a>
            ) : (
              ""
            )}
          </Box>
        </div>
      ) : (
        ""
      )}

      <div>
        <p>
          Do you give us permission to take your child to seek urgent medical
          attention from an Emergency Department or Urgent Care GP?*
        </p>
        <Stack direction="row" spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.urgentMedicalAttention === "Yes"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>Yes</Box>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.urgentMedicalAttention === "No"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>No</Box>
          </Stack>
        </Stack>
      </div>

      <div>
        <h3 style={{ color: "#379592" }}>
          Therapy Assistance Dogs - Camp Confidence Only
        </h3>
        <hr />
      </div>

      <div>
        <p>Select which of the following apply for your child:</p>
        {/* 
        <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Is fearful of dogs"
          />
          <FormControlLabel
            required
            control={<Checkbox />}
            label="Has allergies relating to dogs"
          />
          <FormControlLabel
            required
            control={<Checkbox />}
            label="Enjoys being around"
          />
          <FormControlLabel required control={<Checkbox />} label="Other" />
        </FormGroup> */}

        <Box my="0.75rem">
          <Box className="medium">
            Does your child need to take medication on camp?{" "}
            <span className="red ">
              *</span>
          </Box>
          {/* <Stack direction='row' spacing={4}>
                                        <Stack direction='column' mx='0.25rem' >
                                            <FormControlLabel size='small' control={<Checkbox />} label="Prescribed medication"
                                                sx={{
                                                    "& .MuiSvgIcon-root": {
                                                        fontSize: '12px'
                                                    }
                                                }}
                                            />
                                            <FormControlLabel size='small' control={<Checkbox />} label="Vitamins and natural"
                                                sx={{
                                                    "& .MuiSvgIcon-root": {
                                                        fontSize: '12px'
                                                    }
                                                }}
                                            />
                                        </Stack>
                                        <Stack direction='column' >
                                            <FormControlLabel size='small' control={<Checkbox />} label="Other"
                                                sx={{
                                                    "& .MuiSvgIcon-root": {
                                                        fontSize: '12px'
                                                    }
                                                }}
                                            />
                                            <FormControlLabel size='small' control={<Checkbox />} label="None"
                                                sx={{
                                                    "& .MuiSvgIcon-root": {
                                                        fontSize: '12px'
                                                    }
                                                }}
                                            />
                                        </Stack>
                                    </Stack> */}
          {/* <Stack direction="row" spacing={3}> */}
          {medicalForm &&
            medicalForm.therapyAssistanceDogs &&
            medicalForm.therapyAssistanceDogs.map((item, index) => (
              <Box key={index}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  className="medium"
                >
                  {item && item.key === "isFearfulDogs" ? (
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      className="medium"
                    >
                      <Box
                        component="img"
                        sx={{ height: "15px", width: "15px" }}
                        alt="Logo"
                        src={
                          item && item.Value && item.Value === "Yes"
                            ? Check
                            : CheckboxNotSelected
                        }
                      ></Box>
                      <Box>Is fearful of dogs</Box>
                    </Stack>
                  ) : (
                    ""
                  )}
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  className="medium"
                >
                  {item && item.key === "aggressiveAnimals" ? (
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      className="medium"
                    >
                      <Box
                        component="img"
                        sx={{ height: "15px", width: "15px" }}
                        alt="Logo"
                        src={
                          item && item.Value && item.Value === "Yes"
                            ? Check
                            : CheckboxNotSelected
                        }
                      ></Box>
                      <Box>Is aggressive towards animals</Box>
                    </Stack>
                  ) : (
                    ""
                  )}
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  className="medium"
                >
                  {item && item.key === "allergiesDogs" ? (
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      className="medium"
                    >
                      <Box
                        component="img"
                        sx={{ height: "15px", width: "15px" }}
                        alt="Logo"
                        src={
                          item && item.Value && item.Value === "Yes"
                            ? Check
                            : CheckboxNotSelected
                        }
                      ></Box>
                      <Box>Has allergies relating to dogs</Box>
                    </Stack>
                  ) : (
                    ""
                  )}
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  className="medium"
                >
                  {item && item.key === "enjoyAround" ? (
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      className="medium"
                    >
                      <Box
                        component="img"
                        sx={{ height: "15px", width: "15px" }}
                        alt="Logo"
                        src={
                          item && item.Value && item.Value === "Yes"
                            ? Check
                            : CheckboxNotSelected
                        }
                      ></Box>
                      <Box>Enjoys being around</Box>
                    </Stack>
                  ) : (
                    ""
                  )}
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  className="medium"
                >
                  {item && item.key === "other" ? (
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      className="medium"
                    >
                      <Box
                        component="img"
                        sx={{ height: "15px", width: "15px" }}
                        alt="Logo"
                        src={
                          item && item.Value && item.Value === "Yes"
                            ? Check
                            : CheckboxNotSelected
                        }
                      ></Box>
                      <Box>Other</Box>
                    </Stack>
                  ) : (
                    ""
                  )}
                </Stack>
              </Box>
            ))}
          {/* <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            className="medium"
          >
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm &&
                  medicalForm.therapyAssistanceDogs &&
                  medicalForm.therapyAssistanceDogs.includes("isFearfulDogs")
                  ? Check
                  : CheckboxNotSelected
              }
            ></Box>
            <Box>Is fearful of dogs</Box>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            className="medium"
          >
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm &&
                  medicalForm.therapyAssistanceDogs &&
                  medicalForm.therapyAssistanceDogs.includes("aggressiveAnimals")
                  ? Check
                  : CheckboxNotSelected
              }
            ></Box>
            <Box>Is aggressive towards animals</Box>
          </Stack>
         

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            className="medium"
          >
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm &&
                  medicalForm.therapyAssistanceDogs &&
                  medicalForm.therapyAssistanceDogs.includes("allergiesDogs")
                  ? Check
                  : CheckboxNotSelected
              }
            ></Box>
            <Box>Has allergies relating to dogs</Box>
          </Stack>

         
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            className="medium"
          >
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm &&
                  medicalForm.therapyAssistanceDogs &&
                  medicalForm.therapyAssistanceDogs.includes("enjoyaAround")
                  ? Check
                  : CheckboxNotSelected
              }
            ></Box>
            <Box>Enjoys being around</Box>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            className="medium"
          >
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm &&
                  medicalForm.therapyAssistanceDogs &&
                  medicalForm.therapyAssistanceDogs.includes("other")
                  ? Check
                  : CheckboxNotSelected
              }
            ></Box>
            <Box>Other</Box>
          </Stack> */}

          {/* {applicationDetails && applicationDetails.medicationInfo.includes('none') ? ('Ha') : ('Nah')} */}
        </Box>

        {/* <Box>
          <p>
            If your child is fearful, has allergies and/or is aggressive towards
            animals, please provide details here. Please also provide details if
            you selected other:*
          </p>
          <TextField
            id="standard-basic"
            value={medicalForm.allergiesTowardsAnimals}
            multiline
            rows={2}
            size="small"
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: 580, color: "#000000" }}
          />
        </Box> */}

        {medicalForm &&
        medicalForm.therapyAssistanceDogs &&
        !medicalForm.therapyAssistanceDogs.some(
          (item) => item.key === "enjoyAround" && item.Value === "Yes"
        ) ? (
          <Box>
            <p>
              If your child is fearful, has allergies and/or is aggressive
              towards animals, please provide details here. Please also provide
              details if you selected other:*
            </p>
            <TextField
              id="standard-basic"
              value={medicalForm.allergiesTowardsAnimals}
              multiline
              rows={2}
              size="small"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: 580, color: "#000000" }}
            />
          </Box>
        ) : (
          <Box></Box>
        )}
      </div>

      <div>
        <h3 style={{ color: "#379592" }}>Mental Health</h3>
        <hr />
      </div>

      <div>
        <p>
          Does your child have a mental health condition such as anxiety,
          depression, bipolar, personality disorder, OCD etc..,,*
        </p>
        <Stack direction="row" spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.mentalHealth === "Yes"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>Yes</Box>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.mentalHealth === "No"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>No</Box>
          </Stack>
        </Stack>
      </div>

      {medicalForm && medicalForm.mentalHealth === "Yes" ? (
        <div>
          <p>If yes, please provide details:*</p>
          {/* <TextField
          id="standard-basic"
          // label="Past Camp History"
          multiline
          rows={2}
          size="small"
          variant="outlined"
          value={medicalForm.mentalHealthDetails}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: 580, color: "#000000" }}
        ></TextField> */}
          <Box
            sx={{
              border: 1,
              width: 350,
              borderRadius: "5px",
              py: "0.5rem",
              color: "#ADADAD",
              px: "0.5rem",
            }}
          >
            {medicalForm && medicalForm.mentalHealthDetails !== null ? (
              <a
                href={
                  medicalForm &&
                  `${SERVER_URL}/${medicalForm.mentalHealthDetails}`
                }
                target="_blank"
              >
                Medical Health Details
              </a>
            ) : (
              ""
            )}
          </Box>
        </div>
      ) : (
        ""
      )}

      <div>
        <p>
          Has your child in the past 6 months attempted suicide or self harm?*
        </p>
        <Stack direction="row" spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.suicideAttempt === "Yes"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>Yes</Box>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.suicideAttempt === "No"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>No</Box>
          </Stack>
        </Stack>
      </div>

      {medicalForm && medicalForm.suicideAttempt === "Yes" ? (
        <div>
          <p>If yes, please provide details:*</p>
          {/* <TextField
          id="standard-basic"
          // label="Past Camp History"
          multiline
          rows={2}
          size="small"
          variant="outlined"
          value={medicalForm.suicideAttemptDetails}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: 580, color: "#000000" }}
        ></TextField> */}

          <Box
            sx={{
              border: 1,
              width: 350,
              borderRadius: "5px",
              py: "0.5rem",
              color: "#ADADAD",
              px: "0.5rem",
            }}
          >
            {medicalForm && medicalForm.suicideAttemptDetails !== null ? (
              <a
                href={
                  medicalForm &&
                  `${SERVER_URL}/${medicalForm.suicideAttemptDetails}`
                }
                target="_blank"
              >
                Suicide Attempt Details
              </a>
            ) : (
              ""
            )}
          </Box>
        </div>
      ) : (
        ""
      )}

      <div>
        <h3 style={{ color: "#379592" }}>Dietary Needs</h3>
        <hr />
      </div>

      <div>
        <p>Does your child have any dietary needs:*</p>
        <Stack direction="row" spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.dietaryNeeds === "Yes"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>Yes</Box>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="img"
              sx={{ height: "15px", width: "15px" }}
              alt="Logo"
              src={
                medicalForm && medicalForm.dietaryNeeds === "No"
                  ? Radiobtnyes
                  : Radiobtnno
              }
            ></Box>
            <Box>No</Box>
          </Stack>
        </Stack>
      </div>

      {medicalForm && medicalForm.dietaryNeeds === "Yes" ? (
        <div>
          <p>If yes, please provide details:*</p>
          {/* <TextField
          id="standard-basic"
          // label="Past Camp History"
          multiline
          rows={2}
          size="small"
          variant="outlined"
          value={medicalForm.dietaryNeedsDetails}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: 580, color: "#000000" }}
        ></TextField> */}
          <Box
            sx={{
              border: 1,
              width: 350,
              borderRadius: "5px",
              py: "0.5rem",
              color: "#ADADAD",
              px: "0.5rem",
            }}
          >
            {medicalForm && medicalForm.dietaryNeedsDetails !== null ? (
              <a
                href={
                  medicalForm &&
                  `${SERVER_URL}/${medicalForm.dietaryNeedsDetails}`
                }
                target="_blank"
              >
                Dietary Needs Details
              </a>
            ) : (
              ""
            )}
          </Box>
        </div>
      ) : (
        ""
      )}

      <div>
        <h3 style={{ color: "#379592" }}>Medication Summary</h3>
        <hr />
      </div>

      <div>
        <p>
          Medication schedule: Please add in your child’s medication,
          supplements, vitamins, minerals- anything that they will be taking on
          camp into the below timetable.
          <br />
          Camp staff will then sign off after each event. For PRN and
          medications not considered to be for your child’s diagnosis, it is an
          NDIS
          <br />
          requirement that if we give this medication on camp, we must report
          this to the NDIA commission as a restrictive practice. If you think
          your child will need to take a medication such as this, please contact
          us ASAP to arrange a plan.
        </p>
      </div>

      {/* Table code starts here */}

      <TableContainer component={Paper} sx={{ marginTop: "3rem" }}>
        <Table sx={{ minWidth: 450 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Medicine & Dosage</StyledTableCell>

              <StyledTableCell sx={{ textAlign: "center" }}>
                {/* <Button  onClick={()=>testFuncton('firstName')}>First Name</Button>  */}
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Time
                  </Box>
                </Grid>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Prescribed By
                  </Box>
                </Grid>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Notes
                  </Box>
                </Grid>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Provided By
                  </Box>
                </Grid>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={{ align: "center" }}
                >
                  <Box align="center" px="0.5rem">
                    Date
                  </Box>
                </Grid>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {MedicalHistory.map((row, index) => (
              <TableRow
                // key={row.userId}
                className="normal"
                sx={{
                  "& .MuiTableCell-root": { py: "0.35rem" },
                }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  // sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                >
                  {row.medicine}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                >
                  {/* {row?.childName} */}
                  {(row?.medicince_time || []).map((as) => (
                    <Box>{as}</Box>
                  ))}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  //  sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}
                >
                  {row.prescribed_by}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.notes} <br />
                </StyledTableCell>

                <StyledTableCell align="center">
                  {row.provided_by}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {row.medication_date}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <div>
        <p>
          {" "}
         Medical Prescription
        </p>
        <TextField
          id="standard-basic"
          // label="Past Camp History"
          multiline
          rows={2}
          size="small"
          variant="outlined"
          value=""
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: 580, color: "#000000" }}
        ></TextField>
      </div> */}
      {medicalForm && medicalForm.medicalPrescription !== null ? (
        <Box my="0.75rem">
          <Box className="medium">Medical Prescription</Box>

          <Box
            sx={{
              border: 1,
              width: 350,
              borderRadius: "5px",
              py: "0.5rem",
              color: "#ADADAD",
              px: "0.5rem",
            }}
          >
            {medicalForm && medicalForm.medicalPrescription !== null ? (
              <a
                href={
                  medicalForm &&
                  `${SERVER_URL}/${medicalForm.medicalPrescription}`
                }
                target="_blank"
              >
                Prescription
              </a>
            ) : (
              ""
            )}
          </Box>
        </Box>
      ) : (
        ""
      )}

      <div>
        <h3 style={{ color: "#379592" }}>Consent and Agreement</h3>
        <hr />
      </div>

      <div>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            component="img"
            sx={{ height: "15px", width: "15px" }}
            alt="Logo"
            src={
              medicalForm && medicalForm.agreeCcTermsofservice === "Yes"
                ? Check
                : CheckboxNotSelected
            }
          ></Box>
          <Box>
            {" "}
            I have read and agree to Calm and Connected’s Terms of Service {""}
            <a href="https://calmandconnected.com.au/termsofservice/"  target="_blank">
              https://calmandconnected.com.au/termsofservice/{" "}
            </a>
          </Box>
        </Stack>{" "}
        <br />
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            component="img"
            sx={{ height: "15px", width: "15px" }}
            alt="Logo"
            src={
              medicalForm && medicalForm.agreeChildMedicalAssistance === "Yes"
                ? Check
                : CheckboxNotSelected
            }
          ></Box>
          <Box>
            {" "}
            If assistance is called on your child’s behalf in the event of a
            medical emergency, you agree that they are permitted to go with the
            paramedics and a camp support staff member in the ambulance to the
            hospital. We will do our best to notify the child’s primary contact
            as soon as reasonably possible.
          </Box>
        </Stack>
        <br />
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            component="img"
            sx={{ height: "15px", width: "15px" }}
            alt="Logo"
            src={
              medicalForm && medicalForm.agreeChildParticipation === "Yes"
                ? Check
                : CheckboxNotSelected
            }
          ></Box>
          <Box>
            I agree to voluntarily give permission for my child’s participation
            in the camp, knowing that there are risks involved. I am aware and
            accept that some children – including mine – may experience strong
            physical and emotional reactions, and engage in unpredictable
            behaviour. Calm and Connected staff do all that they can to create a
            safe environment, and it is important that parents assist by being
            honest about my child’s needs and abilities. Calm and Connected
            reserve the right to deny participation in the camp or any related
            activities at any time to children demonstrating behaviour that may
            result in injury to themselves or others. However, Calm and
            Connected are entitled to rely on the information given by
            caregivers when enrolling their child in the camp, and have no duty
            to deny participation in any activity. Failure to deny participation
            will not give rise to any claim for negligence.
          </Box>
        </Stack>
        <br />
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            component="img"
            sx={{ height: "15px", width: "15px" }}
            alt="Logo"
            src={
              medicalForm && medicalForm.agreeCampPolicies === "Yes"
                ? Check
                : CheckboxNotSelected
            }
          ></Box>
          <Box>
            I have read and agree to the policies and information outlined in
            the Camp Welcome Pack emailed to me
          </Box>
        </Stack>
        <br />
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            component="img"
            sx={{ height: "15px", width: "15px" }}
            alt="Logo"
            src={
              medicalForm && medicalForm.agreeTherapyDogs === "Yes"
                ? Check
                : CheckboxNotSelected
            }
          ></Box>
          <Box>
            I consent and agree to my child working with a training therapy dog
            on camp? Dogs go between groups throughout camp to support campers
            to regulate, and learn. Please let us know if you have any questions
            or concerns.
          </Box>
        </Stack>
        <br />
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            component="img"
            sx={{ height: "15px", width: "15px" }}
            alt="Logo"
            src={
              medicalForm && medicalForm.agreeEarlyPickup === "Yes"
                ? Check
                : CheckboxNotSelected
            }
          ></Box>
          <Box>
            I agree to pick my child up early from camp if they have run onto a
            nearby busy road, seriously harmed another support/camper or
            frequently becomes aggressive (physical/verbally/emotionally)
          </Box>
        </Stack>
        <br />
      </div>
    </>
  );
}
