import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import { apiService, apiService2 } from "../../Services/apiService";
import { setCampData } from "../../Redux/slices/campSettingSlice";
import { useDispatch } from "react-redux";
import { US8_URL , SERVER_URL} from '../../Services/apiEndpints'

export default function TherapyCampList() {
  const [campListData, setCampListData] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      // setLoading(true);
      let gettran = {
        _methodId: "1",
        userId: localStorage.getItem("userId"),
        roleName: "THERAPIST",
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/us8",
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);

      const campDataList = login.data.responseData[0];
      // setLoading(false);

      console.log(campDataList);

      setCampListData(campDataList);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);

  return (
    <>
      <Grid mt={4}>
        <Box>Assigned Camps</Box>
      </Grid>

      {campListData.map((camp, index) => (
        <Box
        // key={index}
        // onClick={() => handleSetCampName( item.campName)}
        >
          <Link
            to={`/GoalSettingInCampStaff/${camp.camp_id}`}
            className="link2"
            onClick={() => {
              dispatch(setCampData(camp));
            }}
          >
            <Card
              variant="outlined"
              sx={{
                backgroundColor: "#F8F8F8",

                color: "#707070",
                my: "0.75rem",
                border: 1,
                borderColor: "#379592",
                borderRadius: "0.75rem",
                py: "0rem",
                maxHeight: 60,
              }}
            >
              <CardContent>
                <Grid
                  container
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Grid
                      container
                      display="flex"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Box mx="0.75rem" sx={{ color: "#379592" }}>
                        {camp.camp_name}
                      </Box>
                      <Chip
                        // label={card.campType.charAt(0).toUpperCase()+card.campType.slice(1).toLowerCase()}
                        label={camp.camp_type}
                        variant="outlined"
                        size="small"
                        className="medium"
                        mx="0.75rem"
                        sx={{
                          color: "#707070",
                          backgroundColor: "white",
                          fontSize: "0.5rem",
                          px: "0.5rem",
                          height: "20px",
                        }}
                      />
                      <Box
                        mx="0.75rem"
                        sx={{ fontSize: "0.85rem", color: "#000000" }}
                        className="medium"
                      >
                        Start Date: {camp.camp_startdate}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container display="flex" alignItems="center">
                      <Chip
                        label={camp.user_name}
                        variant="outlined"
                        size="small"
                        mx="0.75rem"
                        className="medium"
                        sx={{
                          // color: (card.campStatus==='DRAFT') ? "black" : "white",
                          // backgroundColor:  (card.campStatus==='ACTIVE') ? "#379592" : "#E2F157",
                          borderColor: "#379592",
                          mr: "1.75rem",
                          px: "1rem",
                          py: "0.85rem",
                        }}
                      />

                      <Box>
                        <Chip
                          label={camp.roles_name}
                          variant="outlined"
                          size="small"
                          mx="0.75rem"
                          className="medium"
                          sx={{
                            // color: (card.campStatus==='DRAFT') ? "black" : "white",
                            // backgroundColor:  (card.campStatus==='ACTIVE') ? "#379592" : "#E2F157",
                            borderColor: "#379592",
                            px: "1rem",
                            py: "0.85rem",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Link>
        </Box>
      ))}
    </>
  );
}
