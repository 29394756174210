import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Link, useHistory, useParams } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import GoalsDropDown from "../../Assets/Icons/Icons/GoalsDropDown.png";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Chip,
  CardContent,
  Divider,
  Box,
  Card,
} from "@mui/material";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Add from "../../Assets/Icons/Icons/add.png";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import "../../index.css";
import { apiService, apiService2 } from "../../Services/apiService";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import { US8_URL, SERVER_URL } from "../../Services/apiEndpints";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#379592", // Set your desired checkbox color
    },
  },
});

const theme1 = createTheme({
  palette: {
    primary: {
      main: "#2196f3", // Set your desired color for grid item 1
    },
  },
});

const useStyles = makeStyles(theme =>
  createStyles({
    smallRadioButton: {
      "& svg": {
        width: "0.7em",
        height: "0.7em"
      }
    }
  })
);

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "#379592" ? "#379592" : "#379592",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#379592",
}));

export default function () {
  const [expanded, setExpanded] = useState([0, 1, 2]);
  const [showForm, setShowForm] = useState(false);
  const [accordionLoop, setAccordionLoop] = useState([1]);
  const [paticipantsInfo, setPaticipantsInfo] = useState("");
  const [campsumstatus, setCampsumstatus] = useState("");
  const [editAccordion, setEditAccordion] = useState([]);

  const classes = useStyles();

  const history = useHistory();

  const intiFormData = Array(accordionLoop.length).fill({
    goalName: "",
    goalDesc: "",
    setImp: "",
    setStasify: "",
    goal_status: "startstatus",
    setPerformance: "",
    parent_importance: "",
    parent_satisfaction: "",
    parent_performance: "",
    preComments: "",
    achImp: "",
    achStasify: "",
    achPerformance: "",
    lastday_importance: "",
    lastday_satisfaction: "",
    lastday_performance: "",
    postComments: "",
  });
  const [formData, setFormData] = useState(intiFormData);

  // console.log("formdata", formData);
  // console.log("accordionLoop", accordionLoop);

  let { applicationId, camp_id } = useParams();

  React.useEffect(() => {
    (async () => {
      // setLoading(true);
      await getParticipantsDetails();
      await getPreGoalsDetails();
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }, []);

  const getPreGoalsDetails = async () => {
    let getPreGoalData = {
      _methodId: "6",
      applicationId: applicationId,
      // applicationId: 300004,
      // campId: 5199,
      campId: camp_id,
      userId: localStorage.getItem("userId"),
    };
    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/us8",
      url: `${SERVER_URL}${US8_URL}`,
      method: "POST",
      postData: getPreGoalData,
      headers: {
        "Content-Type": "application/json",

        tokenheaderkey: localStorage.getItem("token"),
      },
    };

    let goalResponse = await apiService2(apiInfo);
    console.log("goalResponse", goalResponse.data);
    if (
      goalResponse &&
      goalResponse?.data &&
      goalResponse?.data?.responseData &&
      goalResponse?.data?.responseData[0].length
    ) {
      let res = goalResponse?.data?.responseData[0];
      setCampsumstatus(goalResponse?.data?.responseData[1][0].camp_summary_status)
      let apiStatus = (res || []).find(
        (item) => item?.ResponseCode && item?.ResponseCode === "Fail"
      );
      // console.log("apiStatus", apiStatus);
      if (!apiStatus && apiStatus?.ResponseCode !== "Fail") {
        // setPreGaolResponse(res);
        let activeAccordians = [];
        let editAccordions = [];
        let data = [];
        console.log("resssssssssssssssssssssssssssssss", res);
        if (res && res.length && res[0].Code !== "1") {
          res = res.slice(0, 3);
          console.log(
            "reshereeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
            res
          );
          for (let i = 0; i < res.length; i++) {
            data = [
              ...data,
              {
                goal_Id: res[i].goal_Id,
                goalName: res[i].goalName,
                goalDesc: res[i].goalDesc,
                goal_status: res[i].goal_status,
                setImp: res[i].setImp,
                setStasify: res[i].setStasify,
                setPerformance: res[i].setPerformance,
                parent_importance: res[i].parent_importance,
                parent_satisfaction: res[i].parent_satisfaction,
                parent_performance: res[i].parent_performance,
                preComments: res[i].preComments,
                achImp: res[i].achImp,
                achPerformance: res[i].achPerformance,
                achStasify: res[i].achStasify,
                lastday_importance: res[i].lastday_importance,
                lastday_satisfaction: res[i].lastday_satisfaction,
                lastday_performance: res[i].lastday_performance,
                postComments: res[i].postComments,
              },
            ];
            activeAccordians.push(i);
            editAccordions.push(i);
          }
          // console.log("activeAccordians", activeAccordians);
          // console.log("editAccordions", editAccordions);
          setFormData(data);
          setShowForm(true);
          setAccordionLoop(activeAccordians);
          setEditAccordion(editAccordions);
        } else {
          setFormData(intiFormData);
          setShowForm(false);
          setAccordionLoop([1]);
          setEditAccordion([]);
        }
      }
    }
  };

  const getParticipantsDetails = async () => {
    try {
      let gettran = {
        _methodId: "5",
        applicationId: applicationId,
        userId: localStorage.getItem("userId"),
        // campId: 5199,
        // camp_id:camp_id,
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/us8",
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",

          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let login = await apiService2(apiInfo);

      const paticipantsDataList = login.data.responseData[0];

      setPaticipantsInfo(paticipantsDataList);
    } catch (error) {
      console.log("error", error);
    }
  };

  // const handleChange = (index) => (event, newExpanded) => {
  const handleChange = (index) => {
    // setExpanded(newExpanded ? panel : false);
    // alert()
    console.clear();
    console.log("indexvalue", expanded, index);
    let find = expanded.filter((item, index1) => item === index);
    console.log("find", find);
    if (find.length > 0) {
      setExpanded(expanded.filter((item, index1) => item !== index));
    } else {
      setExpanded((prev) => [...prev, index]);
    }
  };

  console.log("expandeddddddddddddddddd", expanded);

  const editHandleChange = (index1) => {
    // setExpanded(newExpanded ? panel : false);
    console.log(
      "Edithandlechangeindexvalue",
      index1,
      editAccordion.filter((item, index) => index !== index1)
    );
    // setEditAccordion((prev) => [...prev, index]);
    setEditAccordion(editAccordion.filter((item, index) => index !== index1));
  };

  const removeAccordion = async (accordionIndex, goal_Id) => {
    if (goal_Id) {
      const confirmed = await Swal.fire({
        text: "Do you want to remove the rating?",
        iconHtml: `<img src="${question}" width="100px" height="100px">`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#379592",

        cancelButtonColor: "#909090",
        customClass: {
          container: "my-swal",
          icon: "no-border",

          //    confirmButton: 'your-confirm-button-class',
        },
      });

      if (!confirmed.value) {
        // User clicked "Cancel" on the prompt, do nothing
        return;
      }

      try {
        let removeGoalPayload = {
          _methodId: "13",
          goalId: goal_Id,
          userId: localStorage.getItem("userId"),
          // campId: 5199,
          // camp_id:camp_id,
        };

        let apiInfo = {
          url: `${SERVER_URL}${US8_URL}`,
          method: "POST",
          postData: removeGoalPayload,
          headers: {
            "Content-Type": "application/json",
            tokenheaderkey: localStorage.getItem("token"),
          },
        };

        apiService2(apiInfo).then((removeRespo) => {
          const paticipantsDataList = removeRespo.data.responseData[0];
          const paticipantsDataOutput = removeRespo.data.responseData[0][0];
          const desc = paticipantsDataOutput.Desc;
          setPaticipantsInfo(paticipantsDataList);
          if (
            paticipantsDataList &&
            paticipantsDataList.length &&
            paticipantsDataList[0].ResponseCode === "Success"
          ) {
            // Show SweetAlert after the API call and getPreGoalsDetails() are successful
            Swal.fire({
              iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
              text: desc,
              confirmButtonText: "OK",
              confirmButtonColor: "#379592",
              customClass: {
                container: "my-swal",
              },
            });

            // Call getPreGoalsDetails after successful API call and SweetAlert
            getPreGoalsDetails();
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setFormData((prevData) =>
        prevData.map((item, index1) => {
          if (accordionIndex === index1) {
            return {
              ...item,
              ...{
                goalName: "",
                goalDesc: "",
                setImp: "",
                setStasify: "",
                setPerformance: "",
                parent_importance: "",
                parent_satisfaction: "",
                parent_performance: "",
                preComments: "",
                achImp: "",
                achStasify: "",
                achPerformance: "",
                lastday_importance: "",
                lastday_satisfaction: "",
                lastday_performance: "",
                postComments: "",
              },
            };
          }
          return item;
        })
      );
      setAccordionLoop(
        accordionLoop.filter((item, index) => accordionIndex !== index)
      );
    }
  };

  const addAccordion = (accordionLength) => {
    setAccordionLoop((prev) => [...prev, 1]);
    setTimeout(() => {
      setFormData((prevData) => [
        ...prevData,
        ...Array(accordionLength + 1).fill({
          goalName: "",
          goalDesc: "",
          setImp: "",
          goal_status: "startstatus",
          setStasify: "",
          setPerformance: "",
          parent_importance: "",
          parent_satisfaction: "",
          parent_performance: "",
          preComments: "",
          achImp: "",
          achStasify: "",
          achPerformance: "",
          lastday_importance: "",
          lastday_satisfaction: "",
          lastday_performance: "",
          postComments: "",
        }),
      ]);
      // setFormData(
      //   Array(accordionLength + 1).fill({
      //     goalName: "",
      //     goalDesc: "",
      //     setImp: "",
      //     setStasify: "",
      //     setPerformance: "",
      //     preComments: "",
      //     achImp: "",
      //     achStasify: "",
      //     achPerformance: "",
      //     postComments: "",
      //   })
      // );
    }, 1000);
  };

  const onSaveAccordion = async (accordionIndex, formType, formStatus) => {
    let preCampData = {
      applicationId: applicationId,
      userId: localStorage.getItem("userId"),
      // "goalName": enterGoal,
      // "goalDesc": goalDesc,
      // "setImp": importance,
      // "setStasify": satisfaction,
      // "setPerformance": performance,
      // "comments": perComments,
      campId: camp_id,
      // campId: 5199,
    };

    // console.log("accordionIndexaccordionIndex", accordionIndex);
    // console.log("formData", formData);
    let formDetails = (formData || []).find(
      (item, index) => index === accordionIndex
    );
    console.log("formDetails", formDetails);

    if (formDetails && Object.keys(formDetails).length > 0) {
      if (formType === "form2") {
        const {
          achImp,
          achPerformance,
          achStasify,
          postComments,
          goal_Id,
          lastday_importance,
          lastday_satisfaction,
          lastday_performance,
        } = formDetails;
        preCampData = {
          ...preCampData,
          goal_Id: goal_Id,
          achImp,
          achPerformance,
          achStasify,
          postComments,
          lastday_importance,
          lastday_satisfaction,
          lastday_performance,
          _methodId: 8,
          goalStatus: formStatus,
        };
      } else {
        const {
          goalName,
          goalDesc,
          setImp,
          preComments,
          setPerformance,
          setStasify,
          parent_importance,
          parent_performance,
          parent_satisfaction,
          goal_Id,
        } = formDetails;
        preCampData = {
          ...preCampData,
          goalId: goal_Id,
          goalName,
          goalDesc,
          setImp,
          preComments,
          setPerformance,
          setStasify,
          parent_importance,
          parent_performance,
          parent_satisfaction,
          _methodId: goal_Id ? 14 : 7,
          goalStatus: formStatus,
        };
      }

      if (["Submitted", "Completed"].includes(formStatus)) {
        console.log(
          "  !preCampData?.lastday_performance &&",
          !preCampData?.lastday_performance,
          formStatus
        );
        if (
          formType === "form1" &&
          (!preCampData?.goalName ||
            !preCampData?.goalDesc ||
            !preCampData?.setImp ||
            !preCampData?.preComments ||
            !preCampData?.setPerformance ||
            !preCampData?.setStasify ||
            !preCampData?.parent_importance ||
            !preCampData?.parent_performance ||
            !preCampData?.parent_satisfaction)
        ) {
          Swal.fire({
            icon: "warning",
            text: "Please Fill All The Ratings",
            confirmButtonText: "OK",
            confirmButtonColor: "#379592",
            customClass: {
              container: "my-swal",
            },
          });
          return false;
        } else if (
          formType === "form2" &&
          (!preCampData?.achImp ||
            !preCampData?.achPerformance ||
            !preCampData?.achStasify ||
            !preCampData?.postComments ||
            !preCampData?.goal_Id ||
            !preCampData?.lastday_importance ||
            !preCampData?.lastday_satisfaction ||
            !preCampData?.lastday_performance)
        ) {
          Swal.fire({
            icon: "warning",
            text: "Please Fill All The Ratings",
            confirmButtonText: "OK",
            confirmButtonColor: "#379592",
            customClass: {
              container: "my-swal",
            },
          });
          return false;
        }
      }

      console.log("preCampData", preCampData);
      // return false ;
      let apiInfo = {
        url: `${SERVER_URL}${US8_URL}`,
        method: "POST",
        postData: preCampData,
        headers: {
          "Content-Type": "application/json",

          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      if (["Submitted", "Completed"].includes(formStatus)) {
        Swal.fire({
          iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
          text: `Are you sure you want to ${
            formStatus === "Draft" ? "Save" : "Submit"
          }?`,
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonColor: "#379592",

          cancelButtonColor: "#909090",
          customClass: {
            container: "my-swal",
            icon: "no-border",

            //    confirmButton: 'your-confirm-button-class',
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            let savePreCampData = await apiService2(apiInfo);
            const savePreCampDataOutput =
              savePreCampData.data.responseData[0][0];
            const desc2 = savePreCampDataOutput.Desc;
            if (savePreCampData.data.responseCode === 0) {
              Swal.fire({
                iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
                text: desc2,
                confirmButtonText: "OK",
                confirmButtonColor: "#379592",
                customClass: {
                  container: "my-swal",
                },
              });
            }
            setExpanded(
              expanded.filter((item, index) => accordionIndex !== item)
            );
            await getPreGoalsDetails();
          }
        });
      } else {
        let savePreCampData = await apiService2(apiInfo);
        const savePreCampDataOutput = savePreCampData.data.responseData[0][0];
        const desc2 = savePreCampDataOutput.Desc;

        if (savePreCampData.data.responseCode === 0) {
          Swal.fire({
            iconHtml: `<img src="${GreenCheck}" width="100px" height="100px">`,
            text: desc2,
            confirmButtonText: "OK",
            confirmButtonColor: "#379592",
            customClass: {
              container: "my-swal",
            },
          });
        }
        setExpanded(expanded.filter((item, index) => accordionIndex !== item));
        await getPreGoalsDetails();
      }

      // console.log("preCampData", preCampData);
      // console.log("accordionIndex,expanded", accordionIndex, expanded);
    } else {
      alert("please fill the form");
    }

    // console.log("preCampData", preCampData);
    // return false;
  };
  const handleFormChange = (e, index) => {
    let { value, name } = e.target;
    if (name === "goalName") {
      value = value.toUpperCase(); // Convert to uppercase for goalName field
    }
    console.log("value,name", value, name, index);

    setFormData(
      formData.map((item, ind) => {
        if (index === ind) {
          console.log("indexxxxxxxxxxxxxxxxxxxxxxxxxx", index, ind);
          return { ...item, [name]: value };
        }
        return item;
      })
    );
  };

  // console.log("expandedexpandedexpanded", expanded);
  // console.log("expanded-formDAta", formData);
  // console.log("editAccordion", editAccordion);
  // console.log("editAccordion", editAccordion);
  // console.log("accordionLoopaccordionLoop", accordionLoop);
  console.log("formDataaaaaaaaaaa", formData);

  return (
    <div>
      <Grid container display="flex" sx={{ mt: "2rem" }}>
        <Grid item xs={12} sm={6} md={9}>
          <Stack direction="row" spacing={1}>
            <Link to={`/GoalSettingInCampStaff/${camp_id}`} className="link2">
              <Box
                alignSelf="center"
                component="img"
                sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>

            <Box alignSelf="center" px="1rem">
              Create Participants Goals
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={9} sx={{ ml: "0.5rem", mt: "1rem" }}>
        <Grid container display="flex" alignItems="flex-end">
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Name"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={paticipantsInfo[0]?.childName}
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          alignItems="flex-end"
          sx={{ mt: "1rem" }}
        >
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Age"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className="text-age"
              value={paticipantsInfo[0]?.childAge}
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Gender"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className="text-age"
              value={paticipantsInfo[0]?.gender}
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Email"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={paticipantsInfo[0]?.parentEmail}
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          alignItems="flex-end"
          sx={{ mt: "1rem" }}
        >
          <Grid item xs={12} md={4}>
            <TextField
              id="standard-basic"
              label="Address"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={paticipantsInfo[0]?.location}
              sx={{ width: 220 }}
            ></TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ ml: "0.5rem", mt: "2rem", mb: "2rem" }}>
        <Box>Goal of the New Camp</Box>
      </Grid>
      {!showForm && accordionLoop.length === 1 && (
        // <Grid sx={{ textAlign: "center" }}>
        //   <Box
        //     justifyContent="center"
        //     component="img"
        //     src={Add}
        //     onClick={() => setShowForm(true)}
        //     sx={{ width: "1rem", textAlign: "center" }}
        //   ></Box>
        // </Grid>
        <Grid sx={{ textAlign: "center" }}>
          {/* <Box
          justifyContent="center"
          component="img"
          src={Add}
          onClick={() => setShowForm(true)}
          sx={{ width: "1rem", textAlign: "center" }}
        ></Box> */}
          <Button
            variant="contained"
            sx={{
              color: "#FFFFFF",
              bgcolor: "#4C9F70",
              textTransform: "none",
              "&:hover": { background: "#4C9F70" },
              fontFamily: "gilroy",
              boardColor: "",
              width: 120,
              borderRadius: "7px",
            }}
            onClick={() => setShowForm(true)}
          >
            Add
          </Button>
        </Grid>
      )}
      {showForm &&
        accordionLoop.map((item, index) => {
          return (
            <Grid>
              <div>
                <Accordion
                  expanded={expanded.includes(index)}
                  // onChange={handleChange(index)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={
                      <ExpandMoreIcon onClick={() => handleChange(index)} />
                    }
                    // iconbuttonprops={{
                    //   onClick: () => handleChange(index)
                    // }}
                  >
                    {/* <Typography>Collapsible Group Item #1</Typography> */}

                    {expanded.includes(index) ? (
                      <Grid
                        container
                        display="flex"
                        sx={{ mt: "0.5rem", mb: "0.5rem" }}
                      >
                        <Grid item xs={12} sm={6} md={8}>
                          <Stack direction="row" spacing={1}>
                            {formData[index]?.goal_Id ? (
                              <Grid
                                item
                                xs={12}
                                md={5}
                                sx={{ color: "#FFFFFF" }}
                                name="goalName"
                              >
                                <p>{formData[index]?.goalName}</p>
                              </Grid>
                            ) : (
                              <Grid item xs={12} md={4}>
                                <TextField
                                  id="filled-basic"
                                  label="Enter Goal Number"
                                  variant="outlined"
                                  name="goalName"
                                  value={formData[index]?.goalName}
                                  onChange={(e) => handleFormChange(e, index)}
                                  sx={{
                                    width: 220,
                                    borderRadius: "7px",
                                    outline: "none",
                                    boxShadow: "none",
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "currentColor !important",
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      outline: "none",
                                      boxShadow: "none",
                                    },
                                  }}
                                  css={{
                                    "& .MuiOutlinedInput-root": {
                                      "&:hover fieldset": {
                                        borderColor: "currentColor", // Set the desired outline color
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "currentColor", // Set the desired outline color
                                      },
                                    },
                                  }}
                                  className="Accordion-btn"
                                />
                              </Grid>
                            )}
                            {formData[index]?.goal_Id ? (
                              <Grid
                                item
                                xs={12}
                                md={5}
                                sx={{
                                  color: "#FFFFFF",
                                  fontSize: "10px",
                                  justifyContent: "end",
                                }}
                                name="goalDesc"
                              >
                                <p>{formData[index]?.goalDesc}</p>
                              </Grid>
                            ) : (
                              <Grid item xs={12} md={4}>
                                <TextField
                                  id="outlined-basic"
                                  label="Enter Goal Decription"
                                  variant="outlined"
                                  name="goalDesc"
                                  value={formData[index]?.goalDesc}
                                  onChange={(e) => handleFormChange(e, index)}
                                  sx={{
                                    ml: "5rem",
                                    borderRadius: "7px",
                                  }}
                                  InputProps={{
                                    style: {
                                      outline: "none",
                                      boxShadow: "none",
                                    },
                                    inputProps: { maxLength: 200 },
                                  }}
                                  css={{
                                    "& .MuiOutlinedInput-root": {
                                      "&:hover fieldset": {
                                        borderColor: "currentColor", // Set the desired outline color
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "currentColor", // Set the desired outline color
                                      },
                                    },
                                  }}
                                  className="Accordion-btn1"
                                />
                              </Grid>
                            )}
                          </Stack>
                        </Grid>

                        {!["Completed"].includes(
                          formData[index]?.goal_status
                        ) && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            container
                            justifyContent="center"
                          >
                            <Box sx={{ mt: "0.5rem" }}>
                              {formData[index]?.goal_Id &&
                              editAccordion.includes(index) ? (
                                <Button
                                  variant="contained"
                                  sx={{
                                    color: "#FFFFFF",
                                    bgcolor: "#FF9529",
                                    textTransform: "none",
                                    "&:hover": { background: "#FF9529" },
                                    fontFamily: "gilroy",
                                    boardColor: "",
                                    width: 120,
                                    borderRadius: "7px",
                                    // width:"3rem"
                                    //   mx: "0.5rem",
                                    //   my:"0.5rem"
                                  }}
                                  onClick={() => editHandleChange(index)}
                                >
                                  Edit
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  sx={{
                                    color: "#FFFFFF",
                                    bgcolor: "#F21010",
                                    textTransform: "none",
                                    "&:hover": { background: "#F21010" },
                                    fontFamily: "gilroy",
                                    boardColor: "",
                                    //   mx: "0.5rem",
                                    //   my:"0.5rem"
                                  }}
                                  className="removegoal"
                                  onClick={() =>
                                    removeAccordion(
                                      index,
                                      formData[index]?.goal_Id
                                    )
                                  }
                                >
                                  Remove
                                </Button>
                              )}
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <Grid
                        container
                        display="flex"
                        sx={{ mt: "0.5rem", mb: "0.5rem" }}
                      >
                        {/* <Stack direction="row" spacing={4}> */}
                        <Grid item xs={12} md={8} sx={{ color: "#FFFFFF" }}>
                          <p>{formData[index]?.goalName}</p>
                        </Grid>
                        {/* 
                        <Grid
                          item
                          xs={12}
                          md={4}
                          sx={{
                            color: "#FFFFFF",
                            fontSize: "10px",
                            justifyContent: "end",
                          }}
                        >
                          <p>{formData[index]?.goalDesc}</p>
                        </Grid> */}
                        {/* </Stack> */}
                      </Grid>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Card sx={{ backgroundColor: "#F8F8F8" }}>
                      <h3>
                        <Box sx={{ ml: "2rem", mt: "2rem", color: "#379592" }}>
                          Pre Camp Rating
                        </Box>
                      </h3>

                      <ThemeProvider theme={theme}>
                        {/* <Grid container  sx={{ mt: "1rem"}} > */}
                          <div>
                            <div className="goal-title">
                              <Box sx={{ ml: "2rem" }}>Importance:</Box>
                            </div>

                            <Stack direction="row" spacing={1} >
                              <Grid container display="flex">
                                <Grid item md={2} sx={{ pr: "1rem" }}>
                                  <div className="">
                                    <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                      Caregiver:
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item md={10} sx={{ pl: "2rem" }}>
                                  <div className="">
                                    <FormControl>
                                      {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                      
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="parent_importance"
                                        value={
                                          formData[index]?.parent_importance
                                        }
                                        onChange={(e) =>
                                          handleFormChange(e, index)
                                        }
                                      >
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                        <FormControlLabel
                                         
                                          value="1"
                                          control={<Radio />}
                                          label="1"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="2"
                                          control={<Radio />}
                                          label="2"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="3"
                                          control={<Radio />}
                                          label="3"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="4"
                                          control={<Radio />}
                                          label="4"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="5"
                                          control={<Radio />}
                                          label="5"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="6"
                                          control={<Radio />}
                                          label="6"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="7"
                                          control={<Radio />}
                                          label="7"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="8"
                                          control={<Radio />}
                                          label="8"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="9"
                                          control={<Radio />}
                                          label="9"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="10"
                                          control={<Radio />}
                                          label="10"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </Grid>
                              </Grid>
                            </Stack>

                            <Stack direction="row" spacing={1} >
                              {/* <div  className="parentgoal"> */}
                              <Grid container display="flex">
                                <Grid item md={2} >
                                  <div className="">
                                    <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                      Camp Team:
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item md={10}  sx={{ pl: "2rem" }}>
                                  <ThemeProvider theme={theme1}>
                                    <div className="">
                                      <FormControl>
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}

                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          // className={classes.smallRadioButton}
                                          name="setImp"
                                          value={formData[index]?.setImp}
                                          onChange={(e) =>
                                            handleFormChange(e, index)
                                          }
                                        >
                                          {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                          <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="1"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="2"
                                            control={<Radio />}
                                            label="2"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="3"
                                            control={<Radio />}
                                            label="3"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="4"
                                            control={<Radio />}
                                            label="4"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="5"
                                            control={<Radio />}
                                            label="5"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="6"
                                            control={<Radio />}
                                            label="6"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="7"
                                            control={<Radio />}
                                            label="7"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="8"
                                            control={<Radio />}
                                            label="8"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="9"
                                            control={<Radio />}
                                            label="9"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="10"
                                            control={<Radio />}
                                            label="10"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </ThemeProvider>
                                </Grid>
                              </Grid>

                              {/* </div> */}
                            </Stack>
                          </div>

                          <div>
                            <div className="goal-title">
                              <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                Satisfaction:
                              </Box>
                            </div>

                            <Stack direction="row" spacing={1}>
                              <Grid container display="flex">
                                <Grid item md={2} sx={{ pr: "1rem" }}>
                                  <div className="">
                                    <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                      Caregiver:
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item md={10} sx={{ pl: "2rem" }}>
                                  <div className="">
                                    <FormControl>
                                      {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="parent_satisfaction"
                                        value={
                                          formData[index]?.parent_satisfaction
                                        }
                                        onChange={(e) =>
                                          handleFormChange(e, index)
                                        }
                                      >
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                        <FormControlLabel
                                          value="1"
                                          control={<Radio />}
                                          label="1"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="2"
                                          control={<Radio />}
                                          label="2"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="3"
                                          control={<Radio />}
                                          label="3"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="4"
                                          control={<Radio />}
                                          label="4"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="5"
                                          control={<Radio />}
                                          label="5"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="6"
                                          control={<Radio />}
                                          label="6"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="7"
                                          control={<Radio />}
                                          label="7"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="8"
                                          control={<Radio />}
                                          label="8"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="9"
                                          control={<Radio />}
                                          label="9"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="10"
                                          control={<Radio />}
                                          label="10"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </Grid>
                              </Grid>
                            </Stack>

                            <Stack direction="row" spacing={1}>
                              <Grid container display="flex">
                                <Grid item md={2} sx={{ pr: "1rem" }}>
                                  <div className="">
                                    <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                    Camp Team:
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item md={10} sx={{ pl: "2rem" }}>
                                  <ThemeProvider theme={theme1}>
                                    <div className="">
                                      <FormControl>
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="setStasify"
                                          value={formData[index]?.setStasify}
                                          onChange={(e) =>
                                            handleFormChange(e, index)
                                          }
                                        >
                                          {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                          <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="1"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="2"
                                            control={<Radio />}
                                            label="2"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="3"
                                            control={<Radio />}
                                            label="3"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="4"
                                            control={<Radio />}
                                            label="4"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="5"
                                            control={<Radio />}
                                            label="5"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="6"
                                            control={<Radio />}
                                            label="6"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="7"
                                            control={<Radio />}
                                            label="7"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="8"
                                            control={<Radio />}
                                            label="8"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="9"
                                            control={<Radio />}
                                            label="9"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="10"
                                            control={<Radio />}
                                            label="10"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </ThemeProvider>
                                </Grid>
                              </Grid>
                            </Stack>
                          </div>

                          <div>
                            <div className="goal-title">
                              <Box sx={{ ml: "2rem", mt: "1.5rem" }}>
                                Performance:
                              </Box>
                            </div>

                            <Stack direction="row" spacing={1}>
                              <Grid container display="flex">
                                <Grid item md={2} sx={{ pr: "1rem" }}>
                                  <div className="">
                                    <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                      Caregiver:
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item md={10} sx={{ pl: "2rem" }}>
                                  <div className="">
                                    <FormControl>
                                      {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="parent_performance"
                                        value={
                                          formData[index]?.parent_performance
                                        }
                                        onChange={(e) =>
                                          handleFormChange(e, index)
                                        }
                                      >
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                        <FormControlLabel
                                          value="1"
                                          control={<Radio />}
                                          label="1"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="2"
                                          control={<Radio />}
                                          label="2"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="3"
                                          control={<Radio />}
                                          label="3"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="4"
                                          control={<Radio />}
                                          label="4"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="5"
                                          control={<Radio />}
                                          label="5"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="6"
                                          control={<Radio />}
                                          label="6"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="7"
                                          control={<Radio />}
                                          label="7"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="8"
                                          control={<Radio />}
                                          label="8"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="9"
                                          control={<Radio />}
                                          label="9"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                        <FormControlLabel
                                          value="10"
                                          control={<Radio />}
                                          label="10"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              [
                                                "Submitted",
                                                "In-Progress",
                                              ].includes(
                                                formData[index]?.goal_status
                                              ))
                                          } // Set disabled prop to true
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </Grid>
                              </Grid>
                            </Stack>

                            <Stack direction="row" spacing={1}>
                              <Grid container display="flex">
                                <Grid item md={2} sx={{ pr: "1rem" }}>
                                  <div className="">
                                    <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                    Camp Team:
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item md={10} sx={{ pl: "2rem" }}>
                                  <ThemeProvider theme={theme1}>
                                    <div className="">
                                      <FormControl>
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="setPerformance"
                                          value={
                                            formData[index]?.setPerformance
                                          }
                                          onChange={(e) =>
                                            handleFormChange(e, index)
                                          }
                                        >
                                          {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                          <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="1"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="2"
                                            control={<Radio />}
                                            label="2"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="3"
                                            control={<Radio />}
                                            label="3"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="4"
                                            control={<Radio />}
                                            label="4"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="5"
                                            control={<Radio />}
                                            label="5"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="6"
                                            control={<Radio />}
                                            label="6"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="7"
                                            control={<Radio />}
                                            label="7"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="8"
                                            control={<Radio />}
                                            label="8"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="9"
                                            control={<Radio />}
                                            label="9"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                          <FormControlLabel
                                            value="10"
                                            control={<Radio />}
                                            label="10"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Submitted",
                                                  "In-Progress",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            } // Set disabled prop to true
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </ThemeProvider>
                                </Grid>
                              </Grid>
                            </Stack>
                          </div>
                        {/* </Grid> */}
                      </ThemeProvider>

                      <Box
                        sx={{
                          ml: "2rem",
                          mt: "1.5rem",
                          mb: "0.5rem",
                          width: "100%",
                        }}
                      >
                        <TextField
                          id="outlined-multiline-static"
                          label="Comment:"
                          // multiline
                          rows={3}
                          sx={{ width: "75%", backgroundColor: "#fff" }}
                          name="preComments"
                          value={formData[index]?.preComments}
                          onChange={(e) => handleFormChange(e, index)}
                          InputProps={{
                            readOnly: editAccordion.includes(index),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // value={formData.description}
                          // onChange={(event) => storeInfo(event)}
                          // {...register("description")}
                          // error={errors.description ? true : false}
                          // defaultValue="Default Value"
                        />
                      </Box>
                      {!editAccordion.includes(index) && (
                        <Grid
                          container
                          justifyContent="flex-end"
                          sx={{
                            mt: "2rem",
                            mb: "2rem",
                          }}
                        >
                          <Grid item>
                            <Button
                              variant="contained"
                              sx={{
                                px: "2rem",
                                mx: "1rem",
                                bgcolor: "#379592",
                                textTransform: "none",
                                "&:hover": { background: "#379592" },
                              }}
                              className="gilroy"
                              onClick={() =>
                                onSaveAccordion(index, "form1", "Draft")
                              }
                              // disabled={true}
                              disabled={
                                formData[index]?.goal_status &&
                                ["Submitted", "In-Progress"].includes(
                                  formData[index]?.goal_status
                                )
                              }
                            >
                              Save
                            </Button>
                          </Grid>
                          <Grid item>
                            {/* <Link className='link' to='/ViewCamp'> */}
                            <Button
                              variant="contained"
                              className="gilroy"
                              sx={{
                                px: "2rem",
                                mx: "1rem",
                                bgcolor: "#379592",
                                textTransform: "none",
                                "&:hover": { background: "#379592" },
                              }}
                              // onClick={() => onSubmit()}
                              onClick={() =>
                                onSaveAccordion(index, "form1", "Submitted")
                              }
                              // onClick={handleSubmit(onPublish)}
                              disabled={
                                formData[index]?.goal_status &&
                                ["Submitted", "In-Progress"].includes(
                                  formData[index]?.goal_status
                                )
                              }
                            >
                              Submit
                            </Button>
                            {/* </Link> */}
                          </Grid>
                        </Grid>
                      )}

                      <Box mt={5} mb={5}>
                        {" "}
                        <Divider />
                      </Box>

                      <Grid>
                        <h3>
                          <Box
                            sx={{ ml: "2rem", mt: "2rem", color: "#379592" }}
                          >
                            Post Camp Rating
                          </Box>
                        </h3>
                      </Grid>

                      <ThemeProvider theme={theme}>
                        {/* <Grid container display="flex" sx={{ mt: "1rem" }}> */}
                          <div>
                            <div className="goal-title">
                              <Box sx={{ ml: "2rem" }}>Importance:</Box>
                            </div>
                            <Stack direction="row" spacing={1}>
                              {/* <div  className="parentgoal"> */}
                              <Grid container display="flex">
                                <Grid item md={2} sx={{ pr: "1rem" }}>
                                  <div className="">
                                    <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                      Caregiver:
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item md={10} sx={{ pl: "2rem" }}>
                                  <div className="">
                                    <FormControl>
                                      {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="lastday_importance"
                                        value={
                                          formData[index]?.lastday_importance
                                        }
                                        onChange={(e) =>
                                          handleFormChange(e, index)
                                        }
                                      >
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                        <FormControlLabel
                                          value="1"
                                          control={<Radio />}
                                          label="1"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="2"
                                          control={<Radio />}
                                          label="2"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="3"
                                          control={<Radio />}
                                          label="3"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="4"
                                          control={<Radio />}
                                          label="4"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="5"
                                          control={<Radio />}
                                          label="5"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="6"
                                          control={<Radio />}
                                          label="6"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="7"
                                          control={<Radio />}
                                          label="7"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="8"
                                          control={<Radio />}
                                          label="8"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="9"
                                          control={<Radio />}
                                          label="9"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="10"
                                          control={<Radio />}
                                          label="10"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </Grid>
                              </Grid>
                              {/* </div> */}
                            </Stack>

                            <Stack direction="row" spacing={1}>
                              {/* <div  className="parentgoal"> */}
                              <Grid container display="flex">
                                <Grid item md={2} sx={{ pr: "1rem" }}>
                                  <div className="">
                                    <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                    Camp Team:
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item md={10} sx={{ pl: "2rem" }}>
                                  <ThemeProvider theme={theme1}>
                                    <div className="">
                                      <FormControl>
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="achImp"
                                          value={formData[index]?.achImp}
                                          onChange={(e) =>
                                            handleFormChange(e, index)
                                          }
                                        >
                                          {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                          <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="1"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="2"
                                            control={<Radio />}
                                            label="2"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="3"
                                            control={<Radio />}
                                            label="3"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="4"
                                            control={<Radio />}
                                            label="4"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="5"
                                            control={<Radio />}
                                            label="5"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="6"
                                            control={<Radio />}
                                            label="6"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="7"
                                            control={<Radio />}
                                            label="7"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="8"
                                            control={<Radio />}
                                            label="8"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="9"
                                            control={<Radio />}
                                            label="9"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="10"
                                            control={<Radio />}
                                            label="10"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </ThemeProvider>
                                </Grid>
                              </Grid>
                              {/* </div> */}
                            </Stack>
                          </div>
                          <div>
                            <div className="goal-title">
                              <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                Satisfaction:
                              </Box>
                            </div>

                            <Stack direction="row" spacing={1}>
                              <Grid container display="flex">
                                <Grid item md={2} sx={{ pr: "1rem" }}>
                                  <div className="">
                                    <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                      Caregiver:
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item md={10} sx={{ pl: "2rem" }}>
                                  <div className="">
                                    <FormControl>
                                      {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="lastday_satisfaction"
                                        value={
                                          formData[index]?.lastday_satisfaction
                                        }
                                        onChange={(e) =>
                                          handleFormChange(e, index)
                                        }
                                      >
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                        <FormControlLabel
                                          value="1"
                                          control={<Radio />}
                                          label="1"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="2"
                                          control={<Radio />}
                                          label="2"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="3"
                                          control={<Radio />}
                                          label="3"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="4"
                                          control={<Radio />}
                                          label="4"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="5"
                                          control={<Radio />}
                                          label="5"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="6"
                                          control={<Radio />}
                                          label="6"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="7"
                                          control={<Radio />}
                                          label="7"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="8"
                                          control={<Radio />}
                                          label="8"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="9"
                                          control={<Radio />}
                                          label="9"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="10"
                                          control={<Radio />}
                                          label="10"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </Grid>
                              </Grid>
                            </Stack>

                            <Stack direction="row" spacing={1}>
                              <Grid container display="flex">
                                <Grid item md={2} sx={{ pr: "1rem" }}>
                                  <div className="">
                                    <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                    Camp Team:
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item md={10} sx={{ pl: "2rem" }}>
                                  <ThemeProvider theme={theme1}>
                                    <div className="">
                                      <FormControl>
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="achStasify"
                                          value={formData[index]?.achStasify}
                                          onChange={(e) =>
                                            handleFormChange(e, index)
                                          }
                                        >
                                          {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                          <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="1"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="2"
                                            control={<Radio />}
                                            label="2"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="3"
                                            control={<Radio />}
                                            label="3"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="4"
                                            control={<Radio />}
                                            label="4"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="5"
                                            control={<Radio />}
                                            label="5"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="6"
                                            control={<Radio />}
                                            label="6"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="7"
                                            control={<Radio />}
                                            label="7"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="8"
                                            control={<Radio />}
                                            label="8"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="9"
                                            control={<Radio />}
                                            label="9"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="10"
                                            control={<Radio />}
                                            label="10"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </ThemeProvider>
                                </Grid>
                              </Grid>
                            </Stack>
                          </div>

                          <div>
                            <div className="goal-title">
                              <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                Performance:
                              </Box>
                            </div>

                            <Stack direction="row" spacing={1}>
                              <Grid container display="flex">
                                <Grid item md={2} sx={{ pr: "1rem" }}>
                                  <div className="">
                                    <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                      Caregiver:
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item md={10} sx={{ pl: "2rem" }}>
                                  <div className="">
                                    <FormControl>
                                      {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="lastday_performance"
                                        value={
                                          formData[index]?.lastday_performance
                                        }
                                        onChange={(e) =>
                                          handleFormChange(e, index)
                                        }
                                      >
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                        <FormControlLabel
                                          value="1"
                                          control={<Radio />}
                                          label="1"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="2"
                                          control={<Radio />}
                                          label="2"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="3"
                                          control={<Radio />}
                                          label="3"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="4"
                                          control={<Radio />}
                                          label="4"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="5"
                                          control={<Radio />}
                                          label="5"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="6"
                                          control={<Radio />}
                                          label="6"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="7"
                                          control={<Radio />}
                                          label="7"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="8"
                                          control={<Radio />}
                                          label="8"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="9"
                                          control={<Radio />}
                                          label="9"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                        <FormControlLabel
                                          value="10"
                                          control={<Radio />}
                                          label="10"
                                          disabled={
                                            editAccordion.includes(index) ||
                                            (formData[index]?.goal_status &&
                                              ["Draft", "startstatus"].includes(
                                                formData[index]?.goal_status
                                              ))
                                          }
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </Grid>
                              </Grid>
                            </Stack>

                            <Stack direction="row" spacing={1}>
                              <Grid container display="flex">
                                <Grid item md={2} sx={{ pr: "1rem" }}>
                                  <div className="">
                                    <Box sx={{ ml: "2rem", mt: "0.5rem" }}>
                                    Camp Team:
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item md={10} sx={{ pl: "2rem" }}>
                                  <ThemeProvider theme={theme1}>
                                    <div className="">
                                      <FormControl>
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="achPerformance"
                                          value={
                                            formData[index]?.achPerformance
                                          }
                                          onChange={(e) =>
                                            handleFormChange(e, index)
                                          }
                                        >
                                          {/* <FormLabel id="demo-row-radio-buttons-group-label">1</FormLabel>  */}
                                          <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="1"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="2"
                                            control={<Radio />}
                                            label="2"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="3"
                                            control={<Radio />}
                                            label="3"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="4"
                                            control={<Radio />}
                                            label="4"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="5"
                                            control={<Radio />}
                                            label="5"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="6"
                                            control={<Radio />}
                                            label="6"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="7"
                                            control={<Radio />}
                                            label="7"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="8"
                                            control={<Radio />}
                                            label="8"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="9"
                                            control={<Radio />}
                                            label="9"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                          <FormControlLabel
                                            value="10"
                                            control={<Radio />}
                                            label="10"
                                            disabled={
                                              editAccordion.includes(index) ||
                                              (formData[index]?.goal_status &&
                                                [
                                                  "Draft",
                                                  "startstatus",
                                                ].includes(
                                                  formData[index]?.goal_status
                                                ))
                                            }
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </ThemeProvider>
                                </Grid>
                              </Grid>
                            </Stack>
                          </div>
                        {/* </Grid> */}
                      </ThemeProvider>

                      <Box
                        sx={{
                          ml: "2rem",
                          mt: "1.5rem",
                          mb: "0.5rem",
                          width: "100%",
                        }}
                      >
                        <TextField
                          id="outlined-multiline-static"
                          label="Comment:"
                          // multiline
                          rows={3}
                          sx={{ width: "75%", backgroundColor: "#fff" }}
                          name="postComments"
                          value={formData[index]?.postComments}
                          onChange={(e) => handleFormChange(e, index)}
                          InputProps={{
                            readOnly: editAccordion.includes(index),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // value={formData.description}
                          // onChange={(event) => storeInfo(event)}
                          // {...register("description")}
                          // error={errors.description ? true : false}
                          // defaultValue="Default Value"
                        />
                      </Box>
                      {!editAccordion.includes(index) && (
                        <Grid
                          container
                          justifyContent="flex-end"
                          sx={{
                            mt: "2rem",
                            mb: "2rem",
                          }}
                        >
                          <Grid item>
                            <Button
                              variant="contained"
                              sx={{
                                px: "2rem",
                                mx: "1rem",
                                bgcolor: "#379592",
                                textTransform: "none",
                                "&:hover": { background: "#379592" },
                              }}
                              className="gilroy"
                              disabled={
                                formData[index]?.goal_status &&
                                ["Draft", "startstatus"].includes(
                                  formData[index]?.goal_status
                                )
                              }
                              onClick={() =>
                                onSaveAccordion(index, "form2", "In-Progress")
                              }
                              // disabled={true}
                            >
                              Save
                            </Button>
                          </Grid>
                          <Grid item>
                            {/* <Link className='link' to='/ViewCamp'> */}
                            <Button
                              variant="contained"
                              className="gilroy"
                              sx={{
                                px: "2rem",
                                mx: "1rem",
                                bgcolor: "#379592",
                                textTransform: "none",
                                "&:hover": { background: "#379592" },
                              }}
                              onClick={() =>
                                onSaveAccordion(index, "form2", "Completed")
                              }
                              disabled={
                                formData[index]?.goal_status &&
                                ["Draft", "startstatus"].includes(
                                  formData[index]?.goal_status
                                )
                              }
                              // onClick={() => onSubmit()}
                              // onClick={handleSubmit(onPublish)}
                            >
                              Submit
                            </Button>
                            {/* </Link> */}
                          </Grid>
                        </Grid>
                      )}
                    </Card>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          );
        })}

      {showForm && accordionLoop.length < 3 && (
        <>
         
          <Grid
            sx={{
              textAlign: "center",
              mt: "2rem",
            }}
          >
            <Button
              variant="contained"
              className="gilroy"
              sx={{
                px: "2rem",
                mx: "1rem",
                bgcolor: "#4C9F70",
                textTransform: "none",
                "&:hover": { background: "#4C9F70" },
              }}
              // onClick={() => onSubmit()}
              onClick={() => addAccordion(accordionLoop.length)}
              disabled={campsumstatus === "Completed"}
            >
              Add
            </Button>
          </Grid>
        </>
      )}

      {showForm && (
        <Grid
          // justifyContent="flex-end"
          sx={{
            textAlign: "end",
            mt: "2rem",
          }}
        >
          <Button
            variant="contained"
            className="gilroy"
            sx={{
              px: "2rem",
              mx: "1rem",
              bgcolor: "#909090",
              textTransform: "none",
              "&:hover": { background: "#909090" },
            }}
            onClick={() => history.push(`/GoalSettingInCampStaff/${camp_id}`)}
          >
            Cancel
          </Button>
        </Grid>
      )}
    </div>
  );
}
